import { create } from 'zustand';

interface InboxCountStore {
  inboxCount: number;
  actions: {
    setInboxCount: (count: number) => void;
  };
}

export const inboxCountStore = create<InboxCountStore>((set) => ({
  inboxCount: 0,
  actions: {
    setInboxCount: (count: number) => set({ inboxCount: count }),
  },
}));

export const useInboxCount = () => {
  return inboxCountStore((state) => state.inboxCount);
};

export const useInboxCountActions = () => {
  return inboxCountStore((state) => state.actions);
};
