export const PencilIcon = (props) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="pencil">
      <path
        id="Icon"
        d="M4.8283 8.50034L3.49988 8.50034L3.49988 7.00034M3.05118 7.44904L8.70537 1.79485C9.09589 1.40432 9.72906 1.40432 10.1196 1.79485C10.5101 2.18537 10.5101 2.81854 10.1196 3.20906L4.382 8.94664C4.11397 9.21467 3.97996 9.34868 3.8316 9.4639C3.69982 9.56625 3.55974 9.65742 3.4128 9.73647C3.24738 9.82547 3.07058 9.89374 2.71697 10.0303L1.5 10.5002L1.89156 9.32541C2.02519 8.92446 2.09201 8.72398 2.18495 8.53679C2.26748 8.37055 2.36527 8.21232 2.47705 8.06415C2.60291 7.89731 2.75233 7.74788 3.05118 7.44904Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
