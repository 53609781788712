import { addDays, format } from 'date-fns';
import { useEffect, useState } from 'react';
import { DateRange } from 'react-day-picker';
import { useParams } from 'react-router-dom';

import { StripeMetricsResponseDto } from '@/api';
import { useStripeMetricsControllerGetMetrics } from '@/api/generated/api/stripe-metrics/stripe-metrics';
import { CalendarIcon } from '@/assets/icon/calendar';
import { Loading } from '@/components/Loading';
import { cn } from '@/lib/utils';

import OverviewCard from './OverviewCard';
import OverviewChart from './OverviewChart';

import { Button } from '../button';
import { Calendar } from '../calendar';
import { Popover, PopoverContent, PopoverTrigger } from '../popover';

const OverviewPage = () => {
  const { brandId } = useParams();
  const [metrics, setMetrics] = useState<StripeMetricsResponseDto>();

  const [date, setDate] = useState<DateRange | undefined>({
    from: addDays(new Date(), -7),
    to: new Date(),
  });

  const [dateCompare, setDateCompare] = useState<DateRange | undefined>({
    from: addDays(new Date(), -15),
    to: addDays(new Date(), -8),
  });

  const overviewQuery = useStripeMetricsControllerGetMetrics(
    brandId as string,
    {
      startDate: date?.from?.toISOString().split('T')[0],
      endDate: date?.to?.toISOString().split('T')[0],
      comparisonStartDate: dateCompare?.from?.toISOString().split('T')[0],
      comparisonEndDate: dateCompare?.to?.toISOString().split('T')[0],
    },
  );

  useEffect(() => {
    if (overviewQuery.isSuccess) {
      setMetrics(overviewQuery.data);
    }
  }, [overviewQuery.isSuccess]);

  if (overviewQuery.isLoading) {
    return <Loading />;
  }

  return (
    <div className="flex flex-col gap-6 overflow-auto">
      <div className="flex flex-col items-center gap-4 lg:flex-row">
        <Popover>
          <PopoverTrigger asChild>
            <Button
              id="date"
              variant={'outline'}
              className={cn(
                'flex w-fit justify-start gap-2.5 border-light font-medium text-black dark:border-dark-light dark:text-white',
                !date && 'text-muted-foreground',
              )}
            >
              <CalendarIcon className="h-4 w-4 fill-black dark:fill-white" />
              {date?.from ? (
                date.to ? (
                  <>
                    {format(date.from, 'LLL dd, y')} -{' '}
                    {format(date.to, 'LLL dd, y')}
                  </>
                ) : (
                  format(date.from, 'LLL dd, y')
                )
              ) : (
                <span>Pick a date</span>
              )}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0" align="start">
            <Calendar
              initialFocus
              mode="range"
              defaultMonth={date?.from}
              selected={date}
              onSelect={setDate}
              numberOfMonths={2}
            />
          </PopoverContent>
        </Popover>
        compared to
        <Popover>
          <PopoverTrigger asChild>
            <Button
              id="dateCompare"
              variant={'outline'}
              className={cn(
                'flex w-fit justify-start gap-2.5 border-light font-medium text-black dark:border-dark-light dark:text-white',
                !dateCompare && 'text-muted-foreground',
              )}
            >
              <CalendarIcon className="h-4 w-4 fill-black dark:fill-white" />
              {dateCompare?.from ? (
                dateCompare.to ? (
                  <>
                    {format(dateCompare.from, 'LLL dd, y')} -{' '}
                    {format(dateCompare.to, 'LLL dd, y')}
                  </>
                ) : (
                  format(dateCompare.from, 'LLL dd, y')
                )
              ) : (
                <span>Pick a date</span>
              )}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0" align="start">
            <Calendar
              initialFocus
              mode="range"
              defaultMonth={dateCompare?.from}
              selected={dateCompare}
              onSelect={setDateCompare}
              numberOfMonths={2}
            />
          </PopoverContent>
        </Popover>
      </div>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
        {metrics?.metrics?.map((data, index) => (
          <OverviewCard
            key={index}
            growth={data.growth}
            lastPeriod={data.lastPeriod}
            title={data.title}
            value={data.value}
          />
        ))}
      </div>
      <OverviewChart chartData={metrics?.chartData || []} />
      <div className="flex flex-col gap-4 xl:flex-row">
        <div className="flex w-full flex-col gap-6 rounded-2xl bg-light-2 p-4 dark:bg-dark-2">
          <div className="text-lg font-bold text-textParagraph dark:text-dark-textParagraph">
            User breakdown
          </div>
          <div className="flex flex-col gap-4">
            {metrics?.userBreakdown && metrics?.userBreakdown?.length > 0 ? (
              metrics?.userBreakdown.map((data, i) => (
                <div
                  key={i}
                  className="flex h-10 w-full items-center justify-between rounded-lg bg-light-3 text-base dark:bg-dark-3"
                >
                  <p
                    className={`flex h-full items-center justify-between gap-4 rounded-lg text-white`}
                    style={{
                      width: `${data.value ? data.value : 0}%`,
                      backgroundColor: data.color,
                      // paddingInline: data.value ? '16px' : 0,
                    }}
                  >
                    <div className="pl-2">{data.title}</div>
                    <div className="pr-2">{data.value}%</div>
                  </p>
                </div>
              ))
            ) : (
              <div className="flex w-full items-center justify-center py-32">
                No data
              </div>
            )}
          </div>
        </div>
        <div className="flex w-full flex-col gap-6 rounded-2xl bg-light-2 p-4 dark:bg-dark-2">
          <div className="text-lg font-bold text-textParagraph dark:text-dark-textParagraph">
            Recently Joined
          </div>
          <div className="flex flex-col gap-4">
            {metrics?.recentlyJoined && metrics?.recentlyJoined.length > 0 ? (
              metrics?.recentlyJoined.map((data) => (
                <div className="flex w-full items-center gap-2.5">
                  {data.photo ? (
                    <img
                      src={data.photo}
                      alt="user"
                      className="h-10 w-10 rounded-lg"
                    />
                  ) : (
                    <div className="flex min-h-10 min-w-10 items-center justify-center rounded-lg bg-brand">
                      {data.name.split('')[0] +
                        data.name.split('')[1].toUpperCase()}
                    </div>
                  )}
                  <div className="text-black dark:text-white">
                    {data.name + ' '}
                    <span className="text-textParagraph dark:text-dark-textParagraph">
                      subscribed to ({data.spaceGroup}) for
                    </span>
                    {' $' + data.paid}
                  </div>
                </div>
              ))
            ) : (
              <div className="flex w-full items-center justify-center py-32">
                No data
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverviewPage;
