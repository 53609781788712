import { Tabs, TabsContent, TabsList, TabsTrigger } from '@radix-ui/react-tabs';
import { useSearchParams } from 'react-router-dom';

import { useUpdateConfigHeader } from '@/components/config-header-provider';
import { Button } from '@/components/ui/button';
import AccountTab from '@/components/ui/settings-components/creator/AccountTab';
import MonetizationTab from '@/components/ui/settings-components/creator/MonetizationTab';
import SubscriptionsTab from '@/components/ui/settings-components/fan/SubscriptionsTab';
import { useBrandPermits } from '@/hooks/useBrandPermits';
import { useAuth } from '@/hooks/useContext';

const CreatorSettingsPage = () => {
  useUpdateConfigHeader(
    {
      showNotifications: true,
      label: '',
      action: '',
      hidden: true,
    },
    [],
  );
  const { logout } = useAuth();
  const hasPermits = useBrandPermits();
  const [searchParams] = useSearchParams();

  const handleLogout = async () => {
    try {
      logout();
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <div className="flex h-full w-full flex-col gap-10 overflow-auto px-6 py-10 xl:px-62.5 xl:py-12">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-semibold text-black dark:text-white">
          Settings
        </h1>
        <Button variant={'destructive'} onClick={handleLogout}>
          Logout
        </Button>
      </div>
      <Tabs
        defaultValue={searchParams.get('type') || 'Account'}
        className="h-fit w-full"
      >
        <TabsList className="flex justify-start gap-8 rounded-none border-b border-light !bg-transparent p-0 dark:border-dark-light">
          <TabsTrigger
            value="Account"
            className="rounded-none pb-2.5 data-[state=active]:-mb-[1px] data-[state=active]:border-b-2 data-[state=active]:border-brand data-[state=active]:bg-transparent data-[state=active]:!text-brand"
          >
            Account
          </TabsTrigger>
          {hasPermits && (
            <TabsTrigger
              value="Monetization"
              className="rounded-none pb-2.5 data-[state=active]:-mb-[1px] data-[state=active]:border-b-2 data-[state=active]:border-brand data-[state=active]:bg-transparent data-[state=active]:!text-brand"
            >
              1:1 Monetization
            </TabsTrigger>
          )}
          {!hasPermits && (
            <TabsTrigger
              value="Subscriptions"
              className="rounded-none pb-2.5 data-[state=active]:-mb-[1px] data-[state=active]:border-b-2 data-[state=active]:border-brand data-[state=active]:bg-transparent data-[state=active]:!text-brand"
            >
              Subscriptions
            </TabsTrigger>
          )}
        </TabsList>
        <TabsContent value="Account">
          <AccountTab />
        </TabsContent>
        <TabsContent value="Subscriptions" className="h-full">
          <SubscriptionsTab />
        </TabsContent>
        <TabsContent value="Monetization" className="h-full">
          <MonetizationTab />
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default CreatorSettingsPage;
