import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';

import {
  NewCourseCounterModel,
  NewDiscussionCounterModel,
  NewEventCounterModel,
  NewGroupChatMessageCounterModel,
  NewPostCounterModel,
} from '@/api';
import { NotificationCounters } from '@/api';
import { getNotificationsControllerGetNotificationCountersForBrandQueryKey } from '@/api';
import { notificationsSocket } from '@/utils/notificationsSocket';

export const useNotificationCounters = (brandId: string) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    notificationsSocket.on(
      'postCounter',
      (newPostCounter: NewPostCounterModel) => {
        queryClient.setQueryData<NotificationCounters>(
          getNotificationsControllerGetNotificationCountersForBrandQueryKey(
            brandId,
          ),
          (prev: NotificationCounters | undefined) => {
            if (!prev)
              return {
                newCourseCounter: [],
                newDiscussionCounter: [],
                newEventCounter: [],
                newGroupChatMessageCounter: [],
                newPostCounter: [newPostCounter],
              };

            const postCountersFiltered = prev.newPostCounter.filter(
              (item) => item.postsSpaceId !== newPostCounter.postsSpaceId,
            );

            return {
              ...prev,
              newPostCounter: [...postCountersFiltered, newPostCounter],
            };
          },
        );
      },
    );

    return () => {
      notificationsSocket.off('postCounter');
    };
  }, [brandId, queryClient]);

  useEffect(() => {
    notificationsSocket.on(
      'eventCounter',
      (newEventCounter: NewEventCounterModel) => {
        queryClient.setQueryData<NotificationCounters>(
          getNotificationsControllerGetNotificationCountersForBrandQueryKey(
            brandId,
          ),
          (prev: NotificationCounters | undefined) => {
            if (!prev) return undefined;

            const eventCountersFiltered = prev.newEventCounter.filter(
              (item) => item.eventSpaceId !== newEventCounter.eventSpaceId,
            );

            return {
              ...prev,
              newEventCounter: [...eventCountersFiltered, newEventCounter],
            };
          },
        );
      },
    );

    return () => {
      notificationsSocket.off('eventCounter');
    };
  }, [brandId, queryClient]);

  useEffect(() => {
    notificationsSocket.on(
      'groupChatMessageCounter',
      (newGroupChatMessageCounter: NewGroupChatMessageCounterModel) => {
        queryClient.setQueryData<NotificationCounters>(
          getNotificationsControllerGetNotificationCountersForBrandQueryKey(
            brandId,
          ),
          (prev: NotificationCounters | undefined) => {
            if (!prev)
              return {
                newCourseCounter: [],
                newDiscussionCounter: [],
                newEventCounter: [],
                newGroupChatMessageCounter: [newGroupChatMessageCounter],
                newPostCounter: [],
              };

            const groupChatMessageCountersFiltered =
              prev.newGroupChatMessageCounter.filter(
                (item) =>
                  item.chatsSpaceId !== newGroupChatMessageCounter.chatsSpaceId,
              );

            return {
              ...prev,
              newGroupChatMessageCounter: [
                ...groupChatMessageCountersFiltered,
                newGroupChatMessageCounter,
              ],
            };
          },
        );
      },
    );

    return () => {
      notificationsSocket.off('groupChatMessageCounter');
    };
  }, [brandId, queryClient]);

  useEffect(() => {
    notificationsSocket.on(
      'courseCounter',
      (newCourseCounter: NewCourseCounterModel) => {
        queryClient.setQueryData<NotificationCounters>(
          getNotificationsControllerGetNotificationCountersForBrandQueryKey(
            brandId,
          ),
          (prev: NotificationCounters | undefined) => {
            if (!prev)
              return {
                newCourseCounter: [newCourseCounter],
                newDiscussionCounter: [],
                newEventCounter: [],
                newGroupChatMessageCounter: [],
                newPostCounter: [],
              };

            const courseCountersFiltered = prev.newCourseCounter.filter(
              (item) => item.coursesSpaceId !== newCourseCounter.coursesSpaceId,
            );

            return {
              ...prev,
              newCourseCounter: [...courseCountersFiltered, newCourseCounter],
            };
          },
        );
      },
    );

    return () => {
      notificationsSocket.off('courseCounter');
    };
  }, [brandId, queryClient]);

  useEffect(() => {
    notificationsSocket.on(
      'discussionCounter',
      (newDiscussionCounter: NewDiscussionCounterModel) => {
        queryClient.setQueryData<NotificationCounters>(
          getNotificationsControllerGetNotificationCountersForBrandQueryKey(
            brandId,
          ),
          (prev: NotificationCounters | undefined) => {
            if (!prev)
              return {
                newCourseCounter: [],
                newDiscussionCounter: [newDiscussionCounter],
                newEventCounter: [],
                newGroupChatMessageCounter: [],
                newPostCounter: [],
              };

            const discussionCountersFiltered = prev.newDiscussionCounter.filter(
              (item) => item.discussionId !== newDiscussionCounter.discussionId,
            );

            return {
              ...prev,
              newDiscussionCounter: [
                ...discussionCountersFiltered,
                newDiscussionCounter,
              ],
            };
          },
        );
      },
    );

    return () => {
      notificationsSocket.off('discussionCounter');
    };
  }, [brandId, queryClient]);
};
