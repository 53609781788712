export const VideoIcon = ({ className }: { className?: string }) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 10L19.553 7.724C20.449 7.276 21.5 7.925 21.5 8.937V15.063C21.5 16.075 20.449 16.724 19.553 16.276L15 14M4 19H14C14.552 19 15 18.552 15 18V6C15 5.448 14.552 5 14 5H4C3.448 5 3 5.448 3 6V18C3 18.552 3.448 19 4 19Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
