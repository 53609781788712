import { useEffect, useRef, useState } from 'react';
import { z } from 'zod';

import { UpdateProfileDto, User, useUserControllerUpdateProfile } from '@/api';
import InputField from '@/components/ui/InputField';
import { Button } from '@/components/ui/button';
import { useAuth } from '@/hooks/useContext';
import { imageToBase64 } from '@/lib/utils';
import { countryList } from '@/utils/CountryProvider';

import ImageCropModal from '../../ImageCropModal';
import PasswordChangeModal from '../PasswordChangeModal';

const AccountTab = () => {
  const imageRef = useRef<HTMLInputElement>(null);
  const { user, refetchUser, getAuthType } = useAuth();

  const defaultAccountData = {
    name: user?.name || '',
    websiteUrl: user?.websiteUrl || '',
    phone: user?.phoneNumber || '',
    countryCode: user?.countryCode || '',
    countryName: user?.countryName || '',
    email: user?.email || '',
  };
  const defaultImage = user?.avatarUrl || null;

  const defaultDial =
    countryList.find(
      (country) =>
        country.idd === user?.countryCode && country.name === user?.countryName,
    ) || countryList[0];

  const [accountData, setAccountData] =
    useState<UpdateProfileDto>(defaultAccountData);

  const [phoneDial, setPhoneDial] = useState(defaultDial);
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState<File | string | null>(defaultImage);
  const [authType, setAuthType] = useState<string | null>(null);
  const [errors, setErrors] = useState<{
    name?: string;
    websiteUrl?: string;
    phone?: string;
    countryCode?: string;
    countryName?: string;
    email?: string;
  }>({});

  useEffect(() => {
    checkAuthType();
  }, []);

  const checkAuthType = async () => {
    await getAuthType().then((res) => {
      setAuthType(res);
    });
  };

  const handleUploadImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setImage(file);
      setOpen(true);
    }
  };

  const handleClickUpload = () => {
    imageRef.current?.click();
  };

  const resetForm = () => {
    setAccountData({
      name: user?.name || '',
      websiteUrl: user?.websiteUrl || '',
      phone: user?.phoneNumber || '',
      countryName: user?.countryName || '',
      email: user?.email || '',
    });
    setImage(user?.avatarUrl || null);
    setPhoneDial(
      countryList.find(
        (country) =>
          country.idd === user?.countryCode &&
          country.name === user?.countryName,
      ) || countryList[0],
    );
  };

  const resetFormWithUser = (user: User) => {
    setAccountData({
      name: user.name || '',
      websiteUrl: user.websiteUrl || '',
      phone: user.phoneNumber || '',
      email: user.email || '',
    });
    setImage(user.avatarUrl || null);
    setPhoneDial(
      countryList.find(
        (country) =>
          country.idd === user.countryCode && country.name === user.countryName,
      ) || countryList[0],
    );
  };

  const handleCancel = () => {
    resetForm();
  };

  const updateProfileMutation = useUserControllerUpdateProfile();

  const handleSaveChanges = async () => {
    setErrors({});

    let imageToSend = undefined;

    if (image === null) {
      imageToSend = undefined;
    } else if (typeof image === 'string') {
      imageToSend = undefined;
    } else {
      imageToSend = await imageToBase64(image);
    }

    let websiteUrl = accountData.websiteUrl?.trim();

    if (websiteUrl && websiteUrl !== '' && !websiteUrl.startsWith('http')) {
      websiteUrl = `https://${websiteUrl}`;
    }

    setAccountData((prev) => ({
      ...prev,
      websiteUrl: websiteUrl,
    }));

    const schema = z.object({
      name: z.string().min(1, 'Invalid name'),
      websiteUrl: z.union([
        z.string().url('Invalid website URL'),
        z.literal(''),
      ]),
      phone: z.string().min(1, 'Invalid phone number'),
      countryCode: z.string().min(1, 'Invalid country code'),
      countryName: z.string().min(1),
      email: z.string().email('Invalid email'),
    });

    const result = schema.safeParse({
      name: accountData.name,
      websiteUrl: websiteUrl,
      phone: accountData.phone,
      countryCode: phoneDial.idd,
      countryName: phoneDial.name,
      email: accountData.email,
    });

    if (!result.success) {
      setErrors({
        name: result.error.formErrors.fieldErrors.name?.[0] || '',
        websiteUrl: result.error.formErrors.fieldErrors.websiteUrl?.[0] || '',
        phone: result.error.formErrors.fieldErrors.phone?.[0] || '',
        countryCode: result.error.formErrors.fieldErrors.countryCode?.[0] || '',
        countryName: result.error.formErrors.fieldErrors.countryName?.[0] || '',
        email: result.error.formErrors.fieldErrors.email?.[0] || '',
      });
      return;
    }

    const payload: UpdateProfileDto = {
      name: result.data.name,
      image: imageToSend || undefined,
      phone: accountData.phone,
      countryCode: phoneDial.idd,
      countryName: phoneDial.name,
      websiteUrl,
    };

    try {
      await updateProfileMutation.mutateAsync({ data: payload });
    } catch (error) {
      console.error(error);
    }

    const userUpdated = await refetchUser();
    resetFormWithUser(userUpdated);
  };

  const handleClose = () => {
    setImage(defaultImage || null);
    setOpen(false);
  };

  const convertImageUrlToFile = async (url: string) => {
    const response = await fetch(url);
    const blob = await response.blob();
    const file = new File([blob], 'image.jpg', { type: 'image/jpeg' });
    return file;
  };

  const handleCroppedImage = async (croppedImage: string) => {
    const imageFile = await convertImageUrlToFile(croppedImage);
    setImage(imageFile);
    setOpen(false);
  };

  // only show buttons if the data has changed
  const showButtons =
    accountData.name !== defaultAccountData.name ||
    accountData.websiteUrl !== defaultAccountData.websiteUrl ||
    accountData.phone !== defaultAccountData.phone ||
    image !== defaultImage ||
    phoneDial.idd !== defaultDial.idd;

  return (
    <div className="flex w-full flex-col gap-10">
      <h1 className="pt-10 text-lg font-semibold">Account</h1>
      <div className="flex w-full flex-col gap-8">
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <h2 className="text-lg font-semibold">Update photo</h2>
            <p className="text-xs font-normal text-[#a3a3a3]">
              Make sure the file is below 2 Mb
            </p>
          </div>
          <div className="flex gap-2.5">
            {image && (
              <img
                src={
                  typeof image === 'string' ? image : URL.createObjectURL(image)
                }
                className="h-10 w-10 rounded-lg"
                alt="profile picture"
              />
            )}
            <Button
              variant={'ghost'}
              className="border border-light dark:border-dark-light"
              onClick={handleClickUpload}
            >
              {image ? 'Replace Image' : 'Upload file'}
            </Button>
            {image && typeof image !== 'string' && (
              <ImageCropModal
                image={URL.createObjectURL(image)}
                open={open}
                onClose={handleClose}
                onSave={handleCroppedImage}
                ratio={1 / 1}
              />
            )}
            <input
              hidden
              type="file"
              ref={imageRef}
              onChange={handleUploadImage}
              accept="image/*"
            />
          </div>
        </div>
        <InputField
          onChange={(e) =>
            setAccountData({
              ...accountData,
              email: e.target.value,
            })
          }
          type="text"
          value={accountData?.email || ''}
          title="Email"
          inputClassName="!bg-transparent border-light dark:border-dark-light"
          disabled={authType === 'google'}
        />
        {authType !== 'google' && <PasswordChangeModal />}
        <InputField
          onChange={(e) =>
            setAccountData({
              ...accountData,
              name: e.target.value,
            })
          }
          type="text"
          value={accountData?.name || ''}
          title="Fullname"
          placeholder="Enter fullname"
          inputClassName="!bg-transparent border-light dark:border-dark-light"
          error={errors.name}
        />
        <InputField
          title="Phone Number"
          placeholder="Enter your phone number"
          onChange={(e) =>
            setAccountData({
              ...accountData,
              phone: e.target.value,
            })
          }
          onChangeDial={setPhoneDial}
          dial={phoneDial}
          value={accountData.phone || ''}
          type="phone"
          inputClassName="border-light !bg-transparent dark:border-dark-light"
          error={errors.phone}
        />
        <InputField
          onChange={(e) =>
            setAccountData({
              ...accountData,
              websiteUrl: e.target.value,
            })
          }
          type="text"
          value={accountData?.websiteUrl || ''}
          title="Website URL"
          placeholder="Enter website URL"
          inputClassName="!bg-transparent border-light dark:border-dark-light"
          error={errors.websiteUrl}
        />
      </div>
      {showButtons && (
        <div className="flex justify-end gap-2.5">
          <Button
            variant={'outline'}
            onClick={handleCancel}
            disabled={updateProfileMutation.isPending}
          >
            Cancel
          </Button>
          <Button
            variant={'default'}
            onClick={handleSaveChanges}
            disabled={updateProfileMutation.isPending}
          >
            Save changes
          </Button>
        </div>
      )}
    </div>
  );
};

export default AccountTab;
