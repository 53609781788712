import { useQueryClient } from '@tanstack/react-query';
import { EllipsisIcon } from 'lucide-react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  Event,
  eventSpacesControllerDeleteEvent,
  getEventSpacesControllerGetEventsQueryKey,
} from '@/api';
import { Dialog } from '@/components/ui/dialog';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { useToast } from '@/hooks/use-toast';

import { EditEventDialogContent } from './edit-event-dialog';

type DialogOptions = 'edit' | 'delete';

export const EventsDropdownOptions = ({ event }: { event: Event }) => {
  const { eventsSpaceId } = useParams();
  const { toast } = useToast();

  const [isOpen, setIsOpen] = useState(false);
  const [dialogOption, setDialogOption] = useState<DialogOptions | null>(null);

  const closeDialog = () => {
    setIsOpen(false);
    setDialogOption(null);
  };

  const queryClient = useQueryClient();

  const handleDeleteEvent = async () => {
    setIsOpen(false);
    setDialogOption(null);
    try {
      eventSpacesControllerDeleteEvent(eventsSpaceId!, event.id).then(() => {
        toast({
          title: 'Event deleted',
          variant: 'success',
        });
        queryClient.invalidateQueries({
          queryKey: getEventSpacesControllerGetEventsQueryKey(eventsSpaceId!),
        });
      });
    } catch (error) {
      toast({
        title: 'Error deleting event',
        variant: 'destructive',
      });
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DropdownMenu>
        <DropdownMenuTrigger>
          <EllipsisIcon />
        </DropdownMenuTrigger>
        <DropdownMenuContent
          onClick={(e) => {
            e.stopPropagation();
          }}
          side="bottom"
          align="end"
        >
          <DropdownMenuItem
            className="font-medium text-[#3B82F6] focus:text-[#3B82F6]"
            onSelect={() => {
              setDialogOption('edit');
              setIsOpen(true);
            }}
          >
            Edit event
          </DropdownMenuItem>
          <DropdownMenuItem
            className="font-medium text-error"
            onSelect={handleDeleteEvent}
          >
            Delete event
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      {dialogOption === 'edit' && (
        <EditEventDialogContent
          key={isOpen.toString()}
          event={event}
          closeDialog={closeDialog}
        />
      )}
    </Dialog>
  );
};
