/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * Teachly API V2
 * Teachly API documentation
 * OpenAPI spec version: 1.0
 */
import { useMutation } from '@tanstack/react-query';
import type {
  MutationFunction,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';

import { customFetch } from '../../../mutator/custom-fetch';
import type {
  CreateBrandInviteDto,
  CreateSpaceGroupInviteDto,
  InvitationsControllerAcceptInvitationBody,
  ProcessInviteResponseDto,
  SuccessMessageResponseDto,
} from '../../model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Create and send a brand invitation
 */
export const invitationsControllerCreateBrandInvite = (
  createBrandInviteDto: CreateBrandInviteDto,
  options?: SecondParameter<typeof customFetch>,
) => {
  return customFetch<SuccessMessageResponseDto>(
    {
      url: `/invitations/brand`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createBrandInviteDto,
    },
    options,
  );
};

export const getInvitationsControllerCreateBrandInviteMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof invitationsControllerCreateBrandInvite>>,
    TError,
    { data: CreateBrandInviteDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof invitationsControllerCreateBrandInvite>>,
  TError,
  { data: CreateBrandInviteDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof invitationsControllerCreateBrandInvite>>,
    { data: CreateBrandInviteDto }
  > = (props) => {
    const { data } = props ?? {};

    return invitationsControllerCreateBrandInvite(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type InvitationsControllerCreateBrandInviteMutationResult = NonNullable<
  Awaited<ReturnType<typeof invitationsControllerCreateBrandInvite>>
>;
export type InvitationsControllerCreateBrandInviteMutationBody =
  CreateBrandInviteDto;
export type InvitationsControllerCreateBrandInviteMutationError = void;

/**
 * @summary Create and send a brand invitation
 */
export const useInvitationsControllerCreateBrandInvite = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof invitationsControllerCreateBrandInvite>>,
    TError,
    { data: CreateBrandInviteDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof invitationsControllerCreateBrandInvite>>,
  TError,
  { data: CreateBrandInviteDto },
  TContext
> => {
  const mutationOptions =
    getInvitationsControllerCreateBrandInviteMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Create and send a space group invitation
 */
export const invitationsControllerCreateSpaceGroupInvite = (
  createSpaceGroupInviteDto: CreateSpaceGroupInviteDto,
  options?: SecondParameter<typeof customFetch>,
) => {
  return customFetch<SuccessMessageResponseDto>(
    {
      url: `/invitations/space-group`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createSpaceGroupInviteDto,
    },
    options,
  );
};

export const getInvitationsControllerCreateSpaceGroupInviteMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof invitationsControllerCreateSpaceGroupInvite>>,
    TError,
    { data: CreateSpaceGroupInviteDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof invitationsControllerCreateSpaceGroupInvite>>,
  TError,
  { data: CreateSpaceGroupInviteDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof invitationsControllerCreateSpaceGroupInvite>>,
    { data: CreateSpaceGroupInviteDto }
  > = (props) => {
    const { data } = props ?? {};

    return invitationsControllerCreateSpaceGroupInvite(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type InvitationsControllerCreateSpaceGroupInviteMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof invitationsControllerCreateSpaceGroupInvite>>
  >;
export type InvitationsControllerCreateSpaceGroupInviteMutationBody =
  CreateSpaceGroupInviteDto;
export type InvitationsControllerCreateSpaceGroupInviteMutationError = void;

/**
 * @summary Create and send a space group invitation
 */
export const useInvitationsControllerCreateSpaceGroupInvite = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof invitationsControllerCreateSpaceGroupInvite>>,
    TError,
    { data: CreateSpaceGroupInviteDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof invitationsControllerCreateSpaceGroupInvite>>,
  TError,
  { data: CreateSpaceGroupInviteDto },
  TContext
> => {
  const mutationOptions =
    getInvitationsControllerCreateSpaceGroupInviteMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Process an invitation
 */
export const invitationsControllerAcceptInvitation = (
  invitationsControllerAcceptInvitationBody: InvitationsControllerAcceptInvitationBody,
  options?: SecondParameter<typeof customFetch>,
) => {
  return customFetch<ProcessInviteResponseDto>(
    {
      url: `/invitations/accept`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: invitationsControllerAcceptInvitationBody,
    },
    options,
  );
};

export const getInvitationsControllerAcceptInvitationMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof invitationsControllerAcceptInvitation>>,
    TError,
    { data: InvitationsControllerAcceptInvitationBody },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof invitationsControllerAcceptInvitation>>,
  TError,
  { data: InvitationsControllerAcceptInvitationBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof invitationsControllerAcceptInvitation>>,
    { data: InvitationsControllerAcceptInvitationBody }
  > = (props) => {
    const { data } = props ?? {};

    return invitationsControllerAcceptInvitation(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type InvitationsControllerAcceptInvitationMutationResult = NonNullable<
  Awaited<ReturnType<typeof invitationsControllerAcceptInvitation>>
>;
export type InvitationsControllerAcceptInvitationMutationBody =
  InvitationsControllerAcceptInvitationBody;
export type InvitationsControllerAcceptInvitationMutationError = void;

/**
 * @summary Process an invitation
 */
export const useInvitationsControllerAcceptInvitation = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof invitationsControllerAcceptInvitation>>,
    TError,
    { data: InvitationsControllerAcceptInvitationBody },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof invitationsControllerAcceptInvitation>>,
  TError,
  { data: InvitationsControllerAcceptInvitationBody },
  TContext
> => {
  const mutationOptions =
    getInvitationsControllerAcceptInvitationMutationOptions(options);

  return useMutation(mutationOptions);
};
