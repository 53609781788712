import { useQueryClient } from '@tanstack/react-query';
import { EllipsisIcon } from 'lucide-react';
import { useState } from 'react';

import {
  PostWithUserAndRoleDto,
  getPostsSpacesControllerGetPostsInSpaceQueryKey,
  usePostsSpacesControllerDeletePost,
  usePostsSpacesControllerTogglePinPost,
} from '@/api';
import { UpdatePostDialogContent } from '@/components/post-components/update-post-dialog';
import { Dialog } from '@/components/ui/dialog';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { useBrandPermits } from '@/hooks/useBrandPermits';
import { useAuth } from '@/hooks/useContext';

type DialogOptions = 'edit' | 'delete';

export const PostDropdownOptions = ({
  post,
  spaceGroupId,
}: {
  post: PostWithUserAndRoleDto;
  spaceGroupId: string;
}) => {
  const { user } = useAuth();
  const hasBrandPermission = useBrandPermits();
  const [isOpen, setIsOpen] = useState(false);
  const [dialogOption, setDialogOption] = useState<DialogOptions | null>(null);

  const closeDialog = () => {
    setIsOpen(false);
    setDialogOption(null);
  };

  const queryClient = useQueryClient();
  const togglePinPostMutation = usePostsSpacesControllerTogglePinPost({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: getPostsSpacesControllerGetPostsInSpaceQueryKey(
            post.postsSpaceId,
          ),
        });
      },
    },
  });
  const deletePostMutation = usePostsSpacesControllerDeletePost({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: getPostsSpacesControllerGetPostsInSpaceQueryKey(
            post.postsSpaceId,
          ),
        });
      },
    },
  });

  const handleTogglePinPost = () => {
    togglePinPostMutation.mutate({
      postId: post.id,
      postsSpaceId: post.postsSpaceId,
      data: {
        isPinned: !post.isPinned,
      },
    });
  };

  const handleDeletePost = () => {
    deletePostMutation.mutate({
      postId: post.id,
    });
  };

  const isMyPost = post.user.id === user?.id;

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DropdownMenu>
        <DropdownMenuTrigger>
          <EllipsisIcon />
        </DropdownMenuTrigger>
        <DropdownMenuContent side="bottom" align="end">
          {isMyPost && (
            <DropdownMenuItem
              className="font-medium text-[#3B82F6] focus:text-[#3B82F6]"
              onSelect={() => {
                setDialogOption('edit');
                setIsOpen(true);
              }}
            >
              Edit post
            </DropdownMenuItem>
          )}

          {hasBrandPermission && (
            <>
              <DropdownMenuItem
                className="font-medium text-black focus:text-black dark:text-white dark:focus:text-white"
                onSelect={handleTogglePinPost}
              >
                {post.isPinned ? 'Unpin post' : 'Pin post'}
              </DropdownMenuItem>
              <DropdownMenuItem
                className="font-medium text-error"
                onSelect={handleDeletePost}
              >
                Delete post
              </DropdownMenuItem>
            </>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
      {dialogOption === 'edit' && (
        <UpdatePostDialogContent
          key={isOpen.toString()}
          post={post}
          spaceGroupId={spaceGroupId}
          closeDialog={closeDialog}
        />
      )}
    </Dialog>
  );
};
