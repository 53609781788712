export const ClipboardClipIcon = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="clipboard-attachment">
      <path
        id="Icon"
        d="M5.33203 3.33337C4.67313 3.33388 4.32776 3.34225 4.06004 3.47866C3.80916 3.60649 3.60519 3.81046 3.47736 4.06135C3.33203 4.34656 3.33203 4.71993 3.33203 5.46667V11.8667C3.33203 12.6134 3.33203 12.9868 3.47736 13.272C3.60519 13.5229 3.80916 13.7268 4.06004 13.8547C4.34526 14 4.71863 14 5.46536 14H10.532C11.2788 14 11.6521 14 11.9374 13.8547C12.1882 13.7268 12.3922 13.5229 12.52 13.272C12.6654 12.9868 12.6654 12.6134 12.6654 11.8667V5.46667C12.6654 4.71993 12.6654 4.34656 12.52 4.06135C12.3922 3.81046 12.1882 3.60649 11.9374 3.47866C11.6696 3.34225 11.3243 3.33388 10.6654 3.33337M5.33203 3.33337V4.66667H10.6654V3.33337M5.33203 3.33337V3.13807C5.33203 2.83624 5.45193 2.54676 5.66536 2.33333C5.87879 2.1199 6.16827 2 6.4701 2H9.52729C9.82913 2 10.1186 2.1199 10.332 2.33333C10.5455 2.54676 10.6654 2.83624 10.6654 3.13807V3.33337M7.9987 10.3333V7.33333C7.9987 6.78105 8.44641 6.33333 8.9987 6.33333C9.55098 6.33333 9.9987 6.78105 9.9987 7.33333V10.3333C9.9987 11.4379 9.10327 12.3333 7.9987 12.3333C6.89413 12.3333 5.9987 11.4379 5.9987 10.3333V7.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
