export const MapPinIcon = (props) => (
  <svg
    viewBox="0 0 12 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6 13.4337L9.2998 10.1338C11.1223 8.31138 11.1223 5.3566 9.2998 3.53415C7.4774 1.7117 4.52261 1.7117 2.70017 3.53415C0.87772 5.3566 0.87772 8.31138 2.70017 10.1338L6 13.4337ZM6 15.3193L1.75736 11.0767C-0.585787 8.73345 -0.585787 4.93449 1.75736 2.59134C4.10051 0.248198 7.89947 0.248198 10.2427 2.59134C12.5858 4.93449 12.5858 8.73345 10.2427 11.0767L6 15.3193ZM6 8.16732C6.7364 8.16732 7.33333 7.57038 7.33333 6.83398C7.33333 6.0976 6.7364 5.50065 6 5.50065C5.2636 5.50065 4.66667 6.0976 4.66667 6.83398C4.66667 7.57038 5.2636 8.16732 6 8.16732ZM6 9.50065C4.52724 9.50065 3.33333 8.30672 3.33333 6.83398C3.33333 5.36122 4.52724 4.16732 6 4.16732C7.47273 4.16732 8.66667 5.36122 8.66667 6.83398C8.66667 8.30672 7.47273 9.50065 6 9.50065Z"
      // fill="#10151D"
    />
  </svg>
);
