import { io } from 'socket.io-client';

import { env } from '@/env';

const BASE_URL = env.VITE_API_URL;

const socketUrl = new URL('/notifications', BASE_URL).toString();

export const notificationsSocket: ReturnType<typeof io> = io(socketUrl, {
  autoConnect: false,
  withCredentials: true,
  timeout: 120_000, // 2 minutes
  reconnection: true,
  reconnectionAttempts: Infinity,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  randomizationFactor: 0.5,
});

// Add heartbeat response
notificationsSocket.on('ping', () => {
  notificationsSocket.emit('pong');
});

notificationsSocket.on('connect', () => {
  console.log('Socket connected successfully');
});

notificationsSocket.on('connect_error', (error) => {
  console.error('Socket connection error:', error);
});

// Enhanced logging
notificationsSocket.on('disconnect', (reason) => {
  console.log('Socket disconnected:', reason);
  if (reason !== 'io client disconnect') {
    notificationsSocket.connect();
  }
});

notificationsSocket.on('error', (error) => {
  console.error('Socket error:', error);
});
