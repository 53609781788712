export const EyeSlashIcon = (props) => (
  <svg
    width="24"
    height="24"
    // opacity="40%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3 3L21 21M9.84428 9.91364C9.32164 10.4536 9 11.1892 9 12C9 13.6569 10.3431 15 12 15C12.8225 15 13.5677 14.669 14.1096 14.133M6.5 6.64715C4.60069 7.90034 3.15403 9.78394 2.45801 12C3.73226 16.0571 7.52256 19 12.0002 19C13.9891 19 15.8424 18.4194 17.3998 17.4184M11 5.04939C11.329 5.01673 11.6626 5 12.0002 5C16.4778 5 20.2681 7.94291 21.5424 12C21.2616 12.894 20.8587 13.7338 20.3532 14.5"
      // stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
