interface SendMessageIconProps extends React.SVGProps<SVGSVGElement> {
  className: string;
}

export const SendMessageIcon = (props: SendMessageIconProps) => (
  <svg
    width={20}
    height={16}
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.16602 14.6673H3.33268C2.41221 14.6673 1.66602 13.9212 1.66602 13.0007V3.00065C1.66602 2.08018 2.41221 1.33398 3.33268 1.33398H16.666C17.5865 1.33398 18.3327 2.08018 18.3327 3.00065V8.00065"
      //   stroke="black"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.1673 10.5L12.084 12.5833L14.1673 14.6667M18.334 12.5833H12.5913"
      //   stroke="black"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 4.66602L9.16025 7.09285C9.67917 7.39552 10.3208 7.39552 10.8397 7.09285L15 4.66602"
      //   stroke="black"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
