import { useQueryClient } from '@tanstack/react-query';
import { Link, Navigate, useParams } from 'react-router-dom';

import {
  CourseLesson,
  CourseWithLessons,
  getCoursesSpacesControllerGetLessonProgressQueryKey,
  useCoursesSpacesControllerGetCourseWithLessonsByCourseId,
  useCoursesSpacesControllerGetLessonProgress,
  useCoursesSpacesControllerMarkLessonAsCompleted,
} from '@/api';
import { ArrowLeftAltIcon } from '@/assets/icon/arrowLeftAlt';
import { CheckIcon } from '@/assets/icon/check-alt';
import { ClipboardClipIcon } from '@/assets/icon/clipboardClip';
import { DownloadIcon } from '@/assets/icon/download';
import { Loading } from '@/components/Loading';
import { useUpdateConfigHeader } from '@/components/config-header-provider';
import { Button } from '@/components/ui/button';
import CourseLessonsProgress from '@/components/ui/courses-components/CourseLessonsProgress';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { parseVideoLink } from '@/utils/videoUtils';

const LessonPage = () => {
  const { brandId, spaceGroupId, courseId, lessonId } = useParams();

  const courseQuery = useCoursesSpacesControllerGetCourseWithLessonsByCourseId(
    courseId as string,
  );

  if (courseQuery.isLoading) {
    return <Loading />;
  }

  if (courseQuery.isError) {
    throw new Error(
      `Error fetching course with lessons for course id ${courseId}, ${courseQuery.error}`,
    );
  }

  const course = courseQuery.data;

  if (!course) {
    throw new Error(
      `No course found for course id ${courseId}, ${courseQuery.error}`,
    );
  }

  const lesson = course.lessons.find((lesson) => lesson.id === lessonId);

  if (!lesson) {
    return <Navigate to="/404" />;
  }

  return (
    <LessonPageInner
      lesson={lesson}
      course={course}
      brandId={brandId as string}
      spaceGroupId={spaceGroupId as string}
    />
  );
};

const LessonPageInner = ({
  lesson,
  course,
  brandId,
  spaceGroupId,
}: {
  lesson: CourseLesson;
  course: CourseWithLessons;
  brandId: string;
  spaceGroupId: string;
}) => {
  useUpdateConfigHeader(
    {
      showNotifications: true,
      action: null,
      hidden: true,
    },
    [],
  );

  const queryClient = useQueryClient();
  const markLessonAsCompletedMutation =
    useCoursesSpacesControllerMarkLessonAsCompleted({
      mutation: {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: getCoursesSpacesControllerGetLessonProgressQueryKey(
              lesson.id,
            ),
          });
        },
      },
    });

  const lessonProgressQuery = useCoursesSpacesControllerGetLessonProgress(
    lesson.id,
  );

  const isLessonCompleted = lessonProgressQuery.data?.completed ?? true;

  return (
    <div className="mb-10 flex w-full gap-8 overflow-y-auto p-6 lg:p-10">
      <div className="flex w-full flex-col gap-7.5 pb-7.5">
        <Link
          to={`/brands/${brandId}/space-groups/${spaceGroupId}/courses-spaces/${course.coursesSpaceId}/courses/${course.id}`}
          className="flex w-fit items-center gap-2.5"
        >
          <ArrowLeftAltIcon className="h-6 w-6 stroke-black dark:stroke-white" />
          <span className="text-black dark:text-white">Back to course</span>
        </Link>
        <div className="flex w-full flex-col xl:flex-row xl:gap-10">
          <div className="flex w-full flex-col gap-[18px]">
            {lesson.videoLink ? (
              <iframe
                src={parseVideoLink(lesson.videoLink)}
                className="aspect-[16/9] w-full rounded-lg"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                allowFullScreen
                frameBorder="0"
              />
            ) : (
              <div className="flex aspect-[16/9] w-full items-center justify-center rounded-lg bg-light-2 dark:bg-dark-2">
                No video available
              </div>
            )}
            <div className="flex w-full flex-col gap-6">
              <div className="text-2xl font-medium">{course.name}</div>
              {/* {!isLessonCompleted && ( */}
              <Button
                variant={'default'}
                className="flex w-fit gap-2.5 rounded-lg bg-light-2 px-4 py-2.5 text-base font-medium text-black dark:bg-dark-2 dark:text-white"
                onClick={() => {
                  markLessonAsCompletedMutation.mutate({
                    lessonId: lesson.id,
                  });
                }}
                disabled={isLessonCompleted}
              >
                {isLessonCompleted ? 'Lesson completed' : 'Complete Lesson'}
                <CheckIcon className="h-5 w-5 stroke-black dark:stroke-white" />
              </Button>
              {/* )} */}
            </div>
          </div>

          <CourseLessonsProgress
            course={course}
            currentLesson={lesson}
            brandId={brandId}
            spaceGroupId={spaceGroupId}
            coursesSpaceId={course.coursesSpaceId}
            courseId={course.id}
          />
        </div>

        <Tabs defaultValue="about" className="flex w-full flex-col">
          <TabsList className="flex justify-start rounded-none border-b border-light !bg-transparent p-0 dark:border-dark-light">
            <TabsTrigger
              value="about"
              className="w-fit rounded-none py-2.5 text-base data-[state=active]:-mb-[1px] data-[state=active]:border-b-2 data-[state=active]:border-black data-[state=active]:bg-transparent data-[state=active]:!text-black data-[state=active]:dark:border-white data-[state=active]:dark:!text-white"
            >
              About
            </TabsTrigger>
            <TabsTrigger
              value="files"
              className="w-fit rounded-none py-2.5 text-base data-[state=active]:-mb-[1px] data-[state=active]:border-b-2 data-[state=active]:border-black data-[state=active]:bg-transparent data-[state=active]:!text-black data-[state=active]:dark:border-white data-[state=active]:dark:!text-white"
            >
              Files
            </TabsTrigger>
          </TabsList>
          <TabsContent
            value="about"
            className="whitespace-pre-wrap p-5 text-base leading-relaxed"
          >
            {lesson.text || 'No content available'}
          </TabsContent>
          <TabsContent value="files">
            <div className="flex flex-col gap-4 p-2.5">
              {lesson.files.length > 0 ? (
                lesson.files.map((file) => (
                  <a
                    key={file.id}
                    href={file.url}
                    download={file.name}
                    target="_blank"
                    className="flex items-center justify-between gap-4 rounded-md bg-light-2 p-4 dark:bg-dark-2"
                  >
                    <p className="flex items-center gap-2.5 text-lg font-medium">
                      <ClipboardClipIcon className="h-5 w-5 stroke-black dark:stroke-white" />
                      {file.name}
                    </p>
                    <DownloadIcon className="h-5 w-5 stroke-black dark:stroke-white" />
                  </a>
                ))
              ) : (
                <p className="p-2.5 text-base leading-relaxed">
                  No files available
                </p>
              )}
            </div>
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
};

export default LessonPage;
