import { zodResolver } from '@hookform/resolvers/zod';
import { XIcon } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { CreateSpaceGroupDto } from '@/api';
import { ArrowLeftIcon } from '@/assets/icon/arrowLeft';
import { Button } from '@/components/ui/button';
import { DialogHeader, DialogTitle } from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormInput,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { cn, onlyFirstLetterCapital } from '@/lib/utils';

const pricings = ['FREE', 'PAID'] as const;

const billingFrequencies = ['one-time', 'weekly', 'monthly'] as const;

const mapServerBillingFrequencyToClient = (
  billingFrequency: string | null | undefined,
) => {
  switch (billingFrequency) {
    case 'ONE_TIME_PAYMENT':
      return 'one-time';
    case 'WEEKLY':
      return 'weekly';
    case 'MONTHLY':
      return 'monthly';
    default:
      return undefined;
  }
};

const formSchema = z
  .object({
    name: z.string().min(1, {
      message: 'Name is required',
    }),
    pricing: z.enum(pricings).default(pricings[0]),
    price: z.coerce
      .number({
        message: 'Price must be a number',
      })
      .optional(),
    billingFrequency: z.enum(billingFrequencies).optional(),
  })
  .refine(
    (data) => {
      if (data.pricing === 'PAID') {
        return data.billingFrequency;
      }
      return true;
    },
    {
      message: 'Billing frequency is required for paid plans',
      path: ['billingFrequency'],
    },
  )
  .refine(
    (data) => {
      return data.pricing === 'FREE' || data.price;
    },
    {
      message: 'Price is required for paid plans',
      path: ['price'],
    },
  );

type FormSchema = z.infer<typeof formSchema>;

export const CreateNewSpaceGroupDialogContent = ({
  handleContinue,
  onClose,
  brandId,
  setData,
  initialData,
  handleBack,
}: {
  handleContinue: () => void;
  onClose: () => void;
  brandId: string;
  setData: (data: CreateSpaceGroupDto) => void;
  initialData: CreateSpaceGroupDto | undefined;
  handleBack: () => void;
}) => {
  const form = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    defaultValues: initialData
      ? {
          name: initialData.name,
          pricing: initialData.pricingType,
          price: initialData.price,
          billingFrequency: mapServerBillingFrequencyToClient(
            initialData.billingFrequency,
          ),
        }
      : {
          name: '',
          pricing: pricings[0],
          price: undefined,
          billingFrequency: billingFrequencies[0],
        },
  });

  const handleSubmit = form.handleSubmit(async (data) => {
    const pricingType = data.pricing;
    const isPaid = pricingType === 'PAID';
    const price = isPaid ? data.price : undefined;

    const mapBillingFrequency = (
      billingFrequency: string | null | undefined,
    ) => {
      switch (billingFrequency) {
        case 'one-time':
          return 'ONE_TIME_PAYMENT';
        case 'weekly':
          return 'WEEKLY';
        case 'monthly':
          return 'MONTHLY';
        default:
          return undefined;
      }
    };

    const billingFrequency = isPaid
      ? mapBillingFrequency(data.billingFrequency)
      : null;

    setData({
      brandId,
      isPrivate: false,
      name: data.name,
      pricingType,
      price,
      // @ts-expect-error - billingFrequency can be null to remove the field
      billingFrequency,
    });
    handleContinue();
  });

  return (
    <>
      <DialogHeader className="flex-row items-center justify-between space-y-0">
        <DialogTitle className="flex items-center gap-2.5 text-2xl font-semibold">
          <Button variant="icon" size="icon" onClick={handleBack}>
            <ArrowLeftIcon className="h-6 w-6 cursor-pointer fill-black dark:fill-white" />
          </Button>
          Create space group
        </DialogTitle>
        <Button
          onClick={onClose}
          variant="icon"
          size="icon"
          className="m-0 rounded-sm p-0 ring-offset-background transition-opacity focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
        >
          <XIcon className="h-4 w-4" />
          <span className="sr-only">Close</span>
        </Button>
      </DialogHeader>
      <div className="font-medium text-[#9E9E9E] dark:text-[#AEAEAE]">
        <Form {...form}>
          <form onSubmit={handleSubmit} className="space-y-5">
            <FormField
              control={form.control}
              name="name"
              render={({ field, fieldState }) => (
                <FormItem className="space-y-1">
                  <FormLabel
                    className={cn(
                      'text-base font-medium text-black dark:text-white',
                      fieldState.error &&
                        'text-destructive dark:text-destructive',
                    )}
                  >
                    Name
                  </FormLabel>
                  <FormControl>
                    <FormInput
                      type="text"
                      placeholder="Enter space group name"
                      className={cn(
                        'p-3 text-base font-normal text-black shadow-sm placeholder:text-[#9E9E9E] dark:text-white dark:placeholder:text-[#AEAEAE]',
                      )}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="pricing"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-base font-medium text-black dark:text-white">
                    Pricing
                  </FormLabel>
                  <FormControl>
                    <div className="space-x-2">
                      {pricings.map((pricing) => (
                        <Button
                          type="button"
                          key={pricing}
                          onClick={() => field.onChange(pricing)}
                          variant={
                            field.value === pricing ? 'default' : 'outline'
                          }
                        >
                          <span>{onlyFirstLetterCapital(pricing)}</span>
                        </Button>
                      ))}
                    </div>
                  </FormControl>

                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="price"
              render={({ field, fieldState }) => (
                <FormItem
                  className={cn(
                    'space-y-1',
                    form.watch('pricing') === 'FREE' && 'hidden',
                  )}
                >
                  <FormLabel
                    className={cn(
                      'text-base font-medium text-black dark:text-white',
                      fieldState.error &&
                        'text-destructive dark:text-destructive',
                    )}
                  >
                    Price
                  </FormLabel>
                  <FormControl>
                    <FormInput
                      type="text"
                      placeholder="100"
                      className={cn(
                        'p-3 text-base font-normal text-black shadow-sm placeholder:text-[#9E9E9E] dark:text-white dark:placeholder:text-[#AEAEAE]',
                      )}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="billingFrequency"
              render={({ field, fieldState }) => (
                <FormItem
                  className={cn(
                    'space-y-1',
                    form.watch('pricing') === 'FREE' && 'hidden',
                  )}
                >
                  <FormLabel
                    className={cn(
                      'text-base font-medium text-black dark:text-white',
                      fieldState.error &&
                        'text-destructive dark:text-destructive',
                    )}
                  >
                    Billing frequency
                  </FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger
                        className={cn(
                          'h-12 text-base text-black dark:text-white',
                          fieldState.error &&
                            'border-destructive dark:border-destructive',
                        )}
                      >
                        <SelectValue />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {billingFrequencies.map((billingFrequency) => (
                        <SelectItem
                          key={billingFrequency}
                          value={billingFrequency}
                        >
                          {onlyFirstLetterCapital(billingFrequency)} Payment
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Button type="submit" className="w-full rounded-lg">
              Continue
            </Button>
          </form>
        </Form>
      </div>
    </>
  );
};
