import { google } from '@/assets/icon';
import { MailPencilIcon } from '@/assets/icon/mail-pencil';
import Divider from '@/components/ui/Divider';
import InputField from '@/components/ui/InputField';
import { Button } from '@/components/ui/button';

interface AuthOptionsSelectPageProps {
  email: string;
  setEmail: (email: string) => void;
  handleValidateEmail: () => void;
  error: string;
  signInWithGoogle: () => void;
  isLoading: boolean;
}

const AuthOptionsSelectPage: React.FC<AuthOptionsSelectPageProps> = ({
  email,
  setEmail,
  handleValidateEmail,
  error,
  signInWithGoogle,
  isLoading,
}) => {
  return (
    <div className="flex w-full flex-col items-center gap-10 rounded-2xl border border-light bg-white px-4 py-6 shadow dark:border-dark-light dark:bg-dark-white sm:w-125 sm:p-10">
      <div className="flex flex-col gap-4">
        <h1 className="text-center text-5xl font-bold text-brand">Teachly</h1>
        <p className="w-full text-center text-base font-normal text-textParagraph">
          Enter your email to continue
        </p>
      </div>
      <div className="flex w-full flex-col gap-4">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleValidateEmail();
          }}
        >
          <div className="flex w-full flex-col gap-6">
            <InputField
              autoFocus={true}
              title="Email"
              placeholder="Enter your email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              error={error}
              type="email"
              startIcon={
                <MailPencilIcon className="stroke-black dark:stroke-white" />
              }
            />
            <Button
              type="submit"
              variant="default"
              className="p-8 text-lg font-normal"
              disabled={isLoading}
            >
              Continue
            </Button>
          </div>
        </form>
        <Divider text="or" />
        <Button
          onClick={signInWithGoogle}
          variant="secondary"
          className="flex w-full items-center justify-center gap-2 p-8 text-lg font-normal"
          disabled={isLoading}
        >
          <img src={google} className="h-6 w-6" />
          Continue with Google
        </Button>
      </div>
      <div className="flex flex-col items-center text-center text-base text-textParagraph">
        <p>By continuing, you agree to the Teachly&rsquo;s</p>
        <div className="flex gap-2">
          <a
            href="https://teachlyai.notion.site/Files-9b0769d44bb240c5b298995a5edb6adc"
            className="text-brand"
          >
            Terms of Service
          </a>
          <p>and</p>
          <a
            href="https://teachlyai.notion.site/Files-9b0769d44bb240c5b298995a5edb6adc"
            className="text-brand"
          >
            Privacy Policy
          </a>
        </div>
      </div>
    </div>
  );
};

export default AuthOptionsSelectPage;
