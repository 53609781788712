import { useQueries, useQueryClient } from '@tanstack/react-query';
import { EllipsisVerticalIcon } from 'lucide-react';
import { useEffect, useState } from 'react';
import SortableList, { SortableItem } from 'react-easy-sort';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';

import {
  CourseLesson,
  CourseWithLessons,
  coursesSpacesControllerGetLessonProgress,
  getCoursesSpacesControllerGetCourseWithLessonsByCourseIdQueryKey,
  getCoursesSpacesControllerGetLessonProgressQueryKey,
  useCoursesSpacesControllerDeleteCourseLesson,
  useCoursesSpacesControllerGetCourseWithLessonsByCourseId,
  useCoursesSpacesControllerOrganizeLessons,
  useCoursesSpacesControllerTogglePublishCourse,
} from '@/api';
import { ArrowLeftAltIcon } from '@/assets/icon/arrowLeftAlt';
import { ArrowRightIcon } from '@/assets/icon/arrowRight';
import { CheckRoundedIcon } from '@/assets/icon/check';
import { Loading } from '@/components/Loading';
import { useUpdateConfigHeader } from '@/components/config-header-provider';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { useBrandPermits } from '@/hooks/useBrandPermits';

const CourseContentPage = () => {
  const { brandId, spaceGroupId, coursesSpaceId, courseId } = useParams();

  const courseQuery = useCoursesSpacesControllerGetCourseWithLessonsByCourseId(
    courseId as string,
  );

  if (courseQuery.isLoading) {
    return <Loading />;
  }

  if (courseQuery.isError) {
    return <Navigate to="/404" />;
  }

  const course = courseQuery.data;

  if (!course) {
    throw new Error(
      `No course found for course id ${courseId}, ${courseQuery.error}`,
    );
  }

  return (
    <CourseContentPageInner
      course={course}
      brandId={brandId as string}
      spaceGroupId={spaceGroupId as string}
      coursesSpaceId={coursesSpaceId as string}
    />
  );
};

const CourseContentPageInner = ({
  course,
  brandId,
  spaceGroupId,
  coursesSpaceId,
}: {
  course: CourseWithLessons;
  brandId: string;
  spaceGroupId: string;
  coursesSpaceId: string;
}) => {
  const hasPermits = useBrandPermits();
  useUpdateConfigHeader(
    {
      action: null,
    },
    [],
  );
  const navigate = useNavigate();
  const [editMode, setEditMode] = useState<boolean>(() => {
    if (!hasPermits) {
      return false;
    }
    return course.isPublished ? false : true;
  });
  const isEditing = hasPermits && editMode;
  const [lessonList, setLessonList] = useState<CourseLesson[]>([]);

  useEffect(() => {
    setLessonList(course.lessons);
  }, [course.lessons]);

  const queryClient = useQueryClient();

  const organizeLessonsMutation = useCoursesSpacesControllerOrganizeLessons({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey:
            getCoursesSpacesControllerGetCourseWithLessonsByCourseIdQueryKey(
              course.id,
            ),
        });
      },
    },
  });

  const lessonProgressQuery = useQueries({
    queries: lessonList.map((lesson) => ({
      queryKey: getCoursesSpacesControllerGetLessonProgressQueryKey(lesson.id),
      queryFn: () => coursesSpacesControllerGetLessonProgress(lesson.id),
    })),
  });

  const lessonProgress = lessonProgressQuery.map((query, index) => {
    return {
      completed: query.data?.completed ?? false,
      lessonId: lessonList[index].id,
    };
  });

  const deleteLessonMutation = useCoursesSpacesControllerDeleteCourseLesson({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey:
            getCoursesSpacesControllerGetCourseWithLessonsByCourseIdQueryKey(
              course.id,
            ),
        });
      },
    },
  });

  const togglePublishCourseMutation =
    useCoursesSpacesControllerTogglePublishCourse({
      mutation: {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey:
              getCoursesSpacesControllerGetCourseWithLessonsByCourseIdQueryKey(
                course.id,
              ),
          });
        },
      },
    });

  const handleAddLesson = () => {
    navigate(
      `/brands/${brandId}/space-groups/${spaceGroupId}/courses-spaces/${coursesSpaceId}/courses/${course.id}/lessons/create`,
    );
  };

  const handleDeleteLesson = (lessonId: string) => {
    deleteLessonMutation.mutate({
      lessonId,
    });
  };

  const handleEditLesson = (lessonId: string) => {
    navigate(
      `/brands/${brandId}/space-groups/${spaceGroupId}/courses-spaces/${coursesSpaceId}/courses/${course.id}/lessons/${lessonId}/edit`,
    );
  };

  const handlePublish = () => {
    togglePublishCourseMutation.mutate({
      courseId: course.id,
    });
    setEditMode(false);
  };

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleStartLesson = (lessonId: string) => {
    navigate(
      `/brands/${brandId}/space-groups/${spaceGroupId}/courses-spaces/${coursesSpaceId}/courses/${course.id}/lessons/${lessonId}`,
    );
  };

  const saveOrderOfLessons = (lessons: CourseLesson[]) => {
    organizeLessonsMutation.mutate({
      data: {
        courseId: course.id,
        lessonIds: lessons.map((lesson) => lesson.id),
      },
    });
  };

  const handleReorderLessons = (oldIndex: number, newIndex: number) => {
    const newLessons = [...lessonList];
    const [removed] = newLessons.splice(oldIndex, 1);
    newLessons.splice(newIndex, 0, removed);
    setLessonList(newLessons);
    saveOrderOfLessons(newLessons);
  };

  const handleSave = () => {
    setEditMode(false);
  };

  return (
    <div className="flex flex-col gap-16 overflow-auto px-6 py-10 xl:px-25 xl:py-16">
      <div className="flex w-full flex-col justify-between gap-15 sm:flex-row sm:gap-0">
        <Link
          to={`/brands/${brandId}/space-groups/${spaceGroupId}/courses-spaces/${coursesSpaceId}`}
          className="flex w-fit items-center gap-2.5"
        >
          <ArrowLeftAltIcon className="h-6 w-6 stroke-black dark:stroke-white" />
          Back to courses
        </Link>
        {hasPermits &&
          (isEditing ? (
            <div className="flex gap-3.5">
              {course.isPublished && (
                <Button variant={'outline'} onClick={handleSave}>
                  Save
                </Button>
              )}
              <Button onClick={handlePublish}>
                {course.isPublished ? 'Unpublish' : 'Publish'}
              </Button>
            </div>
          ) : (
            <Button variant={'outline'} className="w-fit" onClick={handleEdit}>
              Edit course
            </Button>
          ))}
      </div>
      <div className="flex flex-col gap-6">
        {lessonList.length === 0 ? (
          <div className="flex h-90 items-center justify-center rounded-lg bg-light-2 px-6 py-10 dark:bg-dark-2">
            <div className="flex flex-col items-center gap-6">
              <div className="flex flex-col gap-4">
                <h2 className="text-center text-2xl font-semibold text-black dark:text-white">
                  Create your course content
                </h2>
                <p className="text-center text-base font-medium text-textParagraph dark:text-dark-textParagraph">
                  Start by adding a lesson.{' '}
                </p>
              </div>
              <Button className="w-fit" onClick={handleAddLesson}>
                Add lesson
              </Button>
            </div>
          </div>
        ) : (
          <div className="flex w-full flex-col gap-6">
            <div className="flex w-full items-center justify-between">
              <div className="flex w-full flex-col gap-2.5">
                <h1 className="text-2xl font-semibold">{course.name}</h1>
              </div>
              {isEditing && (
                <Button
                  variant={'outline'}
                  className="w-fit"
                  onClick={handleAddLesson}
                >
                  Add lesson
                </Button>
              )}
            </div>

            {isEditing ? (
              <SortableList
                onSortEnd={handleReorderLessons}
                className="flex flex-col gap-6"
                draggedItemClassName="dragged"
              >
                {lessonList.map((lesson) => (
                  <SortableItem key={lesson.id}>
                    <div className="flex items-center gap-2.5 rounded-xl bg-light-2 px-4 py-6 dark:bg-dark-2">
                      <p className="w-full text-base font-medium text-black dark:text-white">
                        {lesson.name}
                      </p>
                      {/* {editMode && (
                        <Button
                          className="w-fit text-brand"
                          variant={'ghost'}
                          onClick={() => handleEditLesson(lesson.id)}
                        >
                          Edit lesson
                        </Button>
                      )} */}
                      {isEditing && (
                        <DropdownMenu>
                          <DropdownMenuTrigger>
                            <EllipsisVerticalIcon />
                          </DropdownMenuTrigger>
                          <DropdownMenuContent
                            side="bottom"
                            align="end"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <DropdownMenuItem
                              className="font-medium"
                              onSelect={() => handleEditLesson(lesson.id)}
                            >
                              Edit lesson
                            </DropdownMenuItem>
                            <DropdownMenuItem
                              className="font-medium text-error"
                              onSelect={() => handleDeleteLesson(lesson.id)}
                            >
                              Delete lesson
                            </DropdownMenuItem>
                          </DropdownMenuContent>
                        </DropdownMenu>
                      )}
                      {!isEditing && (
                        <Button
                          className="w-fit text-brand"
                          variant={'ghost'}
                          onClick={() => handleStartLesson(lesson.id)}
                        >
                          {lessonProgress.find(
                            (lessonProgress) =>
                              lessonProgress.lessonId === lesson.id,
                          )?.completed ? (
                            <CheckRoundedIcon className="h-8 w-8 rounded-full bg-green-base stroke-black p-1.5 text-center" />
                          ) : (
                            'Start lesson'
                          )}
                        </Button>
                      )}
                    </div>
                  </SortableItem>
                ))}
              </SortableList>
            ) : hasPermits ? (
              lessonList.map((lesson) => (
                <Button
                  className="flex w-full items-center justify-between gap-2.5 rounded-xl bg-light-2 px-4 py-6 dark:bg-dark-2"
                  onClick={() => handleStartLesson(lesson.id)}
                  variant="none"
                  size="none"
                >
                  <div className="flex-grow overflow-hidden">
                    <p className="truncate text-start text-base font-medium text-black dark:text-white">
                      {lesson.name}
                    </p>
                  </div>
                  <div className="flex-shrink-0">
                    {isEditing ? (
                      <DropdownMenu>
                        <DropdownMenuTrigger>
                          <EllipsisVerticalIcon />
                        </DropdownMenuTrigger>
                        <DropdownMenuContent
                          side="bottom"
                          align="end"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <DropdownMenuItem
                            className="font-medium"
                            onSelect={() => handleEditLesson(lesson.id)}
                          >
                            Edit lesson
                          </DropdownMenuItem>
                          <DropdownMenuItem
                            className="font-medium text-error"
                            onSelect={() => handleDeleteLesson(lesson.id)}
                          >
                            Delete lesson
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    ) : (
                      <Button
                        className="min-w-fit text-brand"
                        variant="ghost"
                        onClick={() => handleStartLesson(lesson.id)}
                      >
                        {lessonProgress.find(
                          (lessonProgress) =>
                            lessonProgress.lessonId === lesson.id,
                        )?.completed ? (
                          <CheckRoundedIcon className="h-8 w-8 rounded-full bg-green-base stroke-black p-1.5 text-center" />
                        ) : (
                          <>
                            <p className="hidden md:block">Start lesson</p>
                            <ArrowRightIcon className="block h-8 w-8 fill-black dark:fill-white md:hidden" />
                          </>
                        )}
                      </Button>
                    )}
                  </div>
                </Button>
              ))
            ) : (
              <div className="flex flex-col gap-2.5 overflow-clip rounded-2xl bg-light-2 dark:bg-dark-2">
                {course.lessons.map((lesson, index) => (
                  <Button
                    variant={'none'}
                    key={lesson.id}
                    className={
                      'flex h-fit items-center justify-start gap-2.5 p-4 hover:bg-light-3 hover:dark:bg-dark-3'
                    }
                    onClick={() => handleStartLesson(lesson.id)}
                  >
                    {lessonProgress[index].completed ? (
                      <div className="h-3 min-w-3 rounded-full bg-green-accent/50"></div>
                    ) : (
                      index + 1
                    )}
                    <h3 className="text-wrap text-start text-base font-medium">
                      {lesson.name}
                    </h3>
                  </Button>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CourseContentPage;
