import { Navigate, useParams } from 'react-router-dom';

import { useBrandsControllerGetSpaceGroupsOfBrand } from '@/api';
import { Loading } from '@/components/Loading';

export const SpaceGroupPage = () => {
  const { brandId, spaceGroupId } = useParams<{
    brandId: string;
    spaceGroupId: string;
  }>();

  const spaceGroupsQuery = useBrandsControllerGetSpaceGroupsOfBrand(
    brandId as string,
  );

  if (spaceGroupsQuery.isLoading) {
    return <Loading />;
  }

  const spaceGroups = spaceGroupsQuery.data ?? [];

  const spaceGroup = spaceGroups.find(
    (spaceGroup) => spaceGroup.id === spaceGroupId,
  );

  if (!spaceGroup) {
    return <Navigate to="/404" />;
  }

  const postsSpaces = spaceGroup.postsSpaces;

  if (postsSpaces.length > 0) {
    return (
      <Navigate
        to={`/brands/${brandId}/space-groups/${spaceGroupId}/posts-spaces/${postsSpaces[0].id}`}
      />
    );
  }

  const eventsSpaces = spaceGroup.eventSpaces;

  if (eventsSpaces.length > 0) {
    return (
      <Navigate
        to={`/brands/${brandId}/space-groups/${spaceGroupId}/events-spaces/${eventsSpaces[0].id}`}
      />
    );
  }

  const chatSpaces = spaceGroup.chatsSpaces;

  if (chatSpaces.length > 0) {
    return (
      <Navigate
        to={`/brands/${brandId}/space-groups/${spaceGroupId}/chats-spaces/${chatSpaces[0].id}`}
      />
    );
  }

  const coursesSpaces = spaceGroup.coursesSpaces;

  if (coursesSpaces.length > 0) {
    return (
      <Navigate
        to={`/brands/${brandId}/space-groups/${spaceGroupId}/courses-spaces/${coursesSpaces[0].id}`}
      />
    );
  }

  const membersSpaces = spaceGroup.membersSpaces;

  if (membersSpaces.length > 0) {
    return (
      <Navigate
        to={`/brands/${brandId}/space-groups/${spaceGroupId}/members-spaces/${membersSpaces[0].id}`}
      />
    );
  }

  return <Navigate to="/404" />;
};
