import { CometChat } from '@cometchat/chat-sdk-javascript';
import * as Sentry from '@sentry/react';
import { useQueryClient } from '@tanstack/react-query';
import {
  fetchAuthSession,
  signInWithRedirect,
  signOut,
} from 'aws-amplify/auth';
import { createContext, useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import {
  AuthResponseWithTokens,
  BrandUserRoleDto,
  User,
  userControllerGetAuthenticatedUser,
  userControllerGetCometChatAuthToken,
  userControllerGoogleAuth,
  userControllerLogin,
  userControllerRegisterNewUser,
  userControllerSetupProfile,
} from '@/api';

// import { notificationsSocket } from '@/utils/notificationsSocket';

interface AuthContextType {
  user: User | null;
  setUser: (user: User) => void;
  cometChatUser: CometChat.User | null;
  login: (
    email: string,
    password: string,
  ) => Promise<{
    canRedirect: boolean;
  }>;
  logout: () => void;
  isAuthenticated: boolean;
  loginGoogle: () => Promise<void>;
  googleSetupUser: () => Promise<boolean>;
  fetchCurrentUser: () => Promise<boolean>;
  signUp: (email: string, password: string) => Promise<AuthResponseWithTokens>;
  setUpProfile: (
    name: string | null | undefined,
    phone: string | null | undefined,
    avatarBase64: string | null | undefined,
    countryCode: string | null | undefined,
    countryName: string | null | undefined,
  ) => Promise<User>;
  brandRole: BrandUserRoleDto['role'] | null;
  setBrandRole: (brandRole: BrandUserRoleDto['role'] | null) => void;
  refetchUser: () => Promise<User>;
  authType: string | null;
  getAuthType: () => Promise<'google' | 'email'>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth debe usarse dentro de un AuthProvider');
  }
  return context;
};

export const AuthProvider = () => {
  const [user, setUser] = useState<User | null>(null);
  const [cometChatUser, setCometChatUser] = useState<CometChat.User | null>(
    null,
  );

  const [authType, setAuthType] = useState<string | null>(null);
  const [brandRole, setBrandRole] = useState<BrandUserRoleDto['role'] | null>(
    null,
  );

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();

  const getAuthType = async () => {
    const session = await fetchAuthSession();
    if (session.tokens?.accessToken) {
      return 'google';
    } else {
      return 'email';
    }
  };

  const checkIfUserIsSetup = (user: User): { canRedirect: boolean } => {
    if (
      user.avatarUrl == null ||
      user.name == null ||
      user.phoneNumber == null
    ) {
      const searchParams = new URLSearchParams(location.search);
      const keys = [...searchParams.keys()];
      const isCreatorSignup = keys.includes('creatorSignup');
      const callbackUrl = searchParams.get('callbackUrl');

      const pathname = location.pathname;

      const newSearchParams = new URLSearchParams();
      if (callbackUrl || pathname) {
        newSearchParams.set('callbackUrl', callbackUrl || pathname);
      }
      if (isCreatorSignup) {
        newSearchParams.set('creatorSignup', '');
      }
      navigate('/register' + '?' + newSearchParams.toString(), {
        state: { from: location.pathname, step: 1 },
      });

      return { canRedirect: false };
    }

    return { canRedirect: true };
  };

  const fetchCurrentGoogleUser = async () => {
    const session = await fetchAuthSession();
    const accessTokenGoogle = session.tokens?.accessToken?.toString();
    const idTokenGoogle = session.tokens?.idToken?.toString();
    if (!idTokenGoogle || !accessTokenGoogle) return false;

    const res = await userControllerGoogleAuth({ idToken: idTokenGoogle });
    const cometChatUser = await CometChat.login(res.tokens.cometChatAuthToken);

    localStorage.setItem('token', res.tokens.accessToken);
    setUser(res.user);
    setCometChatUser(cometChatUser);

    checkIfUserIsSetup(res.user);

    return true;
  };

  const fetchCurrentUser = async (): Promise<boolean> => {
    // check if google auth is true
    const isGoogleAuth = await fetchCurrentGoogleUser();
    if (isGoogleAuth) return true;

    const token = localStorage.getItem('token');
    if (!token) return false;

    const [user, cometChatTokenRes] = await Promise.all([
      userControllerGetAuthenticatedUser(),
      userControllerGetCometChatAuthToken(),
    ]);
    const cometChatUser = await CometChat.login(
      cometChatTokenRes.cometChatAuthToken,
    );

    setUser(user);
    setCometChatUser(cometChatUser);

    checkIfUserIsSetup(user);

    return true;
  };

  const loginGoogle = async (): Promise<void> => {
    setAuthType('google');
    await signInWithRedirect({ provider: 'Google' });
  };

  const login = async (
    email: string,
    password: string,
  ): Promise<{ canRedirect: boolean }> => {
    const res = await userControllerLogin({ email, password });
    const cometChatUser = await CometChat.login(res.tokens.cometChatAuthToken);

    localStorage.setItem('token', res.tokens.accessToken);
    setUser(res.user);
    setCometChatUser(cometChatUser);
    setAuthType('email');

    return checkIfUserIsSetup(res.user);
  };

  const signUp = async (
    email: string,
    password: string,
  ): Promise<AuthResponseWithTokens> => {
    const res = await userControllerRegisterNewUser({ email, password });
    const cometChatUser = await CometChat.login(res.tokens.cometChatAuthToken);

    localStorage.setItem('token', res.tokens.accessToken);
    setUser(res.user);
    setCometChatUser(cometChatUser);
    setAuthType('email');

    return res;
  };

  const logout = async () => {
    localStorage.removeItem('token');
    setUser(null);
    setCometChatUser(null);
    await signOut();
    await CometChat.logout();
    queryClient.clear();
    navigate('/login');
  };

  const setUpProfile = async (
    name: string | null | undefined,
    phone: string | null | undefined,
    avatarBase64: string | null | undefined,
    countryCode: string | null | undefined,
    countryName: string | null | undefined,
  ): Promise<User> => {
    const user = await userControllerSetupProfile({
      name: name as string,
      phoneNumber: phone as string,
      avatarBase64: avatarBase64 as string,
      countryCode: countryCode as string,
      countryName: countryName as string,
    });

    setUser(user);
    return user;
  };

  const refetchUser = async (): Promise<User> => {
    const user = await userControllerGetAuthenticatedUser();
    setUser(user);
    return user;
  };

  useEffect(() => {
    if (user) {
      Sentry.setUser({
        id: user?.id,
        email: user?.email,
        fullName: user?.name,
      });
    } else {
      Sentry.setUser(null);
    }
    return () => {
      Sentry.setUser(null);
    };
  }, [user]);

  const hasUser = user !== null;

  // useEffect(() => {
  //   if (hasUser) {
  //     // Set auth headers right before connecting
  //     const token = localStorage.getItem('token');
  //     notificationsSocket.io.opts.extraHeaders = {
  //       Authorization: `Bearer ${token}`,
  //     };
  //     notificationsSocket.connect();
  //   } else {
  //     notificationsSocket.disconnect();
  //   }

  //   return () => {
  //     notificationsSocket.disconnect();
  //   };
  // }, [hasUser]);

  const isAuthenticated =
    hasUser &&
    user.avatarUrl !== null &&
    user.name !== null &&
    user.phoneNumber !== null;

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        cometChatUser,
        login,
        loginGoogle,
        logout,
        isAuthenticated,
        googleSetupUser: fetchCurrentGoogleUser,
        fetchCurrentUser,
        signUp,
        setUpProfile,
        brandRole,
        setBrandRole,
        refetchUser,
        authType,
        getAuthType,
      }}
    >
      <Outlet />
    </AuthContext.Provider>
  );
};
