import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import { FileIcon, XIcon } from 'lucide-react';
import { useCallback, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import {
  PostWithUserAndRoleDto,
  getPostsSpacesControllerGetPostsInSpaceQueryKey,
  usePostsSpacesControllerRemoveFiles,
  usePostsSpacesControllerUpdatePost,
  usePostsSpacesControllerUploadFiles,
  useSpaceGroupControllerGetUsersBySpaceGroupIdForMentions,
} from '@/api';
import { ClipIcon } from '@/assets/icon/clip';
import { Button } from '@/components/ui/button';
import {
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Form, FormControl, FormField, FormItem } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { cn, valueOrNull, valueOrUndefined } from '@/lib/utils';

import Editor from './Editor';

const formSchema = z.object({
  title: z.string().optional(),
  content: z.string().min(1),
  filesToUpload: z.instanceof(File).array(),
  filesToRemove: z.string().array(),
});

type FormValues = z.infer<typeof formSchema>;

export const UpdatePostDialogContent = ({
  post,
  spaceGroupId,
  closeDialog,
}: {
  post: PostWithUserAndRoleDto;
  spaceGroupId: string;
  closeDialog: () => void;
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    values: {
      title: post.title ?? undefined,
      content: post.content,
      filesToUpload: [],
      filesToRemove: [],
    },
  });

  const filesToRemove = form.watch('filesToRemove');
  const originalFilesFiltered = post.files.filter(
    (file) => !filesToRemove.includes(file.id),
  );

  const handleClose = () => {
    form.reset();
    closeDialog();
  };

  const queryClient = useQueryClient();

  const usersQuery =
    useSpaceGroupControllerGetUsersBySpaceGroupIdForMentions(spaceGroupId);
  const users = usersQuery.data ?? [];

  const updatePostMutation = usePostsSpacesControllerUpdatePost();
  const uploadFilesMutation = usePostsSpacesControllerUploadFiles();
  const removeFilesMutation = usePostsSpacesControllerRemoveFiles();

  const handleSubmit = form.handleSubmit(async (data) => {
    // if data is the same as the post, return
    if (
      data.title === valueOrUndefined(post.title) &&
      data.content === post.content &&
      data.filesToUpload.length === 0 &&
      data.filesToRemove.length === 0
    ) {
      handleClose();
      return;
    }

    let promises: Promise<any>[] = [];

    if (
      data.title !== valueOrUndefined(post.title) ||
      data.content !== post.content
    ) {
      promises.push(
        updatePostMutation.mutateAsync({
          postId: post.id,
          data: {
            title: valueOrNull(data.title),
            content: data.content,
          },
        }),
      );
    }

    if (data.filesToRemove.length > 0) {
      promises.push(
        removeFilesMutation.mutateAsync({
          postId: post.id,
          data: {
            filesIds: data.filesToRemove,
          },
        }),
      );
    }

    if (data.filesToUpload.length > 0) {
      promises.push(
        uploadFilesMutation.mutateAsync({
          postId: post.id,
          data: {
            files: data.filesToUpload,
          },
        }),
      );
    }

    await Promise.all(promises);

    queryClient.invalidateQueries({
      queryKey: getPostsSpacesControllerGetPostsInSpaceQueryKey(
        post.postsSpaceId,
      ),
    });

    handleClose();
  });

  const filesToUpload = form.watch('filesToUpload');

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles = e.target.files;
    if (newFiles) {
      const filesArray = Array.from(newFiles);
      // append files to form.setValue
      form.setValue('filesToUpload', [...filesToUpload, ...filesArray]);
    }
  };

  const handleFileInputClick = () => {
    fileInputRef.current?.click();
  };

  const handleRemoveOriginalFile = (id: string) => {
    form.setValue('filesToRemove', [...filesToRemove, id]);
  };

  const handleRemoveFile = (index: number) => {
    form.setValue(
      'filesToUpload',
      filesToUpload.slice(0, index).concat(filesToUpload.slice(index + 1)),
    );
  };

  const renderFiles = () => {
    if (originalFilesFiltered.length === 0 && filesToUpload.length === 0) {
      return null;
    }

    return (
      <div className="mt-4 flex flex-wrap gap-4">
        {originalFilesFiltered.map((file) => {
          if (file.type.startsWith('image/')) {
            const imageUrl = file.url;
            return (
              <div key={file.name} className="relative w-fit">
                <img src={imageUrl} alt={file.name} className="h-52" />
                <Button
                  type="button"
                  variant={'icon'}
                  size="icon"
                  className="absolute -bottom-2 -right-2 z-20 rounded-full bg-red-500 p-1 text-white dark:bg-red-500"
                  onClick={() => handleRemoveOriginalFile(file.id)}
                >
                  <XIcon className="h-4 w-4" />
                  <span className="sr-only">Remove</span>
                </Button>
              </div>
            );
          }

          if (file.type.startsWith('video/')) {
            const videoUrl = file.url;
            return (
              <div key={file.name} className="relative w-fit">
                <video src={videoUrl} className="h-52" controls>
                  Your browser does not support the video tag.
                </video>
                <Button
                  type="button"
                  variant={'icon'}
                  size="icon"
                  className="absolute -bottom-2 -right-2 z-20 rounded-full bg-red-500 p-1 text-white dark:bg-red-500"
                  onClick={() => handleRemoveOriginalFile(file.id)}
                >
                  <XIcon className="h-4 w-4" />
                  <span className="sr-only">Remove</span>
                </Button>
              </div>
            );
          }

          return (
            <div
              key={file.name}
              className="min-w-96 rounded-xl bg-bgGrey p-4 dark:bg-dark-bgGrey"
            >
              <div className="grid grid-cols-[auto_1fr_auto] gap-3">
                <div className="flex items-center gap-2">
                  <FileIcon className="h-4 w-4" />
                </div>
                <div className="flex flex-col">
                  <span className="text-sm text-black dark:text-white">
                    {file.name}
                  </span>
                  {/* <span className="text-sm text-gray-500 dark:text-gray-400">
                    {
                      // write file size in KB
                      (file.size / 1024).toFixed(1)
                    }{' '}
                    KB
                  </span> */}
                </div>
                <Button
                  type="button"
                  variant={'icon'}
                  size="icon"
                  onClick={() => handleRemoveOriginalFile(file.id)}
                >
                  <XIcon className="h-4 w-4" />
                  <span className="sr-only">Remove</span>
                </Button>
              </div>
            </div>
          );
        })}
        {filesToUpload.map((file, index) => {
          if (file.type.startsWith('image/')) {
            const imageUrl = URL.createObjectURL(file);
            return (
              <div key={file.name} className="relative w-fit">
                <img src={imageUrl} alt={file.name} className="h-52" />
                <Button
                  type="button"
                  variant={'icon'}
                  size="icon"
                  className="absolute -bottom-2 -right-2 z-20 rounded-full bg-red-500 p-1 text-white dark:bg-red-500"
                  onClick={() => handleRemoveFile(index)}
                >
                  <XIcon className="h-4 w-4" />
                  <span className="sr-only">Remove</span>
                </Button>
              </div>
            );
          }

          if (file.type.startsWith('video/')) {
            const videoUrl = URL.createObjectURL(file);
            return (
              <div key={file.name} className="relative w-fit">
                <video src={videoUrl} className="h-52" controls>
                  Your browser does not support the video tag.
                </video>
                <Button
                  type="button"
                  variant={'icon'}
                  size="icon"
                  className="absolute -bottom-2 -right-2 z-20 rounded-full bg-red-500 p-1 text-white dark:bg-red-500"
                  onClick={() => handleRemoveFile(index)}
                >
                  <XIcon className="h-4 w-4" />
                  <span className="sr-only">Remove</span>
                </Button>
              </div>
            );
          }

          return (
            <div
              key={file.name}
              className="min-w-96 rounded-xl bg-bgGrey p-4 dark:bg-dark-bgGrey"
            >
              <div className="grid grid-cols-[auto_1fr_auto] gap-3">
                <div className="flex items-center gap-2">
                  <FileIcon className="h-4 w-4" />
                </div>
                <div className="flex flex-col">
                  <span className="text-sm text-black dark:text-white">
                    {file.name}
                  </span>
                  <span className="text-sm text-gray-500 dark:text-gray-400">
                    {
                      // write file size in KB
                      (file.size / 1024).toFixed(1)
                    }{' '}
                    KB
                  </span>
                </div>
                <Button
                  type="button"
                  variant={'icon'}
                  size="icon"
                  onClick={() => handleRemoveFile(index)}
                >
                  <XIcon className="h-4 w-4" />
                  <span className="sr-only">Remove</span>
                </Button>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const handleTextChange = ({
    markdown,
  }: {
    markdown: string;
    html: string;
  }) => {
    form.setValue('content', markdown);
  };

  const handleMentionSearch = useCallback(
    (search: string) => {
      if (!search) return [];
      return users
        .filter((user) =>
          user.name?.toLowerCase().includes(search.toLowerCase()),
        )
        .map((user) => ({
          id: user.id ?? '',
          value: user.name ?? '',
          cometChatId: user.cometChatUid ?? '',
          name: user.name ?? '',
          avatarUrl: user.avatarUrl ?? '',
        }));
    },
    [users],
  );

  const findUserById = (id: string) => {
    const user = users.find((user) => user.id === id);
    if (!user) return null;
    return {
      id: user?.id ?? '',
      value: user?.name ?? '',
      cometChatId: user?.cometChatUid ?? '',
      name: user?.name ?? '',
      avatarUrl: user?.avatarUrl ?? '',
    };
  };

  return (
    <DialogContent className="w-[90%] max-w-3xl md:w-3/4 lg:w-1/2">
      <DialogHeader className="flex-row items-center justify-between space-y-0">
        <DialogTitle className="text-2xl font-semibold">
          Update post
        </DialogTitle>
        <DialogClose
          onClick={handleClose}
          className="m-0 rounded-sm p-0 ring-offset-background transition-opacity focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
        >
          <XIcon className="h-4 w-4" />
          <span className="sr-only">Close</span>
        </DialogClose>
      </DialogHeader>
      <Form {...form}>
        <form onSubmit={handleSubmit}>
          <div>
            <FormField
              control={form.control}
              name="title"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormControl>
                    <Input
                      className="border-none text-2xl font-semibold outline-none ring-0 placeholder:text-[#9E9E9E] focus-visible:ring-0 focus-visible:ring-offset-0"
                      placeholder="Title (optional)"
                      {...field}
                    />
                  </FormControl>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="content"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormControl>
                    <div className="max-h-100 overflow-y-auto">
                      <Editor
                        ref={field.ref}
                        defaultValue={post.content}
                        onTextChange={handleTextChange}
                        onSendMessage={handleSubmit}
                        onMentionSearch={handleMentionSearch}
                        className={cn(
                          'mt-4 resize-none border-none px-3 text-base font-normal outline-none ring-0 placeholder:text-[#9E9E9E] focus-visible:ring-0 focus-visible:ring-offset-0',
                          filesToUpload.length === 0 &&
                            originalFilesFiltered.length === 0 &&
                            'h-96 min-h-96',
                        )}
                        findUserById={findUserById}
                      />
                      {renderFiles()}
                    </div>
                  </FormControl>
                </FormItem>
              )}
            />
          </div>

          <div className="mt-4 flex items-center gap-5">
            <input
              type="file"
              className="hidden"
              ref={fileInputRef}
              onChange={handleFileInputChange}
            />
            {/* <Button
              type="button"
              variant="icon"
              size="icon"
              onClick={() => {
              }}
            >
              <ImageIcon className="w-5 fill-current text-gray-800 opacity-50 dark:text-white" />
            </Button> */}
            <Button
              type="button"
              variant="icon"
              size="icon"
              onClick={handleFileInputClick}
              disabled={
                updatePostMutation.isPending ||
                uploadFilesMutation.isPending ||
                removeFilesMutation.isPending
              }
            >
              <ClipIcon className="w-5 fill-current text-gray-800 opacity-50 dark:text-white" />
            </Button>
          </div>

          <div className="mt-4">
            <Button
              type="submit"
              className="rounded-sm px-8"
              disabled={
                updatePostMutation.isPending ||
                uploadFilesMutation.isPending ||
                removeFilesMutation.isPending
              }
            >
              Save
            </Button>
          </div>
        </form>
      </Form>
    </DialogContent>
  );
};
