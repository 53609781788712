import { useQuery } from '@tanstack/react-query';
import { Navigate, useSearchParams } from 'react-router-dom';

import { useUserControllerConfirmAccount } from '@/api';
import { Loading } from '@/components/Loading';

export const ConfirmAccountPage = () => {
  // /confirm-account?token=${confirmationToken}
  const [searchParams] = useSearchParams();
  const confirmationToken = searchParams.get('token');

  const confirmAccountMutation = useUserControllerConfirmAccount();

  const query = useQuery({
    queryKey: ['confirmAccount', confirmationToken],
    queryFn: () =>
      confirmAccountMutation.mutateAsync({
        data: { token: confirmationToken ?? '' },
      }),
    enabled: !!confirmationToken,
    retry: 2,
  });

  if (!confirmationToken) {
    throw new Error('No confirmation token provided');
  }

  if (query.isLoading) {
    return <Loading />;
  }

  if (query.isSuccess) {
    // show a message to the user that their account has been confirmed
    // and they can close this page
    return (
      <div className="flex h-screen flex-col items-center justify-center">
        <h1 className="text-2xl font-bold">Email confirmed</h1>
        <p className="text-lg">You can close this page now</p>
      </div>
    );
  }

  if (query.isError) {
    if (
      query.error instanceof Error &&
      query.error.message === 'This confirmation token has already been used'
    ) {
      return <Navigate to="/" />;
    }

    throw query.error;
  }

  return <Loading />;
};
