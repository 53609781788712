export const ChatBubbleLockedIcon = (props) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17 5.5H15.5V11H22.5V5.5H21M17 5.5V4C17 2.89543 17.8954 2 19 2C20.1045 2 21 2.89543 21 4V5.5M17 5.5H21"
      stroke-width="1.5"
      stroke-linejoin="round"
    />
    <path
      d="M12.489 12H12.498M8.49219 12H8.50116"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.5 2C6.97715 2 2.5 6.47715 2.5 12C2.5 13.5759 2.90213 14.9412 3.56417 16.2246C3.82379 16.7279 3.90711 17.3096 3.74438 17.8521L3.03364 20.2212C2.8048 20.984 3.51601 21.6952 4.27882 21.4664L6.64794 20.7556C7.19036 20.5929 7.77207 20.6762 8.27536 20.9358C9.55879 21.5979 10.9241 22 12.5 22C17.1596 22 20.8899 18.8131 22 14.5"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
