import { useQueries } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import {
  CourseLesson,
  CourseWithLessons,
  coursesSpacesControllerGetLessonProgress,
  getCoursesSpacesControllerGetLessonProgressQueryKey,
} from '@/api';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';

type Props = {
  currentLesson: CourseLesson;
  course: CourseWithLessons;
  brandId: string;
  spaceGroupId: string;
  coursesSpaceId: string;
  courseId: string;
};

const CourseLessonsProgress = ({
  course,
  currentLesson,
  brandId,
  spaceGroupId,
  coursesSpaceId,
  courseId,
}: Props) => {
  const navigate = useNavigate();

  const lessonProgressQuery = useQueries({
    queries: course.lessons.map((lesson) => ({
      queryKey: getCoursesSpacesControllerGetLessonProgressQueryKey(lesson.id),
      queryFn: () => coursesSpacesControllerGetLessonProgress(lesson.id),
    })),
  });

  const lessonProgress = lessonProgressQuery.map((query, index) => {
    return {
      completed: query.data?.completed ?? false,
      lessonId: course.lessons[index].id,
    };
  });

  const getDoneLessons = () => {
    return lessonProgress.filter((lesson) => lesson.completed).length;
  };

  const getDonePorcentage = () => {
    return (getDoneLessons() / course.lessons.length) * 100;
  };

  const currentLessonIndex = course.lessons.findIndex(
    (lesson) => lesson.id === currentLesson.id,
  );

  // const hasNextLesson = currentLessonIndex < course.lessons.length - 1;
  // const hasPreviousLesson = currentLessonIndex > 0;

  // const goToNextLesson = () => {
  //   // get the next lesson from the current lesson

  //   const nextLesson = course.lessons[currentLessonIndex + 1];

  //   if (nextLesson) {
  //     navigate(
  //       `/brands/${brandId}/space-groups/${spaceGroupId}/courses-spaces/${coursesSpaceId}/courses/${courseId}/lessons/${nextLesson.id}`,
  //     );
  //   }
  // };

  // const goToPreviousLesson = () => {
  //   const previousLesson = course.lessons[currentLessonIndex - 1];

  //   if (previousLesson) {
  //     navigate(
  //       `/brands/${brandId}/space-groups/${spaceGroupId}/courses-spaces/${coursesSpaceId}/courses/${courseId}/lessons/${previousLesson.id}`,
  //     );
  //   }
  // };

  return (
    <div className="flex h-fit flex-col gap-7.5 pb-10 pt-7.5 md:min-w-100">
      <div className="flex w-full flex-col gap-2.5">
        {/* <h1 className="text-2xl font-semibold">
          {course.lessons.length} Lessons
        </h1> */}
        <div className="flex w-full items-center gap-6">
          <progress
            value={getDoneLessons()}
            max={course.lessons.length}
            className="h-2.5 w-full [&::-webkit-progress-bar]:rounded-full [&::-webkit-progress-bar]:bg-dark-bgGrey [&::-webkit-progress-value]:rounded-full [&::-webkit-progress-value]:bg-brand"
          />
          <div className="text-nowrap rounded-lg border border-green-accent/10 bg-green-accent/10 px-2.5 py-[5px] text-sm font-medium">
            {getDonePorcentage().toFixed(0)}% complete
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-2.5 overflow-clip rounded-2xl bg-light-2 dark:bg-dark-2">
        {course.lessons.map((lesson, index) => (
          <Button
            variant={'none'}
            key={lesson.id}
            className={cn(
              'flex h-fit items-center justify-start gap-2.5 p-4',
              currentLessonIndex === index && 'bg-light-3 dark:bg-dark-3',
            )}
            disabled={currentLessonIndex === index}
            onClick={() =>
              navigate(
                `/brands/${brandId}/space-groups/${spaceGroupId}/courses-spaces/${coursesSpaceId}/courses/${courseId}/lessons/${lesson.id}`,
              )
            }
          >
            {lessonProgress[index].completed ? (
              <div className="h-3 min-w-3 rounded-full bg-green-accent/50"></div>
            ) : (
              index + 1
            )}
            <h3 className="text-wrap text-start text-base font-medium">
              {lesson.name}
            </h3>
          </Button>
        ))}
      </div>
      {/* <div className="flex gap-2.5">
        <Button
          disabled={!hasPreviousLesson}
          className="w-full"
          onClick={goToPreviousLesson}
        >
          Previous
        </Button>
        <Button
          className="w-full"
          disabled={!hasNextLesson}
          onClick={goToNextLesson}
        >
          Next
        </Button>
      </div> */}
    </div>
  );
};

export default CourseLessonsProgress;
