import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { Loading } from '@/components/Loading';
import { useAuth } from '@/hooks/useContext';

export const InitialUserFetch = () => {
  const { fetchCurrentUser } = useAuth();
  const [isReady, setIsReady] = useState(false);

  const isAuthQuery = useQuery({
    queryKey: ['isAuthenticated'],
    queryFn: fetchCurrentUser,
    retry: false,
  });

  // we need to wait some millis because there is a bug with safari
  useEffect(() => {
    if (isAuthQuery.data) {
      const timer = setTimeout(() => {
        setIsReady(true);
      }, 300);

      return () => clearTimeout(timer);
    }
  }, [isAuthQuery.data]);

  if (isAuthQuery.isLoading || (isAuthQuery.data && !isReady)) {
    return <Loading />;
  }

  return <Outlet />;
};
