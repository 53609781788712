export const HomeIcon = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="home-10">
      <path
        id="Vector"
        d="M2.5 16.6665V8.38729C2.5 7.87681 2.73395 7.39448 3.13485 7.07844L8.96817 2.47987C9.57333 2.0028 10.4267 2.0028 11.0318 2.47987L16.8652 7.07844C17.2661 7.39448 17.5 7.87682 17.5 8.38729V16.6665C17.5 17.587 16.7538 18.3331 15.8333 18.3331H4.16667C3.24619 18.3331 2.5 17.587 2.5 16.6665Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M9.99984 15.0002C11.1504 15.0002 12.0832 14.0674 12.0832 12.9168C12.0832 11.7662 11.1504 10.8335 9.99984 10.8335C8.84924 10.8335 7.9165 11.7662 7.9165 12.9168C7.9165 14.0674 8.84924 15.0002 9.99984 15.0002Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
