/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * Teachly API V2
 * Teachly API documentation
 * OpenAPI spec version: 1.0
 */
import { useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query';
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryClient,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';

import { customFetch } from '../../../mutator/custom-fetch';
import type {
  AddSpacesToSpaceGroupDto,
  CheckoutEndpointControllerGetSpaceGroupByPaymentLink404,
  CreateSpaceGroupDto,
  InviteMemberDto,
  PaymentLinkDto,
  SpaceGroup,
  SpaceGroupControllerCheckUserSpaceGroupAccess200,
  SpaceGroupControllerGetUsersBySpaceGroupIdForMentions200Item,
  SpaceGroupMembersDto,
  SpaceGroupResponseByIdDto,
  SpaceGroupResponseDto,
  SpaceGroupWithSpaces,
  SuccessMessageResponseDto,
  UpdateSharesDto,
  UpdateSpaceGroupDto,
  UpdateSpaceGroupMonetizationSettingsDto,
} from '../../model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * Creates a new space group for the authenticated user.
 * @summary Create a new space group
 */
export const spaceGroupControllerCreateSpaceGroup = (
  createSpaceGroupDto: CreateSpaceGroupDto,
  options?: SecondParameter<typeof customFetch>,
) => {
  return customFetch<SpaceGroup>(
    {
      url: `/space-groups`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createSpaceGroupDto,
    },
    options,
  );
};

export const getSpaceGroupControllerCreateSpaceGroupMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof spaceGroupControllerCreateSpaceGroup>>,
    TError,
    { data: CreateSpaceGroupDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof spaceGroupControllerCreateSpaceGroup>>,
  TError,
  { data: CreateSpaceGroupDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof spaceGroupControllerCreateSpaceGroup>>,
    { data: CreateSpaceGroupDto }
  > = (props) => {
    const { data } = props ?? {};

    return spaceGroupControllerCreateSpaceGroup(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SpaceGroupControllerCreateSpaceGroupMutationResult = NonNullable<
  Awaited<ReturnType<typeof spaceGroupControllerCreateSpaceGroup>>
>;
export type SpaceGroupControllerCreateSpaceGroupMutationBody =
  CreateSpaceGroupDto;
export type SpaceGroupControllerCreateSpaceGroupMutationError = void;

/**
 * @summary Create a new space group
 */
export const useSpaceGroupControllerCreateSpaceGroup = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof spaceGroupControllerCreateSpaceGroup>>,
    TError,
    { data: CreateSpaceGroupDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof spaceGroupControllerCreateSpaceGroup>>,
  TError,
  { data: CreateSpaceGroupDto },
  TContext
> => {
  const mutationOptions =
    getSpaceGroupControllerCreateSpaceGroupMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Retrieves all space groups associated with the authenticated user.
 * @summary Get all space groups for the authenticated user
 */
export const spaceGroupControllerGetSpaceGroups = (
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<SpaceGroupWithSpaces[]>(
    { url: `/space-groups`, method: 'GET', signal },
    options,
  );
};

export const getSpaceGroupControllerGetSpaceGroupsQueryKey = () => {
  return [`/space-groups`] as const;
};

export const getSpaceGroupControllerGetSpaceGroupsQueryOptions = <
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroups>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroups>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getSpaceGroupControllerGetSpaceGroupsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroups>>
  > = ({ signal }) =>
    spaceGroupControllerGetSpaceGroups(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroups>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SpaceGroupControllerGetSpaceGroupsQueryResult = NonNullable<
  Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroups>>
>;
export type SpaceGroupControllerGetSpaceGroupsQueryError = void;

export function useSpaceGroupControllerGetSpaceGroups<
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroups>>,
  TError = void,
>(options: {
  query: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroups>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroups>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customFetch>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSpaceGroupControllerGetSpaceGroups<
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroups>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroups>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroups>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSpaceGroupControllerGetSpaceGroups<
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroups>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroups>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get all space groups for the authenticated user
 */

export function useSpaceGroupControllerGetSpaceGroups<
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroups>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroups>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getSpaceGroupControllerGetSpaceGroupsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get all space groups for the authenticated user
 */
export const prefetchSpaceGroupControllerGetSpaceGroups = async <
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroups>>,
  TError = void,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroups>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions =
    getSpaceGroupControllerGetSpaceGroupsQueryOptions(options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getSpaceGroupControllerGetSpaceGroupsSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroups>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroups>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getSpaceGroupControllerGetSpaceGroupsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroups>>
  > = ({ signal }) =>
    spaceGroupControllerGetSpaceGroups(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroups>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SpaceGroupControllerGetSpaceGroupsSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroups>>
>;
export type SpaceGroupControllerGetSpaceGroupsSuspenseQueryError = void;

export function useSpaceGroupControllerGetSpaceGroupsSuspense<
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroups>>,
  TError = void,
>(options: {
  query: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroups>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSpaceGroupControllerGetSpaceGroupsSuspense<
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroups>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroups>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSpaceGroupControllerGetSpaceGroupsSuspense<
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroups>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroups>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get all space groups for the authenticated user
 */

export function useSpaceGroupControllerGetSpaceGroupsSuspense<
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroups>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroups>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getSpaceGroupControllerGetSpaceGroupsSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const spaceGroupControllerGetAllSpaceGroups = (
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<void>(
    { url: `/space-groups/all`, method: 'GET', signal },
    options,
  );
};

export const getSpaceGroupControllerGetAllSpaceGroupsQueryKey = () => {
  return [`/space-groups/all`] as const;
};

export const getSpaceGroupControllerGetAllSpaceGroupsQueryOptions = <
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetAllSpaceGroups>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof spaceGroupControllerGetAllSpaceGroups>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSpaceGroupControllerGetAllSpaceGroupsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof spaceGroupControllerGetAllSpaceGroups>>
  > = ({ signal }) =>
    spaceGroupControllerGetAllSpaceGroups(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof spaceGroupControllerGetAllSpaceGroups>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SpaceGroupControllerGetAllSpaceGroupsQueryResult = NonNullable<
  Awaited<ReturnType<typeof spaceGroupControllerGetAllSpaceGroups>>
>;
export type SpaceGroupControllerGetAllSpaceGroupsQueryError = void;

export function useSpaceGroupControllerGetAllSpaceGroups<
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetAllSpaceGroups>>,
  TError = void,
>(options: {
  query: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof spaceGroupControllerGetAllSpaceGroups>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof spaceGroupControllerGetAllSpaceGroups>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customFetch>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSpaceGroupControllerGetAllSpaceGroups<
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetAllSpaceGroups>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof spaceGroupControllerGetAllSpaceGroups>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof spaceGroupControllerGetAllSpaceGroups>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSpaceGroupControllerGetAllSpaceGroups<
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetAllSpaceGroups>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof spaceGroupControllerGetAllSpaceGroups>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useSpaceGroupControllerGetAllSpaceGroups<
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetAllSpaceGroups>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof spaceGroupControllerGetAllSpaceGroups>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getSpaceGroupControllerGetAllSpaceGroupsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const prefetchSpaceGroupControllerGetAllSpaceGroups = async <
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetAllSpaceGroups>>,
  TError = void,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof spaceGroupControllerGetAllSpaceGroups>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions =
    getSpaceGroupControllerGetAllSpaceGroupsQueryOptions(options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getSpaceGroupControllerGetAllSpaceGroupsSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetAllSpaceGroups>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof spaceGroupControllerGetAllSpaceGroups>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSpaceGroupControllerGetAllSpaceGroupsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof spaceGroupControllerGetAllSpaceGroups>>
  > = ({ signal }) =>
    spaceGroupControllerGetAllSpaceGroups(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof spaceGroupControllerGetAllSpaceGroups>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SpaceGroupControllerGetAllSpaceGroupsSuspenseQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof spaceGroupControllerGetAllSpaceGroups>>
  >;
export type SpaceGroupControllerGetAllSpaceGroupsSuspenseQueryError = void;

export function useSpaceGroupControllerGetAllSpaceGroupsSuspense<
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetAllSpaceGroups>>,
  TError = void,
>(options: {
  query: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof spaceGroupControllerGetAllSpaceGroups>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSpaceGroupControllerGetAllSpaceGroupsSuspense<
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetAllSpaceGroups>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof spaceGroupControllerGetAllSpaceGroups>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSpaceGroupControllerGetAllSpaceGroupsSuspense<
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetAllSpaceGroups>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof spaceGroupControllerGetAllSpaceGroups>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useSpaceGroupControllerGetAllSpaceGroupsSuspense<
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetAllSpaceGroups>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof spaceGroupControllerGetAllSpaceGroups>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getSpaceGroupControllerGetAllSpaceGroupsSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Retrieves a space group with all its associated spaces
 * @summary Get space group by ID
 */
export const spaceGroupControllerGetSpaceGroupById = (
  spaceGroupId: string,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<SpaceGroupResponseByIdDto>(
    {
      url: `/space-groups/${encodeURIComponent(String(spaceGroupId))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getSpaceGroupControllerGetSpaceGroupByIdQueryKey = (
  spaceGroupId: string,
) => {
  return [`/space-groups/${spaceGroupId}`] as const;
};

export const getSpaceGroupControllerGetSpaceGroupByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupById>>,
  TError = void,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSpaceGroupControllerGetSpaceGroupByIdQueryKey(spaceGroupId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupById>>
  > = ({ signal }) =>
    spaceGroupControllerGetSpaceGroupById(spaceGroupId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!spaceGroupId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SpaceGroupControllerGetSpaceGroupByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupById>>
>;
export type SpaceGroupControllerGetSpaceGroupByIdQueryError = void;

export function useSpaceGroupControllerGetSpaceGroupById<
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupById>>,
  TError = void,
>(
  spaceGroupId: string,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupById>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupById>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSpaceGroupControllerGetSpaceGroupById<
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupById>>,
  TError = void,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupById>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupById>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSpaceGroupControllerGetSpaceGroupById<
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupById>>,
  TError = void,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get space group by ID
 */

export function useSpaceGroupControllerGetSpaceGroupById<
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupById>>,
  TError = void,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSpaceGroupControllerGetSpaceGroupByIdQueryOptions(
    spaceGroupId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get space group by ID
 */
export const prefetchSpaceGroupControllerGetSpaceGroupById = async <
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupById>>,
  TError = void,
>(
  queryClient: QueryClient,
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getSpaceGroupControllerGetSpaceGroupByIdQueryOptions(
    spaceGroupId,
    options,
  );

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getSpaceGroupControllerGetSpaceGroupByIdSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupById>>,
  TError = void,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSpaceGroupControllerGetSpaceGroupByIdQueryKey(spaceGroupId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupById>>
  > = ({ signal }) =>
    spaceGroupControllerGetSpaceGroupById(spaceGroupId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!spaceGroupId,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SpaceGroupControllerGetSpaceGroupByIdSuspenseQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupById>>
  >;
export type SpaceGroupControllerGetSpaceGroupByIdSuspenseQueryError = void;

export function useSpaceGroupControllerGetSpaceGroupByIdSuspense<
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupById>>,
  TError = void,
>(
  spaceGroupId: string,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSpaceGroupControllerGetSpaceGroupByIdSuspense<
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupById>>,
  TError = void,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSpaceGroupControllerGetSpaceGroupByIdSuspense<
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupById>>,
  TError = void,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get space group by ID
 */

export function useSpaceGroupControllerGetSpaceGroupByIdSuspense<
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupById>>,
  TError = void,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getSpaceGroupControllerGetSpaceGroupByIdSuspenseQueryOptions(
      spaceGroupId,
      options,
    );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Deletes a space group for the authenticated user.
 * @summary Delete a space group
 */
export const spaceGroupControllerDeleteSpaceGroup = (
  spaceGroupId: string,
  options?: SecondParameter<typeof customFetch>,
) => {
  return customFetch<SuccessMessageResponseDto>(
    {
      url: `/space-groups/${encodeURIComponent(String(spaceGroupId))}`,
      method: 'DELETE',
    },
    options,
  );
};

export const getSpaceGroupControllerDeleteSpaceGroupMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof spaceGroupControllerDeleteSpaceGroup>>,
    TError,
    { spaceGroupId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof spaceGroupControllerDeleteSpaceGroup>>,
  TError,
  { spaceGroupId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof spaceGroupControllerDeleteSpaceGroup>>,
    { spaceGroupId: string }
  > = (props) => {
    const { spaceGroupId } = props ?? {};

    return spaceGroupControllerDeleteSpaceGroup(spaceGroupId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SpaceGroupControllerDeleteSpaceGroupMutationResult = NonNullable<
  Awaited<ReturnType<typeof spaceGroupControllerDeleteSpaceGroup>>
>;

export type SpaceGroupControllerDeleteSpaceGroupMutationError = void;

/**
 * @summary Delete a space group
 */
export const useSpaceGroupControllerDeleteSpaceGroup = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof spaceGroupControllerDeleteSpaceGroup>>,
    TError,
    { spaceGroupId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof spaceGroupControllerDeleteSpaceGroup>>,
  TError,
  { spaceGroupId: string },
  TContext
> => {
  const mutationOptions =
    getSpaceGroupControllerDeleteSpaceGroupMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Updates a space group for the authenticated user.
 * @summary Update a space group
 */
export const spaceGroupControllerUpdateSpaceGroup = (
  spaceGroupId: string,
  updateSpaceGroupDto: UpdateSpaceGroupDto,
  options?: SecondParameter<typeof customFetch>,
) => {
  return customFetch<SpaceGroup>(
    {
      url: `/space-groups/${encodeURIComponent(String(spaceGroupId))}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: updateSpaceGroupDto,
    },
    options,
  );
};

export const getSpaceGroupControllerUpdateSpaceGroupMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof spaceGroupControllerUpdateSpaceGroup>>,
    TError,
    { spaceGroupId: string; data: UpdateSpaceGroupDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof spaceGroupControllerUpdateSpaceGroup>>,
  TError,
  { spaceGroupId: string; data: UpdateSpaceGroupDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof spaceGroupControllerUpdateSpaceGroup>>,
    { spaceGroupId: string; data: UpdateSpaceGroupDto }
  > = (props) => {
    const { spaceGroupId, data } = props ?? {};

    return spaceGroupControllerUpdateSpaceGroup(
      spaceGroupId,
      data,
      requestOptions,
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type SpaceGroupControllerUpdateSpaceGroupMutationResult = NonNullable<
  Awaited<ReturnType<typeof spaceGroupControllerUpdateSpaceGroup>>
>;
export type SpaceGroupControllerUpdateSpaceGroupMutationBody =
  UpdateSpaceGroupDto;
export type SpaceGroupControllerUpdateSpaceGroupMutationError = void;

/**
 * @summary Update a space group
 */
export const useSpaceGroupControllerUpdateSpaceGroup = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof spaceGroupControllerUpdateSpaceGroup>>,
    TError,
    { spaceGroupId: string; data: UpdateSpaceGroupDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof spaceGroupControllerUpdateSpaceGroup>>,
  TError,
  { spaceGroupId: string; data: UpdateSpaceGroupDto },
  TContext
> => {
  const mutationOptions =
    getSpaceGroupControllerUpdateSpaceGroupMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Retrieves the payment link URL for a space group using its ID.
 * @summary Get payment link by space group ID
 */
export const spaceGroupControllerGetPaymentLink = (
  spaceGroupId: string,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<PaymentLinkDto>(
    {
      url: `/space-groups/${encodeURIComponent(String(spaceGroupId))}/payment-link`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getSpaceGroupControllerGetPaymentLinkQueryKey = (
  spaceGroupId: string,
) => {
  return [`/space-groups/${spaceGroupId}/payment-link`] as const;
};

export const getSpaceGroupControllerGetPaymentLinkQueryOptions = <
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetPaymentLink>>,
  TError = void,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof spaceGroupControllerGetPaymentLink>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSpaceGroupControllerGetPaymentLinkQueryKey(spaceGroupId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof spaceGroupControllerGetPaymentLink>>
  > = ({ signal }) =>
    spaceGroupControllerGetPaymentLink(spaceGroupId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!spaceGroupId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof spaceGroupControllerGetPaymentLink>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SpaceGroupControllerGetPaymentLinkQueryResult = NonNullable<
  Awaited<ReturnType<typeof spaceGroupControllerGetPaymentLink>>
>;
export type SpaceGroupControllerGetPaymentLinkQueryError = void;

export function useSpaceGroupControllerGetPaymentLink<
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetPaymentLink>>,
  TError = void,
>(
  spaceGroupId: string,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof spaceGroupControllerGetPaymentLink>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof spaceGroupControllerGetPaymentLink>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSpaceGroupControllerGetPaymentLink<
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetPaymentLink>>,
  TError = void,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof spaceGroupControllerGetPaymentLink>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof spaceGroupControllerGetPaymentLink>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSpaceGroupControllerGetPaymentLink<
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetPaymentLink>>,
  TError = void,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof spaceGroupControllerGetPaymentLink>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get payment link by space group ID
 */

export function useSpaceGroupControllerGetPaymentLink<
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetPaymentLink>>,
  TError = void,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof spaceGroupControllerGetPaymentLink>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSpaceGroupControllerGetPaymentLinkQueryOptions(
    spaceGroupId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get payment link by space group ID
 */
export const prefetchSpaceGroupControllerGetPaymentLink = async <
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetPaymentLink>>,
  TError = void,
>(
  queryClient: QueryClient,
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof spaceGroupControllerGetPaymentLink>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getSpaceGroupControllerGetPaymentLinkQueryOptions(
    spaceGroupId,
    options,
  );

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getSpaceGroupControllerGetPaymentLinkSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetPaymentLink>>,
  TError = void,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof spaceGroupControllerGetPaymentLink>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSpaceGroupControllerGetPaymentLinkQueryKey(spaceGroupId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof spaceGroupControllerGetPaymentLink>>
  > = ({ signal }) =>
    spaceGroupControllerGetPaymentLink(spaceGroupId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!spaceGroupId,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof spaceGroupControllerGetPaymentLink>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SpaceGroupControllerGetPaymentLinkSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof spaceGroupControllerGetPaymentLink>>
>;
export type SpaceGroupControllerGetPaymentLinkSuspenseQueryError = void;

export function useSpaceGroupControllerGetPaymentLinkSuspense<
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetPaymentLink>>,
  TError = void,
>(
  spaceGroupId: string,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof spaceGroupControllerGetPaymentLink>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSpaceGroupControllerGetPaymentLinkSuspense<
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetPaymentLink>>,
  TError = void,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof spaceGroupControllerGetPaymentLink>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSpaceGroupControllerGetPaymentLinkSuspense<
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetPaymentLink>>,
  TError = void,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof spaceGroupControllerGetPaymentLink>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get payment link by space group ID
 */

export function useSpaceGroupControllerGetPaymentLinkSuspense<
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetPaymentLink>>,
  TError = void,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof spaceGroupControllerGetPaymentLink>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getSpaceGroupControllerGetPaymentLinkSuspenseQueryOptions(
      spaceGroupId,
      options,
    );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Retrieve the list of members belonging to a specific space group. The authenticated user must have access to the space group.
 * @summary Get members of a space group for mentions
 */
export const spaceGroupControllerGetUsersBySpaceGroupIdForMentions = (
  spaceGroupId: string,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<
    SpaceGroupControllerGetUsersBySpaceGroupIdForMentions200Item[]
  >(
    {
      url: `/space-groups/${encodeURIComponent(String(spaceGroupId))}/members-mentions`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getSpaceGroupControllerGetUsersBySpaceGroupIdForMentionsQueryKey =
  (spaceGroupId: string) => {
    return [`/space-groups/${spaceGroupId}/members-mentions`] as const;
  };

export const getSpaceGroupControllerGetUsersBySpaceGroupIdForMentionsQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof spaceGroupControllerGetUsersBySpaceGroupIdForMentions>
    >,
    TError = void,
  >(
    spaceGroupId: string,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof spaceGroupControllerGetUsersBySpaceGroupIdForMentions
            >
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customFetch>;
    },
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getSpaceGroupControllerGetUsersBySpaceGroupIdForMentionsQueryKey(
        spaceGroupId,
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof spaceGroupControllerGetUsersBySpaceGroupIdForMentions>
      >
    > = ({ signal }) =>
      spaceGroupControllerGetUsersBySpaceGroupIdForMentions(
        spaceGroupId,
        requestOptions,
        signal,
      );

    return {
      queryKey,
      queryFn,
      enabled: !!spaceGroupId,
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<typeof spaceGroupControllerGetUsersBySpaceGroupIdForMentions>
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type SpaceGroupControllerGetUsersBySpaceGroupIdForMentionsQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof spaceGroupControllerGetUsersBySpaceGroupIdForMentions>
    >
  >;
export type SpaceGroupControllerGetUsersBySpaceGroupIdForMentionsQueryError =
  void;

export function useSpaceGroupControllerGetUsersBySpaceGroupIdForMentions<
  TData = Awaited<
    ReturnType<typeof spaceGroupControllerGetUsersBySpaceGroupIdForMentions>
  >,
  TError = void,
>(
  spaceGroupId: string,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof spaceGroupControllerGetUsersBySpaceGroupIdForMentions
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof spaceGroupControllerGetUsersBySpaceGroupIdForMentions
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSpaceGroupControllerGetUsersBySpaceGroupIdForMentions<
  TData = Awaited<
    ReturnType<typeof spaceGroupControllerGetUsersBySpaceGroupIdForMentions>
  >,
  TError = void,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof spaceGroupControllerGetUsersBySpaceGroupIdForMentions
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof spaceGroupControllerGetUsersBySpaceGroupIdForMentions
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSpaceGroupControllerGetUsersBySpaceGroupIdForMentions<
  TData = Awaited<
    ReturnType<typeof spaceGroupControllerGetUsersBySpaceGroupIdForMentions>
  >,
  TError = void,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof spaceGroupControllerGetUsersBySpaceGroupIdForMentions
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get members of a space group for mentions
 */

export function useSpaceGroupControllerGetUsersBySpaceGroupIdForMentions<
  TData = Awaited<
    ReturnType<typeof spaceGroupControllerGetUsersBySpaceGroupIdForMentions>
  >,
  TError = void,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof spaceGroupControllerGetUsersBySpaceGroupIdForMentions
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getSpaceGroupControllerGetUsersBySpaceGroupIdForMentionsQueryOptions(
      spaceGroupId,
      options,
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get members of a space group for mentions
 */
export const prefetchSpaceGroupControllerGetUsersBySpaceGroupIdForMentions =
  async <
    TData = Awaited<
      ReturnType<typeof spaceGroupControllerGetUsersBySpaceGroupIdForMentions>
    >,
    TError = void,
  >(
    queryClient: QueryClient,
    spaceGroupId: string,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof spaceGroupControllerGetUsersBySpaceGroupIdForMentions
            >
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customFetch>;
    },
  ): Promise<QueryClient> => {
    const queryOptions =
      getSpaceGroupControllerGetUsersBySpaceGroupIdForMentionsQueryOptions(
        spaceGroupId,
        options,
      );

    await queryClient.prefetchQuery(queryOptions);

    return queryClient;
  };

export const getSpaceGroupControllerGetUsersBySpaceGroupIdForMentionsSuspenseQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof spaceGroupControllerGetUsersBySpaceGroupIdForMentions>
    >,
    TError = void,
  >(
    spaceGroupId: string,
    options?: {
      query?: Partial<
        UseSuspenseQueryOptions<
          Awaited<
            ReturnType<
              typeof spaceGroupControllerGetUsersBySpaceGroupIdForMentions
            >
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customFetch>;
    },
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getSpaceGroupControllerGetUsersBySpaceGroupIdForMentionsQueryKey(
        spaceGroupId,
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof spaceGroupControllerGetUsersBySpaceGroupIdForMentions>
      >
    > = ({ signal }) =>
      spaceGroupControllerGetUsersBySpaceGroupIdForMentions(
        spaceGroupId,
        requestOptions,
        signal,
      );

    return {
      queryKey,
      queryFn,
      enabled: !!spaceGroupId,
      ...queryOptions,
    } as UseSuspenseQueryOptions<
      Awaited<
        ReturnType<typeof spaceGroupControllerGetUsersBySpaceGroupIdForMentions>
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type SpaceGroupControllerGetUsersBySpaceGroupIdForMentionsSuspenseQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof spaceGroupControllerGetUsersBySpaceGroupIdForMentions>
    >
  >;
export type SpaceGroupControllerGetUsersBySpaceGroupIdForMentionsSuspenseQueryError =
  void;

export function useSpaceGroupControllerGetUsersBySpaceGroupIdForMentionsSuspense<
  TData = Awaited<
    ReturnType<typeof spaceGroupControllerGetUsersBySpaceGroupIdForMentions>
  >,
  TError = void,
>(
  spaceGroupId: string,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<
            typeof spaceGroupControllerGetUsersBySpaceGroupIdForMentions
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSpaceGroupControllerGetUsersBySpaceGroupIdForMentionsSuspense<
  TData = Awaited<
    ReturnType<typeof spaceGroupControllerGetUsersBySpaceGroupIdForMentions>
  >,
  TError = void,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<
            typeof spaceGroupControllerGetUsersBySpaceGroupIdForMentions
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSpaceGroupControllerGetUsersBySpaceGroupIdForMentionsSuspense<
  TData = Awaited<
    ReturnType<typeof spaceGroupControllerGetUsersBySpaceGroupIdForMentions>
  >,
  TError = void,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<
            typeof spaceGroupControllerGetUsersBySpaceGroupIdForMentions
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get members of a space group for mentions
 */

export function useSpaceGroupControllerGetUsersBySpaceGroupIdForMentionsSuspense<
  TData = Awaited<
    ReturnType<typeof spaceGroupControllerGetUsersBySpaceGroupIdForMentions>
  >,
  TError = void,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<
            typeof spaceGroupControllerGetUsersBySpaceGroupIdForMentions
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getSpaceGroupControllerGetUsersBySpaceGroupIdForMentionsSuspenseQueryOptions(
      spaceGroupId,
      options,
    );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const spaceGroupControllerUpdateShares = (
  spaceGroupId: string,
  updateSharesDto: UpdateSharesDto,
  options?: SecondParameter<typeof customFetch>,
) => {
  return customFetch<void>(
    {
      url: `/space-groups/${encodeURIComponent(String(spaceGroupId))}/update-shares`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: updateSharesDto,
    },
    options,
  );
};

export const getSpaceGroupControllerUpdateSharesMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof spaceGroupControllerUpdateShares>>,
    TError,
    { spaceGroupId: string; data: UpdateSharesDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof spaceGroupControllerUpdateShares>>,
  TError,
  { spaceGroupId: string; data: UpdateSharesDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof spaceGroupControllerUpdateShares>>,
    { spaceGroupId: string; data: UpdateSharesDto }
  > = (props) => {
    const { spaceGroupId, data } = props ?? {};

    return spaceGroupControllerUpdateShares(spaceGroupId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SpaceGroupControllerUpdateSharesMutationResult = NonNullable<
  Awaited<ReturnType<typeof spaceGroupControllerUpdateShares>>
>;
export type SpaceGroupControllerUpdateSharesMutationBody = UpdateSharesDto;
export type SpaceGroupControllerUpdateSharesMutationError = void;

export const useSpaceGroupControllerUpdateShares = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof spaceGroupControllerUpdateShares>>,
    TError,
    { spaceGroupId: string; data: UpdateSharesDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof spaceGroupControllerUpdateShares>>,
  TError,
  { spaceGroupId: string; data: UpdateSharesDto },
  TContext
> => {
  const mutationOptions =
    getSpaceGroupControllerUpdateSharesMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Adds spaces to a space group for the authenticated user.
 * @summary Add spaces to a space group
 */
export const spaceGroupControllerAddSpacesToSpaceGroup = (
  spaceGroupId: string,
  addSpacesToSpaceGroupDto: AddSpacesToSpaceGroupDto,
  options?: SecondParameter<typeof customFetch>,
) => {
  return customFetch<SuccessMessageResponseDto>(
    {
      url: `/space-groups/${encodeURIComponent(String(spaceGroupId))}/add-spaces`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: addSpacesToSpaceGroupDto,
    },
    options,
  );
};

export const getSpaceGroupControllerAddSpacesToSpaceGroupMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof spaceGroupControllerAddSpacesToSpaceGroup>>,
    TError,
    { spaceGroupId: string; data: AddSpacesToSpaceGroupDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof spaceGroupControllerAddSpacesToSpaceGroup>>,
  TError,
  { spaceGroupId: string; data: AddSpacesToSpaceGroupDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof spaceGroupControllerAddSpacesToSpaceGroup>>,
    { spaceGroupId: string; data: AddSpacesToSpaceGroupDto }
  > = (props) => {
    const { spaceGroupId, data } = props ?? {};

    return spaceGroupControllerAddSpacesToSpaceGroup(
      spaceGroupId,
      data,
      requestOptions,
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type SpaceGroupControllerAddSpacesToSpaceGroupMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof spaceGroupControllerAddSpacesToSpaceGroup>>
  >;
export type SpaceGroupControllerAddSpacesToSpaceGroupMutationBody =
  AddSpacesToSpaceGroupDto;
export type SpaceGroupControllerAddSpacesToSpaceGroupMutationError = void;

/**
 * @summary Add spaces to a space group
 */
export const useSpaceGroupControllerAddSpacesToSpaceGroup = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof spaceGroupControllerAddSpacesToSpaceGroup>>,
    TError,
    { spaceGroupId: string; data: AddSpacesToSpaceGroupDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof spaceGroupControllerAddSpacesToSpaceGroup>>,
  TError,
  { spaceGroupId: string; data: AddSpacesToSpaceGroupDto },
  TContext
> => {
  const mutationOptions =
    getSpaceGroupControllerAddSpacesToSpaceGroupMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Checks if a user has access to a space group.
 * @summary Check user space group access
 */
export const spaceGroupControllerCheckUserSpaceGroupAccess = (
  spaceGroupId: string,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<SpaceGroupControllerCheckUserSpaceGroupAccess200>(
    {
      url: `/space-groups/${encodeURIComponent(String(spaceGroupId))}/access`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getSpaceGroupControllerCheckUserSpaceGroupAccessQueryKey = (
  spaceGroupId: string,
) => {
  return [`/space-groups/${spaceGroupId}/access`] as const;
};

export const getSpaceGroupControllerCheckUserSpaceGroupAccessQueryOptions = <
  TData = Awaited<
    ReturnType<typeof spaceGroupControllerCheckUserSpaceGroupAccess>
  >,
  TError = void,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof spaceGroupControllerCheckUserSpaceGroupAccess>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSpaceGroupControllerCheckUserSpaceGroupAccessQueryKey(spaceGroupId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof spaceGroupControllerCheckUserSpaceGroupAccess>>
  > = ({ signal }) =>
    spaceGroupControllerCheckUserSpaceGroupAccess(
      spaceGroupId,
      requestOptions,
      signal,
    );

  return {
    queryKey,
    queryFn,
    enabled: !!spaceGroupId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof spaceGroupControllerCheckUserSpaceGroupAccess>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SpaceGroupControllerCheckUserSpaceGroupAccessQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof spaceGroupControllerCheckUserSpaceGroupAccess>>
  >;
export type SpaceGroupControllerCheckUserSpaceGroupAccessQueryError = void;

export function useSpaceGroupControllerCheckUserSpaceGroupAccess<
  TData = Awaited<
    ReturnType<typeof spaceGroupControllerCheckUserSpaceGroupAccess>
  >,
  TError = void,
>(
  spaceGroupId: string,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof spaceGroupControllerCheckUserSpaceGroupAccess>
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof spaceGroupControllerCheckUserSpaceGroupAccess>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSpaceGroupControllerCheckUserSpaceGroupAccess<
  TData = Awaited<
    ReturnType<typeof spaceGroupControllerCheckUserSpaceGroupAccess>
  >,
  TError = void,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof spaceGroupControllerCheckUserSpaceGroupAccess>
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof spaceGroupControllerCheckUserSpaceGroupAccess>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSpaceGroupControllerCheckUserSpaceGroupAccess<
  TData = Awaited<
    ReturnType<typeof spaceGroupControllerCheckUserSpaceGroupAccess>
  >,
  TError = void,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof spaceGroupControllerCheckUserSpaceGroupAccess>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Check user space group access
 */

export function useSpaceGroupControllerCheckUserSpaceGroupAccess<
  TData = Awaited<
    ReturnType<typeof spaceGroupControllerCheckUserSpaceGroupAccess>
  >,
  TError = void,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof spaceGroupControllerCheckUserSpaceGroupAccess>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getSpaceGroupControllerCheckUserSpaceGroupAccessQueryOptions(
      spaceGroupId,
      options,
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Check user space group access
 */
export const prefetchSpaceGroupControllerCheckUserSpaceGroupAccess = async <
  TData = Awaited<
    ReturnType<typeof spaceGroupControllerCheckUserSpaceGroupAccess>
  >,
  TError = void,
>(
  queryClient: QueryClient,
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof spaceGroupControllerCheckUserSpaceGroupAccess>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions =
    getSpaceGroupControllerCheckUserSpaceGroupAccessQueryOptions(
      spaceGroupId,
      options,
    );

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getSpaceGroupControllerCheckUserSpaceGroupAccessSuspenseQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof spaceGroupControllerCheckUserSpaceGroupAccess>
    >,
    TError = void,
  >(
    spaceGroupId: string,
    options?: {
      query?: Partial<
        UseSuspenseQueryOptions<
          Awaited<
            ReturnType<typeof spaceGroupControllerCheckUserSpaceGroupAccess>
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customFetch>;
    },
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getSpaceGroupControllerCheckUserSpaceGroupAccessQueryKey(spaceGroupId);

    const queryFn: QueryFunction<
      Awaited<ReturnType<typeof spaceGroupControllerCheckUserSpaceGroupAccess>>
    > = ({ signal }) =>
      spaceGroupControllerCheckUserSpaceGroupAccess(
        spaceGroupId,
        requestOptions,
        signal,
      );

    return {
      queryKey,
      queryFn,
      enabled: !!spaceGroupId,
      ...queryOptions,
    } as UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof spaceGroupControllerCheckUserSpaceGroupAccess>>,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type SpaceGroupControllerCheckUserSpaceGroupAccessSuspenseQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof spaceGroupControllerCheckUserSpaceGroupAccess>>
  >;
export type SpaceGroupControllerCheckUserSpaceGroupAccessSuspenseQueryError =
  void;

export function useSpaceGroupControllerCheckUserSpaceGroupAccessSuspense<
  TData = Awaited<
    ReturnType<typeof spaceGroupControllerCheckUserSpaceGroupAccess>
  >,
  TError = void,
>(
  spaceGroupId: string,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<typeof spaceGroupControllerCheckUserSpaceGroupAccess>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSpaceGroupControllerCheckUserSpaceGroupAccessSuspense<
  TData = Awaited<
    ReturnType<typeof spaceGroupControllerCheckUserSpaceGroupAccess>
  >,
  TError = void,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<typeof spaceGroupControllerCheckUserSpaceGroupAccess>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSpaceGroupControllerCheckUserSpaceGroupAccessSuspense<
  TData = Awaited<
    ReturnType<typeof spaceGroupControllerCheckUserSpaceGroupAccess>
  >,
  TError = void,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<typeof spaceGroupControllerCheckUserSpaceGroupAccess>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Check user space group access
 */

export function useSpaceGroupControllerCheckUserSpaceGroupAccessSuspense<
  TData = Awaited<
    ReturnType<typeof spaceGroupControllerCheckUserSpaceGroupAccess>
  >,
  TError = void,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<typeof spaceGroupControllerCheckUserSpaceGroupAccess>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getSpaceGroupControllerCheckUserSpaceGroupAccessSuspenseQueryOptions(
      spaceGroupId,
      options,
    );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Invites a user to a space group for the authenticated user.
 * @summary Invite a user to a space group
 */
export const spaceGroupControllerInviteUserToSpaceGroup = (
  spaceGroupId: string,
  inviteMemberDto: InviteMemberDto,
  options?: SecondParameter<typeof customFetch>,
) => {
  return customFetch<SuccessMessageResponseDto>(
    {
      url: `/space-groups/${encodeURIComponent(String(spaceGroupId))}/invite`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: inviteMemberDto,
    },
    options,
  );
};

export const getSpaceGroupControllerInviteUserToSpaceGroupMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof spaceGroupControllerInviteUserToSpaceGroup>>,
    TError,
    { spaceGroupId: string; data: InviteMemberDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof spaceGroupControllerInviteUserToSpaceGroup>>,
  TError,
  { spaceGroupId: string; data: InviteMemberDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof spaceGroupControllerInviteUserToSpaceGroup>>,
    { spaceGroupId: string; data: InviteMemberDto }
  > = (props) => {
    const { spaceGroupId, data } = props ?? {};

    return spaceGroupControllerInviteUserToSpaceGroup(
      spaceGroupId,
      data,
      requestOptions,
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type SpaceGroupControllerInviteUserToSpaceGroupMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof spaceGroupControllerInviteUserToSpaceGroup>>
  >;
export type SpaceGroupControllerInviteUserToSpaceGroupMutationBody =
  InviteMemberDto;
export type SpaceGroupControllerInviteUserToSpaceGroupMutationError = void;

/**
 * @summary Invite a user to a space group
 */
export const useSpaceGroupControllerInviteUserToSpaceGroup = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof spaceGroupControllerInviteUserToSpaceGroup>>,
    TError,
    { spaceGroupId: string; data: InviteMemberDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof spaceGroupControllerInviteUserToSpaceGroup>>,
  TError,
  { spaceGroupId: string; data: InviteMemberDto },
  TContext
> => {
  const mutationOptions =
    getSpaceGroupControllerInviteUserToSpaceGroupMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Get members of a space group for the authenticated user.
 * @summary Get members of a space group
 */
export const spaceGroupControllerGetSpaceGroupMembers = (
  spaceGroupId: string,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<SpaceGroupMembersDto[]>(
    {
      url: `/space-groups/${encodeURIComponent(String(spaceGroupId))}/members`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getSpaceGroupControllerGetSpaceGroupMembersQueryKey = (
  spaceGroupId: string,
) => {
  return [`/space-groups/${spaceGroupId}/members`] as const;
};

export const getSpaceGroupControllerGetSpaceGroupMembersQueryOptions = <
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupMembers>>,
  TError = void,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupMembers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSpaceGroupControllerGetSpaceGroupMembersQueryKey(spaceGroupId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupMembers>>
  > = ({ signal }) =>
    spaceGroupControllerGetSpaceGroupMembers(
      spaceGroupId,
      requestOptions,
      signal,
    );

  return {
    queryKey,
    queryFn,
    enabled: !!spaceGroupId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupMembers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SpaceGroupControllerGetSpaceGroupMembersQueryResult = NonNullable<
  Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupMembers>>
>;
export type SpaceGroupControllerGetSpaceGroupMembersQueryError = void;

export function useSpaceGroupControllerGetSpaceGroupMembers<
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupMembers>>,
  TError = void,
>(
  spaceGroupId: string,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupMembers>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupMembers>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSpaceGroupControllerGetSpaceGroupMembers<
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupMembers>>,
  TError = void,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupMembers>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupMembers>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSpaceGroupControllerGetSpaceGroupMembers<
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupMembers>>,
  TError = void,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupMembers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get members of a space group
 */

export function useSpaceGroupControllerGetSpaceGroupMembers<
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupMembers>>,
  TError = void,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupMembers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSpaceGroupControllerGetSpaceGroupMembersQueryOptions(
    spaceGroupId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get members of a space group
 */
export const prefetchSpaceGroupControllerGetSpaceGroupMembers = async <
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupMembers>>,
  TError = void,
>(
  queryClient: QueryClient,
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupMembers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getSpaceGroupControllerGetSpaceGroupMembersQueryOptions(
    spaceGroupId,
    options,
  );

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getSpaceGroupControllerGetSpaceGroupMembersSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupMembers>>,
  TError = void,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupMembers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSpaceGroupControllerGetSpaceGroupMembersQueryKey(spaceGroupId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupMembers>>
  > = ({ signal }) =>
    spaceGroupControllerGetSpaceGroupMembers(
      spaceGroupId,
      requestOptions,
      signal,
    );

  return {
    queryKey,
    queryFn,
    enabled: !!spaceGroupId,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupMembers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SpaceGroupControllerGetSpaceGroupMembersSuspenseQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupMembers>>
  >;
export type SpaceGroupControllerGetSpaceGroupMembersSuspenseQueryError = void;

export function useSpaceGroupControllerGetSpaceGroupMembersSuspense<
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupMembers>>,
  TError = void,
>(
  spaceGroupId: string,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupMembers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSpaceGroupControllerGetSpaceGroupMembersSuspense<
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupMembers>>,
  TError = void,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupMembers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSpaceGroupControllerGetSpaceGroupMembersSuspense<
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupMembers>>,
  TError = void,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupMembers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get members of a space group
 */

export function useSpaceGroupControllerGetSpaceGroupMembersSuspense<
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupMembers>>,
  TError = void,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupMembers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getSpaceGroupControllerGetSpaceGroupMembersSuspenseQueryOptions(
      spaceGroupId,
      options,
    );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Retrieve a specific member of a space group.
 * @summary Get a space group member
 */
export const spaceGroupControllerGetSpaceGroupMember = (
  spaceGroupId: string,
  userId: string,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<SpaceGroupMembersDto>(
    {
      url: `/space-groups/${encodeURIComponent(String(spaceGroupId))}/members/${encodeURIComponent(String(userId))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getSpaceGroupControllerGetSpaceGroupMemberQueryKey = (
  spaceGroupId: string,
  userId: string,
) => {
  return [`/space-groups/${spaceGroupId}/members/${userId}`] as const;
};

export const getSpaceGroupControllerGetSpaceGroupMemberQueryOptions = <
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupMember>>,
  TError = void,
>(
  spaceGroupId: string,
  userId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupMember>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSpaceGroupControllerGetSpaceGroupMemberQueryKey(spaceGroupId, userId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupMember>>
  > = ({ signal }) =>
    spaceGroupControllerGetSpaceGroupMember(
      spaceGroupId,
      userId,
      requestOptions,
      signal,
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(spaceGroupId && userId),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupMember>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SpaceGroupControllerGetSpaceGroupMemberQueryResult = NonNullable<
  Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupMember>>
>;
export type SpaceGroupControllerGetSpaceGroupMemberQueryError = void;

export function useSpaceGroupControllerGetSpaceGroupMember<
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupMember>>,
  TError = void,
>(
  spaceGroupId: string,
  userId: string,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupMember>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupMember>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSpaceGroupControllerGetSpaceGroupMember<
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupMember>>,
  TError = void,
>(
  spaceGroupId: string,
  userId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupMember>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupMember>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSpaceGroupControllerGetSpaceGroupMember<
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupMember>>,
  TError = void,
>(
  spaceGroupId: string,
  userId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupMember>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get a space group member
 */

export function useSpaceGroupControllerGetSpaceGroupMember<
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupMember>>,
  TError = void,
>(
  spaceGroupId: string,
  userId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupMember>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSpaceGroupControllerGetSpaceGroupMemberQueryOptions(
    spaceGroupId,
    userId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get a space group member
 */
export const prefetchSpaceGroupControllerGetSpaceGroupMember = async <
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupMember>>,
  TError = void,
>(
  queryClient: QueryClient,
  spaceGroupId: string,
  userId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupMember>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getSpaceGroupControllerGetSpaceGroupMemberQueryOptions(
    spaceGroupId,
    userId,
    options,
  );

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getSpaceGroupControllerGetSpaceGroupMemberSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupMember>>,
  TError = void,
>(
  spaceGroupId: string,
  userId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupMember>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSpaceGroupControllerGetSpaceGroupMemberQueryKey(spaceGroupId, userId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupMember>>
  > = ({ signal }) =>
    spaceGroupControllerGetSpaceGroupMember(
      spaceGroupId,
      userId,
      requestOptions,
      signal,
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(spaceGroupId && userId),
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupMember>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SpaceGroupControllerGetSpaceGroupMemberSuspenseQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupMember>>
  >;
export type SpaceGroupControllerGetSpaceGroupMemberSuspenseQueryError = void;

export function useSpaceGroupControllerGetSpaceGroupMemberSuspense<
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupMember>>,
  TError = void,
>(
  spaceGroupId: string,
  userId: string,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupMember>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSpaceGroupControllerGetSpaceGroupMemberSuspense<
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupMember>>,
  TError = void,
>(
  spaceGroupId: string,
  userId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupMember>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSpaceGroupControllerGetSpaceGroupMemberSuspense<
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupMember>>,
  TError = void,
>(
  spaceGroupId: string,
  userId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupMember>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get a space group member
 */

export function useSpaceGroupControllerGetSpaceGroupMemberSuspense<
  TData = Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupMember>>,
  TError = void,
>(
  spaceGroupId: string,
  userId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof spaceGroupControllerGetSpaceGroupMember>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getSpaceGroupControllerGetSpaceGroupMemberSuspenseQueryOptions(
      spaceGroupId,
      userId,
      options,
    );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Removes a user from a space group.
 * @summary Remove a user from a space group
 */
export const spaceGroupControllerRemoveUserFromSpaceGroup = (
  spaceGroupId: string,
  userId: string,
  options?: SecondParameter<typeof customFetch>,
) => {
  return customFetch<SuccessMessageResponseDto>(
    {
      url: `/space-groups/${encodeURIComponent(String(spaceGroupId))}/users/${encodeURIComponent(String(userId))}`,
      method: 'DELETE',
    },
    options,
  );
};

export const getSpaceGroupControllerRemoveUserFromSpaceGroupMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof spaceGroupControllerRemoveUserFromSpaceGroup>>,
    TError,
    { spaceGroupId: string; userId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof spaceGroupControllerRemoveUserFromSpaceGroup>>,
  TError,
  { spaceGroupId: string; userId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof spaceGroupControllerRemoveUserFromSpaceGroup>>,
    { spaceGroupId: string; userId: string }
  > = (props) => {
    const { spaceGroupId, userId } = props ?? {};

    return spaceGroupControllerRemoveUserFromSpaceGroup(
      spaceGroupId,
      userId,
      requestOptions,
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type SpaceGroupControllerRemoveUserFromSpaceGroupMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof spaceGroupControllerRemoveUserFromSpaceGroup>>
  >;

export type SpaceGroupControllerRemoveUserFromSpaceGroupMutationError = void;

/**
 * @summary Remove a user from a space group
 */
export const useSpaceGroupControllerRemoveUserFromSpaceGroup = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof spaceGroupControllerRemoveUserFromSpaceGroup>>,
    TError,
    { spaceGroupId: string; userId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof spaceGroupControllerRemoveUserFromSpaceGroup>>,
  TError,
  { spaceGroupId: string; userId: string },
  TContext
> => {
  const mutationOptions =
    getSpaceGroupControllerRemoveUserFromSpaceGroupMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Updates the settings for direct messaging and meetings in a space group.
 * @summary Update direct messaging and meeting settings for a space group
 */
export const spaceGroupControllerUpdateSettings = (
  spaceGroupId: string,
  updateSpaceGroupMonetizationSettingsDto: UpdateSpaceGroupMonetizationSettingsDto,
  options?: SecondParameter<typeof customFetch>,
) => {
  return customFetch<SuccessMessageResponseDto>(
    {
      url: `/space-groups/${encodeURIComponent(String(spaceGroupId))}/monetization-settings`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: updateSpaceGroupMonetizationSettingsDto,
    },
    options,
  );
};

export const getSpaceGroupControllerUpdateSettingsMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof spaceGroupControllerUpdateSettings>>,
    TError,
    { spaceGroupId: string; data: UpdateSpaceGroupMonetizationSettingsDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof spaceGroupControllerUpdateSettings>>,
  TError,
  { spaceGroupId: string; data: UpdateSpaceGroupMonetizationSettingsDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof spaceGroupControllerUpdateSettings>>,
    { spaceGroupId: string; data: UpdateSpaceGroupMonetizationSettingsDto }
  > = (props) => {
    const { spaceGroupId, data } = props ?? {};

    return spaceGroupControllerUpdateSettings(
      spaceGroupId,
      data,
      requestOptions,
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type SpaceGroupControllerUpdateSettingsMutationResult = NonNullable<
  Awaited<ReturnType<typeof spaceGroupControllerUpdateSettings>>
>;
export type SpaceGroupControllerUpdateSettingsMutationBody =
  UpdateSpaceGroupMonetizationSettingsDto;
export type SpaceGroupControllerUpdateSettingsMutationError = void;

/**
 * @summary Update direct messaging and meeting settings for a space group
 */
export const useSpaceGroupControllerUpdateSettings = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof spaceGroupControllerUpdateSettings>>,
    TError,
    { spaceGroupId: string; data: UpdateSpaceGroupMonetizationSettingsDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof spaceGroupControllerUpdateSettings>>,
  TError,
  { spaceGroupId: string; data: UpdateSpaceGroupMonetizationSettingsDto },
  TContext
> => {
  const mutationOptions =
    getSpaceGroupControllerUpdateSettingsMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Retrieves detailed information about a space group using its payment link URL.
 * @summary Get space group by payment link
 */
export const checkoutEndpointControllerGetSpaceGroupByPaymentLink = (
  paymentLinkUrl: string,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<SpaceGroupResponseDto>(
    {
      url: `/checkout/${encodeURIComponent(String(paymentLinkUrl))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getCheckoutEndpointControllerGetSpaceGroupByPaymentLinkQueryKey = (
  paymentLinkUrl: string,
) => {
  return [`/checkout/${paymentLinkUrl}`] as const;
};

export const getCheckoutEndpointControllerGetSpaceGroupByPaymentLinkQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof checkoutEndpointControllerGetSpaceGroupByPaymentLink>
    >,
    TError = CheckoutEndpointControllerGetSpaceGroupByPaymentLink404,
  >(
    paymentLinkUrl: string,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof checkoutEndpointControllerGetSpaceGroupByPaymentLink
            >
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customFetch>;
    },
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getCheckoutEndpointControllerGetSpaceGroupByPaymentLinkQueryKey(
        paymentLinkUrl,
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof checkoutEndpointControllerGetSpaceGroupByPaymentLink>
      >
    > = ({ signal }) =>
      checkoutEndpointControllerGetSpaceGroupByPaymentLink(
        paymentLinkUrl,
        requestOptions,
        signal,
      );

    return {
      queryKey,
      queryFn,
      enabled: !!paymentLinkUrl,
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<typeof checkoutEndpointControllerGetSpaceGroupByPaymentLink>
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type CheckoutEndpointControllerGetSpaceGroupByPaymentLinkQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof checkoutEndpointControllerGetSpaceGroupByPaymentLink>
    >
  >;
export type CheckoutEndpointControllerGetSpaceGroupByPaymentLinkQueryError =
  CheckoutEndpointControllerGetSpaceGroupByPaymentLink404;

export function useCheckoutEndpointControllerGetSpaceGroupByPaymentLink<
  TData = Awaited<
    ReturnType<typeof checkoutEndpointControllerGetSpaceGroupByPaymentLink>
  >,
  TError = CheckoutEndpointControllerGetSpaceGroupByPaymentLink404,
>(
  paymentLinkUrl: string,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof checkoutEndpointControllerGetSpaceGroupByPaymentLink
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof checkoutEndpointControllerGetSpaceGroupByPaymentLink
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useCheckoutEndpointControllerGetSpaceGroupByPaymentLink<
  TData = Awaited<
    ReturnType<typeof checkoutEndpointControllerGetSpaceGroupByPaymentLink>
  >,
  TError = CheckoutEndpointControllerGetSpaceGroupByPaymentLink404,
>(
  paymentLinkUrl: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof checkoutEndpointControllerGetSpaceGroupByPaymentLink
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof checkoutEndpointControllerGetSpaceGroupByPaymentLink
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useCheckoutEndpointControllerGetSpaceGroupByPaymentLink<
  TData = Awaited<
    ReturnType<typeof checkoutEndpointControllerGetSpaceGroupByPaymentLink>
  >,
  TError = CheckoutEndpointControllerGetSpaceGroupByPaymentLink404,
>(
  paymentLinkUrl: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof checkoutEndpointControllerGetSpaceGroupByPaymentLink
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get space group by payment link
 */

export function useCheckoutEndpointControllerGetSpaceGroupByPaymentLink<
  TData = Awaited<
    ReturnType<typeof checkoutEndpointControllerGetSpaceGroupByPaymentLink>
  >,
  TError = CheckoutEndpointControllerGetSpaceGroupByPaymentLink404,
>(
  paymentLinkUrl: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof checkoutEndpointControllerGetSpaceGroupByPaymentLink
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getCheckoutEndpointControllerGetSpaceGroupByPaymentLinkQueryOptions(
      paymentLinkUrl,
      options,
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get space group by payment link
 */
export const prefetchCheckoutEndpointControllerGetSpaceGroupByPaymentLink =
  async <
    TData = Awaited<
      ReturnType<typeof checkoutEndpointControllerGetSpaceGroupByPaymentLink>
    >,
    TError = CheckoutEndpointControllerGetSpaceGroupByPaymentLink404,
  >(
    queryClient: QueryClient,
    paymentLinkUrl: string,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof checkoutEndpointControllerGetSpaceGroupByPaymentLink
            >
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customFetch>;
    },
  ): Promise<QueryClient> => {
    const queryOptions =
      getCheckoutEndpointControllerGetSpaceGroupByPaymentLinkQueryOptions(
        paymentLinkUrl,
        options,
      );

    await queryClient.prefetchQuery(queryOptions);

    return queryClient;
  };

export const getCheckoutEndpointControllerGetSpaceGroupByPaymentLinkSuspenseQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof checkoutEndpointControllerGetSpaceGroupByPaymentLink>
    >,
    TError = CheckoutEndpointControllerGetSpaceGroupByPaymentLink404,
  >(
    paymentLinkUrl: string,
    options?: {
      query?: Partial<
        UseSuspenseQueryOptions<
          Awaited<
            ReturnType<
              typeof checkoutEndpointControllerGetSpaceGroupByPaymentLink
            >
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customFetch>;
    },
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getCheckoutEndpointControllerGetSpaceGroupByPaymentLinkQueryKey(
        paymentLinkUrl,
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof checkoutEndpointControllerGetSpaceGroupByPaymentLink>
      >
    > = ({ signal }) =>
      checkoutEndpointControllerGetSpaceGroupByPaymentLink(
        paymentLinkUrl,
        requestOptions,
        signal,
      );

    return {
      queryKey,
      queryFn,
      enabled: !!paymentLinkUrl,
      ...queryOptions,
    } as UseSuspenseQueryOptions<
      Awaited<
        ReturnType<typeof checkoutEndpointControllerGetSpaceGroupByPaymentLink>
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type CheckoutEndpointControllerGetSpaceGroupByPaymentLinkSuspenseQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof checkoutEndpointControllerGetSpaceGroupByPaymentLink>
    >
  >;
export type CheckoutEndpointControllerGetSpaceGroupByPaymentLinkSuspenseQueryError =
  CheckoutEndpointControllerGetSpaceGroupByPaymentLink404;

export function useCheckoutEndpointControllerGetSpaceGroupByPaymentLinkSuspense<
  TData = Awaited<
    ReturnType<typeof checkoutEndpointControllerGetSpaceGroupByPaymentLink>
  >,
  TError = CheckoutEndpointControllerGetSpaceGroupByPaymentLink404,
>(
  paymentLinkUrl: string,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<
            typeof checkoutEndpointControllerGetSpaceGroupByPaymentLink
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useCheckoutEndpointControllerGetSpaceGroupByPaymentLinkSuspense<
  TData = Awaited<
    ReturnType<typeof checkoutEndpointControllerGetSpaceGroupByPaymentLink>
  >,
  TError = CheckoutEndpointControllerGetSpaceGroupByPaymentLink404,
>(
  paymentLinkUrl: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<
            typeof checkoutEndpointControllerGetSpaceGroupByPaymentLink
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useCheckoutEndpointControllerGetSpaceGroupByPaymentLinkSuspense<
  TData = Awaited<
    ReturnType<typeof checkoutEndpointControllerGetSpaceGroupByPaymentLink>
  >,
  TError = CheckoutEndpointControllerGetSpaceGroupByPaymentLink404,
>(
  paymentLinkUrl: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<
            typeof checkoutEndpointControllerGetSpaceGroupByPaymentLink
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get space group by payment link
 */

export function useCheckoutEndpointControllerGetSpaceGroupByPaymentLinkSuspense<
  TData = Awaited<
    ReturnType<typeof checkoutEndpointControllerGetSpaceGroupByPaymentLink>
  >,
  TError = CheckoutEndpointControllerGetSpaceGroupByPaymentLink404,
>(
  paymentLinkUrl: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<
            typeof checkoutEndpointControllerGetSpaceGroupByPaymentLink
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getCheckoutEndpointControllerGetSpaceGroupByPaymentLinkSuspenseQueryOptions(
      paymentLinkUrl,
      options,
    );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}
