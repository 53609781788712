export const DocumentIcon = (props) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="vuesax/linear/document">
      <g id="document">
        <path
          id="Vector"
          d="M5.9987 15.1673H9.9987C13.332 15.1673 14.6654 13.834 14.6654 10.5007V6.50065C14.6654 3.16732 13.332 1.83398 9.9987 1.83398H5.9987C2.66536 1.83398 1.33203 3.16732 1.33203 6.50065V10.5007C1.33203 13.834 2.66536 15.1673 5.9987 15.1673Z"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M10.5 6.5H5.5"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M10.5 10.5H5.5"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
  </svg>
);
