/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * Teachly API V2
 * Teachly API documentation
 * OpenAPI spec version: 1.0
 */

/**
 * El rol del usuario en la marca
 */
export type CreateBrandInviteDtoRole =
  (typeof CreateBrandInviteDtoRole)[keyof typeof CreateBrandInviteDtoRole];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateBrandInviteDtoRole = {
  ADMIN: 'ADMIN',
  MODERATOR: 'MODERATOR',
} as const;
