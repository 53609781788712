import { NavLink, useParams } from 'react-router-dom';

import { DiscussionDetails } from '@/api';
import { UserIcon } from '@/assets/icon/user';
import PostViewer from '@/components/post-components/PostViewer';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { cn, valueOrUndefined } from '@/lib/utils';

type Props = {
  discussion: DiscussionDetails;
  handleOpen: (isOpen: boolean) => void;
  findUser: (id: string) => { username: string } | null;
  onMentionClick: (uid: string) => void;
};

const DiscussionCard = ({
  discussion,
  handleOpen,
  findUser,
  onMentionClick,
}: Props) => {
  const { brandId, spaceGroupId, postsSpaceId } = useParams();
  return (
    <NavLink
      to={`/brands/${brandId}/space-groups/${spaceGroupId}/posts-spaces/${postsSpaceId}/joined-discussions/${discussion.id}`}
      onClick={() => handleOpen(false)}
      className={({ isActive }) =>
        cn(
          'flex w-full cursor-pointer flex-col gap-2.5 border-b border-light pb-2.5 text-sm font-medium text-black opacity-50 dark:border-dark-light dark:text-white',
          isActive && 'opacity-100',
        )
      }
    >
      <PostViewer
        value={discussion.post.title || discussion.post.content}
        findUser={findUser}
        onMentionClick={onMentionClick}
      />
      <div className="flex items-center gap-2.5">
        <div className="relative flex">
          {discussion.userDiscussions[0] && (
            <Avatar className="h-8 w-8">
              <AvatarImage
                src={valueOrUndefined(
                  discussion.userDiscussions[0].user.avatarUrl,
                )}
              />
              <AvatarFallback>
                <UserIcon className="stroke-textParagraph p-1 dark:stroke-white" />
              </AvatarFallback>
            </Avatar>
          )}
          {discussion.userDiscussions[1] && (
            <Avatar className="h-8 w-8">
              <AvatarImage
                src={valueOrUndefined(
                  discussion.userDiscussions[1].user.avatarUrl,
                )}
              />
              <AvatarFallback>
                <UserIcon className="stroke-textParagraph p-1 dark:stroke-white" />
              </AvatarFallback>
            </Avatar>
          )}
        </div>
        <p className="text-base text-brand">
          {discussion.userCount}{' '}
          {discussion.userCount === 1 ? 'participant' : 'participants'}
        </p>
      </div>
    </NavLink>
  );
};

export default DiscussionCard;
