import { CometChat } from '@cometchat/chat-sdk-javascript';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { UserIcon } from 'lucide-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import {
  MessageAvailabilityResponseDto,
  monetizationControllerGetMessageAvailability,
  userControllerGetUserRole,
} from '@/api';
import { ArrowLeftIcon } from '@/assets/icon/arrowLeft';
import { ChatBubbleLockedIcon } from '@/assets/icon/chatBubbleLocked';
import { Loading } from '@/components/Loading';
import ChatInput from '@/components/ui/chats-components/ChatInput';
import ChatMessage from '@/components/ui/chats-components/ChatMessage';
import { useAuth } from '@/hooks/useContext';
import { getEntries } from '@/lib/utils';

import { Avatar, AvatarFallback, AvatarImage } from '../ui/avatar';
import { Button } from '../ui/button';

export const CometChatOneToOne = ({
  selectedUserId,
  isOpen = true,
}: {
  selectedUserId: string;
  onClose?: () => void;
  isOpen?: boolean;
}) => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const [messageInput, setMessageInput] = useState<string>('');
  const [fileInput, setFileInput] = useState<File | null>(null);
  const [monetizationData, setMonetizationData] =
    useState<MessageAvailabilityResponseDto>();
  const [isSelectedUserCreator, setIsSelectedUserCreator] =
    useState<boolean>(false);
  const [isInputVisible, setIsInputVisible] = useState<boolean>(true);
  const [userRole, setUserRole] = useState<string>();

  const { brandId } = useParams();

  const lastScrollTop = useRef<number>(0);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const endMessageRef = useRef<HTMLDivElement>(null);

  async function getMessages() {
    const limit: number = 100;
    const messagesRequest: CometChat.MessagesRequest =
      new CometChat.MessagesRequestBuilder()
        .setUID(selectedUserId)
        .setLimit(limit)
        .hideReplies(true)
        .setTypes([CometChat.MESSAGE_TYPE.TEXT, CometChat.MESSAGE_TYPE.IMAGE])
        .build();

    const messages = await messagesRequest.fetchPrevious();

    return messages;
  }

  const chatsQuery = useQuery({
    queryKey: ['cometchat', 'one-to-one', selectedUserId],
    queryFn: () => getMessages(),
  });

  async function getUser() {
    const user = await CometChat.getUser(selectedUserId);
    return user;
  }

  const selectedUserQuery = useQuery({
    queryKey: ['cometchat', 'user', selectedUserId],
    queryFn: () => getUser(),
  });

  const scrollToBottom = useCallback(
    (args?: { smooth?: boolean; timeout?: number }) => {
      setTimeout(() => {
        if (!endMessageRef.current) return;
        const smooth = args?.smooth ?? true;
        endMessageRef.current.scrollIntoView({
          behavior: smooth ? 'smooth' : 'instant',
        });
      }, args?.timeout ?? 500);
    },
    [endMessageRef],
  );

  const handleScrollAndHideTextInput = () => {
    const container = containerRef.current;
    if (!container) return;

    const scrollTop = container.scrollTop;
    // const isNearBottom =
    //   container.scrollHeight - container.scrollTop <=
    //   container.clientHeight + 100;

    if (scrollTop > lastScrollTop.current) {
      setIsInputVisible(true);
    } else if (scrollTop < lastScrollTop.current) {
      setIsInputVisible(false);
    }

    lastScrollTop.current = scrollTop;
  };

  const handleGoToBuyMessages = () => {
    navigate(`/checkout/brands/${brandId}/chats/${selectedUserId}`);
  };

  const fetchMonetizationData = async () => {
    monetizationControllerGetMessageAvailability(selectedUserId).then((res) => {
      if (res) setMonetizationData(res);
    });
  };

  const fetchUserRole = async () => {
    if (brandId && selectedUserId)
      userControllerGetUserRole(brandId, selectedUserId).then((res) => {
        if (res) {
          setIsSelectedUserCreator(res.role.toLowerCase() === 'creator');
          if (res.role.toLowerCase() === 'creator') {
            fetchMonetizationData();
          }
        }
      });
  };

  const fetchCurrentUserRole = async () => {
    if (user?.id && brandId) {
      userControllerGetUserRole(brandId, user.id).then((res) => {
        if (res) {
          setUserRole(res.role);
        }
      });
    }
  };

  useEffect(() => {
    fetchCurrentUserRole();
  }, []);

  useEffect(() => {
    if (chatsQuery.isFetchedAfterMount || chatsQuery.isFetched) {
      scrollToBottom({ smooth: false, timeout: 0 });
    }
  }, [scrollToBottom, chatsQuery.isFetchedAfterMount, chatsQuery.isFetched]);

  useEffect(() => {
    if (selectedUserId) {
      fetchUserRole();
    }
  }, [selectedUserId]);

  const queryClient = useQueryClient();

  const invalidateConversationList = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: ['cometchat', 'conversations'] });
  }, [queryClient]);

  useEffect(() => {
    const listenerID = `CHAT_LISTENER_ONE_TO_ONE_${selectedUserId}`;
    CometChat.addMessageListener(
      listenerID,
      new CometChat.MessageListener({
        onTextMessageReceived: (textMessage: CometChat.TextMessage) => {
          // check text message belongs to this user

          const sender = textMessage.getSender();
          if (!(sender instanceof CometChat.User)) return;
          if (sender.getUid() !== selectedUserId) return;
          if (textMessage.getParentMessageId()) return;

          queryClient.setQueryData(
            ['cometchat', 'one-to-one', selectedUserId],
            (oldData: CometChat.BaseMessage[] | undefined) => {
              if (!oldData) return [textMessage];
              return [...oldData, textMessage];
            },
          );
          scrollToBottom();
          invalidateConversationList();
        },
        onMediaMessageReceived: (mediaMessage: CometChat.MediaMessage) => {
          // check media message belongs to this user
          const sender = mediaMessage.getSender();
          if (!(sender instanceof CometChat.User)) return;
          if (sender.getUid() !== selectedUserId) return;
          if (mediaMessage.getParentMessageId()) return;

          if (mediaMessage.getType() !== CometChat.MESSAGE_TYPE.IMAGE) return;

          queryClient.setQueryData(
            ['cometchat', 'one-to-one', selectedUserId],
            (oldData: CometChat.BaseMessage[] | undefined) => {
              if (!oldData) return [mediaMessage];
              return [...oldData, mediaMessage];
            },
          );
          scrollToBottom();
          invalidateConversationList();
        },
      }),
    );

    return () => {
      CometChat.removeMessageListener(listenerID);
    };
  }, [selectedUserId, scrollToBottom, queryClient, invalidateConversationList]);

  const sendMessageMutation = useMutation({
    mutationFn: async () => {
      const receiverID = selectedUserId;
      const messageText = messageInput.trim();
      const receiverType = CometChat.RECEIVER_TYPE.USER;

      let messageToSend: CometChat.BaseMessage;

      if (fileInput) {
        const messageType = fileInput.type.startsWith('image/')
          ? CometChat.MESSAGE_TYPE.IMAGE
          : CometChat.MESSAGE_TYPE.VIDEO;

        const mediaMessage = new CometChat.MediaMessage(
          receiverID,
          fileInput,
          messageType,
          receiverType,
        );

        if (messageText.length > 0) {
          mediaMessage.setCaption(messageText);
        }

        messageToSend = mediaMessage;
      } else {
        messageToSend = new CometChat.TextMessage(
          receiverID,
          messageText,
          receiverType,
        );
      }

      const message = await CometChat.sendMessage(messageToSend);

      queryClient.setQueryData(
        ['cometchat', 'one-to-one', selectedUserId],
        (oldData: CometChat.BaseMessage[] | undefined) => {
          if (!oldData) return [message];
          return [...oldData, message];
        },
      );

      setMessageInput('');
      setFileInput(null);
      scrollToBottom();
      invalidateConversationList();
    },
  });

  const handleSendMessage = async () => {
    if (
      (monetizationData?.free?.remaining ?? 0) +
        (monetizationData?.purchased?.remaining ?? 0) ===
        0 &&
      isSelectedUserCreator &&
      userRole === 'FAN'
    ) {
      return;
    }

    if (messageInput.trim().length > 0 || !!fileInput) {
      await sendMessageMutation.mutateAsync().then(() => {
        setMessageInput('');
      });
      fetchUserRole();
    }
  };

  const handleReact = async (messageId: string, reaction: string) => {
    const messageRes = await CometChat.addReaction(messageId, reaction);
    queryClient.setQueryData(
      ['cometchat', 'one-to-one', selectedUserId],
      (oldData: CometChat.BaseMessage[] | undefined) => {
        if (!oldData) return [messageRes];
        return oldData.map((message) =>
          message.getId().toString() === messageId ? messageRes : message,
        );
      },
    );
  };

  const users: any[] = [];

  const handleMentionSearch = useCallback(
    (search: string) => {
      if (!search) return [];
      return users
        .filter((user) =>
          user.name?.toLowerCase().includes(search.toLowerCase()),
        )
        .map((user) => ({
          id: user.id ?? '',
          value: user.name ?? '',
          cometChatId: user.cometChatUid ?? '',
          name: user.name ?? '',
          avatarUrl: user.avatarUrl ?? '',
        }));
    },
    [users],
  );

  type MessagesByDate = Record<string, CometChat.BaseMessage[]>;

  const messages = chatsQuery.data ?? [];

  const lastMessage = messages.at(-1);

  useEffect(() => {
    if (!lastMessage) return;

    const markAsRead = async () => {
      await CometChat.markAsRead(lastMessage);
      invalidateConversationList();
    };

    markAsRead();
  }, [lastMessage?.getId()]);

  if (chatsQuery.isLoading || selectedUserQuery.isLoading) {
    return <Loading />;
  }

  const messagesByDate = messages.reduce<MessagesByDate>((acc, message) => {
    const date = new Date(message.getSentAt() * 1000).toDateString();
    (acc[date] ??= []).push(message);
    return acc;
  }, {});

  // if messagesByDate is empty, return an empty array
  const messagesByDateEntries =
    Object.keys(messagesByDate).length > 0 ? getEntries(messagesByDate) : [];

  const selectedUser = selectedUserQuery.data;

  if (chatsQuery.isLoading || selectedUserQuery.isLoading) {
    return <Loading />;
  }

  return (
    <div
      className={`relative ${isOpen ? 'flex' : 'hidden lg:flex'} h-full w-full flex-col overflow-hidden`}
    >
      {selectedUser && (
        <div
          className={`flex items-center gap-2.5 border-b border-b-light px-6 pb-4 transition-transform duration-300 dark:border-b-dark-light sm:pt-6 ${
            isInputVisible
              ? 'translate-y-0'
              : 'absolute left-0 right-0 top-0 -translate-y-full md:static md:-translate-y-0'
          }`}
        >
          <Link
            className="flex lg:hidden"
            to={brandId ? `/brands/${brandId}/chats` : '/chats'}
          >
            <Button variant="icon" size="icon">
              <ArrowLeftIcon className="h-5 w-5 fill-black dark:fill-white" />
            </Button>
          </Link>
          <Avatar className="h-10 w-10">
            <AvatarImage src={selectedUser.getAvatar()} />
            <AvatarFallback className="h-10 w-10">
              <UserIcon className="stroke-textParagraph p-1 dark:stroke-white" />
            </AvatarFallback>
          </Avatar>
          <div className="flex flex-col">
            <p className="text-base font-medium text-black dark:text-white">
              {selectedUser.getName()}
            </p>
          </div>
          {isSelectedUserCreator && userRole === 'FAN' && (
            <div className="flex w-full items-center justify-end gap-1">
              <Button
                variant="ghost"
                onClick={handleGoToBuyMessages}
                className="border border-light text-sm dark:border-dark-light"
              >
                Credits (
                {(monetizationData?.free?.remaining ?? 0) +
                  (monetizationData?.purchased?.remaining ?? 0)}
                )
              </Button>
            </div>
          )}
        </div>
      )}
      <div
        ref={containerRef}
        onScroll={handleScrollAndHideTextInput}
        className="flex flex-1 flex-col gap-8 overflow-y-auto p-6 pb-0"
      >
        <div>
          {messagesByDateEntries.length === 0 && (
            <p className="text-center text-textParagraph dark:text-dark-textParagraph">
              No messages yet
            </p>
          )}
          {messagesByDateEntries.length > 0 &&
            messagesByDateEntries.map(([date, chats]) => (
              <div key={date} className="flex flex-col gap-2">
                <div className="flex items-center gap-2.5">
                  <p className="text-nowrap text-base font-medium text-black dark:text-white">
                    {date === new Date().toDateString()
                      ? 'Today'
                      : date ===
                          new Date(
                            new Date().setDate(new Date().getDate() - 1),
                          ).toDateString()
                        ? 'Yesterday'
                        : date}
                  </p>
                  <hr className="w-full border-t border-t-light dark:border-t-dark-light" />
                </div>
                {chats.map((msg) => (
                  <ChatMessage
                    key={msg.getId()}
                    message={msg}
                    users={users}
                    onReact={handleReact}
                    hideThread={true}
                  />
                ))}
              </div>
            ))}
          <div className="mb-4" ref={endMessageRef} />
        </div>
      </div>
      <div
        className={`h-fit transition-transform duration-300 md:!translate-y-0 ${
          isInputVisible
            ? 'translate-y-0'
            : 'absolute bottom-0 left-0 right-0 translate-y-full md:static md:-translate-y-0'
        }`}
      >
        <div className="relative w-full bg-white p-6 dark:bg-dark-1 lg:bg-light-2 lg:dark:bg-dark-2">
          <ChatInput
            message={messageInput}
            setMessage={setMessageInput}
            onSendMessage={handleSendMessage}
            isLoading={sendMessageMutation.isPending}
            fileInput={fileInput}
            setFileInput={setFileInput}
            onMentionSearch={handleMentionSearch}
          />
          {isSelectedUserCreator &&
            !monetizationData?.free?.remaining &&
            !monetizationData?.purchased?.remaining &&
            monetizationData?.free?.type !== 'UNLIMITED' &&
            userRole === 'FAN' && (
              <div className="absolute inset-0 z-30 flex flex-col items-center justify-around gap-5 px-6 py-2.5 backdrop-blur-[9.6px]">
                <div className="flex flex-col items-center gap-1">
                  <div className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-md border border-light dark:border-dark-light">
                    <ChatBubbleLockedIcon className="h-6 w-6 flex-shrink-0 stroke-black dark:stroke-white" />
                  </div>
                  Unlock messaging access to the creator
                </div>
                <Button onClick={handleGoToBuyMessages}>Unlock Now</Button>
              </div>
            )}
        </div>
      </div>
    </div>
  );
};
