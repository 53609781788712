import Quill from 'quill';
import MagicUrl from 'quill-magic-url';
import { Mention, MentionBlot } from 'quill-mention';
import { forwardRef, useEffect, useLayoutEffect, useRef } from 'react';
import 'react-quill/dist/quill.snow.css';

import { chatHtmlToMarkdown, markdownToHtml } from '@/lib/parser';
import { cn } from '@/lib/utils';

import './quill-editor.css';

Quill.register({ 'blots/mention': MentionBlot, 'modules/mention': Mention });
Quill.register('modules/magicUrl', MagicUrl);

interface EditorProps {
  readOnly?: boolean;
  defaultValue?: string;
  onTextChange?: ({
    markdown,
    html,
  }: {
    markdown: string;
    html: string;
  }) => void;
  onSelectionChange?: (...args: any[]) => void;
  onSendMessage: () => Promise<void>;
  className?: string;
  onMentionSearch: (search: string) => {
    id: string;
    value: string;
    cometChatId: string;
    name: string;
    avatarUrl: string;
  }[];
  findUserById?: (id: string) => {
    id: string;
    value: string;
    cometChatId: string;
    name: string;
    avatarUrl: string;
  } | null;
}

// quill mentions look like this:
// {
/* <span
  class="mention"
  data-index="0"
  data-denotation-char="@"
  data-id="85491b5f-2de3-47ed-95e3-a2deff098c29"
  data-value="joaquin mushroom"
  data-comet-chat-id="85491b5f-2de3-47ed-95e3-a2deff098c29"
  data-name="joaquin mushroom"
  data-avatar-url="https://teachly-creator-public-media-dev.s3.us-west-2.amazonaws.com/creator_public/avatars/85491b5f-2de3-47ed-95e3-a2deff098c29-1728310086488.jpg"
>
  ﻿<span contenteditable="false">@joaquin mushroom</span>﻿
</span>; */
// }

const convertToHtml = (
  str: string,
  findUserById: (id: string) => {
    id: string;
    value: string;
    cometChatId: string;
    name: string;
    avatarUrl: string;
  } | null,
) => {
  const initialHtml = markdownToHtml(str);

  // parse mentions into quill mentions, mention looks like this:
  // <@uid:85491b5f-2de3-47ed-95e3-a2deff098c29>
  // regex that captures the uid

  const mentionRegex = /<@uid:([0-9a-f-]+)>/g;

  const quillMentions = initialHtml.replace(mentionRegex, (mention) => {
    const id = mention.replace(/<@uid:|>/g, '');
    const user = findUserById(id) ?? {
      id,
      value: 'Unknown User',
      cometChatId: '',
      name: 'Unknown User',
      avatarUrl: '',
    };

    return `<span
      class="mention"
      data-index="0"
      data-denotation-char="@"
      data-id="${user.id}"
      data-value="${user.value}"
      data-comet-chat-id="${user.cometChatId}"
      data-name="${user.name}"
      data-avatar-url="${user.avatarUrl}"
    >
      <span contenteditable="false">@${user.name}</span>
    </span>`;
  });

  return quillMentions;
};

// Editor is an uncontrolled React component
const Editor = forwardRef<Quill, EditorProps>(
  (
    {
      readOnly = false,
      defaultValue,
      onTextChange,
      onSelectionChange,
      onSendMessage,
      className,
      onMentionSearch,
      findUserById,
    },
    ref,
  ) => {
    const defaultValueHtml =
      defaultValue && findUserById
        ? convertToHtml(defaultValue, findUserById)
        : '';

    const containerRef = useRef<HTMLDivElement | null>(null);
    const defaultValueRef = useRef(defaultValueHtml);
    const onTextChangeRef = useRef(onTextChange);
    const onSelectionChangeRef = useRef(onSelectionChange);
    const onMentionSearchRef = useRef(onMentionSearch);

    const handleSubmit = async () => {
      await onSendMessage();
      onTextChange?.({ markdown: '', html: '' });
      defaultValueRef.current = '';
      // @ts-expect-error
      ref.current?.setSelection(0, 0);
      // @ts-expect-error
      ref.current?.setContents('');
    };

    const onSendMessageRef = useRef(handleSubmit);

    useLayoutEffect(() => {
      onTextChangeRef.current = onTextChange;
      onSelectionChangeRef.current = onSelectionChange;
      onSendMessageRef.current = handleSubmit;
      onMentionSearchRef.current = onMentionSearch;
      defaultValueRef.current = defaultValueHtml;
    });

    useEffect(() => {
      // @ts-expect-error
      if (ref.current) {
        // @ts-expect-error
        ref.current.enable(!readOnly);
      }
    }, [ref, readOnly]);

    useEffect(() => {
      const container = containerRef.current;
      if (!container) return;

      const editorContainer = container.appendChild(
        container.ownerDocument.createElement('div'),
      );

      const mention = onMentionSearchRef.current
        ? {
            allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
            mentionDenotationChars: ['@'],
            defaultMenuOrientation: 'bottom',
            offsetTop: 20,
            dataAttributes: [
              'id',
              'value',
              'denotationChar',
              'link',
              'target',
              'name',
              'avatarUrl',
              'cometChatId',
            ],
            source: (
              searchTerm: string,
              renderList: (
                values: {
                  id: string;
                  value: string;
                  cometChatId: string;
                  name: string;
                  avatarUrl: string;
                }[],
                searchTerm: string,
              ) => void,
              _mentionChar: string,
            ) => {
              const matches = onMentionSearchRef.current?.(searchTerm);
              if (!matches) return;
              renderList(matches, searchTerm);
            },
            renderItem: (
              item: {
                id: string;
                value: string;
                cometChatId: string;
                name: string;
                avatarUrl: string;
              },
              _searchTerm: string,
            ): Node => {
              const img = document.createElement('img');
              img.src = item.avatarUrl;
              img.alt = item.name;
              img.className = 'h-5 w-5 rounded-md';
              const span = document.createElement('span');
              span.textContent = item.name;
              const container = document.createElement('div');
              container.className = 'flex items-center gap-3';
              container.appendChild(img);
              container.appendChild(span);
              return container;
            },
          }
        : null;

      const quill = new Quill(editorContainer, {
        theme: 'snow',
        formats: ['link', 'mention'],
        placeholder: 'Type a message',
        modules: {
          toolbar: null,
          keyboard: {
            bindings: {},
          },
          clipboard: {
            matchVisual: false,
          },
          history: {
            delay: 1000,
            maxStack: 500,
            userOnly: true,
          },
          magicUrl: true,
          mention,
        },
      });

      if (ref && typeof ref !== 'function') {
        ref.current = quill;
      }

      // Add this function to convert string to Delta
      const convertStringToDelta = (str: string) => {
        return quill.clipboard.convert({ html: str });
      };

      if (defaultValueRef.current) {
        const delta = convertStringToDelta(defaultValueRef.current);
        quill.setContents(delta);
      }

      quill.on(Quill.events.TEXT_CHANGE, (..._args: any[]) => {
        const html = quill.root.innerHTML;
        const markdown = chatHtmlToMarkdown(html);
        onTextChangeRef.current?.({ markdown, html });
      });

      quill.on(Quill.events.SELECTION_CHANGE, (...args: any[]) => {
        onSelectionChangeRef.current?.(...args);
      });

      return () => {
        if (ref && typeof ref !== 'function') {
          ref.current = null;
        }
        container.innerHTML = '';
      };
    }, [ref]);

    return (
      <div className={cn('quill-posts', className)} ref={containerRef}></div>
    );
  },
);

Editor.displayName = 'Editor';

export default Editor;
