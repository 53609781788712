import { useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import {
  MembersSpace,
  SpaceGroupMembersDto,
  useBrandsControllerGetSpaceGroupsOfBrand,
  useSpaceGroupControllerGetSpaceGroupMembers,
} from '@/api';
import { SearchIcon } from '@/assets/icon/search';
import { Loading } from '@/components/Loading';
import { AddMemberDialogFull } from '@/components/add-member-dialog';
import { useUpdateConfigHeader } from '@/components/config-header-provider';
import InputField from '@/components/ui/InputField';
import CardMember from '@/components/ui/members-components/CardMember';
import { useBrandPermits } from '@/hooks/useBrandPermits';
import { useMemberInfoDrawerContext } from '@/routes/MemberInfoDrawerProvider';

const MembersPage = () => {
  const { brandId, spaceGroupId, membersSpaceId } = useParams();

  const spaceGroupsQuery = useBrandsControllerGetSpaceGroupsOfBrand(
    brandId as string,
  );

  if (spaceGroupsQuery.isLoading) {
    return <Loading />;
  }

  if (spaceGroupsQuery.isError) {
    throw new Error(
      `Error fetching space groups for brand ${brandId}, ${spaceGroupsQuery.error}`,
    );
  }

  const spaceGroups = spaceGroupsQuery.data ?? [];

  const spaceGroup = spaceGroups.find(
    (spaceGroup) => spaceGroup.id === spaceGroupId,
  );

  if (!spaceGroup) {
    return <Navigate to="/404" />;
  }

  const membersSpace = spaceGroup.membersSpaces.find(
    (space) => space.id === membersSpaceId,
  );

  if (!membersSpace) {
    return <Navigate to="/404" />;
  }

  return (
    <MembersInner membersSpace={membersSpace} spaceGroupId={spaceGroup.id} />
  );
};

const MembersInner = ({
  membersSpace,
  spaceGroupId,
}: {
  membersSpace: MembersSpace;
  spaceGroupId: string;
}) => {
  const membersQuery = useSpaceGroupControllerGetSpaceGroupMembers(
    membersSpace.spaceGroupId,
  );

  if (membersQuery.isLoading) {
    return <Loading />;
  }

  const members = membersQuery.data ?? [];

  return (
    <MembersList
      members={members}
      spaceGroupId={spaceGroupId}
      membersSpace={membersSpace}
    />
  );
};

const getAction = (hasPermits: boolean, spaceGroupId: string) => {
  if (hasPermits) {
    return <AddMemberDialogFull spaceGroupId={spaceGroupId} />;
  }

  return null;
};

const MembersList = ({
  members,
  spaceGroupId,
  membersSpace,
}: {
  members: SpaceGroupMembersDto[];
  spaceGroupId: string;
  membersSpace: MembersSpace;
}) => {
  const hasPermits = useBrandPermits();
  const { setUserInfoData } = useMemberInfoDrawerContext();
  const [memberSearch, setMemberSearch] = useState('');

  const config = {
    showNotifications: true,
    label: `${membersSpace.name} (${members.length})`,
    action: getAction(hasPermits, spaceGroupId),
  };

  useUpdateConfigHeader(config, [
    members.length,
    spaceGroupId,
    hasPermits,
    membersSpace,
  ]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMemberSearch(e.target.value);
  };

  const handleOpenProfileInfo = (member: SpaceGroupMembersDto) => {
    setUserInfoData({
      memberId: member.userId,
      spaceGroupId,
    });
  };

  const filteredMembers = members.filter(
    (member) =>
      member.user?.name?.toLowerCase().includes(memberSearch.toLowerCase()) ||
      member.user?.email?.toLowerCase().includes(memberSearch.toLowerCase()),
  );

  if (members.length > 0) {
    return (
      <div className="flex h-full flex-col gap-8 overflow-auto p-6 pb-20 md:gap-10 md:p-10">
        <div className="flex items-center justify-between md:hidden">
          <h1 className="text-lg font-semibold">{`${membersSpace.name} (${members.length})`}</h1>
          {getAction(hasPermits, spaceGroupId)}
        </div>
        <InputField
          placeholder="Search member"
          onChange={handleSearch}
          type="text"
          value={memberSearch}
          startIcon={<SearchIcon className="fill-black dark:fill-white" />}
          className="max-w-104"
          inputClassName="!rounded-full placeholder:text-textParagraph !bg-light-2 dark:!bg-dark-2 border-none !p-2.5 text-base items-center"
        />
        {filteredMembers.length > 0 && (
          <article className="flex flex-wrap gap-5">
            {filteredMembers.map((member) => (
              <CardMember
                key={member.user.id}
                member={member}
                openProfileInfo={() => handleOpenProfileInfo(member)}
              />
            ))}
          </article>
        )}
        {filteredMembers.length === 0 && (
          <div className="flex h-full flex-col items-center justify-center gap-6">
            <p className="w-125 text-center text-xl text-textParagraph">
              No members found
            </p>
          </div>
        )}
      </div>
    );
  }

  if (!hasPermits) {
    return (
      <div className="flex h-full flex-col items-center justify-center gap-6">
        <p className="w-125 text-center text-xl text-textParagraph">
          No members found
        </p>
      </div>
    );
  }

  return (
    <div className="flex h-full flex-col items-center justify-center gap-6">
      <p className="w-125 text-center text-xl text-textParagraph">
        You currently have no members, start inviting members to your space.
      </p>
      {config.action}
    </div>
  );
};

export default MembersPage;
