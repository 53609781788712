import { zodResolver } from '@hookform/resolvers/zod';
import { XIcon } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { Button } from '@/components/ui/button';
import { DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Form, FormControl, FormField, FormItem } from '@/components/ui/form';
import { cn } from '@/lib/utils';

const types = ['Space group', 'Space'] as const;

const formSchema = z.object({
  type: z.enum(types).default(types[0]),
});

type FormSchema = z.infer<typeof formSchema>;

export const ChooseSpaceOrSpaceGroupDialogContent = ({
  onContinue,
  onClose,
}: {
  onContinue: (type: (typeof types)[number]) => void;
  onClose: () => void;
}) => {
  const form = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    defaultValues: {
      type: types[0],
    },
  });

  const handleSubmit = form.handleSubmit((data) => {
    onContinue(data.type);
  });

  return (
    <>
      <DialogHeader className="flex-row items-center justify-between space-y-0">
        <DialogTitle className="text-2xl font-semibold">Create new</DialogTitle>
        <Button
          onClick={onClose}
          variant="icon"
          size="icon"
          className="m-0 rounded-sm p-0 ring-offset-background transition-opacity focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
        >
          <XIcon className="h-4 w-4" />
          <span className="sr-only">Close</span>
        </Button>
      </DialogHeader>
      <div className="font-medium text-[#9E9E9E] dark:text-[#AEAEAE]">
        <Form {...form}>
          <form onSubmit={handleSubmit} className="space-y-5">
            <FormField
              control={form.control}
              name="type"
              render={({ field }) => (
                <FormItem className="space-y-0">
                  <FormControl>
                    <div className="space-y-3">
                      {types.map((type) => (
                        <Button
                          key={type}
                          type="button"
                          variant="none"
                          size="none"
                          className={cn(
                            'flex w-full flex-row items-center justify-start gap-4 space-y-0 rounded-xl border p-4 hover:border-blue-500 dark:border-opacity-15 hover:dark:border-opacity-100',
                            field.value === type
                              ? 'border-blue-500 dark:border-opacity-100'
                              : 'border-gray-200',
                          )}
                          onClick={() => {
                            field.onChange(type);
                          }}
                        >
                          <CustomCheckbox value={field.value === type} />

                          <span className="space-y-0 text-base font-medium text-black dark:text-white">
                            {capitalizeFirstLetter(type)}
                          </span>
                        </Button>
                      ))}
                    </div>
                  </FormControl>
                </FormItem>
              )}
            />

            <Button
              type="submit"
              className="w-full rounded-lg"
              disabled={!form.formState.isValid}
            >
              Continue
            </Button>
          </form>
        </Form>
      </div>
    </>
  );
};

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export interface CustomCheckboxProps {
  value?: boolean;
}

const CustomCheckbox = ({ value }: CustomCheckboxProps) => (
  <div className="flex items-start gap-2">
    <div className="mt-1 grid place-items-center">
      <div className="peer col-start-1 row-start-1 h-4 w-4 shrink-0 appearance-none rounded-full border-2 border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-0 disabled:border-gray-400" />
      <div
        className={cn(
          'pointer-events-none',
          'col-start-1 row-start-1',
          'h-2 w-2 rounded-full',
          value && 'bg-blue-500',
        )}
      />
    </div>
  </div>
);
