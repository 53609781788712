import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { z } from 'zod';

import {
  useCoursesSpacesControllerCreateCourseLesson,
  useCoursesSpacesControllerUploadFiles,
} from '@/api';
import { ArrowLeftIcon } from '@/assets/icon/arrowLeft';
import { ClipboardClipIcon } from '@/assets/icon/clipboardClip';
import { TrashIcon } from '@/assets/icon/trash';
import { UploadIcon } from '@/assets/icon/upload';
import InputField from '@/components/ui/InputField';
import { Button } from '@/components/ui/button';
import { Textarea } from '@/components/ui/textarea';
import { getVideoProvider, parseVideoLink } from '@/utils/videoUtils';

const LessonCreationPage = () => {
  const { brandId, spaceGroupId, coursesSpaceId, courseId } = useParams();
  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [name, setName] = useState('');
  const [videoLink, setVideoLink] = useState('');
  const [text, setText] = useState('');
  const [files, setFiles] = useState<File[]>([]);

  const [errors, setErrors] = useState<{
    name: string;
    videoLink: string;
    text: string;
  }>({
    name: '',
    videoLink: '',
    text: '',
  });

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const [videoLinkError, setVideoLinkError] = useState('');

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleChangeVideoLink = (e: React.ChangeEvent<HTMLInputElement>) => {
    const url = e.target.value;
    setVideoLink(url);

    if (url && getVideoProvider(url) === 'unknown') {
      setVideoLinkError(
        'Please enter a valid YouTube, Loom, or Wistia video URL',
      );
    } else {
      setVideoLinkError('');
    }
  };

  const handleChangeText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value);
    const scrollPos = window.scrollY;

    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      window.scrollTo(0, scrollPos);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      const start = e.currentTarget.selectionStart;
      const end = e.currentTarget.selectionEnd;

      // Insert tab at cursor position
      const newText = text.substring(0, start) + '\t' + text.substring(end);
      setText(newText);

      // Move cursor after tab
      setTimeout(() => {
        if (textareaRef.current) {
          textareaRef.current.selectionStart =
            textareaRef.current.selectionEnd = start + 1;
        }
      }, 0);
    }
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [text]);

  const handleAddFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const filesArray = Array.from(files);
      setFiles((prevFiles) => {
        const newFiles = [...prevFiles, ...filesArray];
        if (newFiles.length > 5) {
          return newFiles.slice(0, 5);
        }
        return newFiles;
      });
    }
  };

  const handleRemoveFile = (index: number) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const createLessonMutation = useCoursesSpacesControllerCreateCourseLesson();
  const uploadFilesMutation = useCoursesSpacesControllerUploadFiles();

  const handleCreateLesson = async () => {
    if (!courseId) {
      return;
    }

    const createLessonSchema = z.object({
      name: z.string().min(1, 'Name is required'),
      videoLink: z.union([z.string().url('Invalid video link'), z.literal('')]),
      text: z.string().min(1, 'Text is required'),
    });

    setErrors({
      name: '',
      videoLink: '',
      text: '',
    });

    const result = createLessonSchema.safeParse({
      name,
      videoLink,
      text,
    });

    if (!result.success) {
      setErrors({
        name: result.error.formErrors.fieldErrors.name?.[0] ?? '',
        videoLink: result.error.formErrors.fieldErrors.videoLink?.[0] ?? '',
        text: result.error.formErrors.fieldErrors.text?.[0] ?? '',
      });
      return;
    }

    const data = result.data;

    const lesson = await createLessonMutation.mutateAsync({
      data: {
        courseId,
        name: data.name,
        text: data.text,
        videoLink: data.videoLink === '' ? undefined : data.videoLink,
      },
    });

    if (files.length > 0) {
      await uploadFilesMutation.mutateAsync({
        lessonId: lesson.id,
        data: {
          files,
        },
      });
    }

    navigate(
      `/brands/${brandId}/space-groups/${spaceGroupId}/courses-spaces/${coursesSpaceId}/courses/${courseId}`,
    );
  };

  return (
    <div className="flex flex-col gap-16 overflow-auto px-6 py-10 xl:px-25 xl:py-16">
      <Link
        to={`/brands/${brandId}/space-groups/${spaceGroupId}/courses-spaces/${coursesSpaceId}/courses/${courseId}`}
        className="flex w-fit items-center gap-2.5"
      >
        <ArrowLeftIcon className="h-6 w-6 fill-black dark:fill-white" />
        Back to modules
      </Link>
      <div className="flex w-full flex-col gap-10">
        <div className="flex flex-col gap-12">
          <div className="flex w-full flex-col gap-4">
            <h1 className="text-lg font-semibold">Name your lesson</h1>
            <InputField
              onChange={handleChangeName}
              type="text"
              value={name}
              placeholder="What should it be called?"
              inputClassName="!bg-transparent border border-light dark:border-dark-light"
              error={errors.name}
            />
          </div>
          <div className="flex w-full flex-col gap-6">
            <div className="flex flex-col gap-2">
              <h1 className="text-lg font-semibold">Add Video Link</h1>
              <p className="text-xs text-textParagraph dark:text-dark-textParagraph">
                Upload a YouTube, Loom, or Wistia video, users will be able to
                view it as part of your course
              </p>
            </div>
            <InputField
              onChange={handleChangeVideoLink}
              type="text"
              value={videoLink}
              placeholder="Paste a video link here"
              inputClassName="!bg-transparent border border-light dark:border-dark-light"
              error={videoLinkError || errors.videoLink}
            />
            {videoLink && !videoLinkError && (
              <iframe
                src={parseVideoLink(videoLink)}
                width="100%"
                height="400"
                className="rounded-lg"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                allowFullScreen
                frameBorder="0"
              />
            )}
          </div>
          <div className="flex w-full flex-col gap-6">
            <h1 className="text-lg font-semibold">Add Text</h1>
            <div
              style={{ minHeight: textareaRef.current?.scrollHeight ?? 100 }}
            >
              <Textarea
                ref={textareaRef}
                className="min-h-[100px] w-full overflow-hidden rounded-lg border border-light bg-transparent p-5 dark:border-dark-light"
                placeholder="Type a message"
                value={text}
                onChange={handleChangeText}
                onKeyDown={handleKeyDown}
              />
            </div>
            {errors.text && <p className="text-xs text-error">{errors.text}</p>}
          </div>
          <div className="flex w-full flex-col gap-6">
            <div className="flex flex-col gap-2">
              <h1 className="flex items-center gap-2 text-lg font-semibold">
                Add Files
                <div className="rounded-full bg-primary-50 px-2 text-xxs font-normal text-brand dark:bg-dark-primary-50">
                  {files.length}/5
                </div>
              </h1>
              <p className="text-xs text-textParagraph dark:text-dark-textParagraph">
                You can upload multiple files
              </p>
            </div>
            <Button
              onClick={() => {
                if (fileInputRef.current && files.length < 5) {
                  fileInputRef.current.click();
                }
              }}
              variant={'ghost'}
              className="flex w-fit gap-1.5 rounded-lg border border-light p-3.5 text-black dark:border-dark-light dark:text-white"
              disabled={
                files.length >= 5 ||
                createLessonMutation.isPending ||
                uploadFilesMutation.isPending
              }
            >
              <UploadIcon className="h-4 w-4 stroke-black dark:stroke-white" />
              Upload file
            </Button>
            <input
              type="file"
              hidden
              onChange={handleAddFile}
              ref={fileInputRef}
              multiple
            />
            {files.length > 0 &&
              files.map((file, index) => (
                <div
                  key={index}
                  className="flex w-full items-center gap-4 rounded-lg bg-primary-50 p-4 dark:bg-dark-primary-50"
                >
                  <ClipboardClipIcon className="h-4 min-w-4 stroke-black dark:stroke-white" />
                  <span className="w-full text-sm">{file.name}</span>
                  <TrashIcon
                    className="h-4 min-w-4 cursor-pointer stroke-error"
                    onClick={() => handleRemoveFile(index)}
                  />
                </div>
              ))}
          </div>
        </div>
        <div className="flex w-full flex-col gap-2">
          <Button
            className="h-fit !py-5 text-xl font-light"
            onClick={handleCreateLesson}
            disabled={
              createLessonMutation.isPending || uploadFilesMutation.isPending
            }
          >
            Save & Continue
          </Button>
          {/* <Button
            variant={'ghost'}
            className="flex h-fit items-center gap-2 !py-5 text-xl font-light text-error"
          >
            <TrashIcon className="h-6 w-6 stroke-error" />
            Remove Lesson
          </Button> */}
        </div>
      </div>
    </div>
  );
};

export default LessonCreationPage;
