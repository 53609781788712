import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeCardElement } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  DurationAvailability,
  MeetingCreatorDto,
  SpaceGroupResponseDto,
  checkoutEndpointControllerGetSpaceGroupByPaymentLink, // coursesSpacesControllerGetCourseWithLessonsByCourseId,
  monetizationControllerBuyMessages,
  monetizationControllerCanBuyMessages,
  spaceGroupControllerGetPaymentLink,
  stripeControllerCreatePayment,
} from '@/api';
import { ArrowLeftIcon } from '@/assets/icon/arrowLeft';
import { CalendarIcon } from '@/assets/icon/calendar';
import { ChatIcon } from '@/assets/icon/chat';
import { CheckIcon } from '@/assets/icon/check-alt';
import { CourseSpaceIcon } from '@/assets/icon/course-space';
import { LockIcon } from '@/assets/icon/lock';
import { MembersSapceIcon } from '@/assets/icon/member-space';
import { PostIcon } from '@/assets/icon/post';
import CountryDropdown from '@/components/ui/CountryDropdown';
import InputField from '@/components/ui/InputField';
import { Button } from '@/components/ui/button';
import { useToast } from '@/hooks/use-toast';
import { useAuth } from '@/hooks/useContext';

const SPACES = [
  {
    name: 'events',
    Icon: CalendarIcon,
    color: '#4D8EED',
    background: '#CDE3FD',
  },
  {
    name: 'posts',
    Icon: PostIcon,
    color: '#D55F2A',
    background: '#F4DDCF',
  },
  {
    name: 'chats',
    Icon: ChatIcon,
    color: '#65A96E',
    background: '#DDECE0',
  },
  {
    name: 'courses',
    Icon: CourseSpaceIcon,
    color: '#8A69E9',
    background: '#E7E2F4',
  },
  {
    name: 'members',
    Icon: MembersSapceIcon,
    color: '#F32222',
    background: '#FFD8D8',
  },
] as const;

type MeetingData = {
  creator: MeetingCreatorDto;
  selectedDate: Date;
  selectedTime: Date;
  selectedDuration: number;
  selectedSlotDuration: DurationAvailability;
  clientSecret: string;
};

const CheckoutPage = ({
  type,
}: {
  type: 'chat' | 'spaceGroup' | 'meet' | 'course';
}) => {
  const fee = 7.5;
  const { toast } = useToast();
  const stripe = useStripe();
  const elements = useElements();
  const { brandId, spaceGroupId, userId, creatorId } = useParams();
  const { isAuthenticated, user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const referralCode = searchParams.get('ref');

  const [promoCode, setPromoCode] = useState('');
  const [paymentData, setPaymentData] = useState({
    email: '',
    cardHolder: '',
    country: 'United States',
    city: '',
    zipCode: '',
    address: '',
  });
  const [hasAgreed, setHasAgreed] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const [spaceGroupData, setSpaceGroupData] = useState<SpaceGroupResponseDto>();
  const [messagePrice, setMessagePrice] = useState<number>(0);
  const [messageCount, setMessageCount] = useState<number>(1);

  const [meetingData, setMeetingData] = useState<MeetingData>();
  const [meetingClientSecret, setMeetingClientSecret] = useState<string>();

  // TODO: change any to the correct type when api is generated
  // const [courseData, setCourseData] = useState<any>();

  useEffect(() => {
    if (type === 'spaceGroup') {
      handleFetchSpaceGroup();
    } else if (type === 'chat') {
      handleFetchMessagePrice();
    } else if (type === 'meet') {
      setMeetingData(location.state as MeetingData);
      setMeetingClientSecret(location.state?.clientSecret);
    } else if (type === 'course') {
      handleFetchCourse();
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      setPaymentData((prev) => ({
        ...prev,
        email: user?.email || '',
      }));
    }
  }, [isAuthenticated, user]);

  const generateUUID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      },
    );
  };
  const customSessionId = generateUUID();

  const handleFetchSpaceGroup = async () => {
    try {
      if (spaceGroupId) {
        const paymentLink =
          await spaceGroupControllerGetPaymentLink(spaceGroupId);
        if (paymentLink) {
          await checkoutEndpointControllerGetSpaceGroupByPaymentLink(
            paymentLink.paymentLinkUrl + '',
          ).then((res: any) => {
            // Set to any because in the gen-api the type is not defined
            setSpaceGroupData(res);
          });
        }
      }
    } catch (error) {
      console.error(error);
      toast({
        title: 'Failed to fetch space group',
        variant: 'destructive',
      });
    }
  };

  const handleFetchMessagePrice = async () => {
    try {
      if (userId) {
        const priceData = await monetizationControllerCanBuyMessages(userId);
        if (!priceData.canBuy) {
          toast({
            title: 'You cannot buy messages',
            variant: 'destructive',
          });
          navigate(`/brands/${brandId}`);
        } else if (priceData) {
          setMessagePrice(priceData.pricePerMessage);
        }
      }
    } catch (error) {
      console.error(error);
      toast({
        title: 'Failed to fetch creator price',
        variant: 'destructive',
      });
    }
  };

  // TODO: finish once api is ready
  const handleFetchCourse = async () => {
    try {
      // if (courseId) {
      //   const course =
      //     await coursesSpacesControllerGetCourseWithLessonsByCourseId(courseId);
      //   if (course) {
      //     setCourseData(course);
      //   }
      // }
    } catch (error) {
      console.error(error);
      toast({
        title: 'Failed to fetch course',
        variant: 'destructive',
      });
    }
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email) || email === '';
  };

  const handlePay = async () => {
    if (!hasAgreed) {
      toast({
        title: 'Please accept the terms and conditions to proceed',
      });
      return;
    }

    if (!validateEmail(paymentData.email)) {
      toast({
        title: 'Please enter a valid email address',
      });
      return;
    }

    if (!paymentData.cardHolder) {
      toast({
        title: 'Please complete your card information',
      });
      return;
    }

    if (
      !paymentData.country ||
      !paymentData.city ||
      !paymentData.zipCode ||
      !paymentData.address
    ) {
      toast({
        title: 'Please complete your billing address',
      });
      return;
    }

    if (type === 'chat') {
      handleBuyMessages();
    } else if (type === 'spaceGroup') {
      handleSubscribe();
    } else if (type === 'meet') {
      handleBookNow();
    }
  };

  const handleBuyMessages = async () => {
    try {
      setLoading(true);
      setError('');

      const response = await monetizationControllerBuyMessages({
        creatorId: userId!,
        quantity: messageCount,
      });

      confirmPayment(
        response.clientSecret,
        `/brands/${brandId}/chats/${userId}`,
      );
    } catch (error) {
      setError('Failed to process payment');
      setLoading(false);
    }
  };

  const handleSubscribe = async () => {
    try {
      setLoading(true);
      setError('');

      const response = await stripeControllerCreatePayment({
        address: paymentData.address,
        city: paymentData.city,
        country: paymentData.country,
        email: paymentData.email,
        cardHolder: paymentData.cardHolder,
        sessionId: customSessionId,
        userId: isAuthenticated ? user!.id : undefined,
        zipCode: paymentData.zipCode,
        spaceGroupId: spaceGroupData?.id!,
        priceId: spaceGroupData?.stripePriceId!,
        referralCode: referralCode || undefined,
      });

      confirmPayment(
        response.clientSecret,
        `/brands/${brandId}/space-groups/${spaceGroupId}`,
      );
    } catch (error) {
      setError('Failed to process payment');
      setLoading(false);
    }
  };

  const handleBookNow = async () => {
    try {
      setLoading(true);
      if (meetingClientSecret) {
        confirmPayment(meetingClientSecret);
      }
    } catch (error) {
      setError('Failed to process payment');
      setLoading(false);
    }
  };

  const confirmPayment = async (clientSecret: string, redirectUrl?: string) => {
    const result = await stripe?.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements?.getElement(CardElement) as StripeCardElement,
        billing_details: {
          email: paymentData.email,
        },
      },
    });

    if (result?.error) {
      // Manejar error
      setError(result.error.message || 'Error al confirmar el pago');
      setLoading(false);
    } else {
      // Redirigir al usuario después del pago exitoso
      // a comment
      if (isAuthenticated) {
        // Show a message to the user
        toast({
          title: 'Processing your payment, it may take a while',
          variant: 'default',
          duration: 5000,
        });

        setTimeout(() => {
          navigate(redirectUrl || `/`);
          setLoading(false);
        }, 7000);
      } else {
        navigate(`/login?callbackUrl=/process-checkout/${customSessionId}`);
        setLoading(false);
      }
    }
  };

  return (
    <div className="flex flex-col overflow-auto lg:flex-row">
      <div className="w-full p-3 lg:h-full">
        <div className="flex w-full flex-col gap-6 rounded-3xl bg-light-2 px-4 py-6 dark:bg-dark-2 md:px-6 lg:justify-between">
          <div className="flex w-full flex-col gap-6">
            <div className="flex w-full flex-col gap-2">
              <div
                className="flex cursor-pointer items-center gap-1"
                onClick={() => {
                  if (type === 'meet') {
                    navigate(`/book-meeting/${creatorId}`);
                  } else if (type === 'chat') {
                    navigate(`/brands/${brandId}/chats/${userId}`);
                  } else if (type === 'spaceGroup') {
                    navigate(`/brands/space-groups/${spaceGroupId}`);
                  } else if (type === 'course') {
                    // TODO: change to course page
                    // navigate(`/courses-spaces/${courseId}`);
                  }
                }}
              >
                <ArrowLeftIcon className="h-6 w-6 fill-black dark:fill-white" />
                Back
              </div>
              <div className="text-base font-medium text-textParagraph dark:text-dark-textParagraph">
                {type === 'spaceGroup' && 'Subscribe to Creator’s plan'}
                {type === 'chat' && 'Buy credits'}
                {type === 'meet' && 'Book a meeting'}
                {type === 'course' && 'Subscribe to course'}
              </div>
              <div className="flex w-full gap-4 rounded-2xl border-2 border-light p-3 dark:border-dark-light">
                {spaceGroupData?.avatarUrl || meetingData?.creator.avatarUrl ? (
                  <img
                    src={
                      spaceGroupData?.avatarUrl ||
                      meetingData?.creator.avatarUrl
                    }
                    alt="logo"
                    className="h-10 w-10 flex-shrink-0 rounded-lg"
                  />
                ) : (
                  <div className="h-10 w-10 flex-shrink-0 rounded-lg bg-light dark:bg-dark-light" />
                )}
                <div className="flex w-full flex-col gap-1">
                  <div className="flex w-full gap-4 text-base font-medium">
                    <div className="w-full">
                      {type === 'spaceGroup' && spaceGroupData?.name}
                      {type === 'chat' && 'Creator DM access'}
                      {type === 'meet' && 'Creator 1:1 meeting'}

                      {/* //TODO: add couse name */}
                      {type === 'course' && 'Course'}
                    </div>
                    <div className="text-nowrap">
                      ${' '}
                      {spaceGroupData?.price.toFixed(2) ||
                        messagePrice ||
                        (meetingData?.selectedSlotDuration?.isFree
                          ? 'FREE'
                          : meetingData?.selectedSlotDuration?.price)}
                      {/* //TODO: add course price */}
                    </div>
                  </div>
                  {spaceGroupData?.billingFrequency && (
                    <div className="w-full text-sm font-medium text-textParagraph dark:text-dark-textParagraph">
                      Billed{' '}
                      {spaceGroupData?.billingFrequency
                        .replace('_', ' ')
                        .replace('_', ' ')
                        .toLowerCase()}
                    </div>
                  )}
                  {type === 'meet' && (
                    <div className="w-full text-sm font-medium text-textParagraph dark:text-dark-textParagraph">
                      {meetingData?.selectedDate?.toDateString()} at{' '}
                      {meetingData?.selectedTime?.toTimeString().split(' ')[0]}{' '}
                      - {meetingData?.selectedDuration} minutes
                    </div>
                  )}
                  {type === 'course' && (
                    <div>{/* // TODO: add course details */}</div>
                  )}
                </div>
              </div>
            </div>
            {type === 'spaceGroup' && (
              <div className="hidden w-full flex-col gap-2 font-bold lg:flex">
                {spaceGroupData?.chatsSpaces && //  Chats
                  spaceGroupData?.chatsSpaces?.map((item, i) => (
                    <div key={i} className="flex w-full items-center gap-2.5">
                      <div
                        className="flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-md fill-black"
                        style={{
                          backgroundColor: SPACES[2].background,
                        }}
                      >
                        {SPACES[2].Icon({
                          className: 'h-5 w-5',
                          style: {
                            fill: SPACES[2].color,
                          },
                        })}
                      </div>
                      <div className="w-full capitalize">
                        {item.name || 'Chats'}
                      </div>
                    </div>
                  ))}
                {spaceGroupData?.coursesSpaces && //  Courses
                  spaceGroupData?.coursesSpaces?.map((item, i) => (
                    <div key={i} className="flex w-full items-center gap-2.5">
                      <div
                        className="flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-md fill-black"
                        style={{
                          backgroundColor: SPACES[3].background,
                        }}
                      >
                        {SPACES[3].Icon({
                          className: 'h-5 w-5',
                          style: {
                            fill: SPACES[3].color,
                          },
                        })}
                      </div>
                      <div className="w-full capitalize">
                        {item.name || 'Courses'}
                      </div>
                    </div>
                  ))}
                {spaceGroupData?.eventSpaces && //  Events
                  spaceGroupData?.eventSpaces?.map((item, i) => (
                    <div key={i} className="flex w-full items-center gap-2.5">
                      <div
                        className="flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-md fill-black"
                        style={{
                          backgroundColor: SPACES[0].background,
                        }}
                      >
                        {SPACES[0].Icon({
                          className: 'h-5 w-5',
                          style: {
                            fill: SPACES[0].color,
                          },
                        })}
                      </div>
                      <div className="w-full capitalize">
                        {item.name || 'Events'}
                      </div>
                    </div>
                  ))}
                {spaceGroupData?.postsSpaces && //  Posts
                  spaceGroupData?.postsSpaces?.map((item, i) => (
                    <div key={i} className="flex w-full items-center gap-2.5">
                      <div
                        className="flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-md fill-black"
                        style={{
                          backgroundColor: SPACES[1].background,
                        }}
                      >
                        {SPACES[1].Icon({
                          className: 'h-5 w-5',
                          style: {
                            fill: SPACES[1].color,
                          },
                        })}
                      </div>
                      <div className="w-full capitalize">
                        {item.name || 'Posts'}
                      </div>
                    </div>
                  ))}
                {spaceGroupData?.membersSpaces && //  Members
                  spaceGroupData?.membersSpaces?.map((item, i) => (
                    <div key={i} className="flex w-full items-center gap-2.5">
                      <div
                        className="flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-md fill-black"
                        style={{
                          backgroundColor: SPACES[4].background,
                        }}
                      >
                        {SPACES[4].Icon({
                          className: 'h-5 w-5',
                          style: {
                            fill: SPACES[4].color,
                          },
                        })}
                      </div>
                      <div className="w-full capitalize">
                        {item.name || 'Members'}
                      </div>
                    </div>
                  ))}
              </div>
            )}
            {type === 'chat' && (
              <div className="flex w-full items-center justify-between">
                <div>Number of messages</div>
                <div className="flex items-center">
                  <Button
                    variant={'none'}
                    className="h-fit rounded-r-none bg-black py-1 text-xl font-medium text-white dark:bg-white dark:text-black"
                    onClick={() => {
                      if (messageCount > 1) {
                        setMessageCount((prev) => prev - 1);
                      }
                    }}
                  >
                    -
                  </Button>
                  <div className="flex h-9 w-10 flex-shrink-0 items-center justify-center border border-black px-3 dark:border-white">
                    {messageCount}
                  </div>
                  <Button
                    variant={'none'}
                    className="h-fit rounded-l-none bg-black py-1 text-xl text-white dark:bg-white dark:text-black"
                    onClick={() => {
                      if (messageCount < 100) {
                        setMessageCount((prev) => prev + 1);
                      }
                    }}
                  >
                    +
                  </Button>
                </div>
              </div>
            )}
            {type === 'meet' && (
              <div className="flex w-full items-center justify-between">
                IMPORTANT: If you don't complete the payment within 10 minutes,
                the slot will be released.
              </div>
            )}
            <div className="flex w-full items-center gap-2.5">
              <InputField
                value={promoCode}
                onChange={(e) => setPromoCode(e.target.value)}
                type="text"
                inputClassName="!bg-transparent border-light dark:border-dark-light !py-2"
                placeholder="Enter your promo code"
              />
              <Button
                variant={'none'}
                className="mt-1 h-fit bg-black px-6 py-2.5 text-white dark:bg-white dark:text-black"
              >
                Apply
              </Button>
            </div>
          </div>
          <div className="flex w-full flex-col gap-4">
            <div className="flex w-full flex-col gap-2 text-base font-normal text-textParagraph dark:text-dark-textParagraph">
              <div className="flex justify-between">
                Sub total
                <span className="font-medium text-black dark:text-white">
                  ${' '}
                  {spaceGroupData?.price.toFixed(2) ||
                    messagePrice * messageCount ||
                    meetingData?.selectedSlotDuration?.price}
                </span>
              </div>
              <div className="flex justify-between">
                Platform Fee ({fee}%)
                <span className="font-medium text-black dark:text-white">
                  ${' '}
                  {type === 'chat'
                    ? (messagePrice * messageCount * (fee / 100)).toFixed(2)
                    : type === 'meet'
                      ? (
                          (meetingData?.selectedSlotDuration?.price || 0) *
                          (fee / 100)
                        ).toFixed(2)
                      : ((spaceGroupData?.price || 0) * (fee / 100)).toFixed(2)}
                  {/* // TODO: add course price */}
                </span>
              </div>
            </div>
            <div className="flex justify-between text-base font-medium">
              Total
              <span>
                ${' '}
                {type === 'chat'
                  ? (
                      messagePrice * messageCount +
                      messagePrice * messageCount * (fee / 100)
                    ).toFixed(2)
                  : type === 'meet'
                    ? (
                        (meetingData?.selectedSlotDuration?.price || 0) +
                        (meetingData?.selectedSlotDuration?.price || 0) *
                          (fee / 100)
                      ).toFixed(2)
                    : (
                        (spaceGroupData?.price || 0) +
                        (spaceGroupData?.price || 0) * (fee / 100)
                      ).toFixed(2)}
                {/* //TODO: add course total price */}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="relative flex w-full flex-col gap-2 p-4 pb-32 md:px-6 lg:overflow-auto lg:pb-4">
        <InputField
          type="email"
          title="Email Address"
          inputClassName="!bg-transparent border-light dark:border-dark-light !p-2"
          titleClassName="!text-sm"
          value={paymentData.email}
          onChange={(e) =>
            setPaymentData((prev) => ({ ...prev, email: e.target.value }))
          }
        />
        <div className="flex flex-col gap-1">
          <div className="text-sm">Card information</div>
          <CardElement
            className="rounded-lg border border-light p-3 dark:border-dark-light"
            options={{
              hidePostalCode: true,
              style: {
                base: {
                  fontSize: '16px',
                  color: '#777',
                  '::placeholder': {
                    color: '#9ca3af44',
                  },
                },
              },
            }}
          />
        </div>
        <InputField
          type="text"
          title="Cardholder name"
          inputClassName="!bg-transparent border-light dark:border-dark-light !p-2"
          titleClassName="!text-sm"
          value={paymentData.cardHolder}
          onChange={(e) =>
            setPaymentData((prev) => ({
              ...prev,
              cardHolder: e.target.value,
            }))
          }
        />
        <CountryDropdown
          country={{
            name: paymentData.country,
            flag: '',
          }}
          setCountry={(country: { name: string; flag: string }) =>
            setPaymentData((prev) => ({
              ...prev,
              country: country.name,
            }))
          }
          titleClassName="!text-sm"
          inputClassName="!p-2.5"
        />
        <InputField
          type="text"
          title="City"
          inputClassName="!bg-transparent border-light dark:border-dark-light !p-2"
          titleClassName="!text-sm"
          value={paymentData.city}
          onChange={(e) =>
            setPaymentData((prev) => ({
              ...prev,
              city: e.target.value,
            }))
          }
        />
        <InputField
          type="text"
          title="Address"
          inputClassName="!bg-transparent border-light dark:border-dark-light !p-2"
          titleClassName="!text-sm"
          value={paymentData.address}
          onChange={(e) =>
            setPaymentData((prev) => ({
              ...prev,
              address: e.target.value,
            }))
          }
        />
        <InputField
          type="text"
          title="Zip code"
          inputClassName="!bg-transparent border-light dark:border-dark-light !p-2"
          titleClassName="!text-sm"
          value={paymentData.zipCode}
          onChange={(e) =>
            setPaymentData((prev) => ({
              ...prev,
              zipCode: e.target.value,
            }))
          }
        />
        <div className="flex w-full gap-2.5 text-sm font-medium text-textParagraph dark:text-dark-textParagraph">
          <div
            className={`flex h-5 w-5 flex-shrink-0 cursor-pointer items-center justify-center rounded-sm border border-textParagraph dark:border-dark-textParagraph ${hasAgreed && 'bg-brand'}`}
            onClick={() => setHasAgreed(!hasAgreed)}
          >
            {hasAgreed && (
              <CheckIcon className="h-4 w-4 flex-shrink-0 stroke-white" />
            )}
          </div>
          I agree to {spaceGroupData?.name} and teachly’s terms and conditions
          and allow them to charge your card for this payment.
        </div>
        <div className="flex gap-4 font-medium text-textParagraph dark:text-dark-textParagraph">
          <a
            href="https://amber-zucchini-aba.notion.site/Terms-of-Service-12f205cee356806886a8c7d242b2b160"
            className="cursor-pointer"
            target="_blank"
          >
            Terms
          </a>
          <a
            href="https://amber-zucchini-aba.notion.site/Terms-of-Service-12f205cee356806886a8c7d242b2b160"
            className="cursor-pointer"
            target="_blank"
          >
            Privacy
          </a>
        </div>
        {error && <div className="text-sm text-red-400">{error}</div>}
        <div className="fixed bottom-0 left-0 right-0 flex w-full flex-col gap-4 bg-white px-4 dark:bg-dark-1 lg:static">
          <Button
            className="h-fit text-base"
            onClick={handlePay}
            disabled={loading}
          >
            {type === 'chat' && 'Buy credits'}
            {type === 'meet' && 'Book now'}
            {type === 'spaceGroup' && 'Subscribe'}
            {type === 'course' && 'Buy course'}
          </Button>
          <div className="flex w-full items-center justify-center gap-2 py-2.5 text-base font-medium text-textParagraph dark:text-dark-textParagraph">
            <LockIcon className="h-6 w-6 fill-green-100" />
            Payments are secured and encrypted
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutPage;
