import { createContext, useContext, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { UserInfo } from './UserInfo';

interface MemberInfoDrawerContextType {
  memberId: string | null;
  spaceGroupId: string | null;
  setUserInfoData: (data: {
    memberId: string | null;
    spaceGroupId: string | null;
  }) => void;
}

const MemberInfoDrawerContext = createContext<MemberInfoDrawerContextType>({
  memberId: null,
  spaceGroupId: null,
  setUserInfoData: () => {},
});

export const useMemberInfoDrawerContext = () => {
  const context = useContext(MemberInfoDrawerContext);
  if (!context) {
    throw new Error(
      'useMemberInfoDrawerContext must be used within a MemberInfoDrawer',
    );
  }
  return context;
};

export const MemberInfoDrawerProvider = () => {
  const [memberId, setMemberId] = useState<string | null>(null);
  const [spaceGroupId, setSpaceGroupId] = useState<string | null>(null);

  const setUserInfoData = (data: {
    memberId: string | null;
    spaceGroupId: string | null;
  }) => {
    setMemberId(data.memberId);
    setSpaceGroupId(data.spaceGroupId);
  };

  return (
    <MemberInfoDrawerContext.Provider
      value={{ memberId, spaceGroupId, setUserInfoData }}
    >
      <MemberInfoDrawer />
    </MemberInfoDrawerContext.Provider>
  );
};

const MemberInfoDrawer = () => {
  const { memberId, spaceGroupId, setUserInfoData } =
    useMemberInfoDrawerContext();

  return (
    <>
      <Outlet />

      <UserInfo
        isOpen={!!memberId && !!spaceGroupId}
        userId={memberId ?? ''}
        spaceGroupId={spaceGroupId ?? ''}
        onClose={() => {
          setUserInfoData({ memberId: null, spaceGroupId: null });
        }}
      />
    </>
  );
};
