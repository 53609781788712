import { useState } from 'react';

import { CloseIcon } from '@/assets/icon/close';

type Props = {
  src: string;
  alt: string;
  className?: string;
};

const ExpandableImage = ({ src, alt, className }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="relative w-fit">
      <img
        src={src}
        alt={alt}
        className={`cursor-pointer ${className}`}
        onClick={(e) => {
          e.stopPropagation();
          setIsExpanded(!isExpanded);
        }}
      />
      {isExpanded && (
        <div
          className="fixed left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-black bg-opacity-70 p-2 sm:p-4 md:p-6 lg:p-8 xl:p-10"
          onClick={(e) => {
            e.stopPropagation();
            setIsExpanded(false);
          }}
        >
          <img
            src={src}
            alt={alt}
            className="h-auto max-h-full w-auto max-w-full"
            onClick={(e) => e.stopPropagation()}
          />
          <CloseIcon className="absolute right-2 top-2 h-6 w-6 cursor-pointer fill-white" />
        </div>
      )}
    </div>
  );
};

export default ExpandableImage;
