import { Brand } from '@/api';
import { ArrowRightFilledIcon } from '@/assets/icon/arrowRightFilled';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';

interface SelectBrandProps {
  brandId: string | undefined;
  setBrandId: (id: string) => void;
  selectedBrand: Brand | undefined;
  brands: Brand[];
}

export const SelectBrand = ({
  brandId,
  setBrandId,
  selectedBrand,
  brands,
}: SelectBrandProps) => {
  return (
    <Select key={brandId} onValueChange={setBrandId} defaultValue={brandId}>
      <SelectTrigger
        showIcon={false}
        className="h-fit rounded-lg border-0 bg-light-2 px-5 py-2.5 dark:bg-dark-2"
      >
        <SelectValue
          aria-label={brandId}
          asChild
          placeholder={
            <div className="flex h-12 w-full items-center justify-start p-2">
              Select a brand
            </div>
          }
        >
          <div className="grid w-full grid-cols-[auto,minmax(0,1fr),auto] items-center gap-2">
            <Avatar className="h-9 w-9">
              <AvatarImage
                className="rounded-lg"
                src={selectedBrand?.logoUrl}
              />
              <AvatarFallback className="bg-light-3 dark:bg-dark-3">
                {selectedBrand?.name.slice(0, 2)}
              </AvatarFallback>
            </Avatar>
            <h6 className="overflow-hidden break-words text-start text-base font-medium text-textParagraph dark:text-dark-textParagraph">
              {selectedBrand?.name}
            </h6>
            <ArrowRightFilledIcon className="min-h-4 min-w-4 rotate-90 fill-black dark:fill-white" />
          </div>
        </SelectValue>
      </SelectTrigger>
      <SelectContent className="border-light dark:border-dark-light">
        {brands.map((brand) => (
          <SelectItem key={brand.id} value={brand.id} showIcon={false}>
            <div className="grid grid-cols-[auto,1fr] items-center gap-2 p-2">
              <Avatar className="h-9 w-9">
                <AvatarImage className="rounded-lg" src={brand.logoUrl} />
                <AvatarFallback>{brand.name.slice(0, 2)}</AvatarFallback>
              </Avatar>
              <h6 className="overflow-hidden truncate break-words text-sm font-medium">
                {brand.name}
              </h6>
            </div>
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};
