import { Dialog } from '@radix-ui/react-dialog';
import { XIcon } from 'lucide-react';
import { useState } from 'react';
import Cropper, { Area } from 'react-easy-crop';

import getCroppedImg from '@/utils/CropImage';

import { Button } from './button';
import { DialogContent, DialogHeader, DialogTitle } from './dialog';

type Props = {
  image: string;
  onClose: () => void;
  onSave: (croppedImage: any) => void;
  open: boolean;
  ratio?: number;
};

const ImageCropModal = ({
  image,
  onClose,
  onSave,
  open,
  ratio = 4 / 3,
}: Props) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>();

  const onCropComplete = (_: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const showCroppedImage = async () => {
    try {
      if (croppedAreaPixels) {
        const croppedImage = await getCroppedImg(image, croppedAreaPixels, 0);
        onSave(croppedImage as any);
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onClose} modal>
      <DialogContent>
        <DialogHeader className="flex-row items-center justify-between space-y-0">
          <DialogTitle className="text-2xl font-semibold">
            Crop Image
          </DialogTitle>
          <Button
            onClick={onClose}
            variant="icon"
            size="icon"
            className="m-0 rounded-sm p-0 ring-offset-background transition-opacity focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
          >
            <XIcon className="h-4 w-4" />
            <span className="sr-only">Close</span>
          </Button>
        </DialogHeader>
        <div className="flex h-96 w-full flex-col gap-4">
          <div className="relative h-96">
            <Cropper
              image={image}
              crop={crop}
              zoom={zoom}
              aspect={ratio}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
          <div className="flex justify-evenly">
            <Button onClick={onClose} className="px-10" variant={'outline'}>
              Cancel
            </Button>
            <Button onClick={showCroppedImage} className="px-10">
              Done
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ImageCropModal;
