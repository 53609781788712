import { useQueryClient } from '@tanstack/react-query';
import { EllipsisVerticalIcon } from 'lucide-react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  EventSpace,
  getBrandsControllerGetSpaceGroupsOfBrandQueryKey,
  useEventSpacesControllerDeleteEventSpace,
} from '@/api';
import {
  DeleteSpaceDialogContent,
  DeleteSpaceDialogTrgger,
} from '@/components/delete-space-dialog';
import {
  EventsSpaceSettingsDialogContent,
  EventsSpaceSettingsDialogTrigger,
} from '@/components/events-space-settings-dialog';
import { Dialog } from '@/components/ui/dialog';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';

const DELETE_SPACE_DIALOG = {
  id: 'delete-space',
  Trigger: DeleteSpaceDialogTrgger,
  Content: DeleteSpaceDialogContent,
} as const;

const EVENTS_SPACE_SETTINGS_DIALOG = {
  id: 'events-space-settings',
  Trigger: EventsSpaceSettingsDialogTrigger,
  Content: EventsSpaceSettingsDialogContent,
} as const;

const DIALOGS = [EVENTS_SPACE_SETTINGS_DIALOG, DELETE_SPACE_DIALOG];

export type EventsSpaceDropdownProps = {
  brandId: string;
  eventsSpace: EventSpace;
};

export const EventsSpaceDropdown = ({
  brandId,
  eventsSpace,
}: EventsSpaceDropdownProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedDialogId, setSelectedDialogId] = useState<string | null>(null);

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const { eventsSpaceId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const deleteEventsSpaceMutation = useEventSpacesControllerDeleteEventSpace({
    mutation: {
      onSuccess: () => {
        if (eventsSpaceId === eventsSpace.id) {
          navigate(`/brands/${brandId}/space-groups`);
        }
        queryClient.invalidateQueries({
          queryKey: getBrandsControllerGetSpaceGroupsOfBrandQueryKey(brandId),
        });
        closeDialog();
      },
    },
  });

  const handleDeleteSpace = async () => {
    deleteEventsSpaceMutation.mutate({ eventSpaceId: eventsSpace.id });
  };

  return (
    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <DropdownMenu>
        <DropdownMenuTrigger className="absolute right-1 top-2/4 z-10 -translate-y-1/2 transform p-3">
          <EllipsisVerticalIcon className="h-4 w-4" />
        </DropdownMenuTrigger>
        <DropdownMenuContent side="bottom" align="start" className="min-w-40">
          {DIALOGS.map(({ id, Trigger }) => (
            <DropdownMenuItem
              key={id}
              className="p-0"
              onSelect={() => {
                setSelectedDialogId(id);
              }}
            >
              <Trigger />
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
      {selectedDialogId === DELETE_SPACE_DIALOG.id && (
        <DELETE_SPACE_DIALOG.Content
          key={isDialogOpen.toString()}
          isLoading={deleteEventsSpaceMutation.isPending}
          closeDialog={closeDialog}
          deleteSpace={handleDeleteSpace}
        />
      )}
      {selectedDialogId === EVENTS_SPACE_SETTINGS_DIALOG.id && (
        <EVENTS_SPACE_SETTINGS_DIALOG.Content
          key={isDialogOpen.toString()}
          brandId={brandId}
          closeDialog={closeDialog}
          eventsSpace={eventsSpace}
        />
      )}
    </Dialog>
  );
};
