import { CometChat } from '@cometchat/chat-sdk-javascript';
import { memo, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import { SpaceGroupControllerGetUsersBySpaceGroupIdForMentions200Item } from '@/api';
import { ChatBubbleIcon } from '@/assets/icon/chat-bubble';
import { MessagesIcon } from '@/assets/icon/messages';
import { SmileFaceIcon } from '@/assets/icon/smileyFace';
import { UserIcon } from '@/assets/icon/user';
import ExpandableImage from '@/components/ExpandableImage';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { useMemberInfoDrawerContext } from '@/routes/MemberInfoDrawerProvider';
import { getEmbeddableVideos } from '@/utils/videoUtils';

import EmojiSelector from './EmojiSelector';
import Viewer from './Viewer';

type Props = {
  message: CometChat.BaseMessage;
  onReact?: (messageId: string, reaction: string) => void;
  onClick?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
  isThreadMessage?: boolean;
  users: SpaceGroupControllerGetUsersBySpaceGroupIdForMentions200Item[];
  hideThread?: boolean;
};

const ChatMessage = ({
  message,
  onClick,
  // onDelete,
  // onEdit,
  onReact,
  isThreadMessage = false,
  users,
  hideThread = false,
}: Props) => {
  const [isHovered, setIsHovered] = useState(false);

  const [isReactionsVisible, setIsReactionsVisible] = useState(false);

  const { setUserInfoData } = useMemberInfoDrawerContext();
  const { spaceGroupId } = useParams();

  // TODO: add options
  // const openOptions = (e: any) => {
  //   e.stopPropagation();
  //   setIsOptionsVisible(!isOptionsVisible);
  //   setIsReactionsVisible(false);
  // };

  const openReactions = (e: any) => {
    e.stopPropagation();
    setIsReactionsVisible(!isReactionsVisible);
  };

  const handleClose = () => {
    setIsHovered(false);
    setIsReactionsVisible(false);
  };

  const handleReact = useCallback(
    (e: any, reaction: string) => {
      e.stopPropagation();
      onReact?.(message.getId().toString(), reaction);
      handleClose();
    },
    [onReact, message.getId()],
  );

  if (
    !(
      message instanceof CometChat.TextMessage ||
      message instanceof CometChat.MediaMessage
    )
  ) {
    return null;
  }

  const handleMentionClick = useCallback(
    (uid: string) => {
      if (spaceGroupId) {
        setUserInfoData({
          memberId: uid,
          spaceGroupId,
        });
      }
    },
    [spaceGroupId],
  );

  const findUser = useCallback(
    (id: string) => {
      const user = users.find((u) => u.id === id);
      return user?.name ? { username: user.name } : null;
    },
    [users],
  );

  const convertFromUnixEpochToTimeAgo = (unixEpoch: number) => {
    const date = new Date(unixEpoch * 1000);
    const now = new Date();
    const diff = now.getTime() - date.getTime();
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    if (years > 0) {
      return `${years}y`;
    }

    if (months > 0) {
      return `${months}mo`;
    }

    if (days > 0) {
      return `${days}d`;
    }

    if (hours > 0) {
      return `${hours}h`;
    }

    if (minutes > 0) {
      return `${minutes}m`;
    }

    return `${seconds}s`;
  };

  return (
    <div
      className="cursor-pointer space-y-3 p-2.5 transition-all duration-100 hover:bg-primary-50 dark:hover:bg-dark-primary-50"
      onMouseOver={() => {
        if (!isThreadMessage) setIsHovered(true);
      }}
      onMouseLeave={handleClose}
      onClick={onClick}
    >
      <div className="relative flex items-start gap-2.5 rounded-lg">
        <Avatar
          className="h-10 w-10 cursor-pointer rounded-lg"
          onClick={(e) => {
            e.stopPropagation();
            handleMentionClick(message.getSender().getUid());
          }}
        >
          <AvatarImage src={message.getSender().getAvatar()} />
          <AvatarFallback className="h-10 w-10 rounded-lg">
            <UserIcon className="stroke-textParagraph p-1 dark:stroke-white" />
          </AvatarFallback>
        </Avatar>
        <div className="flex flex-col">
          <p
            className="flex cursor-pointer items-center gap-2 text-base font-medium text-black dark:text-white"
            onClick={(e) => {
              e.stopPropagation();
              handleMentionClick(message.getSender().getUid());
            }}
          >
            {message.getSender().getName()}
            <span className="text-xxs font-medium text-textParagraph dark:text-dark-textParagraph">
              {convertFromUnixEpochToTimeAgo(message.getSentAt())}
            </span>
          </p>
          <MemoizedViewer
            value={
              message instanceof CometChat.TextMessage
                ? message.getText()
                : message.getCaption()
            }
            findUser={findUser}
            onMentionClick={handleMentionClick}
          />
          <div className="mt-2 flex flex-col gap-2">
            {message instanceof CometChat.TextMessage &&
              getEmbeddableVideos(message.getText()).map((link, index) => (
                <iframe
                  key={index}
                  src={link}
                  className="aspect-4/3 w-full max-w-xl rounded-lg"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                  allowFullScreen
                  style={{ border: 'none' }}
                />
              ))}
            {message instanceof CometChat.MediaMessage && (
              <>
                {message.getType() === CometChat.MESSAGE_TYPE.IMAGE && (
                  <ExpandableImage
                    src={message.getURL()}
                    alt="media"
                    className="max-h-40 rounded-lg object-contain"
                  />
                )}
                {message.getType() === CometChat.MESSAGE_TYPE.VIDEO && (
                  <video
                    src={message.getURL()}
                    controls
                    className="max-h-80 rounded-lg object-contain"
                  />
                )}
              </>
            )}
          </div>
        </div>
        <div
          className={`${
            isHovered ? 'flex' : 'hidden'
          } absolute -top-6 right-2.5 gap-2.5 rounded-lg border border-light bg-white p-2 dark:border-dark-light dark:bg-dark-1 md:right-15`}
        >
          <Button
            className="min-h-2.5 min-w-2.5 rounded-sm p-2 hover:bg-primary-50 dark:hover:bg-dark-primary-50"
            variant={'icon'}
            onClick={openReactions}
          >
            <SmileFaceIcon className="h-6 w-6 fill-black dark:fill-textParagraph" />
          </Button>
          {!hideThread && (
            <Button
              className="min-h-2.5 min-w-2.5 rounded-sm p-2 hover:bg-primary-50 dark:hover:bg-dark-primary-50"
              variant={'icon'}
              onClick={onClick}
            >
              <MessagesIcon className="h-6 w-6 fill-black dark:fill-textParagraph" />
            </Button>
          )}
          {/* <Button
          className="min-h-2.5 min-w-2.5 rounded-sm p-2 hover:bg-primary-50 dark:hover:bg-dark-primary-50"
          variant={'icon'}
          onClick={openOptions}
        >
          <EllipsisVerticalIcon className="h-6 w-6 fill-textParagraph" />
        </Button> */}
        </div>
        {/* <div
        className={`${
          isOptionsVisible ? 'flex' : 'hidden'
        } absolute right-2.5 top-10 z-10 w-48 flex-col gap-1 rounded-lg border border-light bg-white p-2 dark:border-dark-light dark:bg-dark-dark-1`}
      >
        <div
          className="cursor-pointer rounded p-2.5 hover:bg-primary-50 dark:hover:bg-dark-primary-50"
          onClick={onEdit}
        >
          Edit
        </div>
        <div
          className="cursor-pointer rounded p-2.5 text-error hover:bg-primary-50 dark:hover:bg-dark-primary-50"
          onClick={onDelete}
        >
          Delete
        </div>
      </div> */}
        {isReactionsVisible && (
          <EmojiSelector
            handleReact={handleReact}
            isReactionsVisible={isReactionsVisible}
          />
        )}
      </div>
      {!hideThread && !isThreadMessage && message.getReplyCount() > 0 && (
        <div className="!mt-2 pl-12">
          <p className="flex items-center gap-1 text-base font-medium text-black dark:text-white">
            <ChatBubbleIcon className="h-4 w-4 flex-shrink-0 stroke-black dark:stroke-white" />
            {message.getReplyCount()}
          </p>
        </div>
      )}
      {message.getReactions()?.length > 0 && (
        <div className="flex gap-2">
          {message.getReactions()?.map((reaction) => (
            <Badge
              key={reaction.getReaction()}
              variant="secondary"
              className={cn('text-sm', {
                'bg-blue-500 dark:bg-blue-500': reaction.getReactedByMe(),
              })}
            >
              {reaction.getReaction()}
            </Badge>
          ))}
        </div>
      )}
    </div>
  );
};

const MemoizedChatMessage = memo(ChatMessage);

export default MemoizedChatMessage;

const MemoizedViewer = memo(Viewer);
