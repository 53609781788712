export const TrashIcon = (props) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="trash">
      <path
        id="Icon"
        d="M4.5 6H20.5M16.5 6L16.2294 5.18807C15.9671 4.40125 15.8359 4.00784 15.5927 3.71698C15.3779 3.46013 15.1021 3.26132 14.7905 3.13878C14.4376 3 14.023 3 13.1936 3H11.8064C10.977 3 10.5624 3 10.2095 3.13878C9.89792 3.26132 9.62208 3.46013 9.40729 3.71698C9.16405 4.00784 9.03292 4.40125 8.77064 5.18807L8.5 6M18.5 6V16.2C18.5 17.8802 18.5 18.7202 18.173 19.362C17.8854 19.9265 17.4265 20.3854 16.862 20.673C16.2202 21 15.3802 21 13.7 21H11.3C9.61984 21 8.77976 21 8.13803 20.673C7.57354 20.3854 7.1146 19.9265 6.82698 19.362C6.5 18.7202 6.5 17.8802 6.5 16.2V6M14.5 10V17M10.5 10V17"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
