import { zodResolver } from '@hookform/resolvers/zod';
import { TabsContent } from '@radix-ui/react-tabs';
import { useQueryClient } from '@tanstack/react-query';
import { XIcon } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import {
  SpaceGroupWithSpaces,
  UpdateSpaceGroupMonetizationSettingsDto,
  UpdateSpaceGroupMonetizationSettingsDtoFreeCallDurationType,
  UpdateSpaceGroupMonetizationSettingsDtoFreeCallsFrequency,
  UpdateSpaceGroupMonetizationSettingsDtoFreeMessagesFrequency,
  getBrandsControllerGetSpaceGroupsOfBrandQueryKey,
  useSpaceGroupControllerUpdateSettings,
  useSpaceGroupControllerUpdateSpaceGroup,
} from '@/api';
import { CalendarRoudedIcon } from '@/assets/icon/calendarRounded';
import { ChatBubbleEllipsisIcon } from '@/assets/icon/chat-bubble-ellipsis';
import { Button } from '@/components/ui/button';
import {
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormInput,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { cn, onlyFirstLetterCapital, valueOrUndefined } from '@/lib/utils';

import InputField from './ui/InputField';
import { Switch } from './ui/switch';
import { Tabs, TabsList, TabsTrigger } from './ui/tabs';

const pricings = ['FREE', 'PAID'] as const;

const billingFrequencies = ['one-time', 'weekly', 'monthly'] as const;

const formSchema = z
  .object({
    name: z.string().min(1, {
      message: 'Name is required',
    }),
    pricing: z.enum(pricings).optional(),
    price: z.coerce
      .number({
        message: 'Price must be a number',
      })
      .optional(),
    billingFrequency: z.enum(billingFrequencies).optional(),
  })
  .refine(
    (data) => {
      return data.pricing === 'FREE' || data.billingFrequency;
    },
    {
      message: 'Billing frequency is required for paid plans',
      path: ['billingFrequency'],
    },
  )
  .refine(
    (data) => {
      return data.pricing === 'FREE' || data.price;
    },
    {
      message: 'Price is required for paid plans',
      path: ['price'],
    },
  );

type FormSchema = z.infer<typeof formSchema>;

export const UpdateSpaceGroupDialog = ({
  spaceGroup,
  onClose,
}: {
  spaceGroup: SpaceGroupWithSpaces;
  onClose: () => void;
}) => {
  const updateSpaceGroupMutation = useSpaceGroupControllerUpdateSpaceGroup();
  const updateSpaceGroupMonetizationMutation =
    useSpaceGroupControllerUpdateSettings();
  const queryClient = useQueryClient();

  const [monetizationData, setMonetizationData] =
    useState<UpdateSpaceGroupMonetizationSettingsDto>();

  useEffect(() => {
    setMonetizationData({
      allowDirectMessaging: spaceGroup.allowDirectMessaging,
      messageFrequencyType: spaceGroup.messageFrequencyType || 'CAPPED',
      freeMessagesLimit: spaceGroup.freeMessagesLimit ?? 0,
      freeMessagesFrequency: spaceGroup.freeMessagesFrequency || 'PER_DAY',
      allowCallMeetings: spaceGroup.allowCallMeetings,
      freeCallsLimit: spaceGroup.freeCallsLimit ?? 0,
      freeCallsFrequency: spaceGroup.freeCallsFrequency || 'PER_DAY',
      freeCallDuration: spaceGroup.freeCallDuration ?? 0,
      freeCallDurationType: spaceGroup.freeCallDurationType || 'MINUTES',
    });
  }, [spaceGroup]);

  const mapBillingFrequencyFromServer = (
    billingFrequency: string | null | undefined,
  ) => {
    switch (billingFrequency) {
      case 'ONE_TIME_PAYMENT':
        return 'one-time';
      case 'WEEKLY':
        return 'weekly';
      case 'MONTHLY':
        return 'monthly';
      default:
        return undefined;
    }
  };

  const handleSaveMonetizationSettings = async () => {
    await updateSpaceGroupMonetizationMutation.mutateAsync({
      spaceGroupId: spaceGroup.id,
      data: monetizationData!,
    });
  };

  const form = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    defaultValues: {
      name: spaceGroup.name,
      pricing: spaceGroup.pricingType,
      price: spaceGroup.price ?? undefined,
      billingFrequency: mapBillingFrequencyFromServer(
        spaceGroup.billingFrequency,
      ),
    },
  });

  const handleSubmit = form.handleSubmit(async (data) => {
    // if the form did not change from the original values, do not submit
    if (
      data.name === spaceGroup.name &&
      data.pricing === valueOrUndefined(spaceGroup.pricingType) &&
      data.price === valueOrUndefined(spaceGroup.price) &&
      data.billingFrequency ===
        mapBillingFrequencyFromServer(spaceGroup.billingFrequency) &&
      monetizationData?.allowCallMeetings === spaceGroup.allowCallMeetings &&
      monetizationData?.allowDirectMessaging ===
        spaceGroup.allowDirectMessaging &&
      monetizationData?.freeCallDuration === spaceGroup.freeCallDuration &&
      monetizationData?.freeCallDurationType ===
        spaceGroup.freeCallDurationType &&
      monetizationData?.freeCallsFrequency === spaceGroup.freeCallsFrequency &&
      monetizationData?.freeCallsLimit === spaceGroup.freeCallsLimit &&
      monetizationData?.freeMessagesFrequency ===
        spaceGroup.freeMessagesFrequency &&
      monetizationData?.freeMessagesLimit === spaceGroup.freeMessagesLimit &&
      monetizationData?.messageFrequencyType === spaceGroup.messageFrequencyType
    ) {
      clearFormAndCloseDialog();
      return;
    }

    const pricingType = data.pricing;
    const isPaid = pricingType === 'PAID';
    const price = isPaid ? data.price : null;

    const mapBillingFrequency = (
      billingFrequency: string | null | undefined,
    ) => {
      switch (billingFrequency) {
        case 'one-time':
          return 'ONE_TIME_PAYMENT';
        case 'weekly':
          return 'WEEKLY';
        case 'monthly':
          return 'MONTHLY';
        default:
          return undefined;
      }
    };

    const billingFrequency = isPaid
      ? mapBillingFrequency(data.billingFrequency)
      : null;

    await updateSpaceGroupMutation.mutateAsync({
      spaceGroupId: spaceGroup.id,
      data: {
        name: data.name,
        pricingType,
        price: price as number | undefined,
        billingFrequency: billingFrequency as string | undefined,
      },
    });

    await handleSaveMonetizationSettings();

    queryClient.invalidateQueries({
      queryKey: getBrandsControllerGetSpaceGroupsOfBrandQueryKey(
        spaceGroup.brandId,
      ),
    });

    clearFormAndCloseDialog();
  });

  const clearFormAndCloseDialog = () => {
    form.reset();
    onClose();
  };

  return (
    <DialogContent className="flex w-[90%] max-w-3xl flex-col gap-9 rounded-3xl md:w-3/4 md:p-12 lg:w-1/2">
      <DialogHeader className="flex-row items-center justify-between space-y-0">
        <DialogTitle className="text-2xl font-semibold">Settings</DialogTitle>
        <Button
          onClick={clearFormAndCloseDialog}
          variant="icon"
          size="icon"
          className="m-0 rounded-sm p-0 ring-offset-background transition-opacity focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
        >
          <XIcon className="h-4 w-4" />
          <span className="sr-only">Close</span>
        </Button>
      </DialogHeader>
      <div className="font-medium text-[#9E9E9E] dark:text-[#AEAEAE]">
        <Form {...form}>
          <form onSubmit={handleSubmit} className="space-y-5">
            <Tabs defaultValue="general" className="h-fit w-full">
              <TabsList className="flex justify-start gap-8 rounded-none border-b border-light !bg-transparent p-0 dark:border-dark-light">
                <TabsTrigger
                  value="general"
                  className="rounded-none pb-2.5 data-[state=active]:-mb-[1px] data-[state=active]:border-b-2 data-[state=active]:border-brand data-[state=active]:bg-transparent data-[state=active]:!text-brand"
                >
                  General
                </TabsTrigger>
                <TabsTrigger
                  value="monetization"
                  className="rounded-none pb-2.5 data-[state=active]:-mb-[1px] data-[state=active]:border-b-2 data-[state=active]:border-brand data-[state=active]:bg-transparent data-[state=active]:!text-brand"
                >
                  Monetization
                </TabsTrigger>
              </TabsList>
              <TabsContent value="general" className="space-y-5 py-5">
                <FormField
                  control={form.control}
                  name="name"
                  render={({ field, fieldState }) => (
                    <FormItem className="space-y-1">
                      <FormLabel
                        className={cn(
                          'text-base font-medium text-black dark:text-white',
                          fieldState.error &&
                            'text-destructive dark:text-destructive',
                        )}
                      >
                        Name
                      </FormLabel>
                      <FormControl>
                        <FormInput
                          type="text"
                          placeholder="Enter brand name"
                          className={cn(
                            'p-3 text-base font-normal text-black shadow-sm placeholder:text-[#9E9E9E] dark:text-white dark:placeholder:text-[#AEAEAE]',
                          )}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="pricing"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-base font-medium text-black dark:text-white">
                        Pricing
                      </FormLabel>
                      <FormControl>
                        <div className="space-x-2">
                          {pricings.map((pricing) => (
                            <Button
                              type="button"
                              key={pricing}
                              onClick={() => field.onChange(pricing)}
                              variant={
                                field.value === pricing ? 'default' : 'outline'
                              }
                              disabled={
                                spaceGroup.pricingType === 'PAID' &&
                                pricing === 'FREE'
                              } // Disable "FREE" if current pricing is "PAID"
                            >
                              <span>{onlyFirstLetterCapital(pricing)}</span>
                            </Button>
                          ))}
                        </div>
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="price"
                  render={({ field, fieldState }) => (
                    <FormItem
                      className={cn(
                        'space-y-1',
                        form.watch('pricing') === 'FREE' && 'hidden',
                      )}
                    >
                      <FormLabel
                        className={cn(
                          'text-base font-medium text-black dark:text-white',
                          fieldState.error &&
                            'text-destructive dark:text-destructive',
                        )}
                      >
                        Price
                      </FormLabel>
                      <FormControl>
                        <FormInput
                          type="text"
                          placeholder="100"
                          className={cn(
                            'p-3 text-base font-normal text-black shadow-sm placeholder:text-[#9E9E9E] dark:text-white dark:placeholder:text-[#AEAEAE]',
                          )}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="billingFrequency"
                  render={({ field, fieldState }) => (
                    <FormItem
                      className={cn(
                        'space-y-1',
                        form.watch('pricing') === 'FREE' && 'hidden',
                      )}
                    >
                      <FormLabel
                        className={cn(
                          'text-base font-medium text-black dark:text-white',
                          fieldState.error &&
                            'text-destructive dark:text-destructive',
                        )}
                      >
                        Billing frequency
                      </FormLabel>
                      <Select
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                      >
                        <FormControl>
                          <SelectTrigger
                            className={cn(
                              'h-12 text-base text-black dark:text-white',
                              fieldState.error &&
                                'border-destructive dark:border-destructive',
                            )}
                          >
                            <SelectValue />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {billingFrequencies.map((billingFrequency) => (
                            <SelectItem
                              key={billingFrequency}
                              value={billingFrequency}
                            >
                              {onlyFirstLetterCapital(billingFrequency)} Payment
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </TabsContent>
              <TabsContent value="monetization" className="space-y-5 py-5">
                <div className="flex w-full flex-col gap-8 font-medium">
                  <div className="flex w-full items-center gap-2.5">
                    <div className="flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-md bg-[#FFDAF5]">
                      <ChatBubbleEllipsisIcon className="h-6 w-6 stroke-[#EF38BE]" />
                    </div>
                    <div className="w-full text-lg font-medium">
                      Direct messaging
                    </div>
                    <Switch
                      checked={monetizationData?.allowDirectMessaging ?? false}
                      onClick={() => {
                        setMonetizationData({
                          ...monetizationData,
                          allowDirectMessaging:
                            !monetizationData?.allowDirectMessaging,
                        });
                      }}
                    />
                  </div>
                  {monetizationData?.allowDirectMessaging && (
                    <div className="flex w-full flex-col gap-8">
                      <h3 className="text-lg font-medium text-textParagraph dark:text-dark-textParagraph">
                        Configure messaging
                      </h3>
                      <div className="flex flex-col gap-2.5">
                        <div className="text-base font-medium">
                          Set messaging frequency
                        </div>
                        <Select
                          value={monetizationData?.messageFrequencyType}
                          onValueChange={(value) => {
                            setMonetizationData({
                              ...monetizationData,
                              messageFrequencyType: value as
                                | 'CAPPED'
                                | 'UNLIMITED',
                            });
                          }}
                        >
                          <SelectTrigger className="h-fit border-light p-4 capitalize dark:border-dark-light">
                            {monetizationData?.messageFrequencyType?.toLowerCase() ??
                              'Select frequency'}
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="CAPPED">Capped</SelectItem>
                            <SelectItem value="UNLIMITED">Unlimited</SelectItem>
                          </SelectContent>
                        </Select>
                      </div>
                      <div className="flex flex-col gap-2.5">
                        <div className="text-base font-medium">
                          Set number of messages allowed
                        </div>
                        <div className="flex gap-2.5">
                          <InputField
                            type="number"
                            regex={/^\d+$/}
                            value={monetizationData?.freeMessagesLimit}
                            onChange={(e) => {
                              setMonetizationData({
                                ...monetizationData,
                                freeMessagesLimit: parseInt(e.target.value),
                              });
                            }}
                            inputClassName="!bg-transparent border-light dark:border-dark-light !p-2.5"
                            className="!gap-0"
                          />
                          <Select
                            value={monetizationData?.freeMessagesFrequency}
                            onValueChange={(value) => {
                              setMonetizationData({
                                ...monetizationData,
                                freeMessagesFrequency:
                                  value as UpdateSpaceGroupMonetizationSettingsDtoFreeMessagesFrequency,
                              });
                            }}
                          >
                            <SelectTrigger className="h-fit border-light p-2.5 text-base capitalize dark:border-dark-light">
                              {monetizationData?.freeMessagesFrequency
                                ?.replace('_', ' ')
                                .toLowerCase() ?? 'Select type'}
                            </SelectTrigger>
                            <SelectContent>
                              <SelectItem value="PER_DAY">Per day</SelectItem>
                              <SelectItem value="PER_WEEK">Per week</SelectItem>
                              <SelectItem value="PER_MONTH">
                                Per month
                              </SelectItem>
                              <SelectItem value="PER_YEAR">Per year</SelectItem>
                              <SelectItem value="TOTAL">Total</SelectItem>
                            </SelectContent>
                          </Select>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="flex w-full items-center gap-2.5">
                    <div className="flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-md bg-[#FBEDFF]">
                      <CalendarRoudedIcon className="h-6 w-6 stroke-[#C413F5]" />
                    </div>
                    <div className="w-full text-lg font-medium">
                      Calendar access
                    </div>
                    <Switch
                      checked={monetizationData?.allowCallMeetings ?? false}
                      onClick={() => {
                        setMonetizationData({
                          ...monetizationData,
                          allowCallMeetings:
                            !monetizationData?.allowCallMeetings,
                        });
                      }}
                    />
                  </div>
                  {monetizationData?.allowCallMeetings && (
                    <div className="flex w-full flex-col gap-8">
                      <h3 className="text-lg font-medium text-textParagraph dark:text-dark-textParagraph">
                        Configure booking
                      </h3>
                      <div className="flex flex-col gap-2.5">
                        <div className="text-base font-medium">
                          Allow each member to book
                        </div>
                        <div className="flex gap-2.5">
                          <InputField
                            type="number"
                            regex={/^\d+$/}
                            value={monetizationData?.freeCallsLimit}
                            onChange={(e) => {
                              setMonetizationData({
                                ...monetizationData,
                                freeCallsLimit: parseInt(e.target.value),
                              });
                            }}
                            inputClassName="!bg-transparent border-light dark:border-dark-light !p-2.5"
                            className="!gap-0"
                          />
                          <Select
                            value={monetizationData?.freeCallsFrequency}
                            onValueChange={(value) => {
                              setMonetizationData({
                                ...monetizationData,
                                freeCallsFrequency:
                                  value as UpdateSpaceGroupMonetizationSettingsDtoFreeCallsFrequency,
                              });
                            }}
                          >
                            <SelectTrigger className="h-fit border-light p-2.5 text-base capitalize dark:border-dark-light">
                              {monetizationData?.freeCallsFrequency
                                ?.replace('_', ' ')
                                .toLowerCase() ?? 'Select type'}
                            </SelectTrigger>
                            <SelectContent>
                              <SelectItem value="PER_DAY">Per day</SelectItem>
                              <SelectItem value="PER_WEEK">Per week</SelectItem>
                              <SelectItem value="PER_MONTH">
                                Per month
                              </SelectItem>
                              <SelectItem value="PER_YEAR">Per year</SelectItem>
                              <SelectItem value="TOTAL">Total</SelectItem>
                            </SelectContent>
                          </Select>
                        </div>
                      </div>
                      <div className="flex flex-col gap-2.5">
                        <div className="text-base font-medium">
                          Set call duration
                        </div>
                        <div className="flex gap-2.5">
                          <InputField
                            type="number"
                            regex={/^\d+$/}
                            value={monetizationData?.freeCallDuration}
                            onChange={(e) => {
                              setMonetizationData({
                                ...monetizationData,
                                freeCallDuration: parseInt(e.target.value),
                              });
                            }}
                            inputClassName="!bg-transparent border-light dark:border-dark-light !p-2.5"
                            className="!gap-0"
                          />
                          <Select
                            value={monetizationData?.freeCallDurationType}
                            onValueChange={(value) => {
                              setMonetizationData({
                                ...monetizationData,
                                freeCallDurationType:
                                  value as UpdateSpaceGroupMonetizationSettingsDtoFreeCallDurationType,
                              });
                            }}
                          >
                            <SelectTrigger className="h-fit border-light p-2.5 text-base capitalize dark:border-dark-light">
                              {monetizationData?.freeCallDurationType?.toLowerCase() ??
                                'Select type'}
                            </SelectTrigger>
                            <SelectContent>
                              <SelectItem value="MINUTES">Minutes</SelectItem>
                              <SelectItem value="HOURS">Hours</SelectItem>
                            </SelectContent>
                          </Select>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </TabsContent>
            </Tabs>

            <Button
              type="submit"
              className="w-full rounded-lg"
              disabled={updateSpaceGroupMutation.isPending}
            >
              Continue
            </Button>
          </form>
        </Form>
      </div>
    </DialogContent>
  );
};
