export const BankIcon = (props) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.9961 7.5H12.0051"
      strokeOpacity="0.4"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 10.4961V19.5008M9 10.4961V19.5008"
      strokeOpacity="0.4"
      strokeWidth="1.5"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M15 10.4961V19.5008M19 10.4961V19.5008"
      strokeOpacity="0.4"
      strokeWidth="1.5"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M21 19.5H3L3.0003 22.4999H21V19.5Z"
      strokeOpacity="0.4"
      strokeWidth="1.5"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M3 7.5L12 2.5L21 7.5V10.495H3V7.5Z"
      strokeOpacity="0.4"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
  </svg>
);
