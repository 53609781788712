export const PinIcon = (props) => (
  <svg
    // width={18}
    // height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.5 16.5007L5.66667 12.334"
      //   stroke="black"
      //   strokeOpacity={0.4}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0491 14.7262C6.92962 14.0179 3.98236 11.0707 3.2741 7.95125C3.16198 7.45742 3.10593 7.21055 3.26833 6.80998C3.43074 6.40941 3.62913 6.28546 4.02592 6.03758C4.92287 5.47721 5.89411 5.29907 6.90207 5.38822C8.31641 5.51332 9.02357 5.57587 9.37632 5.39207C9.72916 5.20826 9.96882 4.77848 10.4483 3.91891L11.0557 2.83003C11.4557 2.11273 11.6558 1.75408 12.1264 1.58502C12.597 1.41594 12.8802 1.51833 13.4466 1.72309C14.7711 2.20197 15.7983 3.22921 16.2772 4.55374C16.482 5.12014 16.5844 5.40334 16.4153 5.87392C16.2462 6.3445 15.8876 6.54455 15.1702 6.94465L14.0563 7.566C13.1983 8.0445 12.7694 8.28383 12.5857 8.64C12.4019 8.99625 12.4687 9.688 12.6021 11.0716C12.7002 12.089 12.5311 13.0667 11.9632 13.9748C11.7151 14.3715 11.591 14.5699 11.1906 14.7322C10.7901 14.8944 10.5431 14.8383 10.0491 14.7262Z"
      //   stroke="black"
      //   strokeOpacity={0.4}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
