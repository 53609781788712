export const BrushIcon = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.24565 11.4615C8.81942 8.47098 14.649 2.62963 17.1156 2.50436C18.6416 2.3582 15.6015 7.77188 8.39875 13.6956M9.54842 8.3714L11.4298 10.2714M2.5 17.3795C3.09123 15.29 2.71823 16.3168 2.92006 13.9106C3.02755 13.5543 3.24382 12.4487 4.59465 11.8977C6.13015 11.2714 7.25582 12.2182 7.54677 12.6631C8.40392 13.5925 8.50325 14.7466 7.54677 16.0651C6.59025 17.3836 3.75293 17.7112 2.5 17.3795Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
