import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { DiscussionDetails } from '@/api';
import { ArrowLeftIcon } from '@/assets/icon/arrowLeft';
import { SearchIcon } from '@/assets/icon/search';
import InputField from '@/components/ui/InputField';
import DiscussionCard from '@/components/ui/discussions-components/DiscussionCard';

type Props = {
  onSelectDiscussion: (discussion: DiscussionDetails) => void;
  isOpenDiscussion: boolean;
  discussions: DiscussionDetails[];
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  findUser: (id: string) => { username: string } | null;
  onMentionClick: (uid: string) => void;
};

const DiscussionList = ({
  discussions,
  isOpen,
  setIsOpen,
  findUser,
  onMentionClick,
}: Props) => {
  const { brandId, spaceGroupId, postsSpaceId } = useParams();
  const [discussionSearch, setDiscussionSearch] = useState('');

  const filteredDiscussions = discussions.filter(
    (discussion) =>
      discussion.post?.title
        ?.toLowerCase()
        .includes(discussionSearch.toLowerCase()) ||
      discussion.post.content
        .toLowerCase()
        .includes(discussionSearch.toLowerCase()),
  );

  const handleSearch = (e: any) => {
    setDiscussionSearch(e.target.value);
  };

  return (
    <div
      className={`${isOpen ? 'hidden lg:flex' : 'flex'} h-full w-full flex-col gap-6 overflow-auto px-6 py-6 md:border-light md:dark:border-dark-light lg:min-w-75 lg:border-r lg:px-2.5`}
    >
      <Link
        to={`/brands/${brandId}/space-groups/${spaceGroupId}/posts-spaces/${postsSpaceId}`}
        className="flex w-fit items-center gap-2.5"
      >
        <ArrowLeftIcon className="h-6 w-6 fill-black dark:fill-white" />
        Back to posts
      </Link>
      <InputField
        placeholder="Search discussion"
        onChange={handleSearch}
        type="text"
        value={discussionSearch}
        startIcon={<SearchIcon className="fill-black dark:fill-white" />}
        className="max-w-104"
        inputClassName="!rounded-full placeholder:text-textParagraph bg-bgGrey dark:bg-dark-bgGrey border-none !p-2.5 text-base items-center"
      />
      <h1 className="text-lg font-semibold">Joined discussions</h1>
      <div className="flex flex-col gap-4">
        {filteredDiscussions.map((discussion) => (
          <DiscussionCard
            key={discussion.id}
            discussion={discussion}
            handleOpen={setIsOpen}
            findUser={findUser}
            onMentionClick={onMentionClick}
          />
        ))}
      </div>
    </div>
  );
};

export default DiscussionList;
