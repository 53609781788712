import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { z } from 'zod';

import {
  CourseWithLessons,
  useCoursesSpacesControllerGetCourseWithLessonsByCourseId,
  useCoursesSpacesControllerUpdateCourse,
} from '@/api';
import { Loading } from '@/components/Loading';
import { useUpdateConfigHeader } from '@/components/config-header-provider';
import { ImageDropzone } from '@/components/image-dropzone';
import InputField from '@/components/ui/InputField';
import { Button } from '@/components/ui/button';
import { imageToBase64 } from '@/lib/utils';

const EditCoursePage = () => {
  const { coursesSpaceId, brandId, spaceGroupId, courseId } = useParams();
  useUpdateConfigHeader(
    {
      showNotifications: true,
      label: 'Edit Course',
      action: null,
    },
    [],
  );

  const courseQuery = useCoursesSpacesControllerGetCourseWithLessonsByCourseId(
    courseId ?? '',
  );

  if (courseQuery.isLoading) {
    return <Loading />;
  }

  const course = courseQuery.data;

  if (!course) {
    throw new Error(
      `No course found for course id ${courseId}, ${courseQuery.error}`,
    );
  }

  return (
    <EditCourseInner
      course={course}
      brandId={brandId ?? ''}
      spaceGroupId={spaceGroupId ?? ''}
      coursesSpaceId={coursesSpaceId ?? ''}
    />
  );
};

const EditCourseInner = ({
  course,
  brandId,
  spaceGroupId,
  coursesSpaceId,
}: {
  course: CourseWithLessons;
  brandId: string;
  spaceGroupId: string;
  coursesSpaceId: string;
}) => {
  const [name, setName] = useState<string>(course.name);
  const [coverImage, setCoverImage] = useState<File | null>(null);
  const [isUsingOriginalImage, setIsUsingOriginalImage] =
    useState<boolean>(true);

  const [nameError, setNameError] = useState<string | null>(null);
  const [coverImageError, setCoverImageError] = useState<string | null>(null);

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleCoverImage = (file: File | null) => {
    setCoverImage(file);
  };

  const handleRemoveCoverImage = () => {
    setCoverImage(null);
  };

  const handleRemoveOriginalImage = () => {
    setIsUsingOriginalImage(false);
  };

  const getImageUrl = () => {
    if (coverImage) {
      return URL.createObjectURL(coverImage);
    }
    return '';
  };

  const updateCourseMutation = useCoursesSpacesControllerUpdateCourse({
    mutation: {
      onSuccess: () => {
        navigate(
          `/brands/${brandId}/space-groups/${spaceGroupId}/courses-spaces/${coursesSpaceId}`,
        );
      },
    },
  });

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(
      `/brands/${brandId}/space-groups/${spaceGroupId}/courses-spaces/${coursesSpaceId}`,
    );
  };

  const handleSaveAndContinue = async () => {
    const createCourseSchema = z
      .object({
        name: z.string().min(1, 'Name is required'),
        coverImage: z.instanceof(File).nullish(),
        isUsingOriginalImage: z.boolean(),
      })
      .refine(
        (data) => {
          if (data.isUsingOriginalImage) {
            return data.coverImage === null;
          }
          return data.coverImage !== null;
        },
        {
          message: 'Cover image is required',
          path: ['coverImage'],
        },
      );

    setNameError(null);
    setCoverImageError(null);

    const result = createCourseSchema.safeParse({
      name,
      coverImage,
      isUsingOriginalImage,
    });

    if (!result.success) {
      setNameError(result.error.formErrors.fieldErrors.name?.[0] ?? null);
      setCoverImageError(
        result.error.formErrors.fieldErrors.coverImage?.[0] ?? null,
      );

      return;
    }

    const data = result.data;

    const image = data?.coverImage
      ? await imageToBase64(data.coverImage)
      : undefined;

    if (data.name === course.name && data.isUsingOriginalImage) {
      handleGoBack();
      return;
    }

    updateCourseMutation.mutate({
      courseId: course.id,
      data: {
        name: data.name,
        image,
      },
    });
  };

  const getImageInput = () => {
    if (isUsingOriginalImage && course.image) {
      return (
        <div className="flex flex-col items-center justify-center gap-6 space-y-2 rounded-lg border-2 border-dashed bg-[#F3F4F6] px-2 py-4 hover:border-muted-foreground/50 dark:bg-[#202224]">
          <img src={course.image} className="w-80" />
          <Button
            variant="ghost"
            className="border border-light dark:border-dark-light"
            onClick={handleRemoveOriginalImage}
          >
            Remove image
          </Button>
        </div>
      );
    }

    if (coverImage) {
      return (
        <>
          <div className="flex flex-col items-center justify-center gap-6 space-y-2 rounded-lg border-2 border-dashed bg-[#F3F4F6] px-2 py-4 hover:border-muted-foreground/50 dark:bg-[#202224]">
            <img src={getImageUrl()} className="w-80" />
            <Button
              variant="ghost"
              className="border border-light dark:border-dark-light"
              onClick={handleRemoveCoverImage}
            >
              Replace image
            </Button>
          </div>
        </>
      );
    }

    return (
      <>
        <ImageDropzone
          onFileChange={handleCoverImage}
          infoText="JPEG, PNG, PDF, and MP4 formats, up to 50 MB."
          accept=".jpg, .jpeg, .png"
          ratio={3 / 2}
        />
        {coverImageError && <p className="text-red-500">{coverImageError}</p>}
      </>
    );
  };

  return (
    <div className="px-6 py-10 xl:px-75 xl:py-16">
      <h1 className="text-2xl font-semibold text-black dark:text-white">
        Setting course
      </h1>
      <div className="flex flex-col gap-4 p-4">
        <h2 className="text-lg font-semibold text-black dark:text-white">
          Course Name
        </h2>
        <InputField
          onChange={handleChangeName}
          type="text"
          value={name}
          placeholder="Ex.: Fitness Journey"
          inputClassName="!bg-transparent border border-light dark:border-dark-light"
          error={nameError ?? undefined}
        />
      </div>
      <div className="flex w-full flex-col gap-6 p-4">
        <div className="flex flex-col">
          <h2 className="text-lg font-semibold text-black dark:text-white">
            Cover Image
          </h2>
          <p className="text-textParagraph dark:text-dark-textParagraph">
            Select an image for your course
          </p>
        </div>
        {getImageInput()}
      </div>
      <div className="flex w-full justify-end gap-3.5">
        <Button
          variant={'outline'}
          className="w-full sm:w-36"
          onClick={handleGoBack}
          disabled={updateCourseMutation.isPending}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSaveAndContinue}
          disabled={updateCourseMutation.isPending}
        >
          Save and continue
        </Button>
      </div>
    </div>
  );
};

export default EditCoursePage;
