/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * Teachly API V2
 * Teachly API documentation
 * OpenAPI spec version: 1.0
 */
import { useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query';
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryClient,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';

import { customFetch } from '../../../mutator/custom-fetch';
import type {
  Course,
  CourseLesson,
  CourseWithLessons,
  CoursesSpace,
  CoursesSpacesControllerGetCourseProgress200,
  CoursesSpacesControllerGetLessonProgress200,
  CoursesSpacesControllerMarkLessonAsCompleted200,
  CreateCourseDto,
  CreateCourseLessonDto,
  CreateCoursesSpaceDto,
  OrganizeLessonsDto,
  RemoveFilesDto,
  SaveOrganizedCoursesDto,
  SuccessMessageResponseDto,
  UpdateCourseDto,
  UpdateCourseLessonDto,
  UpdateCoursesSpaceDto,
  UploadFilesDto,
} from '../../model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * Creates a new courses space within a specific space group.
 * @summary Create a new courses space
 */
export const coursesSpacesControllerCreateCoursesSpace = (
  createCoursesSpaceDto: CreateCoursesSpaceDto,
  options?: SecondParameter<typeof customFetch>,
) => {
  return customFetch<CoursesSpace>(
    {
      url: `/courses-spaces`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createCoursesSpaceDto,
    },
    options,
  );
};

export const getCoursesSpacesControllerCreateCoursesSpaceMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof coursesSpacesControllerCreateCoursesSpace>>,
    TError,
    { data: CreateCoursesSpaceDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof coursesSpacesControllerCreateCoursesSpace>>,
  TError,
  { data: CreateCoursesSpaceDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof coursesSpacesControllerCreateCoursesSpace>>,
    { data: CreateCoursesSpaceDto }
  > = (props) => {
    const { data } = props ?? {};

    return coursesSpacesControllerCreateCoursesSpace(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CoursesSpacesControllerCreateCoursesSpaceMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof coursesSpacesControllerCreateCoursesSpace>>
  >;
export type CoursesSpacesControllerCreateCoursesSpaceMutationBody =
  CreateCoursesSpaceDto;
export type CoursesSpacesControllerCreateCoursesSpaceMutationError = void;

/**
 * @summary Create a new courses space
 */
export const useCoursesSpacesControllerCreateCoursesSpace = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof coursesSpacesControllerCreateCoursesSpace>>,
    TError,
    { data: CreateCoursesSpaceDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof coursesSpacesControllerCreateCoursesSpace>>,
  TError,
  { data: CreateCoursesSpaceDto },
  TContext
> => {
  const mutationOptions =
    getCoursesSpacesControllerCreateCoursesSpaceMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Retrieves all courses spaces associated with a specific user.
 * @summary Get all courses spaces for a user
 */
export const coursesSpacesControllerGetUserCoursesSpaces = (
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<CoursesSpace[]>(
    { url: `/courses-spaces/my`, method: 'GET', signal },
    options,
  );
};

export const getCoursesSpacesControllerGetUserCoursesSpacesQueryKey = () => {
  return [`/courses-spaces/my`] as const;
};

export const getCoursesSpacesControllerGetUserCoursesSpacesQueryOptions = <
  TData = Awaited<
    ReturnType<typeof coursesSpacesControllerGetUserCoursesSpaces>
  >,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof coursesSpacesControllerGetUserCoursesSpaces>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getCoursesSpacesControllerGetUserCoursesSpacesQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof coursesSpacesControllerGetUserCoursesSpaces>>
  > = ({ signal }) =>
    coursesSpacesControllerGetUserCoursesSpaces(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof coursesSpacesControllerGetUserCoursesSpaces>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CoursesSpacesControllerGetUserCoursesSpacesQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof coursesSpacesControllerGetUserCoursesSpaces>>
  >;
export type CoursesSpacesControllerGetUserCoursesSpacesQueryError = void;

export function useCoursesSpacesControllerGetUserCoursesSpaces<
  TData = Awaited<
    ReturnType<typeof coursesSpacesControllerGetUserCoursesSpaces>
  >,
  TError = void,
>(options: {
  query: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof coursesSpacesControllerGetUserCoursesSpaces>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof coursesSpacesControllerGetUserCoursesSpaces>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customFetch>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useCoursesSpacesControllerGetUserCoursesSpaces<
  TData = Awaited<
    ReturnType<typeof coursesSpacesControllerGetUserCoursesSpaces>
  >,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof coursesSpacesControllerGetUserCoursesSpaces>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof coursesSpacesControllerGetUserCoursesSpaces>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useCoursesSpacesControllerGetUserCoursesSpaces<
  TData = Awaited<
    ReturnType<typeof coursesSpacesControllerGetUserCoursesSpaces>
  >,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof coursesSpacesControllerGetUserCoursesSpaces>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get all courses spaces for a user
 */

export function useCoursesSpacesControllerGetUserCoursesSpaces<
  TData = Awaited<
    ReturnType<typeof coursesSpacesControllerGetUserCoursesSpaces>
  >,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof coursesSpacesControllerGetUserCoursesSpaces>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getCoursesSpacesControllerGetUserCoursesSpacesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get all courses spaces for a user
 */
export const prefetchCoursesSpacesControllerGetUserCoursesSpaces = async <
  TData = Awaited<
    ReturnType<typeof coursesSpacesControllerGetUserCoursesSpaces>
  >,
  TError = void,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof coursesSpacesControllerGetUserCoursesSpaces>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions =
    getCoursesSpacesControllerGetUserCoursesSpacesQueryOptions(options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getCoursesSpacesControllerGetUserCoursesSpacesSuspenseQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof coursesSpacesControllerGetUserCoursesSpaces>
    >,
    TError = void,
  >(options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof coursesSpacesControllerGetUserCoursesSpaces>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  }) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getCoursesSpacesControllerGetUserCoursesSpacesQueryKey();

    const queryFn: QueryFunction<
      Awaited<ReturnType<typeof coursesSpacesControllerGetUserCoursesSpaces>>
    > = ({ signal }) =>
      coursesSpacesControllerGetUserCoursesSpaces(requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof coursesSpacesControllerGetUserCoursesSpaces>>,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type CoursesSpacesControllerGetUserCoursesSpacesSuspenseQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof coursesSpacesControllerGetUserCoursesSpaces>>
  >;
export type CoursesSpacesControllerGetUserCoursesSpacesSuspenseQueryError =
  void;

export function useCoursesSpacesControllerGetUserCoursesSpacesSuspense<
  TData = Awaited<
    ReturnType<typeof coursesSpacesControllerGetUserCoursesSpaces>
  >,
  TError = void,
>(options: {
  query: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof coursesSpacesControllerGetUserCoursesSpaces>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useCoursesSpacesControllerGetUserCoursesSpacesSuspense<
  TData = Awaited<
    ReturnType<typeof coursesSpacesControllerGetUserCoursesSpaces>
  >,
  TError = void,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof coursesSpacesControllerGetUserCoursesSpaces>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useCoursesSpacesControllerGetUserCoursesSpacesSuspense<
  TData = Awaited<
    ReturnType<typeof coursesSpacesControllerGetUserCoursesSpaces>
  >,
  TError = void,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof coursesSpacesControllerGetUserCoursesSpaces>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get all courses spaces for a user
 */

export function useCoursesSpacesControllerGetUserCoursesSpacesSuspense<
  TData = Awaited<
    ReturnType<typeof coursesSpacesControllerGetUserCoursesSpaces>
  >,
  TError = void,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof coursesSpacesControllerGetUserCoursesSpaces>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getCoursesSpacesControllerGetUserCoursesSpacesSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Updates a courses space for the authenticated user.
 * @summary Update a courses space
 */
export const coursesSpacesControllerUpdateCoursesSpace = (
  coursesSpaceId: string,
  updateCoursesSpaceDto: UpdateCoursesSpaceDto,
  options?: SecondParameter<typeof customFetch>,
) => {
  return customFetch<CoursesSpace>(
    {
      url: `/courses-spaces/${encodeURIComponent(String(coursesSpaceId))}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: updateCoursesSpaceDto,
    },
    options,
  );
};

export const getCoursesSpacesControllerUpdateCoursesSpaceMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof coursesSpacesControllerUpdateCoursesSpace>>,
    TError,
    { coursesSpaceId: string; data: UpdateCoursesSpaceDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof coursesSpacesControllerUpdateCoursesSpace>>,
  TError,
  { coursesSpaceId: string; data: UpdateCoursesSpaceDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof coursesSpacesControllerUpdateCoursesSpace>>,
    { coursesSpaceId: string; data: UpdateCoursesSpaceDto }
  > = (props) => {
    const { coursesSpaceId, data } = props ?? {};

    return coursesSpacesControllerUpdateCoursesSpace(
      coursesSpaceId,
      data,
      requestOptions,
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type CoursesSpacesControllerUpdateCoursesSpaceMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof coursesSpacesControllerUpdateCoursesSpace>>
  >;
export type CoursesSpacesControllerUpdateCoursesSpaceMutationBody =
  UpdateCoursesSpaceDto;
export type CoursesSpacesControllerUpdateCoursesSpaceMutationError = void;

/**
 * @summary Update a courses space
 */
export const useCoursesSpacesControllerUpdateCoursesSpace = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof coursesSpacesControllerUpdateCoursesSpace>>,
    TError,
    { coursesSpaceId: string; data: UpdateCoursesSpaceDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof coursesSpacesControllerUpdateCoursesSpace>>,
  TError,
  { coursesSpaceId: string; data: UpdateCoursesSpaceDto },
  TContext
> => {
  const mutationOptions =
    getCoursesSpacesControllerUpdateCoursesSpaceMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Deletes a courses space for the authenticated user.
 * @summary Delete a courses space
 */
export const coursesSpacesControllerDeleteCoursesSpace = (
  coursesSpaceId: string,
  options?: SecondParameter<typeof customFetch>,
) => {
  return customFetch<SuccessMessageResponseDto>(
    {
      url: `/courses-spaces/${encodeURIComponent(String(coursesSpaceId))}`,
      method: 'DELETE',
    },
    options,
  );
};

export const getCoursesSpacesControllerDeleteCoursesSpaceMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof coursesSpacesControllerDeleteCoursesSpace>>,
    TError,
    { coursesSpaceId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof coursesSpacesControllerDeleteCoursesSpace>>,
  TError,
  { coursesSpaceId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof coursesSpacesControllerDeleteCoursesSpace>>,
    { coursesSpaceId: string }
  > = (props) => {
    const { coursesSpaceId } = props ?? {};

    return coursesSpacesControllerDeleteCoursesSpace(
      coursesSpaceId,
      requestOptions,
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type CoursesSpacesControllerDeleteCoursesSpaceMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof coursesSpacesControllerDeleteCoursesSpace>>
  >;

export type CoursesSpacesControllerDeleteCoursesSpaceMutationError = void;

/**
 * @summary Delete a courses space
 */
export const useCoursesSpacesControllerDeleteCoursesSpace = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof coursesSpacesControllerDeleteCoursesSpace>>,
    TError,
    { coursesSpaceId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof coursesSpacesControllerDeleteCoursesSpace>>,
  TError,
  { coursesSpaceId: string },
  TContext
> => {
  const mutationOptions =
    getCoursesSpacesControllerDeleteCoursesSpaceMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Creates a new course within a specific courses space.
 * @summary Create a new course
 */
export const coursesSpacesControllerCreateCourse = (
  createCourseDto: CreateCourseDto,
  options?: SecondParameter<typeof customFetch>,
) => {
  return customFetch<Course>(
    {
      url: `/courses-spaces/courses/create`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createCourseDto,
    },
    options,
  );
};

export const getCoursesSpacesControllerCreateCourseMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof coursesSpacesControllerCreateCourse>>,
    TError,
    { data: CreateCourseDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof coursesSpacesControllerCreateCourse>>,
  TError,
  { data: CreateCourseDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof coursesSpacesControllerCreateCourse>>,
    { data: CreateCourseDto }
  > = (props) => {
    const { data } = props ?? {};

    return coursesSpacesControllerCreateCourse(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CoursesSpacesControllerCreateCourseMutationResult = NonNullable<
  Awaited<ReturnType<typeof coursesSpacesControllerCreateCourse>>
>;
export type CoursesSpacesControllerCreateCourseMutationBody = CreateCourseDto;
export type CoursesSpacesControllerCreateCourseMutationError = void;

/**
 * @summary Create a new course
 */
export const useCoursesSpacesControllerCreateCourse = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof coursesSpacesControllerCreateCourse>>,
    TError,
    { data: CreateCourseDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof coursesSpacesControllerCreateCourse>>,
  TError,
  { data: CreateCourseDto },
  TContext
> => {
  const mutationOptions =
    getCoursesSpacesControllerCreateCourseMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Retrieves all courses with lessons associated with a specific courses space.
 * @summary Get all courses with lessons for a courses space
 */
export const coursesSpacesControllerGetCoursesWithLessons = (
  coursesSpaceId: string,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<CourseWithLessons[]>(
    {
      url: `/courses-spaces/${encodeURIComponent(String(coursesSpaceId))}/courses`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getCoursesSpacesControllerGetCoursesWithLessonsQueryKey = (
  coursesSpaceId: string,
) => {
  return [`/courses-spaces/${coursesSpaceId}/courses`] as const;
};

export const getCoursesSpacesControllerGetCoursesWithLessonsQueryOptions = <
  TData = Awaited<
    ReturnType<typeof coursesSpacesControllerGetCoursesWithLessons>
  >,
  TError = void,
>(
  coursesSpaceId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof coursesSpacesControllerGetCoursesWithLessons>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getCoursesSpacesControllerGetCoursesWithLessonsQueryKey(coursesSpaceId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof coursesSpacesControllerGetCoursesWithLessons>>
  > = ({ signal }) =>
    coursesSpacesControllerGetCoursesWithLessons(
      coursesSpaceId,
      requestOptions,
      signal,
    );

  return {
    queryKey,
    queryFn,
    enabled: !!coursesSpaceId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof coursesSpacesControllerGetCoursesWithLessons>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CoursesSpacesControllerGetCoursesWithLessonsQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof coursesSpacesControllerGetCoursesWithLessons>>
  >;
export type CoursesSpacesControllerGetCoursesWithLessonsQueryError = void;

export function useCoursesSpacesControllerGetCoursesWithLessons<
  TData = Awaited<
    ReturnType<typeof coursesSpacesControllerGetCoursesWithLessons>
  >,
  TError = void,
>(
  coursesSpaceId: string,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof coursesSpacesControllerGetCoursesWithLessons>
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof coursesSpacesControllerGetCoursesWithLessons>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useCoursesSpacesControllerGetCoursesWithLessons<
  TData = Awaited<
    ReturnType<typeof coursesSpacesControllerGetCoursesWithLessons>
  >,
  TError = void,
>(
  coursesSpaceId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof coursesSpacesControllerGetCoursesWithLessons>
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof coursesSpacesControllerGetCoursesWithLessons>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useCoursesSpacesControllerGetCoursesWithLessons<
  TData = Awaited<
    ReturnType<typeof coursesSpacesControllerGetCoursesWithLessons>
  >,
  TError = void,
>(
  coursesSpaceId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof coursesSpacesControllerGetCoursesWithLessons>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get all courses with lessons for a courses space
 */

export function useCoursesSpacesControllerGetCoursesWithLessons<
  TData = Awaited<
    ReturnType<typeof coursesSpacesControllerGetCoursesWithLessons>
  >,
  TError = void,
>(
  coursesSpaceId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof coursesSpacesControllerGetCoursesWithLessons>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getCoursesSpacesControllerGetCoursesWithLessonsQueryOptions(
      coursesSpaceId,
      options,
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get all courses with lessons for a courses space
 */
export const prefetchCoursesSpacesControllerGetCoursesWithLessons = async <
  TData = Awaited<
    ReturnType<typeof coursesSpacesControllerGetCoursesWithLessons>
  >,
  TError = void,
>(
  queryClient: QueryClient,
  coursesSpaceId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof coursesSpacesControllerGetCoursesWithLessons>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions =
    getCoursesSpacesControllerGetCoursesWithLessonsQueryOptions(
      coursesSpaceId,
      options,
    );

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getCoursesSpacesControllerGetCoursesWithLessonsSuspenseQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof coursesSpacesControllerGetCoursesWithLessons>
    >,
    TError = void,
  >(
    coursesSpaceId: string,
    options?: {
      query?: Partial<
        UseSuspenseQueryOptions<
          Awaited<
            ReturnType<typeof coursesSpacesControllerGetCoursesWithLessons>
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customFetch>;
    },
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getCoursesSpacesControllerGetCoursesWithLessonsQueryKey(coursesSpaceId);

    const queryFn: QueryFunction<
      Awaited<ReturnType<typeof coursesSpacesControllerGetCoursesWithLessons>>
    > = ({ signal }) =>
      coursesSpacesControllerGetCoursesWithLessons(
        coursesSpaceId,
        requestOptions,
        signal,
      );

    return {
      queryKey,
      queryFn,
      enabled: !!coursesSpaceId,
      ...queryOptions,
    } as UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof coursesSpacesControllerGetCoursesWithLessons>>,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type CoursesSpacesControllerGetCoursesWithLessonsSuspenseQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof coursesSpacesControllerGetCoursesWithLessons>>
  >;
export type CoursesSpacesControllerGetCoursesWithLessonsSuspenseQueryError =
  void;

export function useCoursesSpacesControllerGetCoursesWithLessonsSuspense<
  TData = Awaited<
    ReturnType<typeof coursesSpacesControllerGetCoursesWithLessons>
  >,
  TError = void,
>(
  coursesSpaceId: string,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<typeof coursesSpacesControllerGetCoursesWithLessons>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useCoursesSpacesControllerGetCoursesWithLessonsSuspense<
  TData = Awaited<
    ReturnType<typeof coursesSpacesControllerGetCoursesWithLessons>
  >,
  TError = void,
>(
  coursesSpaceId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<typeof coursesSpacesControllerGetCoursesWithLessons>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useCoursesSpacesControllerGetCoursesWithLessonsSuspense<
  TData = Awaited<
    ReturnType<typeof coursesSpacesControllerGetCoursesWithLessons>
  >,
  TError = void,
>(
  coursesSpaceId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<typeof coursesSpacesControllerGetCoursesWithLessons>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get all courses with lessons for a courses space
 */

export function useCoursesSpacesControllerGetCoursesWithLessonsSuspense<
  TData = Awaited<
    ReturnType<typeof coursesSpacesControllerGetCoursesWithLessons>
  >,
  TError = void,
>(
  coursesSpaceId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<typeof coursesSpacesControllerGetCoursesWithLessons>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getCoursesSpacesControllerGetCoursesWithLessonsSuspenseQueryOptions(
      coursesSpaceId,
      options,
    );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Retrieves a course with lessons for the authenticated user.
 * @summary Get a course with lessons
 */
export const coursesSpacesControllerGetCourseWithLessonsByCourseId = (
  courseId: string,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<CourseWithLessons>(
    {
      url: `/courses-spaces/course/${encodeURIComponent(String(courseId))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getCoursesSpacesControllerGetCourseWithLessonsByCourseIdQueryKey =
  (courseId: string) => {
    return [`/courses-spaces/course/${courseId}`] as const;
  };

export const getCoursesSpacesControllerGetCourseWithLessonsByCourseIdQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof coursesSpacesControllerGetCourseWithLessonsByCourseId>
    >,
    TError = void,
  >(
    courseId: string,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof coursesSpacesControllerGetCourseWithLessonsByCourseId
            >
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customFetch>;
    },
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getCoursesSpacesControllerGetCourseWithLessonsByCourseIdQueryKey(
        courseId,
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof coursesSpacesControllerGetCourseWithLessonsByCourseId>
      >
    > = ({ signal }) =>
      coursesSpacesControllerGetCourseWithLessonsByCourseId(
        courseId,
        requestOptions,
        signal,
      );

    return {
      queryKey,
      queryFn,
      enabled: !!courseId,
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<typeof coursesSpacesControllerGetCourseWithLessonsByCourseId>
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type CoursesSpacesControllerGetCourseWithLessonsByCourseIdQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof coursesSpacesControllerGetCourseWithLessonsByCourseId>
    >
  >;
export type CoursesSpacesControllerGetCourseWithLessonsByCourseIdQueryError =
  void;

export function useCoursesSpacesControllerGetCourseWithLessonsByCourseId<
  TData = Awaited<
    ReturnType<typeof coursesSpacesControllerGetCourseWithLessonsByCourseId>
  >,
  TError = void,
>(
  courseId: string,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof coursesSpacesControllerGetCourseWithLessonsByCourseId
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof coursesSpacesControllerGetCourseWithLessonsByCourseId
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useCoursesSpacesControllerGetCourseWithLessonsByCourseId<
  TData = Awaited<
    ReturnType<typeof coursesSpacesControllerGetCourseWithLessonsByCourseId>
  >,
  TError = void,
>(
  courseId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof coursesSpacesControllerGetCourseWithLessonsByCourseId
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof coursesSpacesControllerGetCourseWithLessonsByCourseId
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useCoursesSpacesControllerGetCourseWithLessonsByCourseId<
  TData = Awaited<
    ReturnType<typeof coursesSpacesControllerGetCourseWithLessonsByCourseId>
  >,
  TError = void,
>(
  courseId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof coursesSpacesControllerGetCourseWithLessonsByCourseId
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get a course with lessons
 */

export function useCoursesSpacesControllerGetCourseWithLessonsByCourseId<
  TData = Awaited<
    ReturnType<typeof coursesSpacesControllerGetCourseWithLessonsByCourseId>
  >,
  TError = void,
>(
  courseId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof coursesSpacesControllerGetCourseWithLessonsByCourseId
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getCoursesSpacesControllerGetCourseWithLessonsByCourseIdQueryOptions(
      courseId,
      options,
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get a course with lessons
 */
export const prefetchCoursesSpacesControllerGetCourseWithLessonsByCourseId =
  async <
    TData = Awaited<
      ReturnType<typeof coursesSpacesControllerGetCourseWithLessonsByCourseId>
    >,
    TError = void,
  >(
    queryClient: QueryClient,
    courseId: string,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof coursesSpacesControllerGetCourseWithLessonsByCourseId
            >
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customFetch>;
    },
  ): Promise<QueryClient> => {
    const queryOptions =
      getCoursesSpacesControllerGetCourseWithLessonsByCourseIdQueryOptions(
        courseId,
        options,
      );

    await queryClient.prefetchQuery(queryOptions);

    return queryClient;
  };

export const getCoursesSpacesControllerGetCourseWithLessonsByCourseIdSuspenseQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof coursesSpacesControllerGetCourseWithLessonsByCourseId>
    >,
    TError = void,
  >(
    courseId: string,
    options?: {
      query?: Partial<
        UseSuspenseQueryOptions<
          Awaited<
            ReturnType<
              typeof coursesSpacesControllerGetCourseWithLessonsByCourseId
            >
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customFetch>;
    },
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getCoursesSpacesControllerGetCourseWithLessonsByCourseIdQueryKey(
        courseId,
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof coursesSpacesControllerGetCourseWithLessonsByCourseId>
      >
    > = ({ signal }) =>
      coursesSpacesControllerGetCourseWithLessonsByCourseId(
        courseId,
        requestOptions,
        signal,
      );

    return {
      queryKey,
      queryFn,
      enabled: !!courseId,
      ...queryOptions,
    } as UseSuspenseQueryOptions<
      Awaited<
        ReturnType<typeof coursesSpacesControllerGetCourseWithLessonsByCourseId>
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type CoursesSpacesControllerGetCourseWithLessonsByCourseIdSuspenseQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof coursesSpacesControllerGetCourseWithLessonsByCourseId>
    >
  >;
export type CoursesSpacesControllerGetCourseWithLessonsByCourseIdSuspenseQueryError =
  void;

export function useCoursesSpacesControllerGetCourseWithLessonsByCourseIdSuspense<
  TData = Awaited<
    ReturnType<typeof coursesSpacesControllerGetCourseWithLessonsByCourseId>
  >,
  TError = void,
>(
  courseId: string,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<
            typeof coursesSpacesControllerGetCourseWithLessonsByCourseId
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useCoursesSpacesControllerGetCourseWithLessonsByCourseIdSuspense<
  TData = Awaited<
    ReturnType<typeof coursesSpacesControllerGetCourseWithLessonsByCourseId>
  >,
  TError = void,
>(
  courseId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<
            typeof coursesSpacesControllerGetCourseWithLessonsByCourseId
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useCoursesSpacesControllerGetCourseWithLessonsByCourseIdSuspense<
  TData = Awaited<
    ReturnType<typeof coursesSpacesControllerGetCourseWithLessonsByCourseId>
  >,
  TError = void,
>(
  courseId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<
            typeof coursesSpacesControllerGetCourseWithLessonsByCourseId
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get a course with lessons
 */

export function useCoursesSpacesControllerGetCourseWithLessonsByCourseIdSuspense<
  TData = Awaited<
    ReturnType<typeof coursesSpacesControllerGetCourseWithLessonsByCourseId>
  >,
  TError = void,
>(
  courseId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<
            typeof coursesSpacesControllerGetCourseWithLessonsByCourseId
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getCoursesSpacesControllerGetCourseWithLessonsByCourseIdSuspenseQueryOptions(
      courseId,
      options,
    );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Updates a course for the authenticated user.
 * @summary Update a course
 */
export const coursesSpacesControllerUpdateCourse = (
  courseId: string,
  updateCourseDto: UpdateCourseDto,
  options?: SecondParameter<typeof customFetch>,
) => {
  return customFetch<SuccessMessageResponseDto>(
    {
      url: `/courses-spaces/course/${encodeURIComponent(String(courseId))}/update`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: updateCourseDto,
    },
    options,
  );
};

export const getCoursesSpacesControllerUpdateCourseMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof coursesSpacesControllerUpdateCourse>>,
    TError,
    { courseId: string; data: UpdateCourseDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof coursesSpacesControllerUpdateCourse>>,
  TError,
  { courseId: string; data: UpdateCourseDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof coursesSpacesControllerUpdateCourse>>,
    { courseId: string; data: UpdateCourseDto }
  > = (props) => {
    const { courseId, data } = props ?? {};

    return coursesSpacesControllerUpdateCourse(courseId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CoursesSpacesControllerUpdateCourseMutationResult = NonNullable<
  Awaited<ReturnType<typeof coursesSpacesControllerUpdateCourse>>
>;
export type CoursesSpacesControllerUpdateCourseMutationBody = UpdateCourseDto;
export type CoursesSpacesControllerUpdateCourseMutationError = void;

/**
 * @summary Update a course
 */
export const useCoursesSpacesControllerUpdateCourse = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof coursesSpacesControllerUpdateCourse>>,
    TError,
    { courseId: string; data: UpdateCourseDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof coursesSpacesControllerUpdateCourse>>,
  TError,
  { courseId: string; data: UpdateCourseDto },
  TContext
> => {
  const mutationOptions =
    getCoursesSpacesControllerUpdateCourseMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Deletes a course for the authenticated user.
 * @summary Delete a course
 */
export const coursesSpacesControllerDeleteCourse = (
  courseId: string,
  options?: SecondParameter<typeof customFetch>,
) => {
  return customFetch<SuccessMessageResponseDto>(
    {
      url: `/courses-spaces/course/${encodeURIComponent(String(courseId))}/delete`,
      method: 'DELETE',
    },
    options,
  );
};

export const getCoursesSpacesControllerDeleteCourseMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof coursesSpacesControllerDeleteCourse>>,
    TError,
    { courseId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof coursesSpacesControllerDeleteCourse>>,
  TError,
  { courseId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof coursesSpacesControllerDeleteCourse>>,
    { courseId: string }
  > = (props) => {
    const { courseId } = props ?? {};

    return coursesSpacesControllerDeleteCourse(courseId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CoursesSpacesControllerDeleteCourseMutationResult = NonNullable<
  Awaited<ReturnType<typeof coursesSpacesControllerDeleteCourse>>
>;

export type CoursesSpacesControllerDeleteCourseMutationError = void;

/**
 * @summary Delete a course
 */
export const useCoursesSpacesControllerDeleteCourse = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof coursesSpacesControllerDeleteCourse>>,
    TError,
    { courseId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof coursesSpacesControllerDeleteCourse>>,
  TError,
  { courseId: string },
  TContext
> => {
  const mutationOptions =
    getCoursesSpacesControllerDeleteCourseMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Toggles the publish status of a course.
 * @summary Toggle publish a course
 */
export const coursesSpacesControllerTogglePublishCourse = (
  courseId: string,
  options?: SecondParameter<typeof customFetch>,
) => {
  return customFetch<SuccessMessageResponseDto>(
    {
      url: `/courses-spaces/course/${encodeURIComponent(String(courseId))}/toggle-publish`,
      method: 'PUT',
    },
    options,
  );
};

export const getCoursesSpacesControllerTogglePublishCourseMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof coursesSpacesControllerTogglePublishCourse>>,
    TError,
    { courseId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof coursesSpacesControllerTogglePublishCourse>>,
  TError,
  { courseId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof coursesSpacesControllerTogglePublishCourse>>,
    { courseId: string }
  > = (props) => {
    const { courseId } = props ?? {};

    return coursesSpacesControllerTogglePublishCourse(courseId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CoursesSpacesControllerTogglePublishCourseMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof coursesSpacesControllerTogglePublishCourse>>
  >;

export type CoursesSpacesControllerTogglePublishCourseMutationError = void;

/**
 * @summary Toggle publish a course
 */
export const useCoursesSpacesControllerTogglePublishCourse = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof coursesSpacesControllerTogglePublishCourse>>,
    TError,
    { courseId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof coursesSpacesControllerTogglePublishCourse>>,
  TError,
  { courseId: string },
  TContext
> => {
  const mutationOptions =
    getCoursesSpacesControllerTogglePublishCourseMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Creates a new course lesson within a specific course section.
 * @summary Create a new course lesson
 */
export const coursesSpacesControllerCreateCourseLesson = (
  createCourseLessonDto: CreateCourseLessonDto,
  options?: SecondParameter<typeof customFetch>,
) => {
  return customFetch<CourseLesson>(
    {
      url: `/courses-spaces/courses/lessons/create`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createCourseLessonDto,
    },
    options,
  );
};

export const getCoursesSpacesControllerCreateCourseLessonMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof coursesSpacesControllerCreateCourseLesson>>,
    TError,
    { data: CreateCourseLessonDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof coursesSpacesControllerCreateCourseLesson>>,
  TError,
  { data: CreateCourseLessonDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof coursesSpacesControllerCreateCourseLesson>>,
    { data: CreateCourseLessonDto }
  > = (props) => {
    const { data } = props ?? {};

    return coursesSpacesControllerCreateCourseLesson(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CoursesSpacesControllerCreateCourseLessonMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof coursesSpacesControllerCreateCourseLesson>>
  >;
export type CoursesSpacesControllerCreateCourseLessonMutationBody =
  CreateCourseLessonDto;
export type CoursesSpacesControllerCreateCourseLessonMutationError = void;

/**
 * @summary Create a new course lesson
 */
export const useCoursesSpacesControllerCreateCourseLesson = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof coursesSpacesControllerCreateCourseLesson>>,
    TError,
    { data: CreateCourseLessonDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof coursesSpacesControllerCreateCourseLesson>>,
  TError,
  { data: CreateCourseLessonDto },
  TContext
> => {
  const mutationOptions =
    getCoursesSpacesControllerCreateCourseLessonMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Updates a course lesson for the authenticated user.
 * @summary Update a course lesson
 */
export const coursesSpacesControllerUpdateCourseLesson = (
  lessonId: string,
  updateCourseLessonDto: UpdateCourseLessonDto,
  options?: SecondParameter<typeof customFetch>,
) => {
  return customFetch<SuccessMessageResponseDto>(
    {
      url: `/courses-spaces/courses/lessons/${encodeURIComponent(String(lessonId))}/update`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: updateCourseLessonDto,
    },
    options,
  );
};

export const getCoursesSpacesControllerUpdateCourseLessonMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof coursesSpacesControllerUpdateCourseLesson>>,
    TError,
    { lessonId: string; data: UpdateCourseLessonDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof coursesSpacesControllerUpdateCourseLesson>>,
  TError,
  { lessonId: string; data: UpdateCourseLessonDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof coursesSpacesControllerUpdateCourseLesson>>,
    { lessonId: string; data: UpdateCourseLessonDto }
  > = (props) => {
    const { lessonId, data } = props ?? {};

    return coursesSpacesControllerUpdateCourseLesson(
      lessonId,
      data,
      requestOptions,
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type CoursesSpacesControllerUpdateCourseLessonMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof coursesSpacesControllerUpdateCourseLesson>>
  >;
export type CoursesSpacesControllerUpdateCourseLessonMutationBody =
  UpdateCourseLessonDto;
export type CoursesSpacesControllerUpdateCourseLessonMutationError = void;

/**
 * @summary Update a course lesson
 */
export const useCoursesSpacesControllerUpdateCourseLesson = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof coursesSpacesControllerUpdateCourseLesson>>,
    TError,
    { lessonId: string; data: UpdateCourseLessonDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof coursesSpacesControllerUpdateCourseLesson>>,
  TError,
  { lessonId: string; data: UpdateCourseLessonDto },
  TContext
> => {
  const mutationOptions =
    getCoursesSpacesControllerUpdateCourseLessonMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Deletes a course lesson for the authenticated user.
 * @summary Delete a course lesson
 */
export const coursesSpacesControllerDeleteCourseLesson = (
  lessonId: string,
  options?: SecondParameter<typeof customFetch>,
) => {
  return customFetch<SuccessMessageResponseDto>(
    {
      url: `/courses-spaces/courses/lessons/${encodeURIComponent(String(lessonId))}/delete`,
      method: 'DELETE',
    },
    options,
  );
};

export const getCoursesSpacesControllerDeleteCourseLessonMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof coursesSpacesControllerDeleteCourseLesson>>,
    TError,
    { lessonId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof coursesSpacesControllerDeleteCourseLesson>>,
  TError,
  { lessonId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof coursesSpacesControllerDeleteCourseLesson>>,
    { lessonId: string }
  > = (props) => {
    const { lessonId } = props ?? {};

    return coursesSpacesControllerDeleteCourseLesson(lessonId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CoursesSpacesControllerDeleteCourseLessonMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof coursesSpacesControllerDeleteCourseLesson>>
  >;

export type CoursesSpacesControllerDeleteCourseLessonMutationError = void;

/**
 * @summary Delete a course lesson
 */
export const useCoursesSpacesControllerDeleteCourseLesson = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof coursesSpacesControllerDeleteCourseLesson>>,
    TError,
    { lessonId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof coursesSpacesControllerDeleteCourseLesson>>,
  TError,
  { lessonId: string },
  TContext
> => {
  const mutationOptions =
    getCoursesSpacesControllerDeleteCourseLessonMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Upload files for the authenticated user.
 * @summary Upload files
 */
export const coursesSpacesControllerUploadFiles = (
  lessonId: string,
  uploadFilesDto: UploadFilesDto,
  options?: SecondParameter<typeof customFetch>,
) => {
  const formData = new FormData();
  uploadFilesDto.files.forEach((value) => formData.append('files', value));

  return customFetch<SuccessMessageResponseDto>(
    {
      url: `/courses-spaces/courses/lessons/${encodeURIComponent(String(lessonId))}/files/upload`,
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    },
    options,
  );
};

export const getCoursesSpacesControllerUploadFilesMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof coursesSpacesControllerUploadFiles>>,
    TError,
    { lessonId: string; data: UploadFilesDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof coursesSpacesControllerUploadFiles>>,
  TError,
  { lessonId: string; data: UploadFilesDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof coursesSpacesControllerUploadFiles>>,
    { lessonId: string; data: UploadFilesDto }
  > = (props) => {
    const { lessonId, data } = props ?? {};

    return coursesSpacesControllerUploadFiles(lessonId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CoursesSpacesControllerUploadFilesMutationResult = NonNullable<
  Awaited<ReturnType<typeof coursesSpacesControllerUploadFiles>>
>;
export type CoursesSpacesControllerUploadFilesMutationBody = UploadFilesDto;
export type CoursesSpacesControllerUploadFilesMutationError = void;

/**
 * @summary Upload files
 */
export const useCoursesSpacesControllerUploadFiles = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof coursesSpacesControllerUploadFiles>>,
    TError,
    { lessonId: string; data: UploadFilesDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof coursesSpacesControllerUploadFiles>>,
  TError,
  { lessonId: string; data: UploadFilesDto },
  TContext
> => {
  const mutationOptions =
    getCoursesSpacesControllerUploadFilesMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Remove files for the authenticated user.
 * @summary Remove files
 */
export const coursesSpacesControllerRemoveFiles = (
  lessonId: string,
  removeFilesDto: RemoveFilesDto,
  options?: SecondParameter<typeof customFetch>,
) => {
  return customFetch<SuccessMessageResponseDto>(
    {
      url: `/courses-spaces/courses/lessons/${encodeURIComponent(String(lessonId))}/files/remove`,
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      data: removeFilesDto,
    },
    options,
  );
};

export const getCoursesSpacesControllerRemoveFilesMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof coursesSpacesControllerRemoveFiles>>,
    TError,
    { lessonId: string; data: RemoveFilesDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof coursesSpacesControllerRemoveFiles>>,
  TError,
  { lessonId: string; data: RemoveFilesDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof coursesSpacesControllerRemoveFiles>>,
    { lessonId: string; data: RemoveFilesDto }
  > = (props) => {
    const { lessonId, data } = props ?? {};

    return coursesSpacesControllerRemoveFiles(lessonId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CoursesSpacesControllerRemoveFilesMutationResult = NonNullable<
  Awaited<ReturnType<typeof coursesSpacesControllerRemoveFiles>>
>;
export type CoursesSpacesControllerRemoveFilesMutationBody = RemoveFilesDto;
export type CoursesSpacesControllerRemoveFilesMutationError = void;

/**
 * @summary Remove files
 */
export const useCoursesSpacesControllerRemoveFiles = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof coursesSpacesControllerRemoveFiles>>,
    TError,
    { lessonId: string; data: RemoveFilesDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof coursesSpacesControllerRemoveFiles>>,
  TError,
  { lessonId: string; data: RemoveFilesDto },
  TContext
> => {
  const mutationOptions =
    getCoursesSpacesControllerRemoveFilesMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Marks a lesson as completed for the authenticated user.
 * @summary Mark a lesson as completed
 */
export const coursesSpacesControllerMarkLessonAsCompleted = (
  lessonId: string,
  options?: SecondParameter<typeof customFetch>,
) => {
  return customFetch<CoursesSpacesControllerMarkLessonAsCompleted200>(
    {
      url: `/courses-spaces/courses/lessons/${encodeURIComponent(String(lessonId))}/progress`,
      method: 'POST',
    },
    options,
  );
};

export const getCoursesSpacesControllerMarkLessonAsCompletedMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof coursesSpacesControllerMarkLessonAsCompleted>>,
    TError,
    { lessonId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof coursesSpacesControllerMarkLessonAsCompleted>>,
  TError,
  { lessonId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof coursesSpacesControllerMarkLessonAsCompleted>>,
    { lessonId: string }
  > = (props) => {
    const { lessonId } = props ?? {};

    return coursesSpacesControllerMarkLessonAsCompleted(
      lessonId,
      requestOptions,
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type CoursesSpacesControllerMarkLessonAsCompletedMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof coursesSpacesControllerMarkLessonAsCompleted>>
  >;

export type CoursesSpacesControllerMarkLessonAsCompletedMutationError = void;

/**
 * @summary Mark a lesson as completed
 */
export const useCoursesSpacesControllerMarkLessonAsCompleted = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof coursesSpacesControllerMarkLessonAsCompleted>>,
    TError,
    { lessonId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof coursesSpacesControllerMarkLessonAsCompleted>>,
  TError,
  { lessonId: string },
  TContext
> => {
  const mutationOptions =
    getCoursesSpacesControllerMarkLessonAsCompletedMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Retrieves the progress status of a specific lesson for the authenticated user.
 * @summary Get lesson progress
 */
export const coursesSpacesControllerGetLessonProgress = (
  lessonId: string,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<CoursesSpacesControllerGetLessonProgress200>(
    {
      url: `/courses-spaces/courses/lessons/${encodeURIComponent(String(lessonId))}/progress`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getCoursesSpacesControllerGetLessonProgressQueryKey = (
  lessonId: string,
) => {
  return [`/courses-spaces/courses/lessons/${lessonId}/progress`] as const;
};

export const getCoursesSpacesControllerGetLessonProgressQueryOptions = <
  TData = Awaited<ReturnType<typeof coursesSpacesControllerGetLessonProgress>>,
  TError = void,
>(
  lessonId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof coursesSpacesControllerGetLessonProgress>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getCoursesSpacesControllerGetLessonProgressQueryKey(lessonId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof coursesSpacesControllerGetLessonProgress>>
  > = ({ signal }) =>
    coursesSpacesControllerGetLessonProgress(lessonId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!lessonId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof coursesSpacesControllerGetLessonProgress>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CoursesSpacesControllerGetLessonProgressQueryResult = NonNullable<
  Awaited<ReturnType<typeof coursesSpacesControllerGetLessonProgress>>
>;
export type CoursesSpacesControllerGetLessonProgressQueryError = void;

export function useCoursesSpacesControllerGetLessonProgress<
  TData = Awaited<ReturnType<typeof coursesSpacesControllerGetLessonProgress>>,
  TError = void,
>(
  lessonId: string,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof coursesSpacesControllerGetLessonProgress>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof coursesSpacesControllerGetLessonProgress>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useCoursesSpacesControllerGetLessonProgress<
  TData = Awaited<ReturnType<typeof coursesSpacesControllerGetLessonProgress>>,
  TError = void,
>(
  lessonId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof coursesSpacesControllerGetLessonProgress>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof coursesSpacesControllerGetLessonProgress>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useCoursesSpacesControllerGetLessonProgress<
  TData = Awaited<ReturnType<typeof coursesSpacesControllerGetLessonProgress>>,
  TError = void,
>(
  lessonId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof coursesSpacesControllerGetLessonProgress>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get lesson progress
 */

export function useCoursesSpacesControllerGetLessonProgress<
  TData = Awaited<ReturnType<typeof coursesSpacesControllerGetLessonProgress>>,
  TError = void,
>(
  lessonId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof coursesSpacesControllerGetLessonProgress>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getCoursesSpacesControllerGetLessonProgressQueryOptions(
    lessonId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get lesson progress
 */
export const prefetchCoursesSpacesControllerGetLessonProgress = async <
  TData = Awaited<ReturnType<typeof coursesSpacesControllerGetLessonProgress>>,
  TError = void,
>(
  queryClient: QueryClient,
  lessonId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof coursesSpacesControllerGetLessonProgress>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getCoursesSpacesControllerGetLessonProgressQueryOptions(
    lessonId,
    options,
  );

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getCoursesSpacesControllerGetLessonProgressSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof coursesSpacesControllerGetLessonProgress>>,
  TError = void,
>(
  lessonId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof coursesSpacesControllerGetLessonProgress>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getCoursesSpacesControllerGetLessonProgressQueryKey(lessonId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof coursesSpacesControllerGetLessonProgress>>
  > = ({ signal }) =>
    coursesSpacesControllerGetLessonProgress(lessonId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!lessonId,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof coursesSpacesControllerGetLessonProgress>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CoursesSpacesControllerGetLessonProgressSuspenseQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof coursesSpacesControllerGetLessonProgress>>
  >;
export type CoursesSpacesControllerGetLessonProgressSuspenseQueryError = void;

export function useCoursesSpacesControllerGetLessonProgressSuspense<
  TData = Awaited<ReturnType<typeof coursesSpacesControllerGetLessonProgress>>,
  TError = void,
>(
  lessonId: string,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof coursesSpacesControllerGetLessonProgress>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useCoursesSpacesControllerGetLessonProgressSuspense<
  TData = Awaited<ReturnType<typeof coursesSpacesControllerGetLessonProgress>>,
  TError = void,
>(
  lessonId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof coursesSpacesControllerGetLessonProgress>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useCoursesSpacesControllerGetLessonProgressSuspense<
  TData = Awaited<ReturnType<typeof coursesSpacesControllerGetLessonProgress>>,
  TError = void,
>(
  lessonId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof coursesSpacesControllerGetLessonProgress>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get lesson progress
 */

export function useCoursesSpacesControllerGetLessonProgressSuspense<
  TData = Awaited<ReturnType<typeof coursesSpacesControllerGetLessonProgress>>,
  TError = void,
>(
  lessonId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof coursesSpacesControllerGetLessonProgress>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getCoursesSpacesControllerGetLessonProgressSuspenseQueryOptions(
      lessonId,
      options,
    );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Retrieves the overall progress of a course for the authenticated user.
 * @summary Get course progress
 */
export const coursesSpacesControllerGetCourseProgress = (
  courseId: string,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<CoursesSpacesControllerGetCourseProgress200>(
    {
      url: `/courses-spaces/course/${encodeURIComponent(String(courseId))}/progress`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getCoursesSpacesControllerGetCourseProgressQueryKey = (
  courseId: string,
) => {
  return [`/courses-spaces/course/${courseId}/progress`] as const;
};

export const getCoursesSpacesControllerGetCourseProgressQueryOptions = <
  TData = Awaited<ReturnType<typeof coursesSpacesControllerGetCourseProgress>>,
  TError = void,
>(
  courseId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof coursesSpacesControllerGetCourseProgress>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getCoursesSpacesControllerGetCourseProgressQueryKey(courseId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof coursesSpacesControllerGetCourseProgress>>
  > = ({ signal }) =>
    coursesSpacesControllerGetCourseProgress(courseId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!courseId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof coursesSpacesControllerGetCourseProgress>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CoursesSpacesControllerGetCourseProgressQueryResult = NonNullable<
  Awaited<ReturnType<typeof coursesSpacesControllerGetCourseProgress>>
>;
export type CoursesSpacesControllerGetCourseProgressQueryError = void;

export function useCoursesSpacesControllerGetCourseProgress<
  TData = Awaited<ReturnType<typeof coursesSpacesControllerGetCourseProgress>>,
  TError = void,
>(
  courseId: string,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof coursesSpacesControllerGetCourseProgress>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof coursesSpacesControllerGetCourseProgress>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useCoursesSpacesControllerGetCourseProgress<
  TData = Awaited<ReturnType<typeof coursesSpacesControllerGetCourseProgress>>,
  TError = void,
>(
  courseId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof coursesSpacesControllerGetCourseProgress>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof coursesSpacesControllerGetCourseProgress>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useCoursesSpacesControllerGetCourseProgress<
  TData = Awaited<ReturnType<typeof coursesSpacesControllerGetCourseProgress>>,
  TError = void,
>(
  courseId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof coursesSpacesControllerGetCourseProgress>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get course progress
 */

export function useCoursesSpacesControllerGetCourseProgress<
  TData = Awaited<ReturnType<typeof coursesSpacesControllerGetCourseProgress>>,
  TError = void,
>(
  courseId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof coursesSpacesControllerGetCourseProgress>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getCoursesSpacesControllerGetCourseProgressQueryOptions(
    courseId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get course progress
 */
export const prefetchCoursesSpacesControllerGetCourseProgress = async <
  TData = Awaited<ReturnType<typeof coursesSpacesControllerGetCourseProgress>>,
  TError = void,
>(
  queryClient: QueryClient,
  courseId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof coursesSpacesControllerGetCourseProgress>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getCoursesSpacesControllerGetCourseProgressQueryOptions(
    courseId,
    options,
  );

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getCoursesSpacesControllerGetCourseProgressSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof coursesSpacesControllerGetCourseProgress>>,
  TError = void,
>(
  courseId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof coursesSpacesControllerGetCourseProgress>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getCoursesSpacesControllerGetCourseProgressQueryKey(courseId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof coursesSpacesControllerGetCourseProgress>>
  > = ({ signal }) =>
    coursesSpacesControllerGetCourseProgress(courseId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!courseId,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof coursesSpacesControllerGetCourseProgress>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CoursesSpacesControllerGetCourseProgressSuspenseQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof coursesSpacesControllerGetCourseProgress>>
  >;
export type CoursesSpacesControllerGetCourseProgressSuspenseQueryError = void;

export function useCoursesSpacesControllerGetCourseProgressSuspense<
  TData = Awaited<ReturnType<typeof coursesSpacesControllerGetCourseProgress>>,
  TError = void,
>(
  courseId: string,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof coursesSpacesControllerGetCourseProgress>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useCoursesSpacesControllerGetCourseProgressSuspense<
  TData = Awaited<ReturnType<typeof coursesSpacesControllerGetCourseProgress>>,
  TError = void,
>(
  courseId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof coursesSpacesControllerGetCourseProgress>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useCoursesSpacesControllerGetCourseProgressSuspense<
  TData = Awaited<ReturnType<typeof coursesSpacesControllerGetCourseProgress>>,
  TError = void,
>(
  courseId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof coursesSpacesControllerGetCourseProgress>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get course progress
 */

export function useCoursesSpacesControllerGetCourseProgressSuspense<
  TData = Awaited<ReturnType<typeof coursesSpacesControllerGetCourseProgress>>,
  TError = void,
>(
  courseId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof coursesSpacesControllerGetCourseProgress>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getCoursesSpacesControllerGetCourseProgressSuspenseQueryOptions(
      courseId,
      options,
    );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Saves the organized courses for the authenticated user.
 * @summary Save organized courses
 */
export const coursesSpacesControllerSaveOrganizedCourses = (
  saveOrganizedCoursesDto: SaveOrganizedCoursesDto,
  options?: SecondParameter<typeof customFetch>,
) => {
  return customFetch<SuccessMessageResponseDto>(
    {
      url: `/courses-spaces/save-organized-courses`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: saveOrganizedCoursesDto,
    },
    options,
  );
};

export const getCoursesSpacesControllerSaveOrganizedCoursesMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof coursesSpacesControllerSaveOrganizedCourses>>,
    TError,
    { data: SaveOrganizedCoursesDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof coursesSpacesControllerSaveOrganizedCourses>>,
  TError,
  { data: SaveOrganizedCoursesDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof coursesSpacesControllerSaveOrganizedCourses>>,
    { data: SaveOrganizedCoursesDto }
  > = (props) => {
    const { data } = props ?? {};

    return coursesSpacesControllerSaveOrganizedCourses(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CoursesSpacesControllerSaveOrganizedCoursesMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof coursesSpacesControllerSaveOrganizedCourses>>
  >;
export type CoursesSpacesControllerSaveOrganizedCoursesMutationBody =
  SaveOrganizedCoursesDto;
export type CoursesSpacesControllerSaveOrganizedCoursesMutationError = void;

/**
 * @summary Save organized courses
 */
export const useCoursesSpacesControllerSaveOrganizedCourses = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof coursesSpacesControllerSaveOrganizedCourses>>,
    TError,
    { data: SaveOrganizedCoursesDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof coursesSpacesControllerSaveOrganizedCourses>>,
  TError,
  { data: SaveOrganizedCoursesDto },
  TContext
> => {
  const mutationOptions =
    getCoursesSpacesControllerSaveOrganizedCoursesMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Organizes the lessons for a specific course.
 * @summary Organize lessons
 */
export const coursesSpacesControllerOrganizeLessons = (
  organizeLessonsDto: OrganizeLessonsDto,
  options?: SecondParameter<typeof customFetch>,
) => {
  return customFetch<SuccessMessageResponseDto>(
    {
      url: `/courses-spaces/organize-lessons`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: organizeLessonsDto,
    },
    options,
  );
};

export const getCoursesSpacesControllerOrganizeLessonsMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof coursesSpacesControllerOrganizeLessons>>,
    TError,
    { data: OrganizeLessonsDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof coursesSpacesControllerOrganizeLessons>>,
  TError,
  { data: OrganizeLessonsDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof coursesSpacesControllerOrganizeLessons>>,
    { data: OrganizeLessonsDto }
  > = (props) => {
    const { data } = props ?? {};

    return coursesSpacesControllerOrganizeLessons(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CoursesSpacesControllerOrganizeLessonsMutationResult = NonNullable<
  Awaited<ReturnType<typeof coursesSpacesControllerOrganizeLessons>>
>;
export type CoursesSpacesControllerOrganizeLessonsMutationBody =
  OrganizeLessonsDto;
export type CoursesSpacesControllerOrganizeLessonsMutationError = void;

/**
 * @summary Organize lessons
 */
export const useCoursesSpacesControllerOrganizeLessons = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof coursesSpacesControllerOrganizeLessons>>,
    TError,
    { data: OrganizeLessonsDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof coursesSpacesControllerOrganizeLessons>>,
  TError,
  { data: OrganizeLessonsDto },
  TContext
> => {
  const mutationOptions =
    getCoursesSpacesControllerOrganizeLessonsMutationOptions(options);

  return useMutation(mutationOptions);
};
