import { useUpdateConfigHeader } from '@/components/config-header-provider';
import OverviewPage from '@/components/ui/analytics-components/OverviewPage';

// import AnalyticsSidebar from '@/components/ui/analytics-components/AnalyticsSidebar';

// const tasksMock = [
//   {
//     title: 'Connect stripe',
//     completed: true,
//     link: '#',
//   },
//   {
//     title: 'Customise/Branding',
//     completed: true,
//     link: '#',
//   },
//   {
//     title: 'Build your bundle',
//     completed: false,
//     link: '#',
//   },
//   {
//     title: 'Build Landing page',
//     completed: false,
//     link: '#',
//   },
//   {
//     title: 'Share link and onboard your first member',
//     completed: false,
//     link: '#',
//   },
// ];

// const linksMock = [
//   {
//     title: 'Landing page',
//     link: 'https://spencers-landing.teachly.com',
//   },
//   {
//     title: 'Invite/ Onboarding link',
//     link: 'https://spencers-invite.teachly.com',
//   },
//   {
//     title: 'Checkout url',
//     link: 'https://spencers-invite.teachly.com',
//   },
// ];

export const AnalyticsPage = () => {
  useUpdateConfigHeader({
    showNotifications: true,
    label: 'Overview',
    action: null,
  });
  return (
    <div className="flex max-w-full gap-6 overflow-auto px-4 py-10 md:px-10">
      <div className="flex h-fit w-full flex-col gap-6 xl:w-2/3">
        <OverviewPage />
      </div>
      {/* <AnalyticsSidebar tasks={tasksMock} links={linksMock} /> */}
    </div>
  );
};
