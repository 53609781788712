import { useQueryClient } from '@tanstack/react-query';
import { EllipsisVerticalIcon } from 'lucide-react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  ChatsSpace,
  getBrandsControllerGetSpaceGroupsOfBrandQueryKey,
  useChatsSpacesControllerDeleteChatsSpace,
} from '@/api';
import {
  ChatsSpaceSettingsDialogContent,
  ChatsSpaceSettingsDialogTrigger,
} from '@/components/chats-space-settings-dialog';
import {
  DeleteSpaceDialogContent,
  DeleteSpaceDialogTrgger,
} from '@/components/delete-space-dialog';
import { Dialog } from '@/components/ui/dialog';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';

const DELETE_SPACE_DIALOG = {
  id: 'delete-space',
  Trigger: DeleteSpaceDialogTrgger,
  Content: DeleteSpaceDialogContent,
} as const;

const CHATS_SPACE_SETTINGS_DIALOG = {
  id: 'chats-space-settings',
  Trigger: ChatsSpaceSettingsDialogTrigger,
  Content: ChatsSpaceSettingsDialogContent,
} as const;

const DIALOGS = [CHATS_SPACE_SETTINGS_DIALOG, DELETE_SPACE_DIALOG];

export type ChatsSpaceDropdownProps = {
  brandId: string;
  chatsSpace: ChatsSpace;
};

export const ChatsSpaceDropdown = ({
  brandId,
  chatsSpace,
}: ChatsSpaceDropdownProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedDialogId, setSelectedDialogId] = useState<string | null>(null);

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const { chatsSpaceId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const deleteChatsSpaceMutation = useChatsSpacesControllerDeleteChatsSpace({
    mutation: {
      onSuccess: () => {
        if (chatsSpaceId === chatsSpace.id) {
          navigate(`/brands/${brandId}/space-groups`);
        }
        queryClient.invalidateQueries({
          queryKey: getBrandsControllerGetSpaceGroupsOfBrandQueryKey(brandId),
        });
        closeDialog();
      },
    },
  });

  const handleDeleteSpace = async () => {
    deleteChatsSpaceMutation.mutate({ chatSpaceId: chatsSpace.id });
  };

  return (
    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <DropdownMenu>
        <DropdownMenuTrigger className="absolute right-1 top-2/4 z-10 -translate-y-1/2 transform p-3">
          <EllipsisVerticalIcon className="h-4 w-4" />
        </DropdownMenuTrigger>
        <DropdownMenuContent side="bottom" align="start" className="min-w-40">
          {DIALOGS.map(({ id, Trigger }) => (
            <DropdownMenuItem
              key={id}
              className="p-0"
              onSelect={() => {
                setSelectedDialogId(id);
              }}
            >
              <Trigger />
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
      {selectedDialogId === DELETE_SPACE_DIALOG.id && (
        <DELETE_SPACE_DIALOG.Content
          key={isDialogOpen.toString()}
          isLoading={deleteChatsSpaceMutation.isPending}
          closeDialog={closeDialog}
          deleteSpace={handleDeleteSpace}
        />
      )}
      {selectedDialogId === CHATS_SPACE_SETTINGS_DIALOG.id && (
        <CHATS_SPACE_SETTINGS_DIALOG.Content
          key={isDialogOpen.toString()}
          closeDialog={closeDialog}
          chatsSpace={chatsSpace}
          brandId={brandId}
        />
      )}
    </Dialog>
  );
};
