import { CometChat } from '@cometchat/chat-sdk-javascript';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Amplify } from 'aws-amplify';
import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import { ThemeProvider } from '@/components/theme-provider';
import { env } from '@/env';
import App from '@/routes/App';
import '@/styles/index.css';
import '@/styles/sentry-bug-report.css';
import awsconfig from '@/utils/aws-exports';

import { Toaster } from './components/ui/toaster';

// a comment
Sentry.init({
  dsn: 'https://eb9ea61e17fa3e42ab55f81352bf355a@o4508173083017216.ingest.us.sentry.io/4508173089832960',
  environment: env.NODE_ENV,
  enabled: env.NODE_ENV !== 'local',
  integrations: [
    Sentry.replayIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.browserProfilingIntegration(),
    Sentry.feedbackIntegration({
      // Additional SDK configuration goes in here, for example:
      colorScheme: 'system',
      useSentryUser: {
        name: 'fullName',
        email: 'email',
      },
    }),
  ],
  // Session Replay
  // replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  replaysSessionSampleRate: env.NODE_ENV === 'production' ? 0.1 : 1.0,
  replaysOnErrorSampleRate: 1.0,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: env.NODE_ENV === 'production' ? 0.2 : 1.0,
  profilesSampleRate: env.NODE_ENV === 'production' ? 0.1 : 1.0,
  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', 'awsapprunner'],
});

const ReactQueryDevtoolsProduction = React.lazy(() =>
  import('@tanstack/react-query-devtools/build/modern/production.js').then(
    (d) => ({
      default: d.ReactQueryDevtools,
    }),
  ),
);

Amplify.configure(awsconfig);

const appID = env.VITE_COMETCHAT_APP_ID;
const region = env.VITE_COMETCHAT_REGION;

const appSetting = new CometChat.AppSettingsBuilder()
  .subscribePresenceForAllUsers()
  .setRegion(region)
  .build();

CometChat.init(appID, appSetting).then(
  () => {
    ReactDOM.createRoot(document.getElementById('root')!).render(
      <React.StrictMode>
        <ProfiledProvidersSetup />
      </React.StrictMode>,
    );
  },
  (error) => {
    console.error('CometChat initialization failed with error:', error);
  },
);

const queryClient = new QueryClient();

function ProvidersSetup() {
  const [showDevtools, setShowDevtools] = React.useState(false);

  React.useEffect(() => {
    // @ts-expect-error custom property
    window.toggleDevtools = () => setShowDevtools((old) => !old);
  }, []);

  return (
    <ThemeProvider>
      <QueryClientProvider client={queryClient}>
        <Toaster />
        <App />
        <div id="cometchat-calls-container" />
        {showDevtools && (
          <React.Suspense fallback={null}>
            <ReactQueryDevtoolsProduction />
          </React.Suspense>
        )}
      </QueryClientProvider>
    </ThemeProvider>
  );
}

const ProfiledProvidersSetup = Sentry.withProfiler(ProvidersSetup);
