import { useState } from 'react';

import { userControllerUpdatePassword } from '@/api';

import InputField from '../InputField';
import { Button } from '../button';
import { Dialog, DialogContent, DialogTitle, DialogTrigger } from '../dialog';

const PasswordChangeModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState<{
    oldPassword?: string;
    newPassword?: string;
  }>({});

  const closeDialog = () => {
    setIsOpen(false);
  };

  const handleUpdate = async () => {
    setErrors({
      oldPassword: '',
      newPassword: '',
    });
    if (!oldPassword) {
      setErrors({ oldPassword: 'Please enter your current password' });
    } else if (oldPassword.length < 8) {
      setErrors({ oldPassword: 'Please enter a valid password' });
    } else if (!newPassword) {
      setErrors({ newPassword: 'Please enter your new password' });
    } else if (newPassword.length < 8) {
      setErrors({ newPassword: 'Please enter a valid password' });
    } else {
      userControllerUpdatePassword({
        oldPassword,
        newPassword,
      })
        .then(() => {
          closeDialog();
        })
        .catch((err) => {
          if (err.message.includes('password is incorrect')) {
            setErrors({ oldPassword: 'The password is incorrect' });
          }
        });
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild className="w-fit">
        <Button>Update password</Button>
      </DialogTrigger>
      <DialogContent className="gap-10 px-4 py-6 sm:px-10 sm:py-10">
        <DialogTitle className="text-xl">Update Password</DialogTitle>
        <div className="flex flex-col gap-6">
          <InputField
            title="Current Password"
            placeholder="Enter your current password"
            type="password"
            onChange={(e) => setOldPassword(e.target.value)}
            value={oldPassword}
            error={errors.oldPassword}
          />
          <InputField
            title="New Password"
            placeholder="Enter your new password"
            type="password"
            onChange={(e) => setNewPassword(e.target.value)}
            value={newPassword}
            error={errors.newPassword}
            helperText="Must contain at least 8 characters"
          />
          <InputField
            title="Confirm Password"
            placeholder="Enter your new password again"
            type="password"
            onChange={(e) => setConfirmPassword(e.target.value)}
            value={confirmPassword}
            error={
              newPassword !== confirmPassword && confirmPassword
                ? 'Passwords do not match'
                : ''
            }
          />
          <div className="flex justify-end gap-2.5">
            <Button onClick={closeDialog} variant={'outline'}>
              Cancel
            </Button>
            <Button onClick={handleUpdate}>Update</Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PasswordChangeModal;
