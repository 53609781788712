import { format } from 'date-fns';
import { Link, useParams } from 'react-router-dom';

import {
  DiscussionDetails,
  SpaceGroupMembersDto,
  SpaceGroupWithSpaces,
  useBrandsControllerGetSpaceGroupsOfBrand,
  useDiscussionControllerGetUserJoinedDiscussionsInSpaceGroup,
  useMonetizationControllerCanBookMeeting,
  usePostsSpacesControllerGetLikedPostsInSpaceGroupOfMember,
  usePostsSpacesControllerGetPostsInSpaceGroupOfMember,
  useSpaceGroupControllerGetSpaceGroupMember,
} from '@/api';
import { CalendarIcon } from '@/assets/icon/calendar';
import { ClipIcon } from '@/assets/icon/clip';
import { CloseIcon } from '@/assets/icon/close';
import { SendMessageIcon } from '@/assets/icon/send_message';
import { UserIcon } from '@/assets/icon/user';
import { Loading } from '@/components/Loading';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Badge } from '@/components/ui/badge';
import { buttonVariants } from '@/components/ui/button';
import PostCard from '@/components/ui/post-components/PostCard';
import { Sheet, SheetContent } from '@/components/ui/sheet';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useAuth } from '@/hooks/useContext';
import { cn, onlyFirstLetterCapital, valueOrUndefined } from '@/lib/utils';

type Props = {
  isOpen: boolean;
  userId: string;
  spaceGroupId: string;
  onClose: () => void;
};

export const UserInfo = ({ isOpen, userId, spaceGroupId, onClose }: Props) => {
  return (
    <Sheet open={isOpen} onOpenChange={onClose}>
      <SheetContent className="flex h-full w-full flex-col overflow-y-hidden border-0 p-0 text-base text-black focus:outline-none dark:bg-dark-1 dark:text-white sm:min-w-[585px]">
        <UserInfoInner
          userId={userId}
          spaceGroupId={spaceGroupId}
          onClose={onClose}
        />
      </SheetContent>
    </Sheet>
  );
};

const UserInfoInner = ({
  userId,
  spaceGroupId,
  onClose,
}: {
  userId: string;
  spaceGroupId: string;
  onClose: () => void;
}) => {
  const { brandId } = useParams();
  const { user: currentUser } = useAuth();

  const userQuery = useSpaceGroupControllerGetSpaceGroupMember(
    spaceGroupId,
    userId,
  );

  const allSpaceGroupsQuery = useBrandsControllerGetSpaceGroupsOfBrand(
    brandId ?? '',
  );

  const joinedDiscussionsQuery =
    useDiscussionControllerGetUserJoinedDiscussionsInSpaceGroup(spaceGroupId);

  const canBookMeetingQuery = useMonetizationControllerCanBookMeeting(userId);

  if (
    joinedDiscussionsQuery.isPending ||
    allSpaceGroupsQuery.isPending ||
    userQuery.isPending
  ) {
    return <Loading />;
  }

  const allSpaceGroups = allSpaceGroupsQuery.data ?? [];
  const user = userQuery.data;
  const spaceGroup = allSpaceGroups.find(
    (spaceGroup) => spaceGroup.id === user?.spaceGroupId,
  );

  const joinedDiscussions = joinedDiscussionsQuery.data ?? [];

  if (!spaceGroup) {
    return <div>Space group not found</div>;
  }

  if (!user) {
    return <div>User not found</div>;
  }

  const isCurrentUser = user.userId === currentUser?.id;
  const showSendMessageButton = !isCurrentUser;

  const showBookMeetingButton =
    !isCurrentUser && canBookMeetingQuery.data?.canBook;

  console.log(showBookMeetingButton);

  return (
    <>
      <div className="relative flex min-h-32 w-full justify-end bg-gray-600">
        <CloseIcon
          className="absolute right-10 top-2.5 h-6 w-6 cursor-pointer fill-black dark:fill-white"
          onClick={onClose}
        />
        <div className="absolute left-10 top-[78px] h-25 w-25 !rounded-xl bg-gray-400">
          <Avatar className="h-25 w-25 !rounded-xl">
            <AvatarImage
              className="!rounded-xl border border-light dark:border-gray-400"
              src={valueOrUndefined(user.user.avatarUrl)}
            />
            <AvatarFallback className="text-3xl">
              {user.user.name?.slice(0, 2) ?? (
                <UserIcon className="stroke-textParagraph dark:stroke-white" />
              )}
            </AvatarFallback>
          </Avatar>
        </div>
      </div>
      <article className="flex flex-col gap-3 px-10 pt-16">
        {user.role && user.role !== 'FAN' && (
          <section className="">
            <Badge
              variant="outline"
              className="h-fit bg-[#65A96E] text-xs font-medium text-white"
            >
              {onlyFirstLetterCapital(user.role)}
            </Badge>
          </section>
        )}
        <section>
          <h2 className="text-xl font-semibold">{user.user.name}</h2>
          <p>{user.user.email}</p>
        </section>
        <section className="flex gap-4">
          {showSendMessageButton && (
            <Link
              to={
                brandId
                  ? `/brands/${brandId}/chats/${user.user.id}`
                  : `/chats/${user.user.id}`
              }
              onClick={() => {
                onClose();
              }}
            >
              <div
                className={cn(
                  buttonVariants({ variant: 'secondary' }),
                  'gap-2',
                )}
              >
                <SendMessageIcon className="h-5 w-5 stroke-black dark:stroke-white" />
                Send Message
              </div>
            </Link>
          )}
          {showBookMeetingButton && (
            <Link
              to={`/book-meeting/${user.user.id}`}
              onClick={() => {
                onClose();
              }}
            >
              <div
                className={cn(
                  buttonVariants({ variant: 'secondary' }),
                  'gap-2',
                )}
              >
                <CalendarIcon className="h-5 w-5 fill-black dark:fill-white" />
                Book Call
              </div>
            </Link>
          )}
        </section>
        <section className="flex w-full flex-col gap-4">
          <p className="flex gap-8 font-normal text-textParagraph dark:text-dark-textParagraph">
            {user?.joinedAt && (
              <span className="flex w-auto gap-3">
                <CalendarIcon className="h-6 w-6 fill-textParagraph dark:fill-dark-textParagraph" />
                Joined {format(user?.joinedAt, 'MMMM yyyy')}
              </span>
            )}
            {user.user.websiteUrl && (
              <span className="flex w-auto gap-3">
                <ClipIcon className="h-6 w-6 fill-textParagraph dark:fill-dark-textParagraph" />{' '}
                <a
                  href={
                    user.user.websiteUrl.startsWith('http')
                      ? user.user.websiteUrl
                      : `http://${user.user.websiteUrl}`
                  }
                  target="_blank"
                >
                  {user.user.websiteUrl}
                </a>
              </span>
            )}
          </p>
        </section>
      </article>
      <article className="h-full overflow-y-hidden px-10 pb-10">
        <Tabs
          defaultValue="posts"
          className="flex h-full w-full flex-col overflow-auto"
        >
          <TabsList className="flex justify-start rounded-none border-b border-light !bg-transparent p-0 dark:border-dark-light">
            <TabsTrigger
              value="posts"
              className="rounded-none data-[state=active]:-mb-[1px] data-[state=active]:border-b-2 data-[state=active]:border-brand data-[state=active]:bg-transparent data-[state=active]:!text-brand"
            >
              Posts
            </TabsTrigger>
            <TabsTrigger
              value="likes"
              className="rounded-none data-[state=active]:-mb-[1px] data-[state=active]:border-b-2 data-[state=active]:border-brand data-[state=active]:bg-transparent data-[state=active]:!text-brand"
            >
              Likes
            </TabsTrigger>
          </TabsList>
          <TabsContent value="posts" className="h-full overflow-auto">
            <PostsOfMember
              member={user}
              joinedDiscussions={joinedDiscussions}
              spaceGroup={spaceGroup}
            />
          </TabsContent>
          <TabsContent value="likes" className="h-full overflow-auto">
            <LikesOfMember
              member={user}
              spaceGroup={spaceGroup}
              joinedDiscussions={joinedDiscussions}
            />
          </TabsContent>
        </Tabs>
      </article>
    </>
  );
};

const PostsOfMember = ({
  member,
  joinedDiscussions,
  spaceGroup,
}: {
  member: SpaceGroupMembersDto;
  joinedDiscussions: DiscussionDetails[];
  spaceGroup: SpaceGroupWithSpaces;
}) => {
  const postsOfMemberQuery =
    usePostsSpacesControllerGetPostsInSpaceGroupOfMember(
      member.spaceGroupId,
      member.userId,
    );

  if (postsOfMemberQuery.isLoading) {
    return (
      <div className="flex h-full w-full justify-center">
        <Loading size={4} className="h-full" />
      </div>
    );
  }

  if (postsOfMemberQuery.data?.length === 0) {
    return <div className="flex w-full">No posts found</div>;
  }

  return (
    <div className="mt-4 flex flex-col gap-4 overflow-auto">
      {postsOfMemberQuery.data?.map((post) => (
        <PostCard
          key={post.id}
          post={post}
          spaceGroup={spaceGroup}
          isDiscussionJoined={joinedDiscussions.some(
            (discussion) => discussion.id === post.discussion?.id,
          )}
        />
      ))}
    </div>
  );
};

const LikesOfMember = ({
  member,
  spaceGroup,
  joinedDiscussions,
}: {
  member: SpaceGroupMembersDto;
  spaceGroup: SpaceGroupWithSpaces;
  joinedDiscussions: DiscussionDetails[];
}) => {
  const likedPostsOfMemberQuery =
    usePostsSpacesControllerGetLikedPostsInSpaceGroupOfMember(
      member.spaceGroupId,
      member.userId,
    );

  if (likedPostsOfMemberQuery.isLoading) {
    return (
      <div className="flex h-full w-full justify-center">
        <Loading size={4} className="h-full" />
      </div>
    );
  }

  if (likedPostsOfMemberQuery.data?.length === 0) {
    return <div className="flex w-full">No likes found</div>;
  }

  return (
    <div className="mt-4 flex flex-col gap-4 overflow-y-auto">
      {likedPostsOfMemberQuery.data?.map((post) => (
        <PostCard
          key={post.id}
          post={post}
          spaceGroup={spaceGroup}
          isDiscussionJoined={joinedDiscussions.some(
            (discussion) => discussion.id === post.discussion?.id,
          )}
        />
      ))}
    </div>
  );
};
