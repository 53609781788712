import { useState } from 'react';
import { Link } from 'react-router-dom';

import { arrowLeftIcon } from '@/assets/icon';
import { KeyIcon } from '@/assets/icon/key';
import { MailPencilIcon } from '@/assets/icon/mail-pencil';
import InputField from '@/components/ui/InputField';
import { Button } from '@/components/ui/button';

interface ContinueSignUpPageProps {
  email: string;
  setEmail: (email: string) => void;
  password: string;
  setPassword: (password: string) => void;
  emailError: string;
  passwordError: string;
  handleBackStep: () => void;
  handleContinue: () => void;
  isLoading: boolean;
  requestError: string;
  isCreatorSignup: boolean;
  callbackUrl?: string | null;
}
const ContinueSignUpPage = ({
  email,
  setEmail,
  password,
  setPassword,
  emailError,
  passwordError,
  handleBackStep,
  handleContinue,
  isLoading,
  requestError,
  isCreatorSignup,
  callbackUrl,
}: ContinueSignUpPageProps) => {
  const newSearchParams = new URLSearchParams();
  if (isCreatorSignup) {
    newSearchParams.set('creatorSignup', '');
  }
  if (callbackUrl) {
    newSearchParams.set('callbackUrl', callbackUrl);
  }

  const loginUrl = '/login' + '?' + newSearchParams.toString();
  const [recheckEmailError, setRecheckEmailError] = useState<string>('');
  const [recheckEmail, setRecheckEmail] = useState<string>('');

  const checkEmail = (): boolean => {
    if (email !== recheckEmail) {
      setRecheckEmailError('Emails do not match');
      return false;
    } else {
      setRecheckEmailError('');
      return true;
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (checkEmail()) {
          handleContinue();
        }
      }}
    >
      <div className="flex w-full flex-col items-center gap-10 rounded-2xl border border-light bg-white px-4 py-6 shadow dark:border-dark-light dark:bg-dark-white sm:w-125 sm:p-10">
        <h1 className="w-full text-2xl font-semibold text-black dark:text-white">
          Continue signup
        </h1>
        <div className="flex w-full flex-col gap-6">
          <InputField
            title="Email"
            placeholder="Enter your email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            error={emailError}
            type="email"
            startIcon={
              <MailPencilIcon className="h-6 w-6 stroke-black dark:stroke-white" />
            }
          />
          <InputField
            title="Re enter email"
            placeholder="Enter your email"
            onChange={(e) => setRecheckEmail(e.target.value)}
            value={recheckEmail}
            error={recheckEmailError}
            type="email"
            startIcon={
              <MailPencilIcon className="h-6 w-6 stroke-black dark:stroke-white" />
            }
            disablePaste
          />
          <InputField
            autoFocus={true}
            title="Password"
            placeholder="Enter your password"
            disabled={false}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            startIcon={
              <KeyIcon className="h-6 w-6 stroke-black opacity-40 dark:stroke-white" />
            }
            error={passwordError}
            helperText={'Must contain at least 8 characters'}
          />
          {requestError && (
            <p className="w-full rounded-md bg-destructive p-4 text-center text-white">
              {requestError}
            </p>
          )}
        </div>
        <div className="flex w-full flex-col gap-6">
          <div className="flex w-full flex-col gap-3">
            <Button
              type="button"
              variant="none"
              className="flex w-full justify-center gap-2 border-none p-5 text-lg font-normal"
              onClick={handleBackStep}
              disabled={isLoading}
            >
              <img src={arrowLeftIcon} className="h-6 w-6" alt="arrow left" />
              <span className="text-[#3B82F6]">Back to previous step</span>
            </Button>
            <Button
              type="submit"
              disabled={isLoading}
              variant="default"
              className="w-full p-8 text-lg font-normal"
            >
              Continue
            </Button>
          </div>
          <p className="text-center text-textParagraph">
            Or if you have an account{' '}
            <Link to={loginUrl} state={{ email }} className="text-brand">
              Sign in
            </Link>
          </p>
        </div>
      </div>
    </form>
  );
};

export default ContinueSignUpPage;
