import { Dialog, DialogTrigger } from '@radix-ui/react-dialog';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

import {
  MembersSpacesControllerGetMembersSuscriptions200Item,
  getMembersSpacesControllerGetMembersSuscriptionsQueryKey,
  useMembersSpacesControllerGetMembersSuscriptions,
  useStripeControllerCancelSubscription,
} from '@/api';
import { ArrowLeftIcon } from '@/assets/icon/arrowLeft';
import { Loading } from '@/components/Loading';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { Button } from '@/components/ui/button';
import SubscriptionCard from '@/components/ui/settings-components/fan/SubscriptionCard';

import { DialogContent, DialogFooter, DialogHeader } from '../../dialog';

const SubscriptionsTab = () => {
  const subscriptionsQuery = useMembersSpacesControllerGetMembersSuscriptions();

  const [selectedSubscription, setSelectedSubscription] = useState<
    MembersSpacesControllerGetMembersSuscriptions200Item | undefined
  >();
  const [openConfirmation, setOpenConfirmation] = useState(true);

  const queryClient = useQueryClient();
  const cancelSubscriptionMutation = useStripeControllerCancelSubscription({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: getMembersSpacesControllerGetMembersSuscriptionsQueryKey(),
        });
        setSelectedSubscription(undefined);
        setOpenConfirmation(false);
      },
    },
  });

  if (subscriptionsQuery.isLoading) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <Loading />
      </div>
    );
  }

  const handleCancelSubscription = (
    subscription: MembersSpacesControllerGetMembersSuscriptions200Item,
  ) => {
    const spaceGroupId = subscription.spaceGroup?.id;
    if (!spaceGroupId) {
      return;
    }
    cancelSubscriptionMutation.mutate({
      data: {
        spaceGroupId,
      },
    });
  };

  const activeSubscriptions =
    subscriptionsQuery.data?.filter(
      (sub) => sub.subscription?.status === 'active',
    ) || [];
  const inactiveSubscriptions =
    subscriptionsQuery.data?.filter(
      (sub) => sub.subscription?.status !== 'active',
    ) || [];

  if (selectedSubscription) {
    return (
      <div className="flex w-full flex-col gap-10 pt-10">
        <div
          onClick={() => setSelectedSubscription(undefined)}
          className="flex w-fit cursor-pointer items-center gap-2.5"
        >
          <ArrowLeftIcon className="h-4 w-4 fill-black dark:fill-white" />
          Back
        </div>
        <div className="flex w-full flex-col gap-4">
          <h2 className="text-lg font-medium text-black dark:text-white">
            Plan details
          </h2>

          <SubscriptionCard subscription={selectedSubscription} onlyView />
        </div>
        <Dialog open={openConfirmation} onOpenChange={setOpenConfirmation}>
          <DialogTrigger>
            <Button
              variant={'ghost'}
              className="h-fit w-full !py-4 text-error"
              onClick={() => setOpenConfirmation(true)}
              disabled={cancelSubscriptionMutation.isPending}
            >
              Cancel subscription
            </Button>
          </DialogTrigger>
          <DialogContent className="w-auto">
            <DialogHeader>Cancel subscription</DialogHeader>
            <p className="text-sm font-medium text-textParagraph dark:text-dark-textParagraph">
              Are you sure you want to cancel your subscription?
            </p>
            <DialogFooter>
              <div className="flex w-full justify-center gap-6">
                <Button
                  variant={'default'}
                  className="h-fit w-full"
                  onClick={() => setOpenConfirmation(false)}
                  disabled={cancelSubscriptionMutation.isPending}
                >
                  Go back
                </Button>
                <Button
                  variant={'default'}
                  className="h-fit w-full bg-error hover:bg-error/90"
                  onClick={() => handleCancelSubscription(selectedSubscription)}
                  disabled={cancelSubscriptionMutation.isPending}
                >
                  Proceed to cancel
                </Button>
              </div>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>
    );
  }

  return (
    <div className="flex w-full flex-col gap-10 pt-10">
      <div className="flex w-full flex-col gap-2.5">
        <h2 className="text-lg font-semibold text-black dark:text-white">
          Subscription
        </h2>
        <p className="text-sm font-medium text-textParagraph dark:text-dark-textParagraph">
          Manage your subscriptions and billing information
        </p>
      </div>
      <Accordion type="single" collapsible defaultValue="activeSubscriptions">
        <AccordionItem value="activeSubscriptions">
          <AccordionTrigger className="text-lg font-medium text-black dark:text-white">
            Active
          </AccordionTrigger>
          <AccordionContent className="flex w-full flex-col gap-4">
            {activeSubscriptions.length === 0 && (
              <p className="text-sm font-medium text-textParagraph dark:text-dark-textParagraph">
                No active subscriptions
              </p>
            )}
            {activeSubscriptions.map((sub) => (
              <SubscriptionCard
                key={sub.subscription?.id}
                subscription={sub}
                onClick={() => setSelectedSubscription(sub)}
              />
            ))}
          </AccordionContent>
        </AccordionItem>
      </Accordion>
      <Accordion type="single" collapsible defaultValue="activeSubscriptions">
        <AccordionItem value="activeSubscriptions">
          <AccordionTrigger className="text-lg font-medium text-black dark:text-white">
            Expired
          </AccordionTrigger>
          <AccordionContent className="flex w-full flex-col gap-4">
            {inactiveSubscriptions.length === 0 && (
              <p className="text-sm font-medium text-textParagraph dark:text-dark-textParagraph">
                No expired subscriptions
              </p>
            )}
            {inactiveSubscriptions.map((sub) => (
              <SubscriptionCard
                key={sub.subscription?.id}
                subscription={sub}
                onClick={() => setSelectedSubscription(sub)}
              />
            ))}
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default SubscriptionsTab;
