import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import { FileIcon, XIcon } from 'lucide-react';
import { useCallback, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import {
  PostSpace,
  getPostsSpacesControllerGetPostsInSpaceQueryKey,
  useDiscussionControllerCreateDiscussion,
  usePostsSpacesControllerCreatePost,
  usePostsSpacesControllerUploadFiles,
  useSpaceGroupControllerGetUsersBySpaceGroupIdForMentions,
} from '@/api';
import { ClipIcon } from '@/assets/icon/clip';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Switch } from '@/components/ui/switch';
import { useBrandPermits } from '@/hooks/useBrandPermits';
import { cn } from '@/lib/utils';

import Editor from './Editor';

export const AddPostDialog = ({
  postsSpace,
  triggerContent,
}: {
  postsSpace: PostSpace;
  triggerContent?: string | JSX.Element;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const closeDialog = () => {
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        {triggerContent || <Button>Add post</Button>}
      </DialogTrigger>
      <AddPostDialogContent closeDialog={closeDialog} postsSpace={postsSpace} />
    </Dialog>
  );
};

const isUserAllowedToGenerateDiscussions = (
  hasPermits: boolean,
  postsSpace: PostSpace,
) => {
  if (hasPermits && postsSpace.generateDiscussionFromPosts) {
    return true;
  }

  if (postsSpace.allowMembersToOpenDiscussions) {
    return true;
  }

  return false;
};

const formSchema = z.object({
  title: z.string(),
  content: z.string(),
  files: z.instanceof(File).array(),
  generateDiscussion: z.boolean().default(false),
});

type FormValues = z.infer<typeof formSchema>;

export const AddPostDialogContent = ({
  closeDialog,
  postsSpace,
}: {
  closeDialog: () => void;
  postsSpace: PostSpace;
}) => {
  const hasPermits = useBrandPermits();

  const canGenerateDiscussion = isUserAllowedToGenerateDiscussions(
    hasPermits,
    postsSpace,
  );

  const fileInputRef = useRef<HTMLInputElement>(null);

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      title: '',
      content: '',
      generateDiscussion: false,
      files: [],
    },
  });

  const queryClient = useQueryClient();

  const usersQuery = useSpaceGroupControllerGetUsersBySpaceGroupIdForMentions(
    postsSpace.spaceGroupId,
  );
  const users = usersQuery.data ?? [];

  const createPostMutation = usePostsSpacesControllerCreatePost();
  const createDiscussionMutation = useDiscussionControllerCreateDiscussion();
  const uploadFilesMutation = usePostsSpacesControllerUploadFiles();

  const handleSubmit = form.handleSubmit(async (data) => {
    const title = data.title === '' ? undefined : data.title.trim();
    const content = data.content.trim();

    if (!title && !content && data.files.length === 0) return;

    const post = await createPostMutation.mutateAsync({
      postsSpaceId: postsSpace.id,
      data: {
        title,
        content,
      },
    });

    // Create an array of promises for parallel execution
    const parallelOperations = [];

    if (data.files.length > 0) {
      parallelOperations.push(
        uploadFilesMutation.mutateAsync({
          postId: post.id,
          data: {
            files: data.files,
          },
        }),
      );
    }

    if (data.generateDiscussion) {
      parallelOperations.push(
        createDiscussionMutation.mutateAsync({
          data: {
            postId: post.id,
          },
        }),
      );
    }

    // Execute all operations in parallel
    await Promise.all(parallelOperations);

    queryClient.invalidateQueries({
      queryKey: getPostsSpacesControllerGetPostsInSpaceQueryKey(postsSpace.id),
    });
    form.reset();
    closeDialog();
  });

  const handleCloseDialog = () => {
    form.reset();
    closeDialog();
  };

  const files = form.watch('files');

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles = e.target.files;
    if (newFiles) {
      const filesArray = Array.from(newFiles);
      // append files to form.setValue
      form.setValue('files', [...files, ...filesArray]);
    }
  };

  const handleFileInputClick = () => {
    fileInputRef.current?.click();
  };

  const handleRemoveFile = (index: number) => {
    form.setValue(
      'files',
      files.slice(0, index).concat(files.slice(index + 1)),
    );
  };

  const renderFiles = () => {
    if (files.length === 0) {
      return null;
    }

    return (
      <div className="mt-4 flex flex-wrap gap-4">
        {files.map((file, index) => {
          if (file.type.startsWith('image/')) {
            const imageUrl = URL.createObjectURL(file);
            return (
              <div key={file.name} className="relative w-fit">
                <img src={imageUrl} alt={file.name} className="h-52" />
                <Button
                  variant={'icon'}
                  size="icon"
                  className="absolute -bottom-2 -right-2 z-20 rounded-full bg-red-500 p-1 text-white dark:bg-red-500"
                  onClick={() => handleRemoveFile(index)}
                >
                  <XIcon className="h-4 w-4" />
                  <span className="sr-only">Remove</span>
                </Button>
              </div>
            );
          }

          if (file.type.startsWith('video/')) {
            const videoUrl = URL.createObjectURL(file);
            return (
              <div key={file.name} className="relative w-fit">
                <video src={videoUrl} className="h-52" controls>
                  Your browser does not support the video tag.
                </video>
                <Button
                  variant={'icon'}
                  size="icon"
                  className="absolute -bottom-2 -right-2 z-20 rounded-full bg-red-500 p-1 text-white dark:bg-red-500"
                  onClick={() => handleRemoveFile(index)}
                >
                  <XIcon className="h-4 w-4" />
                  <span className="sr-only">Remove</span>
                </Button>
              </div>
            );
          }

          return (
            <div
              key={file.name}
              className="min-w-96 rounded-xl bg-bgGrey p-4 dark:bg-dark-bgGrey"
            >
              <div className="grid grid-cols-[auto_1fr_auto] gap-3">
                <div className="flex items-center gap-2">
                  <FileIcon className="h-4 w-4" />
                </div>
                <div className="flex flex-col">
                  <span className="text-sm text-black dark:text-white">
                    {file.name}
                  </span>
                  <span className="text-sm text-gray-500 dark:text-gray-400">
                    {
                      // write file size in KB
                      (file.size / 1024).toFixed(1)
                    }{' '}
                    KB
                  </span>
                </div>
                <Button
                  variant={'icon'}
                  size="icon"
                  onClick={() => handleRemoveFile(index)}
                >
                  <XIcon className="h-4 w-4" />
                  <span className="sr-only">Remove</span>
                </Button>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const handleMentionSearch = useCallback(
    (search: string) => {
      if (!search) return [];
      return users
        .filter((user) =>
          user.name?.toLowerCase().includes(search.toLowerCase()),
        )
        .map((user) => ({
          id: user.id ?? '',
          value: user.name ?? '',
          cometChatId: user.cometChatUid ?? '',
          name: user.name ?? '',
          avatarUrl: user.avatarUrl ?? '',
        }));
    },
    [users],
  );

  const handleTextChange = ({
    markdown,
  }: {
    markdown: string;
    html: string;
  }) => {
    form.setValue('content', markdown);
  };

  return (
    <DialogContent className="w-[90%] max-w-3xl overflow-y-hidden md:w-3/4 lg:w-1/2">
      <DialogHeader className="flex-row items-center justify-between space-y-0">
        <DialogTitle className="text-2xl font-semibold">
          Start discussion
        </DialogTitle>
        <Button
          variant={'none'}
          className="m-0 rounded-sm p-0 ring-offset-background transition-opacity focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
          onClick={handleCloseDialog}
        >
          <XIcon className="h-4 w-4" />
          <span className="sr-only">Close</span>
        </Button>
      </DialogHeader>
      <Form {...form}>
        <form onSubmit={handleSubmit} className="z-10">
          <div>
            <FormField
              control={form.control}
              name="title"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormControl>
                    <Input
                      className="border-none text-2xl font-semibold outline-none ring-0 placeholder:text-[#9E9E9E] focus-visible:ring-0 focus-visible:ring-offset-0"
                      placeholder="Title (optional)"
                      {...field}
                    />
                  </FormControl>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="content"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormControl>
                    <div className="max-h-100 overflow-y-auto">
                      <Editor
                        ref={field.ref}
                        onTextChange={handleTextChange}
                        onSendMessage={handleSubmit}
                        onMentionSearch={handleMentionSearch}
                        className={cn(
                          'mt-4 resize-none border-none px-3 text-base font-normal outline-none ring-0 placeholder:text-[#9E9E9E] focus-visible:ring-0 focus-visible:ring-offset-0',
                          files.length === 0 && 'h-96 min-h-96',
                        )}
                      />
                      {renderFiles()}
                    </div>
                  </FormControl>
                </FormItem>
              )}
            />
          </div>
          <FormField
            control={form.control}
            name="generateDiscussion"
            render={({ field }) => (
              <FormItem
                className={cn(
                  'flex flex-row items-center justify-between',
                  !canGenerateDiscussion && 'hidden',
                )}
              >
                <FormLabel className="text-base font-medium text-black dark:text-white">
                  Generate discussion
                </FormLabel>
                <FormControl>
                  <Switch
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <div className="mt-4 flex items-center gap-5">
            <input
              type="file"
              className="hidden"
              ref={fileInputRef}
              onChange={handleFileInputChange}
            />
            {/* <Button
              type="button"
              variant="icon"
              size="icon"
              onClick={() => {
              }}
            >
              <ImageIcon className="w-5 fill-current text-gray-800 opacity-50 dark:text-white" />
            </Button> */}
            <Button
              type="button"
              variant="icon"
              size="icon"
              onClick={handleFileInputClick}
              disabled={
                createPostMutation.isPending ||
                createDiscussionMutation.isPending ||
                uploadFilesMutation.isPending
              }
            >
              <ClipIcon className="w-5 fill-current text-gray-800 opacity-50 dark:text-white" />
            </Button>
          </div>

          <div className="mt-4">
            <Button
              type="submit"
              className="rounded-sm px-8"
              disabled={
                createPostMutation.isPending ||
                createDiscussionMutation.isPending ||
                uploadFilesMutation.isPending
              }
            >
              Publish
            </Button>
          </div>
        </form>
      </Form>
    </DialogContent>
  );
};
