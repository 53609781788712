import React, { ReactNode } from 'react';

import { useBrandPermits } from '@/hooks/useBrandPermits';

interface SpaceCoverProps {
  image: string | null;
  ChangeCoverButton: ReactNode;
  RemoveButton: ReactNode;
}

export const SpaceCover: React.FC<SpaceCoverProps> = ({
  image,
  ChangeCoverButton,
  RemoveButton,
}) => {
  const hasPermits = useBrandPermits();
  if (!image) return null;

  return (
    <div className="group relative hidden w-full items-center justify-center md:flex">
      <img
        src={image}
        className="aspect-[4/1] max-h-80 rounded-3xl object-cover group-hover:brightness-50"
        alt="Space cover"
      />
      {hasPermits && (
        <div className="absolute left-1/2 top-1/2 hidden -translate-x-1/2 -translate-y-1/2 transform gap-5 group-hover:flex">
          {ChangeCoverButton}
          {RemoveButton}
        </div>
      )}
    </div>
  );
};
