import { useRef, useState } from 'react';

import { trashIcon } from '@/assets/icon';
import { UserIcon } from '@/assets/icon/user';
import { UserPen } from '@/assets/icon/user-pen';
import ImageCropModal from '@/components/ui/ImageCropModal';
import InputField from '@/components/ui/InputField';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';

interface SetupProfilePageProps {
  handleBackStep: () => void;
  handleSaveContinue: () => void;
  name: string;
  setname: (name: string) => void;
  phone: string;
  setPhone: (phone: string) => void;
  phoneDial: Dial;
  setPhoneDial: (phoneDial: Dial) => void;
  photo: string | null;
  setPhoto: (photo: string | null) => void;
  usernameError: string;
  phoneError: string;
  photoError: string;
  isLoading: boolean;
}

type Dial = {
  name: string;
  idd: string;
  flag: string;
};

const SetupProfilePage = ({
  handleSaveContinue,
  name,
  setname,
  phone,
  setPhone,
  photo,
  setPhoto,
  usernameError,
  phoneError,
  photoError,
  isLoading,
  phoneDial,
  setPhoneDial,
}: SetupProfilePageProps) => {
  const photoRef = useRef<HTMLInputElement>(null);

  const [open, setOpen] = useState(false);
  const [photoTemp, setPhotoTemp] = useState<string | null>(null);

  const handleClickInputFile = () => {
    photoRef.current?.click();
  };

  const handleSelectFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setOpen(true);
        setPhotoTemp(e.target?.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemovePhoto = () => {
    setPhoto(null);
  };

  const handleCroppedImage = (image: string) => {
    setPhoto(image);
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <form
      onSubmit={(e) => e.preventDefault()}
      onKeyDown={(e) => e.key === 'Enter' && handleSaveContinue()}
      className="flex w-full flex-col items-center gap-10 rounded-2xl border border-light bg-white px-4 py-6 shadow dark:border-dark-light dark:bg-dark-white sm:w-125 sm:p-10"
    >
      <h1 className="w-full text-2xl font-semibold text-black dark:text-white">
        Setup profile
      </h1>
      <div className="flex w-full flex-col gap-6">
        <div className="flex w-full gap-4">
          <div className="relative flex h-30 w-30 p-2">
            {photo ? (
              <img
                src={photo}
                className="h-26 w-26 rounded-lg"
                alt="profile picture"
              />
            ) : (
              <div
                className={cn(
                  'flex h-full w-full items-center justify-center rounded-lg bg-gray-200 dark:bg-dark-bgGrey',
                  photoError && 'border-2 border-error',
                )}
              >
                <UserIcon className="h-12 w-12 stroke-white" />
              </div>
            )}
            {photo && (
              <button
                className="absolute bottom-0 right-0 rounded-md border border-textParagraph bg-bgGrey p-2 dark:border-dark-textParagraph dark:bg-dark-bgGrey"
                onClick={handleRemovePhoto}
              >
                <img src={trashIcon} alt="trash" />
              </button>
            )}
            {photoTemp && (
              <ImageCropModal
                image={photoTemp}
                open={open}
                onClose={handleClose}
                onSave={handleCroppedImage}
                ratio={1 / 1}
              />
            )}
          </div>
          <div className="flex flex-col justify-center">
            <h2
              className="cursor-pointer text-base font-bold text-brand"
              onClick={handleClickInputFile}
            >
              {photo ? 'Update photo' : 'Select a photo'}
            </h2>
            <p className="text-xs font-normal text-textParagraph">
              Make sure the file is below 2 Mb
            </p>
            {photoError && <p className="text-destructive">{photoError}</p>}
            <input
              type="file"
              accept="image/*"
              onChange={handleSelectFile}
              hidden
              ref={photoRef}
            />
          </div>
        </div>
        <InputField
          title="Name"
          placeholder="Enter your name"
          onChange={(e) => setname(e.target.value)}
          value={name}
          type="text"
          startIcon={
            <UserPen className="h-6 w-6 stroke-black dark:stroke-white" />
          }
          error={usernameError}
        />
        <InputField
          title="Phone Number"
          placeholder="Enter your phone number"
          onChange={(e) => setPhone(e.target.value)}
          onChangeDial={setPhoneDial}
          dial={phoneDial}
          value={phone}
          type="phone"
          error={phoneError}
        />
      </div>
      <div className="flex w-full flex-col gap-6">
        <div className="flex w-full flex-col gap-3">
          <Button
            variant="default"
            className="w-full p-8 text-lg font-normal"
            disabled={isLoading}
            onClick={handleSaveContinue}
          >
            Save & Continue
          </Button>
        </div>
      </div>
    </form>
  );
};

export default SetupProfilePage;
