import { useQueryClient } from '@tanstack/react-query';
import { compareAsc, isAfter, isBefore, isEqual } from 'date-fns';
import { useEffect, useState } from 'react';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';

import {
  EventSpace,
  EventWithRsvps,
  SpaceGroupWithSpaces,
  getBrandsControllerGetSpaceGroupsOfBrandQueryKey,
  useBrandsControllerGetSpaceGroupsOfBrand,
  useEventSpacesControllerGetEvents,
  useEventSpacesControllerUpdateEventSpace,
  useNotificationsControllerResetEventCounter,
} from '@/api';
import { Loading } from '@/components/Loading';
import { SpaceCover } from '@/components/SpaceCover';
import { AddEventDialog } from '@/components/add-event-dialog';
import { useUpdateConfigHeader } from '@/components/config-header-provider';
import { EventCard } from '@/components/event-info-dialog';
import { ChangeCoverEventsSpaceDialog } from '@/components/events-space-settings-dialog';
import { Button } from '@/components/ui/button';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useBrandPermits } from '@/hooks/useBrandPermits';

// Filter and sort events
const getLiveEvents = (events: EventWithRsvps[], now: Date) =>
  events
    .filter(
      (event) =>
        (isBefore(event.startDayTime, now) ||
          isEqual(event.startDayTime, now)) &&
        isAfter(event.endDayTime, now),
    )
    .sort((a, b) =>
      compareAsc(new Date(a.startDayTime), new Date(b.startDayTime)),
    );

const getUpcomingEvents = (events: EventWithRsvps[], now: Date) =>
  events
    .filter((event) => isAfter(event.startDayTime, now))
    .sort((a, b) =>
      compareAsc(new Date(a.startDayTime), new Date(b.startDayTime)),
    );

const getPreviousEvents = (events: EventWithRsvps[], now: Date) =>
  events
    .filter((event) => isBefore(event.endDayTime, now))
    .sort((a, b) =>
      compareAsc(new Date(a.startDayTime), new Date(b.startDayTime)),
    );

export default function EventsPage() {
  const { brandId, spaceGroupId, eventsSpaceId } = useParams();

  const spaceGroupsQuery = useBrandsControllerGetSpaceGroupsOfBrand(
    brandId as string,
  );

  if (spaceGroupsQuery.isLoading) {
    return <Loading />;
  }

  if (spaceGroupsQuery.isError) {
    throw new Error(
      `Error fetching space groups for brand ${brandId}, ${spaceGroupsQuery.error}`,
    );
  }

  const spaceGroups = spaceGroupsQuery.data ?? [];

  const spaceGroup = spaceGroups.find(
    (spaceGroup) => spaceGroup.id === spaceGroupId,
  );

  if (!spaceGroup) {
    return <Navigate to="/404" />;
  }

  const eventSpace = spaceGroup.eventSpaces.find(
    (space) => space.id === eventsSpaceId,
  );

  if (!eventSpace) {
    return <Navigate to="/404" />;
  }

  return <EventsInner eventSpace={eventSpace} spaceGroup={spaceGroup} />;
}

const getAction = (hasPermits: boolean, eventSpace: EventSpace) => {
  if (hasPermits) {
    return <AddEventDialog eventSpaceId={eventSpace.id} />;
  }

  return null;
};

const EventsInner = ({
  eventSpace,
  spaceGroup,
}: {
  eventSpace: EventSpace;
  spaceGroup: SpaceGroupWithSpaces;
}) => {
  const hasPermits = useBrandPermits();
  const config = {
    showNotifications: true,
    label: eventSpace.name,
    action: getAction(hasPermits, eventSpace),
  };

  useUpdateConfigHeader(config, [
    eventSpace.name,
    eventSpace.id,
    hasPermits,
    eventSpace,
  ]);

  const [searchParams] = useSearchParams();

  const selectedEventId = searchParams.get('eventId');

  const [selectedTab, setSelectedTab] = useState<'upcoming' | 'previous'>(
    'upcoming',
  );

  const eventsQuery = useEventSpacesControllerGetEvents(eventSpace.id);
  const resetEventCounterMutation =
    useNotificationsControllerResetEventCounter();

  useEffect(() => {
    resetEventCounterMutation.mutate({ eventSpaceId: eventSpace.id });
  }, [eventSpace.id, eventsQuery.data]);

  const events = eventsQuery.data ?? [];

  const liveEvents = getLiveEvents(events, new Date());
  const upcomingEvents = getUpcomingEvents(events, new Date());
  const previousEvents = getPreviousEvents(events, new Date());

  const liveAndUpcomingEvents = [...liveEvents, ...upcomingEvents];

  const isSelectedEventInPreviousEvents = previousEvents.find(
    (event) => event.id === selectedEventId,
  );

  const isSelectedEventInLiveAndUpcomingEvents = liveAndUpcomingEvents.find(
    (event) => event.id === selectedEventId,
  );

  useEffect(() => {
    if (isSelectedEventInPreviousEvents) {
      setSelectedTab('previous');
    } else if (isSelectedEventInLiveAndUpcomingEvents) {
      setSelectedTab('upcoming');
    }
  }, [
    selectedEventId,
    isSelectedEventInPreviousEvents,
    isSelectedEventInLiveAndUpcomingEvents,
  ]);

  const isEmpty =
    liveEvents.length === 0 &&
    upcomingEvents.length === 0 &&
    previousEvents.length === 0;

  if (eventsQuery.isLoading) {
    return <Loading />;
  }

  return (
    <div className="flex h-full flex-col items-center gap-5 overflow-scroll p-5 md:p-10">
      <SpaceCover
        image={eventSpace.image}
        ChangeCoverButton={
          <ChangeCoverEventsSpaceDialog
            eventsSpace={eventSpace}
            brandId={spaceGroup.brandId}
          />
        }
        RemoveButton={
          <RemoveCoverButton
            eventSpace={eventSpace}
            brandId={spaceGroup.brandId}
          />
        }
      />
      <div className="flex w-full content-between items-center justify-between md:hidden">
        <h5 className="text-lg font-medium">{config.label}</h5>
        {config.action}
      </div>

      {isEmpty && (
        <div className="flex h-full w-full flex-col items-center justify-center gap-4">
          <p className="text-lg font-medium text-gray-600 dark:text-gray-400">
            You currrently have no events
          </p>
          {config.action}
        </div>
      )}

      {!isEmpty && (
        <div className="flex w-full justify-center">
          <Tabs
            className="w-full"
            value={selectedTab}
            onValueChange={(value) =>
              setSelectedTab(value as 'upcoming' | 'previous')
            }
          >
            <div className="sticky -top-5 z-10 my-4 flex w-full justify-center bg-white py-5 dark:bg-dark-1 md:-top-10">
              <TabsList className="mx-auto grid w-full grid-cols-2 sm:w-fit">
                <TabsTrigger value="upcoming">Upcoming</TabsTrigger>
                <TabsTrigger value="previous">Previous</TabsTrigger>
              </TabsList>
            </div>
            <TabsContent value="upcoming" className="w-full">
              <EventList events={liveAndUpcomingEvents} />
            </TabsContent>
            <TabsContent value="previous">
              <EventList events={previousEvents} />
            </TabsContent>
          </Tabs>
        </div>
      )}
    </div>
  );
};

const EventList = ({ events }: { events: EventWithRsvps[] }) => {
  return (
    <div className="mb-30 grid grid-cols-[repeat(auto-fit,minmax(270px,1fr))] gap-4 md:grid-cols-1">
      {events.length === 0 && (
        <div className="flex h-full w-full flex-col items-center justify-center gap-4">
          <p className="text-lg font-medium text-gray-600 dark:text-gray-400">
            No events found
          </p>
        </div>
      )}
      {events.map((event) => (
        <EventCard key={event.id} event={event} />
      ))}
    </div>
  );
};

const RemoveCoverButton = ({
  eventSpace,
  brandId,
}: {
  eventSpace: EventSpace;
  brandId: string;
}) => {
  const queryClient = useQueryClient();
  const updateEventSpaceMutation = useEventSpacesControllerUpdateEventSpace({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: getBrandsControllerGetSpaceGroupsOfBrandQueryKey(brandId),
        });
      },
    },
  });

  const handleRemoveCover = async () => {
    updateEventSpaceMutation.mutate({
      eventSpaceId: eventSpace.id,
      data: {
        image: null,
      },
    });
  };

  return (
    <Button
      variant="secondary"
      className="rounded-sm"
      onClick={handleRemoveCover}
    >
      Remove
    </Button>
  );
};
