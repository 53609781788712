import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { useStripeControllerProcessCheckout } from '@/api';
import { Loading } from '@/components/Loading';

export const ProcessCheckoutPage = () => {
  const { token } = useParams<{ token: string }>();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const processCheckoutMutation = useStripeControllerProcessCheckout({
    mutation: {
      onSuccess: (data) => {
        queryClient.removeQueries({ queryKey: ['process-checkout'] });

        if (data.brandId && data.spaceGroupId) {
          navigate(`/brands/${data.brandId}/space-groups/${data.spaceGroupId}`);
          return;
        }

        navigate('/');
      },
      throwOnError: true,
    },
  });

  useQuery({
    queryKey: ['process-checkout', token],
    queryFn: () =>
      processCheckoutMutation.mutateAsync({ data: { token: token ?? '' } }),
    enabled: !!token,
  });

  if (!token) {
    throw new Error(`No token found`);
  }

  return <Loading />;
};
