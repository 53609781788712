import { CometChat } from '@cometchat/chat-sdk-javascript';
import { useQueries, useQueryClient } from '@tanstack/react-query';
import { NavLink } from 'react-router-dom';

import {
  ChatsSpace,
  CoursesSpace,
  EventSpace,
  MembersSpace,
  NewCourseCounterModel,
  NewEventCounterModel,
  NewPostCounterModel,
  NotificationCounters,
  PostSpace,
  SpaceGroupWithSpaces,
  getCoursesSpacesControllerGetCourseWithLessonsByCourseIdQueryKey,
  getEventSpacesControllerGetEventsQueryKey,
  getPostsSpacesControllerGetPostsInSpaceQueryKey,
} from '@/api';
import { ChatsSpaceDropdown } from '@/components/spaces-dropdowns/chats-space-dropdown';
import { CoursesSpaceDropdown } from '@/components/spaces-dropdowns/courses-space-dropdown';
import { EventsSpaceDropdown } from '@/components/spaces-dropdowns/events-space-dropdown';
import { MembersSpaceDropdown } from '@/components/spaces-dropdowns/members-space-dropdown';
import { PostsSpaceDropdown } from '@/components/spaces-dropdowns/posts-space-dropdown';
import { useBrandPermits } from '@/hooks/useBrandPermits';
import { cn } from '@/lib/utils';

export const Spaces = ({
  spaceGroup,
  notificationCounters,
}: {
  spaceGroup: SpaceGroupWithSpaces;
  notificationCounters?: NotificationCounters;
}) => {
  const brandId = spaceGroup.brandId;
  const spaceGroupId = spaceGroup.id;

  return (
    <div className="flex flex-col justify-start gap-1 overflow-x-hidden">
      {[
        renderPostsSpaces(
          brandId,
          spaceGroupId,
          spaceGroup.postsSpaces.sort(
            (a, b) =>
              new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
          ),
          notificationCounters?.newPostCounter,
        ),
        renderEventsSpaces(
          brandId,
          spaceGroupId,
          spaceGroup.eventSpaces.sort(
            (a, b) =>
              new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
          ),
          notificationCounters?.newEventCounter,
        ),
        renderMembersSpaces(brandId, spaceGroupId, spaceGroup.membersSpaces),
        renderChatsSpaces(
          brandId,
          spaceGroupId,
          spaceGroup.chatsSpaces.sort(
            (a, b) =>
              new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
          ),
        ),
        renderCoursesSpaces(
          brandId,
          spaceGroupId,
          spaceGroup.coursesSpaces.sort(
            (a, b) =>
              new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
          ),
          notificationCounters?.newCourseCounter,
        ),
      ].flat()}
    </div>
  );
};

const SpaceNavLink = ({
  path,
  item,
  counter,
  handleClick,
}: {
  path: string;
  item: PostSpace | EventSpace | MembersSpace | ChatsSpace | CoursesSpace;
  counter?: number;
  handleClick?: () => void;
}) => {
  const hasBrandPermissions = useBrandPermits();

  const getColorComponent = (color: string) => {
    return (
      <div
        className={cn('h-2.5 w-2.5 rounded-xs')}
        style={{ backgroundColor: color }}
      />
    );
  };

  const getEmojiComponent = (emoji: string) => {
    return <span className={cn('text-xs')}>{emoji}</span>;
  };

  const getEmojiOrColor = () => {
    if (item.color) {
      return getColorComponent(item.color);
    }

    if (item.emoji) {
      return getEmojiComponent(item.emoji);
    }

    return null;
  };

  return (
    <NavLink
      key={item.name}
      to={path}
      onClick={(e) => {
        e.stopPropagation();
        if (counter && counter > 0) {
          handleClick?.();
        }
      }}
      className={({ isActive }) =>
        cn(
          'h-10 px-4 py-2',
          'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
          'hover:bg-accent hover:text-accent-foreground',
          'w-full content-center items-center justify-start gap-2 overflow-x-hidden rounded-lg',
          isActive && 'bg-[#F2F7FF] dark:bg-[#2C2E30]',
        )
      }
    >
      {({ isActive }) => {
        return (
          <div className="grid w-full grid-cols-[auto,1fr,auto] place-content-center content-center items-center justify-center gap-2.5 truncate">
            {getEmojiOrColor()}
            <span
              className={cn(
                'w-fit truncate text-sm font-medium text-[#9E9E9E] dark:text-gray-300',
                isActive && 'text-black dark:text-white',
              )}
            >
              {item.name}
            </span>
            {counter && counter > 0 ? (
              <span
                className={cn(
                  'min-w-5.5 rounded-xs bg-light-3 p-1 text-center text-xs font-medium dark:bg-dark-bgGrey',
                  hasBrandPermissions && 'mr-7',
                )}
              >
                {counter}
              </span>
            ) : null}
          </div>
        );
      }}
    </NavLink>
  );
};

const renderPostsSpaces = (
  brandId: string,
  spaceGroupId: string,
  postsSpaces: PostSpace[],
  newPostCounter?: NewPostCounterModel[],
) => {
  const hasPermits = useBrandPermits();
  const queryClient = useQueryClient();

  const getPath = (postsSpaceId: string) =>
    `/brands/${brandId}/space-groups/${spaceGroupId}/posts-spaces/${postsSpaceId}`;

  return postsSpaces.map((postSpace) => {
    const counter = newPostCounter?.find(
      (counter) => counter.postsSpaceId === postSpace.id,
    )?.count;

    return (
      <div key={postSpace.id} className="relative overflow-hidden">
        <SpaceNavLink
          path={getPath(postSpace.id)}
          item={postSpace}
          counter={counter}
          handleClick={() =>
            queryClient.invalidateQueries({
              queryKey: getPostsSpacesControllerGetPostsInSpaceQueryKey(
                postSpace.id,
              ),
            })
          }
        />
        {hasPermits && (
          <PostsSpaceDropdown brandId={brandId} postsSpace={postSpace} />
        )}
      </div>
    );
  });
};

const renderEventsSpaces = (
  brandId: string,
  spaceGroupId: string,
  eventsSpaces: EventSpace[],
  newEventCounter?: NewEventCounterModel[],
) => {
  const hasPermits = useBrandPermits();
  const queryClient = useQueryClient();

  const getPath = (eventSpaceId: string) =>
    `/brands/${brandId}/space-groups/${spaceGroupId}/events-spaces/${eventSpaceId}`;

  return eventsSpaces.map((item) => {
    const counter = newEventCounter?.find(
      (counter) => counter.eventSpaceId === item.id,
    )?.count;

    return (
      <div key={item.id} className="relative overflow-hidden">
        <SpaceNavLink
          path={getPath(item.id)}
          item={item}
          counter={counter}
          handleClick={() =>
            queryClient.invalidateQueries({
              queryKey: getEventSpacesControllerGetEventsQueryKey(item.id),
            })
          }
        />
        {hasPermits && (
          <EventsSpaceDropdown brandId={brandId} eventsSpace={item} />
        )}
      </div>
    );
  });
};

const renderMembersSpaces = (
  brandId: string,
  spaceGroupId: string,
  membersSpaces: MembersSpace[],
) => {
  const hasPermits = useBrandPermits();
  const getPath = (membersSpaceId: string) =>
    `/brands/${brandId}/space-groups/${spaceGroupId}/members-spaces/${membersSpaceId}`;

  return membersSpaces.map((item) => {
    return (
      <div key={item.id} className="relative overflow-hidden">
        <SpaceNavLink path={getPath(item.id)} item={item} />
        {hasPermits && (
          <MembersSpaceDropdown brandId={brandId} membersSpace={item} />
        )}
      </div>
    );
  });
};

const renderChatsSpaces = (
  brandId: string,
  spaceGroupId: string,
  chatsSpaces: ChatsSpace[],
) => {
  const hasPermits = useBrandPermits();

  const getPath = (chatsSpaceId: string) =>
    `/brands/${brandId}/space-groups/${spaceGroupId}/chats-spaces/${chatsSpaceId}`;

  const unreadMessageCountsQuery = useQueries({
    queries: chatsSpaces
      .filter(
        (item): item is ChatsSpace & { cometChatGroupId: string } =>
          !!item.cometChatGroupId,
      )
      .map((item) => {
        return {
          queryKey: [
            'cometchat',
            'group-unread-message-count',
            item.cometChatGroupId,
          ],
          queryFn: async () => {
            const conversation = await CometChat.getConversation(
              item.cometChatGroupId,
              'group',
            );

            const unreadMessageCount = conversation.getUnreadMessageCount();

            return {
              id: item.id,
              unreadMessageCount,
            };
          },
          refetchInterval: 30 * 1000,
          refetchIntervalInBackground: true,
        };
      }),
  });

  const unreadMessageCounts =
    unreadMessageCountsQuery.map((query) => query.data) ?? [];

  return chatsSpaces.map((item) => {
    // const counter = newGroupChatMessageCounter?.find(
    //   (counter) => counter.chatsSpaceId === item.id,
    // )?.count;

    const unreadMessageCount = unreadMessageCounts.find(
      (count) => count?.id === item.id,
    )?.unreadMessageCount;

    return (
      <div key={item.id} className="relative overflow-hidden">
        <SpaceNavLink
          path={getPath(item.id)}
          item={item}
          counter={unreadMessageCount}
        />
        {hasPermits && (
          <ChatsSpaceDropdown brandId={brandId} chatsSpace={item} />
        )}
      </div>
    );
  });
};

const renderCoursesSpaces = (
  brandId: string,
  spaceGroupId: string,
  coursesSpaces: CoursesSpace[],
  newCourseCounter?: NewCourseCounterModel[],
) => {
  const hasPermits = useBrandPermits();

  const queryClient = useQueryClient();

  const getPath = (coursesSpaceId: string) =>
    `/brands/${brandId}/space-groups/${spaceGroupId}/courses-spaces/${coursesSpaceId}`;

  return coursesSpaces.map((item) => {
    const counter = newCourseCounter?.find(
      (counter) => counter.coursesSpaceId === item.id,
    )?.count;

    return (
      <div key={item.id} className="relative overflow-hidden">
        <SpaceNavLink
          path={getPath(item.id)}
          item={item}
          counter={counter}
          handleClick={() =>
            queryClient.invalidateQueries({
              queryKey:
                getCoursesSpacesControllerGetCourseWithLessonsByCourseIdQueryKey(
                  item.id,
                ),
            })
          }
        />
        {hasPermits && (
          <CoursesSpaceDropdown brandId={brandId} coursesSpace={item} />
        )}
      </div>
    );
  });
};
