import { CometChat } from '@cometchat/chat-sdk-javascript';
import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  SpaceGroupControllerGetUsersBySpaceGroupIdForMentions200Item,
  useSpaceGroupControllerGetUsersBySpaceGroupIdForMentions,
} from '@/api';
import { ArrowLeftAltIcon } from '@/assets/icon/arrowLeftAlt';
import { Loading } from '@/components/Loading';
import ChatInput from '@/components/ui/chats-components/ChatInput';
import ChatMessage from '@/components/ui/chats-components/ChatMessage';
import ThreadDrawer from '@/components/ui/chats-components/ThreadDrawer';
import { getEntries } from '@/lib/utils';

const MESSAGES_LIMIT = 100;

export const CometChatComponent = ({
  GUID,
  spaceGroupId,
  onClose = () => {},
  isOpen = true,
  isDiscussion = false,
}: {
  GUID: string;
  spaceGroupId: string;
  onClose?: () => void;
  isOpen?: boolean;
  isDiscussion?: boolean;
}) => {
  const [messageInput, setMessageInput] = useState<string>('');
  const [fileInput, setFileInput] = useState<File | null>(null);

  const endMessageRef = useRef<HTMLDivElement>(null);

  const messagesContainerRef = useRef<HTMLDivElement>(null);

  async function getMessages(lastMessageId?: number) {
    const messagesRequest: CometChat.MessagesRequest =
      new CometChat.MessagesRequestBuilder()
        .setGUID(GUID)
        .setLimit(MESSAGES_LIMIT)
        .hideReplies(true)
        .setTypes([
          CometChat.MESSAGE_TYPE.TEXT,
          CometChat.MESSAGE_TYPE.IMAGE,
          CometChat.MESSAGE_TYPE.VIDEO,
        ])
        .setMessageId(lastMessageId === -1 ? undefined : lastMessageId)
        .build();

    const messages = await messagesRequest.fetchPrevious();

    return messages;
  }

  const chatsQuery = useInfiniteQuery({
    queryKey: ['cometchat', GUID],
    queryFn: ({ pageParam }) => getMessages(pageParam),
    initialPageParam: -1,
    getNextPageParam: (lastPage) => {
      // If no messages returned, there are no more pages
      if (!lastPage || lastPage.length === 0) return undefined;

      // If we got fewer messages than the limit, we've reached the end
      if (lastPage.length < MESSAGES_LIMIT) return undefined;

      // Otherwise, use the ID of the first (oldest) message as the next page param
      return lastPage.at(0)?.getId();
    },
  });

  // Add a ref to track if this is the initial load
  const isInitialLoadRef = useRef(true);

  // Update scroll handler
  const handleScroll = useCallback(() => {
    const container = messagesContainerRef.current;
    const hasMore = chatsQuery.hasNextPage;

    const isThereAPageLessThanLimit = chatsQuery.data?.pages.some(
      (page) => page.length < MESSAGES_LIMIT,
    );

    if (isThereAPageLessThanLimit) {
      return;
    }

    if (!container || !hasMore || chatsQuery.isFetching) return;

    // Skip the first check after initial load
    if (isInitialLoadRef.current) {
      isInitialLoadRef.current = false;
      return;
    }

    // one message has 500px height approx
    // so 3000px is 6 messages
    const threshold = 3000;
    if (container.scrollTop <= threshold) {
      chatsQuery.fetchNextPage().then(() => {
        // Check if we're at the very top (fast scroll case)
        const isAtTop = container.scrollTop === 0;
        setTimeout(() => {
          requestAnimationFrame(() => {
            if (!container) return;

            const oldScrollHeight = container.scrollHeight;
            // Force a reflow to get the new content height
            container.style.height = container.style.height;
            const newScrollHeight = container.scrollHeight;
            const heightDifference = newScrollHeight - oldScrollHeight;

            if (isAtTop) {
              // For fast scroll, position to show about 3 messages from the previous batch
              container.scrollTop = 1500; // Approximate height for 3 messages
            } else {
              container.scrollTop = container.scrollTop + heightDifference;
            }
          });
        }, 0);
      });
    }
  }, [chatsQuery]);

  // Add scroll event listener
  useEffect(() => {
    const container = messagesContainerRef.current;
    if (!container) return;

    container.addEventListener('scroll', handleScroll);
    return () => container.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  const usersQuery =
    useSpaceGroupControllerGetUsersBySpaceGroupIdForMentions(spaceGroupId);

  const users = usersQuery.data ?? [];

  const scrollToBottom = useCallback(
    (args?: { smooth?: boolean; timeout?: number }) => {
      setTimeout(() => {
        if (!endMessageRef.current) return;
        const smooth = args?.smooth ?? true;
        endMessageRef.current.scrollIntoView({
          behavior: smooth ? 'smooth' : 'instant',
        });
      }, args?.timeout ?? 100);
    },
    [endMessageRef],
  );

  // Initial scroll to bottom
  useEffect(() => {
    if (chatsQuery.isFetched) {
      requestAnimationFrame(() => {
        if (messagesContainerRef.current) {
          messagesContainerRef.current.scrollTop =
            messagesContainerRef.current.scrollHeight;
        }
      });
    }
  }, [chatsQuery.isFetched]);

  const queryClient = useQueryClient();

  const [isOpenThreadDrawer, setIsOpenThreadDrawer] = useState(false);
  const [selectedMessage, setSelectedMessage] =
    useState<CometChat.BaseMessage | null>(null);

  const handleCloseThreadDrawer = () => {
    setSelectedMessage(null);
    setIsOpenThreadDrawer(false);
  };

  const isNearBottom = useCallback(() => {
    const container = messagesContainerRef.current;
    if (!container) return false;

    // Consider "near bottom" if within 100px of the bottom
    const threshold = 100;
    return (
      container.scrollHeight - container.scrollTop - container.clientHeight <
      threshold
    );
  }, []);

  useEffect(() => {
    const addMessage = (newMessage: CometChat.BaseMessage) => {
      queryClient.setQueryData<{
        pages: CometChat.BaseMessage[][];
        pageParams: number[];
      }>(['cometchat', GUID], (oldData) => {
        if (!oldData) return { pages: [[newMessage]], pageParams: [-1] };
        const firstPage = oldData.pages.at(0);
        if (!firstPage) return { pages: [[newMessage]], pageParams: [-1] };
        const newFirstPage = [...firstPage, newMessage];
        return {
          pages: [newFirstPage, ...oldData.pages.slice(1)],
          pageParams: oldData.pageParams,
        };
      });

      // Only scroll if user is near bottom
      if (isNearBottom()) {
        scrollToBottom();
      }
    };

    const listenerID = `CHAT_LISTENER_${GUID}`;
    CometChat.addMessageListener(
      listenerID,
      new CometChat.MessageListener({
        onTextMessageReceived: (textMessage: CometChat.TextMessage) => {
          // check text message belongs to this group
          const receiver = textMessage.getReceiver();
          if (!(receiver instanceof CometChat.Group)) return;
          if (receiver.getGuid() !== GUID) return;
          if (textMessage.getParentMessageId()) return;

          addMessage(textMessage);
        },
        onMediaMessageReceived: (mediaMessage: CometChat.MediaMessage) => {
          const receiver = mediaMessage.getReceiver();
          if (!(receiver instanceof CometChat.Group)) return;
          if (receiver.getGuid() !== GUID) return;
          if (mediaMessage.getParentMessageId()) return;

          if (
            mediaMessage.getType() !== CometChat.MESSAGE_TYPE.IMAGE &&
            mediaMessage.getType() !== CometChat.MESSAGE_TYPE.VIDEO
          )
            return;

          addMessage(mediaMessage);
        },
      }),
    );

    return () => {
      CometChat.removeMessageListener(listenerID);
    };
  }, [GUID, scrollToBottom, queryClient, isNearBottom]);

  const sendMessageMutation = useMutation({
    mutationFn: async () => {
      const receiverID = GUID;
      const messageText = messageInput.trim();
      const receiverType = CometChat.RECEIVER_TYPE.GROUP;

      let messageToSend: CometChat.BaseMessage;

      if (fileInput) {
        const messageType = fileInput.type.startsWith('image/')
          ? CometChat.MESSAGE_TYPE.IMAGE
          : CometChat.MESSAGE_TYPE.VIDEO;

        const mediaMessage = new CometChat.MediaMessage(
          receiverID,
          fileInput,
          messageType,
          receiverType,
        );

        if (messageText.length > 0) {
          mediaMessage.setCaption(messageText);
        }

        messageToSend = mediaMessage;
      } else {
        messageToSend = new CometChat.TextMessage(
          receiverID,
          messageText,
          receiverType,
        );
      }

      const message = await CometChat.sendMessage(messageToSend);

      queryClient.setQueryData<{
        pages: CometChat.BaseMessage[][];
        pageParams: number[];
      }>(['cometchat', GUID], (oldData) => {
        if (!oldData) return { pages: [[message]], pageParams: [-1] };
        // add message to the first page (because the first page has the newest messages)
        const firstPage = oldData.pages.at(0);

        if (!firstPage) return { pages: [[message]], pageParams: [-1] };
        const newFirstPage = [...firstPage, message];
        return {
          pages: [newFirstPage, ...oldData.pages.slice(1)],
          pageParams: oldData.pageParams,
        };
      });
      setMessageInput('');
      setFileInput(null);
      scrollToBottom();
    },
  });

  const handleSendMessage = async () => {
    if (messageInput.trim().length > 0 || !!fileInput) {
      await sendMessageMutation.mutateAsync();
    }
  };

  const handleReact = useCallback(
    async (messageId: string, reaction: string) => {
      const messageRes = await CometChat.addReaction(messageId, reaction);
      queryClient.setQueryData<{
        pages: CometChat.BaseMessage[][];
        pageParams: number[];
      }>(['cometchat', GUID], (oldData) => {
        if (!oldData) return { pages: [[messageRes]], pageParams: [-1] };

        return {
          pages: oldData.pages.map((page) =>
            page.map((message) =>
              message.getId().toString() === messageId ? messageRes : message,
            ),
          ),
          pageParams: oldData.pageParams,
        };
      });
    },
    [GUID, queryClient],
  );

  const handleMentionSearch = useCallback(
    (search: string) => {
      if (!search) return [];
      return users
        .filter((user) =>
          user.name?.toLowerCase().includes(search.toLowerCase()),
        )
        .map((user) => ({
          id: user.id ?? '',
          value: user.name ?? '',
          cometChatId: user.cometChatUid ?? '',
          name: user.name ?? '',
          avatarUrl: user.avatarUrl ?? '',
        }));
    },
    [users],
  );

  const messages = [...(chatsQuery.data?.pages ?? [])].reverse().flat();

  const lastMessage = messages.at(-1);

  useEffect(() => {
    if (!lastMessage) return;

    const markAsRead = async () => {
      await CometChat.markAsRead(lastMessage);
      queryClient.invalidateQueries({
        queryKey: ['cometchat', 'group-unread-message-count', GUID],
      });
    };

    markAsRead();
  }, [lastMessage?.getId()]);

  if (chatsQuery.isLoading) {
    return <Loading />;
  }

  return (
    <div
      className={`relative ${isOpen ? 'grid' : 'hidden lg:grid'} h-full w-full grid-rows-[1fr_auto] flex-col overflow-auto overflow-y-hidden p-6`}
    >
      <div
        ref={messagesContainerRef}
        className="flex flex-col gap-8 overflow-y-auto"
      >
        {isDiscussion && (
          <div
            onClick={onClose}
            className="flex w-fit items-center gap-2.5 lg:hidden"
          >
            <ArrowLeftAltIcon className="h-6 w-6 stroke-black dark:stroke-white" />
            Back to discussions
          </div>
        )}

        <ChatMessages
          messages={messages}
          users={users}
          setSelectedMessage={setSelectedMessage}
          setIsOpenThreadDrawer={setIsOpenThreadDrawer}
          handleReact={handleReact}
          endMessageRef={endMessageRef}
          isFetchingMore={chatsQuery.isFetchingNextPage}
        />
      </div>
      <div className="w-full bg-white dark:bg-dark-1">
        <ChatInput
          message={messageInput}
          setMessage={setMessageInput}
          onSendMessage={handleSendMessage}
          isLoading={sendMessageMutation.isPending}
          fileInput={fileInput}
          setFileInput={setFileInput}
          onMentionSearch={handleMentionSearch}
        />
      </div>
      {selectedMessage && (
        <ThreadDrawer
          GUID={GUID}
          isOpen={isOpenThreadDrawer}
          onClose={handleCloseThreadDrawer}
          message={selectedMessage}
          onMentionSearch={handleMentionSearch}
          users={users}
        />
      )}
    </div>
  );
};

type MessagesByDate = Record<string, CometChat.BaseMessage[]>;

const ChatMessages = memo(
  ({
    messages,
    users,
    setSelectedMessage,
    setIsOpenThreadDrawer,
    handleReact,
    endMessageRef,
    isFetchingMore,
  }: {
    messages: CometChat.BaseMessage[];
    users: SpaceGroupControllerGetUsersBySpaceGroupIdForMentions200Item[];
    setSelectedMessage: (message: CometChat.BaseMessage) => void;
    setIsOpenThreadDrawer: (isOpen: boolean) => void;
    handleReact: (messageId: string, reaction: string) => void;
    endMessageRef: React.RefObject<HTMLDivElement>;
    isFetchingMore: boolean;
  }) => {
    const messagesByDateEntries = useMemo(() => {
      const messagesByDate = messages.reduce<MessagesByDate>((acc, message) => {
        const date = new Date(message.getSentAt() * 1000).toDateString();
        (acc[date] ??= []).push(message);
        return acc;
      }, {});

      // if messagesByDate is empty, return an empty array
      const messagesByDateEntries =
        Object.keys(messagesByDate).length > 0
          ? getEntries(messagesByDate)
          : [];

      return messagesByDateEntries;
    }, [messages]);

    return (
      <div>
        {messagesByDateEntries.length === 0 && (
          <p className="text-center text-textParagraph dark:text-dark-textParagraph">
            No messages yet
          </p>
        )}
        {isFetchingMore && (
          <div className="flex justify-center py-2">
            <Loading size={4} />
          </div>
        )}

        {messagesByDateEntries.length > 0 &&
          messagesByDateEntries.map(([date, chats]) => (
            <div key={date} className="flex flex-col gap-2">
              <div className="flex items-center gap-2.5">
                <p className="text-nowrap text-base font-medium text-black dark:text-white">
                  {date === new Date().toDateString()
                    ? 'Today'
                    : date ===
                        new Date(
                          new Date().setDate(new Date().getDate() - 1),
                        ).toDateString()
                      ? 'Yesterday'
                      : date}
                </p>
                <hr className="w-full border-t border-t-light dark:border-t-dark-light" />
              </div>
              {chats.map((msg) => (
                <ChatMessage
                  key={msg.getId()}
                  message={msg}
                  users={users}
                  onClick={() => {
                    setSelectedMessage(msg);
                    setIsOpenThreadDrawer(true);
                  }}
                  onReact={handleReact}
                />
              ))}
            </div>
          ))}
        <div className="mb-4" ref={endMessageRef} />
      </div>
    );
  },
);
