import { CometChat } from '@cometchat/chat-sdk-javascript';
import { NavLink, useParams } from 'react-router-dom';

import { UserIcon } from '@/assets/icon/user';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import Viewer from '@/components/ui/chats-components/Viewer';

type Props = {
  conversation: CometChat.Conversation;
};

const ChatMessagePreview = ({ conversation }: Props) => {
  const { brandId } = useParams();

  const conversationWith = conversation.getConversationWith();
  const lastMessage = conversation.getLastMessage() ?? null;
  const unreadCount = conversation.getUnreadMessageCount();

  if (!(conversationWith instanceof CometChat.User)) {
    return null;
  }

  let text = '';

  if (lastMessage instanceof CometChat.TextMessage) {
    text = lastMessage.getText();
  } else if (lastMessage instanceof CometChat.MediaMessage) {
    text = lastMessage.getCaption();
  }

  const trimmedText = text?.length > 80 ? `${text.slice(0, 80)}...` : text;

  return (
    <NavLink
      to={
        brandId
          ? `/brands/${brandId}/chats/${conversationWith.getUid()}`
          : `/chats/${conversationWith.getUid()}`
      }
      className={({ isActive }) =>
        `flex h-fit w-full items-center justify-between rounded-xl p-2 transition-all duration-100 hover:bg-primary-50 dark:hover:bg-dark-primary-50 ${
          isActive ? 'bg-primary-50 dark:bg-dark-primary-50' : ''
        }`
      }
    >
      <div className="relative flex w-full items-start gap-2.5 rounded-lg">
        <Avatar className="h-10 w-10 cursor-pointer items-center rounded-lg">
          <AvatarImage src={conversationWith.getAvatar()} />
          <AvatarFallback className="h-10 w-10 rounded-lg">
            <UserIcon className="stroke-textParagraph p-1 dark:stroke-white" />
          </AvatarFallback>
        </Avatar>
        <div className="flex flex-col">
          <p className="cursor-pointer text-base font-medium text-black dark:text-white">
            {conversationWith.getName()}
          </p>
          <Viewer
            value={trimmedText}
            findUser={() => null}
            onMentionClick={() => {}}
          />
          {lastMessage instanceof CometChat.MediaMessage && (
            <span className="text-sm">📷</span>
          )}
        </div>
        {unreadCount > 0 && (
          <span className="ml-auto mr-2 flex h-5 w-5 items-center justify-center rounded-sm bg-red-500 text-xs text-white">
            {unreadCount}
          </span>
        )}
      </div>
    </NavLink>
  );
};

export default ChatMessagePreview;
