import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { useAuth } from '@/hooks/useContext';

import ContinueSignUpPage from './signUp/ContinueSignUpPage';
import SetupProfilePage from './signUp/SetupProfilePage';

const Register = () => {
  const { signUp, setUpProfile } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  // Sign up data
  const [email, setEmail] = useState(location.state?.email || '');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [step, setStep] = useState(location.state?.step || 0);

  const searchParams = new URLSearchParams(location.search);
  const keys = [...searchParams.keys()];
  const isCreatorSignup = keys.includes('creatorSignup');
  const callbackUrl = searchParams.get('callbackUrl');

  useEffect(() => {
    const newSearchParams = new URLSearchParams();
    if (isCreatorSignup) {
      newSearchParams.set('creatorSignup', '');
    }
    if (callbackUrl) {
      newSearchParams.set('callbackUrl', callbackUrl);
    }
    window.history.replaceState(
      null,
      '',
      location.pathname + '?' + newSearchParams.toString(),
    );
  }, [location.pathname]);

  const [phoneDial, setPhoneDial] = useState({
    name: 'United States',
    idd: '+1',
    flag: 'https://flagcdn.com/us.svg',
  });

  // Profile setup data
  const [username, setUsername] = useState('');
  const [phone, setPhone] = useState('');
  const [photo, setPhoto] = useState<string | null>(null);

  const [usernameError, setUsernameError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [photoError, setPhotoError] = useState('');

  const [requestError, setRequestError] = useState('');

  const signUpMutation = useMutation({
    mutationFn: async ({
      email,
      password,
    }: {
      email: string;
      password: string;
    }) => {
      return await signUp(email, password);
    },
  });

  const setUpProfileMutation = useMutation({
    mutationFn: async ({
      username,
      phone,
      photo,
      countryCode,
      countryName,
    }: {
      username: string;
      phone: string;
      photo: string;
      countryCode: string;
      countryName: string;
    }) => {
      return await setUpProfile(
        username,
        phone,
        photo,
        countryCode,
        countryName,
      );
    },
  });

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handleBackStep = () => {
    if (step === 0) {
      const newSearchParams = new URLSearchParams();
      if (isCreatorSignup) {
        newSearchParams.set('creatorSignup', '');
      }
      if (callbackUrl) {
        newSearchParams.set('callbackUrl', callbackUrl);
      }
      navigate('/login' + '?' + newSearchParams.toString(), {
        state: { email },
      });
    } else {
      setStep(step - 1);
    }
  };

  const handleSignUp = async () => {
    setEmailError('');
    setPasswordError('');
    setRequestError('');

    const registerSchema = z.object({
      email: z
        .string({ required_error: 'Email is required' })
        .email('Invalid email'),
      password: z
        .string({ required_error: 'Password is required' })
        .min(8, 'Password must be at least 8 characters'),
    });

    const result = registerSchema.safeParse({ email, password });

    if (!result.success) {
      setEmailError(result.error.formErrors.fieldErrors.email?.[0] || '');
      setPasswordError(result.error.formErrors.fieldErrors.password?.[0] || '');
      return;
    }

    setRequestError('');
    try {
      await signUpMutation.mutateAsync({ email, password });
      handleNextStep();
    } catch (error) {
      console.error({ error });
      if (error instanceof Error) {
        setRequestError(error.message);
      } else {
        setRequestError('Something went wrong');
      }
    }
  };

  const handleSaveContinue = async () => {
    setRequestError('');
    setUsernameError('');
    setPhoneError('');
    setPhotoError('');

    const setupProfileSchema = z.object({
      username: z
        .string({ required_error: 'Username is required' })
        .min(1, 'Username is required'),
      phone: z
        .string({ required_error: 'Phone is required' })
        .min(1, 'Phone is required'),
      photo: z
        .string({
          invalid_type_error: 'Photo is required',
          required_error: 'Photo is required',
        })
        .min(1, 'Photo is required'),
      countryCode: z
        .string({ required_error: 'Country code is required' })
        .min(1, 'Country code is required')
        .regex(/^\+\d+$/, 'Invalid country code'),
      countryName: z.string({ required_error: 'Country name is required' }),
    });

    const result = setupProfileSchema.safeParse({
      username,
      phone,
      photo,
      countryCode: phoneDial.idd,
      countryName: phoneDial.name,
    });

    if (!result.success) {
      setUsernameError(result.error.formErrors.fieldErrors.username?.[0] || '');
      setPhoneError(
        result.error.formErrors.fieldErrors.phone?.[0] ||
          result.error.formErrors.fieldErrors.countryCode?.[0] ||
          '',
      );
      setPhotoError(result.error.formErrors.fieldErrors.photo?.[0] || '');
      return;
    }

    // Función para obtener el blob desde una URL
    async function getBlobFromUrl(url: string): Promise<Blob> {
      const response = await fetch(url);
      return await response.blob();
    }

    // Función para convertir un Blob a base64
    function blobToBase64(blob: Blob): Promise<string> {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64data = reader.result as string;
          resolve(base64data);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    }

    try {
      const blob = await getBlobFromUrl(photo!);
      const photoBase64 = await blobToBase64(blob);
      if (photoBase64)
        await setUpProfileMutation.mutateAsync({
          username: result.data.username,
          phone: result.data.phone,
          photo: photoBase64.split(',')[1],
          countryCode: result.data.countryCode,
          countryName: result.data.countryName,
        });
      finishSignUp();
    } catch (error) {
      if (error instanceof Error) {
        setRequestError(error.message);
      } else {
        setRequestError('Something went wrong');
      }
    }
  };

  const finishSignUp = () => {
    if (callbackUrl && !['/login', '/register'].includes(callbackUrl)) {
      navigate(callbackUrl + (isCreatorSignup ? '?creatorSignup' : ''));
      return;
    }

    if (!isCreatorSignup) {
      navigate('/settings');
      return;
    }

    navigate('/getting-started' + (isCreatorSignup ? '?creatorSignup' : ''));
  };

  return (
    <div className="flex h-full w-full items-center justify-center bg-bgGrey p-6 dark:bg-dark-bgGrey sm:p-0">
      {step === 0 && (
        <ContinueSignUpPage
          email={email}
          setEmail={setEmail}
          password={password}
          setPassword={setPassword}
          emailError={emailError}
          passwordError={passwordError}
          handleBackStep={handleBackStep}
          handleContinue={handleSignUp}
          isLoading={signUpMutation.isPending}
          requestError={requestError}
          isCreatorSignup={isCreatorSignup}
          callbackUrl={callbackUrl}
        />
      )}
      {step === 1 && (
        <SetupProfilePage
          handleBackStep={handleBackStep} // TODO: validate this
          handleSaveContinue={handleSaveContinue}
          name={username}
          setname={setUsername}
          phone={phone}
          setPhone={setPhone}
          photo={photo}
          setPhoto={setPhoto}
          usernameError={usernameError}
          phoneError={phoneError}
          photoError={photoError}
          isLoading={setUpProfileMutation.isPending}
          phoneDial={phoneDial}
          setPhoneDial={setPhoneDial}
        />
      )}
    </div>
  );
};

export default Register;
