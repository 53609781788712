import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

export const GoogleRedirect = () => {
  const redirectedFrom = localStorage.getItem('google-redirect');

  useEffect(() => {
    return () => {
      localStorage.removeItem('google-redirect');
    };
  }, []);

  if (redirectedFrom) {
    return <Navigate to={redirectedFrom} />;
  }

  return <Navigate to="/" />;
};
