export const PhoneIcon = (props) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path d="M3 6C3 14.5604 9.93959 21.5 18.5 21.5C18.8862 21.5 19.2691 21.4859 19.6483 21.4581C20.0834 21.4262 20.3009 21.4103 20.499 21.2963C20.663 21.2019 20.8185 21.0345 20.9007 20.864C21 20.6582 21 20.4181 21 19.938V17.1207C21 16.7169 21 16.515 20.9335 16.342C20.8749 16.1891 20.7795 16.053 20.6559 15.9456C20.516 15.824 20.3262 15.755 19.9468 15.617L16.74 14.4509C16.2985 14.2904 16.0777 14.2101 15.8683 14.2237C15.6836 14.2357 15.5059 14.2988 15.3549 14.4058C15.1837 14.5271 15.0629 14.7285 14.8212 15.1314L14 16.5C11.3501 15.2999 9.2019 13.1489 8 10.5L9.36863 9.67882C9.77145 9.43713 9.97286 9.31628 10.0942 9.14506C10.2012 8.99408 10.2643 8.81637 10.2763 8.6317C10.2899 8.42227 10.2096 8.20153 10.0491 7.76005L8.88299 4.55321C8.745 4.17376 8.67601 3.98403 8.55442 3.8441C8.44701 3.72049 8.31089 3.62515 8.15802 3.56645C7.98496 3.5 7.78308 3.5 7.37932 3.5H4.56201C4.08188 3.5 3.84181 3.5 3.63598 3.59925C3.4655 3.68146 3.29814 3.83701 3.2037 4.00103C3.08968 4.19907 3.07375 4.41662 3.04189 4.85173C3.01413 5.23086 3 5.61378 3 6Z" />
    </g>
  </svg>
);
