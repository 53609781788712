import { TriangleAlertIcon } from 'lucide-react';
import { Suspense, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { useUserControllerResendConfirmationEmail } from '@/api';
import { Loading } from '@/components/Loading';
import { ConfigHeaderProvider } from '@/components/config-header-provider';
import { Header } from '@/components/layout/header';
import { Sidebar } from '@/components/layout/sidebar';
import { Button } from '@/components/ui/button';
import { useGetConversations } from '@/hooks/use-get-conversations';
import { useAuth } from '@/hooks/useContext';

const ConfirmAccountBanner = () => {
  const { user } = useAuth();
  const resendConfirmationEmailMutation =
    useUserControllerResendConfirmationEmail();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isButtonDisabled) {
      timer = setTimeout(() => {
        setIsButtonDisabled(false);
      }, 15000);
    }
    return () => clearTimeout(timer);
  }, [isButtonDisabled]);

  const handleResendEmail = () => {
    resendConfirmationEmailMutation.mutate({
      data: {
        email: user?.email ?? '',
      },
    });
    setIsButtonDisabled(true);
  };

  return (
    <div className="p-4">
      <div className="flex items-center justify-between rounded-md bg-bgGrey p-4 dark:bg-dark-bgGrey">
        <div className="flex items-center gap-4">
          <TriangleAlertIcon className="h-5 w-5" />
          <div className="flex flex-col gap-1">
            <h4 className="text-base font-semibold">
              Email verification required
            </h4>
            <p className="text-sm">
              Please verify your email for full access to the platform.
            </p>
          </div>
        </div>
        <Button
          variant="outline"
          onClick={handleResendEmail}
          disabled={isButtonDisabled}
        >
          {isButtonDisabled ? 'Resent!' : 'Resend email'}
        </Button>
      </div>
    </div>
  );
};

export function Layout() {
  const { user } = useAuth();

  useGetConversations();

  return (
    <ConfigHeaderProvider>
      <Suspense fallback={<Loading />}>
        <div className="h-full md:grid md:grid-cols-[auto,1fr]">
          <div className="hidden md:block">
            <Sidebar />
          </div>
          <div className="flex h-full w-full flex-col overflow-y-hidden">
            <Header />
            {user?.accountConfirmed === false && <ConfirmAccountBanner />}
            <Outlet />
          </div>
        </div>
      </Suspense>
    </ConfigHeaderProvider>
  );
}
