import { XIcon } from 'lucide-react';

import {
  UpdateSpaceGroupMonetizationSettingsDto,
  UpdateSpaceGroupMonetizationSettingsDtoFreeCallDurationType,
  UpdateSpaceGroupMonetizationSettingsDtoFreeCallsFrequency,
  UpdateSpaceGroupMonetizationSettingsDtoFreeMessagesFrequency,
} from '@/api';
import { ArrowLeftIcon } from '@/assets/icon/arrowLeft';
import { CalendarRoudedIcon } from '@/assets/icon/calendarRounded';
import { ChatBubbleEllipsisIcon } from '@/assets/icon/chat-bubble-ellipsis';

import InputField from '../ui/InputField';
import { Button } from '../ui/button';
import { DialogHeader, DialogTitle } from '../ui/dialog';
import { Select, SelectContent, SelectItem, SelectTrigger } from '../ui/select';
import { Switch } from '../ui/switch';

const ConfigMonetization = ({
  onClose,
  handleBack,
  isLoading,
  updateMonetization,
  monetizationData,
  handleContinue,
}: {
  onClose: () => void;
  handleBack: () => void;
  isLoading: boolean;
  updateMonetization: (data: UpdateSpaceGroupMonetizationSettingsDto) => void;
  monetizationData: UpdateSpaceGroupMonetizationSettingsDto;
  handleContinue: () => void;
}) => {
  return (
    <>
      <DialogHeader className="flex-row items-center justify-between space-y-0">
        <DialogTitle className="flex items-center gap-2.5 text-2xl font-semibold">
          <Button
            variant="icon"
            size="icon"
            onClick={handleBack}
            disabled={isLoading}
          >
            <ArrowLeftIcon className="h-6 w-6 cursor-pointer fill-black dark:fill-white" />
          </Button>
          1:1 monetization
        </DialogTitle>
        <Button
          onClick={onClose}
          variant="icon"
          size="icon"
          className="m-0 rounded-sm p-0 ring-offset-background transition-opacity focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
        >
          <XIcon className="h-4 w-4" />
          <span className="sr-only">Close</span>
        </Button>
      </DialogHeader>
      <div className="flex w-full flex-col gap-8 font-medium">
        <div className="flex w-full items-center gap-2.5">
          <div className="flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-md bg-[#FFDAF5]">
            <ChatBubbleEllipsisIcon className="h-6 w-6 stroke-[#EF38BE]" />
          </div>
          <div className="w-full text-lg font-medium">Direct messaging</div>
          <Switch
            checked={monetizationData?.allowDirectMessaging ?? false}
            onClick={() => {
              updateMonetization({
                ...monetizationData,
                allowDirectMessaging: !monetizationData?.allowDirectMessaging,
              });
            }}
          />
        </div>
        {monetizationData?.allowDirectMessaging && (
          <div className="flex w-full flex-col gap-8">
            <h3 className="text-lg font-medium text-textParagraph dark:text-dark-textParagraph">
              Configure messaging
            </h3>
            <div className="flex flex-col gap-2.5">
              <div className="text-base font-medium">
                Set messaging frequency
              </div>
              <Select
                value={monetizationData?.messageFrequencyType}
                onValueChange={(value) => {
                  updateMonetization({
                    ...monetizationData,
                    messageFrequencyType: value as 'CAPPED' | 'UNLIMITED',
                  });
                }}
              >
                <SelectTrigger className="h-fit border-light p-4 capitalize dark:border-dark-light">
                  {monetizationData?.messageFrequencyType?.toLowerCase() ??
                    'Select frequency'}
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="CAPPED">Capped</SelectItem>
                  <SelectItem value="UNLIMITED">Unlimited</SelectItem>
                </SelectContent>
              </Select>
            </div>
            {monetizationData?.messageFrequencyType === 'CAPPED' && (
              <div className="flex flex-col gap-2.5">
                <div className="text-base font-medium">
                  Set number of messages allowed
                </div>
                <div className="flex gap-2.5">
                  <InputField
                    type="number"
                    regex={/^\d+$/}
                    value={monetizationData?.freeMessagesLimit}
                    onChange={(e) => {
                      updateMonetization({
                        ...monetizationData,
                        freeMessagesLimit: parseInt(e.target.value),
                      });
                    }}
                    inputClassName="!bg-transparent border-light dark:border-dark-light !p-2.5"
                    className="!gap-0"
                  />
                  <Select
                    value={monetizationData?.freeMessagesFrequency}
                    onValueChange={(value) => {
                      updateMonetization({
                        ...monetizationData,
                        freeMessagesFrequency:
                          value as UpdateSpaceGroupMonetizationSettingsDtoFreeMessagesFrequency,
                      });
                    }}
                  >
                    <SelectTrigger className="h-fit border-light p-2.5 text-base capitalize dark:border-dark-light">
                      {monetizationData?.freeMessagesFrequency
                        ?.replace('_', ' ')
                        .toLowerCase() ?? 'Select type'}
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="PER_DAY">Per day</SelectItem>
                      <SelectItem value="PER_WEEK">Per week</SelectItem>
                      <SelectItem value="PER_MONTH">Per month</SelectItem>
                      <SelectItem value="PER_YEAR">Per year</SelectItem>
                      <SelectItem value="TOTAL">Total</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
              </div>
            )}
          </div>
        )}
        <div className="flex w-full items-center gap-2.5">
          <div className="flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-md bg-[#FBEDFF]">
            <CalendarRoudedIcon className="h-6 w-6 stroke-[#C413F5]" />
          </div>
          <div className="w-full text-lg font-medium">Calendar access</div>
          <Switch
            checked={monetizationData?.allowCallMeetings ?? false}
            onClick={() => {
              updateMonetization({
                ...monetizationData,
                allowCallMeetings: !monetizationData?.allowCallMeetings,
              });
            }}
          />
        </div>
        {monetizationData?.allowCallMeetings && (
          <div className="flex w-full flex-col gap-8">
            <h3 className="text-lg font-medium text-textParagraph dark:text-dark-textParagraph">
              Configure booking
            </h3>
            <div className="flex flex-col gap-2.5">
              <div className="text-base font-medium">
                Allow each member to book
              </div>
              <div className="flex gap-2.5">
                <InputField
                  type="number"
                  regex={/^\d+$/}
                  value={monetizationData?.freeCallsLimit}
                  onChange={(e) => {
                    updateMonetization({
                      ...monetizationData,
                      freeCallsLimit: parseInt(e.target.value),
                    });
                  }}
                  inputClassName="!bg-transparent border-light dark:border-dark-light !p-2.5"
                  className="!gap-0"
                />
                <Select
                  value={monetizationData?.freeCallsFrequency}
                  onValueChange={(value) => {
                    updateMonetization({
                      ...monetizationData,
                      freeCallsFrequency:
                        value as UpdateSpaceGroupMonetizationSettingsDtoFreeCallsFrequency,
                    });
                  }}
                >
                  <SelectTrigger className="h-fit border-light p-2.5 text-base capitalize dark:border-dark-light">
                    {monetizationData?.freeCallsFrequency
                      ?.replace('_', ' ')
                      .toLowerCase() ?? 'Select type'}
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="PER_DAY">Per day</SelectItem>
                    <SelectItem value="PER_WEEK">Per week</SelectItem>
                    <SelectItem value="PER_MONTH">Per month</SelectItem>
                    <SelectItem value="PER_YEAR">Per year</SelectItem>
                    <SelectItem value="TOTAL">Total</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>
            <div className="flex flex-col gap-2.5">
              <div className="text-base font-medium">Set call duration</div>
              <div className="flex gap-2.5">
                <InputField
                  type="number"
                  regex={/^\d+$/}
                  value={monetizationData?.freeCallDuration}
                  onChange={(e) => {
                    updateMonetization({
                      ...monetizationData,
                      freeCallDuration: parseInt(e.target.value),
                    });
                  }}
                  inputClassName="!bg-transparent border-light dark:border-dark-light !p-2.5"
                  className="!gap-0"
                />
                <Select
                  value={monetizationData?.freeCallDurationType}
                  onValueChange={(value) => {
                    updateMonetization({
                      ...monetizationData,
                      freeCallDurationType:
                        value as UpdateSpaceGroupMonetizationSettingsDtoFreeCallDurationType,
                    });
                  }}
                >
                  <SelectTrigger className="h-fit border-light p-2.5 text-base capitalize dark:border-dark-light">
                    {monetizationData?.freeCallDurationType?.toLowerCase() ??
                      'Select type'}
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="MINUTES">Minutes</SelectItem>
                    <SelectItem value="HOURS">Hours</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>
          </div>
        )}
      </div>
      <Button
        onClick={handleContinue}
        className="w-full rounded-lg"
        disabled={isLoading}
      >
        {isLoading ? 'Loading...' : 'Continue'}
      </Button>
    </>
  );
};

export default ConfigMonetization;
