export const AllEmojisList: string[] = [
  '😂',
  '😍',
  '😊',
  '👍',
  '🙏',
  '💕',
  '🔥',
  '😘',
  '🥰',
  '😅',
  '😁',
  '🙂',
  '🙌',
  '😎',
  '🤔',
  '😢',
  '🎉',
  '😆',
  '💯',
  '😜',
  '🥳',
  '🤗',
  '👏',
  '😱',
  '🙈',
  '😴',
  '😉',
  '🤦‍♂️',
  '🤷‍♀️',
  '🤩',
  '😇',
  '😋',
  '😳',
  '😛',
  '😡',
  '😭',
  '🤤',
  '🤢',
  '🤑',
  '😷',
  '😪',
  '😔',
  '🤪',
  '😏',
  '🥲',
  '🤭',
  '🤯',
  '🤬',
  '🥵',
  '🥶',
  '💀',
  '👻',
  '👽',
  '👾',
  '🤖',
  '💩',
  '😺',
  '😸',
  '😹',
  '😻',
  '😼',
  '😽',
  '🙊',
  '🙉',
  '🦄',
  '🐶',
  '🐱',
  '🐭',
  '🐹',
  '🐰',
  '🦊',
  '🐻',
  '🐼',
  '🐷',
  '🐵',
  '🐔',
  '🐧',
  '🐦',
  '🐤',
  '🐣',
  '🐥',
  '🦉',
  '🦅',
  '🐢',
  '🐍',
  '🦖',
  '🦕',
  '🐙',
  '🦑',
  '🐡',
  '🐠',
  '🐬',
  '🦈',
  '🐳',
  '🐋',
  '🦋',
  '🐞',
  '🐜',
  '🪲',
  '🦟',
  '🌸',
  '🌹',
  '🌻',
  '🌼',
  '🌷',
  '🌲',
  '🌴',
  '🌵',
  '🍄',
  '🌰',
  '🍁',
  '🍂',
  '🍃',
  '🌍',
  '🌎',
  '🌏',
  '🌙',
  '⭐',
  '☀️',
  '🔥',
  '🌈',
  '💧',
  '🌊',
  '🍏',
  '🍎',
  '🍐',
  '🍊',
  '🍋',
  '🍌',
  '🍉',
  '🍇',
  '🍓',
  '🫐',
  '🍒',
  '🍑',
  '🍍',
  '🥥',
  '🥝',
  '🍅',
  '🍆',
  '🥑',
  '🥒',
  '🥬',
  '🥕',
  '🌽',
  '🌶️',
  '🫑',
  '🥔',
  '🍠',
  '🥐',
  '🍞',
  '🥖',
  '🥯',
  '🥨',
  '🧀',
  '🥚',
  '🍳',
  '🥓',
  '🥩',
  '🍗',
  '🍖',
  '🌭',
  '🍔',
  '🍟',
  '🍕',
  '🥪',
  '🥙',
  '🧆',
  '🌮',
  '🌯',
  '🥗',
  '🍲',
  '🍜',
  '🍝',
  '🍣',
  '🍤',
  '🍥',
  '🥮',
  '🍡',
  '🥠',
  '🍧',
  '🍨',
  '🍦',
  '🥧',
  '🍰',
  '🎂',
  '🧁',
  '🍭',
  '🍬',
  '🍫',
  '🍿',
  '🍩',
  '🍪',
  '🍯',
  '🍵',
  '☕',
  '🍶',
  '🍺',
  '🍻',
  '🥂',
  '🍷',
  '🥃',
  '🍸',
  '🍹',
  '🧃',
  '🥤',
  '🧋',
  '🍾',
  '🥄',
  '🍴',
  '🍽️',
  '🔪',
  '🧂',
  '🏆',
  '🎖️',
  '🥇',
  '🥈',
  '🥉',
  '🏅',
  '🎲',
  '🎯',
  '🎳',
  '🎮',
  '🎰',
  '🎨',
  '🎬',
  '🎤',
  '🎧',
  '🎷',
  '🎸',
  '🎹',
  '🎺',
  '🥁',
  '🎻',
  '🎫',
  '🎟️',
  '🎭',
  '🩰',
  '🎪',
  '🎡',
  '🎢',
  '🎠',
  '🏖️',
  '🏝️',
  '🏜️',
  '🏞️',
  '🏕️',
  '🏔️',
  '⛰️',
  '🏠',
  '🏡',
  '🏢',
  '🏣',
  '🏤',
  '🏥',
  '🏦',
  '🏨',
  '🏩',
  '🏪',
  '🏫',
  '🏬',
  '🏭',
  '🏯',
  '🏰',
  '💒',
  '🗼',
  '🗽',
  '🕌',
  '🕍',
  '🛕',
  '⛪',
  '🕋',
  '⛩️',
  '🕰️',
  '🕳️',
  '🌋',
  '🌁',
  '🌉',
  '🗾',
  '🎆',
  '🎇',
  '🎑',
  '🌌',
  '🌠',
  '🛸',
  '🚀',
  '🛶',
  '⛵',
  '🚢',
  '🚤',
  '🛥️',
  '🚖',
  '🚔',
  '🚉',
  '🚆',
  '🚄',
  '🚃',
  '🚊',
  '🚲',
  '🛴',
  '🚏',
  '🏗️',
  '🏭',
  '🏘️',
  '🏙️',
  '🏞️',
  '🌉',
  '🏰',
  '🏩',
  '🕋',
  '⛩️',
  '💼',
  '🎒',
  '💍',
  '🕶️',
  '🧳',
  '👑',
  '🌂',
  '🪁',
  '🪀',
  '🧸',
  '💈',
  '🪓',
  '🔨',
  '⛏️',
  '🔧',
  '🔩',
  '🔗',
  '🧲',
  '🧱',
  '🚪',
  '🛋️',
  '🛏️',
  '🛌',
  '🖼️',
  '🧽',
  '🧼',
  '🛁',
  '🪠',
  '🪣',
  '🚽',
  '🚿',
  '🛀',
  '🧖‍♂️',
  '🧖‍♀️',
  '🧖',
  '🧘‍♂️',
  '🧘‍♀️',
  '🧘',
  '🏄‍♂️',
  '🏄‍♀️',
  '🏄',
  '🏊‍♂️',
  '🏊‍♀️',
  '🏊',
  '🤸‍♂️',
  '🤸‍♀️',
  '🤸',
  '🤼‍♂️',
  '🤼‍♀️',
  '🤼',
  '🤽‍♂️',
  '🤽‍♀️',
  '🤽',
  '🏇',
  '🚴‍♂️',
  '🚴‍♀️',
  '🚴',
  '🚵‍♂️',
  '🚵‍♀️',
  '🚵',
  '🧗‍♂️',
  '🧗‍♀️',
  '🧗',
  '🧚‍♂️',
  '🧚‍♀️',
  '🧚',
  '🧙‍♂️',
  '🧙‍♀️',
  '🧙',
  '👼',
  '💪',
  '👋',
  '🤙',
  '✋',
  '👌',
  '✌️',
  '🤞',
  '👆',
  '👇',
  '👉',
  '👈',
  '👊',
  '🤜',
  '🤛',
  '👏',
  '👐',
  '🤲',
  '🙏',
  '🤝',
  '🧏‍♂️',
  '🧏‍♀️',
  '🧏',
  '🙆‍♂️',
  '🙆‍♀️',
  '🙆',
  '🙅‍♂️',
  '🙅‍♀️',
  '🙅',
  '🙋‍♂️',
  '🙋‍♀️',
  '🙋',
  '🙇‍♂️',
  '🙇‍♀️',
  '🙇',
  '🤦‍♂️',
  '🤦‍♀️',
  '🤦',
  '🤷‍♂️',
  '🤷‍♀️',
  '🤷',
  '💁‍♂️',
  '💁‍♀️',
  '💁',
  '💇‍♂️',
  '💇‍♀️',
  '💇',
  '💆‍♂️',
  '💆‍♀️',
  '💆',
  '👫',
  '👬',
  '👭',
  '💏',
  '💑',
  '👪',
  '🤱',
  '👨‍👩‍👦',
  '👨‍👩‍👧',
  '👨‍👩‍👧‍👦',
  '👨‍👩‍👦‍👦',
  '👨‍👨‍👦',
  '👩‍👩‍👦',
  '👨‍👨‍👧',
  '👩‍👩‍👧',
  '👩‍👦',
  '👨‍👦',
  '👩‍👧',
  '👨‍👧',
  '👶',
  '🧒',
  '👦',
  '👧',
  '🧑',
  '👩',
  '👨',
  '👩‍🦱',
  '👨‍🦱',
  '👩‍🦳',
  '👨‍🦳',
  '👩‍🦲',
  '👨‍🦲',
  '👩‍🦰',
  '👨‍🦰',
  '👱‍♀️',
  '👱‍♂️',
  '🧓',
  '👴',
  '👵',
  '🙍‍♂️',
  '🙍‍♀️',
  '🙍',
  '🙎‍♂️',
  '🙎‍♀️',
  '🙎',
  '😇',
  '🤠',
  '🥳',
  '🥸',
  '😈',
  '👿',
  '😺',
  '😹',
  '😻',
  '😼',
  '😽',
  '🙀',
  '😿',
  '😾',
];

export const colorsOrEmojis = [
  { type: 'color', value: '#65A96E' },
  { type: 'color', value: 'red' },
  { type: 'color', value: 'yellow' },
  { type: 'color', value: 'green' },
  { type: 'color', value: 'blue' },
  { type: 'color', value: 'indigo' },
  { type: 'color', value: 'purple' },
  { type: 'color', value: 'pink' },
  { type: 'emoji', value: '🚀' },
  { type: 'emoji', value: '💻' },
  { type: 'emoji', value: '💬' },
  { type: 'emoji', value: '📅' },
  { type: 'emoji', value: '👥' },
  { type: 'emoji', value: '🛰️' },
  { type: 'emoji', value: '📍' },
  { type: 'emoji', value: '🌐' },
  { type: 'emoji', value: '🎉' },
  { type: 'emoji', value: '🎈' },
  { type: 'emoji', value: '🎁' },
  { type: 'emoji', value: '🎊' },
  { type: 'emoji', value: '🎃' },
  { type: 'emoji', value: '📚' },
  { type: 'emoji', value: '📙' },
  { type: 'emoji', value: '📘' },
  { type: 'emoji', value: '📕' },
  { type: 'emoji', value: '📖' },
  { type: 'emoji', value: '📓' },
  { type: 'emoji', value: '📔' },
  { type: 'emoji', value: '📒' },
  { type: 'emoji', value: '🔖' },
  { type: 'emoji', value: '👨‍🏫' },
  { type: 'emoji', value: '👩‍🏫' },
  { type: 'emoji', value: '📝' },
  { type: 'emoji', value: '📐' },
  { type: 'emoji', value: '📏' },
  { type: 'emoji', value: '📊' },
  { type: 'emoji', value: '📈' },
  { type: 'emoji', value: '🔍' },
  { type: 'emoji', value: '💡' },
  { type: 'emoji', value: '🎓' },
  { type: 'emoji', value: '📎' },
  { type: 'emoji', value: '📌' },
  { type: 'emoji', value: '🖍️' },
  { type: 'emoji', value: '✏️' },
  { type: 'emoji', value: '📑' },
  { type: 'emoji', value: '🧮' },
  { type: 'emoji', value: '🗂️' },
];
