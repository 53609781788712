import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { useInvitationsControllerAcceptInvitation } from '@/api';
import { Loading } from '@/components/Loading';

export const ProcessInvitePage = () => {
  const { token } = useParams<{ token: string }>();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const processInviteMutation = useInvitationsControllerAcceptInvitation({
    mutation: {
      onSuccess: (data) => {
        queryClient.removeQueries({ queryKey: ['process-invite'] });

        if (data.brandId && data.spaceGroupId) {
          navigate(`/brands/${data.brandId}/space-groups/${data.spaceGroupId}`);
          return;
        }

        if (data.brandId) {
          navigate(`/brands/${data.brandId}`);
          return;
        }

        navigate('/');
      },
    },
  });

  useQuery({
    queryKey: ['process-invite', token],
    queryFn: () =>
      processInviteMutation.mutateAsync({ data: { token: token ?? '' } }),
    enabled: !!token,
  });

  if (!token) {
    throw new Error(`No token found`);
  }

  if (processInviteMutation.isError) {
    if (
      (processInviteMutation.error as unknown) instanceof Error &&
      (processInviteMutation.error as unknown as Error).message ===
        'Invitation already processed'
    ) {
      return <Navigate to="/" />;
    }

    throw processInviteMutation.error;
  }

  return <Loading />;
};
