export const BarChartIcon = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="analytics-01">
      <path
        id="Vector"
        d="M5.4165 13.7498V10.4165M9.58317 13.7498V5.4165M13.7498 13.7498V8.74984"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M17.4998 3.33317V15.8332C17.4998 16.7537 16.7537 17.4998 15.8332 17.4998H3.33317C2.4127 17.4998 1.6665 16.7537 1.6665 15.8332V3.33317C1.6665 2.4127 2.4127 1.6665 3.33317 1.6665H15.8332C16.7537 1.6665 17.4998 2.4127 17.4998 3.33317Z"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
