import { SVGProps } from 'react';

export const MembersSapceIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 19 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 13.252V15.3414C9.3744 15.1203 8.7013 15 8 15C4.68629 15 2 17.6863 2 21H0C0 16.5817 3.58172 13 8 13C8.6906 13 9.3608 13.0875 10 13.252ZM8 12C4.685 12 2 9.315 2 6C2 2.685 4.685 0 8 0C11.315 0 14 2.685 14 6C14 9.315 11.315 12 8 12ZM8 10C10.21 10 12 8.21 12 6C12 3.79 10.21 2 8 2C5.79 2 4 3.79 4 6C4 8.21 5.79 10 8 10ZM14 16V13H16V16H19V18H16V21H14V18H11V16H14Z"
      //   fill="#F32222"
    />
  </svg>
);
