import { useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import {
  DiscussionDetails,
  useDiscussionControllerGetUserJoinedDiscussionsInPostSpace,
  useSpaceGroupControllerGetUsersBySpaceGroupIdForMentions,
} from '@/api';
import { Loading } from '@/components/Loading';
import { CometChatComponent } from '@/components/comet-chat-component';
import { useUpdateConfigHeader } from '@/components/config-header-provider';
import DiscussionList from '@/components/ui/discussions-components/DiscussionList';
import { useMemberInfoDrawerContext } from '@/routes/MemberInfoDrawerProvider';

export const DiscussionPage = () => {
  const { brandId, spaceGroupId, postsSpaceId, discussionId } = useParams();
  const allDiscussionsQuery =
    useDiscussionControllerGetUserJoinedDiscussionsInPostSpace(
      postsSpaceId as string,
    );

  const { setUserInfoData } = useMemberInfoDrawerContext();

  const navigate = useNavigate();

  useUpdateConfigHeader(
    {
      showNotifications: true,
      label: 'Discussions',
      action: null,
    },
    [],
  );

  const [isOpen, setIsOpen] = useState<boolean>(true);

  useEffect(() => {
    setIsOpen(true);
  }, [discussionId]);

  const usersQuery = useSpaceGroupControllerGetUsersBySpaceGroupIdForMentions(
    spaceGroupId as string,
  );

  const users = usersQuery.data ?? [];

  const findUser = (id: string) => {
    const user = users.find((user) => user.id === id);
    return user?.name ? { username: user.name } : null;
  };

  const handleMentionClick = (uid: string) => {
    const user = users.find((u) => u.id === uid);
    if (user?.id && spaceGroupId) {
      setUserInfoData({
        memberId: user.id,
        spaceGroupId: spaceGroupId,
      });
    }
  };

  if (allDiscussionsQuery.isLoading) {
    return <Loading />;
  }

  if (allDiscussionsQuery.isError) {
    throw new Error(
      `Error fetching all discussions for posts space id ${postsSpaceId}, ${allDiscussionsQuery.error}`,
    );
  }

  const allDiscussions = allDiscussionsQuery.data ?? [];

  const discussion = allDiscussions.find(
    (discussion) => discussion.id === discussionId,
  );

  if (!discussion) {
    return <Navigate to="/404" />;
  }

  const handleSelectDiscussion = (discussion: DiscussionDetails) => {
    navigate(
      `/app/brands/${brandId}/space-groups/${spaceGroupId}/posts-spaces/${postsSpaceId}/joined-discussions/${discussion.id}`,
    );
  };

  const isOpenDiscussion = discussion.id === discussionId;

  const handleCloseDiscussion = () => {
    setIsOpen(false);
  };

  return (
    <div className="flex h-full w-full overflow-auto md:flex-row">
      <DiscussionList
        discussions={allDiscussions}
        findUser={findUser}
        onMentionClick={handleMentionClick}
        onSelectDiscussion={handleSelectDiscussion}
        isOpenDiscussion={isOpenDiscussion}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />

      <DiscussionChat
        discussion={discussion}
        isOpenDiscussion={isOpenDiscussion}
        handleClose={handleCloseDiscussion}
        isOpen={isOpen}
        spaceGroupId={spaceGroupId ?? ''}
      />
    </div>
  );
};

const DiscussionChat = ({
  discussion,
  isOpenDiscussion,
  handleClose,
  isOpen,
  spaceGroupId,
}: {
  discussion: DiscussionDetails;
  isOpenDiscussion: boolean;
  handleClose: () => void;
  isOpen: boolean;
  spaceGroupId: string;
}) => {
  const GUID = discussion.cometChatGroupId;

  if (!GUID) {
    return null;
  }

  if (isOpenDiscussion) {
    return (
      <CometChatComponent
        GUID={GUID}
        spaceGroupId={spaceGroupId}
        onClose={handleClose}
        isOpen={isOpen}
        isDiscussion={true}
      />
    );
  }
};

export default DiscussionPage;
