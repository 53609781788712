import { Area, AreaChart, CartesianGrid, XAxis, YAxis } from 'recharts';

import { Card, CardContent, CardHeader, CardTitle } from '../card';
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from '../chart';

const chartConfig = {
  value: {
    label: 'New member purchases',
    color: 'hsl(var(--chart-1))',
  },
} satisfies ChartConfig;

type ChartData = {
  date: string;
  value: number;
};

const OverviewChart = ({ chartData }: { chartData: ChartData[] }) => {
  return (
    <Card className="border-0 bg-light-2 p-6 dark:bg-dark-2">
      <CardHeader>
        <CardTitle>New Members Purchases</CardTitle>
        {/* <CardDescription>
          Showing total visitors for the last 6 months
        </CardDescription> */}
      </CardHeader>
      <CardContent className="p-0">
        <ChartContainer config={chartConfig} className="h-125 w-full">
          {chartData?.length > 0 ? (
            <AreaChart
              accessibilityLayer
              data={chartData}
              margin={{
                left: 12,
                right: 12,
              }}
            >
              <CartesianGrid vertical={false} />
              <YAxis tickLine={false} axisLine={false} tickCount={9} />
              <XAxis
                dataKey="date"
                tickLine={true}
                axisLine={false}
                tickMargin={8}
                tickFormatter={(value) => {
                  const date = new Date(value);
                  return date.toLocaleDateString('en-US', {
                    month: 'short',
                    day: 'numeric',
                  });
                }}
              />
              <ChartTooltip
                cursor={false}
                content={
                  <ChartTooltipContent
                    className="w-[150px]"
                    nameKey="views"
                    labelFormatter={(value) => {
                      return new Date(value).toLocaleDateString('en-US', {
                        month: 'short',
                        day: 'numeric',
                        year: 'numeric',
                      });
                    }}
                  />
                }
              />
              <defs>
                <linearGradient id="fillDesktop" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#3B82F6" stopOpacity={0.5} />
                  <stop offset="95%" stopColor="#3B82F6" stopOpacity={0} />
                </linearGradient>
              </defs>
              <Area
                dataKey="value"
                type="natural"
                fill="url(#fillDesktop)"
                fillOpacity={0.4}
                stroke="var(--color-value)"
              />
            </AreaChart>
          ) : (
            <div className="flex w-full items-center justify-center py-40 text-base">
              No data
            </div>
          )}
        </ChartContainer>
      </CardContent>
    </Card>
  );
};

export default OverviewChart;
