import { useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import {
  useSpaceGroupControllerCheckUserSpaceGroupAccess,
  useStripeControllerProcessPurchase,
} from '@/api';
import { Loading } from '@/components/Loading';
import { Button } from '@/components/ui/button';
import { useBrandPermits } from '@/hooks/useBrandPermits';

export function SpaceGroupAccessCheck() {
  const hasBrandPermits = useBrandPermits();
  const { spaceGroupId } = useParams();
  const accessQuery = useSpaceGroupControllerCheckUserSpaceGroupAccess(
    spaceGroupId ?? '',
    {
      query: {
        enabled: !hasBrandPermits && !!spaceGroupId,
      },
    },
  );

  if (hasBrandPermits) {
    return <Outlet />;
  }

  if (accessQuery.isLoading) {
    return <Loading />;
  }

  if (spaceGroupId && !accessQuery.data?.canAccess && !hasBrandPermits) {
    return <StripeCheckout />;
  }

  return <Outlet />;
}

function StripeCheckout() {
  const navigate = useNavigate();
  const { spaceGroupId, brandId } = useParams();

  const [isRedirecting, setIsRedirecting] = useState(false);

  const stripeControllerProcessPurchaseMutation =
    useStripeControllerProcessPurchase({
      mutation: {
        onSuccess: (data) => {
          setIsRedirecting(true);
          const url = data.url;
          window.location.href = url;
        },
      },
    });

  return (
    <div className="flex h-screen flex-col items-center justify-center gap-4 px-4">
      <h3 className="text-center text-2xl font-bold">
        You need to buy access to this space group
      </h3>
      <Button
        onClick={() =>
          navigate(`/checkout/brands/${brandId}/space-groups/${spaceGroupId}`)
        }
        disabled={
          stripeControllerProcessPurchaseMutation.isPending || isRedirecting
        }
      >
        {stripeControllerProcessPurchaseMutation.isPending || isRedirecting
          ? 'Loading...'
          : 'Buy access'}
      </Button>
    </div>
  );
}
