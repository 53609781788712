import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import {
  PostSpace,
  getPostsSpacesControllerGetPostsInSpaceQueryKey,
  usePostsSpacesControllerCreatePost,
  useSpaceGroupControllerGetUsersBySpaceGroupIdForMentions,
} from '@/api';
import { UserIcon } from '@/assets/icon/user';
import { AddPostDialog } from '@/components/post-components/add-post-dialog';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Card } from '@/components/ui/card';
import { Form, FormControl, FormField, FormItem } from '@/components/ui/form';
import { useAuth } from '@/hooks/useContext';
import { valueOrUndefined } from '@/lib/utils';

import Editor from './Editor';

import { Button } from '../button';

const formSchema = z.object({
  content: z.string().min(1),
});

type FormValues = z.infer<typeof formSchema>;

const ShareInput = ({
  postsSpace,
  spaceGroupId,
}: {
  postsSpace: PostSpace;
  spaceGroupId: string;
}) => {
  const { user } = useAuth();

  const queryClient = useQueryClient();

  const usersQuery =
    useSpaceGroupControllerGetUsersBySpaceGroupIdForMentions(spaceGroupId);

  const users = usersQuery.data ?? [];

  const createPostMutation = usePostsSpacesControllerCreatePost({
    mutation: {
      onSuccess: () => {
        form.reset();
        queryClient.invalidateQueries({
          queryKey: getPostsSpacesControllerGetPostsInSpaceQueryKey(
            postsSpace.id,
          ),
        });
      },
    },
  });

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      content: '',
    },

    disabled: createPostMutation.isPending,
  });

  const handleSubmit = form.handleSubmit((data) => {
    createPostMutation.mutate({
      postsSpaceId: postsSpace.id,
      data: {
        content: data.content,
      },
    });
  });

  const handleTextChange = ({
    markdown,
  }: {
    markdown: string;
    html: string;
  }) => {
    form.setValue('content', markdown);
  };

  const handleMentionSearch = useCallback(
    (search: string) => {
      if (!search) return [];
      return users
        .filter((user) =>
          user.name?.toLowerCase().includes(search.toLowerCase()),
        )
        .map((user) => ({
          id: user.id ?? '',
          value: user.name ?? '',
          cometChatId: user.cometChatUid ?? '',
          name: user.name ?? '',
          avatarUrl: user.avatarUrl ?? '',
        }));
    },
    [users],
  );

  return (
    <Card className="flex items-start gap-4 rounded-lg p-2">
      <Avatar className="rounded-lg">
        <AvatarImage src={valueOrUndefined(user?.avatarUrl)} />
        <AvatarFallback>
          {user?.name?.slice(0, 2) ?? (
            <UserIcon className="h-full w-full stroke-black p-2 dark:stroke-white" />
          )}
        </AvatarFallback>
      </Avatar>
      <Form {...form}>
        <form
          onSubmit={handleSubmit}
          className="hidden h-full w-full items-center md:flex"
        >
          <FormField
            control={form.control}
            name="content"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormControl>
                  <Editor
                    ref={field.ref}
                    onTextChange={handleTextChange}
                    onSendMessage={handleSubmit}
                    className=""
                    onMentionSearch={handleMentionSearch}
                  />
                </FormControl>
              </FormItem>
            )}
          />
        </form>
      </Form>
      <AddPostDialog
        postsSpace={postsSpace}
        triggerContent={
          <Button className="h-fit w-full justify-start bg-light-2 px-2.5 py-4 dark:bg-dark-2 md:hidden">
            Share something
          </Button>
        }
      />
    </Card>
  );
};

export default ShareInput;
