import { Amplify } from 'aws-amplify';

type AmplifyConfig = Parameters<typeof Amplify.configure>[0];

const awsconfig: AmplifyConfig = {
  Auth: {
    Cognito: {
      loginWith: {
        oauth: {
          redirectSignIn: [
            'http://localhost:5173/oauth',
            'https://app.teachly.ai/oauth',
            'https://teachly-frontend-react-v2.vercel.app/oauth',
            'https://teachly-v2-frontend-development.vercel.app/oauth',
          ],
          redirectSignOut: [
            'http://localhost:5173/',
            'https://app.teachly.ai/',
            'https://teachly-frontend-react-v2.vercel.app/',
            'https://teachly-v2-frontend-development.vercel.app/',
          ],
          domain:
            'creator-teachly-development.auth.us-west-2.amazoncognito.com',
          providers: ['Google'],
          scopes: [
            'phone',
            'email',
            'openid',
            'profile',
            'aws.cognito.signin.user.admin',
          ],
          responseType: 'code',
        },
      },
      userPoolId: 'us-west-2_GytET6jQl',
      userPoolClientId: '46kagb9isoj579tm0pj8a5383',
    },
  },
};

export default awsconfig;
