export const UserIcon = (props) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path d="M24 28.5C29.5228 28.5 34 24.0228 34 18.5C34 12.9772 29.5228 8.5 24 8.5C18.4772 8.5 14 12.9772 14 18.5C14 24.0228 18.4772 28.5 24 28.5ZM24 28.5C15.1634 28.5 8 33.8726 8 40.5M24 28.5C32.8366 28.5 40 33.8726 40 40.5" />
    </g>
  </svg>
);
