import { useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  useBrandsControllerGetSpaceGroupsOfBrand,
  useNotificationsControllerGetNotificationCountersForBrand,
} from '@/api';
import { ArrowRightFilledIcon } from '@/assets/icon/arrowRightFilled';
import { Spaces } from '@/components/layout/spaces';
import { AddNewSpaceOrSpaceGroupDialog } from '@/components/new-space-or-space-group/add-new-space-or-space-group-dialog';
import { SpaceGroupDropdown } from '@/components/space-group-dropdown';
import { useNotificationCounters } from '@/hooks/use-notification-counters';
import { useBrandPermits } from '@/hooks/useBrandPermits';

import { Loading } from '../Loading';

export const SpaceGroups = ({ brandId }: { brandId: string }) => {
  const spaceGroupsQuery = useBrandsControllerGetSpaceGroupsOfBrand(brandId);

  const notificationCountersQuery =
    useNotificationsControllerGetNotificationCountersForBrand(brandId, {
      query: {
        refetchInterval: 30_000,
        refetchIntervalInBackground: true,
      },
    });

  useNotificationCounters(brandId);

  const hasPermits = useBrandPermits();
  const { spaceGroupId } = useParams();

  const [spacesOpened, setSpaceOpened] = useState<string[]>([
    spaceGroupId || '',
  ]);

  if (spaceGroupsQuery.isPending) {
    return <Loading size={6} />;
  }

  if (spaceGroupsQuery.isError) {
    throw spaceGroupsQuery.error;
  }

  const spaceGroups = spaceGroupsQuery.data;

  const handleSelectSpaceGroup = (id: string) => {
    if (spacesOpened.includes(id)) {
      setSpaceOpened(spacesOpened.filter((item) => item !== id));
    } else {
      setSpaceOpened([...spacesOpened, id]);
    }
  };

  return (
    <div className="flex flex-col gap-4 rounded-lg bg-light-2 p-2.5 dark:bg-dark-2">
      {spaceGroups.map((item) => (
        <div key={item.id} className="flex flex-col gap-2">
          <div
            className="flex cursor-pointer items-center justify-center gap-2.5 rounded-lg px-2.5 py-3 transition-colors hover:bg-light-3 hover:dark:bg-dark-3"
            onClick={() => handleSelectSpaceGroup(item.id)}
          >
            <h4 className="w-full overflow-hidden text-ellipsis font-medium text-black dark:text-gray-300">
              {item.name}
            </h4>
            {hasPermits && (
              <div
                onClick={(e) => e.stopPropagation()}
                className="flex items-center"
              >
                <AddNewSpaceOrSpaceGroupDialog spaceGroup={item} />
                <SpaceGroupDropdown spaceGroup={item} />
              </div>
            )}

            <ArrowRightFilledIcon
              className={`min-h-4 min-w-4 fill-black dark:fill-white ${spacesOpened.includes(item.id) ? 'rotate-90' : 'rotate-0'}`}
            />
          </div>
          {spacesOpened.includes(item.id) && (
            <div
              className="flex flex-col gap-1"
              onClick={(e) => e.stopPropagation()}
            >
              <Spaces
                spaceGroup={item}
                notificationCounters={notificationCountersQuery.data}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
