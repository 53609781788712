export const ArrowRightIcon = (props) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M8.78112 8.50047L5.48126 5.20062L6.42408 4.25781L10.6667 8.50047L6.42408 12.7431L5.48126 11.8003L8.78112 8.50047Z" />
    </svg>
  );
};
