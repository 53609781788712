export const PencilEditIcon = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.5 5.50009L17.2929 3.7072C17.6834 3.31667 18.3165 3.31667 18.7071 3.7072L20.2929 5.29298C20.6834 5.68351 20.6834 6.31667 20.2929 6.70719L18.5 8.50009M15.5 5.50009L9.39171 11.6084C9.13539 11.8647 8.95356 12.1859 8.86564 12.5375L8 16.0001L11.4626 15.1345C11.8142 15.0465 12.1354 14.8647 12.3917 14.6084L18.5 8.50009M15.5 5.50009L18.5 8.50009"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M10.5 5H5C3.89543 5 3 5.89543 3 7V19C3 20.1046 3.89543 21 5 21H16.9999C18.1045 21 18.9999 20.1046 18.9999 19V13.5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
