import { useQueryClient } from '@tanstack/react-query';
import { formatDistanceToNowStrict } from 'date-fns';
import { DownloadIcon, FileIcon, Heart } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

import {
  PostWithUserAndRoleDto,
  SpaceGroupWithSpaces,
  getDiscussionControllerGetUserJoinedDiscussionsInPostSpaceQueryKey,
  getPostsSpacesControllerGetPostsInSpaceQueryKey,
  useDiscussionControllerJoinDiscussion,
  usePostsSpacesControllerToggleLikePost,
  useSpaceGroupControllerGetUsersBySpaceGroupIdForMentions,
} from '@/api';
import { PinIcon } from '@/assets/icon/pin';
import { UserIcon } from '@/assets/icon/user';
import ExpandableImage from '@/components/ExpandableImage';
import PostViewer from '@/components/post-components/PostViewer';
import { PostDropdownOptions } from '@/components/post-components/post-dropdown-options';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from '@/components/ui/card';
import { useBrandPermits } from '@/hooks/useBrandPermits';
import { useAuth } from '@/hooks/useContext';
import { cn, onlyFirstLetterCapital } from '@/lib/utils';
import { useMemberInfoDrawerContext } from '@/routes/MemberInfoDrawerProvider';

const PostCard = ({
  post,
  spaceGroup,
  isDiscussionJoined,
}: {
  post: PostWithUserAndRoleDto;
  spaceGroup: SpaceGroupWithSpaces;
  isDiscussionJoined: boolean;
}) => {
  const { user } = useAuth();
  const hasBrandPermission = useBrandPermits();

  const { setUserInfoData } = useMemberInfoDrawerContext();

  const queryClient = useQueryClient();

  const usersQuery = useSpaceGroupControllerGetUsersBySpaceGroupIdForMentions(
    spaceGroup.id,
  );

  const users = usersQuery.data ?? [];

  const toggleLikePostMutation = usePostsSpacesControllerToggleLikePost({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: getPostsSpacesControllerGetPostsInSpaceQueryKey(
            post.postsSpaceId,
          ),
        });
      },
    },
  });

  const handleToggleLikePost = () => {
    toggleLikePostMutation.mutate({
      postId: post.id,
    });
  };

  const navigate = useNavigate();

  const joinDiscussionMutation = useDiscussionControllerJoinDiscussion({
    mutation: {
      onSuccess: (data) => {
        queryClient.removeQueries({
          queryKey:
            getDiscussionControllerGetUserJoinedDiscussionsInPostSpaceQueryKey(
              post.postsSpaceId,
            ),
        });
        navigate(
          `/brands/${spaceGroup.brandId}/space-groups/${spaceGroup.id}/posts-spaces/${post.postsSpaceId}/joined-discussions/${data.discussionId}`,
        );
      },
    },
  });

  const handleOpenDiscussion = () => {
    if (!post.discussion) return;
    navigate(
      `/brands/${spaceGroup.brandId}/space-groups/${spaceGroup.id}/posts-spaces/${post.postsSpaceId}/joined-discussions/${post.discussion.id}`,
    );
  };

  const handleJoinDiscussion = () => {
    if (!post.discussion) return;
    joinDiscussionMutation.mutate({
      discussionId: post.discussion.id,
    });
  };

  const renderFiles = () => {
    const files = post.files;
    if (files.length === 0) {
      return null;
    }

    return (
      <div className="mt-4 flex flex-wrap gap-4">
        {files.map((file) => {
          if (file.type.startsWith('image/')) {
            return (
              <div key={file.name} className="relative w-fit">
                {/* <img src={file.url} alt={file.name} className="h-52" /> */}
                <ExpandableImage
                  src={file.url}
                  alt={file.name}
                  className="max-h-52"
                />
              </div>
            );
          }

          if (file.type.startsWith('video/')) {
            return (
              <div key={file.name} className="relative w-fit">
                <video src={file.url} className="h-52" controls>
                  Your browser does not support the video tag.
                </video>
              </div>
            );
          }

          return (
            <a
              key={file.name}
              href={file.url}
              download={file.name}
              className="h-fit min-w-96 rounded-xl bg-bgGrey p-4 dark:bg-dark-bgGrey"
            >
              <div className="grid grid-cols-[auto_1fr_auto] gap-3">
                <div className="flex items-center gap-2">
                  <FileIcon className="h-4 w-4" />
                </div>
                <div className="flex flex-col">
                  <span className="text-sm text-black dark:text-white">
                    {file.name}
                  </span>
                </div>
                <div className="flex items-center gap-2">
                  <DownloadIcon className="h-4 w-4" />
                </div>
              </div>
            </a>
          );
        })}
      </div>
    );
  };

  const isLikedByMe = post.likes.some((like) => like.userId === user?.id);

  const isMyPost = post.user.id === user?.id;

  const handleMentionClick = (uid: string) => {
    if (spaceGroup.id) {
      setUserInfoData({
        memberId: uid,
        spaceGroupId: spaceGroup.id,
      });
    }
  };

  const findUser = (id: string) => {
    const user = users.find((u) => u.id === id);
    return user?.name ? { username: user.name } : null;
  };

  const getVideoLinksFromMessage = (message: string) => {
    const links: string[] = [];
    const text = message;
    const markdownRegex = /\[([^\]]+)\]\(([^)]+)\)/g;
    const markdownMatches = [...text.matchAll(markdownRegex)];

    const convertToEmbedUrl = (url: string) => {
      let embedUrl = url;

      if (url.includes('youtube.com/watch?v=')) {
        embedUrl = url.replace('watch?v=', 'embed/');
        embedUrl = embedUrl.split('&')[0];
      }

      if (url.includes('loom.com/share/')) {
        embedUrl = url.replace('share', 'embed');
      }

      return embedUrl;
    };

    const isVideoUrl = (url: string) => {
      return (
        url.includes('youtube.com/watch?v=') ||
        url.includes('youtu.be/') ||
        url.includes('.mp4') ||
        url.match(/loom\.com\/share\/[a-f0-9-]+/i) !== null
      );
    };

    markdownMatches.forEach((match) => {
      const url = match[2].replace(/\].*$/, '');
      if (isVideoUrl(url)) {
        const embedUrl = convertToEmbedUrl(url);
        if (!links.includes(embedUrl)) {
          links.push(embedUrl);
        }
      }
    });

    return [...new Set(links)];
  };

  return (
    <Card>
      <CardHeader>
        <div className="grid grid-cols-[auto,1fr,auto] gap-3">
          <Avatar
            onClick={() => handleMentionClick(post.user.id)}
            className="cursor-pointer"
          >
            <AvatarImage src={post.user.avatarUrl} />
            <AvatarFallback>
              {post.user.name?.slice(0, 2).toUpperCase() ?? (
                <UserIcon className="h-full w-full stroke-black p-2 dark:stroke-white" />
              )}
            </AvatarFallback>
          </Avatar>
          <div className="flex flex-col gap-0.5">
            <div className="flex gap-3">
              <h6
                className="cursor-pointer text-base font-medium"
                onClick={() => handleMentionClick(post.user.id)}
              >
                {post.user.name ?? 'Anonymous'}
              </h6>
              {post.user.role && post.user.role !== 'FAN' && (
                <Badge
                  variant="outline"
                  className="h-fit bg-[#65A96E] text-xs font-medium text-white"
                >
                  {onlyFirstLetterCapital(post.user.role)}
                </Badge>
              )}
            </div>
            <span className="text-xxs font-medium text-[#9E9E9E]">
              {formatDistanceToNowStrict(post.createdAt)}
            </span>
          </div>
          <div className="flex items-center gap-2">
            {post.isPinned && (
              <PinIcon className="h-4 w-4 stroke-black opacity-40 dark:stroke-white" />
            )}
            {(isMyPost || hasBrandPermission) && (
              <PostDropdownOptions post={post} spaceGroupId={spaceGroup.id} />
            )}
          </div>
        </div>
      </CardHeader>
      <CardContent className="flex flex-col">
        {post.title && (
          <h6 className="mb-3 whitespace-pre-wrap text-2xl font-medium">
            {post.title}
          </h6>
        )}
        <PostViewer
          value={post.content}
          findUser={findUser}
          onMentionClick={handleMentionClick}
        />
        {post.content &&
          getVideoLinksFromMessage(post.content).map((link, index) => (
            <iframe
              key={index}
              src={link}
              className="aspect-4/3 max-w-xl pt-2"
            />
          ))}
        {renderFiles()}
      </CardContent>
      <CardFooter className="flex gap-10">
        <div className="flex gap-2">
          <Button variant="icon" size="icon" onClick={handleToggleLikePost}>
            <Heart
              className={cn(
                'w-5',
                isLikedByMe && 'fill-red-600 stroke-red-600',
              )}
            />
          </Button>
          <span className="font-medium">{post.likes.length}</span>
        </div>
        {post.discussion && (
          <Button
            onClick={
              isDiscussionJoined ? handleOpenDiscussion : handleJoinDiscussion
            }
          >
            {isDiscussionJoined ? 'Open discussion' : 'Join discussion'}
          </Button>
        )}
      </CardFooter>
    </Card>
  );
};

export default PostCard;
