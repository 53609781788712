import { useNavigate } from 'react-router-dom';

import {
  CourseWithLessons,
  useCoursesSpacesControllerGetCourseProgress,
} from '@/api';
import { DocumentIcon } from '@/assets/icon/document';
import { PencilIcon } from '@/assets/icon/pencil';
import { Button } from '@/components/ui/button';
import { useBrandPermits } from '@/hooks/useBrandPermits';

const CourseCard = ({
  course,
  brandId,
  spaceGroupId,
  coursesSpaceId,
}: {
  course: CourseWithLessons;
  brandId: string;
  spaceGroupId: string;
  coursesSpaceId: string;
}) => {
  const navigate = useNavigate();
  const hasPermits = useBrandPermits();

  const handleEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    navigate(
      `/brands/${brandId}/space-groups/${spaceGroupId}/courses-spaces/${coursesSpaceId}/courses/${course.id}/edit`,
    );
  };

  const handleCourseClick = () => {
    navigate(
      `/brands/${brandId}/space-groups/${spaceGroupId}/courses-spaces/${coursesSpaceId}/courses/${course.id}`,
    );
  };

  const courseProgress = useCoursesSpacesControllerGetCourseProgress(course.id);

  const progress = courseProgress.data?.progress;

  const isCompleted = progress === 100;

  return (
    <div className="relative">
      <div onClick={handleCourseClick}>
        <div className="flex flex-col gap-4 rounded-2xl border border-light bg-light-2 px-2.5 pb-6 pt-2.5 dark:border-dark-light dark:bg-dark-2">
          <div className="h-[200px] w-full overflow-hidden rounded-2xl bg-brand">
            {course.image && (
              <img
                src={course.image}
                alt={course.name}
                className="h-full w-full cursor-pointer object-cover"
                draggable={false}
              />
            )}
          </div>
          <div className="flex flex-col gap-2.5">
            <div className="flex flex-col gap-4">
              <h2 className="cursor-pointer text-base">{course.name}</h2>
              <div className="flex items-center justify-between gap-4">
                <div className="flex items-center gap-1">
                  <DocumentIcon className="stroke-black dark:stroke-white" />
                  <span className="">
                    {course.lessons.length}{' '}
                    {course.lessons.length === 1 ? 'lesson' : 'lessons'}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {hasPermits && (
        <div className="absolute bottom-4 right-0">
          <Button
            variant={'icon'}
            className="flex gap-1 text-brand"
            onClick={handleEdit}
          >
            <PencilIcon className="h-3 w-3 stroke-brand" />
            Edit
          </Button>
        </div>
      )}
      {isCompleted && (
        <div className="absolute left-5 top-5 flex items-center gap-1 rounded-full bg-white px-2 py-1 text-sm font-medium dark:bg-dark-light">
          <div className="h-2 w-2 rounded-full bg-green-500" />
          <span className="text-xs">Completed</span>
        </div>
      )}
    </div>
  );
};

export default CourseCard;
