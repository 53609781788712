import { CometChat } from '@cometchat/chat-sdk-javascript';
import { useQuery } from '@tanstack/react-query';

import { useInboxCountActions } from '@/stores/inboxCount';

export const useGetConversations = () => {
  const { setInboxCount } = useInboxCountActions();

  const getConversations = async () => {
    const limit = 50;
    const conversationRequest = new CometChat.ConversationsRequestBuilder()
      .setConversationType('user')
      .setLimit(limit)
      .build();

    const conversations = await conversationRequest.fetchNext();

    const unreadCount = conversations.reduce((acc, conversation) => {
      return acc + conversation.getUnreadMessageCount();
    }, 0);

    setInboxCount(unreadCount);

    return conversations;
  };

  useQuery({
    queryKey: ['cometchat', 'conversations'],
    queryFn: () => getConversations(),
    // refetch every 30 seconds
    refetchInterval: 30 * 1000,
  });
};
