import { EllipsisVerticalIcon } from 'lucide-react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { SpaceGroupWithSpaces } from '@/api';
import {
  AddMemberDialogContent,
  AddMemberDialogTrigger,
} from '@/components/add-member-dialog';
import {
  DeleteSpaceGroupDialogContent,
  DeleteSpaceGroupDialogTrgger,
} from '@/components/delete-space-group-dialog';
import { EventsSpaceSettingsDialogTrigger } from '@/components/events-space-settings-dialog';
import { Dialog } from '@/components/ui/dialog';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { UpdateSpaceGroupDialog } from '@/components/update-space-group-dialog';
import { useToast } from '@/hooks/use-toast';

const SPACE_GROUP_SETTINGS_DIALOG = {
  id: 'space-group-settings',
  Trigger: EventsSpaceSettingsDialogTrigger,
  Content: UpdateSpaceGroupDialog,
} as const;

const ADD_MEMBER_DIALOG = {
  id: 'add-member',
  Trigger: AddMemberDialogTrigger,
  Content: AddMemberDialogContent,
} as const;

const DELETE_SPACE_DIALOG = {
  id: 'delete-space',
  Trigger: DeleteSpaceGroupDialogTrgger,
  Content: DeleteSpaceGroupDialogContent,
} as const;

const DIALOGS = [
  SPACE_GROUP_SETTINGS_DIALOG,
  ADD_MEMBER_DIALOG,
  DELETE_SPACE_DIALOG,
];

type Dialog = (typeof DIALOGS)[number];

export const SpaceGroupDropdown = ({
  spaceGroup,
}: {
  spaceGroup: SpaceGroupWithSpaces;
}) => {
  const { toast } = useToast();
  const { brandId } = useParams();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedDialogId, setSelectedDialogId] = useState<Dialog['id'] | null>(
    null,
  );

  const closeDialog = () => {
    setIsDialogOpen(false);
    setSelectedDialogId(null);
  };

  return (
    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <DropdownMenu>
        <DropdownMenuTrigger>
          <EllipsisVerticalIcon className="h-4 w-4" />
        </DropdownMenuTrigger>
        <DropdownMenuContent side="bottom" align="start" className="min-w-40">
          <DropdownMenuItem
            className="p-0"
            onSelect={() => {
              setSelectedDialogId(SPACE_GROUP_SETTINGS_DIALOG.id);
            }}
          >
            <SPACE_GROUP_SETTINGS_DIALOG.Trigger />
          </DropdownMenuItem>

          {spaceGroup.paymentLinkUrl && (
            <DropdownMenuItem
              className="p-0"
              onSelect={() => {
                const protocol = window.location.protocol;
                const host = window.location.host;
                navigator.clipboard.writeText(
                  `${protocol}//${host}/checkout/brands/${brandId}/space-groups/${spaceGroup.id}`,
                );
                toast({
                  description: 'Copied!',
                });
              }}
            >
              <div className="w-full cursor-pointer justify-start p-2 font-medium">
                Copy Link
              </div>
            </DropdownMenuItem>
          )}
          <DropdownMenuItem
            className="p-0"
            onSelect={() => {
              setSelectedDialogId(ADD_MEMBER_DIALOG.id);
            }}
          >
            <ADD_MEMBER_DIALOG.Trigger />
          </DropdownMenuItem>
          <DropdownMenuItem
            className="p-0"
            onSelect={() => {
              setSelectedDialogId(DELETE_SPACE_DIALOG.id);
            }}
          >
            <DELETE_SPACE_DIALOG.Trigger />
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      {selectedDialogId === SPACE_GROUP_SETTINGS_DIALOG.id && (
        <SPACE_GROUP_SETTINGS_DIALOG.Content
          key={isDialogOpen.toString()}
          spaceGroup={spaceGroup}
          onClose={closeDialog}
        />
      )}
      {selectedDialogId === ADD_MEMBER_DIALOG.id && (
        <ADD_MEMBER_DIALOG.Content
          key={isDialogOpen.toString()}
          closeDialog={closeDialog}
          spaceGroupId={spaceGroup.id}
        />
      )}
      {selectedDialogId === DELETE_SPACE_DIALOG.id && (
        <DELETE_SPACE_DIALOG.Content
          key={isDialogOpen.toString()}
          brandId={spaceGroup.brandId}
          spaceGroupId={spaceGroup.id}
          closeDialog={closeDialog}
        />
      )}
    </Dialog>
  );
};
