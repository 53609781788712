import { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { useBrandsControllerGetUserRoleInBrand } from '@/api';
import { Loading } from '@/components/Loading';
import { useAuth } from '@/hooks/useContext';

export const BrandRoleCheck = () => {
  const { brandId } = useParams();
  const roleQuery = useBrandsControllerGetUserRoleInBrand(brandId ?? '');

  const { setBrandRole } = useAuth();

  const role = roleQuery.data?.role ?? null;

  useEffect(() => {
    setBrandRole(role);
  }, [role]);

  if (roleQuery.isLoading) {
    return <Loading />;
  }

  return <Outlet />;
};
