import { Navigate, useParams } from 'react-router-dom';

import { useStripeControllerProcessPurchase } from '@/api';
import { Button } from '@/components/ui/button';
import { useAuth } from '@/hooks/useContext';

export function CancelPaymentPage() {
  const { user } = useAuth();
  const { spaceGroupId } = useParams();

  const stripeControllerProcessPurchaseMutation =
    useStripeControllerProcessPurchase({
      mutation: {
        onSuccess: (data) => {
          const url = data.url;
          window.location.href = url;
        },
      },
    });

  if (!spaceGroupId) {
    return <Navigate to="/404" />;
  }

  if (!user) {
    throw new Error(`No user is logged in`);
  }

  const goToStripe = () => {
    stripeControllerProcessPurchaseMutation.mutateAsync({
      data: {
        spaceGroupId: spaceGroupId ?? '',
        fanId: user?.id,
      },
    });
  };

  return (
    <div className="flex h-screen flex-col items-center justify-center gap-4">
      <h1 className="text-2xl font-bold">
        To enter the space group, you must pay
      </h1>
      <Button onClick={goToStripe}>Go to Stripe</Button>
    </div>
  );
}
