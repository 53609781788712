import { DependencyList, useEffect } from 'react';
import { createContext, useContext, useState } from 'react';
import { ReactNode } from 'react';

type ConfigHeaderState = {
  showNotifications: boolean;
  action: ReactNode;
  label: ReactNode;
  actions: {
    setShowNotifications: (showNotifications: boolean) => void;
    setAction: (action: ReactNode) => void;
    setLabel: (label: ReactNode) => void;
    setHidden: (hidden: boolean) => void;
  };
  hidden?: boolean;
};

const initialState: ConfigHeaderState = {
  showNotifications: true,
  action: null,
  label: null,
  actions: {
    setShowNotifications: () => null,
    setAction: () => null,
    setLabel: () => null,
    setHidden: () => null,
  },
  hidden: false,
};

const ConfigHeaderContext = createContext(initialState);

export function ConfigHeaderProvider({ children }: { children: ReactNode }) {
  const [showNotifications, setShowNotifications] = useState(true);
  const [action, setAction] = useState<ReactNode>(null);
  const [label, setLabel] = useState<ReactNode>(null);
  const [hidden, setHidden] = useState<boolean>(false);

  const value = {
    showNotifications,
    action,
    label,
    actions: { setShowNotifications, setAction, setLabel, setHidden },
    hidden,
  };

  return (
    <ConfigHeaderContext.Provider value={value}>
      {children}
    </ConfigHeaderContext.Provider>
  );
}

export const useUpdateConfigHeader = (
  state: Partial<Omit<ConfigHeaderState, 'actions'>>,
  deps: DependencyList = [],
) => {
  const context = useConfigHeader();

  const { setAction, setLabel, setShowNotifications, setHidden } =
    context.actions;
  useEffect(() => {
    if (state.showNotifications !== undefined) {
      setShowNotifications(state.showNotifications);
    }
    if (state.action !== undefined) {
      setAction(state.action);
    }

    if (state.label !== undefined) {
      setLabel(state.label);
    }

    setHidden(state.hidden || false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setAction, setLabel, setShowNotifications, setHidden, ...deps]);
};

export const useConfigHeader = () => {
  const context = useContext(ConfigHeaderContext);

  if (context === undefined)
    throw new Error(
      'useConfigHeader must be used within a ConfigHeaderProvider',
    );

  return context;
};
