import { XIcon } from 'lucide-react';
import Quill from 'quill';
import { useRef } from 'react';

import { ImageIcon } from '@/assets/icon/image';
import { VideoIcon } from '@/assets/icon/video';
import { Button } from '@/components/ui/button';

import Editor from './Editor';

const ChatInput = ({
  message,
  setMessage,
  onSendMessage,
  isLoading,
  fileInput,
  setFileInput,
  onMentionSearch,
}: {
  message: string;
  setMessage: (message: string) => void;
  onSendMessage: () => Promise<void>;
  isLoading: boolean;
  fileInput: File | null;
  setFileInput: (file: File | null) => void;
  onMentionSearch?: (search: string) => {
    id: string;
    value: string;
    cometChatId: string;
    name: string;
    avatarUrl: string;
  }[];
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const videoInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setFileInput(file);
    }
  };

  const handleRemoveFile = () => {
    setFileInput(null);
  };

  const getImageUrl = (file: File) => {
    return URL.createObjectURL(file);
  };

  const quillRef = useRef<Quill | null>(null);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await onSendMessage();

    setMessage('');
    // defaultValueRef.current = '';

    quillRef.current?.setSelection(0, 0);
    // @ts-expect-error
    quillRef.current?.setContents('');
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="sticky bottom-0 flex w-full flex-col gap-6 rounded-2xl border border-light bg-white px-4 py-3 dark:border-dark-light dark:bg-dark-1"
    >
      <div className="flex w-full flex-row">
        <div className="break-word flex w-full flex-grow-0 flex-col gap-3">
          <Editor
            ref={quillRef}
            defaultValue={message}
            onSelectionChange={() => {}}
            onTextChange={(value) => {
              setMessage(value.markdown);
            }}
            onSendMessage={onSendMessage}
            onMentionSearch={onMentionSearch}
            disabled={isLoading}
          />

          {fileInput && (
            <div className="relative w-fit">
              {fileInput.type.startsWith('image/') ? (
                <img
                  src={getImageUrl(fileInput)}
                  alt="file"
                  className="h-20 w-20 rounded-lg"
                />
              ) : fileInput.type.startsWith('video/') ? (
                <video
                  src={getImageUrl(fileInput)}
                  className="h-20 w-20 rounded-lg"
                  controls
                />
              ) : null}
              <Button
                type="button"
                className="absolute -bottom-2 -right-2 rounded-full bg-red-500 p-1"
                variant={'icon'}
                size={'icon'}
                onClick={handleRemoveFile}
              >
                <XIcon className="h-3 w-3 fill-white text-white" />
              </Button>
            </div>
          )}
          <div className="flex w-fit gap-2.5">
            <input
              type="file"
              className="hidden"
              ref={fileInputRef}
              onChange={handleFileChange}
              accept="image/*"
            />
            <input
              type="file"
              className="hidden"
              ref={videoInputRef}
              onChange={handleFileChange}
              accept="video/*"
            />
            <Button
              type="button"
              className="min-h-2.5 min-w-2.5 rounded-sm p-2 hover:bg-primary-50 dark:hover:bg-dark-primary-50"
              variant={'icon'}
              size={'icon'}
              onClick={() => fileInputRef.current?.click()}
              disabled={isLoading || !!fileInput}
            >
              <ImageIcon className="h-4 w-4 fill-textParagraph" />
            </Button>
            <Button
              type="button"
              className="min-h-2.5 min-w-2.5 rounded-sm p-2 hover:bg-primary-50 dark:hover:bg-dark-primary-50"
              variant={'icon'}
              size={'icon'}
              onClick={() => videoInputRef.current?.click()}
              disabled={isLoading || !!fileInput}
            >
              <VideoIcon className="h-4 w-4 fill-textParagraph" />
            </Button>
          </div>
        </div>
        <div className="flex w-fit justify-between">
          <Button
            type="submit"
            className="rounded-lg px-6"
            disabled={isLoading}
          >
            {isLoading ? 'Sending...' : 'Send'}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default ChatInput;
