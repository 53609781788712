import { memo } from 'react';

import { AllEmojisList } from '@/utils/Emojis';

type Props = {
  isReactionsVisible: boolean;
  handleReact: (e: React.MouseEvent<HTMLDivElement>, emoji: string) => void;
};

const EmojiSelector = ({ isReactionsVisible, handleReact }: Props) => {
  return (
    <div
      className={`${
        isReactionsVisible ? 'flex' : 'hidden'
      } hide-scrollbar absolute right-2.5 top-10 z-10 grid max-h-40 w-fit grid-cols-4 gap-2.5 overflow-y-auto rounded-lg border border-light bg-white p-2 dark:border-dark-light dark:bg-dark-1`}
    >
      {AllEmojisList.map((emoji, i) => (
        <div
          key={i}
          className="flex cursor-pointer items-center justify-center rounded p-2.5 hover:bg-primary-50 dark:hover:bg-dark-primary-50"
          onClick={(e) => handleReact(e, emoji)}
        >
          {emoji}
        </div>
      ))}
    </div>
  );
};

export default memo(EmojiSelector);
