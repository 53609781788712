export const CalendarIcon = (props) => (
  <svg
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.00004 0.166016V1.49935H9.00004V0.166016H10.3334V1.49935H13C13.3682 1.49935 13.6667 1.79783 13.6667 2.16602V12.8327C13.6667 13.2009 13.3682 13.4993 13 13.4993H1.00004C0.631854 13.4993 0.333374 13.2009 0.333374 12.8327V2.16602C0.333374 1.79783 0.631854 1.49935 1.00004 1.49935H3.66671V0.166016H5.00004ZM12.3334 6.83268H1.66671V12.166H12.3334V6.83268ZM3.66671 2.83268H1.66671V5.49935H12.3334V2.83268H10.3334V4.16602H9.00004V2.83268H5.00004V4.16602H3.66671V2.83268Z"
      //   fill="#10151D"
    />
  </svg>
);
