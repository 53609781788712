export const ChatBubbleIcon = (props) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.99867 15.1673C11.6806 15.1673 14.6653 12.1825 14.6653 8.50065C14.6653 4.81875 11.6806 1.83398 7.99867 1.83398C4.3168 1.83398 1.33203 4.81875 1.33203 8.50065C1.33203 9.55125 1.60012 10.4615 2.04148 11.3171C2.21456 11.6526 2.2701 12.0404 2.16162 12.4021L1.6878 13.9815C1.53524 14.49 2.00938 14.9641 2.51791 14.8116L4.09732 14.3377C4.45894 14.2293 4.84674 14.2848 5.18227 14.4579C6.0379 14.8993 6.94814 15.1673 7.99867 15.1673Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
