export const CompassIcon = (props) => (
  <svg
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.00033 17.3327C4.39795 17.3327 0.666992 13.6017 0.666992 8.99935C0.666992 4.39697 4.39795 0.666016 9.00033 0.666016C13.6027 0.666016 17.3337 4.39697 17.3337 8.99935C17.3337 13.6017 13.6027 17.3327 9.00033 17.3327ZM9.00033 15.666C12.6822 15.666 15.667 12.6813 15.667 8.99935C15.667 5.31745 12.6822 2.33268 9.00033 2.33268C5.31843 2.33268 2.33366 5.31745 2.33366 8.99935C2.33366 12.6813 5.31843 15.666 9.00033 15.666ZM11.917 6.08268L10.2503 10.2493L6.08366 11.916L7.75033 7.74935L11.917 6.08268Z"
      //   fill="black"
      //   fillOpacity={0.4}
    />
  </svg>
);
