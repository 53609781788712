import { MembersSpacesControllerGetMembersSuscriptions200Item } from '@/api';
import { ArrowRightIcon } from '@/assets/icon/arrowRight';

type Props = {
  onClick?: () => void;
  onlyView?: boolean;
  subscription: MembersSpacesControllerGetMembersSuscriptions200Item;
};

const SubscriptionCard = ({ subscription, onClick, onlyView }: Props) => {
  const isActive = subscription.subscription?.status === 'active';
  return (
    <div className="flex w-full flex-col gap-10 rounded-2xl border border-light p-4 dark:border-dark-light">
      <div className="flex w-full flex-col-reverse gap-4 sm:flex-row">
        <div className="flex w-full gap-4">
          {subscription.brand?.logoUrl && (
            <img
              src={subscription.brand?.logoUrl}
              alt="Sub Img"
              className="h-12 min-w-12 rounded-md"
            />
          )}
          <div className="flex w-full flex-col gap-1">
            <h3 className="text-lg font-medium text-black dark:text-white">
              {subscription.brand?.name} - {subscription.spaceGroup?.name}
            </h3>
            <p className="text-sm font-medium text-textParagraph dark:text-dark-textParagraph">{`$${subscription.spaceGroup?.price} USD / ${subscription.spaceGroup?.billingFrequency?.toLocaleLowerCase()}`}</p>
          </div>
        </div>
        <div
          className={`h-fit w-fit rounded-sm px-2 py-[6px] text-xxs font-medium uppercase leading-3 ${isActive && 'bg-green-50 text-black'}`}
        >
          {isActive ? 'Subscribed' : 'UnSubscribed'}
        </div>
      </div>
      {!onlyView && isActive && (
        <div
          onClick={onClick}
          className="flex cursor-pointer justify-between border-t border-light py-2.5 text-black dark:border-dark-light dark:text-white"
        >
          Manage Subscription
          <ArrowRightIcon className="h-4 min-w-4 fill-white" />
        </div>
      )}
    </div>
  );
};

export default SubscriptionCard;
