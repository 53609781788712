import { format, isSameDay } from 'date-fns';
import { Clock } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  GetMeetingAvailabilityResponseDto,
  useMonetizationControllerBookFreeMeeting,
  useMonetizationControllerBookMeeting,
  useMonetizationControllerGetMeetingAvailability,
} from '@/api';
import { AlertCircleIcon } from '@/assets/icon/alertCircle';
import { ArrowLeftAltIcon } from '@/assets/icon/arrowLeftAlt';
import { UserIcon } from '@/assets/icon/user';
import { Loading } from '@/components/Loading';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import { useToast } from '@/hooks/use-toast';
import { cn, valueOrUndefined } from '@/lib/utils';

export const BookMeetingPage = () => {
  const { creatorId } = useParams();

  const meetingAvailabilityQuery =
    useMonetizationControllerGetMeetingAvailability(creatorId ?? '');

  if (meetingAvailabilityQuery.isPending) {
    return <Loading />;
  }

  const meetingAvailability = meetingAvailabilityQuery.data;

  if (!meetingAvailability) {
    return <div>Meeting availability not found</div>;
  }

  return <BookMeetingInner meetingAvailability={meetingAvailability} />;
};

const isValidTimeSlot = (slotTime: Date) => {
  const now = new Date();
  const isTwoHoursFromNow =
    slotTime.getTime() > now.getTime() + 2 * 60 * 60 * 1000;
  const isToday = isSameDay(slotTime, now);
  return !isToday || (isToday && isTwoHoursFromNow);
};

const BookMeetingInner = ({
  meetingAvailability,
}: {
  meetingAvailability: GetMeetingAvailabilityResponseDto;
}) => {
  const navigate = useNavigate();
  const { creatorId } = useParams();
  const { toast } = useToast();

  // default values for selected date, duration, and time should be the first free call slot, if there is one
  // else, the first slot of the first available day

  const availableSlots = meetingAvailability.availableSlots;
  const creator = meetingAvailability.creator;

  const freeCallsAvailableSlot = availableSlots.find(
    (slot) =>
      slot.isFree && slot.freeCallsAvailable && slot.freeCallsAvailable > 0,
  );

  // const firstFreeCallSlot = freeCallsAvailableSlot?.availableDays
  //   .find((day) => day.slots.some((slot) => slot.canBook))
  //   ?.slots.find((slot) => slot.canBook)?.startTime;

  const freeCallsAvailable = freeCallsAvailableSlot?.freeCallsAvailable;

  const hasFreeCalls =
    freeCallsAvailable !== undefined && freeCallsAvailable > 0;

  const firstPaidAvailableSlot = availableSlots.find(
    (slot) =>
      !slot.isFree &&
      slot.availableDays.some((day) => day.slots.some((slot) => slot.canBook)),
  );

  const firstAvailableSlot = freeCallsAvailableSlot ?? firstPaidAvailableSlot;

  const [selectedDuration, setSelectedDuration] = useState<number | null>(
    firstAvailableSlot?.duration ?? null,
  );

  const [selectedDate, setSelectedDate] = useState<Date | undefined>(() => {
    if (freeCallsAvailableSlot) {
      const firstDayWithAvailableSlots =
        freeCallsAvailableSlot.availableDays.find((day) =>
          day.slots.some((slot) => slot.canBook),
        );

      return firstDayWithAvailableSlots?.day
        ? new Date(firstDayWithAvailableSlots.day)
        : undefined;
    }

    const firstDayWithAvailableSlots = firstAvailableSlot?.availableDays.find(
      (day) => day.slots.some((slot) => slot.canBook),
    );

    return firstDayWithAvailableSlots?.day
      ? new Date(firstDayWithAvailableSlots.day)
      : undefined;
  });

  const [selectedTime, setSelectedTime] = useState<Date | null>(() => {
    // Find first valid day and time
    for (const day of firstAvailableSlot?.availableDays || []) {
      for (const slot of day.slots) {
        const slotTime = new Date(slot.startTime);
        if (slot.canBook && isValidTimeSlot(slotTime)) {
          return slotTime;
        }
      }
    }
    return null;
  });

  const selectedSlotDuration = availableSlots.find(
    (slot) => slot.duration === selectedDuration,
  );

  const allowedDaysWithAvailableSlots = meetingAvailability.availableSlots
    .flatMap((slot) => slot.availableDays)
    .filter((day) => day.slots.some((slot) => slot.canBook))
    .map((day) => day.day);

  const selectedSlotDay = selectedSlotDuration?.availableDays.find(
    (day) =>
      new Date(day.day).toISOString().split('T')[0] ===
      selectedDate?.toISOString().split('T')[0],
  );

  const bookFreeMeetingMutation = useMonetizationControllerBookFreeMeeting();
  const bookMeetingMutation = useMonetizationControllerBookMeeting();

  const handleNavigate = (clientSecret: string) => {
    navigate(`/checkout/booking/${creatorId}`, {
      state: {
        creator,
        selectedDate,
        selectedTime,
        selectedDuration,
        selectedSlotDuration,
        clientSecret,
      },
    });
  };

  const handleBookNow = async () => {
    if (!selectedDate || !selectedTime || !selectedDuration) return;

    if (hasFreeCalls && selectedSlotDuration?.isFree) {
      await bookFreeMeetingMutation
        .mutateAsync({
          data: {
            creatorId: creator.id,
            startTime: selectedTime.toISOString(),
          },
        })
        .then((res) => {
          if (res.meetingId) {
            navigate('/');
            toast({
              title: 'Booking complete',
              variant: 'success',
            });
          }
        })
        .catch((error) => {
          toast({
            title: error.message,
            variant: 'destructive',
          });
        });
    }

    if (
      !hasFreeCalls &&
      selectedSlotDuration?.price &&
      selectedSlotDuration.priceTierId
    ) {
      const response = await bookMeetingMutation
        .mutateAsync({
          data: {
            creatorId: creator.id,
            startTime: selectedTime.toISOString(),
            priceTierId: selectedSlotDuration.priceTierId,
          },
        })
        .then((res) => {
          handleNavigate(res.clientSecret);
        });

      console.log('response', response);
    }
  };

  useEffect(() => {
    const validSlot = selectedSlotDay?.slots.find(
      (slot) => slot.canBook && isValidTimeSlot(new Date(slot.startTime)),
    )?.startTime;

    setSelectedTime(validSlot ? new Date(validSlot) : null);
  }, [selectedSlotDay]);

  return (
    <div className="flex h-full w-full flex-col md:flex-row">
      <div className="flex w-full justify-center">
        <div className="flex w-full max-w-125 flex-col gap-10 px-6 py-10 md:gap-20 md:px-10">
          <Button
            variant="ghost"
            className="flex w-fit gap-2 p-0 text-base font-semibold hover:bg-transparent"
            onClick={() => navigate('/')}
          >
            <ArrowLeftAltIcon className="h-6 w-6 stroke-black dark:stroke-white" />
            Back
          </Button>

          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-4">
              <div className="flex items-center gap-2.5">
                <Avatar className="h-12 w-12">
                  <AvatarImage src={valueOrUndefined(creator?.avatarUrl)} />
                  <AvatarFallback>
                    <UserIcon className="stroke-textParagraph p-1 dark:stroke-white" />
                  </AvatarFallback>
                </Avatar>
                <div>
                  <h1 className="text-base font-medium">
                    {creator?.name ?? 'Creator'}
                  </h1>
                </div>
              </div>
              <p className="text-2xl font-semibold">1.1 Coaching call</p>
              <p className="text-gray-500">
                Book a private coaching session with me!
              </p>
            </div>

            <div className="flex items-center gap-6">
              <div className="flex items-center gap-2">
                <Clock className="h-4 w-4 text-gray-500" />
                <span>
                  {selectedDuration ? `${selectedDuration} mins` : '15 mins'}
                </span>
              </div>
            </div>
          </div>

          <Calendar
            mode="single"
            selected={selectedDate}
            required
            onSelect={(date) => {
              if (!date) return;
              setSelectedDate(date);
            }}
            className="w-full rounded-md"
            disabled={(date) => {
              return !allowedDaysWithAvailableSlots.some((day) =>
                isSameDay(day, date),
              );
            }}
            classNames={{
              months: 'w-full',
              head_row: 'flex border-b pb-1 items-center justify-between',
              row: 'flex w-full items-center justify-between pt-1',
            }}
          />
        </div>
      </div>

      <div className="flex w-full justify-center md:border-l">
        <div className="flex w-full max-w-125 flex-col gap-10 px-6 py-10 md:px-10">
          <div className="flex items-center justify-between text-base font-medium">
            <h2 className="flex items-center gap-1 font-medium text-light-gray dark:text-dark-light-gray">
              <AlertCircleIcon className="h-5 w-5 flex-shrink-0 stroke-light-gray dark:stroke-dark-light-gray" />{' '}
              Available Included Calls
            </h2>
            <span className="text-light-gray dark:text-dark-light-gray">
              {freeCallsAvailable ?? 0}
            </span>
          </div>

          <div className="flex flex-col gap-4">
            <h2 className="text-base font-medium">Select duration</h2>
            <div className="grid grid-cols-3 gap-4">
              {availableSlots.map((slot) => (
                <Button
                  key={slot.duration}
                  onClick={() => {
                    setSelectedDuration(slot.duration);
                    setSelectedTime(
                      new Date(slot.availableDays[0].slots[0].startTime),
                    );
                  }}
                  className={cn(
                    'w-full',
                    selectedDuration === slot.duration
                      ? 'bg-primary text-primary-foreground'
                      : 'bg-light-3 text-textParagraph dark:bg-dark-3 dark:text-dark-textParagraph',
                  )}
                  disabled={
                    (hasFreeCalls && !slot.isFree) ||
                    (!hasFreeCalls && !slot.price)
                  }
                >
                  {slot.duration}
                  {slot.durationType === 'HOURS' ? 'hours' : 'mins'}
                </Button>
              ))}
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <h2 className="text-base font-medium">Available slot</h2>
            <div className="grid grid-cols-3 gap-4">
              {selectedSlotDay?.slots.length ? (
                selectedSlotDay?.slots.map((slot) => {
                  const slotTime = new Date(slot.startTime);
                  const now = new Date();
                  const isTwoHoursFromNow =
                    slotTime.getTime() > now.getTime() + 2 * 60 * 60 * 1000;
                  const isToday = isSameDay(slotTime, now);
                  const canBookTime =
                    !isToday || (isToday && isTwoHoursFromNow);

                  return (
                    <Button
                      key={slot.startTime.toString()}
                      onClick={() => setSelectedTime(new Date(slot.startTime))}
                      disabled={!slot.canBook || !canBookTime}
                      className={cn(
                        'w-full',
                        format(selectedTime ?? new Date(), 'h:mm a') ===
                          format(new Date(slot.startTime), 'h:mm a')
                          ? 'bg-primary text-primary-foreground'
                          : 'bg-light-3 text-textParagraph dark:bg-dark-3 dark:text-dark-textParagraph',
                      )}
                    >
                      {format(new Date(slot.startTime), 'h:mm a')}
                    </Button>
                  );
                })
              ) : (
                <div className="text-nowrap text-textParagraph dark:text-dark-textParagraph">
                  No slots available
                </div>
              )}
            </div>
          </div>

          <div className="flex w-full gap-2.5">
            <div className="flex w-full flex-col gap-1">
              <span className="text-sm font-medium text-textParagraph dark:text-dark-textParagraph">
                Price
              </span>
              <span className="text-xl font-semibold">
                {hasFreeCalls && selectedSlotDuration?.isFree
                  ? 'FREE'
                  : `$${selectedSlotDuration?.price}`}
              </span>
            </div>
            <Button
              className="w-fit bg-primary text-primary-foreground hover:bg-primary/90"
              disabled={
                !selectedDate ||
                !selectedTime ||
                !selectedDuration ||
                bookFreeMeetingMutation.isPending
              }
              onClick={handleBookNow}
            >
              Book Now
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
