export const AlertCircleIcon = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.0001 18.3346C14.6025 18.3346 18.3334 14.6037 18.3334 10.0013C18.3334 5.39893 14.6025 1.66797 10.0001 1.66797C5.39771 1.66797 1.66675 5.39893 1.66675 10.0013C1.66675 14.6037 5.39771 18.3346 10.0001 18.3346Z"
      stroke-opacity="0.4"
      stroke-width="1.5"
      stroke-linejoin="round"
    />
    <path
      d="M10 7.08203V10.4154"
      stroke-opacity="0.4"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10 12.918H10.0075"
      stroke-opacity="0.4"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
