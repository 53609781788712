import { zodResolver } from '@hookform/resolvers/zod';
import { Dialog } from '@radix-ui/react-dialog';
import { XIcon } from 'lucide-react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { useInvitationsControllerCreateSpaceGroupInvite } from '@/api';
import { Button } from '@/components/ui/button';
import {
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormInput,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Switch } from '@/components/ui/switch';
import { cn } from '@/lib/utils';

export const AddMemberDialogFull = ({
  spaceGroupId,
}: {
  spaceGroupId: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const closeDialog = () => {
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger>
        <Button>Add Member</Button>
      </DialogTrigger>
      <AddMemberDialogContent
        closeDialog={closeDialog}
        spaceGroupId={spaceGroupId}
      />
    </Dialog>
  );
};

export const AddMemberDialogTrigger = () => {
  return (
    <DialogTrigger asChild className="w-full justify-start p-2 font-medium">
      <Button variant="none" size="none" className="">
        <span>Invite member</span>
      </Button>
    </DialogTrigger>
  );
};

const formSchema = z.object({
  email: z.string().email({
    message: 'Must be a valid email address',
  }),
  bypassCheckout: z.boolean(),
});

type FormSchema = z.infer<typeof formSchema>;

export const AddMemberDialogContent = ({
  closeDialog,
  spaceGroupId,
}: {
  closeDialog: () => void;
  spaceGroupId: string;
}) => {
  const form = useForm<FormSchema>({
    mode: 'onBlur',
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
      bypassCheckout: false,
    },
  });

  const inviteUserMutation = useInvitationsControllerCreateSpaceGroupInvite({
    mutation: {
      onSuccess: () => {
        clearFormAndCloseDialog();
      },
    },
  });

  const clearFormAndCloseDialog = () => {
    form.reset();
    closeDialog();
  };

  const handleSubmit = form.handleSubmit((data) => {
    inviteUserMutation.mutate({
      data: {
        spaceGroupId,
        email: data.email,
        bypassCheckout: data.bypassCheckout,
      },
    });
  });

  return (
    <DialogContent className="flex w-[90%] max-w-3xl flex-col gap-9 rounded-3xl md:w-3/4 md:p-12 lg:w-1/2">
      <DialogHeader className="flex-row items-center justify-between space-y-0">
        <DialogTitle className="text-2xl font-semibold">Add member</DialogTitle>
        <Button
          onClick={clearFormAndCloseDialog}
          variant="icon"
          size="icon"
          className="m-0 rounded-sm p-0 ring-offset-background transition-opacity focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
        >
          <XIcon className="h-4 w-4" />
          <span className="sr-only">Close</span>
        </Button>
      </DialogHeader>
      <div className="font-medium text-[#9E9E9E] dark:text-[#AEAEAE]">
        <Form {...form}>
          <form onSubmit={handleSubmit} className="space-y-8">
            <FormField
              control={form.control}
              name="email"
              render={({ field, fieldState }) => (
                <FormItem className="space-y-1">
                  <FormLabel
                    className={cn(
                      'text-base font-medium text-black dark:text-white',
                      fieldState.error &&
                        'text-destructive dark:text-destructive',
                    )}
                  >
                    Email address
                  </FormLabel>
                  <FormControl>
                    <FormInput
                      type="email"
                      placeholder="Enter email address"
                      className={cn(
                        'p-3 text-base font-normal text-black shadow-sm placeholder:text-[#9E9E9E] dark:text-white dark:placeholder:text-[#AEAEAE]',
                      )}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="bypassCheckout"
              render={({ field }) => (
                <FormItem className="flex flex-row items-center justify-between">
                  <FormLabel className="text-base font-medium text-black dark:text-white">
                    Bypass checkout
                  </FormLabel>
                  <FormControl>
                    <Switch
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                </FormItem>
              )}
            />

            <Button
              type="submit"
              className="w-full rounded-lg"
              disabled={inviteUserMutation.isPending}
            >
              {inviteUserMutation.isPending ? 'Sending...' : 'Send invite'}
            </Button>
          </form>
        </Form>
      </div>
    </DialogContent>
  );
};
