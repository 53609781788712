interface DividerProps {
  text?: string;
}

const Divider: React.FC<DividerProps> = ({ text }) => {
  return (
    <div className="flex w-full items-center gap-4 text-grey">
      {text ? (
        <div className="flex w-full items-center gap-4">
          <hr className="w-full border-t border-grey" />
          {text}
          <hr className="w-full border-t border-grey" />
        </div>
      ) : (
        <hr className="w-full border-t border-grey" />
      )}
    </div>
  );
};

export default Divider;
