export const countryList = [
  {
    name: 'Panama',
    idd: '+507',
    flag: 'https://flagcdn.com/pa.svg',
  },
  {
    name: 'Australia',
    idd: '+61',
    flag: 'https://flagcdn.com/au.svg',
  },
  {
    name: 'Wallis and Futuna',
    idd: '+681',
    flag: 'https://flagcdn.com/wf.svg',
  },
  {
    name: 'Iceland',
    idd: '+354',
    flag: 'https://flagcdn.com/is.svg',
  },
  {
    name: 'Luxembourg',
    idd: '+352',
    flag: 'https://flagcdn.com/lu.svg',
  },
  {
    name: 'Mali',
    idd: '+223',
    flag: 'https://flagcdn.com/ml.svg',
  },
  {
    name: 'Comoros',
    idd: '+269',
    flag: 'https://flagcdn.com/km.svg',
  },
  {
    name: 'Estonia',
    idd: '+372',
    flag: 'https://flagcdn.com/ee.svg',
  },
  {
    name: 'Angola',
    idd: '+244',
    flag: 'https://flagcdn.com/ao.svg',
  },
  {
    name: 'Canada',
    idd: '+1',
    flag: 'https://flagcdn.com/ca.svg',
  },
  {
    name: 'Belarus',
    idd: '+375',
    flag: 'https://flagcdn.com/by.svg',
  },
  {
    name: 'Guyana',
    idd: '+592',
    flag: 'https://flagcdn.com/gy.svg',
  },
  {
    name: 'Gambia',
    idd: '+220',
    flag: 'https://flagcdn.com/gm.svg',
  },
  {
    name: 'Tunisia',
    idd: '+216',
    flag: 'https://flagcdn.com/tn.svg',
  },
  {
    name: 'Cameroon',
    idd: '+237',
    flag: 'https://flagcdn.com/cm.svg',
  },
  {
    name: 'Rwanda',
    idd: '+250',
    flag: 'https://flagcdn.com/rw.svg',
  },
  {
    name: 'Cambodia',
    idd: '+855',
    flag: 'https://flagcdn.com/kh.svg',
  },
  {
    name: 'Benin',
    idd: '+229',
    flag: 'https://flagcdn.com/bj.svg',
  },
  {
    name: 'Greece',
    idd: '+30',
    flag: 'https://flagcdn.com/gr.svg',
  },
  {
    name: 'South Korea',
    idd: '+82',
    flag: 'https://flagcdn.com/kr.svg',
  },
  {
    name: 'Mauritius',
    idd: '+230',
    flag: 'https://flagcdn.com/mu.svg',
  },
  {
    name: 'United States Virgin Islands',
    idd: '+1340',
    flag: 'https://flagcdn.com/vi.svg',
  },
  {
    name: 'Åland Islands',
    idd: '+358',
    flag: 'https://flagcdn.com/ax.svg',
  },
  {
    name: 'San Marino',
    idd: '+378',
    flag: 'https://flagcdn.com/sm.svg',
  },
  {
    name: 'Maldives',
    idd: '+960',
    flag: 'https://flagcdn.com/mv.svg',
  },
  {
    name: 'Vanuatu',
    idd: '+678',
    flag: 'https://flagcdn.com/vu.svg',
  },
  {
    name: 'Malawi',
    idd: '+265',
    flag: 'https://flagcdn.com/mw.svg',
  },
  {
    name: 'Egypt',
    idd: '+20',
    flag: 'https://flagcdn.com/eg.svg',
  },
  {
    name: 'Senegal',
    idd: '+221',
    flag: 'https://flagcdn.com/sn.svg',
  },
  {
    name: 'Georgia',
    idd: '+995',
    flag: 'https://flagcdn.com/ge.svg',
  },
  {
    name: 'New Zealand',
    idd: '+64',
    flag: 'https://flagcdn.com/nz.svg',
  },
  {
    name: 'Cape Verde',
    idd: '+238',
    flag: 'https://flagcdn.com/cv.svg',
  },
  {
    name: 'Italy',
    idd: '+39',
    flag: 'https://flagcdn.com/it.svg',
  },
  {
    name: 'Monaco',
    idd: '+377',
    flag: 'https://flagcdn.com/mc.svg',
  },
  {
    name: 'Slovakia',
    idd: '+421',
    flag: 'https://flagcdn.com/sk.svg',
  },
  {
    name: 'Uruguay',
    idd: '+598',
    flag: 'https://flagcdn.com/uy.svg',
  },
  {
    name: 'Laos',
    idd: '+856',
    flag: 'https://flagcdn.com/la.svg',
  },
  {
    name: 'Faroe Islands',
    idd: '+298',
    flag: 'https://flagcdn.com/fo.svg',
  },
  {
    name: 'Niue',
    idd: '+683',
    flag: 'https://flagcdn.com/nu.svg',
  },
  {
    name: 'North Macedonia',
    idd: '+389',
    flag: 'https://flagcdn.com/mk.svg',
  },
  {
    name: 'Chile',
    idd: '+56',
    flag: 'https://flagcdn.com/cl.svg',
  },
  {
    name: 'Cyprus',
    idd: '+357',
    flag: 'https://flagcdn.com/cy.svg',
  },
  {
    name: 'Macau',
    idd: '+853',
    flag: 'https://flagcdn.com/mo.svg',
  },
  {
    name: 'El Salvador',
    idd: '+503',
    flag: 'https://flagcdn.com/sv.svg',
  },
  {
    name: 'Jordan',
    idd: '+962',
    flag: 'https://flagcdn.com/jo.svg',
  },
  {
    name: 'Jamaica',
    idd: '+1',
    flag: 'https://flagcdn.com/jm.svg',
  },
  {
    name: 'Barbados',
    idd: '+1',
    flag: 'https://flagcdn.com/bb.svg',
  },
  {
    name: 'Western Sahara',
    idd: '+212',
    flag: 'https://flagcdn.com/eh.svg',
  },
  {
    name: 'Qatar',
    idd: '+974',
    flag: 'https://flagcdn.com/qa.svg',
  },
  {
    name: 'Guatemala',
    idd: '+502',
    flag: 'https://flagcdn.com/gt.svg',
  },
  {
    name: 'Micronesia',
    idd: '+691',
    flag: 'https://flagcdn.com/fm.svg',
  },
  {
    name: 'Montserrat',
    idd: '+1',
    flag: 'https://flagcdn.com/ms.svg',
  },
  {
    name: 'Papua New Guinea',
    idd: '+675',
    flag: 'https://flagcdn.com/pg.svg',
  },
  {
    name: 'Brazil',
    idd: '+55',
    flag: 'https://flagcdn.com/br.svg',
  },
  {
    name: 'Lithuania',
    idd: '+370',
    flag: 'https://flagcdn.com/lt.svg',
  },
  {
    name: 'French Guiana',
    idd: '+594',
    flag: 'https://flagcdn.com/gf.svg',
  },
  {
    name: 'Moldova',
    idd: '+373',
    flag: 'https://flagcdn.com/md.svg',
  },
  {
    name: 'Kyrgyzstan',
    idd: '+996',
    flag: 'https://flagcdn.com/kg.svg',
  },
  {
    name: 'Curaçao',
    idd: '+599',
    flag: 'https://flagcdn.com/cw.svg',
  },
  {
    name: 'Vietnam',
    idd: '+84',
    flag: 'https://flagcdn.com/vn.svg',
  },
  {
    name: 'Malaysia',
    idd: '+60',
    flag: 'https://flagcdn.com/my.svg',
  },
  {
    name: 'Switzerland',
    idd: '+41',
    flag: 'https://flagcdn.com/ch.svg',
  },
  {
    name: 'Thailand',
    idd: '+66',
    flag: 'https://flagcdn.com/th.svg',
  },
  {
    name: 'Dominican Republic',
    idd: '+1',
    flag: 'https://flagcdn.com/do.svg',
  },
  {
    name: 'Uzbekistan',
    idd: '+998',
    flag: 'https://flagcdn.com/uz.svg',
  },
  {
    name: 'Chad',
    idd: '+235',
    flag: 'https://flagcdn.com/td.svg',
  },
  {
    name: 'Cocos (Keeling) Islands',
    idd: '+61',
    flag: 'https://flagcdn.com/cc.svg',
  },
  {
    name: 'Guinea',
    idd: '+224',
    flag: 'https://flagcdn.com/gn.svg',
  },
  {
    name: 'Turks and Caicos Islands',
    idd: '+1649',
    flag: 'https://flagcdn.com/tc.svg',
  },
  {
    name: 'Puerto Rico',
    idd: '+1',
    flag: 'https://flagcdn.com/pr.svg',
  },
  {
    name: 'Bhutan',
    idd: '+975',
    flag: 'https://flagcdn.com/bt.svg',
  },
  {
    name: 'Cayman Islands',
    idd: '+1',
    flag: 'https://flagcdn.com/ky.svg',
  },
  {
    name: 'Marshall Islands',
    idd: '+692',
    flag: 'https://flagcdn.com/mh.svg',
  },
  {
    name: 'Anguilla',
    idd: '+1',
    flag: 'https://flagcdn.com/ai.svg',
  },
  {
    name: 'Mauritania',
    idd: '+222',
    flag: 'https://flagcdn.com/mr.svg',
  },
  {
    name: 'Norway',
    idd: '+47',
    flag: 'https://flagcdn.com/no.svg',
  },
  {
    name: 'Martinique',
    idd: '+596',
    flag: 'https://flagcdn.com/mq.svg',
  },
  {
    name: 'Israel',
    idd: '+972',
    flag: 'https://flagcdn.com/il.svg',
  },
  {
    name: 'Saint Barthélemy',
    idd: '+590',
    flag: 'https://flagcdn.com/bl.svg',
  },
  {
    name: 'Ecuador',
    idd: '+593',
    flag: 'https://flagcdn.com/ec.svg',
  },
  {
    name: 'Grenada',
    idd: '+1473',
    flag: 'https://flagcdn.com/gd.svg',
  },
  {
    name: 'Croatia',
    idd: '+385',
    flag: 'https://flagcdn.com/hr.svg',
  },
  {
    name: 'Brunei',
    idd: '+673',
    flag: 'https://flagcdn.com/bn.svg',
  },
  {
    name: 'Iraq',
    idd: '+964',
    flag: 'https://flagcdn.com/iq.svg',
  },
  {
    name: 'Japan',
    idd: '+81',
    flag: 'https://flagcdn.com/jp.svg',
  },
  {
    name: 'Lesotho',
    idd: '+266',
    flag: 'https://flagcdn.com/ls.svg',
  },
  {
    name: 'Tuvalu',
    idd: '+688',
    flag: 'https://flagcdn.com/tv.svg',
  },
  {
    name: 'Finland',
    idd: '+358',
    flag: 'https://flagcdn.com/fi.svg',
  },
  {
    name: 'South Sudan',
    idd: '+211',
    flag: 'https://flagcdn.com/ss.svg',
  },
  {
    name: 'United States Minor Outlying Islands',
    idd: '+268',
    flag: 'https://flagcdn.com/um.svg',
  },
  {
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    idd: '+290',
    flag: 'https://flagcdn.com/sh.svg',
  },
  {
    name: 'Afghanistan',
    idd: '+93',
    flag: 'https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_the_Taliban.svg',
  },
  {
    name: 'Solomon Islands',
    idd: '+677',
    flag: 'https://flagcdn.com/sb.svg',
  },
  {
    name: 'China',
    idd: '+86',
    flag: 'https://flagcdn.com/cn.svg',
  },
  {
    name: 'Eritrea',
    idd: '+291',
    flag: 'https://flagcdn.com/er.svg',
  },
  {
    name: 'Russia',
    idd: '+7',
    flag: 'https://flagcdn.com/ru.svg',
  },
  {
    name: 'Andorra',
    idd: '+376',
    flag: 'https://flagcdn.com/ad.svg',
  },
  {
    name: 'Armenia',
    idd: '+374',
    flag: 'https://flagcdn.com/am.svg',
  },
  {
    name: 'Austria',
    idd: '+43',
    flag: 'https://flagcdn.com/at.svg',
  },
  {
    name: 'Suriname',
    idd: '+597',
    flag: 'https://flagcdn.com/sr.svg',
  },
  {
    name: 'Spain',
    idd: '+34',
    flag: 'https://flagcdn.com/es.svg',
  },
  {
    name: 'Tokelau',
    idd: '+690',
    flag: 'https://flagcdn.com/tk.svg',
  },
  {
    name: 'Bahamas',
    idd: '+1242',
    flag: 'https://flagcdn.com/bs.svg',
  },
  {
    name: 'Sint Maarten',
    idd: '+1721',
    flag: 'https://flagcdn.com/sx.svg',
  },
  {
    name: 'Belize',
    idd: '+501',
    flag: 'https://flagcdn.com/bz.svg',
  },
  {
    name: 'Jersey',
    idd: '+44',
    flag: 'https://flagcdn.com/je.svg',
  },
  {
    name: 'Sweden',
    idd: '+46',
    flag: 'https://flagcdn.com/se.svg',
  },
  {
    name: 'Botswana',
    idd: '+267',
    flag: 'https://flagcdn.com/bw.svg',
  },
  {
    name: 'Isle of Man',
    idd: '+44',
    flag: 'https://flagcdn.com/im.svg',
  },
  {
    name: 'United Arab Emirates',
    idd: '+971',
    flag: 'https://flagcdn.com/ae.svg',
  },
  {
    name: 'Iran',
    idd: '+98',
    flag: 'https://flagcdn.com/ir.svg',
  },
  {
    name: 'Gabon',
    idd: '+241',
    flag: 'https://flagcdn.com/ga.svg',
  },
  {
    name: 'Saint Kitts and Nevis',
    idd: '+1869',
    flag: 'https://flagcdn.com/kn.svg',
  },
  {
    name: 'Equatorial Guinea',
    idd: '+240',
    flag: 'https://flagcdn.com/gq.svg',
  },
  {
    name: 'Sao Tome and Principe',
    idd: '+239',
    flag: 'https://flagcdn.com/st.svg',
  },
  {
    name: 'Greenland',
    idd: '+299',
    flag: 'https://flagcdn.com/gl.svg',
  },
  {
    name: 'Bangladesh',
    idd: '+880',
    flag: 'https://flagcdn.com/bd.svg',
  },
  {
    name: 'Romania',
    idd: '+40',
    flag: 'https://flagcdn.com/ro.svg',
  },
  {
    name: 'British Indian Ocean Territory',
    idd: '+246',
    flag: 'https://flagcdn.com/io.svg',
  },
  {
    name: 'Sudan',
    idd: '+249',
    flag: 'https://flagcdn.com/sd.svg',
  },
  {
    name: 'Bosnia and Herzegovina',
    idd: '+387',
    flag: 'https://flagcdn.com/ba.svg',
  },
  {
    name: 'Malta',
    idd: '+356',
    flag: 'https://flagcdn.com/mt.svg',
  },
  {
    name: 'Seychelles',
    idd: '+248',
    flag: 'https://flagcdn.com/sc.svg',
  },
  {
    name: 'Sri Lanka',
    idd: '+94',
    flag: 'https://flagcdn.com/lk.svg',
  },
  {
    name: 'Mexico',
    idd: '+52',
    flag: 'https://flagcdn.com/mx.svg',
  },
  {
    name: 'Yemen',
    idd: '+967',
    flag: 'https://flagcdn.com/ye.svg',
  },
  {
    name: 'British Virgin Islands',
    idd: '+1284',
    flag: 'https://flagcdn.com/vg.svg',
  },
  {
    name: 'Netherlands',
    idd: '+31',
    flag: 'https://flagcdn.com/nl.svg',
  },
  {
    name: 'Paraguay',
    idd: '+595',
    flag: 'https://flagcdn.com/py.svg',
  },
  {
    name: 'United Kingdom',
    idd: '+44',
    flag: 'https://flagcdn.com/gb.svg',
  },
  {
    name: 'Venezuela',
    idd: '+58',
    flag: 'https://flagcdn.com/ve.svg',
  },
  {
    name: 'Bouvet Island',
    idd: '+47',
    flag: 'https://flagcdn.com/bv.svg',
  },
  {
    name: 'Ukraine',
    idd: '+380',
    flag: 'https://flagcdn.com/ua.svg',
  },
  {
    name: 'Morocco',
    idd: '+212',
    flag: 'https://flagcdn.com/ma.svg',
  },
  {
    name: 'Portugal',
    idd: '+351',
    flag: 'https://flagcdn.com/pt.svg',
  },
  {
    name: 'Pakistan',
    idd: '+92',
    flag: 'https://flagcdn.com/pk.svg',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    idd: '+1784',
    flag: 'https://flagcdn.com/vc.svg',
  },
  {
    name: 'North Korea',
    idd: '+850',
    flag: 'https://flagcdn.com/kp.svg',
  },
  {
    name: 'Slovenia',
    idd: '+386',
    flag: 'https://flagcdn.com/si.svg',
  },
  {
    name: 'Ivory Coast',
    idd: '+225',
    flag: 'https://flagcdn.com/ci.svg',
  },
  {
    name: 'Palestine',
    idd: '+970',
    flag: 'https://flagcdn.com/ps.svg',
  },
  {
    name: 'Caribbean Netherlands',
    idd: '+599',
    flag: 'https://flagcdn.com/bq.svg',
  },
  {
    name: 'Belgium',
    idd: '+32',
    flag: 'https://flagcdn.com/be.svg',
  },
  {
    name: 'Zimbabwe',
    idd: '+263',
    flag: 'https://flagcdn.com/zw.svg',
  },
  {
    name: 'Tanzania',
    idd: '+255',
    flag: 'https://flagcdn.com/tz.svg',
  },
  {
    name: 'Togo',
    idd: '+228',
    flag: 'https://flagcdn.com/tg.svg',
  },
  {
    name: 'Cook Islands',
    idd: '+682',
    flag: 'https://flagcdn.com/ck.svg',
  },
  {
    name: 'Guadeloupe',
    idd: '+590',
    flag: 'https://flagcdn.com/gp.svg',
  },
  {
    name: 'New Caledonia',
    idd: '+687',
    flag: 'https://flagcdn.com/nc.svg',
  },
  {
    name: 'Saint Lucia',
    idd: '+1758',
    flag: 'https://flagcdn.com/lc.svg',
  },
  {
    name: 'South Georgia',
    idd: '+500',
    flag: 'https://flagcdn.com/gs.svg',
  },
  {
    name: 'Poland',
    idd: '+48',
    flag: 'https://flagcdn.com/pl.svg',
  },
  {
    name: 'Samoa',
    idd: '+685',
    flag: 'https://flagcdn.com/ws.svg',
  },
  {
    name: 'Czechia',
    idd: '+420',
    flag: 'https://flagcdn.com/cz.svg',
  },
  {
    name: 'Indonesia',
    idd: '+62',
    flag: 'https://flagcdn.com/id.svg',
  },
  {
    name: 'Bolivia',
    idd: '+591',
    flag: 'https://flagcdn.com/bo.svg',
  },
  {
    name: 'Colombia',
    idd: '+57',
    flag: 'https://flagcdn.com/co.svg',
  },
  {
    name: 'Honduras',
    idd: '+504',
    flag: 'https://flagcdn.com/hn.svg',
  },
  {
    name: 'Denmark',
    idd: '+45',
    flag: 'https://flagcdn.com/dk.svg',
  },
  {
    name: 'Central African Republic',
    idd: '+236',
    flag: 'https://flagcdn.com/cf.svg',
  },
  {
    name: 'Libya',
    idd: '+218',
    flag: 'https://flagcdn.com/ly.svg',
  },
  {
    name: 'Guinea-Bissau',
    idd: '+245',
    flag: 'https://flagcdn.com/gw.svg',
  },
  {
    name: 'Mongolia',
    idd: '+976',
    flag: 'https://flagcdn.com/mn.svg',
  },
  {
    name: 'Democratic Republic of the Congo',
    idd: '+243',
    flag: 'https://flagcdn.com/cd.svg',
  },
  {
    name: 'Falkland Islands',
    idd: '+500',
    flag: 'https://flagcdn.com/fk.svg',
  },
  {
    name: 'Nauru',
    idd: '+674',
    flag: 'https://flagcdn.com/nr.svg',
  },
  {
    name: 'Syria',
    idd: '+963',
    flag: 'https://flagcdn.com/sy.svg',
  },
  {
    name: 'Montenegro',
    idd: '+382',
    flag: 'https://flagcdn.com/me.svg',
  },
  {
    name: 'France',
    idd: '+33',
    flag: 'https://flagcdn.com/fr.svg',
  },
  {
    name: 'Zambia',
    idd: '+260',
    flag: 'https://flagcdn.com/zm.svg',
  },
  {
    name: 'Myanmar',
    idd: '+95',
    flag: 'https://flagcdn.com/mm.svg',
  },
  {
    name: 'Saint Pierre and Miquelon',
    idd: '+508',
    flag: 'https://flagcdn.com/pm.svg',
  },
  {
    name: 'Costa Rica',
    idd: '+506',
    flag: 'https://flagcdn.com/cr.svg',
  },
  {
    name: 'Somalia',
    idd: '+252',
    flag: 'https://flagcdn.com/so.svg',
  },
  {
    name: 'Ireland',
    idd: '+353',
    flag: 'https://flagcdn.com/ie.svg',
  },
  {
    name: 'French Polynesia',
    idd: '+689',
    flag: 'https://flagcdn.com/pf.svg',
  },
  {
    name: 'Pitcairn Islands',
    idd: '+64',
    flag: 'https://flagcdn.com/pn.svg',
  },
  {
    name: 'Hungary',
    idd: '+36',
    flag: 'https://flagcdn.com/hu.svg',
  },
  {
    name: 'Turkey',
    idd: '+90',
    flag: 'https://flagcdn.com/tr.svg',
  },
  {
    name: 'Oman',
    idd: '+968',
    flag: 'https://flagcdn.com/om.svg',
  },
  {
    name: 'Guam',
    idd: '+1671',
    flag: 'https://flagcdn.com/gu.svg',
  },
  {
    name: 'Nepal',
    idd: '+977',
    flag: 'https://flagcdn.com/np.svg',
  },
  {
    name: 'Liechtenstein',
    idd: '+423',
    flag: 'https://flagcdn.com/li.svg',
  },
  {
    name: 'Madagascar',
    idd: '+261',
    flag: 'https://flagcdn.com/mg.svg',
  },
  {
    name: 'Kazakhstan',
    idd: '+767',
    flag: 'https://flagcdn.com/kz.svg',
  },
  {
    name: 'Djibouti',
    idd: '+253',
    flag: 'https://flagcdn.com/dj.svg',
  },
  {
    name: 'Gibraltar',
    idd: '+350',
    flag: 'https://flagcdn.com/gi.svg',
  },
  {
    name: 'Guernsey',
    idd: '+44',
    flag: 'https://flagcdn.com/gg.svg',
  },
  {
    name: 'Namibia',
    idd: '+264',
    flag: 'https://flagcdn.com/na.svg',
  },
  {
    name: 'Republic of the Congo',
    idd: '+242',
    flag: 'https://flagcdn.com/cg.svg',
  },
  {
    name: 'Germany',
    idd: '+49',
    flag: 'https://flagcdn.com/de.svg',
  },
  {
    name: 'Vatican City',
    idd: '+379',
    flag: 'https://flagcdn.com/va.svg',
  },
  {
    name: 'French Southern and Antarctic Lands',
    idd: '+262',
    flag: 'https://flagcdn.com/tf.svg',
  },
  {
    name: 'Albania',
    idd: '+355',
    flag: 'https://flagcdn.com/al.svg',
  },
  {
    name: 'Algeria',
    idd: '+213',
    flag: 'https://flagcdn.com/dz.svg',
  },
  {
    name: 'Mayotte',
    idd: '+262',
    flag: 'https://flagcdn.com/yt.svg',
  },
  {
    name: 'Haiti',
    idd: '+509',
    flag: 'https://flagcdn.com/ht.svg',
  },
  {
    name: 'Svalbard and Jan Mayen',
    idd: '+47',
    flag: 'https://flagcdn.com/sj.svg',
  },
  {
    name: 'Tonga',
    idd: '+676',
    flag: 'https://flagcdn.com/to.svg',
  },
  {
    name: 'Hong Kong',
    idd: '+852',
    flag: 'https://flagcdn.com/hk.svg',
  },
  {
    name: 'Singapore',
    idd: '+65',
    flag: 'https://flagcdn.com/sg.svg',
  },
  {
    name: 'Antigua and Barbuda',
    idd: '+1268',
    flag: 'https://flagcdn.com/ag.svg',
  },
  {
    name: 'Saudi Arabia',
    idd: '+966',
    flag: 'https://flagcdn.com/sa.svg',
  },
  {
    name: 'Cuba',
    idd: '+53',
    flag: 'https://flagcdn.com/cu.svg',
  },
  {
    name: 'Liberia',
    idd: '+231',
    flag: 'https://flagcdn.com/lr.svg',
  },
  {
    name: 'Azerbaijan',
    idd: '+994',
    flag: 'https://flagcdn.com/az.svg',
  },
  {
    name: 'Latvia',
    idd: '+371',
    flag: 'https://flagcdn.com/lv.svg',
  },
  {
    name: 'Antarctica',
    idd: '+672',
    flag: 'https://flagcdn.com/aq.svg',
  },
  {
    name: 'Kiribati',
    idd: '+686',
    flag: 'https://flagcdn.com/ki.svg',
  },
  {
    name: 'Ethiopia',
    idd: '+251',
    flag: 'https://flagcdn.com/et.svg',
  },
  {
    name: 'Niger',
    idd: '+227',
    flag: 'https://flagcdn.com/ne.svg',
  },
  {
    name: 'Trinidad and Tobago',
    idd: '+1868',
    flag: 'https://flagcdn.com/tt.svg',
  },
  {
    name: 'Sierra Leone',
    idd: '+232',
    flag: 'https://flagcdn.com/sl.svg',
  },
  {
    name: 'Mozambique',
    idd: '+258',
    flag: 'https://flagcdn.com/mz.svg',
  },
  {
    name: 'Palau',
    idd: '+680',
    flag: 'https://flagcdn.com/pw.svg',
  },
  {
    name: 'Fiji',
    idd: '+679',
    flag: 'https://flagcdn.com/fj.svg',
  },
  {
    name: 'Réunion',
    idd: '+262',
    flag: 'https://flagcdn.com/re.svg',
  },
  {
    name: 'Norfolk Island',
    idd: '+672',
    flag: 'https://flagcdn.com/nf.svg',
  },
  {
    name: 'Turkmenistan',
    idd: '+993',
    flag: 'https://flagcdn.com/tm.svg',
  },
  {
    name: 'Tajikistan',
    idd: '+992',
    flag: 'https://flagcdn.com/tj.svg',
  },
  {
    name: 'Lebanon',
    idd: '+961',
    flag: 'https://flagcdn.com/lb.svg',
  },
  {
    name: 'Nigeria',
    idd: '+234',
    flag: 'https://flagcdn.com/ng.svg',
  },
  {
    name: 'United States',
    idd: '+1',
    flag: 'https://flagcdn.com/us.svg',
  },
  {
    name: 'Argentina',
    idd: '+54',
    flag: 'https://flagcdn.com/ar.svg',
  },
  {
    name: 'Burkina Faso',
    idd: '+226',
    flag: 'https://flagcdn.com/bf.svg',
  },
  {
    name: 'Christmas Island',
    idd: '+61',
    flag: 'https://flagcdn.com/cx.svg',
  },
  {
    name: 'Bermuda',
    idd: '+1441',
    flag: 'https://flagcdn.com/bm.svg',
  },
  {
    name: 'Nicaragua',
    idd: '+505',
    flag: 'https://flagcdn.com/ni.svg',
  },
  {
    name: 'Eswatini',
    idd: '+268',
    flag: 'https://flagcdn.com/sz.svg',
  },
  {
    name: 'Bahrain',
    idd: '+973',
    flag: 'https://flagcdn.com/bh.svg',
  },
  {
    name: 'Kenya',
    idd: '+254',
    flag: 'https://flagcdn.com/ke.svg',
  },
  {
    name: 'Serbia',
    idd: '+381',
    flag: 'https://flagcdn.com/rs.svg',
  },
  {
    name: 'Timor-Leste',
    idd: '+670',
    flag: 'https://flagcdn.com/tl.svg',
  },
  {
    name: 'Dominica',
    idd: '+1767',
    flag: 'https://flagcdn.com/dm.svg',
  },
  {
    name: 'Northern Mariana Islands',
    idd: '+1670',
    flag: 'https://flagcdn.com/mp.svg',
  },
  {
    name: 'Philippines',
    idd: '+63',
    flag: 'https://flagcdn.com/ph.svg',
  },
  {
    name: 'Kosovo',
    idd: '+383',
    flag: 'https://flagcdn.com/xk.svg',
  },
  {
    name: 'Kuwait',
    idd: '+965',
    flag: 'https://flagcdn.com/kw.svg',
  },
  {
    name: 'Taiwan',
    idd: '+886',
    flag: 'https://flagcdn.com/tw.svg',
  },
  {
    name: 'Burundi',
    idd: '+257',
    flag: 'https://flagcdn.com/bi.svg',
  },
  {
    name: 'American Samoa',
    idd: '+1684',
    flag: 'https://flagcdn.com/as.svg',
  },
  {
    name: 'Bulgaria',
    idd: '+359',
    flag: 'https://flagcdn.com/bg.svg',
  },
  {
    name: 'South Africa',
    idd: '+27',
    flag: 'https://flagcdn.com/za.svg',
  },
  {
    name: 'Peru',
    idd: '+51',
    flag: 'https://flagcdn.com/pe.svg',
  },
  {
    name: 'Aruba',
    idd: '+297',
    flag: 'https://flagcdn.com/aw.svg',
  },
  {
    name: 'Ghana',
    idd: '+233',
    flag: 'https://flagcdn.com/gh.svg',
  },
  {
    name: 'Saint Martin',
    idd: '+590',
    flag: 'https://flagcdn.com/mf.svg',
  },
  {
    name: 'India',
    idd: '+91',
    flag: 'https://flagcdn.com/in.svg',
  },
  {
    name: 'Uganda',
    idd: '+256',
    flag: 'https://flagcdn.com/ug.svg',
  },
].sort((a, b) => a.name.localeCompare(b.name));

export const timezones: string[] = [
  'Pacific Standard Time (PST) UTC-08:00',
  'Mountain Standard Time (MST) UTC-07:00',
  'Central Standard Time (CST) UTC-06:00',
  'Eastern Standard Time (EST) UTC-05:00',
  'Atlantic Standard Time (AST) UTC-04:00',
  'Greenwich Mean Time (GMT) UTC+00:00',
  'Central European Time (CET) UTC+01:00',
  'Eastern European Time (EET) UTC+02:00',
  'Moscow Standard Time (MSK) UTC+03:00',
  'Gulf Standard Time (GST) UTC+04:00',
  'Pakistan Standard Time (PKT) UTC+05:00',
  'India Standard Time (IST) UTC+05:30',
  'Bangladesh Standard Time (BST) UTC+06:00',
  'Indochina Time (ICT) UTC+07:00',
  'China Standard Time (CST) UTC+08:00',
  'Japan Standard Time (JST) UTC+09:00',
  'Australian Eastern Standard Time (AEST) UTC+10:00',
  'New Zealand Standard Time (NZST) UTC+12:00',
];

export const hourTimeIntervals = [
  '01:00',
  '01:30',
  '02:00',
  '02:30',
  '03:00',
  '03:30',
  '04:00',
  '04:30',
  '05:00',
  '05:30',
  '06:00',
  '06:30',
  '07:00',
  '07:30',
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
  '21:30',
  '22:00',
  '22:30',
  '23:00',
  '23:30',
  '24:00',
] as const;

const allTimeZones = [
  'Africa/Abidjan',
  'Africa/Accra',
  'Africa/Addis_Ababa',
  'Africa/Algiers',
  'Africa/Asmera',
  'Africa/Bamako',
  'Africa/Bangui',
  'Africa/Banjul',
  'Africa/Bissau',
  'Africa/Blantyre',
  'Africa/Brazzaville',
  'Africa/Bujumbura',
  'Africa/Cairo',
  'Africa/Casablanca',
  'Africa/Ceuta',
  'Africa/Conakry',
  'Africa/Dakar',
  'Africa/Dar_es_Salaam',
  'Africa/Djibouti',
  'Africa/Douala',
  'Africa/El_Aaiun',
  'Africa/Freetown',
  'Africa/Gaborone',
  'Africa/Harare',
  'Africa/Johannesburg',
  'Africa/Juba',
  'Africa/Kampala',
  'Africa/Khartoum',
  'Africa/Kigali',
  'Africa/Kinshasa',
  'Africa/Lagos',
  'Africa/Libreville',
  'Africa/Lome',
  'Africa/Luanda',
  'Africa/Lubumbashi',
  'Africa/Lusaka',
  'Africa/Malabo',
  'Africa/Maputo',
  'Africa/Maseru',
  'Africa/Mbabane',
  'Africa/Mogadishu',
  'Africa/Monrovia',
  'Africa/Nairobi',
  'Africa/Ndjamena',
  'Africa/Niamey',
  'Africa/Nouakchott',
  'Africa/Ouagadougou',
  'Africa/Porto-Novo',
  'Africa/Sao_Tome',
  'Africa/Tripoli',
  'Africa/Tunis',
  'Africa/Windhoek',
  'America/Adak',
  'America/Anchorage',
  'America/Anguilla',
  'America/Antigua',
  'America/Araguaina',
  'America/Argentina/La_Rioja',
  'America/Argentina/Rio_Gallegos',
  'America/Argentina/Salta',
  'America/Argentina/San_Juan',
  'America/Argentina/San_Luis',
  'America/Argentina/Tucuman',
  'America/Argentina/Ushuaia',
  'America/Aruba',
  'America/Asuncion',
  'America/Bahia',
  'America/Bahia_Banderas',
  'America/Barbados',
  'America/Belem',
  'America/Belize',
  'America/Blanc-Sablon',
  'America/Boa_Vista',
  'America/Bogota',
  'America/Boise',
  'America/Buenos_Aires',
  'America/Cambridge_Bay',
  'America/Campo_Grande',
  'America/Cancun',
  'America/Caracas',
  'America/Catamarca',
  'America/Cayenne',
  'America/Cayman',
  'America/Chicago',
  'America/Chihuahua',
  'America/Ciudad_Juarez',
  'America/Coral_Harbour',
  'America/Cordoba',
  'America/Costa_Rica',
  'America/Creston',
  'America/Cuiaba',
  'America/Curacao',
  'America/Danmarkshavn',
  'America/Dawson',
  'America/Dawson_Creek',
  'America/Denver',
  'America/Detroit',
  'America/Dominica',
  'America/Edmonton',
  'America/Eirunepe',
  'America/El_Salvador',
  'America/Fort_Nelson',
  'America/Fortaleza',
  'America/Glace_Bay',
  'America/Godthab',
  'America/Goose_Bay',
  'America/Grand_Turk',
  'America/Grenada',
  'America/Guadeloupe',
  'America/Guatemala',
  'America/Guayaquil',
  'America/Guyana',
  'America/Halifax',
  'America/Havana',
  'America/Hermosillo',
  'America/Indiana/Knox',
  'America/Indiana/Marengo',
  'America/Indiana/Petersburg',
  'America/Indiana/Tell_City',
  'America/Indiana/Vevay',
  'America/Indiana/Vincennes',
  'America/Indiana/Winamac',
  'America/Indianapolis',
  'America/Inuvik',
  'America/Iqaluit',
  'America/Jamaica',
  'America/Jujuy',
  'America/Juneau',
  'America/Kentucky/Monticello',
  'America/Kralendijk',
  'America/La_Paz',
  'America/Lima',
  'America/Los_Angeles',
  'America/Louisville',
  'America/Lower_Princes',
  'America/Maceio',
  'America/Managua',
  'America/Manaus',
  'America/Marigot',
  'America/Martinique',
  'America/Matamoros',
  'America/Mazatlan',
  'America/Mendoza',
  'America/Menominee',
  'America/Merida',
  'America/Metlakatla',
  'America/Mexico_City',
  'America/Miquelon',
  'America/Moncton',
  'America/Monterrey',
  'America/Montevideo',
  'America/Montserrat',
  'America/Nassau',
  'America/New_York',
  'America/Nome',
  'America/Noronha',
  'America/North_Dakota/Beulah',
  'America/North_Dakota/Center',
  'America/North_Dakota/New_Salem',
  'America/Ojinaga',
  'America/Panama',
  'America/Paramaribo',
  'America/Phoenix',
  'America/Port-au-Prince',
  'America/Port_of_Spain',
  'America/Porto_Velho',
  'America/Puerto_Rico',
  'America/Punta_Arenas',
  'America/Rankin_Inlet',
  'America/Recife',
  'America/Regina',
  'America/Resolute',
  'America/Rio_Branco',
  'America/Santarem',
  'America/Santiago',
  'America/Santo_Domingo',
  'America/Sao_Paulo',
  'America/Scoresbysund',
  'America/Sitka',
  'America/St_Barthelemy',
  'America/St_Johns',
  'America/St_Kitts',
  'America/St_Lucia',
  'America/St_Thomas',
  'America/St_Vincent',
  'America/Swift_Current',
  'America/Tegucigalpa',
  'America/Thule',
  'America/Tijuana',
  'America/Toronto',
  'America/Tortola',
  'America/Vancouver',
  'America/Whitehorse',
  'America/Winnipeg',
  'America/Yakutat',
  'Antarctica/Casey',
  'Antarctica/Davis',
  'Antarctica/DumontDUrville',
  'Antarctica/Macquarie',
  'Antarctica/Mawson',
  'Antarctica/McMurdo',
  'Antarctica/Palmer',
  'Antarctica/Rothera',
  'Antarctica/Syowa',
  'Antarctica/Troll',
  'Antarctica/Vostok',
  'Arctic/Longyearbyen',
  'Asia/Aden',
  'Asia/Almaty',
  'Asia/Amman',
  'Asia/Anadyr',
  'Asia/Aqtau',
  'Asia/Aqtobe',
  'Asia/Ashgabat',
  'Asia/Atyrau',
  'Asia/Baghdad',
  'Asia/Bahrain',
  'Asia/Baku',
  'Asia/Bangkok',
  'Asia/Barnaul',
  'Asia/Beirut',
  'Asia/Bishkek',
  'Asia/Brunei',
  'Asia/Calcutta',
  'Asia/Chita',
  'Asia/Colombo',
  'Asia/Damascus',
  'Asia/Dhaka',
  'Asia/Dili',
  'Asia/Dubai',
  'Asia/Dushanbe',
  'Asia/Famagusta',
  'Asia/Gaza',
  'Asia/Hebron',
  'Asia/Hong_Kong',
  'Asia/Hovd',
  'Asia/Irkutsk',
  'Asia/Jakarta',
  'Asia/Jayapura',
  'Asia/Jerusalem',
  'Asia/Kabul',
  'Asia/Kamchatka',
  'Asia/Karachi',
  'Asia/Katmandu',
  'Asia/Khandyga',
  'Asia/Krasnoyarsk',
  'Asia/Kuala_Lumpur',
  'Asia/Kuching',
  'Asia/Kuwait',
  'Asia/Macau',
  'Asia/Magadan',
  'Asia/Makassar',
  'Asia/Manila',
  'Asia/Muscat',
  'Asia/Nicosia',
  'Asia/Novokuznetsk',
  'Asia/Novosibirsk',
  'Asia/Omsk',
  'Asia/Oral',
  'Asia/Phnom_Penh',
  'Asia/Pontianak',
  'Asia/Pyongyang',
  'Asia/Qatar',
  'Asia/Qostanay',
  'Asia/Qyzylorda',
  'Asia/Rangoon',
  'Asia/Riyadh',
  'Asia/Saigon',
  'Asia/Sakhalin',
  'Asia/Samarkand',
  'Asia/Seoul',
  'Asia/Shanghai',
  'Asia/Singapore',
  'Asia/Srednekolymsk',
  'Asia/Taipei',
  'Asia/Tashkent',
  'Asia/Tbilisi',
  'Asia/Tehran',
  'Asia/Thimphu',
  'Asia/Tokyo',
  'Asia/Tomsk',
  'Asia/Ulaanbaatar',
  'Asia/Urumqi',
  'Asia/Ust-Nera',
  'Asia/Vientiane',
  'Asia/Vladivostok',
  'Asia/Yakutsk',
  'Asia/Yekaterinburg',
  'Asia/Yerevan',
  'Atlantic/Azores',
  'Atlantic/Bermuda',
  'Atlantic/Canary',
  'Atlantic/Cape_Verde',
  'Atlantic/Faeroe',
  'Atlantic/Madeira',
  'Atlantic/Reykjavik',
  'Atlantic/South_Georgia',
  'Atlantic/St_Helena',
  'Atlantic/Stanley',
  'Australia/Adelaide',
  'Australia/Brisbane',
  'Australia/Broken_Hill',
  'Australia/Darwin',
  'Australia/Eucla',
  'Australia/Hobart',
  'Australia/Lindeman',
  'Australia/Lord_Howe',
  'Australia/Melbourne',
  'Australia/Perth',
  'Australia/Sydney',
  'Europe/Amsterdam',
  'Europe/Andorra',
  'Europe/Astrakhan',
  'Europe/Athens',
  'Europe/Belgrade',
  'Europe/Berlin',
  'Europe/Bratislava',
  'Europe/Brussels',
  'Europe/Bucharest',
  'Europe/Budapest',
  'Europe/Busingen',
  'Europe/Chisinau',
  'Europe/Copenhagen',
  'Europe/Dublin',
  'Europe/Gibraltar',
  'Europe/Guernsey',
  'Europe/Helsinki',
  'Europe/Isle_of_Man',
  'Europe/Istanbul',
  'Europe/Jersey',
  'Europe/Kaliningrad',
  'Europe/Kiev',
  'Europe/Kirov',
  'Europe/Lisbon',
  'Europe/Ljubljana',
  'Europe/London',
  'Europe/Luxembourg',
  'Europe/Madrid',
  'Europe/Malta',
  'Europe/Mariehamn',
  'Europe/Minsk',
  'Europe/Monaco',
  'Europe/Moscow',
  'Europe/Oslo',
  'Europe/Paris',
  'Europe/Podgorica',
  'Europe/Prague',
  'Europe/Riga',
  'Europe/Rome',
  'Europe/Samara',
  'Europe/San_Marino',
  'Europe/Sarajevo',
  'Europe/Saratov',
  'Europe/Simferopol',
  'Europe/Skopje',
  'Europe/Sofia',
  'Europe/Stockholm',
  'Europe/Tallinn',
  'Europe/Tirane',
  'Europe/Ulyanovsk',
  'Europe/Vaduz',
  'Europe/Vatican',
  'Europe/Vienna',
  'Europe/Vilnius',
  'Europe/Volgograd',
  'Europe/Warsaw',
  'Europe/Zagreb',
  'Europe/Zurich',
  'Indian/Antananarivo',
  'Indian/Chagos',
  'Indian/Christmas',
  'Indian/Cocos',
  'Indian/Comoro',
  'Indian/Kerguelen',
  'Indian/Mahe',
  'Indian/Maldives',
  'Indian/Mauritius',
  'Indian/Mayotte',
  'Indian/Reunion',
  'Pacific/Apia',
  'Pacific/Auckland',
  'Pacific/Bougainville',
  'Pacific/Chatham',
  'Pacific/Easter',
  'Pacific/Efate',
  'Pacific/Enderbury',
  'Pacific/Fakaofo',
  'Pacific/Fiji',
  'Pacific/Funafuti',
  'Pacific/Galapagos',
  'Pacific/Gambier',
  'Pacific/Guadalcanal',
  'Pacific/Guam',
  'Pacific/Honolulu',
  'Pacific/Kiritimati',
  'Pacific/Kosrae',
  'Pacific/Kwajalein',
  'Pacific/Majuro',
  'Pacific/Marquesas',
  'Pacific/Midway',
  'Pacific/Nauru',
  'Pacific/Niue',
  'Pacific/Norfolk',
  'Pacific/Noumea',
  'Pacific/Pago_Pago',
  'Pacific/Palau',
  'Pacific/Pitcairn',
  'Pacific/Ponape',
  'Pacific/Port_Moresby',
  'Pacific/Rarotonga',
  'Pacific/Saipan',
  'Pacific/Tahiti',
  'Pacific/Tarawa',
  'Pacific/Tongatapu',
  'Pacific/Truk',
  'Pacific/Wake',
  'Pacific/Wallis',
];

export function getTimeZonesWithOffsets(): {
  label: string;
  value: string;
}[] {
  const timeZones = allTimeZones; // Get all IANA timezones
  const now = new Date(); // Use the current date

  return timeZones.map((timeZone) => {
    // Use Intl.DateTimeFormat to get the offset in minutes
    const formatter = new Intl.DateTimeFormat('en-US', {
      timeZone,
      hour: '2-digit',
      timeZoneName: 'shortOffset',
    });
    const formattedParts = formatter.formatToParts(now);

    // Extract the UTC offset from formatted parts
    const offset =
      formattedParts.find((part) => part.type === 'timeZoneName')?.value ||
      'UTC';

    return {
      label: `${timeZone.replace(/_/g, ' ')} (${offset})`,
      value: timeZone,
    };
  });
}

export const timeZonesWithOffsets = getTimeZonesWithOffsets();
