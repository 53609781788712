/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * Teachly API V2
 * Teachly API documentation
 * OpenAPI spec version: 1.0
 */
import { useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query';
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryClient,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';

import { customFetch } from '../../../mutator/custom-fetch';
import type {
  CreateMembersSpaceDto,
  MembersSpace,
  MembersSpacesControllerGetMembersSuscriptions200Item,
  SuccessMessageResponseDto,
  UpdateMembersSpaceDto,
} from '../../model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * Creates a new members space within a specific space group.
 * @summary Create a new members space
 */
export const membersSpacesControllerCreateMembersSpace = (
  createMembersSpaceDto: CreateMembersSpaceDto,
  options?: SecondParameter<typeof customFetch>,
) => {
  return customFetch<MembersSpace>(
    {
      url: `/members-spaces`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createMembersSpaceDto,
    },
    options,
  );
};

export const getMembersSpacesControllerCreateMembersSpaceMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof membersSpacesControllerCreateMembersSpace>>,
    TError,
    { data: CreateMembersSpaceDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof membersSpacesControllerCreateMembersSpace>>,
  TError,
  { data: CreateMembersSpaceDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof membersSpacesControllerCreateMembersSpace>>,
    { data: CreateMembersSpaceDto }
  > = (props) => {
    const { data } = props ?? {};

    return membersSpacesControllerCreateMembersSpace(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type MembersSpacesControllerCreateMembersSpaceMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof membersSpacesControllerCreateMembersSpace>>
  >;
export type MembersSpacesControllerCreateMembersSpaceMutationBody =
  CreateMembersSpaceDto;
export type MembersSpacesControllerCreateMembersSpaceMutationError = void;

/**
 * @summary Create a new members space
 */
export const useMembersSpacesControllerCreateMembersSpace = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof membersSpacesControllerCreateMembersSpace>>,
    TError,
    { data: CreateMembersSpaceDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof membersSpacesControllerCreateMembersSpace>>,
  TError,
  { data: CreateMembersSpaceDto },
  TContext
> => {
  const mutationOptions =
    getMembersSpacesControllerCreateMembersSpaceMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Retrieves all members spaces associated with a specific user.
 * @summary Get all members spaces for a user
 */
export const membersSpacesControllerGetUserMembersSpaces = (
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<MembersSpace[]>(
    { url: `/members-spaces/my`, method: 'GET', signal },
    options,
  );
};

export const getMembersSpacesControllerGetUserMembersSpacesQueryKey = () => {
  return [`/members-spaces/my`] as const;
};

export const getMembersSpacesControllerGetUserMembersSpacesQueryOptions = <
  TData = Awaited<
    ReturnType<typeof membersSpacesControllerGetUserMembersSpaces>
  >,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof membersSpacesControllerGetUserMembersSpaces>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getMembersSpacesControllerGetUserMembersSpacesQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof membersSpacesControllerGetUserMembersSpaces>>
  > = ({ signal }) =>
    membersSpacesControllerGetUserMembersSpaces(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof membersSpacesControllerGetUserMembersSpaces>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type MembersSpacesControllerGetUserMembersSpacesQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof membersSpacesControllerGetUserMembersSpaces>>
  >;
export type MembersSpacesControllerGetUserMembersSpacesQueryError = void;

export function useMembersSpacesControllerGetUserMembersSpaces<
  TData = Awaited<
    ReturnType<typeof membersSpacesControllerGetUserMembersSpaces>
  >,
  TError = void,
>(options: {
  query: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof membersSpacesControllerGetUserMembersSpaces>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof membersSpacesControllerGetUserMembersSpaces>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customFetch>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useMembersSpacesControllerGetUserMembersSpaces<
  TData = Awaited<
    ReturnType<typeof membersSpacesControllerGetUserMembersSpaces>
  >,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof membersSpacesControllerGetUserMembersSpaces>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof membersSpacesControllerGetUserMembersSpaces>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useMembersSpacesControllerGetUserMembersSpaces<
  TData = Awaited<
    ReturnType<typeof membersSpacesControllerGetUserMembersSpaces>
  >,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof membersSpacesControllerGetUserMembersSpaces>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get all members spaces for a user
 */

export function useMembersSpacesControllerGetUserMembersSpaces<
  TData = Awaited<
    ReturnType<typeof membersSpacesControllerGetUserMembersSpaces>
  >,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof membersSpacesControllerGetUserMembersSpaces>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getMembersSpacesControllerGetUserMembersSpacesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get all members spaces for a user
 */
export const prefetchMembersSpacesControllerGetUserMembersSpaces = async <
  TData = Awaited<
    ReturnType<typeof membersSpacesControllerGetUserMembersSpaces>
  >,
  TError = void,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof membersSpacesControllerGetUserMembersSpaces>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions =
    getMembersSpacesControllerGetUserMembersSpacesQueryOptions(options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getMembersSpacesControllerGetUserMembersSpacesSuspenseQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof membersSpacesControllerGetUserMembersSpaces>
    >,
    TError = void,
  >(options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof membersSpacesControllerGetUserMembersSpaces>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  }) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getMembersSpacesControllerGetUserMembersSpacesQueryKey();

    const queryFn: QueryFunction<
      Awaited<ReturnType<typeof membersSpacesControllerGetUserMembersSpaces>>
    > = ({ signal }) =>
      membersSpacesControllerGetUserMembersSpaces(requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof membersSpacesControllerGetUserMembersSpaces>>,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type MembersSpacesControllerGetUserMembersSpacesSuspenseQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof membersSpacesControllerGetUserMembersSpaces>>
  >;
export type MembersSpacesControllerGetUserMembersSpacesSuspenseQueryError =
  void;

export function useMembersSpacesControllerGetUserMembersSpacesSuspense<
  TData = Awaited<
    ReturnType<typeof membersSpacesControllerGetUserMembersSpaces>
  >,
  TError = void,
>(options: {
  query: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof membersSpacesControllerGetUserMembersSpaces>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useMembersSpacesControllerGetUserMembersSpacesSuspense<
  TData = Awaited<
    ReturnType<typeof membersSpacesControllerGetUserMembersSpaces>
  >,
  TError = void,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof membersSpacesControllerGetUserMembersSpaces>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useMembersSpacesControllerGetUserMembersSpacesSuspense<
  TData = Awaited<
    ReturnType<typeof membersSpacesControllerGetUserMembersSpaces>
  >,
  TError = void,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof membersSpacesControllerGetUserMembersSpaces>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get all members spaces for a user
 */

export function useMembersSpacesControllerGetUserMembersSpacesSuspense<
  TData = Awaited<
    ReturnType<typeof membersSpacesControllerGetUserMembersSpaces>
  >,
  TError = void,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof membersSpacesControllerGetUserMembersSpaces>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getMembersSpacesControllerGetUserMembersSpacesSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Updates an members space for the authenticated user.
 * @summary Update an members space
 */
export const membersSpacesControllerUpdateMembersSpace = (
  membersSpaceId: string,
  updateMembersSpaceDto: UpdateMembersSpaceDto,
  options?: SecondParameter<typeof customFetch>,
) => {
  return customFetch<MembersSpace>(
    {
      url: `/members-spaces/${encodeURIComponent(String(membersSpaceId))}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: updateMembersSpaceDto,
    },
    options,
  );
};

export const getMembersSpacesControllerUpdateMembersSpaceMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof membersSpacesControllerUpdateMembersSpace>>,
    TError,
    { membersSpaceId: string; data: UpdateMembersSpaceDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof membersSpacesControllerUpdateMembersSpace>>,
  TError,
  { membersSpaceId: string; data: UpdateMembersSpaceDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof membersSpacesControllerUpdateMembersSpace>>,
    { membersSpaceId: string; data: UpdateMembersSpaceDto }
  > = (props) => {
    const { membersSpaceId, data } = props ?? {};

    return membersSpacesControllerUpdateMembersSpace(
      membersSpaceId,
      data,
      requestOptions,
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type MembersSpacesControllerUpdateMembersSpaceMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof membersSpacesControllerUpdateMembersSpace>>
  >;
export type MembersSpacesControllerUpdateMembersSpaceMutationBody =
  UpdateMembersSpaceDto;
export type MembersSpacesControllerUpdateMembersSpaceMutationError = void;

/**
 * @summary Update an members space
 */
export const useMembersSpacesControllerUpdateMembersSpace = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof membersSpacesControllerUpdateMembersSpace>>,
    TError,
    { membersSpaceId: string; data: UpdateMembersSpaceDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof membersSpacesControllerUpdateMembersSpace>>,
  TError,
  { membersSpaceId: string; data: UpdateMembersSpaceDto },
  TContext
> => {
  const mutationOptions =
    getMembersSpacesControllerUpdateMembersSpaceMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Deletes an members space for the authenticated user.
 * @summary Delete an members space
 */
export const membersSpacesControllerDeleteMembersSpace = (
  membersSpaceId: string,
  options?: SecondParameter<typeof customFetch>,
) => {
  return customFetch<SuccessMessageResponseDto>(
    {
      url: `/members-spaces/${encodeURIComponent(String(membersSpaceId))}`,
      method: 'DELETE',
    },
    options,
  );
};

export const getMembersSpacesControllerDeleteMembersSpaceMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof membersSpacesControllerDeleteMembersSpace>>,
    TError,
    { membersSpaceId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof membersSpacesControllerDeleteMembersSpace>>,
  TError,
  { membersSpaceId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof membersSpacesControllerDeleteMembersSpace>>,
    { membersSpaceId: string }
  > = (props) => {
    const { membersSpaceId } = props ?? {};

    return membersSpacesControllerDeleteMembersSpace(
      membersSpaceId,
      requestOptions,
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type MembersSpacesControllerDeleteMembersSpaceMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof membersSpacesControllerDeleteMembersSpace>>
  >;

export type MembersSpacesControllerDeleteMembersSpaceMutationError = void;

/**
 * @summary Delete an members space
 */
export const useMembersSpacesControllerDeleteMembersSpace = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof membersSpacesControllerDeleteMembersSpace>>,
    TError,
    { membersSpaceId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof membersSpacesControllerDeleteMembersSpace>>,
  TError,
  { membersSpaceId: string },
  TContext
> => {
  const mutationOptions =
    getMembersSpacesControllerDeleteMembersSpaceMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Retrieves all subscriptions associated with the current user, including related space group and brand information.
 * @summary Get all member subscriptions for a user
 */
export const membersSpacesControllerGetMembersSuscriptions = (
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<MembersSpacesControllerGetMembersSuscriptions200Item[]>(
    { url: `/members-spaces/subscriptions`, method: 'GET', signal },
    options,
  );
};

export const getMembersSpacesControllerGetMembersSuscriptionsQueryKey = () => {
  return [`/members-spaces/subscriptions`] as const;
};

export const getMembersSpacesControllerGetMembersSuscriptionsQueryOptions = <
  TData = Awaited<
    ReturnType<typeof membersSpacesControllerGetMembersSuscriptions>
  >,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof membersSpacesControllerGetMembersSuscriptions>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getMembersSpacesControllerGetMembersSuscriptionsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof membersSpacesControllerGetMembersSuscriptions>>
  > = ({ signal }) =>
    membersSpacesControllerGetMembersSuscriptions(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof membersSpacesControllerGetMembersSuscriptions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type MembersSpacesControllerGetMembersSuscriptionsQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof membersSpacesControllerGetMembersSuscriptions>>
  >;
export type MembersSpacesControllerGetMembersSuscriptionsQueryError = void;

export function useMembersSpacesControllerGetMembersSuscriptions<
  TData = Awaited<
    ReturnType<typeof membersSpacesControllerGetMembersSuscriptions>
  >,
  TError = void,
>(options: {
  query: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof membersSpacesControllerGetMembersSuscriptions>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<
          ReturnType<typeof membersSpacesControllerGetMembersSuscriptions>
        >,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customFetch>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useMembersSpacesControllerGetMembersSuscriptions<
  TData = Awaited<
    ReturnType<typeof membersSpacesControllerGetMembersSuscriptions>
  >,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof membersSpacesControllerGetMembersSuscriptions>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<
          ReturnType<typeof membersSpacesControllerGetMembersSuscriptions>
        >,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useMembersSpacesControllerGetMembersSuscriptions<
  TData = Awaited<
    ReturnType<typeof membersSpacesControllerGetMembersSuscriptions>
  >,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof membersSpacesControllerGetMembersSuscriptions>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get all member subscriptions for a user
 */

export function useMembersSpacesControllerGetMembersSuscriptions<
  TData = Awaited<
    ReturnType<typeof membersSpacesControllerGetMembersSuscriptions>
  >,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof membersSpacesControllerGetMembersSuscriptions>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getMembersSpacesControllerGetMembersSuscriptionsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get all member subscriptions for a user
 */
export const prefetchMembersSpacesControllerGetMembersSuscriptions = async <
  TData = Awaited<
    ReturnType<typeof membersSpacesControllerGetMembersSuscriptions>
  >,
  TError = void,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof membersSpacesControllerGetMembersSuscriptions>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions =
    getMembersSpacesControllerGetMembersSuscriptionsQueryOptions(options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getMembersSpacesControllerGetMembersSuscriptionsSuspenseQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof membersSpacesControllerGetMembersSuscriptions>
    >,
    TError = void,
  >(options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<typeof membersSpacesControllerGetMembersSuscriptions>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  }) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getMembersSpacesControllerGetMembersSuscriptionsQueryKey();

    const queryFn: QueryFunction<
      Awaited<ReturnType<typeof membersSpacesControllerGetMembersSuscriptions>>
    > = ({ signal }) =>
      membersSpacesControllerGetMembersSuscriptions(requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof membersSpacesControllerGetMembersSuscriptions>>,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type MembersSpacesControllerGetMembersSuscriptionsSuspenseQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof membersSpacesControllerGetMembersSuscriptions>>
  >;
export type MembersSpacesControllerGetMembersSuscriptionsSuspenseQueryError =
  void;

export function useMembersSpacesControllerGetMembersSuscriptionsSuspense<
  TData = Awaited<
    ReturnType<typeof membersSpacesControllerGetMembersSuscriptions>
  >,
  TError = void,
>(options: {
  query: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof membersSpacesControllerGetMembersSuscriptions>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useMembersSpacesControllerGetMembersSuscriptionsSuspense<
  TData = Awaited<
    ReturnType<typeof membersSpacesControllerGetMembersSuscriptions>
  >,
  TError = void,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof membersSpacesControllerGetMembersSuscriptions>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useMembersSpacesControllerGetMembersSuscriptionsSuspense<
  TData = Awaited<
    ReturnType<typeof membersSpacesControllerGetMembersSuscriptions>
  >,
  TError = void,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof membersSpacesControllerGetMembersSuscriptions>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get all member subscriptions for a user
 */

export function useMembersSpacesControllerGetMembersSuscriptionsSuspense<
  TData = Awaited<
    ReturnType<typeof membersSpacesControllerGetMembersSuscriptions>
  >,
  TError = void,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof membersSpacesControllerGetMembersSuscriptions>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getMembersSpacesControllerGetMembersSuscriptionsSuspenseQueryOptions(
      options,
    );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}
