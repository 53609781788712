import { PlusIcon } from 'lucide-react';
import { useEffect, useState } from 'react';

import {
  AvailabilityDtoDayOfWeek,
  UpdateCreatorMonetizationSettingsDto,
  useUserControllerUpdateMonetizationSettings,
  userControllerGetMonetizationSettings,
} from '@/api';
import {
  appleCalendarIcon,
  googleCalendarIcon,
  microsoftCalendarIcon,
} from '@/assets/icon';
import { CloseIcon } from '@/assets/icon/close';
import { Loading } from '@/components/Loading';
import InputField from '@/components/ui/InputField';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Switch } from '@/components/ui/switch';
import { useToast } from '@/hooks/use-toast';
import {
  hourTimeIntervals,
  timeZonesWithOffsets,
} from '@/utils/CountryProvider';

type settingsMock = {
  price: number;
  enablePaidMessaging: boolean;
  calendars: {
    google: boolean;
    microsoft: boolean;
    apple: boolean;
  };
  enableCallBooking: boolean;
  durationPricing: {
    duration: number;
    price: number;
    type: string; // mins, hours
  }[];
  dateAvailability: {
    weekDay: string;
    available: boolean;
    from: string;
    to: string;
  }[];
  defaultTimezone: string;
  maxCallsPerDay: number;
  maxCallsPerMonth: number;
  bufferTime: number;
  bufferTimeType: string;
};

const MonetizationTab = () => {
  const { toast } = useToast();
  const [settings, setSettings] = useState<settingsMock>();
  const [loading, setLoading] = useState(false);

  const updateUserMonetizationMutation =
    useUserControllerUpdateMonetizationSettings();

  useEffect(() => {
    fetchSettings();
  }, []);

  const handleUpdate = async () => {
    if (!settings) return;

    if (
      settings?.enablePaidMessaging &&
      settings?.durationPricing.length === 0
    ) {
      toast({
        title: 'Error',
        description: 'Please set at least one pricing tier for messaging',
        variant: 'destructive',
      });
      return;
    }

    setLoading(true);

    const payload: UpdateCreatorMonetizationSettingsDto = {
      isMessagingEnabled: settings.enablePaidMessaging,
      pricePerMessage: settings.price,
      isCallMeetingsEnabled: settings.enableCallBooking,
      bufferTime: settings.bufferTime,
      bufferTimeType: 'MINUTES',
      maxCallsPerDay: settings.maxCallsPerDay,
      maxCallsPerMonth: settings.maxCallsPerMonth,
      timezone: settings.defaultTimezone,
      priceTiers: settings.durationPricing.map((item) => ({
        duration: item.duration,
        durationType: item.type === 'mins' ? 'MINUTES' : 'HOURS',
        price: item.price,
      })),
      availability: settings.dateAvailability.map((item) => ({
        dayOfWeek: item.weekDay.toUpperCase() as AvailabilityDtoDayOfWeek,
        startTime: item.from.slice(0, 5),
        endTime: item.to.slice(0, 5),
        isEnabled: item.available,
      })),
    };

    await updateUserMonetizationMutation
      .mutateAsync({
        data: payload!,
      })
      .then((res) => {
        if (res.message)
          toast({
            title: 'Success',
            description: 'Settings updated successfully',
            variant: 'success',
          });
      })
      .finally(() => {
        setLoading(false);
      });

    fetchSettings();
  };

  const fetchSettings = async () => {
    const data = await userControllerGetMonetizationSettings();

    setSettings({
      enablePaidMessaging: data.isMessagingEnabled,
      price: data.pricePerMessage,
      calendars: {
        google: false,
        microsoft: false,
        apple: false,
      },
      enableCallBooking: data.isCallMeetingsEnabled,
      durationPricing: data.priceTiers.map((item) => ({
        duration: item.duration,
        price: item.price,
        type: item.durationType === 'MINUTES' ? 'mins' : 'hours',
      })),
      dateAvailability:
        data.availability.length === 0
          ? setupDays()
          : data.availability.map((item) => ({
              weekDay: item.dayOfWeek,
              available: item.isEnabled,
              from: item.startTime,
              to: item.endTime,
            })),
      defaultTimezone:
        data.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone,
      maxCallsPerDay: data.maxCallsPerDay,
      maxCallsPerMonth: data.maxCallsPerMonth,
      bufferTime: data.bufferTime,
      bufferTimeType: data.bufferTimeType,
    });
  };

  const setupDays = () => {
    const days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    return days.map((day) => ({
      weekDay: day,
      available: false,
      from: '09:00',
      to: '17:00',
    }));
  };

  if (!settings) {
    return <Loading className="pt-20" />;
  } else {
    return (
      <div className="flex w-full flex-col gap-10 pt-10">
        <div className="flex w-full flex-col gap-16">
          <div className="flex w-full flex-col gap-8">
            <h2 className="text-lg font-semibold">1:1 Messaging</h2>
            <div className="flex h-12 items-center gap-2.5">
              <div className="w-full text-base font-medium">
                Enable paid messaging
              </div>
              <Switch
                onCheckedChange={(checked) => {
                  setSettings({
                    ...settings,
                    enablePaidMessaging: checked,
                  });
                }}
                checked={settings.enablePaidMessaging}
              />
            </div>
            <InputField
              startChar="$"
              type="number"
              title="Price Per Message"
              inputClassName="!bg-transparent border-light dark:border-dark-light"
              regex={/^\d*\.?\d{0,2}$/}
              value={settings.price}
              onChange={(e) =>
                setSettings({ ...settings, price: Number(e.target.value) })
              }
            />
          </div>
          <div className="flex w-full flex-col gap-8">
            <h2 className="text-lg font-semibold">1:1 Call booking</h2>
            <div className="flex flex-col gap-4">
              <h3 className="text-base font-medium">Connect your calendar</h3>
              <div className="flex w-full flex-col gap-6 rounded-2xl bg-light-2 p-4 dark:bg-dark-2">
                <div className="flex w-full items-center gap-2.5">
                  <img
                    alt="google calendar"
                    className="h-12 w-12 flex-shrink-0"
                    src={googleCalendarIcon}
                  />
                  <div className="w-full text-base font-medium">
                    Google calendar
                  </div>
                  <Button>Connect</Button>
                </div>
                <div className="flex items-center gap-2.5">
                  <img
                    alt="google calendar"
                    className="h-12 w-12 flex-shrink-0"
                    src={microsoftCalendarIcon}
                  />
                  <div className="w-full text-base font-medium">
                    Microsoft calendar
                  </div>
                  <Button>Connect</Button>
                </div>
                <div className="flex items-center gap-2.5">
                  <img
                    alt="google calendar"
                    className="h-12 w-12 flex-shrink-0"
                    src={appleCalendarIcon}
                  />
                  <div className="w-full text-base font-medium">
                    Apple calendar
                  </div>
                  <Button>Connect</Button>
                </div>
              </div>
            </div>
            <div className="flex h-12 items-center gap-2.5">
              <div className="w-full text-base font-medium">
                Allow members to schedule a calls
              </div>
              <Switch
                onCheckedChange={(checked) => {
                  setSettings({
                    ...settings,
                    enableCallBooking: checked,
                  });
                }}
                checked={settings.enableCallBooking}
              />
            </div>
            <div className="flex w-full flex-col gap-6">
              <h3 className="text-base font-medium">
                Set pricing base on duration
              </h3>
              <div className="flex w-full flex-col gap-4">
                <div className="flex flex-col rounded-md border border-light dark:border-dark-light">
                  <div className="flex border-b border-light dark:border-dark-light">
                    <h4 className="w-1/2 p-2.5 text-base font-medium text-textParagraph dark:text-dark-textParagraph">
                      From
                    </h4>
                    <h4 className="w-1/2 p-2.5 text-base font-medium text-textParagraph dark:text-dark-textParagraph">
                      Price
                    </h4>
                  </div>
                  <div>
                    {settings.durationPricing.map((item, index) => (
                      <div key={index} className="flex items-center">
                        <div className="flex w-1/2 items-center gap-2.5 p-2.5">
                          <Select
                            onValueChange={(value) => {
                              const newDurationPricing = [
                                ...settings.durationPricing,
                              ];
                              newDurationPricing[index].duration =
                                Number(value);
                              setSettings({
                                ...settings,
                                durationPricing: newDurationPricing,
                              });
                            }}
                          >
                            <SelectTrigger className="mt-1 flex h-fit w-full gap-2 rounded-lg border border-transparent bg-light-2 p-4 text-base shadow-md placeholder:text-textParagraph dark:bg-dark-2">
                              {item.duration}
                            </SelectTrigger>
                            <SelectContent>
                              {item.type === 'mins'
                                ? [15, 30, 45].map((time) => (
                                    <SelectItem
                                      key={time}
                                      value={time.toString()}
                                    >
                                      {time}
                                    </SelectItem>
                                  ))
                                : [1, 2, 3, 4, 5].map((time) => (
                                    <SelectItem
                                      key={time}
                                      value={time.toString()}
                                    >
                                      {time}
                                    </SelectItem>
                                  ))}
                            </SelectContent>
                          </Select>
                          <Select
                            onValueChange={(value) => {
                              const newDurationPricing = [
                                ...settings.durationPricing,
                              ];
                              newDurationPricing[index].type = value;
                              if (value === 'hours') {
                                newDurationPricing[index].duration = 1;
                              } else {
                                newDurationPricing[index].duration = 15;
                              }
                              setSettings({
                                ...settings,
                                durationPricing: newDurationPricing,
                              });
                            }}
                          >
                            <SelectTrigger
                              value={item.type === 'mins' ? 'mins' : 'hours'}
                              className="mt-1 flex h-fit w-full gap-2 rounded-lg border border-transparent bg-light-2 p-4 text-base shadow-md placeholder:text-textParagraph dark:bg-dark-2"
                            >
                              <span className="text-base font-medium text-textParagraph dark:text-dark-textParagraph">
                                {item.type}
                              </span>
                            </SelectTrigger>
                            <SelectContent>
                              <SelectItem value="mins">mins</SelectItem>
                              <SelectItem value="hours">hours</SelectItem>
                            </SelectContent>
                          </Select>
                        </div>
                        <div className="flex w-1/2 items-center gap-2.5 p-2.5">
                          <InputField
                            type="number"
                            inputClassName="!bg-light-2 dark:!bg-dark-2"
                            value={item.price}
                            startChar="$"
                            className="w-full"
                            onChange={(e) => {
                              const newDurationPricing = [
                                ...settings.durationPricing,
                              ];
                              newDurationPricing[index].price = Number(
                                e.target.value,
                              );
                              setSettings({
                                ...settings,
                                durationPricing: newDurationPricing,
                              });
                            }}
                          />
                          <Button
                            variant={'icon'}
                            className="!w-fit p-0"
                            onClick={() => {
                              const newDurationPricing = [
                                ...settings.durationPricing,
                              ];
                              newDurationPricing.splice(index, 1);
                              setSettings({
                                ...settings,
                                durationPricing: newDurationPricing,
                              });
                            }}
                          >
                            <CloseIcon className="m-2 h-7 w-7 flex-shrink-0 fill-light-gray dark:fill-dark-light-gray" />
                          </Button>
                        </div>
                      </div>
                    ))}
                    {settings.durationPricing.length === 0 && (
                      <div className="flex items-center justify-center p-2.5 text-base font-normal text-textParagraph dark:text-dark-textParagraph">
                        No pricing set yet.
                      </div>
                    )}
                  </div>
                </div>
                <Button
                  variant={'outline'}
                  className="flex gap-2.5 border-black text-base font-medium text-black dark:border-white dark:text-white"
                  onClick={() => {
                    setSettings({
                      ...settings,
                      durationPricing: [
                        ...settings.durationPricing,
                        {
                          duration: 0,
                          price: 0,
                          type: 'mins',
                        },
                      ],
                    });
                  }}
                >
                  Add new{' '}
                  <PlusIcon className="h-5 w-5 stroke-black dark:stroke-white" />
                </Button>
              </div>
            </div>
            <div className="flex w-full flex-col gap-6">
              <h3 className="text-base font-medium">
                Set calendar availability
              </h3>
              <div className="flex w-full flex-col gap-4">
                <div className="flex flex-col rounded-md border border-light dark:border-dark-light">
                  <div className="flex items-center border-b border-light dark:border-dark-light">
                    <Checkbox
                      className="m-3"
                      onClick={() => {
                        const newDateAvailability = [
                          ...settings.dateAvailability,
                        ];
                        if (
                          newDateAvailability.some((item) => item.available)
                        ) {
                          newDateAvailability.forEach((item) => {
                            item.available = false;
                          });
                        } else {
                          newDateAvailability.forEach((item) => {
                            item.available = true;
                          });
                        }
                        setSettings({
                          ...settings,
                          dateAvailability: newDateAvailability,
                        });
                      }}
                      checked={
                        settings.dateAvailability.filter(
                          (item) => item.available,
                        ).length === settings.dateAvailability.length
                      }
                    />
                    <div className="w-full p-2.5 text-base font-medium text-textParagraph dark:text-dark-textParagraph">
                      Days
                    </div>
                    <div className="w-full p-2.5 text-base font-medium text-textParagraph dark:text-dark-textParagraph">
                      From
                    </div>
                    <div className="w-full p-2.5 text-base font-medium text-textParagraph dark:text-dark-textParagraph">
                      To
                    </div>
                  </div>
                  <div>
                    {settings.dateAvailability.map((item, index) => (
                      <div key={index} className="flex items-center">
                        <Checkbox
                          className="m-3"
                          onClick={() => {
                            const newDateAvailability = [
                              ...settings.dateAvailability,
                            ];
                            newDateAvailability[index].available =
                              !item.available;
                            setSettings({
                              ...settings,
                              dateAvailability: newDateAvailability,
                            });
                          }}
                          checked={item.available}
                        />
                        <div className="w-full p-2.5 text-base font-normal capitalize">
                          {item.weekDay.toLowerCase()}
                        </div>
                        <div className="w-full p-2.5">
                          <Select
                            onValueChange={(value) => {
                              const newDateAvailability = [
                                ...settings.dateAvailability,
                              ];
                              newDateAvailability[index].from = value;
                              setSettings({
                                ...settings,
                                dateAvailability: newDateAvailability,
                              });
                            }}
                          >
                            <SelectTrigger className="flex h-fit w-full gap-2 rounded-lg border border-transparent bg-light-2 p-4 text-base shadow-md placeholder:text-textParagraph dark:bg-dark-2">
                              {
                                <span className="text-base font-medium text-textParagraph dark:text-dark-textParagraph">
                                  {item.from.slice(0, 5)}
                                </span>
                              }
                            </SelectTrigger>
                            <SelectContent>
                              {hourTimeIntervals.map((time) => (
                                <SelectItem key={time} value={time}>
                                  {time}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        </div>
                        <div className="w-full p-2.5">
                          <Select
                            onValueChange={(value) => {
                              const newDateAvailability = [
                                ...settings.dateAvailability,
                              ];
                              newDateAvailability[index].to = value;
                              setSettings({
                                ...settings,
                                dateAvailability: newDateAvailability,
                              });
                            }}
                          >
                            <SelectTrigger className="flex h-fit w-full gap-2 rounded-lg border border-transparent bg-light-2 p-4 text-base shadow-md placeholder:text-textParagraph dark:bg-dark-2">
                              {
                                <span className="text-base font-medium text-textParagraph dark:text-dark-textParagraph">
                                  {item.to.slice(0, 5)}
                                </span>
                              }
                            </SelectTrigger>
                            <SelectContent>
                              {hourTimeIntervals.map((time) => (
                                <SelectItem key={time} value={time}>
                                  {time}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-2.5">
              <div className="text-base font-medium">Default timezone</div>
              <Select
                value={settings.defaultTimezone}
                onValueChange={(value) => {
                  setSettings({
                    ...settings,
                    defaultTimezone: value,
                  });
                }}
              >
                <SelectTrigger className="h-fit border-light !bg-transparent p-4 dark:border-dark-light">
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  {timeZonesWithOffsets.map((timezone) => (
                    <SelectItem
                      key={timezone.value}
                      value={timezone.value}
                      className="cursor-pointer"
                    >
                      {timezone.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <InputField
              title="Set the maximum calls allowed per day."
              type="number"
              inputClassName="!bg-transparent border-light dark:border-dark-light"
              value={settings.maxCallsPerDay}
              onChange={(e) =>
                setSettings({
                  ...settings,
                  maxCallsPerDay: Number(e.target.value),
                })
              }
            />
            <InputField
              title="Set the maximum calls allowed per month."
              type="number"
              inputClassName="!bg-transparent border-light dark:border-dark-light"
              value={settings.maxCallsPerMonth}
              onChange={(e) =>
                setSettings({
                  ...settings,
                  maxCallsPerMonth: Number(e.target.value),
                })
              }
            />
            <div className="flex flex-col gap-2.5">
              <div className="text-base font-medium">Buffer time</div>
              <Select
                onValueChange={(value) => {
                  const bufferNumber = Number(value.split(' ')[0]);
                  const bufferType = value.split(' ')[1].toUpperCase();

                  setSettings({
                    ...settings,
                    bufferTime: bufferNumber,
                    bufferTimeType: bufferType,
                  });
                }}
              >
                <SelectTrigger className="h-fit border-light !bg-transparent p-4 dark:border-dark-light">
                  {settings.bufferTime} {settings.bufferTimeType.toLowerCase()}
                </SelectTrigger>
                <SelectContent>
                  {[
                    '0 minutes',
                    '5 minutes',
                    '10 minutes',
                    '15 minutes',
                    '20 minutes',
                    '25 minutes',
                    '30 minutes',
                  ].map((time) => (
                    <SelectItem key={time} value={time}>
                      {time}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </div>
        </div>
        <div className="flex justify-end gap-2.5">
          <Button
            className="h-fit p-4"
            variant={'outline'}
            onClick={fetchSettings}
          >
            Cancel
          </Button>
          <Button
            className="h-fit p-4"
            onClick={handleUpdate}
            disabled={loading}
          >
            Save changes
          </Button>
        </div>
      </div>
    );
  }
};

export default MonetizationTab;
