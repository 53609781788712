export const CheckRoundedIcon = (props) => (
  <svg
    width="14"
    height="11"
    viewBox="0 0 14 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      id="Vector 1"
      d="M1 6.13636L2.94996 8.83631C3.75945 9.95714 5.40762 10.0164 6.29554 8.95665L13 0.954544"
      strokeWidth="1.33333"
      strokeLinecap="round"
    />
  </svg>
);
