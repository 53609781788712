import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import { XIcon } from 'lucide-react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import {
  EventSpace,
  getBrandsControllerGetSpaceGroupsOfBrandQueryKey,
  useEventSpacesControllerUpdateEventSpace,
} from '@/api';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Separator } from '@/components/ui/separator';
import { Switch } from '@/components/ui/switch';
import { cn, imageToBase64, valueOrNull } from '@/lib/utils';
import { colorsOrEmojis } from '@/utils/Emojis';

import { ImageDropzone } from './image-dropzone';

export const ChangeCoverEventsSpaceDialog = ({
  eventsSpace,
  brandId,
}: {
  eventsSpace: EventSpace;
  brandId: string;
}) => {
  const [open, setOpen] = useState(false);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild className="w-full justify-start p-2 font-medium">
        <Button variant="secondary" className="rounded-sm">
          Change Cover
        </Button>
      </DialogTrigger>
      <EventsSpaceSettingsDialogContent
        key={open.toString()}
        eventsSpace={eventsSpace}
        brandId={brandId}
        closeDialog={() => setOpen(false)}
      />
    </Dialog>
  );
};

export const EventsSpaceSettingsDialogTrigger = () => {
  return (
    <DialogTrigger asChild className="w-full justify-start p-2 font-medium">
      <Button variant="none" size="none" className="">
        <span>Settings</span>
      </Button>
    </DialogTrigger>
  );
};

const renderColorOrEmoji = (colorOrEmojiIndex: number) => {
  const colorOrEmoji = colorsOrEmojis[colorOrEmojiIndex];

  if (!colorOrEmoji) {
    return null;
  }

  if (colorOrEmoji.type === 'color') {
    return renderColor(colorOrEmoji.value);
  }
  return <span>{colorOrEmoji.value}</span>;
};

const renderColor = (color: string) => {
  return (
    <div
      className={cn('h-3 w-3 rounded-full')}
      style={{
        backgroundColor: color,
      }}
    />
  );
};

const MB_BYTES = 1000000; // Number of bytes in a megabyte.

// This is the list of mime types you will accept with the schema
const ACCEPTED_MIME_TYPES = [
  'image/gif',
  'image/jpeg',
  'image/png',
  'image/jpg',
];

const formSchema = z
  .object({
    colorOrEmojiIndex: z
      .number()
      .min(0)
      .max(colorsOrEmojis.length - 1)
      .optional(),
    spaceName: z.string().min(1, {
      message: 'Space name is required',
    }),
    image: z.instanceof(File).optional(),
    rsvp: z.boolean().default(false),
    isUsingOriginalImage: z.boolean(),
  })
  .transform((data) => {
    if (data.isUsingOriginalImage) {
      return {
        ...data,
        image: undefined,
      };
    }
    return data;
  });

type FormSchema = z.infer<typeof formSchema>;

export const EventsSpaceSettingsDialogContent = ({
  closeDialog,
  eventsSpace,
  brandId,
}: {
  closeDialog: () => void;
  eventsSpace: EventSpace;
  brandId: string;
}) => {
  const defaultColorOrEmojiIndex = colorsOrEmojis.findIndex(
    (colorOrEmoji) =>
      colorOrEmoji.type === (eventsSpace.color ? 'color' : 'emoji') &&
      colorOrEmoji.value ===
        (eventsSpace.color ? eventsSpace.color : eventsSpace.emoji),
  );

  const defaultColorOrEmojiIndexRefined =
    defaultColorOrEmojiIndex === -1 ? undefined : defaultColorOrEmojiIndex;

  const form = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    values: {
      colorOrEmojiIndex: defaultColorOrEmojiIndexRefined,
      spaceName: eventsSpace.name,
      rsvp: eventsSpace.autoRsvp,
      isUsingOriginalImage: eventsSpace.image ? true : false,
    },
  });

  function handleFileChange(file: File | null | undefined) {
    if (!file) {
      form.setValue('image', undefined);
      return;
    }

    if (!ACCEPTED_MIME_TYPES.includes(file.type)) {
      form.setValue('image', undefined);
      form.setError('image', {
        type: 'typeError',
        message: `Unsupported file type`,
      });
    } else if (file.size > 3 * MB_BYTES) {
      form.setValue('image', undefined);
      form.setError('image', {
        type: 'fileSizeError',
        message: `The file must not be larger than ${3 * MB_BYTES} bytes: ${
          file.size
        }`,
      });
    } else {
      form.setValue('image', file);
      form.clearErrors('image');
    }
  }

  const clearFormAndCloseDialog = () => {
    form.reset();
    closeDialog();
  };

  const queryClient = useQueryClient();
  const updateEventsSpaceMutation = useEventSpacesControllerUpdateEventSpace({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: getBrandsControllerGetSpaceGroupsOfBrandQueryKey(brandId),
        });
        clearFormAndCloseDialog();
      },
    },
  });

  const handleSubmit = form.handleSubmit(async (data) => {
    const base64Image = data.image
      ? await imageToBase64(data.image)
      : undefined;

    const colorOrEmoji =
      data.colorOrEmojiIndex !== undefined
        ? colorsOrEmojis[data.colorOrEmojiIndex]
        : undefined;

    const colorRaw = colorOrEmoji
      ? colorOrEmoji.type === 'color'
        ? colorOrEmoji.value
        : null
      : undefined;

    const emoji = colorOrEmoji
      ? colorOrEmoji.type === 'emoji'
        ? colorOrEmoji.value
        : null
      : undefined;

    let color: string | null | undefined = colorRaw
      ? colorRaw
      : eventsSpace.color
        ? eventsSpace.color
        : undefined;

    if (emoji) {
      color = null;
    }

    // if the form values are the same as the eventsSpace values and there is no image, return
    if (
      data.spaceName === eventsSpace.name &&
      valueOrNull(color) === eventsSpace.color &&
      valueOrNull(emoji) === eventsSpace.emoji &&
      data.rsvp === eventsSpace.autoRsvp &&
      !data.image &&
      data.isUsingOriginalImage
    ) {
      clearFormAndCloseDialog();
      return;
    }

    updateEventsSpaceMutation.mutate({
      eventSpaceId: eventsSpace.id,
      data: {
        name: data.spaceName,
        color,
        emoji,
        image: base64Image,
        autoRsvp: data.rsvp,
      },
    });
  });

  const image = form.watch('image');
  const isUsingOriginalImage = form.watch('isUsingOriginalImage');

  const getImageUrl = () => {
    if (image) {
      return URL.createObjectURL(image);
    }
    return '';
  };

  const handleRemoveOriginalImage = () => {
    form.setValue('isUsingOriginalImage', false);
  };

  return (
    <DialogContent className="flex w-[90%] max-w-3xl flex-col gap-9 rounded-3xl md:w-3/4 md:p-10 lg:w-1/2">
      <DialogHeader className="flex-row items-center justify-between space-y-0">
        <DialogTitle className="text-2xl font-semibold">Settings</DialogTitle>
        <Button
          onClick={clearFormAndCloseDialog}
          variant="icon"
          size="icon"
          className="m-0 rounded-sm p-0 ring-offset-background transition-opacity focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
        >
          <XIcon className="h-4 w-4" />
          <span className="sr-only">Close</span>
        </Button>
      </DialogHeader>
      <div className="font-medium text-[#9E9E9E] dark:text-[#AEAEAE]">
        <Form {...form}>
          <form onSubmit={handleSubmit} className="space-y-5">
            <div className="h-fit">
              <Label
                className={cn(
                  'text-base font-medium text-black dark:text-white',
                  form.formState.errors.spaceName &&
                    'text-destructive dark:text-destructive',
                )}
              >
                Space name
              </Label>
              <div className="mt-2 flex h-12 w-full items-center gap-1 rounded-lg border">
                <FormField
                  control={form.control}
                  name="colorOrEmojiIndex"
                  render={({ field }) => (
                    <FormItem>
                      <Select
                        onValueChange={(value) => {
                          field.onChange(parseInt(value));
                        }}
                        defaultValue={
                          field.value?.toString() ??
                          eventsSpace.color ??
                          undefined
                        }
                      >
                        <FormControl>
                          <SelectTrigger className="w-fit gap-1 border-0">
                            <SelectValue
                              asChild
                              aria-label={
                                field.value?.toString() ??
                                eventsSpace.color ??
                                undefined
                              }
                            >
                              {field.value !== undefined
                                ? renderColorOrEmoji(field.value)
                                : eventsSpace.color
                                  ? renderColor(eventsSpace.color)
                                  : undefined}
                            </SelectValue>
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent className="w-full">
                          {colorsOrEmojis.map((colorOrEmoji, index) => (
                            <SelectItem
                              key={colorOrEmoji.value}
                              value={index.toString()}
                            >
                              {renderColorOrEmoji(index)}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <Separator orientation="vertical" className="h-1/2" />
                <FormField
                  control={form.control}
                  name="spaceName"
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <FormControl>
                        <Input
                          type="text"
                          placeholder="Enter space name"
                          className={cn(
                            'w-full border-0 p-2 px-2 text-base font-normal text-black shadow-sm placeholder:text-[#9E9E9E] dark:text-white dark:placeholder:text-[#AEAEAE]',
                          )}
                          {...field}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
              </div>
              <p className={cn('mt-1 text-sm font-medium text-destructive')}>
                {form.formState.errors.spaceName?.message}
              </p>
            </div>

            <FormField
              control={form.control}
              name="image"
              render={({ field }) => {
                return (
                  <FormItem
                    className={cn((image || isUsingOriginalImage) && 'hidden')}
                  >
                    <FormLabel className="text-base font-medium text-black dark:text-white">
                      Upload banner
                    </FormLabel>

                    <FormControl>
                      <ImageDropzone
                        {...field}
                        infoText=" JPEG, PNG formats, up to 50 MB."
                        onFileChange={handleFileChange}
                        ratio={4 / 1}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />

            {isUsingOriginalImage && eventsSpace.image && (
              <div className="flex flex-col items-center justify-center gap-6 space-y-2 rounded-lg border-2 border-dashed bg-[#F3F4F6] px-2 py-4 hover:border-muted-foreground/50 dark:bg-[#202224]">
                <img src={eventsSpace.image} className="w-80" />
                <Button
                  variant="ghost"
                  className="border border-light dark:border-dark-light"
                  onClick={handleRemoveOriginalImage}
                >
                  Remove image
                </Button>
              </div>
            )}
            {image && (
              <div className="flex flex-col items-center justify-center gap-6 space-y-2 rounded-lg border-2 border-dashed bg-[#F3F4F6] px-2 py-4 hover:border-muted-foreground/50 dark:bg-[#202224]">
                <img src={getImageUrl()} className="w-80" />
                <Button
                  variant="ghost"
                  className="border border-light dark:border-dark-light"
                  onClick={() => handleFileChange(null)}
                >
                  Replace image
                </Button>
              </div>
            )}

            <FormField
              control={form.control}
              name="rsvp"
              render={({ field }) => (
                <FormItem className="flex flex-row items-center justify-between">
                  <FormLabel className="text-base font-medium text-black dark:text-white">
                    Auto-RSVP space members to new events
                  </FormLabel>
                  <FormControl>
                    <Switch
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <Button
              type="submit"
              className="w-full rounded-lg"
              disabled={updateEventsSpaceMutation.isPending}
            >
              Save
            </Button>
          </form>
        </Form>
      </div>
    </DialogContent>
  );
};
