import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import { XIcon } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import {
  ChatsSpace,
  getBrandsControllerGetSpaceGroupsOfBrandQueryKey,
  useChatsSpacesControllerUpdateChatsSpace,
} from '@/api';
import { Button } from '@/components/ui/button';
import {
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Separator } from '@/components/ui/separator';
import { cn, valueOrNull } from '@/lib/utils';
import { colorsOrEmojis } from '@/utils/Emojis';

export const ChatsSpaceSettingsDialogTrigger = () => {
  return (
    <DialogTrigger asChild className="w-full justify-start p-2 font-medium">
      <Button variant="none" size="none" className="">
        <span>Settings</span>
      </Button>
    </DialogTrigger>
  );
};

const renderColorOrEmoji = (colorOrEmojiIndex: number) => {
  const colorOrEmoji = colorsOrEmojis[colorOrEmojiIndex];

  if (!colorOrEmoji) {
    return null;
  }

  if (colorOrEmoji.type === 'color') {
    return renderColor(colorOrEmoji.value);
  }
  return <span>{colorOrEmoji.value}</span>;
};

const renderColor = (color: string) => {
  return (
    <div
      className={cn('h-3 w-3 rounded-full')}
      style={{
        backgroundColor: color,
      }}
    />
  );
};

const formSchema = z.object({
  colorOrEmojiIndex: z
    .number()
    .min(0)
    .max(colorsOrEmojis.length - 1)
    .optional(),
  spaceName: z.string().min(1, {
    message: 'Space name is required',
  }),
});

type FormSchema = z.infer<typeof formSchema>;

export const ChatsSpaceSettingsDialogContent = ({
  closeDialog,
  chatsSpace,
  brandId,
}: {
  closeDialog: () => void;
  chatsSpace: ChatsSpace;
  brandId: string;
}) => {
  const defaultColorOrEmojiIndex = colorsOrEmojis.findIndex(
    (colorOrEmoji) =>
      colorOrEmoji.type === (chatsSpace.color ? 'color' : 'emoji') &&
      colorOrEmoji.value ===
        (chatsSpace.color ? chatsSpace.color : chatsSpace.emoji),
  );

  const defaultColorOrEmojiIndexRefined =
    defaultColorOrEmojiIndex === -1 ? undefined : defaultColorOrEmojiIndex;

  const form = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    values: {
      colorOrEmojiIndex: defaultColorOrEmojiIndexRefined,
      spaceName: chatsSpace.name,
    },
  });

  const clearFormAndCloseDialog = () => {
    form.reset();
    closeDialog();
  };

  const queryClient = useQueryClient();
  const updateChatsSpaceMutation = useChatsSpacesControllerUpdateChatsSpace({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: getBrandsControllerGetSpaceGroupsOfBrandQueryKey(brandId),
        });
        clearFormAndCloseDialog();
      },
    },
  });

  const handleSubmit = form.handleSubmit(async (data) => {
    const colorOrEmoji =
      data.colorOrEmojiIndex !== undefined
        ? colorsOrEmojis[data.colorOrEmojiIndex]
        : undefined;

    const colorRaw = colorOrEmoji
      ? colorOrEmoji.type === 'color'
        ? colorOrEmoji.value
        : null
      : undefined;

    const emoji = colorOrEmoji
      ? colorOrEmoji.type === 'emoji'
        ? colorOrEmoji.value
        : null
      : undefined;

    let color: string | null | undefined = colorRaw
      ? colorRaw
      : chatsSpace.color
        ? chatsSpace.color
        : undefined;

    if (emoji) {
      color = null;
    }

    // if the form values are the same as the eventsSpace values and there is no image, return
    if (
      data.spaceName === chatsSpace.name &&
      valueOrNull(color) === chatsSpace.color &&
      valueOrNull(emoji) === chatsSpace.emoji
    ) {
      clearFormAndCloseDialog();
      return;
    }

    updateChatsSpaceMutation.mutate({
      chatsSpaceId: chatsSpace.id,
      data: {
        name: data.spaceName,
        color,
        emoji,
      },
    });
  });

  return (
    <DialogContent className="flex w-[90%] max-w-3xl flex-col gap-9 rounded-3xl md:w-3/4 md:p-10 lg:w-1/2">
      <DialogHeader className="flex-row items-center justify-between space-y-0">
        <DialogTitle className="text-2xl font-semibold">Settings</DialogTitle>
        <Button
          onClick={clearFormAndCloseDialog}
          variant="icon"
          size="icon"
          className="m-0 rounded-sm p-0 ring-offset-background transition-opacity focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
        >
          <XIcon className="h-4 w-4" />
          <span className="sr-only">Close</span>
        </Button>
      </DialogHeader>
      <div className="font-medium text-[#9E9E9E] dark:text-[#AEAEAE]">
        <Form {...form}>
          <form onSubmit={handleSubmit} className="space-y-5">
            <div className="h-fit">
              <Label
                className={cn(
                  'text-base font-medium text-black dark:text-white',
                  form.formState.errors.spaceName &&
                    'text-destructive dark:text-destructive',
                )}
              >
                Space name
              </Label>
              <div className="mt-2 flex h-12 w-full items-center gap-1 rounded-lg border">
                <FormField
                  control={form.control}
                  name="colorOrEmojiIndex"
                  render={({ field }) => (
                    <FormItem>
                      <Select
                        onValueChange={(value) => {
                          field.onChange(parseInt(value));
                        }}
                        defaultValue={
                          field.value?.toString() ??
                          chatsSpace.color ??
                          undefined
                        }
                      >
                        <FormControl>
                          <SelectTrigger className="w-fit gap-1 border-0">
                            <SelectValue
                              asChild
                              aria-label={
                                field.value?.toString() ??
                                chatsSpace.color ??
                                undefined
                              }
                            >
                              {field.value !== undefined
                                ? renderColorOrEmoji(field.value)
                                : chatsSpace.color
                                  ? renderColor(chatsSpace.color)
                                  : undefined}
                            </SelectValue>
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent className="w-full">
                          {colorsOrEmojis.map((colorOrEmoji, index) => (
                            <SelectItem
                              key={colorOrEmoji.value}
                              value={index.toString()}
                            >
                              {renderColorOrEmoji(index)}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <Separator orientation="vertical" className="h-1/2" />
                <FormField
                  control={form.control}
                  name="spaceName"
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <FormControl>
                        <Input
                          type="text"
                          placeholder="Enter space name"
                          className={cn(
                            'w-full border-0 p-2 px-2 text-base font-normal text-black shadow-sm placeholder:text-[#9E9E9E] dark:text-white dark:placeholder:text-[#AEAEAE]',
                          )}
                          {...field}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
              </div>
              <p className={cn('mt-1 text-sm font-medium text-destructive')}>
                {form.formState.errors.spaceName?.message}
              </p>
            </div>

            <Button
              type="submit"
              className="w-full rounded-lg"
              disabled={updateChatsSpaceMutation.isPending}
            >
              Save
            </Button>
          </form>
        </Form>
      </div>
    </DialogContent>
  );
};
