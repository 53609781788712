/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * Teachly API V2
 * Teachly API documentation
 * OpenAPI spec version: 1.0
 */

export type MeetingDtoStatus =
  (typeof MeetingDtoStatus)[keyof typeof MeetingDtoStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MeetingDtoStatus = {
  PENDING: 'PENDING',
  ACCEPTED: 'ACCEPTED',
  DECLINED: 'DECLINED',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
  RESCHEDULING: 'RESCHEDULING',
  RESCHEDULED: 'RESCHEDULED',
} as const;
