import { CometChat } from '@cometchat/chat-sdk-javascript';
import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { useCallback, useEffect, useRef, useState } from 'react';

import { SpaceGroupControllerGetUsersBySpaceGroupIdForMentions200Item } from '@/api';
import { CloseIcon } from '@/assets/icon/close';
import { Loading } from '@/components/Loading';
import { Button } from '@/components/ui/button';
import { Sheet, SheetContent } from '@/components/ui/sheet';

import ChatInput from './ChatInput';
import ChatMessage from './ChatMessage';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  message: CometChat.BaseMessage;
  GUID: string;
  onMentionSearch?: (search: string) => {
    id: string;
    value: string;
    cometChatId: string;
    name: string;
    avatarUrl: string;
  }[];
  users: SpaceGroupControllerGetUsersBySpaceGroupIdForMentions200Item[];
};

const MESSAGES_LIMIT = 100;

const ThreadDrawer = ({
  isOpen,
  onClose,
  message,
  GUID,
  onMentionSearch,
  users,
}: Props) => {
  const [messageInput, setMessageInput] = useState('');
  const [fileInput, setFileInput] = useState<File | null>(null);

  const queryClient = useQueryClient();
  const endMessageRef = useRef<HTMLDivElement>(null);

  const isInitialLoadRef = useRef(true);

  async function getThreadMessages(messageId: number, lastMessageId?: number) {
    const messagesRequest: CometChat.MessagesRequest =
      new CometChat.MessagesRequestBuilder()
        .setGUID(GUID)
        .setLimit(MESSAGES_LIMIT)
        .hideReplies(true)
        .setParentMessageId(messageId)
        .setMessageId(lastMessageId === -1 ? undefined : lastMessageId)
        .setTypes([
          CometChat.MESSAGE_TYPE.TEXT,
          CometChat.MESSAGE_TYPE.IMAGE,
          CometChat.MESSAGE_TYPE.VIDEO,
        ])
        .build();

    const messages = await messagesRequest.fetchPrevious();

    return messages;
  }

  const chatsQuery = useInfiniteQuery({
    queryKey: ['cometchat', GUID, 'thread', message.getId()],
    queryFn: ({ pageParam }) => getThreadMessages(message.getId(), pageParam),
    initialPageParam: -1,
    getNextPageParam: (lastPage) => {
      if (!lastPage || lastPage.length === 0) return undefined;

      if (lastPage.length < MESSAGES_LIMIT) return undefined;

      return lastPage.at(0)?.getId();
    },
  });

  const scrollToBottom = useCallback(
    (args?: { smooth?: boolean; timeout?: number }) => {
      setTimeout(() => {
        if (!endMessageRef.current) return;
        const smooth = args?.smooth ?? true;
        endMessageRef.current.scrollIntoView({
          behavior: smooth ? 'smooth' : 'instant',
        });
      }, args?.timeout ?? 100);
    },
    [endMessageRef],
  );

  useEffect(() => {
    if (chatsQuery.isFetchedAfterMount || chatsQuery.isFetched) {
      requestAnimationFrame(() => {
        if (messagesContainerRef.current) {
          messagesContainerRef.current.scrollTop =
            messagesContainerRef.current.scrollHeight;
        }
      });
    }
  }, [chatsQuery.isFetchedAfterMount, chatsQuery.isFetched]);

  const sendMessageMutation = useMutation({
    mutationFn: async () => {
      const receiverID = GUID;
      const messageText = messageInput.trim();
      const receiverType = CometChat.RECEIVER_TYPE.GROUP;

      let messageToSend: CometChat.BaseMessage;

      if (fileInput) {
        const messageType = fileInput.type.startsWith('image/')
          ? CometChat.MESSAGE_TYPE.IMAGE
          : CometChat.MESSAGE_TYPE.VIDEO;

        const mediaMessage = new CometChat.MediaMessage(
          receiverID,
          fileInput,
          messageType,
          receiverType,
        );

        if (messageText.length > 0) {
          mediaMessage.setCaption(messageText);
        }

        messageToSend = mediaMessage;
      } else {
        messageToSend = new CometChat.TextMessage(
          receiverID,
          messageText,
          receiverType,
        );
      }

      messageToSend.setParentMessageId(message.getId());

      const newMessage = await CometChat.sendMessage(messageToSend);

      queryClient.setQueryData<{
        pages: CometChat.BaseMessage[][];
        pageParams: number[];
      }>(['cometchat', GUID, 'thread', message.getId()], (oldData) => {
        if (!oldData) return { pages: [[newMessage]], pageParams: [-1] };
        // add message to the first page (because the first page has the newest messages)
        const firstPage = oldData.pages.at(0);

        if (!firstPage) return { pages: [[newMessage]], pageParams: [-1] };
        const newFirstPage = [...firstPage, newMessage];
        return {
          pages: [newFirstPage, ...oldData.pages.slice(1)],
          pageParams: oldData.pageParams,
        };
      });

      queryClient.setQueryData<{
        pages: CometChat.BaseMessage[][];
        pageParams: number[];
      }>(['cometchat', GUID], (oldData) => {
        if (!oldData) return undefined;

        const newPages = oldData.pages.map((page) => {
          if (page.some((msg) => msg.getId() === message.getId())) {
            return page.map((msg) => {
              if (msg.getId() === message.getId()) {
                msg.setReplyCount((msg.getReplyCount() ?? 0) + 1);
              }
              return msg;
            });
          }
          return page;
        });
        return {
          pages: newPages,
          pageParams: oldData.pageParams,
        };
      });
      setMessageInput('');
      setFileInput(null);
      scrollToBottom();
    },
  });

  const isNearBottom = useCallback(() => {
    const container = messagesContainerRef.current;
    if (!container) return false;

    // Consider "near bottom" if within 100px of the bottom
    const threshold = 100;
    return (
      container.scrollHeight - container.scrollTop - container.clientHeight <
      threshold
    );
  }, []);

  useEffect(() => {
    const addMessage = (newMessage: CometChat.BaseMessage) => {
      queryClient.setQueryData<{
        pages: CometChat.BaseMessage[][];
        pageParams: number[];
      }>(['cometchat', GUID, 'thread', message.getId()], (oldData) => {
        if (!oldData) return { pages: [[newMessage]], pageParams: [-1] };
        const firstPage = oldData.pages.at(0);
        if (!firstPage) return { pages: [[newMessage]], pageParams: [-1] };
        const newFirstPage = [...firstPage, newMessage];
        return {
          pages: [newFirstPage, ...oldData.pages.slice(1)],
          pageParams: oldData.pageParams,
        };
      });

      // Only scroll if user is near bottom
      if (isNearBottom()) {
        requestAnimationFrame(() => {
          if (messagesContainerRef.current) {
            messagesContainerRef.current.scrollTop =
              messagesContainerRef.current.scrollHeight;
          }
        });
      }
    };

    const listenerID = `CHAT_LISTENER_THREAD_${GUID}_${message.getId()}`;
    CometChat.addMessageListener(
      listenerID,
      new CometChat.MessageListener({
        onTextMessageReceived: (textMessage: CometChat.TextMessage) => {
          // check text message belongs to this group
          const receiver = textMessage.getReceiver();
          if (!(receiver instanceof CometChat.Group)) return;
          if (receiver.getGuid() !== GUID) return;
          if (textMessage.getParentMessageId() !== message.getId()) return;

          addMessage(textMessage);
        },
        onMediaMessageReceived: (mediaMessage: CometChat.MediaMessage) => {
          // check media message belongs to this group
          const receiver = mediaMessage.getReceiver();
          if (!(receiver instanceof CometChat.Group)) return;
          if (receiver.getGuid() !== GUID) return;
          if (mediaMessage.getParentMessageId() !== message.getId()) return;

          if (
            mediaMessage.getType() !== CometChat.MESSAGE_TYPE.IMAGE &&
            mediaMessage.getType() !== CometChat.MESSAGE_TYPE.VIDEO
          )
            return;

          addMessage(mediaMessage);
        },
      }),
    );

    return () => {
      CometChat.removeMessageListener(listenerID);
    };
  }, [GUID, message.getId(), scrollToBottom, queryClient]);

  const handleSendMessage = async () => {
    if (messageInput.trim().length > 0 || !!fileInput) {
      await sendMessageMutation.mutateAsync();
    }
  };

  const messagesContainerRef = useRef<HTMLDivElement>(null);

  const handleScroll = useCallback(() => {
    const container = messagesContainerRef.current;
    const hasMore = chatsQuery.hasNextPage;

    const isThereAPageLessThanLimit = chatsQuery.data?.pages.some(
      (page) => page.length < MESSAGES_LIMIT,
    );

    if (isThereAPageLessThanLimit) {
      return;
    }

    if (!container || !hasMore || chatsQuery.isFetching) return;

    // Skip the first check after initial load
    if (isInitialLoadRef.current) {
      isInitialLoadRef.current = false;
      return;
    }

    // Load more messages when within 100px of the top
    const threshold = 100;
    if (container.scrollTop <= threshold) {
      const scrollHeight = container.scrollHeight;

      chatsQuery.fetchNextPage().then(() => {
        requestAnimationFrame(() => {
          const newScrollHeight = container.scrollHeight;
          container.scrollTop = newScrollHeight - scrollHeight;
        });
      });
    }
  }, [chatsQuery]);

  useEffect(() => {
    const container = messagesContainerRef.current;
    if (!container) return;

    container.addEventListener('scroll', handleScroll);
    return () => container.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  const threadMessages = [...(chatsQuery.data?.pages ?? [])].reverse().flat();

  return (
    <Sheet open={isOpen} onOpenChange={onClose}>
      <SheetContent className="flex w-full flex-col gap-0 border-0 pb-0 pl-10 pr-0 pt-10 text-base text-black focus:outline-none dark:bg-dark-1 dark:text-white sm:min-w-[585px]">
        {chatsQuery.isLoading ? (
          <Loading />
        ) : (
          <ThreadDrawerContent
            onClose={onClose}
            message={message}
            threadMessages={threadMessages}
            messageInput={messageInput}
            setMessageInput={setMessageInput}
            handleSendMessage={handleSendMessage}
            endMessageRef={endMessageRef}
            messagesContainerRef={messagesContainerRef}
            isLoading={sendMessageMutation.isPending}
            isFetchingMore={chatsQuery.isFetchingNextPage}
            fileInput={fileInput}
            setFileInput={setFileInput}
            onMentionSearch={onMentionSearch}
            users={users}
          />
        )}
      </SheetContent>
    </Sheet>
  );
};

const getReplyCounText = (number: number | null | undefined) => {
  if (number === null || number === undefined || number === 0)
    return 'No Replies';
  if (number === 1) return '1 Reply';
  return `${number} Replies`;
};

const ThreadDrawerContent = ({
  onClose,
  message,
  threadMessages,
  messageInput,
  setMessageInput,
  handleSendMessage,
  endMessageRef,
  messagesContainerRef,
  isLoading,
  fileInput,
  setFileInput,
  onMentionSearch,
  users,
  isFetchingMore,
}: {
  onClose: () => void;
  message: CometChat.BaseMessage;
  threadMessages: CometChat.BaseMessage[];
  messageInput: string;
  setMessageInput: (message: string) => void;
  handleSendMessage: () => Promise<void>;
  endMessageRef: React.RefObject<HTMLDivElement>;
  messagesContainerRef: React.RefObject<HTMLDivElement>;
  isLoading: boolean;
  fileInput: File | null;
  setFileInput: (file: File | null) => void;
  onMentionSearch?: (search: string) => {
    id: string;
    value: string;
    cometChatId: string;
    name: string;
    avatarUrl: string;
  }[];
  users: SpaceGroupControllerGetUsersBySpaceGroupIdForMentions200Item[];
  isFetchingMore: boolean;
}) => {
  return (
    <>
      <div className="flex items-center justify-between gap-2.5 pb-11 pr-10">
        <h1 className="w-full text-2xl font-semibold">Thread</h1>
        <Button variant="ghost" onClick={onClose} className="h-fit p-3">
          <CloseIcon className="h-6 w-6 fill-black dark:fill-white" />
        </Button>
      </div>
      <div
        ref={messagesContainerRef}
        className="flex h-full w-full flex-col gap-12 overflow-auto pr-10"
      >
        <ChatMessage message={message} isThreadMessage users={users} />
        <div className="flex w-full flex-col gap-2.5">
          <div className="flex items-center gap-2.5">
            <p className="text-nowrap text-base font-medium text-black dark:text-white">
              {getReplyCounText(threadMessages.length)}
            </p>
            <hr className="w-full border-t border-t-light dark:border-t-dark-light" />
          </div>
          {isFetchingMore && (
            <div className="flex justify-center py-2">
              <Loading size={4} />
            </div>
          )}
          {threadMessages.map((threadMessage) => (
            <ChatMessage
              key={threadMessage.getId()}
              message={threadMessage}
              isThreadMessage
              users={users}
            />
          ))}
          <div ref={endMessageRef} />
        </div>
      </div>
      <div className="sticky bottom-0 pb-10 pr-10">
        <ChatInput
          fileInput={fileInput}
          setFileInput={setFileInput}
          message={messageInput}
          setMessage={setMessageInput}
          onSendMessage={handleSendMessage}
          isLoading={isLoading}
          onMentionSearch={onMentionSearch}
        />
      </div>
    </>
  );
};

export default ThreadDrawer;
