import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { z } from 'zod';

import {
  useUserControllerForgotPassword,
  userControllerCheckEmail,
} from '@/api';
import { useAuth } from '@/hooks/useContext';

import AuthOptionsSelectPage from './signIn/AuthOptionsSelectPage';
import EnterPasswordPage from './signIn/EnterPasswordPage';
import ForgotPasswordPage from './signIn/ForgotPasswordPage';

const Login = () => {
  const location = useLocation();
  const { loginGoogle, login } = useAuth();

  const [email, setEmail] = useState(
    (location.state?.email as string | undefined) || '',
  );
  const [password, setPassword] = useState('');

  const [error, setError] = useState('');
  const [step, setStep] = useState(0);

  const [requestError, setRequestError] = useState('');

  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);
  const keys = [...searchParams.keys()];
  const isCreatorSignup = keys.includes('creatorSignup');
  const callbackUrl = searchParams.get('callbackUrl');

  const checkEmailMutation = useMutation({
    mutationFn: async (email: string) => {
      return await userControllerCheckEmail({ email });
    },
    onError: (error: unknown) => {
      if (error instanceof Error) {
        setRequestError(error.message);
      } else {
        setRequestError('Something went wrong');
      }
    },
  });

  const loginMutation = useMutation({
    mutationFn: () => login(email, password),
    onSuccess: (data) => {
      if (!data.canRedirect) {
        return;
      }

      // append the creatorSignup to all
      const newSearchParams = new URLSearchParams();
      if (isCreatorSignup) {
        newSearchParams.set('creatorSignup', '');
      }

      if (callbackUrl && !['/login', '/register'].includes(callbackUrl)) {
        navigate(`${callbackUrl}?${newSearchParams.toString()}`);
        return;
      }

      navigate('/' + '?' + newSearchParams.toString());
    },
    onError: (error: unknown) => {
      if (!(error instanceof Error)) {
        setRequestError('Something went wrong');
        return;
      }

      setRequestError(error.message);
    },
  });

  const signInWithGoogle = async () => {
    setError('');
    setRequestError('');

    if (callbackUrl) {
      localStorage.setItem(
        'google-redirect',
        callbackUrl + (isCreatorSignup ? '?creatorSignup' : ''),
      );
    }
    await loginGoogle();
  };

  const handleSignIn = async () => {
    setError('');
    setRequestError('');

    if (!password) {
      setError('Invalid password');
      return;
    }

    loginMutation.mutate();
  };

  const handleValidateEmail = async () => {
    setError('');
    setRequestError('');

    const emailValidation = z
      .string({
        required_error: 'Email is required',
        message: 'Invalid email',
      })
      .email({
        message: 'Invalid email',
      })
      .safeParse(email);

    if (!emailValidation.success) {
      setError(emailValidation.error.errors[0].message);
      return;
    }

    const validatedEmail = emailValidation.data;

    try {
      const res = await checkEmailMutation.mutateAsync(validatedEmail);

      if (res.exists) {
        handleNextStep();
      } else {
        const newSearchParams = new URLSearchParams();
        if (callbackUrl) {
          newSearchParams.set('callbackUrl', callbackUrl);
        }
        if (isCreatorSignup) {
          newSearchParams.set('creatorSignup', '');
        }

        navigate('/register' + '?' + newSearchParams.toString(), {
          state: { email: validatedEmail, step: 0 },
        });
      }
    } catch (error) {
      alert('Error verifying email');
    }
  };

  const handleNextStep = () => {
    setError('');
    setRequestError('');
    setStep(step + 1);
  };

  const handleBackStep = () => {
    setError('');
    setRequestError('');
    setStep(step - 1);
  };

  const forgotPasswordMutation = useUserControllerForgotPassword();

  const handleForgotPassword = async () => {
    if (!email) {
      setError('Email is required');
      return;
    }
    forgotPasswordMutation.mutate({ data: { email } });
    setStep(2);
  };

  return (
    <div className="flex h-full w-full items-center justify-center bg-bgGrey p-6 dark:bg-dark-bgGrey sm:p-0">
      {step === 0 && (
        <AuthOptionsSelectPage
          email={email}
          setEmail={setEmail}
          handleValidateEmail={handleValidateEmail}
          error={error}
          signInWithGoogle={signInWithGoogle}
          isLoading={checkEmailMutation.isPending}
        />
      )}
      {step === 1 && (
        <EnterPasswordPage
          password={password}
          setPassword={setPassword}
          error={error}
          handleBackStep={handleBackStep}
          handleSignIn={handleSignIn}
          isLoading={loginMutation.isPending}
          requestError={requestError}
          forgotPassword={handleForgotPassword}
        />
      )}
      {step === 2 && (
        <ForgotPasswordPage email={email} goBackToLogin={() => setStep(0)} />
      )}
    </div>
  );
};

export default Login;
