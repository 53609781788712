import React, { useRef, useState } from 'react';

import { CloudUploadIcon } from '@/assets/icon/cloud-upload';
import { Card } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { cn } from '@/lib/utils';

import ImageCropModal from './ui/ImageCropModal';
import { Button } from './ui/button';

interface DropzoneProps
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'value' | 'onChange'
  > {
  onFileChange: (file: File | null) => void;
  infoText?: string;
  imageURL?: string;
  ratio?: number;
}

const ImageDropzone = React.forwardRef<HTMLDivElement, DropzoneProps>(
  (
    { onFileChange, infoText, accept, imageURL, ratio = 4 / 3, ...props },
    ref,
  ) => {
    const [dragActive, setDragActive] = useState(false);
    const [open, setOpen] = useState(false);
    const [image, setImage] = useState<File>();
    const inputRef = useRef<HTMLInputElement>(null);

    const handleAddFile = (file: File) => {
      setImage(file);
      setOpen(true);
    };

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
      e.preventDefault();
      if (e.target.files && e.target.files[0]) {
        handleAddFile(e.target.files[0]);
      }
    }

    function handleDrop(e: React.DragEvent<HTMLDivElement>) {
      e.preventDefault();
      e.stopPropagation();
      setDragActive(false);
      if (e.dataTransfer.files && e.dataTransfer.files[0]) {
        handleAddFile(e.dataTransfer.files[0]);
      }
    }

    function handleDragLeave(e: React.DragEvent<HTMLDivElement>) {
      e.preventDefault();
      e.stopPropagation();
      setDragActive(false);
    }

    function handleDragOver(e: React.DragEvent<HTMLDivElement>) {
      e.preventDefault();
      e.stopPropagation();
      setDragActive(true);
    }

    function handleDragEnter(e: React.DragEvent<HTMLDivElement>) {
      e.preventDefault();
      e.stopPropagation();
      setDragActive(true);
    }

    function openFileExplorer() {
      if (!inputRef.current || open) return;
      inputRef.current.value = '';
      inputRef.current?.click();
    }

    const handleClose = () => {
      setImage(undefined);
      setOpen(false);
    };

    const handleCroppedImage = async (croppedImage: string) => {
      const imageFile = await convertImageUrlToFile(croppedImage);
      setImage(imageFile);
      onFileChange(imageFile);
      setOpen(false);
    };

    const convertImageUrlToFile = async (url: string) => {
      const response = await fetch(url);
      const blob = await response.blob();
      const file = new File([blob], 'image.jpg', { type: 'image/jpeg' });
      return file;
    };

    const handleRemoveImage = () => {
      setImage(undefined);
    };

    return (
      <Card
        ref={ref}
        className={cn(
          `flex flex-col items-center justify-center space-y-2 border-2 border-dashed bg-[#F3F4F6] px-2 py-4 hover:cursor-pointer hover:border-muted-foreground/50 dark:bg-[#202224]`,
          dragActive && 'border-muted-foreground/50',
        )}
        onDragEnter={handleDragEnter}
        onDrop={handleDrop}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onClick={openFileExplorer}
      >
        {image && !open ? (
          <div className="flex flex-col items-center justify-center gap-6">
            <img src={URL.createObjectURL(image)} alt="image" />
            <Button
              variant="ghost"
              className="border border-light dark:border-dark-light"
              onClick={handleRemoveImage}
            >
              Replace image
            </Button>
          </div>
        ) : (
          <div className="flex items-center justify-center">
            <div className="flex flex-col items-center gap-4">
              <CloudUploadIcon className="h-6 w-6 fill-current text-black dark:text-white" />
              <span className="text-base">
                Choose a file or drag & drop it here.
              </span>
              <span className="text-sm text-[#9E9E9E] dark:text-[#AEAEAE]">
                {infoText}
              </span>
            </div>

            <Input
              {...props}
              value={undefined}
              ref={inputRef}
              type="file"
              className={'hidden'}
              onChange={handleChange}
              accept={accept || '*'}
            />
          </div>
        )}

        <ImageCropModal
          open={open}
          image={image ? URL.createObjectURL(image) : ''}
          onClose={handleClose}
          onSave={handleCroppedImage}
          ratio={ratio}
        />
      </Card>
    );
  },
);

ImageDropzone.displayName = 'Dropzone';

export { ImageDropzone };
