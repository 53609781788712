export const NotificationBellIcon = (props) => (
  <svg viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M1.68694 9.84705C1.54517 10.7764 2.17899 11.4215 2.95503 11.7429C5.9302 12.9754 10.0705 12.9754 13.0456 11.7429C13.8217 11.4215 14.4555 10.7764 14.3137 9.84705C14.2266 9.27592 13.7958 8.80032 13.4766 8.33592C13.0585 7.72018 13.017 7.04852 13.0169 6.33398C13.0169 3.57256 10.7709 1.33398 8.00033 1.33398C5.22975 1.33398 2.98375 3.57256 2.98375 6.33398C2.98368 7.04852 2.94214 7.72018 2.52407 8.33592C2.20489 8.80032 1.77407 9.27592 1.68694 9.84705Z"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.33301 12.666C5.63867 13.8161 6.71667 14.666 7.99967 14.666C9.28267 14.666 10.3607 13.8161 10.6663 12.666"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
