import React, { Fragment, memo, useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

export interface ViewerProps {
  value: string;
  findUser: (id: string) => { username: string } | null;
  onMentionClick: (uid: string) => void;
}

// Custom component for mentions
const Mention: React.FC<{
  uid: string;
  username: string;
  onMentionClick: (uid: string) => void;
}> = ({ uid, username, onMentionClick }) => {
  return (
    <span
      className="inline cursor-pointer text-blue-500 no-underline"
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onMentionClick(uid);
      }}
      title={username}
    >
      <span className="inline-block">@{username}</span>{' '}
    </span>
  );
};

// Function to split content into text and mentions
function splitContent(
  value: string,
  findUser: (id: string) => { username: string } | null,
  onMentionClick: (uid: string) => void,
): React.ReactNode[] {
  const parts = value?.split(/(<@uid:.*?>)/);
  return parts?.map((part, index) => {
    const match = part.match(/<@uid:(.*?)>/);
    if (match) {
      const uid = match[1];
      const user = findUser(uid);
      const username = user ? user.username : 'Unknown User';
      return (
        <Mention
          key={`${index}-${uid}-${part}`}
          uid={uid}
          username={username}
          onMentionClick={onMentionClick}
        />
      );
    }
    return <MemoizedMarkdown key={`${index}-${part}`} part={part} />;
  });
}

export default function Viewer(props: ViewerProps) {
  const { value, findUser, onMentionClick } = props;

  const content = useMemo(() => {
    return splitContent(value, findUser, onMentionClick);
  }, [value, findUser, onMentionClick]);

  return (
    <div className="break-word text-base text-black dark:text-white">
      {content}
    </div>
  );
}

const CustomParagraph = ({ children }: { children?: React.ReactNode }) => (
  <Fragment>{children}</Fragment>
);

const CustomLink = ({
  href,
  children,
  ...otherProps
}: React.AnchorHTMLAttributes<HTMLAnchorElement>) => (
  <a
    onClick={(e) => {
      e.stopPropagation();
    }}
    target="_blank"
    rel="noopener noreferrer"
    className="text-blue-500 underline"
    href={href}
    {...otherProps}
  >
    {children}
  </a>
);

const MemoizedMarkdown = memo(({ part }: { part: string }) => {
  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      components={{
        p: CustomParagraph,
        a: CustomLink,
      }}
    >
      {part}
    </ReactMarkdown>
  );
});
