import * as Sentry from '@sentry/react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
// import { Elements } from '@stripe/react-stripe-js';
// import { loadStripe } from '@stripe/stripe-js';
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';

import { ErrorBoundary } from '@/components/error-boundary';
import { Layout } from '@/components/layout/layout';
import { AuthProvider } from '@/hooks/useContext';
import { CreatorSignupProvider } from '@/hooks/useCreatorSignup';
import { CancelPaymentPage } from '@/pages/CancelPaymentPage';
import { NotFoundPage } from '@/pages/NotFoundPage';
import AffiliatePage from '@/pages/app/AffiliatePage';
import { AnalyticsPage } from '@/pages/app/AnalyticsPage';
import { BookMeetingPage } from '@/pages/app/BookMeetingPage';
import { BrandPage } from '@/pages/app/BrandPage';
import BrandingPage from '@/pages/app/BrandingPage';
import { ChatPage } from '@/pages/app/ChatPage';
import { CheckBrandsPage } from '@/pages/app/CheckBrandsPage';
import { CheckDiscussionsPage } from '@/pages/app/CheckDiscussionsPage';
import CheckoutPage from '@/pages/app/CheckoutPage';
import CourseContentPage from '@/pages/app/CourseContent';
import { CoursePage } from '@/pages/app/CoursePage';
import CreateCoursePage from '@/pages/app/CreateCoursePage';
import CreatorSettingsPage from '@/pages/app/CreatorSettingsPage';
import DiscussionPage from '@/pages/app/DiscussionsPage';
import EditCoursePage from '@/pages/app/EditCoursePage';
import EventsPage from '@/pages/app/EventsPage';
import { GoogleRedirect } from '@/pages/app/GoogleRedirect';
import LessonCreationPage from '@/pages/app/LessonCreationPage';
import LessonPage from '@/pages/app/LessonPage';
import MembersCreatorPage from '@/pages/app/MembersCreatorPage';
import MembersPage from '@/pages/app/MembersPage';
import OnboardingPage from '@/pages/app/OnboardingPage';
import { InboxPage } from '@/pages/app/OneToOneChatsPage';
import PayoutPage from '@/pages/app/PayoutPage';
import PostsPage from '@/pages/app/PostsPage';
import { ProcessCheckoutPage } from '@/pages/app/ProcessCheckoutPage';
import { ProcessInvitePage } from '@/pages/app/ProcessInvitePage';
import { SpaceGroupPage } from '@/pages/app/SpaceGroupPage';
import UpdateLessonPage from '@/pages/app/UpdateLessonPage';
import { ConfirmAccountPage } from '@/pages/auth/ConfirmAccountPage';
import Login from '@/pages/auth/Login';
import Register from '@/pages/auth/Register';
import { BrandRoleCheck } from '@/routes/BrandRoleCheck';
import { InitialUserFetch } from '@/routes/InitialUserFetch';
import LoggedOut from '@/routes/LoggedOut';
import { MemberInfoDrawerProvider } from '@/routes/MemberInfoDrawerProvider';
import PrivateRoute from '@/routes/PrivateRoute';
import { SpaceGroupAccessCheck } from '@/routes/SpaceGroupAccessCheck';

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

function App() {
  const stripePromise = loadStripe(
    import.meta.env.VITE_PUBLIC_STRIPE_PUBLISHABLE_KEY || '',
  );

  return (
    <RouterProvider
      router={sentryCreateBrowserRouter(
        createRoutesFromElements(
          <Route errorElement={<ErrorBoundary />}>
            <Route element={<CreatorSignupProvider />}>
              <Route element={<AuthProvider />}>
                <Route element={<InitialUserFetch />}>
                  <Route
                    path="/checkout/brands/:brandId/space-groups/:spaceGroupId"
                    element={
                      <Elements stripe={stripePromise}>
                        <CheckoutPage type="spaceGroup" />
                      </Elements>
                    }
                  />
                  <Route
                    path="/checkout/brands/:brandId/chats/:userId"
                    element={
                      <Elements stripe={stripePromise}>
                        <CheckoutPage type="chat" />
                      </Elements>
                    }
                  />
                  <Route
                    path="/checkout/booking/:creatorId"
                    element={
                      <Elements stripe={stripePromise}>
                        <CheckoutPage type="meet" />
                      </Elements>
                    }
                  />

                  <Route element={<LoggedOut />}>
                    <Route path="/register" element={<Register />} />
                    <Route path="/login" element={<Login />} />
                  </Route>

                  <Route element={<PrivateRoute />}>
                    <Route element={<Layout />}>
                      <Route element={<MemberInfoDrawerProvider />}>
                        <Route path="/" element={<CheckBrandsPage />} />
                        <Route path="/brands" element={<CheckBrandsPage />} />

                        <Route element={<BrandRoleCheck />}>
                          <Route element={<SpaceGroupAccessCheck />}>
                            <Route
                              path={'/brands/:brandId'}
                              element={<BrandPage />}
                            />
                            <Route
                              path={'/brands/:brandId/chats'}
                              element={<InboxPage />}
                            />
                            <Route
                              path={'/brands/:brandId/chats/:userId'}
                              element={<InboxPage />}
                            />
                            <Route path={'/chats'} element={<InboxPage />} />
                            <Route
                              path={'/chats/:userId'}
                              element={<InboxPage />}
                            />
                            <Route
                              path={'/brands/:brandId/space-groups'}
                              element={<BrandPage />}
                            />
                            <Route
                              path={
                                '/brands/:brandId/space-groups/:spaceGroupId'
                              }
                              element={<SpaceGroupPage />}
                            />
                            <Route
                              path={
                                '/brands/:brandId/space-groups/:spaceGroupId/posts-spaces/:postsSpaceId'
                              }
                              element={<PostsPage />}
                            />
                            <Route
                              path={
                                '/brands/:brandId/space-groups/:spaceGroupId/posts-spaces/:postsSpaceId/joined-discussions'
                              }
                              element={<CheckDiscussionsPage />}
                            />
                            <Route
                              path={
                                '/brands/:brandId/space-groups/:spaceGroupId/posts-spaces/:postsSpaceId/joined-discussions/:discussionId'
                              }
                              element={<DiscussionPage />}
                            />

                            <Route
                              path={
                                '/brands/:brandId/space-groups/:spaceGroupId/members-spaces/:membersSpaceId'
                              }
                              element={<MembersPage />}
                            />
                            <Route
                              path={
                                '/brands/:brandId/space-groups/:spaceGroupId/events-spaces/:eventsSpaceId'
                              }
                              element={<EventsPage />}
                            />
                            <Route
                              path={
                                '/brands/:brandId/space-groups/:spaceGroupId/chats-spaces/:chatsSpaceId'
                              }
                              element={<ChatPage />}
                            />
                            <Route
                              path="/brands/:brandId/settings"
                              element={<CreatorSettingsPage />}
                            />
                            <Route
                              path="/brands/:brandId/analytics"
                              element={<AnalyticsPage />}
                            />
                            <Route
                              path="/brands/:brandId/branding"
                              element={<BrandingPage />}
                            />
                            <Route
                              path="/brands/:brandId/payouts"
                              element={<PayoutPage />}
                            />
                            <Route
                              path="/brands/:brandId/members"
                              element={<MembersCreatorPage />}
                            />

                            <Route
                              path={
                                '/brands/:brandId/space-groups/:spaceGroupId/courses-spaces/:coursesSpaceId'
                              }
                              element={<CoursePage />}
                            />
                            <Route
                              path={
                                '/brands/:brandId/space-groups/:spaceGroupId/courses-spaces/:coursesSpaceId/create'
                              }
                              element={<CreateCoursePage />}
                            />
                            <Route
                              path={
                                '/brands/:brandId/space-groups/:spaceGroupId/courses-spaces/:coursesSpaceId/courses/:courseId/edit'
                              }
                              element={<EditCoursePage />}
                            />
                            <Route
                              path={
                                '/brands/:brandId/space-groups/:spaceGroupId/courses-spaces/:coursesSpaceId/courses/:courseId'
                              }
                              element={<CourseContentPage />}
                            />
                            <Route
                              path={
                                '/brands/:brandId/space-groups/:spaceGroupId/courses-spaces/:coursesSpaceId/courses/:courseId/lessons/create'
                              }
                              element={<LessonCreationPage />}
                            />
                            <Route
                              path={
                                '/brands/:brandId/space-groups/:spaceGroupId/courses-spaces/:coursesSpaceId/courses/:courseId/lessons/:lessonId/edit'
                              }
                              element={<UpdateLessonPage />}
                            />
                            <Route
                              path={
                                '/brands/:brandId/space-groups/:spaceGroupId/courses-spaces/:coursesSpaceId/courses/:courseId/lessons/:lessonId'
                              }
                              element={<LessonPage />}
                            />

                            <Route
                              path="/settings"
                              element={<CreatorSettingsPage />}
                            />
                          </Route>
                        </Route>
                        <Route
                          path="/getting-started"
                          element={<OnboardingPage />}
                        />
                        <Route path="/table" element={<AffiliatePage />} />
                      </Route>
                    </Route>

                    <Route
                      path="/book-meeting/:creatorId"
                      element={
                        <Elements stripe={stripePromise}>
                          <BookMeetingPage />
                        </Elements>
                      }
                    />

                    <Route path="/oauth" element={<GoogleRedirect />} />
                    <Route
                      path="/process-invite/:token"
                      element={<ProcessInvitePage />}
                    />
                    <Route
                      path="/process-checkout/:token"
                      element={<ProcessCheckoutPage />}
                    />

                    <Route
                      path="/cancel-payment/:spaceGroupId"
                      element={<CancelPaymentPage />}
                    />
                  </Route>

                  <Route
                    path="/confirm-account"
                    element={<ConfirmAccountPage />}
                  />

                  <Route path="/404" element={<NotFoundPage />} />
                  <Route path="*" element={<NotFoundPage />} />
                </Route>
              </Route>
            </Route>
          </Route>,
        ),
      )}
    />
  );
}

export default App;
