import { z } from 'zod';

const envSchema = z.object({
  NODE_ENV: z.enum(['development', 'production', 'local']).default('local'),
  VITE_API_URL: z.string().url(),
  VITE_COMETCHAT_APP_ID: z.string().min(1),
  VITE_COMETCHAT_REGION: z.string().min(1),
});

const getEnv = () => {
  const envParseResult = envSchema.safeParse({
    NODE_ENV: import.meta.env.NODE_ENV,
    VITE_API_URL: import.meta.env.VITE_API_URL,
    VITE_COMETCHAT_APP_ID: import.meta.env.VITE_COMETCHAT_APP_ID,
    VITE_COMETCHAT_REGION: import.meta.env.VITE_COMETCHAT_REGION,
  });

  if (!envParseResult.success) {
    throw new Error(envParseResult.error.message);
  }

  return envParseResult.data;
};

export const env = getEnv();
