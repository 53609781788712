type Props = {
  size?: number;
  className?: string;
};

export const Loading = ({ size = 8, className = '' }: Props) => {
  return (
    <div
      className={
        'flex h-full w-full items-center justify-center space-x-2 ' + className
      }
    >
      <span className="sr-only">Loading...</span>
      <div
        className={`h-${size} w-${size} animate-bounce rounded-full bg-brand [animation-delay:-0.3s]`}
      />
      <div
        className={`h-${size} w-${size} animate-bounce rounded-full bg-brand [animation-delay:-0.15s]`}
      />
      <div
        className={`h-${size} w-${size} animate-bounce rounded-full bg-brand`}
      />
    </div>
  );
};
