import { SVGProps } from 'react';

import { cn } from '@/lib/utils';

export const PostIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 18 20"
    {...props}
    className={cn('fill-black dark:fill-white', props.className)}
  >
    <path
      d="M18 6v12.993A1 1 0 0 1 17.007 20H.993A.993.993 0 0 1 0 19.008V.992C0 .455.449 0 1.002 0h10.995L18 6Zm-2 1h-5V2H2v16h14V7ZM5 5h3v2H5V5Zm0 4h8v2H5V9Zm0 4h8v2H5v-2Z"
      //   fill="#D55F2A"
    />
  </svg>
);
