export const convertImageUrlToFile = async (url: string) => {
  const response = await fetch(url);
  const blob = await response.blob();
  const file = new File([blob], 'image.jpg', { type: 'image/jpeg' });
  return file;
};

export const downloadTableAsCsv = (
  headers: string[],
  rows: string[][],
  filename: string,
) => {
  console.log(rows);
  const csv = [headers.join(','), ...rows.map((row) => row.join(','))].join(
    '\n',
  );

  const blob = new Blob([csv], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  a.download = `${filename}.csv`;
  a.click();
  URL.revokeObjectURL(url);
};
