import { Navigate, useParams } from 'react-router-dom';

import { useDiscussionControllerGetUserJoinedDiscussionsInPostSpace } from '@/api';
import { Loading } from '@/components/Loading';

export const CheckDiscussionsPage = () => {
  const { brandId, spaceGroupId, postsSpaceId } = useParams<{
    brandId: string;
    spaceGroupId: string;
    postsSpaceId: string;
  }>();

  const discussionsQuery =
    useDiscussionControllerGetUserJoinedDiscussionsInPostSpace(
      postsSpaceId as string,
    );

  if (discussionsQuery.isLoading) {
    return <Loading />;
  }

  const discussions = discussionsQuery.data ?? [];

  if (discussions.length === 0) {
    return (
      <Navigate
        to={`/brands/${brandId}/space-groups/${spaceGroupId}/posts-spaces/${postsSpaceId}`}
      />
    );
  }

  const firstDiscussion = discussions[0];

  return (
    <Navigate
      to={`/brands/${brandId}/space-groups/${spaceGroupId}/posts-spaces/${postsSpaceId}/joined-discussions/${firstDiscussion.id}`}
    />
  );
};
