import { zodResolver } from '@hookform/resolvers/zod';
import { XIcon } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { AddSpacesToSpaceGroupDto } from '@/api';
import { ArrowLeftIcon } from '@/assets/icon/arrowLeft';
import { CalendarIcon } from '@/assets/icon/calendar';
import { ChatIcon } from '@/assets/icon/chat';
import { CourseSpaceIcon } from '@/assets/icon/course-space';
import { MembersSapceIcon } from '@/assets/icon/member-space';
import { PostIcon } from '@/assets/icon/post';
import { Button } from '@/components/ui/button';
import { DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Form, FormControl, FormField, FormItem } from '@/components/ui/form';
import { capitalizeFirstLetter, cn } from '@/lib/utils';

const SPACES = [
  {
    name: 'events',
    Icon: CalendarIcon,
    color: '#4D8EED',
    background: '#CDE3FD',
  },
  {
    name: 'posts',
    Icon: PostIcon,
    color: '#D55F2A',
    background: '#F4DDCF',
  },
  {
    name: 'chats',
    Icon: ChatIcon,
    color: '#65A96E',
    background: '#DDECE0',
  },
  {
    name: 'courses',
    Icon: CourseSpaceIcon,
    color: '#8A69E9',
    background: '#E7E2F4',
  },
  {
    name: 'members',
    Icon: MembersSapceIcon,
    color: '#F32222',
    background: '#FFD8D8',
  },
] as const;

const formSchema = z.object({
  events: z.boolean(),
  posts: z.boolean(),
  chats: z.boolean(),
  courses: z.boolean(),
  members: z.boolean(),
});

type FormSchema = z.infer<typeof formSchema>;

export const AddSpacesDialogContent = ({
  onChooseSpaces,
  onClose,
  handleBack,
  isLoading,
}: {
  onChooseSpaces: (spaces: AddSpacesToSpaceGroupDto) => void;
  onClose: () => void;
  handleBack: () => void;
  isLoading: boolean;
}) => {
  const form = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    defaultValues: {
      posts: false,
      events: false,
      chats: false,
      courses: false,
      members: false,
    },
  });

  const handleSubmit = form.handleSubmit((data) => {
    onChooseSpaces({
      chatSpace: data.chats,
      courseSpace: data.courses,
      eventSpace: data.events,
      memberSpace: data.members,
      postSpace: data.posts,
    });
  });

  // only can continue if there is at least one space selected
  const canContinue = Object.values(form.getValues()).some((value) => value);

  return (
    <>
      <DialogHeader className="flex-row items-center justify-between space-y-0">
        <DialogTitle className="flex items-center gap-2.5 text-2xl font-semibold">
          <Button
            variant="icon"
            size="icon"
            onClick={handleBack}
            disabled={isLoading}
          >
            <ArrowLeftIcon className="h-6 w-6 cursor-pointer fill-black dark:fill-white" />
          </Button>
          Select space types
        </DialogTitle>
        <Button
          disabled={isLoading}
          onClick={onClose}
          variant="icon"
          size="icon"
          className="m-0 rounded-sm p-0 ring-offset-background transition-opacity focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
        >
          <XIcon className="h-4 w-4" />
          <span className="sr-only">Close</span>
        </Button>
      </DialogHeader>
      <div className="font-medium text-[#9E9E9E] dark:text-[#AEAEAE]">
        <Form {...form}>
          <form onSubmit={handleSubmit} className="space-y-5">
            {SPACES.map((space) => (
              <FormField
                key={space.name}
                control={form.control}
                name={space.name}
                render={({ field }) => (
                  <FormItem className="space-y-0">
                    <FormControl>
                      <Button
                        type="button"
                        variant="none"
                        size="none"
                        className={cn(
                          'flex w-full flex-row items-center justify-start gap-4 space-y-0 rounded-xl border p-4 hover:border-blue-500 dark:border-opacity-15 hover:dark:border-opacity-100',
                          field.value
                            ? 'border-blue-500 dark:border-opacity-100'
                            : 'border-gray-200',
                        )}
                        onClick={() => {
                          field.onChange(!field.value);
                        }}
                      >
                        <CustomCheckbox value={field.value} />

                        <div
                          className={cn('rounded-lg p-4')}
                          style={{
                            backgroundColor: space.background,
                          }}
                        >
                          <space.Icon
                            className={cn(
                              'h-5 w-5 fill-current dark:fill-current',
                            )}
                            color={space.color}
                          />
                        </div>
                        <span className="space-y-0 text-base font-medium text-black dark:text-white">
                          {capitalizeFirstLetter(space.name)}
                        </span>
                      </Button>
                    </FormControl>
                  </FormItem>
                )}
              />
            ))}

            <Button
              type="submit"
              className="w-full rounded-lg"
              disabled={!canContinue || isLoading}
            >
              {isLoading ? 'Loading...' : 'Continue'}
            </Button>
          </form>
        </Form>
      </div>
    </>
  );
};

export interface CustomCheckboxProps {
  value?: boolean;
}

const CustomCheckbox = ({ value }: CustomCheckboxProps) => (
  <div className="flex items-start gap-2">
    <div className="mt-1 grid place-items-center">
      <div className="peer col-start-1 row-start-1 h-4 w-4 shrink-0 appearance-none rounded-full border-2 border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-0 disabled:border-gray-400" />
      <div
        className={cn(
          'pointer-events-none',
          'col-start-1 row-start-1',
          'h-2 w-2 rounded-full',
          value && 'bg-blue-500',
        )}
      />
    </div>
  </div>
);
