export const MailPencilIcon = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="mail-pencil">
      <path
        id="Icon"
        d="M10 19H7.8C6.11984 19 5.27976 19 4.63803 18.673C4.07354 18.3854 3.6146 17.9265 3.32698 17.362C3 16.7202 3 15.8802 3 14.2V9.8C3 9.05259 3 8.47142 3.02878 8M21 9.8C21 9.07362 21 8.50425 20.9736 8.04005M20.9736 8.04005C20.9389 7.43053 20.8586 7.00232 20.673 6.63803C20.3854 6.07354 19.9265 5.6146 19.362 5.32698C18.7202 5 17.8802 5 16.2 5H7.8C6.11984 5 5.27976 5 4.63803 5.32698C4.07354 5.6146 3.6146 6.07354 3.32698 6.63803C3.14546 6.99429 3.06471 7.41168 3.02878 8M20.9736 8.04005L15.5693 11.645C14.2887 12.4963 13.6483 12.9219 12.9565 13.0873C12.3451 13.2335 11.7078 13.2335 11.0964 13.0873C10.4045 12.9219 9.76421 12.4963 8.48356 11.645L3.02878 8M14 21L16.025 20.595C16.2015 20.5597 16.2898 20.542 16.3721 20.5097C16.4452 20.4811 16.5147 20.4439 16.579 20.399C16.6516 20.3484 16.7152 20.2848 16.8426 20.1574L21 16C21.5523 15.4477 21.5523 14.5523 21 14C20.4477 13.4477 19.5523 13.4477 19 14L14.8426 18.1574C14.7152 18.2848 14.6516 18.3484 14.601 18.421C14.5561 18.4853 14.5189 18.5548 14.4903 18.6279C14.458 18.7102 14.4403 18.7985 14.405 18.975L14 21Z"
        strokeOpacity="0.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
