import { createContext, useContext, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

type CreatorSignupContextType = {
  isCreatorSignup: boolean;
  setIsCreatorSignup: (isCreatorSignup: boolean) => void;
};

const CreatorSignupContext = createContext<CreatorSignupContextType | null>(
  null,
);

export const CreatorSignupProvider = () => {
  const location = useLocation();

  const isCreatorSignupFromUrl = location.search.includes('creatorSignup');

  const [isCreatorSignup, setIsCreatorSignup] = useState<boolean>(
    isCreatorSignupFromUrl,
  );

  return (
    <CreatorSignupContext.Provider
      value={{ isCreatorSignup, setIsCreatorSignup }}
    >
      <Outlet />
    </CreatorSignupContext.Provider>
  );
};

export const useCreatorSignup = (): CreatorSignupContextType => {
  const context = useContext(CreatorSignupContext);
  if (!context) {
    throw new Error(
      'useCreatorSignup debe usarse dentro de un CreatorSignupProvider',
    );
  }
  return context;
};
