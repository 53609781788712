import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import { compareAsc, format, isSameDay, isToday, startOfDay } from 'date-fns';
import { XIcon } from 'lucide-react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import {
  Event,
  getEventSpacesControllerGetEventsQueryKey,
  useEventSpacesControllerUpdateEvent,
} from '@/api';
import { CalendarIcon } from '@/assets/icon/calendar';
import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import {
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormInput,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Textarea } from '@/components/ui/textarea';
import { cn, imageToBase64, valueOrNull, valueOrUndefined } from '@/lib/utils';

import { ImageDropzone } from './image-dropzone';

const times = [
  '01:00',
  '01:30',
  '02:00',
  '02:30',
  '03:00',
  '03:30',
  '04:00',
  '04:30',
  '05:00',
  '05:30',
  '06:00',
  '06:30',
  '07:00',
  '07:30',
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
  '21:30',
  '22:00',
  '22:30',
  '23:00',
  '23:30',
  '24:00',
] as const;

const locations = ['In-person', 'Url (Zoom, Google meet, Youtube)'] as const;

const MB_BYTES = 1000000; // Number of bytes in a megabyte.

// This is the list of mime types you will accept with the schema
const ACCEPTED_MIME_TYPES = [
  'image/gif',
  'image/jpeg',
  'image/png',
  'image/jpg',
];

const formSchema = z
  .object({
    eventTitle: z.string().min(1, 'Event title is required'),
    startDate: z.date(),
    endDate: z.date(),
    startTime: z.enum(times).default(times[0]),
    endTime: z.enum(times).default(times[1]),
    eventDescription: z.string().min(1, 'Event description is required'),
    location: z.enum(locations).default(locations[1]),
    locationAddress: z.string().optional(),
    eventLink: z
      .string()
      .transform((value) => {
        if (value.includes('https://')) {
          return value;
        }
        return `https://${value}`;
      })
      .optional(),
    image: z.instanceof(File).optional(),
    isUsingOriginalImage: z.boolean(),
  })
  .refine(
    (data) => (data.location === 'In-person' ? !!data.locationAddress : true),
    {
      message: 'Address is required',
      path: ['locationAddress'],
    },
  )
  .refine(
    (data) => {
      if (data.location === 'Url (Zoom, Google meet, Youtube)') {
        return !!data.eventLink;
      }
      return true;
    },
    {
      message: 'Link is required',
      path: ['eventLink'],
    },
  )
  .refine(
    (data) => {
      return (
        compareAsc(startOfDay(data.startDate), startOfDay(data.endDate)) <= 0
      );
    },
    {
      message: 'End date should be after start date',
      path: ['endDate'],
    },
  )
  .refine(
    (data) => {
      const startDateTime = new Date(data.startDate);
      startDateTime.setHours(parseInt(data.startTime.split(':')[0]));
      startDateTime.setMinutes(parseInt(data.startTime.split(':')[1]));

      const endDateTime = new Date(data.endDate);
      endDateTime.setHours(parseInt(data.endTime.split(':')[0]));
      endDateTime.setMinutes(parseInt(data.endTime.split(':')[1]));

      return compareAsc(startDateTime, endDateTime) < 0;
    },
    {
      message: 'The event should end after it starts',
      path: ['endTime'],
    },
  );

type FormSchema = z.infer<typeof formSchema>;

export const EditEventDialogContent = ({
  event,
  closeDialog,
}: {
  event: Event;
  closeDialog: () => void;
}) => {
  const originalStartDate = new Date(event.startDayTime);
  const originalEndDate = new Date(event.endDayTime);
  const originalStartTime = format(
    event.startDayTime,
    'HH:mm',
  ) as (typeof times)[number];
  const originalEndTime = format(
    event.endDayTime,
    'HH:mm',
  ) as (typeof times)[number];

  const originalImage = event.image;

  const hasEventStarted = compareAsc(event.startDayTime, new Date()) < 0;

  const form = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    values: {
      eventTitle: event.title,
      startDate: originalStartDate,
      endDate: originalEndDate,
      startTime: originalStartTime,
      endTime: originalEndTime,
      eventDescription: event.description,
      location:
        event.locationType === 'in person' ? locations[0] : locations[1],
      locationAddress: valueOrUndefined(event.locationAddress),
      eventLink: valueOrUndefined(event.linkEvent),
      isUsingOriginalImage: originalImage ? true : false,
    },
  });

  const image = form.watch('image');
  const isUsingOriginalImage = form.watch('isUsingOriginalImage');
  function handleFileChange(file: File | null | undefined) {
    if (!file) {
      form.setValue('image', undefined);
      return;
    }

    if (!ACCEPTED_MIME_TYPES.includes(file.type)) {
      form.setValue('image', undefined);
      form.setError('image', {
        type: 'typeError',
        message: `Unsupported file type`,
      });
    } else if (file.size > 3 * MB_BYTES) {
      form.setValue('image', undefined);
      form.setError('image', {
        type: 'fileSizeError',
        message: `The file must not be larger than ${3 * MB_BYTES} bytes: ${
          file.size
        }`,
      });
    } else {
      form.setValue('image', file);
      form.clearErrors('image');
    }
  }

  const startDate = form.watch('startDate');
  const endDate = form.watch('endDate');
  useEffect(() => {
    if (hasEventStarted) {
      return;
    }

    if (compareAsc(startOfDay(startDate), startOfDay(endDate)) > 0) {
      form.setValue('endDate', startDate, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  }, [endDate, form, startDate, hasEventStarted]);

  const startTime = form.watch('startTime');
  const endTime = form.watch('endTime');

  const queryClient = useQueryClient();

  const editEventMutation = useEventSpacesControllerUpdateEvent({
    mutation: {
      onSuccess: () => {
        form.reset();
        queryClient.invalidateQueries({
          queryKey: getEventSpacesControllerGetEventsQueryKey(
            event.eventSpaceId,
          ),
        });
        closeDialog();
      },
    },
  });

  const handleSubmit = form.handleSubmit(async (data) => {
    const base64Image = data.image
      ? await imageToBase64(data.image)
      : undefined;

    const locationType = data.location === 'In-person' ? 'in person' : 'remote';
    const locationAddress =
      locationType === 'in person' ? data.locationAddress : null;
    const linkEvent = locationType === 'remote' ? data.eventLink : null;

    const startDayTime = new Date(data.startDate);
    const [startHours, startMinutes] = data.startTime.split(':').map(Number);
    startDayTime.setHours(startHours, startMinutes, 0, 0);

    const endDayTime = new Date(data.endDate);
    const [endHours, endMinutes] = data.endTime.split(':').map(Number);
    endDayTime.setHours(endHours, endMinutes, 0, 0);

    if (
      data.eventTitle === event.title &&
      data.eventDescription === event.description &&
      locationType === event.locationType &&
      valueOrNull(locationAddress) === event.locationAddress &&
      valueOrNull(linkEvent) === event.linkEvent &&
      isSameDay(startDayTime, event.startDayTime) &&
      isSameDay(endDayTime, event.endDayTime) &&
      data.startTime === originalStartTime &&
      data.endTime === originalEndTime &&
      !image &&
      isUsingOriginalImage
    ) {
      clearFormAndCloseDialog();
      return;
    }

    editEventMutation.mutate({
      eventId: event.id,
      data: {
        description: data.eventDescription,
        locationType,
        title: data.eventTitle,
        // @ts-expect-error
        startDayTime: startDayTime.toISOString(),
        endDayTime: endDayTime.toISOString(),
        linkEvent: valueOrNull(linkEvent) as string | undefined,
        locationAddress: valueOrNull(locationAddress) as string | undefined,
        image: base64Image,
      },
    });
  });

  const clearFormAndCloseDialog = () => {
    form.reset();
    closeDialog();
  };

  const filteredStartTimes = times.filter((time) => {
    // if start date is today, then filter out the times that are before the current time
    if (!isToday(startDate)) {
      return true;
    }
    const [hours, minutes] = time.split(':').map(Number);
    const startTime = new Date();
    startTime.setHours(hours, minutes, 0, 0);
    return startTime >= new Date();
  });

  const filteredEndTimes = times.filter((time) => {
    // if end date is start date, then filter out the times that are before the current time
    if (compareAsc(startOfDay(startDate), startOfDay(endDate)) !== 0) {
      return true;
    }
    const [hours, minutes] = time.split(':').map(Number);
    const startDateTime = new Date(startDate);
    startDateTime.setHours(parseInt(startTime.split(':')[0]));
    startDateTime.setMinutes(parseInt(startTime.split(':')[1]));
    const endTime = new Date(endDate);

    endTime.setHours(hours, minutes, 0, 0);
    return endTime > startDateTime;
  });

  useEffect(() => {
    if (!filteredStartTimes.includes(startTime)) {
      form.setValue('startTime', filteredStartTimes[0], {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  }, [startTime, filteredStartTimes, form, hasEventStarted]);

  useEffect(() => {
    if (!filteredEndTimes.includes(endTime)) {
      form.setValue('endTime', filteredEndTimes[0], {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  }, [endTime, filteredEndTimes, form]);

  const getImageUrl = (): string => {
    if (isUsingOriginalImage && originalImage) {
      return originalImage;
    }

    if (image) {
      return URL.createObjectURL(image);
    }
    return '';
  };

  const handleRemoveOriginalImage = () => {
    form.setValue('isUsingOriginalImage', false);
    form.setValue('image', undefined);
  };

  return (
    <DialogContent className="flex w-[90%] max-w-3xl flex-col gap-9 rounded-3xl md:w-3/4 md:p-10 lg:w-1/2">
      <DialogHeader className="flex-row items-center justify-between space-y-0">
        <DialogTitle className="text-2xl font-semibold">Edit Event</DialogTitle>
        <Button
          onClick={clearFormAndCloseDialog}
          variant="icon"
          size="icon"
          className="m-0 rounded-sm p-0 ring-offset-background transition-opacity focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
        >
          <XIcon className="h-4 w-4" />
          <span className="sr-only">Close</span>
        </Button>
      </DialogHeader>
      <div className="font-medium text-[#9E9E9E] dark:text-[#AEAEAE]">
        <Form {...form}>
          <form onSubmit={handleSubmit} className="space-y-5">
            <FormField
              control={form.control}
              name="eventTitle"
              render={({ field, fieldState }) => (
                <FormItem className="w-full">
                  <FormLabel
                    className={cn(
                      'text-base font-medium text-black dark:text-white',
                      fieldState.error && 'text-red-500 dark:text-red-500',
                    )}
                  >
                    Event title
                  </FormLabel>
                  <FormControl>
                    <FormInput
                      type="text"
                      placeholder="e.g hangout-club"
                      className={cn(
                        'h-12 w-full text-base font-normal text-black shadow-sm placeholder:text-[#9E9E9E] dark:text-white dark:placeholder:text-[#AEAEAE]',
                      )}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="grid gap-6 md:grid-cols-2">
              <FormField
                control={form.control}
                name="startDate"
                render={({ field, fieldState }) => (
                  <FormItem className="flex flex-col">
                    <FormLabel
                      className={cn(
                        'text-base font-medium text-black dark:text-white',
                        fieldState.error && 'text-red-500 dark:text-red-500',
                      )}
                    >
                      Start date
                    </FormLabel>
                    <Popover>
                      <PopoverTrigger asChild>
                        <FormControl>
                          <Button
                            variant={'outline'}
                            className={cn(
                              'flex h-12 w-full justify-start gap-3 border-input text-base font-normal text-black dark:text-white',
                              !field.value && 'text-muted-foreground',
                            )}
                          >
                            <CalendarIcon className="h-5 w-5 fill-[#10151D] dark:fill-white" />
                            {field.value ? (
                              format(field.value, 'MMMM dd, yyyy')
                            ) : (
                              <span>Pick a date</span>
                            )}
                          </Button>
                        </FormControl>
                      </PopoverTrigger>
                      <PopoverContent className="w-auto p-0" align="start">
                        <Calendar
                          mode="single"
                          required
                          selected={field.value}
                          onSelect={field.onChange}
                          disabled={(date) =>
                            compareAsc(
                              startOfDay(date),
                              startOfDay(new Date()),
                            ) < 0
                          }
                          initialFocus
                        />
                      </PopoverContent>
                    </Popover>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="endDate"
                render={({ field, fieldState }) => (
                  <FormItem className="flex flex-col">
                    <FormLabel
                      className={cn(
                        'text-base font-medium text-black dark:text-white',
                        fieldState.error && 'text-red-500 dark:text-red-500',
                      )}
                    >
                      End date
                    </FormLabel>
                    <Popover>
                      <PopoverTrigger asChild>
                        <FormControl>
                          <Button
                            variant={'outline'}
                            className={cn(
                              'flex h-12 w-full justify-start gap-3 border-input text-base font-normal text-black dark:text-white',
                              !field.value && 'text-muted-foreground',
                            )}
                          >
                            <CalendarIcon className="h-5 w-5 fill-[#10151D] dark:fill-white" />
                            {field.value ? (
                              format(field.value, 'MMMM dd, yyyy')
                            ) : (
                              <span>Pick a date</span>
                            )}
                          </Button>
                        </FormControl>
                      </PopoverTrigger>
                      <PopoverContent className="w-auto p-0" align="start">
                        <Calendar
                          mode="single"
                          required
                          selected={field.value}
                          onSelect={field.onChange}
                          disabled={(date) =>
                            compareAsc(
                              startOfDay(date),
                              startOfDay(startDate),
                            ) < 0
                          }
                          initialFocus
                        />
                      </PopoverContent>
                    </Popover>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="grid grid-cols-2 gap-6">
              <FormField
                control={form.control}
                name="startTime"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel
                      className={cn(
                        'text-base font-medium text-black dark:text-white',
                        fieldState.error && 'text-red-500 dark:text-red-500',
                      )}
                    >
                      Start time
                    </FormLabel>
                    <Select
                      key={field.value}
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger
                          className={cn(
                            'h-12 text-base text-black dark:text-white',
                          )}
                        >
                          <SelectValue />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {filteredStartTimes.map((time) => (
                          <SelectItem key={time} value={time}>
                            {time}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="endTime"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel
                      className={cn(
                        'text-base font-medium text-black dark:text-white',
                        fieldState.error && 'text-red-500 dark:text-red-500',
                      )}
                    >
                      End time
                    </FormLabel>
                    <Select
                      key={field.value}
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger className="h-12 text-base text-black dark:text-white">
                          <SelectValue />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {filteredEndTimes.map((time) => (
                          <SelectItem key={time} value={time}>
                            {time}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <FormField
              control={form.control}
              name="eventDescription"
              render={({ field, fieldState }) => (
                <FormItem className="w-full">
                  <FormLabel
                    className={cn(
                      'text-base font-medium text-black dark:text-white',
                      fieldState.error && 'text-red-500 dark:text-red-500',
                    )}
                  >
                    Event description
                  </FormLabel>
                  <FormControl>
                    <Textarea
                      placeholder="Write a description.."
                      className={cn(
                        'h-12 w-full resize-none text-base font-normal text-black shadow-sm placeholder:text-[#9E9E9E] dark:text-white dark:placeholder:text-[#AEAEAE]',
                        fieldState.error &&
                          'border-red-500 dark:border-red-500',
                      )}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="location"
              render={({ field, fieldState }) => (
                <FormItem>
                  <FormLabel
                    className={cn(
                      'text-base font-medium text-black dark:text-white',
                      fieldState.error && 'text-red-500 dark:text-red-500',
                    )}
                  >
                    Location
                  </FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                    value={field.value}
                  >
                    <FormControl>
                      <SelectTrigger className="h-12 text-base text-black dark:text-white">
                        <SelectValue placeholder="Select location" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {locations.map((location) => (
                        <SelectItem key={location} value={location}>
                          {location}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name={'locationAddress'}
              render={({ field, fieldState }) => (
                <FormItem
                  className={cn(
                    'w-full',
                    form.watch('location') === 'In-person' ? 'block' : 'hidden',
                  )}
                >
                  <FormLabel
                    className={cn(
                      'text-base font-medium text-black dark:text-white',
                      fieldState.error && 'text-red-500 dark:text-red-500',
                    )}
                  >
                    Location Address
                  </FormLabel>
                  <FormControl>
                    <FormInput
                      type="text"
                      placeholder={
                        'e.g 2715 Ash Dr. San Jose, South Dakota 83475'
                      }
                      className={cn(
                        'h-12 w-full text-base font-normal text-black shadow-sm placeholder:text-[#9E9E9E] dark:text-white dark:placeholder:text-[#AEAEAE]',
                      )}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name={'eventLink'}
              render={({ field, fieldState }) => (
                <FormItem
                  className={cn(
                    'w-full',
                    form.watch('location') ===
                      'Url (Zoom, Google meet, Youtube)'
                      ? 'block'
                      : 'hidden',
                  )}
                >
                  <FormLabel
                    className={cn(
                      'text-base font-medium text-black dark:text-white',
                      fieldState.error && 'text-red-500 dark:text-red-500',
                    )}
                  >
                    Event link
                  </FormLabel>
                  <FormControl>
                    <FormInput
                      type="text"
                      placeholder={'e.g https://zoom.us/j/123456789'}
                      className={cn(
                        'h-12 w-full text-base font-normal text-black shadow-sm placeholder:text-[#9E9E9E] dark:text-white dark:placeholder:text-[#AEAEAE]',
                      )}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="image"
              render={({ field, fieldState }) => {
                return (
                  <FormItem
                    className={cn((image || isUsingOriginalImage) && 'hidden')}
                  >
                    <FormLabel
                      className={cn(
                        'text-base font-medium text-black dark:text-white',
                        fieldState.error && 'text-red-500 dark:text-red-500',
                      )}
                    >
                      Upload Image
                    </FormLabel>

                    <FormControl>
                      <ImageDropzone
                        {...field}
                        infoText=" JPEG, PNG formats, up to 50 MB."
                        onFileChange={handleFileChange}
                        ratio={1 / 1}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />

            {isUsingOriginalImage && (
              <div className="flex flex-col items-center justify-center gap-6 space-y-2 rounded-lg border-2 border-dashed bg-[#F3F4F6] px-2 py-4 hover:border-muted-foreground/50 dark:bg-[#202224]">
                <img src={getImageUrl()} className="w-80" />
                <Button
                  variant="ghost"
                  className="border border-light dark:border-dark-light"
                  onClick={handleRemoveOriginalImage}
                >
                  Replace image
                </Button>
              </div>
            )}

            {image && (
              <div className="flex flex-col items-center justify-center gap-6 space-y-2 rounded-lg border-2 border-dashed bg-[#F3F4F6] px-2 py-4 hover:border-muted-foreground/50 dark:bg-[#202224]">
                <img src={getImageUrl()} className="w-80" />
                <Button
                  variant="ghost"
                  className="border border-light dark:border-dark-light"
                  onClick={() => handleFileChange(null)}
                >
                  Replace image
                </Button>
              </div>
            )}

            <Button
              type="submit"
              className="w-full rounded-lg"
              disabled={editEventMutation.isPending}
            >
              {editEventMutation.isPending ? 'Saving...' : 'Save'}
            </Button>
          </form>
        </Form>
      </div>
    </DialogContent>
  );
};
