import { useQueryClient } from '@tanstack/react-query';
import {
  compareAsc,
  format,
  formatDistanceToNow,
  isAfter,
  isBefore,
  isEqual,
} from 'date-fns';
import { enUS } from 'date-fns/locale';
import { XIcon } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import {
  Event,
  EventWithRsvps,
  eventSpacesControllerToggleRsvp,
  getEventSpacesControllerGetEventsQueryKey,
} from '@/api';
import { CalendarIcon } from '@/assets/icon/calendar';
import { CalendarRoudedIcon } from '@/assets/icon/calendarRounded';
import { MapPinIcon } from '@/assets/icon/map-pin';
import { VideoEventIcon } from '@/assets/icon/video-event';
import eventBackground from '@/assets/image/eventBackground.png';
import { EventsDropdownOptions } from '@/components/events-dropdown-options';
import { Badge } from '@/components/ui/badge';
import { Button, buttonVariants } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { useBrandPermits } from '@/hooks/useBrandPermits';
import { useAuth } from '@/hooks/useContext';
import { cn } from '@/lib/utils';

import AddToCalendarDialog from './events-add-to-calendar';

interface EventCardProps {
  event: EventWithRsvps;
}

const formatDate = (date: string | number | Date) => {
  return format(date, 'EEEE, MMMM d, h:mm a zzz', {
    locale: enUS,
  });
};

export const EventCard = ({ event }: EventCardProps) => {
  // const [isOpen, setIsOpen] = useState(false);
  // get isOpen from query params
  const [searchParams, setSearchParams] = useSearchParams();
  const eventId = searchParams.get('eventId');
  const isOpen = eventId === event.id;

  const handleOpenChange = (value: boolean) => {
    if (value) {
      setSearchParams({ eventId: event.id });
    } else {
      setSearchParams({});
    }
  };

  const closeDialog = () => {
    setSearchParams({});
  };

  const hasPermits = useBrandPermits();
  const formattedDate = formatDate(event.startDayTime);
  const hasEventFinished = compareAsc(event.endDayTime, new Date()) < 0;

  return (
    <Dialog open={isOpen} onOpenChange={handleOpenChange}>
      <DialogTrigger className="p-0">
        <Card className="relative flex h-fit flex-col gap-3 rounded-2xl p-3 sm:flex-row sm:gap-5">
          <div className="relative">
            <img
              src={event.image ? event.image : eventBackground}
              className="aspect-square h-full min-h-48 w-full rounded-2xl object-cover sm:aspect-square sm:h-48 sm:w-48"
            />

            {isEventLive(event) && (
              <Badge
                variant="secondary"
                className="absolute left-3 top-3 flex gap-2"
              >
                <div className="h-2 w-2 rounded-full bg-green-600" />
                <span>Live</span>
              </Badge>
            )}
          </div>

          <div className="flex flex-col justify-start gap-3 overflow-x-hidden">
            <div className="flex w-full items-center">
              <TooltipProvider delayDuration={300}>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <h4 className="truncate text-base font-medium">
                      {event.title}
                    </h4>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>{event.title}</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>

            <div className="flex w-full items-center gap-2">
              <CalendarIcon className="h-4 w-4 fill-[#10151D] dark:fill-white" />
              <TooltipProvider delayDuration={300}>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <span className="truncate text-sm font-medium text-[#9E9E9E] dark:text-[#AEAEAE]">
                      {formattedDate}
                    </span>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>{formattedDate}</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>

            <div className="flex w-full items-center gap-2">
              <MapPinIcon className="h-5 w-4 fill-[#10151D] dark:fill-white" />
              <TooltipProvider delayDuration={300}>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <span className="truncate text-sm font-medium text-[#9E9E9E] dark:text-[#AEAEAE]">
                      {event.locationType === 'remote'
                        ? 'Virtual event'
                        : event.locationAddress}
                    </span>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>
                      {event.locationType === 'remote'
                        ? 'Virtual event'
                        : event.locationAddress}
                    </p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>

            {renderEventButton(event)}
          </div>

          {hasPermits && !hasEventFinished && (
            <div
              className="absolute bottom-2 right-6 md:right-6 md:top-4"
              onClick={(e) => e.stopPropagation()}
            >
              <EventsDropdownOptions event={event} />
            </div>
          )}
        </Card>
      </DialogTrigger>
      <EventInfoDialogContent closeDialog={closeDialog} event={event} />
    </Dialog>
  );
};

const EventInfoDialogContent = ({
  closeDialog,
  event,
}: {
  closeDialog: () => void;
  event: EventWithRsvps;
}) => {
  const formattedStartDate = formatDate(event.startDayTime);
  const formattedEndDate = formatDate(event.endDayTime);
  const queryClient = useQueryClient();
  const { eventsSpaceId } = useParams();
  const { user } = useAuth();

  const [colorList, setColorList] = useState<string[]>([]);

  useEffect(() => {
    setRandomColors();
  }, []);

  const handleRSVP = async () => {
    await eventSpacesControllerToggleRsvp(event.id);
    queryClient.invalidateQueries({
      queryKey: getEventSpacesControllerGetEventsQueryKey(eventsSpaceId!),
    });
    closeDialog();
  };

  const setRandomColors = () => {
    const colors = Array.from({ length: 5 }, () => getRandomHexColor());
    setColorList(colors);
  };

  const getRandomHexColor = () => {
    return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
  };

  return (
    <DialogContent className="flex w-[90%] max-w-3xl flex-col gap-10 rounded-3xl md:w-3/4 md:p-12 lg:w-1/2">
      <DialogHeader className="flex-row items-center justify-between space-y-0">
        <DialogTitle className="text-2xl font-semibold">Event info</DialogTitle>
        <Button
          onClick={closeDialog}
          variant="icon"
          size="icon"
          className="m-0 rounded-sm p-0 ring-offset-background transition-opacity focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
        >
          <XIcon className="h-4 w-4" />
          <span className="sr-only">Close</span>
        </Button>
      </DialogHeader>
      <h3 className="text-lg font-bold">{event.title}</h3>

      <div className="flex flex-col gap-4">
        <div className="flex w-full items-center gap-4">
          <div className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-md bg-light-2 dark:bg-dark-2">
            <CalendarRoudedIcon className="h-6 w-6 stroke-black dark:stroke-white" />
          </div>

          <span className="text-sm font-medium text-black dark:text-white">
            {formattedStartDate} - {formattedEndDate}
          </span>
        </div>
        <div className="flex w-full items-center gap-4">
          <div className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-md bg-light-2 dark:bg-dark-2">
            {event.locationType === 'remote' ? (
              <VideoEventIcon className="h-6 w-6 stroke-black dark:stroke-white" />
            ) : (
              <MapPinIcon className="h-5 w-4 fill-[#10151D] dark:fill-white" />
            )}
          </div>
          <span className="text-sm font-medium text-black dark:text-white">
            {event.locationType === 'remote'
              ? 'Virtual event'
              : event.locationAddress}
          </span>
        </div>
      </div>

      <span className="text-justify text-base font-normal">
        {event.description}
      </span>

      <div className="flex flex-col gap-4">
        <div className="text-base font-medium">Attendees</div>
        <div className="flex">
          {event.eventRsvps.length ? (
            event.eventRsvps.map((rsvp, i) => (
              <div className="flex items-center gap-2.5">
                {i <= 7 ? (
                  rsvp.user.avatarUrl ? (
                    <img
                      src={rsvp.user.avatarUrl}
                      className="-ml-2 h-10 min-w-10 flex-shrink-0 rounded-lg"
                    />
                  ) : (
                    <div
                      className={`-ml-2 flex h-10 min-w-10 flex-shrink-0 items-center justify-center rounded-lg`}
                      style={{
                        backgroundColor: colorList[i],
                      }}
                    >
                      {rsvp.user.name?.slice(0, 2).toUpperCase()}
                    </div>
                  )
                ) : (
                  <div className="text-base font-medium">
                    {event.eventRsvps.length - 7}+{' '}
                    <span className="text-textParagraph dark:text-dark-textParagraph">
                      Others
                    </span>
                  </div>
                )}
              </div>
            ))
          ) : (
            <div className="w-full font-normal text-textParagraph dark:text-dark-textParagraph">
              No attendees yet
            </div>
          )}
        </div>
      </div>

      <DialogFooter className="w-full">
        <Button className="w-full" onClick={handleRSVP}>
          {event.eventRsvps.find((rsvp) => rsvp.user.id === user?.id)
            ? 'Un-RSVP'
            : 'RSVP'}
        </Button>
        <AddToCalendarDialog event={event} />
      </DialogFooter>
    </DialogContent>
  );
};

const renderEventButton = (event: EventWithRsvps, isDialog = false) => {
  const { user } = useAuth();
  // if the event finished return null
  if (isBefore(event.endDayTime, new Date())) {
    return null;
  }

  if (
    event.locationType === 'remote' &&
    isEventLive(event) &&
    event.linkEvent
  ) {
    return (
      <a
        onClick={(e) => e.stopPropagation()}
        className={cn(
          buttonVariants(),
          'mt-2',
          !isDialog && 'hidden w-fit md:block',
          isDialog && 'flex w-full place-content-center',
        )}
        href={event.linkEvent}
        target="_blank"
        rel="noreferrer"
      >
        Join Now
      </a>
    );
  }

  return (
    <>
      <div
        className={cn(
          buttonVariants({
            variant: 'secondary',
          }),
          'mt-2',
          !isDialog && 'hidden w-fit place-content-center sm:block',
          isDialog && 'flex w-full place-content-center',
        )}
      >
        {getTimeUntilDate(event.startDayTime)}
      </div>
      <Button>
        {event.eventRsvps.some((attendee) => attendee.userId === user?.id)
          ? 'Attending'
          : 'RSVP'}
      </Button>
    </>
  );
};

const getTimeUntilDate = (targetDate: string | number | Date) => {
  const formattedDistance = formatDistanceToNow(new Date(targetDate), {
    addSuffix: true,
  });
  return `Starts ${formattedDistance}`;
};

const isEventLive = (event: Event) => {
  const now = new Date();
  return (
    (isBefore(event.startDayTime, now) || isEqual(event.startDayTime, now)) &&
    isAfter(event.endDayTime, now)
  );
};
