import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  Brand,
  brandsControllerGetBrandById,
  brandsControllerUpdateBrand,
  getBrandsControllerGetAllBrandsQueryKey,
} from '@/api';
import { PencilEditIcon } from '@/assets/icon/pencil-edit';
import ImageCropModal from '@/components/ui/ImageCropModal';
import InputField from '@/components/ui/InputField';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { useToast } from '@/hooks/use-toast';
import { imageToBase64 } from '@/lib/utils';
import { convertImageUrlToFile } from '@/utils/FileTools';

const BrandingPage = () => {
  const logoRef = useRef<HTMLInputElement>(null);
  const { brandId } = useParams();
  const { toast } = useToast();
  const queryClient = useQueryClient();

  const [communityName, setCommunityName] = useState('');
  const [communityUrl, setCommunityUrl] = useState('');
  const [communityLogo, setCommunityLogo] = useState<File | string>();
  const [baseBrandData, setBaseBrandData] = useState<Brand>();
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    fetchBrand();
  }, [brandId]);

  const fetchBrand = async () => {
    if (brandId) {
      const response = await brandsControllerGetBrandById(brandId);
      setCommunityName(response.name);
      setCommunityLogo(response.logoUrl);
      setBaseBrandData(response);
    }
  };

  const handleChangeLogo = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCommunityLogo(e.target.files?.[0]);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCroppedImage = async (croppedImage: string) => {
    const imageFile = await convertImageUrlToFile(croppedImage);
    setCommunityLogo(imageFile);
    setOpen(false);
  };

  const handleUpdateBrand = async () => {
    if (brandId) {
      setIsLoading(true);
      let logoBase64 = '';

      if (communityLogo && typeof communityLogo !== 'string') {
        logoBase64 = (await imageToBase64(communityLogo)) || '';
      }

      brandsControllerUpdateBrand(brandId, {
        name: communityName,
        logoBase64,
      })
        .then(() => {
          toast({
            description: 'Brand updated successfully!',
            variant: 'success',
          });
          fetchBrand();
          queryClient.invalidateQueries({
            queryKey: getBrandsControllerGetAllBrandsQueryKey(),
          });
        })
        .finally(() => {
          setIsLoading(false);
        })
        .catch(() => {
          toast({
            description: 'Failed to update brand',
            variant: 'warning',
          });
        });
    }
  };

  return (
    <div className="flex w-full flex-col items-end gap-10 px-6 py-10 xl:px-62.5 xl:py-12">
      <div className="flex w-full flex-col gap-2.5">
        <h1 className="text-base font-medium">Upload logo</h1>
        <div className="flex w-full items-center gap-6 rounded-2xl border border-light p-4 dark:border-dark-light">
          <div className="flex w-full flex-col gap-1 text-sm">
            <h3 className="font-medium text-black dark:text-white">Logo</h3>
            <p className="font-normal text-textParagraph dark:text-dark-textParagraph">
              Recommended size: 150x150 (1:1).
            </p>
          </div>
          {communityLogo ? (
            <img
              src={
                typeof communityLogo === 'string'
                  ? communityLogo
                  : URL.createObjectURL(communityLogo)
              }
              alt="comunity pic"
              className="max-h-16 min-h-16 min-w-16 max-w-16 rounded-lg"
            />
          ) : (
            <div className="min-h-16 min-w-16 rounded-lg bg-light"></div>
          )}
          <div
            onClick={() => logoRef.current?.click()}
            className="flex min-h-12 min-w-12 cursor-pointer items-center justify-center rounded-full border border-light dark:border-dark-light"
          >
            <PencilEditIcon className="stroke-black dark:stroke-white" />
          </div>
          <input type="file" ref={logoRef} hidden onChange={handleChangeLogo} />
        </div>
      </div>
      <InputField
        onChange={(e) => setCommunityName(e.target.value)}
        type="text"
        value={communityName}
        title="Community name"
        inputClassName="border border-light dark:border-dark-light !bg-transparent shadow-none"
      />
      <div className="flex w-full flex-col gap-2.5">
        <h1 className="text-base font-medium">Community url</h1>
        <div className="flex gap-2.5">
          <div className="rounded-md bg-light-2 p-4 text-textParagraph dark:bg-dark-2 dark:text-dark-textParagraph">
            app.teachly.ai/
          </div>
          <Input
            className="border-light !ring-0 !ring-offset-0 dark:border-dark-light"
            value={communityUrl}
            onChange={() => setCommunityUrl('')} // TODO: change to e.target.value when API is ready
            onClick={() =>
              toast({
                description: 'Coming soon!',
              })
            }
          />
        </div>
      </div>
      {(baseBrandData?.logoUrl !== communityLogo ||
        baseBrandData?.name !== communityName) && (
        <Button
          className="w-fit"
          onClick={handleUpdateBrand}
          disabled={isLoading}
        >
          Save Settings
        </Button>
      )}
      {communityLogo && typeof communityLogo !== 'string' && (
        <ImageCropModal
          image={URL.createObjectURL(communityLogo)}
          open={open}
          onClose={handleClose}
          onSave={handleCroppedImage}
          ratio={1 / 1}
        />
      )}
    </div>
  );
};

export default BrandingPage;
