import { SVGProps } from 'react';

export const ChatIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.45 16L10 19.5L7.55 16H1C0.73478 16 0.48043 15.8946 0.29289 15.7071C0.10536 15.5196 0 15.2652 0 15V1C0 0.73478 0.10536 0.48043 0.29289 0.29289C0.48043 0.10536 0.73478 0 1 0H19C19.2652 0 19.5196 0.10536 19.7071 0.29289C19.8946 0.48043 20 0.73478 20 1V15C20 15.2652 19.8946 15.5196 19.7071 15.7071C19.5196 15.8946 19.2652 16 19 16H12.45ZM11.409 14H18V2H2V14H8.591L10 16.012L11.409 14Z"
      //   fill="#65A96E"
    />
  </svg>
);
