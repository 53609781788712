export const MembersIcon = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.229 12.0835H6.77067C3.95172 12.0835 1.6665 14.5086 1.6665 17.5002H13.3332C13.3332 14.5086 11.0479 12.0835 8.229 12.0835Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.8333 17.5002H18.3333C18.3333 14.6952 16.3243 12.3604 13.75 12.0835"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.50016 9.16683C9.11099 9.16683 10.4168 7.86099 10.4168 6.25016C10.4168 4.63933 9.11099 3.3335 7.50016 3.3335C5.88933 3.3335 4.5835 4.63933 4.5835 6.25016C4.5835 7.86099 5.88933 9.16683 7.50016 9.16683Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 9.16683C14.1108 9.16683 15.4167 7.861 15.4167 6.25016C15.4167 4.63933 14.1108 3.3335 12.5 3.3335"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
