import { AddToCalendarButton } from 'add-to-calendar-button-react';
import { CalendarPlus, XIcon } from 'lucide-react';
import { useState } from 'react';

import { EventWithRsvps } from '@/api';
import { DateTimeFormatToStringDate } from '@/utils/DateFormatter';

import { Button } from './ui/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from './ui/dialog';

const AddToCalendarDialog = ({ event }: { event: EventWithRsvps }) => {
  const [isOpen, setIsOpen] = useState(false);

  console.log(event);

  const closeDialog = () => {
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger className="flex h-10 w-10 items-center justify-center rounded-md bg-brand">
        <CalendarPlus className="h-6 w-6" />
      </DialogTrigger>
      <DialogContent className="flex flex-col gap-10 overflow-hidden">
        <DialogHeader className="flex-row items-center justify-between space-y-0">
          <DialogTitle className="flex flex-col gap-2.5 text-2xl font-semibold">
            Thank you for Registering 🎉
            <div className="text-sm text-textParagraph dark:text-dark-textParagraph">
              We will send you a reminder before the event
            </div>
          </DialogTitle>
          <Button
            onClick={closeDialog}
            variant="icon"
            size="icon"
            className="m-0 rounded-sm p-0 ring-offset-background transition-opacity focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
          >
            <XIcon className="h-4 w-4" />
            <span className="sr-only">Close</span>
          </Button>
        </DialogHeader>
        <div className="flex flex-col gap-4">
          <div className="flex w-full items-center gap-1">
            <div className="w-full rounded-lg bg-light-2 p-2.5 font-medium dark:bg-dark-2">
              {DateTimeFormatToStringDate(event.startDayTime).datePart},{' '}
              {DateTimeFormatToStringDate(event.startDayTime).timePart}
            </div>
          </div>
          <AddToCalendarButton
            listStyle="overlay"
            name={event.title}
            startDate={event.startDayTime}
            endDate={event.endDayTime}
            options={[
              'Apple',
              'Google',
              'Microsoft365',
              'MicrosoftTeams',
              'Outlook.com',
            ]}
            timeZone={event.timeZone}
            location={event.linkEvent || event.locationType}
          />
        </div>
        <DialogFooter>
          <Button onClick={closeDialog} className="w-full">
            Done
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default AddToCalendarDialog;
