//  Format from YYYY-MM-DD to Mmmm DD, YYYY
export const DateToString = (date: string | number | Date) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric' } as const;
  if (date) return new Date(date).toLocaleDateString('en-US', options);
};

// Format from YYYY-MM-DD:HH:MM:SS to Mmmm DD, YYYY at HH:MM AM/PM
export const DateTimeFormatToStringDate = (isoDateString: string) => {
  const date = new Date(isoDateString);

  // Format for "Tuesday, Sept 6"
  const dayFormat = new Intl.DateTimeFormat('en-US', {
    weekday: 'long',
    month: 'short',
    day: 'numeric',
  }).format(date);

  // Format for "1:30 PM UTC"
  const timeFormat =
    new Intl.DateTimeFormat('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      timeZone: 'UTC',
      hour12: true,
    }).format(date) + ' UTC';

  return {
    datePart: dayFormat,
    timePart: timeFormat,
  };
};
