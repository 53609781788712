import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const imageToBase64 = async (
  image: File,
): Promise<string | undefined> => {
  const base64 = await new Promise<string | undefined>((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = () => resolve(undefined);
  });
  // remove the data:image/png;base64, prefix
  return base64?.replace(/^data:image\/\w+;base64,/, '');
};

export type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

export const getEntries = <T extends object>(obj: T) =>
  Object.entries(obj) as Entries<T>;

export function getObjectKeys<T extends object>(obj: T) {
  return Object.keys(obj) as (keyof T)[];
}

export function getObjectValues<T extends object>(obj: T) {
  return Object.values(obj) as T[keyof T][];
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function onlyFirstLetterCapital(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export function formatPrice(price: number) {
  return price.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
}

export function valueOrUndefined<T>(
  value: T | undefined | null,
): T | undefined {
  return value === undefined || value === null ? undefined : value;
}

export function valueOrNull<T>(value: T | undefined | null): T | null {
  return value === undefined || value === null ? null : value;
}

export function groupBy<T extends object, K extends keyof T>(
  array: T[],
  key: K,
): Record<string, T[]> {
  return array.reduce(
    (acc, item) => {
      const groupKey = String(item[key]);
      if (!acc[groupKey]) {
        acc[groupKey] = [];
      }
      acc[groupKey].push(item);
      return acc;
    },
    {} as Record<string, T[]>,
  );
}
