type VideoProvider = 'youtube' | 'loom' | 'wistia' | 'tiktok' | 'unknown';

export const getVideoProvider = (url: string): VideoProvider => {
  if (!url) return 'unknown';

  try {
    const urlObj = new URL(url);

    // YouTube validation
    if (urlObj.hostname.includes('youtube.com')) {
      // Must have 'watch?v=' or be a shorts
      if (
        !urlObj.pathname.includes('watch') &&
        !urlObj.pathname.includes('shorts')
      ) {
        return 'unknown';
      }
      return 'youtube';
    }
    if (urlObj.hostname.includes('youtu.be')) {
      return 'youtube';
    }

    // Loom validation
    if (urlObj.hostname.includes('loom.com')) {
      // Must have /share/ or /v/ in the path
      if (
        !urlObj.pathname.includes('/share/') &&
        !urlObj.pathname.includes('/v/')
      ) {
        return 'unknown';
      }
      return 'loom';
    }

    // Wistia validation
    if (
      urlObj.hostname.includes('wistia.com') ||
      urlObj.hostname.includes('fast.wistia.net')
    ) {
      // Must have /medias/ or /embed/iframe/ in the path
      if (
        !urlObj.pathname.includes('/medias/') &&
        !urlObj.pathname.includes('/embed/iframe/')
      ) {
        return 'unknown';
      }
      return 'wistia';
    }

    // TikTok validation
    if (urlObj.hostname.includes('tiktok.com')) {
      // Don't consider shortened URLs as valid video URLs
      if (urlObj.pathname.startsWith('/t/')) {
        return 'unknown';
      }
      // Must have /video/ in the path
      if (!urlObj.pathname.includes('/video/')) {
        return 'unknown';
      }
      return 'tiktok';
    }

    return 'unknown';
  } catch {
    return 'unknown';
  }
};

export const parseVideoLink = (url: string): string => {
  if (!url) return '';

  try {
    const urlObj = new URL(url);
    const provider = getVideoProvider(url);

    switch (provider) {
      case 'youtube': {
        const videoId =
          urlObj.searchParams.get('v') || urlObj.pathname.slice(1);
        return `https://www.youtube.com/embed/${videoId}`;
      }
      case 'loom': {
        const videoId = urlObj.pathname.split('/').pop();
        return `https://www.loom.com/embed/${videoId}`;
      }
      case 'wistia': {
        const videoId = urlObj.pathname.split('/').pop();
        return `https://fast.wistia.net/embed/iframe/${videoId}`;
      }
      case 'tiktok': {
        // For shortened URLs like https://www.tiktok.com/t/ZTYekCTvE/
        // We'll need to ask users to provide the full URL
        if (urlObj.pathname.startsWith('/t/')) {
          return url;
        }

        const pathParts = urlObj.pathname.split('/');
        const videoId = pathParts.find((part) => /^\d+$/.test(part));
        if (!videoId) return url;
        return `https://www.tiktok.com/embed/v3/${videoId}`;
      }
      default:
        return url;
    }
  } catch {
    return url;
  }
};

// Example test links:
// TikTok:
// - Share: https://www.tiktok.com/@username/video/7432007200320589061?is_from_webapp=1
// - Direct: https://www.tiktok.com/@username/video/7432007200320589061
// - Short: https://www.tiktok.com/t/ZTYekCTvE/
// - Embed: https://www.tiktok.com/embed/v3/7432007200320589061
//
// YouTube:
// - Regular: https://www.youtube.com/watch?v=dQw4w9WgXcQ
// - Short: https://youtu.be/dQw4w9WgXcQ
//
// Loom:
// - Share: https://www.loom.com/share/41f6aad19fb74048a8e9dbf3105b5a6a
// - Direct: https://www.loom.com/v/41f6aad19fb74048a8e9dbf3105b5a6a
//
// Wistia:
// - Share: https://home.wistia.com/medias/e4a27b971d
// - Embed: https://fast.wistia.net/embed/iframe/e4a27b971d

const URL_REGEX =
  /(?:^|\s)((?:https?:\/\/)?[\w-]+(?:\.[\w-]+)+\.?(?::\d+)?(?:\/\S*)?)/gi;
const MARKDOWN_REGEX = /\[([^\]]+)\]\(([^)]+)\)/g;

export const extractUrls = (text: string): string[] => {
  const urls: string[] = [];

  // Find markdown links
  const markdownMatches = [...text.matchAll(MARKDOWN_REGEX)];
  markdownMatches.forEach((match) => {
    const url = match[2].trim();
    if (url && !urls.includes(url)) urls.push(url);
  });

  // Find plain URLs
  const urlMatches = text.matchAll(URL_REGEX);
  for (const match of urlMatches) {
    const url = match[1].trim();
    if (url && !urls.includes(url)) urls.push(url);
  }

  return urls;
};

export const getEmbeddableVideos = (text: string): string[] => {
  const urls = extractUrls(text);
  const embedUrls = urls
    .filter((url) => getVideoProvider(url) !== 'unknown')
    .map((url) => parseVideoLink(url));

  return [...new Set(embedUrls)]; // Remove any duplicates
};
