import { PlusIcon } from '@radix-ui/react-icons';
import { XIcon } from 'lucide-react';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  spaceGroupControllerInviteUserToSpaceGroup,
  useBrandsControllerCreateBrand,
  useSpaceGroupControllerAddSpacesToSpaceGroup,
  useSpaceGroupControllerCreateSpaceGroup,
  useStripeControllerCreateStripeConnectAccount,
  useStripeControllerGetStripeConnectAccount,
} from '@/api';
import { CalendarIcon } from '@/assets/icon/calendar';
import { ChatIcon } from '@/assets/icon/chat';
import { CourseSpaceIcon } from '@/assets/icon/course-space';
import { MembersSapceIcon } from '@/assets/icon/member-space';
import { PencilEditIcon } from '@/assets/icon/pencil-edit';
import { PostIcon } from '@/assets/icon/post';
import { Loading } from '@/components/Loading';
import { useUpdateConfigHeader } from '@/components/config-header-provider';
import { FileDropzone } from '@/components/file-dropzone';
// import { FirstProductCreationForms } from '@/components/new-brand-flow/first-product-creation-forms';
import ImageCropModal from '@/components/ui/ImageCropModal';
import InputField from '@/components/ui/InputField';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { Button } from '@/components/ui/button';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Switch } from '@/components/ui/switch';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useToast } from '@/hooks/use-toast';
import { cn, getObjectValues, imageToBase64 } from '@/lib/utils';

const SPACES = [
  {
    name: 'posts',
    Icon: PostIcon,
    color: '#D55F2A',
    background: '#F4DDCF',
  },
  {
    name: 'events',
    Icon: CalendarIcon,
    color: '#4D8EED',
    background: '#CDE3FD',
  },
  {
    name: 'chats',
    Icon: ChatIcon,
    color: '#65A96E',
    background: '#DDECE0',
  },
  {
    name: 'courses',
    Icon: CourseSpaceIcon,
    color: '#8A69E9',
    background: '#E7E2F4',
  },
  {
    name: 'members',
    Icon: MembersSapceIcon,
    color: '#F32222',
    background: '#FFD8D8',
  },
] as const;

type Spaces = {
  chats: boolean;
  courses: boolean;
  events: boolean;
  members: boolean;
  posts: boolean;
};

const stepList = ['payouts', 'customize', 'features', 'members'];

const OnboardingPage = () => {
  useUpdateConfigHeader(
    {
      showNotifications: false,
      label: '',
      action: '',
      hidden: true,
    },
    [],
  );

  const createBrandMutation = useBrandsControllerCreateBrand();
  const createSpaceGroupMutation = useSpaceGroupControllerCreateSpaceGroup();
  const addSpacesToSpaceGroupMutation =
    useSpaceGroupControllerAddSpacesToSpaceGroup();

  const logoRef = useRef<HTMLInputElement>(null);

  const navigate = useNavigate();
  const { toast } = useToast();

  const [isRedirecting, setIsRedirecting] = useState(false);
  const [communityName, setCommunityName] = useState('');
  const [communityLogo, setCommunityLogo] = useState<File>();
  const [open, setOpen] = useState(false);
  const [invitesCsv, setInvitesCsv] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [spaces, setSpaces] = useState<Spaces>({
    chats: false,
    courses: false,
    events: false,
    members: false,
    posts: false,
  });
  const [invitesList, setInvitesList] = useState<string[]>(['']);
  const [isPaid, setIsPaid] = useState(false);
  const [billingFrequency, setBillingFrequency] = useState<string>('MONTHLY');
  // const [subPeriod, setSubPeriod] = useState<string>('MONTHLY');
  const [price, setPrice] = useState<string>('');
  const [currentStep, setCurrentStep] = useState('');

  const stripeGetAccountQuery = useStripeControllerGetStripeConnectAccount();

  const stripeCreateAccountMutation =
    useStripeControllerCreateStripeConnectAccount({
      mutation: {
        onSuccess: (data) => {
          const linkToRedirect = data.accountLink;
          // redirect to the link
          setIsRedirecting(true);
          window.location.href = linkToRedirect;
        },
      },
    });

  if (
    stripeGetAccountQuery.isLoading ||
    stripeCreateAccountMutation.isPending ||
    isRedirecting
  ) {
    return <Loading />;
  }

  const handleCreateStripeAccount = () => {
    stripeCreateAccountMutation.mutate();
  };

  // const handleFinishCreateProduct = () => {
  //   // TODO: implement
  // };

  const stripeAccount = stripeGetAccountQuery.data;

  if (!stripeAccount) {
    throw new Error(`No stripe account found, ${stripeGetAccountQuery.error}`);
  }

  const isStripeAccountConnected = getObjectValues(stripeAccount).every(
    (value) => value === true,
  );

  const step = isStripeAccountConnected ? 2 : 1;

  const convertImageUrlToFile = async (url: string) => {
    const response = await fetch(url);
    const blob = await response.blob();
    const file = new File([blob], 'image.jpg', { type: 'image/jpeg' });
    return file;
  };

  const handleCroppedImage = async (croppedImage: string) => {
    const imageFile = await convertImageUrlToFile(croppedImage);
    setCommunityLogo(imageFile);
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeLogo = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCommunityLogo(e.target.files?.[0]);
    setOpen(true);
  };

  const handleSelectSpace = (space: keyof Spaces) => {
    setSpaces((prev) => ({
      ...prev,
      [space]: !prev[space],
    }));
  };

  const handleChangeInviteList = (index: number, value: string) => {
    const newInvitesList = [...invitesList];
    newInvitesList[index] = value;
    setInvitesList(newInvitesList);
  };

  const addInvite = () => {
    setInvitesList((prev) => [...prev, '']);
  };

  const removeInvite = (index: number) => {
    setInvitesList((prev) => {
      const newInvitesList = [...prev];
      newInvitesList.splice(index, 1);
      return newInvitesList;
    });
  };

  const downloadCsv = () => {
    const fileTxt = `Name,Email
Member 1,member@test.com
Member 2,member@test.com
Member 3,member@test.com
Member 4,member@test.com
Member 5,member@test.com
Member 6,member@test.com`;
    const element = document.createElement('a');
    const file = new Blob([fileTxt], { type: 'text/csv' });
    element.href = URL.createObjectURL(file);
    element.download = 'Circle Community Example CSV.csv';
    document.body.appendChild(element);

    element.click();
  };

  const handleCompleteSetup = async (doInvites: boolean) => {
    if (!communityName) {
      toast({
        title: 'Community name is required',
        variant: 'destructive',
      });
      return;
    }

    if (!isStripeAccountConnected) {
      toast({
        title: 'Please connect your stripe account',
        variant: 'destructive',
      });
      return;
    }

    if (isPaid && (!price || !billingFrequency)) {
      toast({
        title: 'Please fill in the pricing details',
        variant: 'destructive',
      });
      return;
    }

    if (
      !spaces.chats &&
      !spaces.courses &&
      !spaces.events &&
      !spaces.members &&
      !spaces.posts
    ) {
      toast({
        title: 'Please select at least one feature',
        variant: 'destructive',
      });
      return;
    }

    if (doInvites && invitesList.length === 0) {
      toast({
        title: 'Please add at least one invite',
        variant: 'destructive',
      });
      return;
    }

    if (
      doInvites &&
      invitesList.some(
        (invite) => !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(invite) || !invite,
      )
    ) {
      toast({
        title: 'Please add a valid email',
        variant: 'destructive',
      });
      return;
    }

    try {
      setIsLoading(true);
      const brand = await createBrandMutation.mutateAsync({
        data: {
          name: communityName,
          description: '',
          logoBase64: communityLogo
            ? await imageToBase64(communityLogo)
            : undefined,
        },
      });

      const spaceGroup = await createSpaceGroupMutation.mutateAsync({
        data: {
          brandId: brand.id,
          name: communityName,
          isPrivate: false,
          pricingType: isPaid ? 'PAID' : 'FREE',
          billingFrequency: isPaid ? billingFrequency : undefined,
          price: isPaid ? parseInt(price) : 0,
        },
      });

      await addSpacesToSpaceGroupMutation.mutateAsync({
        spaceGroupId: spaceGroup.id,
        data: {
          chatSpace: spaces.chats,
          courseSpace: spaces.courses,
          eventSpace: spaces.events,
          memberSpace: spaces.members,
          postSpace: spaces.posts,
        },
      });

      if (doInvites) {
        // send invites
        await invitesList.forEach(async (invite) => {
          spaceGroupControllerInviteUserToSpaceGroup(spaceGroup.id, {
            email: invite,
            notifyWhenUserAccepts: true,
          });
        });
        navigate(`/brands/${brand.id}`);
      } else {
        navigate(`/brands/${brand.id}`);
      }
    } catch (error) {
      console.error(error);
      toast({
        title: 'Ups, something went wrong',
        variant: 'destructive',
      });
    }
  };

  return (
    <div className="h-full overflow-auto">
      <div className="flex w-full flex-col gap-10 px-6 py-10 xl:px-62.5 xl:py-12">
        <div className="flex w-full flex-col gap-2.5">
          <h1 className="text-2xl font-semibold text-black dark:text-white">
            Getting started Checklist
          </h1>
          <p className="text-base font-normal text-textParagraph dark:text-dark-textParagraph">
            Complete these simple steps to get your account up and running
          </p>
        </div>
        <div className="flex w-full items-center gap-6">
          <progress
            className="h-2.5 w-full [&::-webkit-progress-bar]:rounded-full [&::-webkit-progress-bar]:bg-light-2 [&::-webkit-progress-bar]:dark:bg-dark-2 [&::-webkit-progress-value]:rounded-full [&::-webkit-progress-value]:bg-green-accent"
            value={stepList.indexOf(currentStep) + 1}
            max={4}
          />
          <div className="text-nowrap rounded-lg border border-[#5AE99E1A] bg-[#5AE99E1A] px-2.5 py-[5px] leading-3 text-black dark:text-white">
            {`${
              ((stepList.indexOf(currentStep) + 1) / stepList.length) * 100
            }% complete`}
          </div>
        </div>
        <div className="flex w-full">
          <Accordion
            type="single"
            collapsible
            className="flex w-full flex-col gap-[14px]"
            value={currentStep}
          >
            <AccordionItem
              value="payouts"
              className={`flex w-full flex-col gap-8 rounded-2xl border-none bg-light-2 dark:bg-dark-2 ${step !== 1 && 'opacity-50'}`}
              disabled={step !== 1}
            >
              <AccordionTrigger
                onClick={() => setCurrentStep('payouts')}
                className="flex w-full items-center gap-4 rounded-2xl bg-light-2 p-6 text-lg font-bold text-black hover:no-underline dark:bg-dark-2 dark:text-white"
              >
                <div className="flex max-h-8 min-h-8 min-w-8 items-center justify-center rounded-full bg-light-3 text-sm font-medium text-textParagraph dark:bg-dark-3 dark:text-dark-textParagraph">
                  1
                </div>
                <div className="w-full text-start">Setup Payouts</div>
              </AccordionTrigger>
              <AccordionContent className="flex w-full flex-col gap-4 rounded-b-2xl bg-light-2 px-4 pb-4 dark:bg-dark-2">
                <div className="flex flex-col gap-6">
                  <div className="text-base font-medium text-textParagraph dark:text-dark-textParagraph">
                    Set up payout details so you can get paid
                  </div>
                  <div className="flex items-center justify-between">
                    <div>1 of 4</div>
                    <Button
                      variant={'default'}
                      onClick={handleCreateStripeAccount}
                    >
                      Connect account
                    </Button>
                  </div>
                </div>
              </AccordionContent>
            </AccordionItem>
            <AccordionItem
              value="customize"
              className="flex w-full flex-col gap-8 rounded-2xl border-none bg-light-2 dark:bg-dark-2"
            >
              <AccordionTrigger
                onClick={() => setCurrentStep('customize')}
                className="flex w-full items-center gap-4 rounded-2xl bg-light-2 p-6 text-lg font-bold text-black hover:no-underline dark:bg-dark-2 dark:text-white"
              >
                <div className="flex max-h-8 min-h-8 min-w-8 items-center justify-center rounded-full bg-light-3 text-sm font-medium text-textParagraph dark:bg-dark-3 dark:text-dark-textParagraph">
                  2
                </div>
                <div className="w-full text-start">
                  Customize your community
                </div>
              </AccordionTrigger>
              <AccordionContent className="flex w-full flex-col gap-4 rounded-b-2xl bg-light-2 px-4 pb-4 dark:bg-dark-2">
                <div className="flex flex-col gap-6">
                  <div className="flex w-full flex-col gap-8 text-base font-medium text-textParagraph dark:text-dark-textParagraph">
                    <InputField
                      onChange={(e) => setCommunityName(e.target.value)}
                      type="text"
                      value={communityName}
                      title="Community name"
                      inputClassName="border border-light dark:border-dark-light !bg-transparent shadow-none"
                    />
                    <div className="flex w-full items-center gap-6 rounded-2xl border border-light p-4 dark:border-dark-light">
                      <div className="flex w-full flex-col gap-1 text-sm">
                        <h3 className="font-medium text-black dark:text-white">
                          Logo
                        </h3>
                        <p className="font-normal text-textParagraph dark:text-dark-textParagraph">
                          Recommended size: 150x150 (1:1).
                        </p>
                      </div>
                      {communityLogo ? (
                        <img
                          src={URL.createObjectURL(communityLogo)}
                          alt="comunity pic"
                          className="max-h-16 min-h-16 min-w-16 max-w-16 rounded-lg"
                        />
                      ) : (
                        <div className="min-h-16 min-w-16 rounded-lg bg-light"></div>
                      )}
                      <div
                        onClick={() => logoRef.current?.click()}
                        className="flex min-h-12 min-w-12 cursor-pointer items-center justify-center rounded-full border border-light dark:border-dark-light"
                      >
                        <PencilEditIcon className="stroke-black dark:stroke-white" />
                      </div>
                      <input
                        type="file"
                        ref={logoRef}
                        hidden
                        onChange={handleChangeLogo}
                      />
                    </div>
                    <div className="flex w-full flex-col gap-2.5">
                      <div className="text-base font-medium text-black dark:text-white">
                        Pricing
                      </div>
                      <div className="flex gap-2.5">
                        <Button
                          type="button"
                          onClick={() => setIsPaid(false)}
                          variant={!isPaid ? 'default' : 'outline'}
                        >
                          Free
                        </Button>
                        <Button
                          type="button"
                          onClick={() => setIsPaid(true)}
                          variant={isPaid ? 'default' : 'outline'}
                        >
                          Paid
                        </Button>
                      </div>
                    </div>
                    {isPaid && (
                      <>
                        <div className="flex w-full flex-col gap-2.5">
                          <div className="text-base font-medium text-black dark:text-white">
                            Billing frequency
                          </div>
                          <Select
                            onValueChange={setBillingFrequency}
                            defaultValue={billingFrequency}
                          >
                            <SelectTrigger
                              className={cn(
                                'h-fit border border-light !bg-transparent p-4 text-base capitalize text-black dark:border-dark-light dark:text-white',
                              )}
                            >
                              <SelectValue />
                            </SelectTrigger>
                            <SelectContent className="border border-light dark:border-dark-light">
                              {[
                                {
                                  value: 'ONE_TIME_PAYMENT',
                                  label: 'One-Time Payment',
                                },
                                {
                                  value: 'WEEKLY',
                                  label: 'Weekly',
                                },
                                {
                                  value: 'MONTHLY',
                                  label: 'Monthly',
                                },
                                {
                                  value: 'YEARLY',
                                  label: 'Yearly',
                                },
                              ].map((billingFrequency) => (
                                <SelectItem
                                  key={billingFrequency.value}
                                  value={billingFrequency.value}
                                  className="bg-light-2 capitalize dark:bg-dark-2"
                                >
                                  {billingFrequency.label} Payment
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        </div>
                        {/* <div className="flex w-full flex-col gap-2.5">
                          <div className="text-base font-medium text-black dark:text-white">
                            Subscription period
                          </div>
                          <Select
                            onValueChange={setSubPeriod}
                            defaultValue={subPeriod}
                          >
                            <SelectTrigger
                              className={cn(
                                'h-fit border border-light !bg-transparent p-4 text-base capitalize text-black dark:border-dark-light dark:text-white',
                              )}
                            >
                              <SelectValue />
                            </SelectTrigger>
                            <SelectContent className="border border-light dark:border-dark-light">
                              {[
                                {
                                  label: '1 month',
                                  value: 'monthly',
                                },
                                {
                                  label: '3 month',
                                  value: 'tri-monthly',
                                },
                                {
                                  label: '6 month',
                                  value: 'semesterly',
                                },
                                {
                                  label: '1 year',
                                  value: 'yearly',
                                },
                              ].map((item) => (
                                <SelectItem
                                  key={item.value}
                                  value={item.value}
                                  className="bg-light-2 capitalize dark:bg-dark-2"
                                >
                                  {item.label} Payment
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        </div> */}
                        <InputField
                          onChange={(e) => setPrice(e.target.value)}
                          type="text"
                          value={price}
                          title="Price"
                          inputClassName="border border-light dark:border-dark-light !bg-transparent shadow-none"
                          regex={/^\d*\.?\d{0,2}$/}
                        />
                      </>
                    )}
                  </div>
                  <div className="flex items-center justify-between">
                    <div>2 of 4</div>
                    <div className="flex gap-2.5">
                      {/* <Button
                        variant={'ghost'}
                        onClick={() => setCurrentStep('features')}
                      >
                        Skip
                      </Button> */}
                      <Button
                        variant={'default'}
                        onClick={() => setCurrentStep('features')}
                      >
                        Continue
                      </Button>
                    </div>
                  </div>
                </div>
              </AccordionContent>
            </AccordionItem>
            <AccordionItem
              value="features"
              className="flex w-full flex-col gap-8 rounded-2xl border-none bg-light-2 dark:bg-dark-2"
            >
              <AccordionTrigger
                onClick={() => setCurrentStep('features')}
                className="flex w-full items-center gap-4 rounded-2xl bg-light-2 p-6 text-lg font-bold text-black hover:no-underline dark:bg-dark-2 dark:text-white"
              >
                <div className="flex max-h-8 min-h-8 min-w-8 items-center justify-center rounded-full bg-light-3 text-sm font-medium text-textParagraph dark:bg-dark-3 dark:text-dark-textParagraph">
                  3
                </div>
                <div className="w-full text-start">Select Features</div>
              </AccordionTrigger>
              <AccordionContent className="flex w-full flex-col gap-6 rounded-b-2xl bg-light-2 px-4 pb-4 dark:bg-dark-2">
                {SPACES.map((space) => (
                  <div
                    className="flex cursor-pointer items-center gap-4 rounded-2xl border border-light p-4 dark:border-dark-light"
                    onClick={() => handleSelectSpace(space.name)}
                  >
                    <CustomCheckbox value={spaces[space.name]} />
                    <div className="flex items-center gap-2.5">
                      <div
                        className="rounded-lg p-4"
                        style={{
                          backgroundColor: space.background,
                        }}
                      >
                        <space.Icon
                          className="h-5 w-5 fill-current dark:fill-current"
                          color={space.color}
                        />
                      </div>
                      <div className="w-full text-lg font-bold capitalize text-black dark:text-white">
                        {space.name}
                      </div>
                    </div>
                  </div>
                ))}
                <div className="flex items-center justify-between">
                  <div>3 of 4</div>
                  <Button
                    variant={'default'}
                    onClick={() => setCurrentStep('members')}
                  >
                    Continue
                  </Button>
                </div>
              </AccordionContent>
            </AccordionItem>
            <AccordionItem
              value="members"
              className="flex w-full flex-col gap-8 rounded-2xl border-none bg-light-2 dark:bg-dark-2"
            >
              <AccordionTrigger
                onClick={() => setCurrentStep('members')}
                className="flex w-full items-center gap-4 rounded-2xl bg-light-2 p-6 text-lg font-bold text-black hover:no-underline dark:bg-dark-2 dark:text-white"
              >
                <div className="flex max-h-8 min-h-8 min-w-8 items-center justify-center rounded-full bg-light-3 text-sm font-medium text-textParagraph dark:bg-dark-3 dark:text-dark-textParagraph">
                  4
                </div>
                <div className="w-full text-start">Invite Members</div>
              </AccordionTrigger>
              <AccordionContent className="flex w-full flex-col gap-10 rounded-b-2xl bg-light-2 px-4 pb-4 dark:bg-dark-2">
                <div className="w-full text-base font-normal text-textParagraph dark:text-dark-textParagraph">
                  How do you want to invite members?
                </div>
                <Tabs
                  defaultValue="manually"
                  className="flex w-full flex-col gap-10"
                >
                  <TabsList className="grid w-full grid-cols-2 p-[3px]">
                    <TabsTrigger value="manually">Invite Link</TabsTrigger>
                    <TabsTrigger value="import">Send invites</TabsTrigger>
                  </TabsList>
                  <TabsContent
                    value="manually"
                    className="flex w-full flex-col gap-6"
                  >
                    {invitesList.map((invite, index) => (
                      <div
                        className="flex w-full items-center gap-4"
                        key={index}
                      >
                        {/* <InputField
                          onChange={(e) => {
                            handleChangeInviteList(
                              index,
                              'name',
                              e.target.value,
                            );
                          }}
                          type="text"
                          value={invite.name}
                          title="Name"
                          placeholder="Enter fullname"
                          inputClassName="border border-light dark:border-dark-light !bg-transparent shadow-none"
                        /> */}
                        <InputField
                          onChange={(e) => {
                            handleChangeInviteList(index, e.target.value);
                          }}
                          type="text"
                          value={invite}
                          title="Email address"
                          placeholder="Enter email address"
                          inputClassName="border border-light dark:border-dark-light !bg-transparent shadow-none"
                        />
                        {invitesList.length > 1 && (
                          <XIcon
                            className="mt-6 h-5 min-w-5 cursor-pointer"
                            onClick={() => removeInvite(index)}
                          />
                        )}
                      </div>
                    ))}
                    <Button
                      variant={'outline'}
                      className="flex h-fit gap-2.5 border-black !bg-transparent px-4 py-3 text-base font-medium text-black dark:border-white dark:text-white"
                      onClick={addInvite}
                    >
                      Add New
                      <PlusIcon className="h-5 w-5" />
                    </Button>
                    <div className="flex h-12 w-full items-center justify-between text-base font-medium text-black dark:text-white">
                      Bypass checkout
                      <Switch
                      // checked={}
                      // onCheckedChange={}
                      />
                    </div>
                  </TabsContent>
                  <TabsContent
                    value="import"
                    className="flex w-full flex-col gap-6"
                  >
                    <FileDropzone
                      onFileChange={setInvitesCsv}
                      infoText={
                        <div className="flex flex-col items-center gap-6">
                          {!invitesCsv ? (
                            <p className="text-sm font-normal text-textParagraph dark:text-dark-textParagraph">
                              Please choose a comma-separated CSV file formatted
                              with name, email. Here's a{' '}
                              <span
                                className="cursor-pointer underline"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  downloadCsv();
                                }}
                              >
                                template CSV file
                              </span>
                              .
                            </p>
                          ) : (
                            <p className="text-sm font-normal text-textParagraph dark:text-dark-textParagraph">
                              {invitesCsv.name}
                            </p>
                          )}
                          <Button
                            variant={'ghost'}
                            className="w-fit border border-light dark:border-dark-light"
                          >
                            Upload CSV
                          </Button>
                        </div>
                      }
                    />
                  </TabsContent>
                </Tabs>
                <div className="flex items-center justify-between">
                  <div>4 of 4</div>
                  <div className="flex gap-2.5">
                    <Button
                      variant={'ghost'}
                      onClick={() => handleCompleteSetup(false)}
                      disabled={isLoading}
                    >
                      Skip
                    </Button>
                    <Button
                      variant={'default'}
                      onClick={() => handleCompleteSetup(true)}
                      disabled={isLoading}
                    >
                      Invite member
                    </Button>
                  </div>
                </div>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </div>
      </div>
      <div className="absolute bottom-8 right-9 rounded border border-light px-4 py-2 text-xs font-medium lowercase text-black shadow dark:border-dark-light dark:text-white">
        Powered by teachly
      </div>
      {communityLogo && (
        <ImageCropModal
          image={URL.createObjectURL(communityLogo)}
          open={open}
          onClose={handleClose}
          onSave={handleCroppedImage}
          ratio={1 / 1}
        />
      )}
    </div>
  );
};

export default OnboardingPage;

const CustomCheckbox = ({ value }: { value?: boolean }) => (
  <div className="flex items-start gap-2">
    <div className="mt-1 grid place-items-center">
      <div className="peer col-start-1 row-start-1 h-6 w-6 shrink-0 appearance-none rounded-full border-2 border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-0 disabled:border-gray-400" />
      <div
        className={cn(
          'pointer-events-none',
          'col-start-1 row-start-1',
          'h-3 w-3 rounded-full',
          value && 'bg-blue-500',
        )}
      />
    </div>
  </div>
);
