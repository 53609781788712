/**
 * @file InputField component
 * @param {string} title - The title for the input field
 * @param {string} placeholder - The placeholder for the input field
 * @param {boolean} disabled - Whether the input field is disabled
 * @param {() => void} onChange - The function to call when the input
 * @param {string} value - The value of the input field
 * @param {string} type - The type of the input field
 * @param {string} startIcon - The icon to display on the left of the input field
 * @param {string} error - The error message to display
 * @param {string} helperText - The helper text to display
 * @returns {JSX.Element} - The input field component
 */
import React, { useState } from 'react';

import { ArrowLeftIcon } from '@/assets/icon/arrowLeft';
import { EyeIcon } from '@/assets/icon/eye';
import { EyeSlashIcon } from '@/assets/icon/eye-slash';
import { cn } from '@/lib/utils';
import { countryList } from '@/utils/CountryProvider';

type Props = {
  title?: string;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string | number;
  type: string;
  startIcon?: React.ReactNode;
  error?: string;
  helperText?: string;
  className?: string;
  inputClassName?: string;
  titleClassName?: string;
  autoFocus?: boolean;
  dial?: Dial;
  onChangeDial?: (dial: Dial) => void;
  regex?: RegExp;
  disablePaste?: boolean;
  startChar?: string;
  endChar?: string;
};

type Dial = {
  name: string;
  idd: string;
  flag: string;
};

const InputField = ({
  title,
  placeholder,
  disabled,
  onChange,
  value,
  type,
  startIcon,
  error,
  helperText,
  className = '',
  inputClassName = '',
  titleClassName = '',
  autoFocus = false,
  onChangeDial,
  dial,
  regex,
  disablePaste,
  startChar,
  endChar,
}: Props) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const dialOptions = countryList;

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectDial = (dial: Dial) => {
    setIsOpen(false);
    onChangeDial && onChangeDial(dial);
  };

  return (
    <div
      className={`${className} flex w-full flex-col items-start gap-1 text-base text-black dark:text-white ${
        disabled && 'opacity-50'
      } `}
    >
      <label
        className={cn(
          `font-medium`,
          error && 'text-destructive',
          titleClassName,
        )}
      >
        {title}
      </label>
      <div
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        className={`flex w-full gap-2 rounded-lg bg-white p-4 shadow-md placeholder:text-textParagraph dark:bg-dark-bgGrey ${
          error
            ? 'border-2 border-error'
            : isFocused
              ? 'border-2 border-brand'
              : 'border border-light'
        } ${error && 'border-2 border-error'} ${inputClassName} `}
      >
        {startIcon}
        {type === 'phone' && (
          <div
            className="flex h-full min-w-fit items-center gap-1 rounded-s-md hover:cursor-pointer"
            onClick={handleToggle}
            onBlur={() => setIsOpen(false)}
          >
            <div className="h-6 w-6 flex-shrink-0 overflow-hidden rounded-full border border-light">
              {dial?.name ? (
                <img
                  alt={dial?.name}
                  src={dial?.flag}
                  className="h-full w-full object-cover"
                />
              ) : (
                <div className="h-full w-full bg-textParagraph object-cover"></div>
              )}
            </div>
            <div>{dial?.idd}</div>
            <ArrowLeftIcon
              onClick={handleSelectDial}
              className="min-w-6 rotate-270 fill-black dark:fill-white"
            />
          </div>
        )}
        {type === 'phone' && isOpen && (
          <div
            className={`scrollbar absolute z-50 flex max-h-60 max-w-80 flex-col gap-2 overflow-auto rounded-md bg-white pb-4 shadow dark:bg-dark-bgGrey`}
          >
            {dialOptions.map((dial) => (
              <div
                key={dial.name}
                onClick={() => handleSelectDial(dial)}
                className="flex cursor-pointer items-center gap-2 p-2 hover:bg-gray-200 dark:hover:bg-dark-bgGrey"
              >
                <img
                  alt={dial.name}
                  src={dial.flag}
                  className=""
                  width={35}
                  height={10}
                />
                <div>
                  {dial.name} ({dial.idd})
                </div>
              </div>
            ))}
          </div>
        )}
        {startChar && (
          <div className="flex items-center text-textParagraph">
            {startChar}
          </div>
        )}
        <input
          autoFocus={autoFocus}
          className={`remove-arrow w-full bg-inherit outline-none`}
          placeholder={placeholder}
          disabled={disabled}
          onChange={(e) => {
            if (regex) {
              if (regex.test(e.target.value)) {
                onChange && onChange(e);
              }
            } else {
              onChange && onChange(e);
            }
          }}
          value={value}
          type={type === 'password' && passwordVisible ? 'text' : type}
          onPaste={(e) => disablePaste && e.preventDefault()}
        />
        {endChar && (
          <div className="flex items-center text-textParagraph">{endChar}</div>
        )}
        {type === 'password' && (
          <button
            type="button"
            onClick={() => setPasswordVisible(!passwordVisible)}
            className="focus:outline-none"
          >
            {passwordVisible ? (
              <EyeIcon className="h-6 w-6 stroke-black opacity-40 dark:stroke-white" />
            ) : (
              <EyeSlashIcon className="h-6 w-6 stroke-black opacity-40 dark:stroke-white" />
            )}
          </button>
        )}
      </div>
      {error && <p className="text-xs font-normal text-error">{error}</p>}
      {!error && helperText && (
        <p className="text-xs font-normal text-textParagraph">{helperText}</p>
      )}
    </div>
  );
};

export default InputField;
