import { useQueryClient } from '@tanstack/react-query';
import { EllipsisVerticalIcon } from 'lucide-react';

import {
  SpaceGroupMembersDto,
  getSpaceGroupControllerGetSpaceGroupMembersQueryKey,
  useSpaceGroupControllerRemoveUserFromSpaceGroup,
} from '@/api';
import { UserIcon } from '@/assets/icon/user';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { useBrandPermits } from '@/hooks/useBrandPermits';
import { valueOrUndefined } from '@/lib/utils';

import { Avatar, AvatarFallback, AvatarImage } from '../avatar';
import { Button } from '../button';

type Props = {
  member: SpaceGroupMembersDto;
  openProfileInfo: () => void;
};

const CardMember = ({ member, openProfileInfo }: Props) => {
  const hasPermits = useBrandPermits();

  const queryClient = useQueryClient();
  const removeUserFromSpaceGroupMutation =
    useSpaceGroupControllerRemoveUserFromSpaceGroup({
      mutation: {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: getSpaceGroupControllerGetSpaceGroupMembersQueryKey(
              member.spaceGroupId,
            ),
          });
        },
      },
    });

  return (
    <Button
      onClick={openProfileInfo}
      variant={'ghost'}
      size={'none'}
      className="flex w-full flex-col gap-2.5 rounded-lg bg-light-2 p-2.5 dark:bg-dark-2 sm:w-fit"
    >
      <Avatar className="aspect-square h-57.5 w-full rounded-2xl sm:max-w-57.5">
        <AvatarImage
          src={valueOrUndefined(member.user.avatarUrl)}
          className="h-57.5 w-full rounded-2xl object-cover"
        />
        <AvatarFallback className="h-57.5 rounded-2xl">
          <UserIcon className="h-57.5 w-full rounded-2xl stroke-black dark:stroke-white" />
        </AvatarFallback>
      </Avatar>

      <div className="flex h-16 w-full items-center justify-between gap-2.5 font-medium">
        <p className="">{member.user.name || member.user.email}</p>
        {hasPermits && member.role !== 'CREATOR' && (
          <DropdownMenu>
            <DropdownMenuTrigger>
              <EllipsisVerticalIcon />
            </DropdownMenuTrigger>
            <DropdownMenuContent
              side="bottom"
              align="end"
              onClick={(e) => e.stopPropagation()}
            >
              <DropdownMenuItem
                className="font-medium text-error"
                onSelect={() =>
                  removeUserFromSpaceGroupMutation.mutate({
                    spaceGroupId: member.spaceGroupId,
                    userId: member.user.id,
                  })
                }
              >
                Remove member
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        )}
      </div>
    </Button>
  );
};

export default CardMember;
