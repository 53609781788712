import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { useAuth } from '@/hooks/useContext';

const PrivateRoute = () => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  if (!isAuthenticated) {
    const callBackUrl = location.pathname;
    return <Navigate to={`/login?callbackUrl=${callBackUrl}`} replace />;
  }

  return <Outlet />;
};

export default PrivateRoute;
