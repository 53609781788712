/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * Teachly API V2
 * Teachly API documentation
 * OpenAPI spec version: 1.0
 */
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  QueryClient,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';

import { customFetch } from '../../../mutator/custom-fetch';
import type {
  StripeMetricsControllerGetMetricsParams,
  StripeMetricsControllerGetPayoutsMetricsParams,
  StripeMetricsResponseDto,
} from '../../model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * Retrieves various financial and user metrics for the specified brand, allowing customizable comparison periods.
 * @summary Retrieve Stripe metrics for a specific brand
 */
export const stripeMetricsControllerGetMetrics = (
  brandId: string,
  params?: StripeMetricsControllerGetMetricsParams,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<StripeMetricsResponseDto>(
    {
      url: `/stripe-metrics/metrics/${encodeURIComponent(String(brandId))}`,
      method: 'GET',
      params,
      signal,
    },
    options,
  );
};

export const getStripeMetricsControllerGetMetricsQueryKey = (
  brandId: string,
  params?: StripeMetricsControllerGetMetricsParams,
) => {
  return [
    `/stripe-metrics/metrics/${brandId}`,
    ...(params ? [params] : []),
  ] as const;
};

export const getStripeMetricsControllerGetMetricsQueryOptions = <
  TData = Awaited<ReturnType<typeof stripeMetricsControllerGetMetrics>>,
  TError = unknown,
>(
  brandId: string,
  params?: StripeMetricsControllerGetMetricsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof stripeMetricsControllerGetMetrics>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getStripeMetricsControllerGetMetricsQueryKey(brandId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof stripeMetricsControllerGetMetrics>>
  > = ({ signal }) =>
    stripeMetricsControllerGetMetrics(brandId, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!brandId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof stripeMetricsControllerGetMetrics>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type StripeMetricsControllerGetMetricsQueryResult = NonNullable<
  Awaited<ReturnType<typeof stripeMetricsControllerGetMetrics>>
>;
export type StripeMetricsControllerGetMetricsQueryError = unknown;

export function useStripeMetricsControllerGetMetrics<
  TData = Awaited<ReturnType<typeof stripeMetricsControllerGetMetrics>>,
  TError = unknown,
>(
  brandId: string,
  params: undefined | StripeMetricsControllerGetMetricsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof stripeMetricsControllerGetMetrics>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof stripeMetricsControllerGetMetrics>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useStripeMetricsControllerGetMetrics<
  TData = Awaited<ReturnType<typeof stripeMetricsControllerGetMetrics>>,
  TError = unknown,
>(
  brandId: string,
  params?: StripeMetricsControllerGetMetricsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof stripeMetricsControllerGetMetrics>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof stripeMetricsControllerGetMetrics>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useStripeMetricsControllerGetMetrics<
  TData = Awaited<ReturnType<typeof stripeMetricsControllerGetMetrics>>,
  TError = unknown,
>(
  brandId: string,
  params?: StripeMetricsControllerGetMetricsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof stripeMetricsControllerGetMetrics>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Retrieve Stripe metrics for a specific brand
 */

export function useStripeMetricsControllerGetMetrics<
  TData = Awaited<ReturnType<typeof stripeMetricsControllerGetMetrics>>,
  TError = unknown,
>(
  brandId: string,
  params?: StripeMetricsControllerGetMetricsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof stripeMetricsControllerGetMetrics>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getStripeMetricsControllerGetMetricsQueryOptions(
    brandId,
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Retrieve Stripe metrics for a specific brand
 */
export const prefetchStripeMetricsControllerGetMetrics = async <
  TData = Awaited<ReturnType<typeof stripeMetricsControllerGetMetrics>>,
  TError = unknown,
>(
  queryClient: QueryClient,
  brandId: string,
  params?: StripeMetricsControllerGetMetricsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof stripeMetricsControllerGetMetrics>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getStripeMetricsControllerGetMetricsQueryOptions(
    brandId,
    params,
    options,
  );

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getStripeMetricsControllerGetMetricsSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof stripeMetricsControllerGetMetrics>>,
  TError = unknown,
>(
  brandId: string,
  params?: StripeMetricsControllerGetMetricsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof stripeMetricsControllerGetMetrics>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getStripeMetricsControllerGetMetricsQueryKey(brandId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof stripeMetricsControllerGetMetrics>>
  > = ({ signal }) =>
    stripeMetricsControllerGetMetrics(brandId, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!brandId,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof stripeMetricsControllerGetMetrics>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type StripeMetricsControllerGetMetricsSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof stripeMetricsControllerGetMetrics>>
>;
export type StripeMetricsControllerGetMetricsSuspenseQueryError = unknown;

export function useStripeMetricsControllerGetMetricsSuspense<
  TData = Awaited<ReturnType<typeof stripeMetricsControllerGetMetrics>>,
  TError = unknown,
>(
  brandId: string,
  params: undefined | StripeMetricsControllerGetMetricsParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof stripeMetricsControllerGetMetrics>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useStripeMetricsControllerGetMetricsSuspense<
  TData = Awaited<ReturnType<typeof stripeMetricsControllerGetMetrics>>,
  TError = unknown,
>(
  brandId: string,
  params?: StripeMetricsControllerGetMetricsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof stripeMetricsControllerGetMetrics>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useStripeMetricsControllerGetMetricsSuspense<
  TData = Awaited<ReturnType<typeof stripeMetricsControllerGetMetrics>>,
  TError = unknown,
>(
  brandId: string,
  params?: StripeMetricsControllerGetMetricsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof stripeMetricsControllerGetMetrics>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Retrieve Stripe metrics for a specific brand
 */

export function useStripeMetricsControllerGetMetricsSuspense<
  TData = Awaited<ReturnType<typeof stripeMetricsControllerGetMetrics>>,
  TError = unknown,
>(
  brandId: string,
  params?: StripeMetricsControllerGetMetricsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof stripeMetricsControllerGetMetrics>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getStripeMetricsControllerGetMetricsSuspenseQueryOptions(
    brandId,
    params,
    options,
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Retrieves various financial and user metrics for the specified brand, allowing customizable comparison periods.
 * @summary Retrieve Stripe metrics for a specific brand
 */
export const stripeMetricsControllerGetPayoutsMetrics = (
  params?: StripeMetricsControllerGetPayoutsMetricsParams,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<StripeMetricsResponseDto>(
    { url: `/stripe-metrics/payouts-metrics`, method: 'GET', params, signal },
    options,
  );
};

export const getStripeMetricsControllerGetPayoutsMetricsQueryKey = (
  params?: StripeMetricsControllerGetPayoutsMetricsParams,
) => {
  return [
    `/stripe-metrics/payouts-metrics`,
    ...(params ? [params] : []),
  ] as const;
};

export const getStripeMetricsControllerGetPayoutsMetricsQueryOptions = <
  TData = Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutsMetrics>>,
  TError = unknown,
>(
  params?: StripeMetricsControllerGetPayoutsMetricsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutsMetrics>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getStripeMetricsControllerGetPayoutsMetricsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutsMetrics>>
  > = ({ signal }) =>
    stripeMetricsControllerGetPayoutsMetrics(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutsMetrics>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type StripeMetricsControllerGetPayoutsMetricsQueryResult = NonNullable<
  Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutsMetrics>>
>;
export type StripeMetricsControllerGetPayoutsMetricsQueryError = unknown;

export function useStripeMetricsControllerGetPayoutsMetrics<
  TData = Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutsMetrics>>,
  TError = unknown,
>(
  params: undefined | StripeMetricsControllerGetPayoutsMetricsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutsMetrics>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutsMetrics>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useStripeMetricsControllerGetPayoutsMetrics<
  TData = Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutsMetrics>>,
  TError = unknown,
>(
  params?: StripeMetricsControllerGetPayoutsMetricsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutsMetrics>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutsMetrics>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useStripeMetricsControllerGetPayoutsMetrics<
  TData = Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutsMetrics>>,
  TError = unknown,
>(
  params?: StripeMetricsControllerGetPayoutsMetricsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutsMetrics>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Retrieve Stripe metrics for a specific brand
 */

export function useStripeMetricsControllerGetPayoutsMetrics<
  TData = Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutsMetrics>>,
  TError = unknown,
>(
  params?: StripeMetricsControllerGetPayoutsMetricsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutsMetrics>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getStripeMetricsControllerGetPayoutsMetricsQueryOptions(
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Retrieve Stripe metrics for a specific brand
 */
export const prefetchStripeMetricsControllerGetPayoutsMetrics = async <
  TData = Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutsMetrics>>,
  TError = unknown,
>(
  queryClient: QueryClient,
  params?: StripeMetricsControllerGetPayoutsMetricsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutsMetrics>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getStripeMetricsControllerGetPayoutsMetricsQueryOptions(
    params,
    options,
  );

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getStripeMetricsControllerGetPayoutsMetricsSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutsMetrics>>,
  TError = unknown,
>(
  params?: StripeMetricsControllerGetPayoutsMetricsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutsMetrics>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getStripeMetricsControllerGetPayoutsMetricsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutsMetrics>>
  > = ({ signal }) =>
    stripeMetricsControllerGetPayoutsMetrics(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutsMetrics>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type StripeMetricsControllerGetPayoutsMetricsSuspenseQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutsMetrics>>
  >;
export type StripeMetricsControllerGetPayoutsMetricsSuspenseQueryError =
  unknown;

export function useStripeMetricsControllerGetPayoutsMetricsSuspense<
  TData = Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutsMetrics>>,
  TError = unknown,
>(
  params: undefined | StripeMetricsControllerGetPayoutsMetricsParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutsMetrics>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useStripeMetricsControllerGetPayoutsMetricsSuspense<
  TData = Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutsMetrics>>,
  TError = unknown,
>(
  params?: StripeMetricsControllerGetPayoutsMetricsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutsMetrics>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useStripeMetricsControllerGetPayoutsMetricsSuspense<
  TData = Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutsMetrics>>,
  TError = unknown,
>(
  params?: StripeMetricsControllerGetPayoutsMetricsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutsMetrics>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Retrieve Stripe metrics for a specific brand
 */

export function useStripeMetricsControllerGetPayoutsMetricsSuspense<
  TData = Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutsMetrics>>,
  TError = unknown,
>(
  params?: StripeMetricsControllerGetPayoutsMetricsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutsMetrics>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getStripeMetricsControllerGetPayoutsMetricsSuspenseQueryOptions(
      params,
      options,
    );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}
