export const MailReceiveIcon = (props) => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.1665 17.1668H3.33317C2.4127 17.1668 1.6665 16.4207 1.6665 15.5002V5.50016C1.6665 4.57969 2.4127 3.8335 3.33317 3.8335H16.6665C17.587 3.8335 18.3332 4.57969 18.3332 5.50016V10.5002"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.1668 13L12.0835 15.0833L14.1668 17.1667M18.3335 15.0833H12.5908"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 7.1665L9.16025 9.59334C9.67917 9.896 10.3208 9.896 10.8397 9.59334L15 7.1665"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
