import { arrowLeftIcon, lockIcon } from '@/assets/icon';
import { KeyIcon } from '@/assets/icon/key';
import InputField from '@/components/ui/InputField';
import { Button } from '@/components/ui/button';

interface EnterPasswordPageProps {
  password: string;
  setPassword: (password: string) => void;
  error: string;
  handleBackStep: () => void;
  handleSignIn: () => void;
  isLoading: boolean;
  requestError: string;
  forgotPassword: () => void;
}

const EnterPasswordPage: React.FC<EnterPasswordPageProps> = ({
  password,
  setPassword,
  error,
  handleBackStep,
  handleSignIn,
  requestError,
  isLoading,
  forgotPassword,
}) => {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSignIn();
      }}
      className="flex w-full flex-col items-center gap-10 rounded-2xl border border-light bg-white px-4 py-6 shadow dark:bg-dark-white sm:w-125 sm:p-10"
    >
      <div className="bg- rounded-full bg-primary-50 p-4 dark:bg-dark-primary-50">
        <img src={lockIcon} className="h-12 w-12" alt="lock icon" />
      </div>
      <h1 className="text-center text-2xl font-bold text-black dark:text-white">
        Enter your password
      </h1>
      <div className="flex w-full flex-col items-end gap-4">
        <InputField
          autoFocus
          title="Password"
          placeholder="Enter your password"
          disabled={false}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type="password"
          startIcon={
            <KeyIcon className="h-6 w-6 stroke-black opacity-40 dark:stroke-white" />
          }
          error={error}
        />
        {requestError && (
          <p className="w-full rounded-md bg-destructive p-4 text-center text-white">
            {requestError}
          </p>
        )}
        <div
          className="cursor-pointer text-sm font-normal text-brand"
          onClick={forgotPassword}
        >
          Forgot password?
        </div>
      </div>
      <div className="flex w-full flex-col gap-6">
        <Button
          type="submit"
          variant="default"
          className="p-8 text-lg font-normal"
          disabled={isLoading}
        >
          Sign in
        </Button>
        <Button
          type="button"
          variant="none"
          className="flex w-full justify-center gap-2 border-none p-5 text-lg font-normal"
          onClick={handleBackStep}
        >
          <img src={arrowLeftIcon} className="h-6 w-6" alt="arrow left" />
          <span className="text-[#3B82F6]">Back to previous step</span>
        </Button>
      </div>
    </form>
  );
};

export default EnterPasswordPage;
