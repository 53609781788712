export const MoneyReceiveIcon = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.0016 7.50521C9.08113 7.50521 8.33496 8.06485 8.33496 8.75523C8.33496 9.44556 9.08113 10.0052 10.0016 10.0052C10.922 10.0052 11.6683 10.5649 11.6683 11.2552C11.6683 11.9456 10.922 12.5052 10.0016 12.5052M10.0016 7.50521C10.7273 7.50521 11.3446 7.85304 11.5734 8.33856M10.0016 7.50521V6.67188M10.0016 12.5052C9.27588 12.5052 8.65854 12.1574 8.42979 11.6719M10.0016 12.5052V13.3386"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M10.8335 2.08984C10.8335 2.08984 10.5701 2.08984 10.0002 2.08984C6.26821 2.08984 4.40224 2.08984 3.24286 3.24922C2.0835 4.40859 2.0835 6.27456 2.0835 10.0065C2.0835 13.7384 2.0835 15.6044 3.24286 16.7639C4.40224 17.9232 6.26821 17.9232 10.0002 17.9232C13.7321 17.9232 15.5981 17.9232 16.7575 16.7639C17.9168 15.6044 17.9168 13.7384 17.9168 10.0065C17.9168 9.43661 17.9168 9.17319 17.9168 9.17319"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M17.9069 2.07812L14.4271 5.55996M13.7402 2.51292L13.8387 5.08917C13.8387 5.69637 14.2013 6.07469 14.8617 6.12241L17.4651 6.24479"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
