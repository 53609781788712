import { useQueryClient } from '@tanstack/react-query';
import { ChevronLeftIcon, ChevronRightIcon } from 'lucide-react';
import { type ReactNode, useEffect, useMemo, useState } from 'react';

import {
  MeetingDto,
  RescheduleAvailability,
  RescheduleTimeSlot,
  getMonetizationControllerGetMeetingsQueryKey,
  useMonetizationControllerGetRescheduleAvailability,
  useMonetizationControllerSelectRescheduleOption,
} from '@/api';
import { Loading } from '@/components/Loading';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogTrigger } from '@/components/ui/dialog';
import { cn } from '@/lib/utils';

export const RescheduleMeetingModal = ({
  trigger,
  meeting,
}: {
  trigger: ReactNode;
  meeting: MeetingDto;
}) => {
  const [open, setOpen] = useState(false);
  const rescheduleAvailabilityQuery =
    useMonetizationControllerGetRescheduleAvailability(meeting.id);

  const slots = rescheduleAvailabilityQuery.data?.availableSlots ?? [];

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogContent className="min-w-62.5">
        {rescheduleAvailabilityQuery.isLoading ? (
          <Loading size={4} />
        ) : (
          <RescheduleMeetingForm
            meeting={meeting}
            availability={slots}
            onClose={() => setOpen(false)}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

const RescheduleMeetingForm = ({
  meeting,
  availability,
  onClose,
}: {
  meeting: MeetingDto;
  availability: RescheduleAvailability[];
  onClose: () => void;
}) => {
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [selectedSlot, setSelectedSlot] = useState<RescheduleTimeSlot | null>(
    null,
  );
  const [currentWeek, setCurrentWeek] = useState(new Date());

  const queryClient = useQueryClient();
  const rescheduleOptionMutation =
    useMonetizationControllerSelectRescheduleOption({
      mutation: {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: getMonetizationControllerGetMeetingsQueryKey(),
          });
          onClose();
        },
      },
    });

  const availableDays = useMemo(() => {
    return availability
      .flatMap((a) => a.availableDays)
      .reduce((acc, day) => {
        acc.set(
          new Date(day.day).toISOString().split('T')[0],
          day.slots.filter((slot) => slot.canBook),
        );
        return acc;
      }, new Map<string, RescheduleTimeSlot[]>());
  }, [availability]);

  // Auto-select first available date when week changes or on initial load
  useEffect(() => {
    const firstAvailableDate = weekDays.find(
      (day) => day.isAvailable,
    )?.dateString;
    if (firstAvailableDate) {
      setSelectedDate(firstAvailableDate);
    }
  }, [currentWeek, availability]);

  // Auto-select first available time slot when date changes
  useEffect(() => {
    if (selectedDate) {
      const slots = availableDays.get(selectedDate) || [];
      if (slots.length > 0) {
        setSelectedSlot(slots[0]);
      } else {
        setSelectedSlot(null);
      }
    }
  }, [selectedDate, availableDays]);

  useEffect(() => {
    setWeekWithNearestAvailableDate();
  }, []);

  const setWeekWithNearestAvailableDate = () => {
    const nearestWeek = availability.find((a) => {
      const availableDays = a.availableDays.map((d) => new Date(d.day));
      return availableDays.some((d) => d >= new Date());
    });
    if (nearestWeek) {
      const availableDays = nearestWeek.availableDays.map((d) => {
        if (d.slots.some((s) => s.canBook)) {
          return new Date(d.day);
        }
      });
      const nearestDate = availableDays.find((d) => d && d >= new Date());
      setCurrentWeek(nearestDate || new Date());
    }
  };

  const weekDays = useMemo(() => {
    const days = [];
    const startOfWeek = new Date(currentWeek);
    startOfWeek.setDate(currentWeek.getDate() - currentWeek.getDay());
    startOfWeek.setHours(0, 0, 0, 0);

    for (let i = 0; i < 7; i++) {
      const date = new Date(startOfWeek);
      date.setDate(startOfWeek.getDate() + i);
      const dateString = date.toISOString().split('T')[0];

      days.push({
        date,
        dateString,
        isAvailable: availableDays.has(dateString),
      });
    }
    return days;
  }, [currentWeek, availableDays]);

  const selectedDaySlots = selectedDate
    ? availableDays.get(selectedDate) || []
    : [];

  return (
    <div className="flex flex-col gap-6">
      <div className="flex items-center justify-between">
        <h2 className="text-lg font-medium">Select available dates</h2>
        <div className="flex gap-2">
          <Button
            variant="ghost"
            size="icon"
            className="h-8 w-8 p-0"
            onClick={() => {
              const prev = new Date(currentWeek);
              prev.setDate(prev.getDate() - 7);
              setCurrentWeek(prev);
            }}
          >
            <ChevronLeftIcon className="h-4 w-4" />
          </Button>
          <Button
            variant="ghost"
            size="icon"
            className="h-8 w-8 p-0"
            onClick={() => {
              const next = new Date(currentWeek);
              next.setDate(next.getDate() + 7);
              setCurrentWeek(next);
            }}
          >
            <ChevronRightIcon className="h-4 w-4" />
          </Button>
        </div>
      </div>

      {/* Week view */}
      <div>
        <div className="mb-2 grid grid-cols-7 text-center text-sm text-muted-foreground">
          {['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'].map((day) => (
            <div key={day}>{day}</div>
          ))}
        </div>
        <div className="grid grid-cols-7 gap-2">
          {weekDays.map(({ date, dateString, isAvailable }) => (
            <div className="flex w-full justify-center">
              <Button
                key={dateString}
                variant={selectedDate === dateString ? 'default' : 'ghost'}
                disabled={!isAvailable}
                onClick={() => {
                  console.log('Selecting date:', dateString);
                  setSelectedDate(dateString);
                }}
                className={cn(
                  'aspect-square p-0',
                  isAvailable && 'hover:bg-light-2 hover:dark:bg-dark-2',
                  selectedDate === dateString &&
                    'bg-primary text-primary-foreground',
                )}
              >
                {date.getDate()}
              </Button>
            </div>
          ))}
        </div>
      </div>

      {/* Time slots */}
      {selectedDate && (
        <div className="mt-4">
          <h3 className="mb-4 text-base">
            {new Date(selectedDate).toLocaleDateString('en-US', {
              weekday: 'short',
              day: 'numeric',
              year: 'numeric',
            })}
          </h3>
          <div className="grid grid-cols-3 gap-3">
            {selectedDaySlots.map((slot) => (
              <Button
                key={slot.startTime}
                onClick={() => setSelectedSlot(slot)}
                className={
                  selectedSlot?.startTime !== slot.startTime
                    ? 'bg-light-3 text-textParagraph hover:bg-light-2 dark:bg-dark-3 dark:text-dark-textParagraph hover:dark:bg-dark-2'
                    : ''
                }
                disabled={!slot.canBook}
              >
                {new Date(slot.startTime).toLocaleTimeString('en-US', {
                  hour: 'numeric',
                  minute: '2-digit',
                })}
              </Button>
            ))}
          </div>
        </div>
      )}

      {/* Action buttons */}
      <div className="mt-6 flex justify-end gap-3">
        <Button variant="outline" onClick={onClose}>
          Cancel
        </Button>
        <Button
          disabled={!selectedDate || !selectedSlot}
          onClick={() => {
            if (!selectedDate || !selectedSlot) return;
            rescheduleOptionMutation.mutate({
              meetingId: meeting.id,
              data: {
                startTime: selectedSlot.startTime,
              },
            });
          }}
        >
          Send now
        </Button>
      </div>
    </div>
  );
};
