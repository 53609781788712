import { useQueryClient } from '@tanstack/react-query';
import { XIcon } from 'lucide-react';

import {
  getBrandsControllerGetSpaceGroupsOfBrandQueryKey,
  useSpaceGroupControllerDeleteSpaceGroup,
} from '@/api';
import { Button } from '@/components/ui/button';
import {
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';

export const DeleteSpaceGroupDialogTrgger = () => {
  return (
    <DialogTrigger
      asChild
      className="w-full justify-start p-2 font-medium text-destructive focus:text-destructive"
    >
      <Button variant="none" size="none" className="">
        <span>Delete Space group</span>
      </Button>
    </DialogTrigger>
  );
};

export const DeleteSpaceGroupDialogContent = ({
  closeDialog,
  spaceGroupId,
  brandId,
}: {
  closeDialog: () => void;
  spaceGroupId: string;
  brandId: string;
}) => {
  const queryClient = useQueryClient();
  const deleteSpaceGroupMutation = useSpaceGroupControllerDeleteSpaceGroup({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: getBrandsControllerGetSpaceGroupsOfBrandQueryKey(brandId),
        });
        closeDialog();
      },
    },
  });

  const handleDelete = async () => {
    deleteSpaceGroupMutation.mutate({
      spaceGroupId,
    });
  };
  return (
    <DialogContent className="flex w-[90%] max-w-3xl flex-col gap-9 rounded-3xl md:w-3/4 md:p-12 lg:w-1/2">
      <DialogHeader className="flex-row items-center justify-between space-y-0">
        <DialogTitle className="text-2xl font-semibold">
          Delete space group
        </DialogTitle>
        <Button
          onClick={closeDialog}
          disabled={deleteSpaceGroupMutation.isPending}
          variant="icon"
          size="icon"
          className="m-0 rounded-sm p-0 ring-offset-background transition-opacity focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
        >
          <XIcon className="h-4 w-4" />
          <span className="sr-only">Close</span>
        </Button>
      </DialogHeader>
      <DialogDescription className="font-medium text-[#9E9E9E] dark:text-[#AEAEAE]">
        If you proceed, you will permanently lose ALL the data associated with
        this space group.
      </DialogDescription>
      <DialogFooter className="flex flex-row justify-end gap-2">
        <Button
          variant="outline"
          onClick={closeDialog}
          type="button"
          disabled={deleteSpaceGroupMutation.isPending}
        >
          Cancel
        </Button>
        <Button
          variant="destructive"
          onClick={handleDelete}
          type="button"
          disabled={deleteSpaceGroupMutation.isPending}
        >
          {deleteSpaceGroupMutation.isPending ? 'Deleting...' : 'Delete now'}
        </Button>
      </DialogFooter>
    </DialogContent>
  );
};
