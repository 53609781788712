type Props = {
  title: string;
  value: string;
  growth: string;
  lastPeriod: string;
};

const OverviewCard = ({ growth, lastPeriod, title, value }: Props) => {
  return (
    <div className="flex w-full flex-col gap-6 rounded-2xl bg-light-2 px-4 py-6 dark:bg-dark-2">
      <div className="flex w-full justify-between text-lg font-bold text-textParagraph dark:text-dark-textParagraph">
        {title}
        {growth && growth !== 'N/A' && (
          <div
            className={`flex h-fit rounded-sm px-2 py-1 text-xxs font-medium leading-3 ${growth.includes('+') ? 'bg-[#A6F7CC] text-[#055E39]' : 'bg-red-300 text-red-900'}`}
          >
            {growth || 0}
          </div>
        )}
      </div>
      <div className="flex items-end gap-2.5">
        <div className="text-xl font-semibold text-black dark:text-white">
          {value || 0}
        </div>
        <div className="text-sm font-medium text-textParagraph dark:text-dark-textParagraph">
          {lastPeriod || 0} last period
        </div>
      </div>
    </div>
  );
};

export default OverviewCard;
