import { formatDistanceToNow } from 'date-fns';
import {
  CalendarCheck,
  CalendarClock,
  CalendarX2,
  UserIcon,
} from 'lucide-react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  NotificationsControllerGetAllUserNotifications200,
  NotificationsControllerGetAllUserNotifications200NotificationsItem,
} from '@/api';
import { CloseIcon } from '@/assets/icon/close';
import eventBackground from '@/assets/image/eventBackground.png';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Sheet, SheetContent } from '@/components/ui/sheet';

type Props = {
  isOpen: boolean;
  notificationsResponse?: NotificationsControllerGetAllUserNotifications200;
  userId: string;
  onClose: () => void;
};

const NotificationDrawer = ({
  isOpen,
  notificationsResponse,
  onClose,
}: Props) => {
  const notifications = notificationsResponse?.notifications ?? [];

  const unReadNotificationsCount = notifications.filter(
    (notification) => !notification.read,
  ).length;

  if (!notificationsResponse) {
    return null;
  }

  return (
    <Sheet open={isOpen} onOpenChange={onClose}>
      <SheetContent className="flex h-full w-[80vw] flex-col gap-8.75 overflow-y-hidden border-0 p-0 px-6 py-6 text-base text-black focus:outline-none dark:bg-dark-1 dark:text-white sm:min-w-[585px]">
        <div className="flex w-full justify-between">
          <h2 className="text-lg font-semibold">
            Notifications{' '}
            {unReadNotificationsCount > 0 && `(${unReadNotificationsCount})`}
          </h2>
          <CloseIcon
            className="h-6 w-6 cursor-pointer fill-black dark:fill-white"
            onClick={onClose}
          />
        </div>
        <div className="flex h-full w-full flex-col gap-6 overflow-y-auto">
          {notifications.length === 0 ? (
            <div className="flex h-full w-full items-center justify-center">
              <p className="text-base font-normal text-textParagraph dark:text-dark-textParagraph">
                No notifications yet
              </p>
            </div>
          ) : (
            notifications.map((notification) => (
              <NotificationItem
                key={notification.id}
                notification={notification}
                notificationsResponse={notificationsResponse}
                handleClose={onClose}
              />
            ))
          )}
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default NotificationDrawer;

const NotificationItem = ({
  notification,
  notificationsResponse,
  handleClose,
}: {
  notification: NotificationsControllerGetAllUserNotifications200NotificationsItem;
  notificationsResponse: NotificationsControllerGetAllUserNotifications200;
  handleClose: () => void;
}) => {
  const navigate = useNavigate();
  const { content, image, link } = getNotificationData(
    notification,
    notificationsResponse,
  );

  if (!content) {
    return null;
  }

  return (
    <div
      className={`flex w-full gap-4 ${link ? 'cursor-pointer' : ''}`}
      onClick={() => {
        if (link) {
          navigate(link);
          handleClose();
        }
      }}
    >
      {image || (
        <div className="h-10 w-10 flex-shrink-0 rounded-lg bg-light-2 dark:bg-dark-2" />
      )}
      <div className="flex w-full flex-col gap-0.5">
        <h3 className="text-base font-medium text-textParagraph dark:text-dark-textParagraph">
          {content}
        </h3>
        <div className="text-xs font-normal text-textParagraph dark:text-dark-textParagraph">
          {formatDistanceToNow(notification.createdAt, {
            addSuffix: true,
          })}
        </div>
      </div>
      {!notification.read && (
        <div className={`h-2.5 w-2.5 flex-shrink-0 rounded-full bg-error`} />
      )}
    </div>
  );
};

const getNotificationData = (
  notification: NotificationsControllerGetAllUserNotifications200NotificationsItem,
  notificationsResponse: NotificationsControllerGetAllUserNotifications200,
): {
  content: React.ReactNode;
  image: React.ReactNode;
  link?: string | null;
} => {
  switch (notification.type) {
    case 'MEETING_APPROVED': {
      const { brandId } = useParams();

      return {
        content: <div>Your meeting was approved</div>,
        image: (
          <CalendarCheck className="stroke-textParagraph dark:stroke-white" />
        ),
        link: `/brands/${brandId}/chats?type=Requests`,
      };
    }

    case 'MEETING_DECLINED': {
      const { brandId } = useParams();

      return {
        content: <div>Your meeting was declined</div>,
        image: (
          <CalendarX2 className="stroke-textParagraph dark:stroke-white" />
        ),
        link: `/brands/${brandId}/chats?type=Requests`,
      };
    }

    case 'MEETING_RESCHEDULED': {
      const { brandId } = useParams();

      return {
        content: <div>Your meeting was rescheduled</div>,
        image: (
          <CalendarClock className="stroke-textParagraph dark:stroke-white" />
        ),
        link: `/brands/${brandId}/chats?type=Requests`,
      };
    }

    case 'EVENT_STARTING_SOON': {
      const event = notificationsResponse.relatedEvents.find(
        (event) => event.id === notification.metadata.eventId,
      );

      if (!event) {
        return {
          content: null,
          image: null,
        };
      }

      const now = new Date();

      let text = '';

      // if event hasn't started yet
      if (new Date(event.startDayTime) > now) {
        text = `Event [${event.title}] starts in ${formatDistanceToNow(
          event.startDayTime,
        )}`;
      }

      // if event has already started
      if (new Date(event.startDayTime) < now) {
        text = `Event [${event.title}] started ${formatDistanceToNow(
          event.startDayTime,
          { addSuffix: true },
        )}`;
      }

      const link = `/brands/${event.eventSpace.spaceGroup.brandId}/space-groups/${event.eventSpace.spaceGroup.id}/events-spaces/${event.eventSpace.id}?eventId=${event.id}`;

      return {
        content: text,
        image: getAvatar(
          event.image,
          // <div className="h-10 w-10 flex-shrink-0 rounded-lg bg-light-2 dark:bg-dark-2" />,
          <img
            src={eventBackground}
            alt={event.title}
            className="h-10 w-10 object-cover"
          />,
        ),
        link,
      };
    }

    case 'LIKED_POST': {
      const user = notificationsResponse.relatedUsers.find(
        (user) => user.id === notification.metadata.userId,
      );

      if (!user) {
        return {
          content: null,
          image: null,
        };
      }

      const post = notificationsResponse.relatedPosts.find(
        (post) => post.id === notification.metadata.postId,
      );

      if (!post) {
        return {
          content: null,
          image: null,
        };
      }

      return {
        content: (
          <>
            <span className="text-black dark:text-white">{user.name}</span>{' '}
            liked your post
          </>
        ),
        image: getAvatar(
          user.avatarUrl,
          <UserIcon className="stroke-textParagraph p-1 dark:stroke-white" />,
        ),
      };
    }

    case 'MENTIONED_IN_POST': {
      const user = notificationsResponse.relatedUsers.find(
        (user) => user.id === notification.metadata.userId,
      );

      if (!user) {
        return {
          content: null,
          image: null,
        };
      }

      const post = notificationsResponse.relatedPosts.find(
        (post) => post.id === notification.metadata.postId,
      );

      if (!post) {
        return {
          content: null,
          image: null,
        };
      }

      return {
        content: (
          <>
            <span className="text-black dark:text-white">{user.name}</span>{' '}
            mentioned you in a post
          </>
        ),
        image: getAvatar(
          user.avatarUrl,
          <UserIcon className="stroke-textParagraph p-1 dark:stroke-white" />,
        ),
      };
    }

    case 'MENTIONED_IN_GROUP_CHAT': {
      const user = notificationsResponse.relatedUsers.find(
        (user) => user.id === notification.metadata.userId,
      );

      if (!user) {
        return {
          content: null,
          image: null,
        };
      }

      const groupChat = notificationsResponse.relatedGroupChats.find(
        (groupChat) => groupChat.id === notification.metadata.chatSpaceId,
      );

      if (!groupChat) {
        return {
          content: null,
          image: null,
        };
      }

      return {
        content: (
          <>
            <span className="text-black dark:text-white">{user.name}</span>{' '}
            mentioned you in a group chat
          </>
        ),
        image: getAvatar(
          user.avatarUrl,
          <UserIcon className="stroke-textParagraph p-1 dark:stroke-white" />,
        ),
      };
    }

    case 'MENTIONED_IN_DISCUSSION': {
      const user = notificationsResponse.relatedUsers.find(
        (user) => user.id === notification.metadata.userId,
      );

      if (!user) {
        return {
          content: null,
          image: null,
        };
      }

      const discussion = notificationsResponse.relatedDiscussions.find(
        (discussion) => discussion.id === notification.metadata.discussionId,
      );

      if (!discussion) {
        return {
          content: null,
          image: null,
        };
      }

      return {
        content: (
          <>
            <span className="text-black dark:text-white">{user.name}</span>{' '}
            mentioned you in a discussion
          </>
        ),
        image: getAvatar(
          user.avatarUrl,
          <UserIcon className="stroke-textParagraph p-1 dark:stroke-white" />,
        ),
      };
    }

    case 'REACTION_IN_DISCUSSION_MESSAGE': {
      const user = notificationsResponse.relatedUsers.find(
        (user) => user.id === notification.metadata.userId,
      );

      if (!user) {
        return {
          content: null,
          image: null,
        };
      }

      const discussion = notificationsResponse.relatedDiscussions.find(
        (discussion) => discussion.id === notification.metadata.discussionId,
      );

      if (!discussion) {
        return {
          content: null,
          image: null,
        };
      }

      return {
        content: (
          <>
            <span className="text-black dark:text-white">{user.name}</span>{' '}
            reacted to your message in discussion
          </>
        ),
        image: getAvatar(
          user.avatarUrl,
          <UserIcon className="stroke-textParagraph p-1 dark:stroke-white" />,
        ),
      };
    }

    case 'REACTION_IN_GROUP_CHAT_MESSAGE': {
      const user = notificationsResponse.relatedUsers.find(
        (user) => user.id === notification.metadata.userId,
      );

      if (!user) {
        return {
          content: null,
          image: null,
        };
      }

      const groupChat = notificationsResponse.relatedGroupChats.find(
        (groupChat) => groupChat.id === notification.metadata.chatSpaceId,
      );

      if (!groupChat) {
        return {
          content: null,
          image: null,
        };
      }

      return {
        content: (
          <>
            <span className="text-black dark:text-white">{user.name}</span>{' '}
            reacted to your message in group chat
          </>
        ),
        image: getAvatar(
          user.avatarUrl,
          <UserIcon className="stroke-textParagraph p-1 dark:stroke-white" />,
        ),
      };
    }

    case 'MENTIONED_IN_DISCUSSION_THREAD': {
      const user = notificationsResponse.relatedUsers.find(
        (user) => user.id === notification.metadata.userId,
      );

      if (!user) {
        return {
          content: null,
          image: null,
        };
      }

      const discussion = notificationsResponse.relatedDiscussions.find(
        (discussion) => discussion.id === notification.metadata.discussionId,
      );

      if (!discussion) {
        return {
          content: null,
          image: null,
        };
      }

      return {
        content: (
          <>
            <span className="text-black dark:text-white">{user.name}</span>{' '}
            mentioned you in a discussion thread
          </>
        ),
        image: getAvatar(
          user.avatarUrl,
          <UserIcon className="stroke-textParagraph p-1 dark:stroke-white" />,
        ),
      };
    }

    case 'MENTIONED_IN_GROUP_CHAT_THREAD': {
      const user = notificationsResponse.relatedUsers.find(
        (user) => user.id === notification.metadata.userId,
      );

      if (!user) {
        return {
          content: null,
          image: null,
        };
      }

      const groupChat = notificationsResponse.relatedGroupChats.find(
        (groupChat) => groupChat.id === notification.metadata.chatSpaceId,
      );

      if (!groupChat) {
        return {
          content: null,
          image: null,
        };
      }

      return {
        content: (
          <>
            <span className="text-black dark:text-white">{user.name}</span>{' '}
            mentioned you in a group chat thread
          </>
        ),
        image: getAvatar(
          user.avatarUrl,
          <UserIcon className="stroke-textParagraph p-1 dark:stroke-white" />,
        ),
      };
    }

    case 'REPLIED_TO_DISCUSSION_THREAD': {
      const user = notificationsResponse.relatedUsers.find(
        (user) => user.id === notification.metadata.userId,
      );

      if (!user) {
        return {
          content: null,
          image: null,
        };
      }

      const discussion = notificationsResponse.relatedDiscussions.find(
        (discussion) => discussion.id === notification.metadata.discussionId,
      );

      if (!discussion) {
        return {
          content: null,
          image: null,
        };
      }

      return {
        content: (
          <>
            <span className="text-black dark:text-white">{user.name}</span>{' '}
            replied to your thread in discussion
          </>
        ),
        image: getAvatar(
          user.avatarUrl,
          <UserIcon className="stroke-textParagraph p-1 dark:stroke-white" />,
        ),
      };
    }

    case 'REPLIED_TO_GROUP_CHAT_THREAD': {
      const user = notificationsResponse.relatedUsers.find(
        (user) => user.id === notification.metadata.userId,
      );

      if (!user) {
        return {
          content: null,
          image: null,
        };
      }

      const groupChat = notificationsResponse.relatedGroupChats.find(
        (groupChat) => groupChat.id === notification.metadata.chatSpaceId,
      );

      if (!groupChat) {
        return {
          content: null,
          image: null,
        };
      }

      return {
        content: (
          <>
            <span className="text-black dark:text-white">{user.name}</span>{' '}
            replied to your thread in group chat
          </>
        ),
        image: getAvatar(
          user.avatarUrl,
          <UserIcon className="stroke-textParagraph p-1 dark:stroke-white" />,
        ),
      };
    }

    default: {
      return {
        content: null,
        image: null,
      };
    }
  }
};

const getAvatar = (img: string, fallback: React.ReactNode) => {
  return (
    <Avatar className="h-10 w-10 flex-shrink-0 rounded-lg">
      <AvatarImage src={img} />
      <AvatarFallback>{fallback}</AvatarFallback>
    </Avatar>
  );
};
