import { useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

export interface PostViewerProps {
  value: string;
  findUser: (id: string) => { username: string } | null;
  onMentionClick: (uid: string) => void;
}

// Custom component for mentions
const Mention: React.FC<{
  uid: string;
  username: string;
  onMentionClick: (uid: string) => void;
}> = ({ uid, username, onMentionClick }) => (
  <span
    className="inline cursor-pointer text-blue-500 no-underline"
    onClick={(e) => {
      e.stopPropagation();
      e.preventDefault();
      onMentionClick(uid);
    }}
    title={username}
  >
    <span className="inline-block">@{username}</span>{' '}
  </span>
);

// Function to split content into text and mentions
function splitContent(
  value: string,
  findUser: (id: string) => { username: string } | null,
  onMentionClick: (uid: string) => void,
): React.ReactNode[] {
  const parts = value.split(/(<@uid:.*?>)/);
  return parts.map((part, index) => {
    const match = part.match(/<@uid:(.*?)>/);
    if (match) {
      const uid = match[1];
      const user = findUser(uid);
      const username = user ? user.username : 'Unknown User';
      return (
        <Mention
          key={`mention-${uid}-${index}`}
          uid={uid}
          username={username}
          onMentionClick={onMentionClick}
        />
      );
    }
    return part;
  });
}

export default function PostViewer(props: PostViewerProps) {
  const { value, findUser, onMentionClick } = props;

  const modifiedText = useMemo(() => {
    const lines = value.split('\n');

    return lines
      .map((line, index) => {
        // Check if the line is part of a list
        const isListItem = /^\s*[*\-+]\s+|^\s*\d+\.\s+/.test(line);
        const isNextLineListItem =
          index < lines.length - 1 &&
          /^\s*[*\-+]\s+|^\s*\d+\.\s+/.test(lines[index + 1]);

        if (isListItem || isNextLineListItem) return line;

        if (line.trim() === '\\') return line.replace('\\', '&nbsp;\n');

        return line + '&nbsp;\n';
      })
      .join('\n');
  }, [value]);

  const content = splitContent(modifiedText, findUser, onMentionClick);

  return (
    <div className="whitespace-pre-wrap break-words text-base">
      {content.map((part, index) => {
        if (typeof part === 'string') {
          return (
            <ReactMarkdown
              key={index}
              remarkPlugins={[remarkGfm]}
              components={{
                p: ({ children }) => <span>{children}</span>,
                a: ({ href, children, ...otherProps }) => (
                  <a
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 underline"
                    href={href}
                    {...otherProps}
                  >
                    {children}
                  </a>
                ),
              }}
            >
              {part}
            </ReactMarkdown>
          );
        }
        return part;
      })}
    </div>
  );
}
