export const UploadIcon = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="arrow-narrow-up-move">
      <path
        id="Icon"
        d="M5.33464 5.33268L8.0013 2.66602M8.0013 2.66602L10.668 5.33268M8.0013 2.66602V10.666M2.66797 13.3327H13.3346"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
