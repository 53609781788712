import { CheckIcon } from '@/assets/icon/check-alt';

import Divider from './Divider';

type Props = {
  current: string;
  steps: {
    value: string;
    index: number;
  }[];
};

const Stepper = ({ current, steps }: Props) => {
  return (
    <div className="flex w-full items-center gap-2.5 px-10">
      {steps.map((step, index) => (
        <div
          className={`flex ${index !== steps.length - 1 ? 'w-full' : 'w-fit'} items-center gap-2.5`}
        >
          {step.index <
          (steps?.find((s) => s.value === current)?.index || 0) ? (
            <div className="flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full bg-green-base">
              <CheckIcon className="stroke-black" />
            </div>
          ) : step.value === current ? (
            <div className="flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full bg-light-4 dark:bg-dark-4">
              {index + 1}
            </div>
          ) : (
            <div className="flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full bg-light-2 dark:bg-dark-2">
              {index + 1}
            </div>
          )}
          {index !== steps.length - 1 && <Divider />}
        </div>
      ))}
    </div>
  );
};

export default Stepper;
