import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import {
  NotificationsControllerGetAllUserNotifications200,
  NotificationsControllerGetAllUserNotifications200NotificationsItem,
  NotificationsControllerGetAllUserNotifications200RelatedDiscussionsItem,
  NotificationsControllerGetAllUserNotifications200RelatedEventsItem,
  NotificationsControllerGetAllUserNotifications200RelatedGroupChatsItem,
  NotificationsControllerGetAllUserNotifications200RelatedPostsItem,
  NotificationsControllerGetAllUserNotifications200RelatedUsersItem,
  getNotificationsControllerGetAllUserNotificationsQueryKey,
  useNotificationsControllerGetAllUserNotifications,
  useNotificationsControllerMarkAsReadAll,
} from '@/api';
import { MenuSidebarIcon } from '@/assets/icon/menu-sidebar';
import { NotificationBellIcon } from '@/assets/icon/notification-bell';
import { UserIcon } from '@/assets/icon/user';
import { useConfigHeader } from '@/components/config-header-provider';
import { Sidebar } from '@/components/layout/sidebar';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet';
import { useAuth } from '@/hooks/useContext';
import { valueOrUndefined } from '@/lib/utils';
import { notificationsSocket } from '@/utils/notificationsSocket';

import NotificationDrawer from '../ui/notifications/NotificationDrawer';

const MobileSidebar = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Sheet open={isOpen} onOpenChange={setIsOpen}>
      <SheetTrigger className="md:hidden">
        <MenuSidebarIcon className="h-10 w-10 stroke-black dark:stroke-white" />
      </SheetTrigger>
      <SheetContent side="left" className="w-fit p-0 sm:max-w-fit">
        <Sidebar />
      </SheetContent>
    </Sheet>
  );
};

export const Header = () => {
  const configHeader = useConfigHeader();
  const { user } = useAuth();

  return (
    <div
      className={`flex h-fit items-center justify-between px-6 pb-2 pt-4 ${configHeader.hidden ? 'md:hidden' : ''}`}
      hidden
    >
      <span className="hidden text-lg font-semibold md:block">
        {configHeader.label}
      </span>
      <MobileSidebar />

      <div className="flex items-center gap-6">
        {user?.id && configHeader.showNotifications && (
          <NotificationButton userId={user.id} />
        )}
        <div className="hidden md:block">{configHeader.action}</div>
        <div className="md:hidden">
          <Avatar className="h-10 w-10">
            <AvatarImage src={valueOrUndefined(user?.avatarUrl)} />
            <AvatarFallback>
              <UserIcon className="stroke-textParagraph p-1 dark:stroke-white" />
            </AvatarFallback>
          </Avatar>
        </div>
      </div>
    </div>
  );
};

const NotificationButton = ({ userId }: { userId: string }) => {
  const queryClient = useQueryClient();
  const notificationsQuery = useNotificationsControllerGetAllUserNotifications({
    query: {
      refetchInterval: 30_000,
      refetchIntervalInBackground: true,
    },
  });
  const markAsReadAllMutation = useNotificationsControllerMarkAsReadAll({
    mutation: {
      onSuccess: () => {
        queryClient.setQueryData<NotificationsControllerGetAllUserNotifications200>(
          getNotificationsControllerGetAllUserNotificationsQueryKey(),
          (prev) => {
            if (!prev) return undefined;
            return {
              ...prev,
              notifications: prev.notifications?.map((notification) => ({
                ...notification,
                read: true,
              })),
            };
          },
        );
      },
    },
  });

  const [open, setOpen] = useState(false);

  const notificationsResponse = notificationsQuery.data;

  const notifications = notificationsResponse?.notifications ?? [];

  const hasUnreadNotifications =
    notifications.filter((notification) => !notification.read).length > 0;

  useEffect(() => {
    notificationsSocket.on(
      'newNotification',
      (notification: {
        notification: NotificationsControllerGetAllUserNotifications200NotificationsItem;
        relatedDiscussions?: NotificationsControllerGetAllUserNotifications200RelatedDiscussionsItem[];
        relatedEvents?: NotificationsControllerGetAllUserNotifications200RelatedEventsItem[];
        relatedGroupChats?: NotificationsControllerGetAllUserNotifications200RelatedGroupChatsItem[];
        relatedPosts?: NotificationsControllerGetAllUserNotifications200RelatedPostsItem[];
        relatedUsers?: NotificationsControllerGetAllUserNotifications200RelatedUsersItem[];
      }) => {
        queryClient.setQueryData<NotificationsControllerGetAllUserNotifications200>(
          getNotificationsControllerGetAllUserNotificationsQueryKey(),
          (prev) => {
            if (!prev)
              return {
                notifications: [notification.notification],
                relatedDiscussions: notification.relatedDiscussions ?? [],
                relatedEvents: notification.relatedEvents ?? [],
                relatedGroupChats: notification.relatedGroupChats ?? [],
                relatedPosts: notification.relatedPosts ?? [],
                relatedUsers: notification.relatedUsers ?? [],
              };
            return {
              ...prev,
              notifications: [
                notification.notification,
                ...(prev.notifications ?? []),
              ],
              relatedDiscussions: [
                ...(prev.relatedDiscussions ?? []),
                ...(notification.relatedDiscussions ?? []),
              ],
              relatedEvents: [
                ...(prev.relatedEvents ?? []),
                ...(notification.relatedEvents ?? []),
              ],
              relatedGroupChats: [
                ...(prev.relatedGroupChats ?? []),
                ...(notification.relatedGroupChats ?? []),
              ],
              relatedPosts: [
                ...(prev.relatedPosts ?? []),
                ...(notification.relatedPosts ?? []),
              ],
              relatedUsers: [
                ...(prev.relatedUsers ?? []),
                ...(notification.relatedUsers ?? []),
              ],
            };
          },
        );
      },
    );

    return () => {
      notificationsSocket.off('newNotification');
    };
  }, []);

  return (
    <>
      <Button
        variant="icon"
        size="icon"
        onClick={() => {
          setOpen(true);
          markAsReadAllMutation.mutate();
        }}
        className="relative"
      >
        <NotificationBellIcon className="h-4 w-4 stroke-black dark:stroke-white" />
        {hasUnreadNotifications && (
          <span className="absolute -right-1 -top-1 h-2 w-2 rounded-full bg-red-500" />
        )}
      </Button>

      <NotificationDrawer
        isOpen={open}
        onClose={() => setOpen(false)}
        userId={userId}
        notificationsResponse={notificationsResponse}
      />
    </>
  );
};
