import { useQuery } from '@tanstack/react-query';
import { Navigate, useParams } from 'react-router-dom';

import {
  ChatsSpace,
  SpaceGroupWithSpaces,
  useBrandsControllerGetSpaceGroupsOfBrand,
  useNotificationsControllerResetGroupChatMessageCounter,
} from '@/api';
import { Loading } from '@/components/Loading';
import { CometChatComponent } from '@/components/comet-chat-component';
import { useUpdateConfigHeader } from '@/components/config-header-provider';

export const ChatPage = () => {
  const { brandId, spaceGroupId, chatsSpaceId } = useParams();
  const spaceGroupsQuery = useBrandsControllerGetSpaceGroupsOfBrand(
    brandId as string,
  );

  if (spaceGroupsQuery.isLoading) {
    return <Loading />;
  }

  if (spaceGroupsQuery.isError) {
    throw new Error(
      `Error fetching space groups for brand ${brandId}, ${spaceGroupsQuery.error}`,
    );
  }

  const spaceGroups = spaceGroupsQuery.data ?? [];

  const spaceGroup = spaceGroups.find(
    (spaceGroup) => spaceGroup.id === spaceGroupId,
  );

  if (!spaceGroup) {
    return <Navigate to="/404" />;
  }

  const chatsSpace = spaceGroup.chatsSpaces.find(
    (space) => space.id === chatsSpaceId,
  );

  if (!chatsSpace) {
    return <Navigate to="/404" />;
  }

  return <ChatPageInner chatsSpace={chatsSpace} spaceGroup={spaceGroup} />;
};

export const ChatPageInner = ({
  chatsSpace,
  spaceGroup,
}: {
  chatsSpace: ChatsSpace;
  spaceGroup: SpaceGroupWithSpaces;
}) => {
  useUpdateConfigHeader(
    {
      showNotifications: true,
      label: chatsSpace.name,
      action: null,
    },
    [chatsSpace.name],
  );

  const GUID = chatsSpace.cometChatGroupId;

  const resetGroupChatMessageCounterMutation =
    useNotificationsControllerResetGroupChatMessageCounter();

  useQuery({
    queryKey: ['group-chat-message-counter-reset', chatsSpace.id],
    queryFn: () => {
      resetGroupChatMessageCounterMutation.mutate({
        chatsSpaceId: chatsSpace.id,
      });
      return true;
    },
  });

  if (!GUID) {
    throw new Error(`GUID is required for chats space ${chatsSpace.id}`);
  }

  return (
    <CometChatComponent
      GUID={GUID}
      spaceGroupId={spaceGroup.id}
      onClose={() => {}}
    />
  );
};
