/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * Teachly API V2
 * Teachly API documentation
 * OpenAPI spec version: 1.0
 */
import { useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query';
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryClient,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';

import { customFetch } from '../../../mutator/custom-fetch';
import type {
  CreatePostDto,
  CreatePostsSpaceDto,
  Post,
  PostSpace,
  PostWithUserAndRoleDto,
  RemoveFilesDto,
  SuccessMessageResponseDto,
  TogglePinPostDto,
  UpdatePostDto,
  UpdatePostsSpaceDto,
  UploadFilesDto,
} from '../../model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * Creates a new post space within a specific space group.
 * @summary Create a new post space
 */
export const postsSpacesControllerCreatePostSpace = (
  createPostsSpaceDto: CreatePostsSpaceDto,
  options?: SecondParameter<typeof customFetch>,
) => {
  return customFetch<PostSpace>(
    {
      url: `/posts-spaces`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createPostsSpaceDto,
    },
    options,
  );
};

export const getPostsSpacesControllerCreatePostSpaceMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postsSpacesControllerCreatePostSpace>>,
    TError,
    { data: CreatePostsSpaceDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postsSpacesControllerCreatePostSpace>>,
  TError,
  { data: CreatePostsSpaceDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postsSpacesControllerCreatePostSpace>>,
    { data: CreatePostsSpaceDto }
  > = (props) => {
    const { data } = props ?? {};

    return postsSpacesControllerCreatePostSpace(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostsSpacesControllerCreatePostSpaceMutationResult = NonNullable<
  Awaited<ReturnType<typeof postsSpacesControllerCreatePostSpace>>
>;
export type PostsSpacesControllerCreatePostSpaceMutationBody =
  CreatePostsSpaceDto;
export type PostsSpacesControllerCreatePostSpaceMutationError = void;

/**
 * @summary Create a new post space
 */
export const usePostsSpacesControllerCreatePostSpace = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postsSpacesControllerCreatePostSpace>>,
    TError,
    { data: CreatePostsSpaceDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postsSpacesControllerCreatePostSpace>>,
  TError,
  { data: CreatePostsSpaceDto },
  TContext
> => {
  const mutationOptions =
    getPostsSpacesControllerCreatePostSpaceMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Creates a new post within a specific post space.
 * @summary Create a new post
 */
export const postsSpacesControllerCreatePost = (
  postsSpaceId: string,
  createPostDto: CreatePostDto,
  options?: SecondParameter<typeof customFetch>,
) => {
  return customFetch<Post>(
    {
      url: `/posts-spaces/${encodeURIComponent(String(postsSpaceId))}/posts`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createPostDto,
    },
    options,
  );
};

export const getPostsSpacesControllerCreatePostMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postsSpacesControllerCreatePost>>,
    TError,
    { postsSpaceId: string; data: CreatePostDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postsSpacesControllerCreatePost>>,
  TError,
  { postsSpaceId: string; data: CreatePostDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postsSpacesControllerCreatePost>>,
    { postsSpaceId: string; data: CreatePostDto }
  > = (props) => {
    const { postsSpaceId, data } = props ?? {};

    return postsSpacesControllerCreatePost(postsSpaceId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostsSpacesControllerCreatePostMutationResult = NonNullable<
  Awaited<ReturnType<typeof postsSpacesControllerCreatePost>>
>;
export type PostsSpacesControllerCreatePostMutationBody = CreatePostDto;
export type PostsSpacesControllerCreatePostMutationError = void;

/**
 * @summary Create a new post
 */
export const usePostsSpacesControllerCreatePost = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postsSpacesControllerCreatePost>>,
    TError,
    { postsSpaceId: string; data: CreatePostDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postsSpacesControllerCreatePost>>,
  TError,
  { postsSpaceId: string; data: CreatePostDto },
  TContext
> => {
  const mutationOptions =
    getPostsSpacesControllerCreatePostMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Retrieves all posts associated with a specific post space.
 * @summary Get all posts for a post space
 */
export const postsSpacesControllerGetPostsInSpace = (
  postsSpaceId: string,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<PostWithUserAndRoleDto[]>(
    {
      url: `/posts-spaces/${encodeURIComponent(String(postsSpaceId))}/posts`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getPostsSpacesControllerGetPostsInSpaceQueryKey = (
  postsSpaceId: string,
) => {
  return [`/posts-spaces/${postsSpaceId}/posts`] as const;
};

export const getPostsSpacesControllerGetPostsInSpaceQueryOptions = <
  TData = Awaited<ReturnType<typeof postsSpacesControllerGetPostsInSpace>>,
  TError = void,
>(
  postsSpaceId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof postsSpacesControllerGetPostsInSpace>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getPostsSpacesControllerGetPostsInSpaceQueryKey(postsSpaceId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof postsSpacesControllerGetPostsInSpace>>
  > = ({ signal }) =>
    postsSpacesControllerGetPostsInSpace(postsSpaceId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!postsSpaceId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof postsSpacesControllerGetPostsInSpace>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PostsSpacesControllerGetPostsInSpaceQueryResult = NonNullable<
  Awaited<ReturnType<typeof postsSpacesControllerGetPostsInSpace>>
>;
export type PostsSpacesControllerGetPostsInSpaceQueryError = void;

export function usePostsSpacesControllerGetPostsInSpace<
  TData = Awaited<ReturnType<typeof postsSpacesControllerGetPostsInSpace>>,
  TError = void,
>(
  postsSpaceId: string,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof postsSpacesControllerGetPostsInSpace>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof postsSpacesControllerGetPostsInSpace>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function usePostsSpacesControllerGetPostsInSpace<
  TData = Awaited<ReturnType<typeof postsSpacesControllerGetPostsInSpace>>,
  TError = void,
>(
  postsSpaceId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof postsSpacesControllerGetPostsInSpace>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof postsSpacesControllerGetPostsInSpace>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function usePostsSpacesControllerGetPostsInSpace<
  TData = Awaited<ReturnType<typeof postsSpacesControllerGetPostsInSpace>>,
  TError = void,
>(
  postsSpaceId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof postsSpacesControllerGetPostsInSpace>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get all posts for a post space
 */

export function usePostsSpacesControllerGetPostsInSpace<
  TData = Awaited<ReturnType<typeof postsSpacesControllerGetPostsInSpace>>,
  TError = void,
>(
  postsSpaceId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof postsSpacesControllerGetPostsInSpace>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getPostsSpacesControllerGetPostsInSpaceQueryOptions(
    postsSpaceId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get all posts for a post space
 */
export const prefetchPostsSpacesControllerGetPostsInSpace = async <
  TData = Awaited<ReturnType<typeof postsSpacesControllerGetPostsInSpace>>,
  TError = void,
>(
  queryClient: QueryClient,
  postsSpaceId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof postsSpacesControllerGetPostsInSpace>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getPostsSpacesControllerGetPostsInSpaceQueryOptions(
    postsSpaceId,
    options,
  );

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getPostsSpacesControllerGetPostsInSpaceSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof postsSpacesControllerGetPostsInSpace>>,
  TError = void,
>(
  postsSpaceId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof postsSpacesControllerGetPostsInSpace>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getPostsSpacesControllerGetPostsInSpaceQueryKey(postsSpaceId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof postsSpacesControllerGetPostsInSpace>>
  > = ({ signal }) =>
    postsSpacesControllerGetPostsInSpace(postsSpaceId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!postsSpaceId,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof postsSpacesControllerGetPostsInSpace>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PostsSpacesControllerGetPostsInSpaceSuspenseQueryResult =
  NonNullable<Awaited<ReturnType<typeof postsSpacesControllerGetPostsInSpace>>>;
export type PostsSpacesControllerGetPostsInSpaceSuspenseQueryError = void;

export function usePostsSpacesControllerGetPostsInSpaceSuspense<
  TData = Awaited<ReturnType<typeof postsSpacesControllerGetPostsInSpace>>,
  TError = void,
>(
  postsSpaceId: string,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof postsSpacesControllerGetPostsInSpace>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function usePostsSpacesControllerGetPostsInSpaceSuspense<
  TData = Awaited<ReturnType<typeof postsSpacesControllerGetPostsInSpace>>,
  TError = void,
>(
  postsSpaceId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof postsSpacesControllerGetPostsInSpace>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function usePostsSpacesControllerGetPostsInSpaceSuspense<
  TData = Awaited<ReturnType<typeof postsSpacesControllerGetPostsInSpace>>,
  TError = void,
>(
  postsSpaceId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof postsSpacesControllerGetPostsInSpace>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get all posts for a post space
 */

export function usePostsSpacesControllerGetPostsInSpaceSuspense<
  TData = Awaited<ReturnType<typeof postsSpacesControllerGetPostsInSpace>>,
  TError = void,
>(
  postsSpaceId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof postsSpacesControllerGetPostsInSpace>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getPostsSpacesControllerGetPostsInSpaceSuspenseQueryOptions(
      postsSpaceId,
      options,
    );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Updates a post for the authenticated user.
 * @summary Update a post
 */
export const postsSpacesControllerUpdatePost = (
  postId: string,
  updatePostDto: UpdatePostDto,
  options?: SecondParameter<typeof customFetch>,
) => {
  return customFetch<SuccessMessageResponseDto>(
    {
      url: `/posts-spaces/posts/${encodeURIComponent(String(postId))}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: updatePostDto,
    },
    options,
  );
};

export const getPostsSpacesControllerUpdatePostMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postsSpacesControllerUpdatePost>>,
    TError,
    { postId: string; data: UpdatePostDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postsSpacesControllerUpdatePost>>,
  TError,
  { postId: string; data: UpdatePostDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postsSpacesControllerUpdatePost>>,
    { postId: string; data: UpdatePostDto }
  > = (props) => {
    const { postId, data } = props ?? {};

    return postsSpacesControllerUpdatePost(postId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostsSpacesControllerUpdatePostMutationResult = NonNullable<
  Awaited<ReturnType<typeof postsSpacesControllerUpdatePost>>
>;
export type PostsSpacesControllerUpdatePostMutationBody = UpdatePostDto;
export type PostsSpacesControllerUpdatePostMutationError = void;

/**
 * @summary Update a post
 */
export const usePostsSpacesControllerUpdatePost = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postsSpacesControllerUpdatePost>>,
    TError,
    { postId: string; data: UpdatePostDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postsSpacesControllerUpdatePost>>,
  TError,
  { postId: string; data: UpdatePostDto },
  TContext
> => {
  const mutationOptions =
    getPostsSpacesControllerUpdatePostMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Deletes a post for the authenticated user. This endpoint allows a user to delete a post they own, provided they meet the necessary conditions. The post must exist and the user must be authorized to delete it.
 * @summary Delete a post
 */
export const postsSpacesControllerDeletePost = (
  postId: string,
  options?: SecondParameter<typeof customFetch>,
) => {
  return customFetch<SuccessMessageResponseDto>(
    {
      url: `/posts-spaces/posts/${encodeURIComponent(String(postId))}`,
      method: 'DELETE',
    },
    options,
  );
};

export const getPostsSpacesControllerDeletePostMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postsSpacesControllerDeletePost>>,
    TError,
    { postId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postsSpacesControllerDeletePost>>,
  TError,
  { postId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postsSpacesControllerDeletePost>>,
    { postId: string }
  > = (props) => {
    const { postId } = props ?? {};

    return postsSpacesControllerDeletePost(postId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostsSpacesControllerDeletePostMutationResult = NonNullable<
  Awaited<ReturnType<typeof postsSpacesControllerDeletePost>>
>;

export type PostsSpacesControllerDeletePostMutationError = void;

/**
 * @summary Delete a post
 */
export const usePostsSpacesControllerDeletePost = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postsSpacesControllerDeletePost>>,
    TError,
    { postId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postsSpacesControllerDeletePost>>,
  TError,
  { postId: string },
  TContext
> => {
  const mutationOptions =
    getPostsSpacesControllerDeletePostMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Retrieves all posts associated with a specific member in a space group.
 * @summary Get all posts of a member in a space group
 */
export const postsSpacesControllerGetPostsInSpaceGroupOfMember = (
  spaceGroupId: string,
  memberId: string,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<PostWithUserAndRoleDto[]>(
    {
      url: `/posts-spaces/${encodeURIComponent(String(spaceGroupId))}/posts/single-member/${encodeURIComponent(String(memberId))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getPostsSpacesControllerGetPostsInSpaceGroupOfMemberQueryKey = (
  spaceGroupId: string,
  memberId: string,
) => {
  return [
    `/posts-spaces/${spaceGroupId}/posts/single-member/${memberId}`,
  ] as const;
};

export const getPostsSpacesControllerGetPostsInSpaceGroupOfMemberQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof postsSpacesControllerGetPostsInSpaceGroupOfMember>
    >,
    TError = void,
  >(
    spaceGroupId: string,
    memberId: string,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<typeof postsSpacesControllerGetPostsInSpaceGroupOfMember>
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customFetch>;
    },
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getPostsSpacesControllerGetPostsInSpaceGroupOfMemberQueryKey(
        spaceGroupId,
        memberId,
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof postsSpacesControllerGetPostsInSpaceGroupOfMember>
      >
    > = ({ signal }) =>
      postsSpacesControllerGetPostsInSpaceGroupOfMember(
        spaceGroupId,
        memberId,
        requestOptions,
        signal,
      );

    return {
      queryKey,
      queryFn,
      enabled: !!(spaceGroupId && memberId),
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<typeof postsSpacesControllerGetPostsInSpaceGroupOfMember>
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type PostsSpacesControllerGetPostsInSpaceGroupOfMemberQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof postsSpacesControllerGetPostsInSpaceGroupOfMember>
    >
  >;
export type PostsSpacesControllerGetPostsInSpaceGroupOfMemberQueryError = void;

export function usePostsSpacesControllerGetPostsInSpaceGroupOfMember<
  TData = Awaited<
    ReturnType<typeof postsSpacesControllerGetPostsInSpaceGroupOfMember>
  >,
  TError = void,
>(
  spaceGroupId: string,
  memberId: string,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof postsSpacesControllerGetPostsInSpaceGroupOfMember>
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof postsSpacesControllerGetPostsInSpaceGroupOfMember>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function usePostsSpacesControllerGetPostsInSpaceGroupOfMember<
  TData = Awaited<
    ReturnType<typeof postsSpacesControllerGetPostsInSpaceGroupOfMember>
  >,
  TError = void,
>(
  spaceGroupId: string,
  memberId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof postsSpacesControllerGetPostsInSpaceGroupOfMember>
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof postsSpacesControllerGetPostsInSpaceGroupOfMember>
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function usePostsSpacesControllerGetPostsInSpaceGroupOfMember<
  TData = Awaited<
    ReturnType<typeof postsSpacesControllerGetPostsInSpaceGroupOfMember>
  >,
  TError = void,
>(
  spaceGroupId: string,
  memberId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof postsSpacesControllerGetPostsInSpaceGroupOfMember>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get all posts of a member in a space group
 */

export function usePostsSpacesControllerGetPostsInSpaceGroupOfMember<
  TData = Awaited<
    ReturnType<typeof postsSpacesControllerGetPostsInSpaceGroupOfMember>
  >,
  TError = void,
>(
  spaceGroupId: string,
  memberId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof postsSpacesControllerGetPostsInSpaceGroupOfMember>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getPostsSpacesControllerGetPostsInSpaceGroupOfMemberQueryOptions(
      spaceGroupId,
      memberId,
      options,
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get all posts of a member in a space group
 */
export const prefetchPostsSpacesControllerGetPostsInSpaceGroupOfMember = async <
  TData = Awaited<
    ReturnType<typeof postsSpacesControllerGetPostsInSpaceGroupOfMember>
  >,
  TError = void,
>(
  queryClient: QueryClient,
  spaceGroupId: string,
  memberId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof postsSpacesControllerGetPostsInSpaceGroupOfMember>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions =
    getPostsSpacesControllerGetPostsInSpaceGroupOfMemberQueryOptions(
      spaceGroupId,
      memberId,
      options,
    );

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getPostsSpacesControllerGetPostsInSpaceGroupOfMemberSuspenseQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof postsSpacesControllerGetPostsInSpaceGroupOfMember>
    >,
    TError = void,
  >(
    spaceGroupId: string,
    memberId: string,
    options?: {
      query?: Partial<
        UseSuspenseQueryOptions<
          Awaited<
            ReturnType<typeof postsSpacesControllerGetPostsInSpaceGroupOfMember>
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customFetch>;
    },
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getPostsSpacesControllerGetPostsInSpaceGroupOfMemberQueryKey(
        spaceGroupId,
        memberId,
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof postsSpacesControllerGetPostsInSpaceGroupOfMember>
      >
    > = ({ signal }) =>
      postsSpacesControllerGetPostsInSpaceGroupOfMember(
        spaceGroupId,
        memberId,
        requestOptions,
        signal,
      );

    return {
      queryKey,
      queryFn,
      enabled: !!(spaceGroupId && memberId),
      ...queryOptions,
    } as UseSuspenseQueryOptions<
      Awaited<
        ReturnType<typeof postsSpacesControllerGetPostsInSpaceGroupOfMember>
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type PostsSpacesControllerGetPostsInSpaceGroupOfMemberSuspenseQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof postsSpacesControllerGetPostsInSpaceGroupOfMember>
    >
  >;
export type PostsSpacesControllerGetPostsInSpaceGroupOfMemberSuspenseQueryError =
  void;

export function usePostsSpacesControllerGetPostsInSpaceGroupOfMemberSuspense<
  TData = Awaited<
    ReturnType<typeof postsSpacesControllerGetPostsInSpaceGroupOfMember>
  >,
  TError = void,
>(
  spaceGroupId: string,
  memberId: string,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<typeof postsSpacesControllerGetPostsInSpaceGroupOfMember>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function usePostsSpacesControllerGetPostsInSpaceGroupOfMemberSuspense<
  TData = Awaited<
    ReturnType<typeof postsSpacesControllerGetPostsInSpaceGroupOfMember>
  >,
  TError = void,
>(
  spaceGroupId: string,
  memberId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<typeof postsSpacesControllerGetPostsInSpaceGroupOfMember>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function usePostsSpacesControllerGetPostsInSpaceGroupOfMemberSuspense<
  TData = Awaited<
    ReturnType<typeof postsSpacesControllerGetPostsInSpaceGroupOfMember>
  >,
  TError = void,
>(
  spaceGroupId: string,
  memberId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<typeof postsSpacesControllerGetPostsInSpaceGroupOfMember>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get all posts of a member in a space group
 */

export function usePostsSpacesControllerGetPostsInSpaceGroupOfMemberSuspense<
  TData = Awaited<
    ReturnType<typeof postsSpacesControllerGetPostsInSpaceGroupOfMember>
  >,
  TError = void,
>(
  spaceGroupId: string,
  memberId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<typeof postsSpacesControllerGetPostsInSpaceGroupOfMember>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getPostsSpacesControllerGetPostsInSpaceGroupOfMemberSuspenseQueryOptions(
      spaceGroupId,
      memberId,
      options,
    );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Retrieves all liked posts associated with a specific member in a space group.
 * @summary Get all liked posts of a member in a space group
 */
export const postsSpacesControllerGetLikedPostsInSpaceGroupOfMember = (
  spaceGroupId: string,
  memberId: string,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<PostWithUserAndRoleDto[]>(
    {
      url: `/posts-spaces/${encodeURIComponent(String(spaceGroupId))}/posts/${encodeURIComponent(String(memberId))}/liked`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getPostsSpacesControllerGetLikedPostsInSpaceGroupOfMemberQueryKey =
  (spaceGroupId: string, memberId: string) => {
    return [`/posts-spaces/${spaceGroupId}/posts/${memberId}/liked`] as const;
  };

export const getPostsSpacesControllerGetLikedPostsInSpaceGroupOfMemberQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof postsSpacesControllerGetLikedPostsInSpaceGroupOfMember>
    >,
    TError = void,
  >(
    spaceGroupId: string,
    memberId: string,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof postsSpacesControllerGetLikedPostsInSpaceGroupOfMember
            >
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customFetch>;
    },
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getPostsSpacesControllerGetLikedPostsInSpaceGroupOfMemberQueryKey(
        spaceGroupId,
        memberId,
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          typeof postsSpacesControllerGetLikedPostsInSpaceGroupOfMember
        >
      >
    > = ({ signal }) =>
      postsSpacesControllerGetLikedPostsInSpaceGroupOfMember(
        spaceGroupId,
        memberId,
        requestOptions,
        signal,
      );

    return {
      queryKey,
      queryFn,
      enabled: !!(spaceGroupId && memberId),
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<
          typeof postsSpacesControllerGetLikedPostsInSpaceGroupOfMember
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type PostsSpacesControllerGetLikedPostsInSpaceGroupOfMemberQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof postsSpacesControllerGetLikedPostsInSpaceGroupOfMember>
    >
  >;
export type PostsSpacesControllerGetLikedPostsInSpaceGroupOfMemberQueryError =
  void;

export function usePostsSpacesControllerGetLikedPostsInSpaceGroupOfMember<
  TData = Awaited<
    ReturnType<typeof postsSpacesControllerGetLikedPostsInSpaceGroupOfMember>
  >,
  TError = void,
>(
  spaceGroupId: string,
  memberId: string,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof postsSpacesControllerGetLikedPostsInSpaceGroupOfMember
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof postsSpacesControllerGetLikedPostsInSpaceGroupOfMember
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function usePostsSpacesControllerGetLikedPostsInSpaceGroupOfMember<
  TData = Awaited<
    ReturnType<typeof postsSpacesControllerGetLikedPostsInSpaceGroupOfMember>
  >,
  TError = void,
>(
  spaceGroupId: string,
  memberId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof postsSpacesControllerGetLikedPostsInSpaceGroupOfMember
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof postsSpacesControllerGetLikedPostsInSpaceGroupOfMember
            >
          >,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function usePostsSpacesControllerGetLikedPostsInSpaceGroupOfMember<
  TData = Awaited<
    ReturnType<typeof postsSpacesControllerGetLikedPostsInSpaceGroupOfMember>
  >,
  TError = void,
>(
  spaceGroupId: string,
  memberId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof postsSpacesControllerGetLikedPostsInSpaceGroupOfMember
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get all liked posts of a member in a space group
 */

export function usePostsSpacesControllerGetLikedPostsInSpaceGroupOfMember<
  TData = Awaited<
    ReturnType<typeof postsSpacesControllerGetLikedPostsInSpaceGroupOfMember>
  >,
  TError = void,
>(
  spaceGroupId: string,
  memberId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof postsSpacesControllerGetLikedPostsInSpaceGroupOfMember
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getPostsSpacesControllerGetLikedPostsInSpaceGroupOfMemberQueryOptions(
      spaceGroupId,
      memberId,
      options,
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get all liked posts of a member in a space group
 */
export const prefetchPostsSpacesControllerGetLikedPostsInSpaceGroupOfMember =
  async <
    TData = Awaited<
      ReturnType<typeof postsSpacesControllerGetLikedPostsInSpaceGroupOfMember>
    >,
    TError = void,
  >(
    queryClient: QueryClient,
    spaceGroupId: string,
    memberId: string,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof postsSpacesControllerGetLikedPostsInSpaceGroupOfMember
            >
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customFetch>;
    },
  ): Promise<QueryClient> => {
    const queryOptions =
      getPostsSpacesControllerGetLikedPostsInSpaceGroupOfMemberQueryOptions(
        spaceGroupId,
        memberId,
        options,
      );

    await queryClient.prefetchQuery(queryOptions);

    return queryClient;
  };

export const getPostsSpacesControllerGetLikedPostsInSpaceGroupOfMemberSuspenseQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof postsSpacesControllerGetLikedPostsInSpaceGroupOfMember>
    >,
    TError = void,
  >(
    spaceGroupId: string,
    memberId: string,
    options?: {
      query?: Partial<
        UseSuspenseQueryOptions<
          Awaited<
            ReturnType<
              typeof postsSpacesControllerGetLikedPostsInSpaceGroupOfMember
            >
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customFetch>;
    },
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getPostsSpacesControllerGetLikedPostsInSpaceGroupOfMemberQueryKey(
        spaceGroupId,
        memberId,
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          typeof postsSpacesControllerGetLikedPostsInSpaceGroupOfMember
        >
      >
    > = ({ signal }) =>
      postsSpacesControllerGetLikedPostsInSpaceGroupOfMember(
        spaceGroupId,
        memberId,
        requestOptions,
        signal,
      );

    return {
      queryKey,
      queryFn,
      enabled: !!(spaceGroupId && memberId),
      ...queryOptions,
    } as UseSuspenseQueryOptions<
      Awaited<
        ReturnType<
          typeof postsSpacesControllerGetLikedPostsInSpaceGroupOfMember
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type PostsSpacesControllerGetLikedPostsInSpaceGroupOfMemberSuspenseQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof postsSpacesControllerGetLikedPostsInSpaceGroupOfMember>
    >
  >;
export type PostsSpacesControllerGetLikedPostsInSpaceGroupOfMemberSuspenseQueryError =
  void;

export function usePostsSpacesControllerGetLikedPostsInSpaceGroupOfMemberSuspense<
  TData = Awaited<
    ReturnType<typeof postsSpacesControllerGetLikedPostsInSpaceGroupOfMember>
  >,
  TError = void,
>(
  spaceGroupId: string,
  memberId: string,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<
            typeof postsSpacesControllerGetLikedPostsInSpaceGroupOfMember
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function usePostsSpacesControllerGetLikedPostsInSpaceGroupOfMemberSuspense<
  TData = Awaited<
    ReturnType<typeof postsSpacesControllerGetLikedPostsInSpaceGroupOfMember>
  >,
  TError = void,
>(
  spaceGroupId: string,
  memberId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<
            typeof postsSpacesControllerGetLikedPostsInSpaceGroupOfMember
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function usePostsSpacesControllerGetLikedPostsInSpaceGroupOfMemberSuspense<
  TData = Awaited<
    ReturnType<typeof postsSpacesControllerGetLikedPostsInSpaceGroupOfMember>
  >,
  TError = void,
>(
  spaceGroupId: string,
  memberId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<
            typeof postsSpacesControllerGetLikedPostsInSpaceGroupOfMember
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get all liked posts of a member in a space group
 */

export function usePostsSpacesControllerGetLikedPostsInSpaceGroupOfMemberSuspense<
  TData = Awaited<
    ReturnType<typeof postsSpacesControllerGetLikedPostsInSpaceGroupOfMember>
  >,
  TError = void,
>(
  spaceGroupId: string,
  memberId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<
            typeof postsSpacesControllerGetLikedPostsInSpaceGroupOfMember
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getPostsSpacesControllerGetLikedPostsInSpaceGroupOfMemberSuspenseQueryOptions(
      spaceGroupId,
      memberId,
      options,
    );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Retrieves all posts spaces associated with a specific user.
 * @summary Get all posts spaces for a user
 */
export const postsSpacesControllerGetUserPostsSpaces = (
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<PostSpace[]>(
    { url: `/posts-spaces/my`, method: 'GET', signal },
    options,
  );
};

export const getPostsSpacesControllerGetUserPostsSpacesQueryKey = () => {
  return [`/posts-spaces/my`] as const;
};

export const getPostsSpacesControllerGetUserPostsSpacesQueryOptions = <
  TData = Awaited<ReturnType<typeof postsSpacesControllerGetUserPostsSpaces>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof postsSpacesControllerGetUserPostsSpaces>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getPostsSpacesControllerGetUserPostsSpacesQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof postsSpacesControllerGetUserPostsSpaces>>
  > = ({ signal }) =>
    postsSpacesControllerGetUserPostsSpaces(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof postsSpacesControllerGetUserPostsSpaces>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PostsSpacesControllerGetUserPostsSpacesQueryResult = NonNullable<
  Awaited<ReturnType<typeof postsSpacesControllerGetUserPostsSpaces>>
>;
export type PostsSpacesControllerGetUserPostsSpacesQueryError = void;

export function usePostsSpacesControllerGetUserPostsSpaces<
  TData = Awaited<ReturnType<typeof postsSpacesControllerGetUserPostsSpaces>>,
  TError = void,
>(options: {
  query: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof postsSpacesControllerGetUserPostsSpaces>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof postsSpacesControllerGetUserPostsSpaces>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customFetch>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function usePostsSpacesControllerGetUserPostsSpaces<
  TData = Awaited<ReturnType<typeof postsSpacesControllerGetUserPostsSpaces>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof postsSpacesControllerGetUserPostsSpaces>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof postsSpacesControllerGetUserPostsSpaces>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function usePostsSpacesControllerGetUserPostsSpaces<
  TData = Awaited<ReturnType<typeof postsSpacesControllerGetUserPostsSpaces>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof postsSpacesControllerGetUserPostsSpaces>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get all posts spaces for a user
 */

export function usePostsSpacesControllerGetUserPostsSpaces<
  TData = Awaited<ReturnType<typeof postsSpacesControllerGetUserPostsSpaces>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof postsSpacesControllerGetUserPostsSpaces>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getPostsSpacesControllerGetUserPostsSpacesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get all posts spaces for a user
 */
export const prefetchPostsSpacesControllerGetUserPostsSpaces = async <
  TData = Awaited<ReturnType<typeof postsSpacesControllerGetUserPostsSpaces>>,
  TError = void,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof postsSpacesControllerGetUserPostsSpaces>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions =
    getPostsSpacesControllerGetUserPostsSpacesQueryOptions(options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getPostsSpacesControllerGetUserPostsSpacesSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof postsSpacesControllerGetUserPostsSpaces>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof postsSpacesControllerGetUserPostsSpaces>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getPostsSpacesControllerGetUserPostsSpacesQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof postsSpacesControllerGetUserPostsSpaces>>
  > = ({ signal }) =>
    postsSpacesControllerGetUserPostsSpaces(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof postsSpacesControllerGetUserPostsSpaces>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PostsSpacesControllerGetUserPostsSpacesSuspenseQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof postsSpacesControllerGetUserPostsSpaces>>
  >;
export type PostsSpacesControllerGetUserPostsSpacesSuspenseQueryError = void;

export function usePostsSpacesControllerGetUserPostsSpacesSuspense<
  TData = Awaited<ReturnType<typeof postsSpacesControllerGetUserPostsSpaces>>,
  TError = void,
>(options: {
  query: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof postsSpacesControllerGetUserPostsSpaces>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function usePostsSpacesControllerGetUserPostsSpacesSuspense<
  TData = Awaited<ReturnType<typeof postsSpacesControllerGetUserPostsSpaces>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof postsSpacesControllerGetUserPostsSpaces>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function usePostsSpacesControllerGetUserPostsSpacesSuspense<
  TData = Awaited<ReturnType<typeof postsSpacesControllerGetUserPostsSpaces>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof postsSpacesControllerGetUserPostsSpaces>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get all posts spaces for a user
 */

export function usePostsSpacesControllerGetUserPostsSpacesSuspense<
  TData = Awaited<ReturnType<typeof postsSpacesControllerGetUserPostsSpaces>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof postsSpacesControllerGetUserPostsSpaces>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getPostsSpacesControllerGetUserPostsSpacesSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Updates an posts space for the authenticated user.
 * @summary Update an posts space
 */
export const postsSpacesControllerUpdatePostsSpace = (
  postsSpaceId: string,
  updatePostsSpaceDto: UpdatePostsSpaceDto,
  options?: SecondParameter<typeof customFetch>,
) => {
  return customFetch<PostSpace>(
    {
      url: `/posts-spaces/${encodeURIComponent(String(postsSpaceId))}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: updatePostsSpaceDto,
    },
    options,
  );
};

export const getPostsSpacesControllerUpdatePostsSpaceMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postsSpacesControllerUpdatePostsSpace>>,
    TError,
    { postsSpaceId: string; data: UpdatePostsSpaceDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postsSpacesControllerUpdatePostsSpace>>,
  TError,
  { postsSpaceId: string; data: UpdatePostsSpaceDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postsSpacesControllerUpdatePostsSpace>>,
    { postsSpaceId: string; data: UpdatePostsSpaceDto }
  > = (props) => {
    const { postsSpaceId, data } = props ?? {};

    return postsSpacesControllerUpdatePostsSpace(
      postsSpaceId,
      data,
      requestOptions,
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type PostsSpacesControllerUpdatePostsSpaceMutationResult = NonNullable<
  Awaited<ReturnType<typeof postsSpacesControllerUpdatePostsSpace>>
>;
export type PostsSpacesControllerUpdatePostsSpaceMutationBody =
  UpdatePostsSpaceDto;
export type PostsSpacesControllerUpdatePostsSpaceMutationError = void;

/**
 * @summary Update an posts space
 */
export const usePostsSpacesControllerUpdatePostsSpace = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postsSpacesControllerUpdatePostsSpace>>,
    TError,
    { postsSpaceId: string; data: UpdatePostsSpaceDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postsSpacesControllerUpdatePostsSpace>>,
  TError,
  { postsSpaceId: string; data: UpdatePostsSpaceDto },
  TContext
> => {
  const mutationOptions =
    getPostsSpacesControllerUpdatePostsSpaceMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Deletes an post space for the authenticated user.
 * @summary Delete an post space
 */
export const postsSpacesControllerDeletePostSpace = (
  postsSpaceId: string,
  options?: SecondParameter<typeof customFetch>,
) => {
  return customFetch<SuccessMessageResponseDto>(
    {
      url: `/posts-spaces/${encodeURIComponent(String(postsSpaceId))}`,
      method: 'DELETE',
    },
    options,
  );
};

export const getPostsSpacesControllerDeletePostSpaceMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postsSpacesControllerDeletePostSpace>>,
    TError,
    { postsSpaceId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postsSpacesControllerDeletePostSpace>>,
  TError,
  { postsSpaceId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postsSpacesControllerDeletePostSpace>>,
    { postsSpaceId: string }
  > = (props) => {
    const { postsSpaceId } = props ?? {};

    return postsSpacesControllerDeletePostSpace(postsSpaceId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostsSpacesControllerDeletePostSpaceMutationResult = NonNullable<
  Awaited<ReturnType<typeof postsSpacesControllerDeletePostSpace>>
>;

export type PostsSpacesControllerDeletePostSpaceMutationError = void;

/**
 * @summary Delete an post space
 */
export const usePostsSpacesControllerDeletePostSpace = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postsSpacesControllerDeletePostSpace>>,
    TError,
    { postsSpaceId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postsSpacesControllerDeletePostSpace>>,
  TError,
  { postsSpaceId: string },
  TContext
> => {
  const mutationOptions =
    getPostsSpacesControllerDeletePostSpaceMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Toggles like post for the authenticated user.
 * @summary Toggle like post
 */
export const postsSpacesControllerToggleLikePost = (
  postId: string,
  options?: SecondParameter<typeof customFetch>,
) => {
  return customFetch<SuccessMessageResponseDto>(
    {
      url: `/posts-spaces/posts/${encodeURIComponent(String(postId))}/toggle-like`,
      method: 'POST',
    },
    options,
  );
};

export const getPostsSpacesControllerToggleLikePostMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postsSpacesControllerToggleLikePost>>,
    TError,
    { postId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postsSpacesControllerToggleLikePost>>,
  TError,
  { postId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postsSpacesControllerToggleLikePost>>,
    { postId: string }
  > = (props) => {
    const { postId } = props ?? {};

    return postsSpacesControllerToggleLikePost(postId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostsSpacesControllerToggleLikePostMutationResult = NonNullable<
  Awaited<ReturnType<typeof postsSpacesControllerToggleLikePost>>
>;

export type PostsSpacesControllerToggleLikePostMutationError = void;

/**
 * @summary Toggle like post
 */
export const usePostsSpacesControllerToggleLikePost = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postsSpacesControllerToggleLikePost>>,
    TError,
    { postId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postsSpacesControllerToggleLikePost>>,
  TError,
  { postId: string },
  TContext
> => {
  const mutationOptions =
    getPostsSpacesControllerToggleLikePostMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Upload files for the authenticated user.
 * @summary Upload files
 */
export const postsSpacesControllerUploadFiles = (
  postId: string,
  uploadFilesDto: UploadFilesDto,
  options?: SecondParameter<typeof customFetch>,
) => {
  const formData = new FormData();
  uploadFilesDto.files.forEach((value) => formData.append('files', value));

  return customFetch<SuccessMessageResponseDto>(
    {
      url: `/posts-spaces/posts/${encodeURIComponent(String(postId))}/files/upload`,
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    },
    options,
  );
};

export const getPostsSpacesControllerUploadFilesMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postsSpacesControllerUploadFiles>>,
    TError,
    { postId: string; data: UploadFilesDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postsSpacesControllerUploadFiles>>,
  TError,
  { postId: string; data: UploadFilesDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postsSpacesControllerUploadFiles>>,
    { postId: string; data: UploadFilesDto }
  > = (props) => {
    const { postId, data } = props ?? {};

    return postsSpacesControllerUploadFiles(postId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostsSpacesControllerUploadFilesMutationResult = NonNullable<
  Awaited<ReturnType<typeof postsSpacesControllerUploadFiles>>
>;
export type PostsSpacesControllerUploadFilesMutationBody = UploadFilesDto;
export type PostsSpacesControllerUploadFilesMutationError = void;

/**
 * @summary Upload files
 */
export const usePostsSpacesControllerUploadFiles = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postsSpacesControllerUploadFiles>>,
    TError,
    { postId: string; data: UploadFilesDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postsSpacesControllerUploadFiles>>,
  TError,
  { postId: string; data: UploadFilesDto },
  TContext
> => {
  const mutationOptions =
    getPostsSpacesControllerUploadFilesMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Remove files for the authenticated user.
 * @summary Remove files
 */
export const postsSpacesControllerRemoveFiles = (
  postId: string,
  removeFilesDto: RemoveFilesDto,
  options?: SecondParameter<typeof customFetch>,
) => {
  return customFetch<SuccessMessageResponseDto>(
    {
      url: `/posts-spaces/posts/${encodeURIComponent(String(postId))}/files/remove`,
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      data: removeFilesDto,
    },
    options,
  );
};

export const getPostsSpacesControllerRemoveFilesMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postsSpacesControllerRemoveFiles>>,
    TError,
    { postId: string; data: RemoveFilesDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postsSpacesControllerRemoveFiles>>,
  TError,
  { postId: string; data: RemoveFilesDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postsSpacesControllerRemoveFiles>>,
    { postId: string; data: RemoveFilesDto }
  > = (props) => {
    const { postId, data } = props ?? {};

    return postsSpacesControllerRemoveFiles(postId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostsSpacesControllerRemoveFilesMutationResult = NonNullable<
  Awaited<ReturnType<typeof postsSpacesControllerRemoveFiles>>
>;
export type PostsSpacesControllerRemoveFilesMutationBody = RemoveFilesDto;
export type PostsSpacesControllerRemoveFilesMutationError = void;

/**
 * @summary Remove files
 */
export const usePostsSpacesControllerRemoveFiles = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postsSpacesControllerRemoveFiles>>,
    TError,
    { postId: string; data: RemoveFilesDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postsSpacesControllerRemoveFiles>>,
  TError,
  { postId: string; data: RemoveFilesDto },
  TContext
> => {
  const mutationOptions =
    getPostsSpacesControllerRemoveFilesMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Toggles pin post for the authenticated user.
 * @summary Toggle pin post
 */
export const postsSpacesControllerTogglePinPost = (
  postsSpaceId: string,
  postId: string,
  togglePinPostDto: TogglePinPostDto,
  options?: SecondParameter<typeof customFetch>,
) => {
  return customFetch<SuccessMessageResponseDto>(
    {
      url: `/posts-spaces/${encodeURIComponent(String(postsSpaceId))}/posts/${encodeURIComponent(String(postId))}/pin`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: togglePinPostDto,
    },
    options,
  );
};

export const getPostsSpacesControllerTogglePinPostMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postsSpacesControllerTogglePinPost>>,
    TError,
    { postsSpaceId: string; postId: string; data: TogglePinPostDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postsSpacesControllerTogglePinPost>>,
  TError,
  { postsSpaceId: string; postId: string; data: TogglePinPostDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postsSpacesControllerTogglePinPost>>,
    { postsSpaceId: string; postId: string; data: TogglePinPostDto }
  > = (props) => {
    const { postsSpaceId, postId, data } = props ?? {};

    return postsSpacesControllerTogglePinPost(
      postsSpaceId,
      postId,
      data,
      requestOptions,
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type PostsSpacesControllerTogglePinPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof postsSpacesControllerTogglePinPost>>
>;
export type PostsSpacesControllerTogglePinPostMutationBody = TogglePinPostDto;
export type PostsSpacesControllerTogglePinPostMutationError = void;

/**
 * @summary Toggle pin post
 */
export const usePostsSpacesControllerTogglePinPost = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postsSpacesControllerTogglePinPost>>,
    TError,
    { postsSpaceId: string; postId: string; data: TogglePinPostDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postsSpacesControllerTogglePinPost>>,
  TError,
  { postsSpaceId: string; postId: string; data: TogglePinPostDto },
  TContext
> => {
  const mutationOptions =
    getPostsSpacesControllerTogglePinPostMutationOptions(options);

  return useMutation(mutationOptions);
};
