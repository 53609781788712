import { Monitor, Moon, Sun } from 'lucide-react';

import { useTheme } from '@/components/theme-provider';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';

const options = [
  {
    theme: 'system',
    Icon: Monitor,
    style: 'bg-white dark:bg-dark-4',
  },
  {
    theme: 'light',
    Icon: Sun,
    style: 'bg-white',
  },
  {
    theme: 'dark',
    Icon: Moon,
    style: 'dark:bg-dark-4',
  },
] as const;

export function ThemeToggle() {
  const { theme, setTheme } = useTheme();
  return (
    <div className="flex items-center justify-between gap-2 rounded-full bg-light-3 p-1 dark:bg-dark-3">
      {options.map((item) => (
        <Button
          key={item.theme}
          variant="ghost"
          onClick={() => setTheme(item.theme)}
          className={cn(
            'h-fit grow rounded-full hover:bg-white hover:bg-opacity-80 dark:hover:bg-dark-4',
            theme === item.theme && item.style,
          )}
        >
          <item.Icon className="h-[0.85rem] w-[0.85rem]" />
        </Button>
      ))}
    </div>
  );
}
