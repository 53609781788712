import { useAuth } from './useContext';

export const useBrandPermits = (): boolean => {
  const { brandRole } = useAuth();

  return (
    brandRole === 'CREATOR' ||
    brandRole === 'ADMIN' ||
    brandRole === 'MODERATOR'
  );
};
