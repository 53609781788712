import { marked } from 'marked';
import TurndownService from 'turndown';

export function htmlToMarkdown(html: string): string {
  const turndownService = new TurndownService({
    headingStyle: 'atx',
    bulletListMarker: '-',
  });

  // Convert HTML to Markdown
  let markdown = turndownService.turndown(html);

  // Post-process the markdown to replace double newlines with a single newline
  markdown = markdown.replace(/\n\n/g, '\n');

  return markdown;
}

export function chatHtmlToMarkdown(html: string): string {
  const turndownService = new TurndownService({
    headingStyle: 'atx',
    bulletListMarker: '-',
  });

  // Custom rule to handle Quill mentions
  turndownService.addRule('mention', {
    filter: (node) => {
      if (
        node.nodeName === 'SPAN' &&
        node.classList.contains('mention') &&
        node.dataset.id // Ensure it's a mention span with a data-id
      )
        return true;
      return false;
    },
    replacement: (_content, node) => {
      const mentionId = (node as Element).getAttribute('data-id');
      return `<@uid:${mentionId}>`; // Return the mention in the desired format
    },
  });

  // Convert HTML to Markdown
  let markdown = turndownService.turndown(html);

  // Post-process the markdown to replace double newlines with a single newline
  markdown = markdown.replace(/\n\n/g, '\n');

  return markdown;
}

export function markdownToHtml(markdown: string): string {
  // Configure marked options
  marked.setOptions({
    breaks: true, // Translate line breaks to <br>
    gfm: true, // Use GitHub Flavored Markdown
  });

  // Regular expression for mention tags
  const mentionRegex = /<@uid:[0-9a-f-]+>/g;

  // Function to replace mention tags with HTML entity placeholders
  const replaceMentions = (text: string): string => {
    return text.replace(mentionRegex, (match) => {
      const encoded = match.replace(/[<>]/g, (c) =>
        c === '<' ? '&lt;' : '&gt;',
      );
      return `&lbrace;MENTION:${encoded}&rbrace;`;
    });
  };

  // Function to restore mention tags from HTML entity placeholders
  const restoreMentions = (text: string): string => {
    return text.replace(/&lbrace;MENTION:(.+?)&rbrace;/g, (_, encoded) => {
      return encoded.replace(/&lt;/g, '<').replace(/&gt;/g, '>');
    });
  };

  // Pre-process: Replace mention tags with HTML entity placeholders
  const processedMarkdown = replaceMentions(markdown);

  // Convert Markdown to HTML
  let html = marked(processedMarkdown, {
    async: false,
  });

  // Post-process: Restore mention tags
  html = restoreMentions(html);

  return html;
}
