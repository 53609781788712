import { Navigate, useParams } from 'react-router-dom';

import { useBrandsControllerGetSpaceGroupsOfBrand } from '@/api';
import { Loading } from '@/components/Loading';

export const BrandPage = () => {
  const { brandId } = useParams<{ brandId: string }>();

  const spaceGroupsQuery = useBrandsControllerGetSpaceGroupsOfBrand(
    brandId as string,
  );

  if (spaceGroupsQuery.isLoading) {
    return <Loading />;
  }

  const spaceGroups = spaceGroupsQuery.data ?? [];

  if (spaceGroups.length === 0) {
    return <Navigate to={`/brands/${brandId}/settings`} />;
  }

  const firstSpaceGroup = spaceGroups[0];

  const postsSpaces = firstSpaceGroup.postsSpaces;

  if (postsSpaces.length > 0) {
    return (
      <Navigate
        to={`/brands/${brandId}/space-groups/${firstSpaceGroup.id}/posts-spaces/${postsSpaces[0].id}`}
      />
    );
  }

  const eventsSpaces = firstSpaceGroup.eventSpaces;

  if (eventsSpaces.length > 0) {
    return (
      <Navigate
        to={`/brands/${brandId}/space-groups/${firstSpaceGroup.id}/events-spaces/${eventsSpaces[0].id}`}
      />
    );
  }

  const chatSpaces = firstSpaceGroup.chatsSpaces;

  if (chatSpaces.length > 0) {
    return (
      <Navigate
        to={`/brands/${brandId}/space-groups/${firstSpaceGroup.id}/chats-spaces/${chatSpaces[0].id}`}
      />
    );
  }

  const coursesSpaces = firstSpaceGroup.coursesSpaces;

  if (coursesSpaces.length > 0) {
    return (
      <Navigate
        to={`/brands/${brandId}/space-groups/${firstSpaceGroup.id}/courses-spaces/${coursesSpaces[0].id}`}
      />
    );
  }

  const membersSpaces = firstSpaceGroup.membersSpaces;

  if (membersSpaces.length > 0) {
    return (
      <Navigate
        to={`/brands/${brandId}/space-groups/${firstSpaceGroup.id}/members-spaces/${membersSpaces[0].id}`}
      />
    );
  }

  return <Navigate to={`/brands/${brandId}/settings`} />;
};
