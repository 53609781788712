import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';

import {
  DiscussionDetails,
  PostSpace,
  PostWithUserAndRoleDto,
  SpaceGroupWithSpaces,
  getBrandsControllerGetSpaceGroupsOfBrandQueryKey,
  useBrandsControllerGetSpaceGroupsOfBrand,
  useDiscussionControllerGetUserJoinedDiscussionsInPostSpace,
  useNotificationsControllerResetPostCounter,
  usePostsSpacesControllerGetPostsInSpace,
  usePostsSpacesControllerUpdatePostsSpace,
} from '@/api';
import { Loading } from '@/components/Loading';
import { SpaceCover } from '@/components/SpaceCover';
import { useUpdateConfigHeader } from '@/components/config-header-provider';
import { AddPostDialog } from '@/components/post-components/add-post-dialog';
import { ChangeCoverPostsSpaceDialog } from '@/components/post-components/posts-space-settings-dialog';
import { Button } from '@/components/ui/button';
import PostCard from '@/components/ui/post-components/PostCard';
import ShareInput from '@/components/ui/post-components/ShareInput';
import { useBrandPermits } from '@/hooks/useBrandPermits';
import { cn } from '@/lib/utils';

const PostsPage = () => {
  const { brandId, spaceGroupId, postsSpaceId } = useParams();
  const spaceGroupsQuery = useBrandsControllerGetSpaceGroupsOfBrand(
    brandId as string,
  );

  if (spaceGroupsQuery.isLoading) {
    return <Loading />;
  }

  if (spaceGroupsQuery.isError) {
    throw new Error(
      `Error fetching space groups for brand ${brandId}, ${spaceGroupsQuery.error}`,
    );
  }

  const spaceGroups = spaceGroupsQuery.data ?? [];

  const spaceGroup = spaceGroups.find(
    (spaceGroup) => spaceGroup.id === spaceGroupId,
  );

  if (!spaceGroup) {
    return <Navigate to="/404" />;
  }

  const postsSpace = spaceGroup.postsSpaces.find(
    (space) => space.id === postsSpaceId,
  );

  if (!postsSpace) {
    return <Navigate to="/404" />;
  }

  return <PostsInner postsSpace={postsSpace} spaceGroup={spaceGroup} />;
};

const getAction = (hasPermits: boolean, postsSpace: PostSpace) => {
  if (hasPermits) {
    return <AddPostDialog postsSpace={postsSpace} />;
  }

  if (!postsSpace.adminOnlyPosting) {
    return <AddPostDialog postsSpace={postsSpace} />;
  }

  return null;
};

const PostsInner = ({
  postsSpace,
  spaceGroup,
}: {
  postsSpace: PostSpace;
  spaceGroup: SpaceGroupWithSpaces;
}) => {
  const hasPermits = useBrandPermits();

  const config = {
    showNotifications: true,
    label: postsSpace.name,
    action: getAction(hasPermits, postsSpace),
  };

  useUpdateConfigHeader(config, [
    postsSpace.name,
    postsSpace.id,
    postsSpace.adminOnlyPosting,
    hasPermits,
    postsSpace,
  ]);

  const postsQuery = usePostsSpacesControllerGetPostsInSpace(postsSpace.id);
  const joinedDiscussionsQuery =
    useDiscussionControllerGetUserJoinedDiscussionsInPostSpace(postsSpace.id);

  const resetPostCounterMutation = useNotificationsControllerResetPostCounter();

  useEffect(() => {
    resetPostCounterMutation.mutate({ postSpaceId: postsSpace.id });
  }, [postsSpace.id, postsQuery.data]);

  if (postsQuery.isLoading || joinedDiscussionsQuery.isLoading) {
    return <Loading />;
  }

  const posts = postsQuery.data ?? [];
  const joinedDiscussions = joinedDiscussionsQuery.data ?? [];

  const canCreatePost = hasPermits || !postsSpace.adminOnlyPosting;

  return (
    <div className="flex h-full w-full flex-col gap-10 overflow-auto p-6">
      <SpaceCover
        image={postsSpace.image}
        ChangeCoverButton={
          <ChangeCoverPostsSpaceDialog
            postsSpace={postsSpace}
            brandId={spaceGroup.brandId}
          />
        }
        RemoveButton={
          <RemoveCoverButton
            postsSpace={postsSpace}
            brandId={spaceGroup.brandId}
          />
        }
      />
      <div className={cn('grid grid-cols-1 gap-10 xl:grid-cols-[1fr]')}>
        <div className="flex h-full flex-col items-center gap-5 overflow-auto">
          {/* <div className="flex w-full content-between items-center justify-between md:hidden">
            <h5 className="text-lg font-medium">{config.label}</h5>
            {config.action}
          </div> */}

          <div className="flex w-full flex-col items-center gap-6">
            {canCreatePost && (
              <div className="sticky -top-5 z-10 flex w-full justify-center bg-white py-4 dark:bg-dark-1 md:-top-0">
                <div className="w-full px-2">
                  <ShareInput
                    postsSpace={postsSpace}
                    spaceGroupId={spaceGroup.id}
                  />
                </div>
              </div>
            )}
            <div className="flex h-full w-full flex-col gap-6 px-2">
              <div className="flex flex-col gap-8 pb-32">
                <PostsList
                  posts={posts}
                  joinedDiscussions={joinedDiscussions}
                  spaceGroup={spaceGroup}
                />
              </div>
            </div>
          </div>
        </div>

        {/* {postsSpace.generateDiscussionFromPosts && (
          <div className="sticky top-0 hidden xl:block">
            <TopDiscussions
              postsSpaceId={postsSpace.id}
              spaceGroup={spaceGroup}
              joinedDiscussions={joinedDiscussions}
            />
          </div>
        )} */}
      </div>
    </div>
  );
};

const RemoveCoverButton = ({
  postsSpace,
  brandId,
}: {
  postsSpace: PostSpace;
  brandId: string;
}) => {
  const queryClient = useQueryClient();
  const updatePostMutation = usePostsSpacesControllerUpdatePostsSpace({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: getBrandsControllerGetSpaceGroupsOfBrandQueryKey(brandId),
        });
      },
    },
  });

  const handleRemoveCover = async () => {
    updatePostMutation.mutate({
      postsSpaceId: postsSpace.id,
      data: {
        image: null,
      },
    });
  };

  return (
    <Button
      variant="secondary"
      className="rounded-sm"
      onClick={handleRemoveCover}
    >
      Remove
    </Button>
  );
};

const PostsList = ({
  posts,
  joinedDiscussions,
  spaceGroup,
}: {
  posts: PostWithUserAndRoleDto[];
  joinedDiscussions: DiscussionDetails[];
  spaceGroup: SpaceGroupWithSpaces;
}) => {
  const { brandId, spaceGroupId, postsSpaceId } = useParams();
  const hasJoinedDiscussions = joinedDiscussions.length > 0;

  // split the list of posts into pinned and unpinned
  const pinnedPosts = posts.filter((post) => post.isPinned);
  const unpinnedPosts = posts.filter((post) => !post.isPinned);

  return (
    <div>
      <div className="flex items-start gap-4">
        <div className="border-b-2 border-brand pb-[6px] text-sm font-medium">
          All posts
        </div>
        {hasJoinedDiscussions && (
          <Link
            to={`/brands/${brandId}/space-groups/${spaceGroupId}/posts-spaces/${postsSpaceId}/joined-discussions`}
            className="pb-[6px] text-sm font-medium text-textParagraph dark:text-dark-textParagraph"
          >
            Joined discussions
          </Link>
        )}
      </div>

      {pinnedPosts.length > 0 && (
        <div className="mb-6 mt-10 flex flex-col gap-6">
          {pinnedPosts.map((post) => (
            <PostCard
              key={post.id}
              post={post}
              spaceGroup={spaceGroup}
              isDiscussionJoined={joinedDiscussions.some(
                (discussion) => discussion.id === post.discussion?.id,
              )}
            />
          ))}
        </div>
      )}

      <div className="mb-6 mt-10 flex flex-col gap-6 pb-32">
        {posts.length === 0 && <div>No posts found</div>}
        {unpinnedPosts.map((post) => (
          <PostCard
            key={post.id}
            post={post}
            spaceGroup={spaceGroup}
            isDiscussionJoined={joinedDiscussions.some(
              (discussion) => discussion.id === post.discussion?.id,
            )}
          />
        ))}
      </div>
    </div>
  );
};

export default PostsPage;
