export const ArrowRightFilledIcon = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.50003 12L5.5 3.99997C5.5 3.79777 5.62182 3.61543 5.80865 3.53803C5.99549 3.46063 6.21055 3.50343 6.35355 3.64643L10.3535 7.64643C10.5488 7.84163 10.5488 8.15823 10.3535 8.3535L6.35359 12.3535C6.21059 12.4965 5.99553 12.5393 5.80869 12.4619C5.62185 12.3845 5.50003 12.2022 5.50003 12Z"
    />
  </svg>
);
