import { useState } from 'react';

import { checkLine } from '@/assets/icon';
import { ArrowRightIcon } from '@/assets/icon/arrowRight';
import { countryList } from '@/utils/CountryProvider';

type Props = {
  disabled?: boolean;
  country: {
    name: string;
    flag: string;
  };
  setCountry?: (country: { name: string; flag: string }) => void;
  titleClassName?: string;
  inputClassName?: string;
};

const CountryDropdown = ({
  disabled = false,
  country,
  setCountry = () => {},
  titleClassName = '',
  inputClassName = '',
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const handleSelect = (selectedCountry: { name: string; flag: string }) => {
    setCountry(selectedCountry);
    setIsOpen(false);
    setSearchTerm('');
  };

  // Filter countries based on search term
  const filteredCountries = countryList.filter((cntry) =>
    cntry.name.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  return (
    <div
      className={`relative w-full text-base font-normal text-black dark:text-white ${
        disabled && 'cursor-not-allowed opacity-50'
      }`}
    >
      <h3 className={`pb-1 text-left text-base font-medium ${titleClassName}`}>
        Country
      </h3>
      <button
        className={`flex w-full cursor-default items-center justify-between gap-2.5 rounded-lg border border-light bg-white p-4 text-left shadow focus:border-brand focus:outline-none dark:border-dark-light dark:bg-dark-1 ${
          disabled ? 'cursor-not-allowed' : 'cursor-pointer'
        } ${inputClassName}`}
        disabled={disabled}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="h-6 w-6 flex-shrink-0 overflow-hidden rounded-full border border-light dark:border-dark-light">
          {country &&
            countryList.find((c) => c.name === country.name)?.flag && (
              <img
                src={countryList.find((c) => c.name === country.name)?.flag}
                alt="country flag"
                className="h-full w-full object-cover"
              />
            )}
        </div>
        <label
          className={`w-full ${!country && 'text-textParagraph dark:text-dark-textParagraph'}`}
        >
          {country ? country.name : 'Select a country'}
        </label>

        <ArrowRightIcon className="h-6 w-6 rotate-90 fill-black dark:fill-white" />
      </button>

      {isOpen && (
        <div className="absolute z-50 mt-1 w-full rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 dark:bg-dark-2">
          {/* Search input */}
          <div className="p-2">
            <input
              type="text"
              placeholder="Search countries..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full rounded-md border p-2 text-base dark:border-dark-light dark:bg-dark-1"
            />
          </div>

          {/* Country list */}
          <ul className="max-h-56 overflow-auto py-1 text-base sm:text-sm">
            {filteredCountries.length > 0 ? (
              filteredCountries.map((cntry) => (
                <li
                  key={cntry.name}
                  className="relative cursor-pointer select-none py-2 pl-3 pr-9 hover:bg-gray-100 dark:hover:bg-dark-3"
                  onClick={() => handleSelect(cntry)}
                >
                  <div className="flex items-center">
                    <div className="h-6 w-6 flex-shrink-0 overflow-hidden rounded-lg">
                      <img
                        src={cntry.flag}
                        alt="country flag"
                        className="h-full w-full object-cover"
                      />
                    </div>
                    <span className="ml-3 block truncate">{cntry.name}</span>
                  </div>
                  {country && country.name === cntry.name && (
                    <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                      <img src={checkLine} alt="check" className="w-6" />
                    </span>
                  )}
                </li>
              ))
            ) : (
              <li className="p-2 text-center text-gray-500">
                No countries found
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CountryDropdown;
