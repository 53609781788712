import { Navigate } from 'react-router-dom';

import {
  useBrandsControllerGetAllBrands,
  useBrandsControllerGetSpaceGroupsOfBrand,
} from '@/api';
import { Loading } from '@/components/Loading';

export const CheckBrandsPage = () => {
  const brandsQuery = useBrandsControllerGetAllBrands();

  if (brandsQuery.isLoading) {
    return <Loading />;
  }

  const brands = brandsQuery.data ?? [];

  if (brands.length === 0) {
    return <Navigate to={`/settings`} />;
  }

  return <BrandsInner brandId={brands[0].id} />;
};

const BrandsInner = ({ brandId }: { brandId: string }) => {
  const spaceGroupsQuery = useBrandsControllerGetSpaceGroupsOfBrand(brandId);

  if (spaceGroupsQuery.isLoading) {
    return <Loading />;
  }

  const spaceGroups = spaceGroupsQuery.data ?? [];

  if (spaceGroups.length === 0) {
    return <Navigate to={`/brands/${brandId}/settings`} />;
  }

  const firstSpaceGroup = spaceGroups[0];

  const postsSpaces = firstSpaceGroup.postsSpaces;

  if (postsSpaces.length > 0) {
    return (
      <Navigate
        to={`/brands/${brandId}/space-groups/${firstSpaceGroup.id}/posts-spaces/${postsSpaces[0].id}`}
      />
    );
  }

  const eventsSpaces = firstSpaceGroup.eventSpaces;

  if (eventsSpaces.length > 0) {
    return (
      <Navigate
        to={`/brands/${brandId}/space-groups/${firstSpaceGroup.id}/events-spaces/${eventsSpaces[0].id}`}
      />
    );
  }

  const chatSpaces = firstSpaceGroup.chatsSpaces;

  if (chatSpaces.length > 0) {
    return (
      <Navigate
        to={`/brands/${brandId}/space-groups/${firstSpaceGroup.id}/chats-spaces/${chatSpaces[0].id}`}
      />
    );
  }

  const coursesSpaces = firstSpaceGroup.coursesSpaces;

  if (coursesSpaces.length > 0) {
    return (
      <Navigate
        to={`/brands/${brandId}/space-groups/${firstSpaceGroup.id}/courses-spaces/${coursesSpaces[0].id}`}
      />
    );
  }

  const membersSpaces = firstSpaceGroup.membersSpaces;

  if (membersSpaces.length > 0) {
    return (
      <Navigate
        to={`/brands/${brandId}/space-groups/${firstSpaceGroup.id}/members-spaces/${membersSpaces[0].id}`}
      />
    );
  }

  return <Navigate to={`/brands/${brandId}/settings`} />;
};
