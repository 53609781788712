export const UserPen = (props) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path d="M8.5 21.5H4C4 17.634 7.13401 14.5 11 14.5C11.1681 14.5 11.3348 14.5059 11.5 14.5176M15 7.5C15 9.70914 13.2091 11.5 11 11.5C8.79086 11.5 7 9.70914 7 7.5C7 5.29086 8.79086 3.5 11 3.5C13.2091 3.5 15 5.29086 15 7.5ZM12.5898 21.5L14.6148 21.095C14.7914 21.0597 14.8797 21.042 14.962 21.0097C15.0351 20.9811 15.1045 20.9439 15.1689 20.899C15.2414 20.8484 15.3051 20.7848 15.4324 20.6574L19.5898 16.5C20.1421 15.9477 20.1421 15.0523 19.5898 14.5C19.0376 13.9477 18.1421 13.9477 17.5898 14.5L13.4324 18.6574C13.3051 18.7848 13.2414 18.8484 13.1908 18.921C13.1459 18.9853 13.1088 19.0548 13.0801 19.1279C13.0478 19.2102 13.0302 19.2985 12.9948 19.475L12.5898 21.5Z" />
    </g>
  </svg>
);
