import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { SearchIcon } from '@/assets/icon/search';
import { useUpdateConfigHeader } from '@/components/config-header-provider';
import ChatMessagePreview from '@/components/one-to-one-chats/ChatMessagePreview';
import { CometChatOneToOne } from '@/components/one-to-one-chats/comet-chat-one-to-one';
import InputField from '@/components/ui/InputField';
import MeetingRequests from '@/components/ui/inbox-components/MeetingRequests';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';

export function InboxPage() {
  const { userId: selectedUserId } = useParams();
  const [searchParams] = useSearchParams();

  useUpdateConfigHeader({
    hidden: true,
  });

  return (
    <Tabs
      className="flex h-full w-full flex-col overflow-hidden pt-4"
      defaultValue={searchParams.get('type') || 'Messages'}
    >
      <TabsList className="flex h-fit justify-start gap-6 rounded-none !bg-transparent p-2.5">
        <TabsTrigger
          value="Messages"
          className="rounded-none p-0 text-lg font-semibold text-textParagraph data-[state=active]:bg-transparent data-[state=active]:!text-black dark:text-dark-textParagraph data-[state=active]:dark:!text-white"
        >
          Messages
        </TabsTrigger>
        <TabsTrigger
          value="Requests"
          className="rounded-none p-0 text-lg font-semibold text-textParagraph data-[state=active]:bg-transparent data-[state=active]:!text-black dark:text-dark-textParagraph data-[state=active]:dark:!text-white"
        >
          Requests
        </TabsTrigger>
      </TabsList>
      <TabsContent value="Messages" className="flex-1 overflow-hidden">
        <div className="h-full lg:hidden">
          {selectedUserId ? (
            <ConversationWithUser selectedUserId={selectedUserId} />
          ) : (
            <ConversationList />
          )}
        </div>
        <div className="hidden h-full lg:grid lg:grid-cols-[350px_1fr]">
          <ConversationList />
          <div className="flex h-full items-center justify-center overflow-hidden rounded-l-3xl bg-[#F3F4F6] dark:bg-dark-2">
            {selectedUserId ? (
              <ConversationWithUser selectedUserId={selectedUserId} />
            ) : (
              <span className="text-textParagraph dark:text-dark-textParagraph">
                No conversation selected
              </span>
            )}
          </div>
        </div>
      </TabsContent>

      <TabsContent value="Requests" className="flex-1 overflow-hidden">
        <MeetingRequests />
      </TabsContent>
    </Tabs>
  );
}

const ConversationList = () => {
  const [memberSearch, setMemberSearch] = useState('');
  const queryClient = useQueryClient();

  const conversations =
    queryClient.getQueryData<CometChat.Conversation[]>([
      'cometchat',
      'conversations',
    ]) || [];

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMemberSearch(e.target.value);
  };

  const filteredConversations = conversations.filter((conversation) => {
    const conversationWith = conversation.getConversationWith();
    return conversationWith
      .getName()
      .toLowerCase()
      .includes(memberSearch.toLowerCase());
  });

  return (
    <div className="flex h-full flex-col gap-4 overflow-auto p-4">
      <InputField
        placeholder="Search member"
        onChange={handleSearch}
        type="text"
        value={memberSearch}
        startIcon={<SearchIcon className="fill-black dark:fill-white" />}
        className="max-w-104"
        inputClassName="!rounded-full placeholder:text-textParagraph !bg-light-2 dark:!bg-dark-2 border-none !p-2.5 text-base items-center"
      />
      <div>
        {filteredConversations.length === 0 && (
          <span className="text-textParagraph dark:text-dark-textParagraph">
            No conversations
          </span>
        )}
        <div className="flex w-full flex-col gap-2">
          {filteredConversations.map((conversation) => (
            <ChatMessagePreview
              key={conversation.getConversationId()}
              conversation={conversation}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const ConversationWithUser = ({
  selectedUserId,
}: {
  selectedUserId: string;
}) => {
  return <CometChatOneToOne selectedUserId={selectedUserId} />;
};
